import { Middleware, PayloadAction } from '@reduxjs/toolkit';

import { trackIndirect } from 'lib/amplitude';
import { REDUX_ACTION_MAP } from 'utils/analytics/constants';

const sendEvent: Middleware<{}, any> =
  () => (next) => (action: PayloadAction<any>) => {
    const eventsToTrack = [
      'mapVessels/addVesselAsync/fulfilled',
      'mapVessels/addVesselAsync/rejected',
      'mapVessels/updateInCharge',
      'reminders/addReminderAsync/fulfilled',
      'reminders/addReminderAsync/rejected',
      'reminders/removeReminderAsync/fulfilled',
      'reminders/removeReminderAsync/rejected',
      'desktopNotifications/toggleDesktopNotifications',
      'infoboxPopup/setInfoboxPopup',
      'infoboxPopup/clearInfoboxPopup',
      'mapRuler/startMapRuler',
      'mapRuler/closeMapRuler',
      'portsInRange/togglePortsInRange',
      'portsInRange/setRangeNauticalMiles',
      'setupWizard/toggleWizardVisiblity',
      'riskArea/addRiskAreaTrackingRequestAsync/fulfilled',
      'riskArea/addRiskAreaTrackingRequestAsync/rejected',
      'riskArea/deleteRiskAreaTrackingRequestAsync/fulfilled',
      'riskArea/deleteRiskAreaTrackingRequestAsync/rejected',
    ];

    if (eventsToTrack.includes(action.type)) {
      trackIndirect(REDUX_ACTION_MAP[action.type] || action.type);
    }

    next(action);
  };

export default sendEvent;
