import CircularProgress from '@mui/material/CircularProgress';
import {
  black90,
  blue,
  blue10,
  borderGray,
  gray10,
  gray40,
  gray50,
  gray70,
  green,
  red,
  red10,
  textBlack,
  white,
  blue70,
} from 'lib/colors';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';

type ButtonProps = {
  id?: string;
  className?: string;
  children?: React.ReactNode;
  variant?: ButtonVariants;
  buttonText?: string;
  size?: ButtonSizes;
  onClick?: (e: any) => void;
  style?: any;
  onMouseEnter?: any;
  onMouseOut?: any;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  onMouseLeave?: () => void;
  loading?: boolean;
  disabled?: boolean;
  isLift?: boolean;
  type?: 'button' | 'submit' | 'reset';
  fullWidth?: boolean;
  tabIndex?: number;
  fullHeight?: boolean;
};

const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'outline-black',
  'danger',
  'dark',
  'success',
  'dark-blue',
  'ghost',
] as const;
type ButtonVariants = typeof BUTTON_VARIANTS[number];

const BUTTON_SIZES = ['small', 'medium', 'large', 'xlarge'] as const;
type ButtonSizes = typeof BUTTON_SIZES[number];

const BUTTON_FONT_SIZE: { [key in ButtonSizes]: string } = {
  small: '14px',
  medium: '16px',
  large: '18px',
  xlarge: '18px',
};

const BUTTON_MIN_HEIGHT: { [key in ButtonSizes]: string } = {
  small: '30px',
  medium: '33px',
  large: '36px',
  xlarge: '40px',
};

const TEXT_COLOR: { [key in ButtonVariants]: string } = {
  primary: white,
  secondary: blue,
  'outline-black': textBlack,
  danger: white,
  dark: white,
  success: white,
  'dark-blue': white,
  ghost: textBlack,
};

const BUTTON_COLOR: { [key in ButtonVariants]: string } = {
  primary: blue,
  secondary: white,
  'outline-black': white,
  danger: red,
  dark: black90,
  success: green,
  'dark-blue': blue70,
  ghost: 'transparent',
};

const DISABLED_BUTTON_COLOR: { [key in ButtonVariants]: string } = {
  primary: blue10,
  secondary: gray10,
  'outline-black': gray10,
  danger: red10,
  dark: gray70,
  success: gray10,
  'dark-blue': blue10,
  ghost: 'transparent',
};

const DISABLED_TEXT_COLOR: { [key in ButtonVariants]: string } = {
  primary: white,
  secondary: blue10,
  'outline-black': gray50,
  danger: white,
  dark: white,
  success: blue10,
  'dark-blue': white,
  ghost: gray50,
};

const BORDER_COLOR: { [key in ButtonVariants]: string } = {
  primary: blue,
  secondary: borderGray,
  'outline-black': textBlack,
  danger: red,
  dark: black90,
  success: green,
  'dark-blue': blue70,
  ghost: 'transparent',
};

const ButtonWrapper = styled.button<{
  $variant: ButtonVariants;
  $isLift: boolean;
  $fullWidth: boolean;
  $size: ButtonSizes;
  $fullHeight: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 85px;
  min-height: ${({ $size }) => BUTTON_MIN_HEIGHT[$size]};

  ${({ $fullHeight }) => $fullHeight && 'height: 100%;'}

  font-size: ${({ $size }) => BUTTON_FONT_SIZE[$size]};

  ${({ $fullWidth }) => $fullWidth && 'width: 100%;'}

  padding: 5px 10px;

  border-radius: 4px;

  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  // Remove default button stylings
  border: none;
  box-shadow: none;
  background-color: transparent;

  color: ${({ $variant }) => TEXT_COLOR[$variant]};
  background-color: ${({ $variant }) => BUTTON_COLOR[$variant]};
  border: 1px solid ${({ $variant }) => BORDER_COLOR[$variant]};

  transition: background-color 0.3s ease-in-out;

  font-weight: 500;
  cursor: pointer;

  ${({ $isLift }) =>
    $isLift &&
    `
      &:hover {
      cursor: pointer;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transform: translate(-1px, -1px);
      }
    `}

  &:disabled {
    color: ${({ $variant }) => DISABLED_TEXT_COLOR[$variant]};
    background-color: ${({ $variant }) => DISABLED_BUTTON_COLOR[$variant]};
    transform: none;
    box-shadow: none;
    border: 1px solid transparent;
    cursor: not-allowed;
  }

  #loading {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    color: ${({ $variant }) => TEXT_COLOR[$variant]};
    background-color: ${({ $variant }) => BUTTON_COLOR[$variant]};
    z-index: 1;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ $variant }) =>
    $variant === 'ghost' &&
    `&:hover {
    background-color: ${gray40};
  }`}
`;

const ButtonV2 = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className,
      variant = 'primary',
      buttonText = '',
      onClick,
      style,
      onMouseEnter,
      onMouseOut,
      loading = false,
      disabled,
      type = 'button',
      isLift = false,
      fullWidth = false,
      size = 'medium',
      fullHeight = false,
      ...rest
    },
    ref
  ) => {
    return (
      <ButtonWrapper
        ref={ref}
        $isLift={isLift}
        className={className}
        $variant={variant}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        style={style}
        disabled={!!disabled || loading}
        type={type}
        $fullWidth={fullWidth}
        $size={size}
        $fullHeight={fullHeight}
        {...rest}
      >
        {children || buttonText}
        {loading && (
          <div id="loading">
            <CircularProgress size={16} sx={{ color: 'inherit' }} />
          </div>
        )}
      </ButtonWrapper>
    );
  }
);

ButtonV2.displayName = 'ButtonV2';
export default ButtonV2;
