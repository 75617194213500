import { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { PortCallResponseMap, PortCallV2CommonUTC } from 'utils/types';

type PlanPortCalls = {
  [vesselId: number]: PortCallV2CommonUTC[];
};

export const findPortOrder = (
  currPortCall: PortCallV2CommonUTC,
  futurePortCalls: PortCallV2CommonUTC[]
) => {
  const futurePortOrder = futurePortCalls.findIndex(
    ({ displayName }) => displayName === currPortCall.displayName
  );
  return futurePortOrder >= 0 ? futurePortOrder + 1 : undefined;
};

export function removePastPorts(portCalls: PortCallResponseMap) {
  return Object.keys(portCalls).reduce((newMap, vesselId) => {
    if (portCalls[Number(vesselId)]?.success) {
      const unorderedPortCalls = portCalls[Number(vesselId)].portCalls.reduce<
        PortCallV2CommonUTC[]
      >((portCalls, { utcETA, utcETD, ...rest }) => {
        if (!utcETA && !utcETD) return portCalls;
        if (utcETA && moment(utcETA).isAfter(moment())) {
          portCalls.push({
            ...rest,
            utcETA,
            utcETD,
          });
          return portCalls;
        }
        if (utcETD && moment(utcETD).isAfter(moment())) {
          portCalls.push({
            ...rest,
            utcETA,
            utcETD,
          });
          return portCalls;
        }
        return portCalls;
      }, []);
      newMap[Number(vesselId)] = orderBy(
        unorderedPortCalls,
        (o) => o.utcETD || o.utcETA
      );
    }
    return newMap;
  }, {} as PlanPortCalls);
}

export const plannedPortSearchResults = (
  storePortCalls: PortCallResponseMap,
  vessels: Map<number, Vessel>,
  searchText: string
) => {
  const portsData = removePastPorts(storePortCalls);

  return new Map(
    Object.entries(portsData).reduce((acc, [key, portCalls]) => {
      const vesselId = Number(key);
      const vessel = vessels.get(vesselId);

      // find the ports based on search query
      const searchedPorts = portCalls
        // filter searched ports based on query text
        .filter(({ displayName, portLocode }) =>
          [displayName, portLocode || ''].some((text) =>
            text.toLowerCase().includes(searchText)
          )
        )
        // include port order for future journey
        .map((portCall) => ({
          ...portCall,
          order: findPortOrder(portCall, portCalls),
        }));

      if (vessel && searchedPorts.length) {
        acc.set(vessel.id, vessel);
      }

      return acc;
    }, new Map<number, Vessel>())
  );
};
