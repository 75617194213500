import Switch from '@mui/material/Switch';
import {
  GridColDef,
  GridRenderCellParams,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridEditInputCell,
} from '@mui/x-data-grid-pro';
import {
  AlertType,
  SearchFilter,
} from '@greywing-maritime/frontend-library/dist/types/alerts';
import styled from 'styled-components/macro';

import { SetModal } from 'hooks/useModal';
import { iconGray, red } from 'lib/colors';
import { ETA_SEARCH_KEYS } from 'lib/constants';
import { ExpandButton, Tooltip } from 'components/shared';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_NU_EDIT_ALERT_START,
  TRACK_NU_EDIT_ALERT_DELETE_START,
  TRACK_SA_EDIT_ALERT_START,
  TRACK_SA_EDIT_ALERT_DELETE_START,
} from 'utils/analytics/constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledEditIcon = styled(EditIcon)`
  font-size: 18px !important;
  color: ${iconGray};
  margin-right: 6px;
`;

const TrashIcon = styled(DeleteIcon)`
  font-size: 20px !important;
  color: ${iconGray};
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    color: ${red};
  }
`;

const Criteria = styled.div`
  font-size: 0.8rem;
  text-transform: uppercase;
`;

const renderHeader = ({
  field,
  colDef,
}: {
  field: string;
  colDef: GridColDef;
}) => {
  const { headerName } = colDef;
  const showIcon = field === 'notifyFreqHrs';
  const columnHeader = (
    <HeaderWrapper>
      {showIcon ? <StyledEditIcon /> : null}
      {headerName}
    </HeaderWrapper>
  );

  return showIcon ? (
    <Tooltip content={`Double click cell to update Frequency`}>
      {columnHeader}
    </Tooltip>
  ) : (
    columnHeader
  );
};

export const getAlertsColumns = (
  type: AlertType,
  setModal: SetModal,
  showError: boolean
): GridColDef[] => {
  let customColumns: GridColDef[] = [];

  switch (type) {
    case 'SEARCH_FILTER': {
      customColumns = [
        // custom icon component for detail panel toggle
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
          maxWidth: 60,
          type: 'string', // overrides the type defined in `GRID_DETAIL_PANEL_TOGGLE_COL_DEF` to avoid console warning
          renderCell: (params: GridRenderCellParams) => (
            <ExpandButton expanded={params.value} />
          ),
        },
        {
          field: 'searchFilters',
          headerName: 'Criteria',
          minWidth: 240,
          renderCell: (params: GridRenderCellParams) => {
            const { searchFilters } = params.row;
            const criteria = (searchFilters as SearchFilter[])
              .map(({ field, query: value }) =>
                ETA_SEARCH_KEYS.includes(field)
                  ? `${Number(value) / (3600 * 24)} days`
                  : value
              )
              .join(', ');
            return <Criteria>{criteria}</Criteria>;
          },
        },
      ];
      break;
    }

    case 'UPDATE_FILTER': {
      customColumns = [
        {
          field: 'textFilter',
          headerName: 'Description',
          minWidth: 250,
        },
      ];
      break;
    }

    default:
      break;
  }

  // restrict alert frequency to 720 hrs
  const validateFreq = (value: number): Promise<boolean> => {
    return new Promise((resolve) => {
      const error =
        value !== null && !isNaN(value) && value <= 720 && value > 0
          ? false
          : true;
      resolve(error);
    });
  };

  const preProcessEditCellProps = async (
    params: GridPreProcessEditCellProps
  ) => {
    const error = await validateFreq(params.props.value);
    return { ...params.props, error };
  };
  const handleErrorText = (val: number) => {
    if (val > 720) return `Can't be greater than 720`;
    else if (val < 1) return `Can't be less than 1`;
    return 'Invalid value!';
  };

  const commonColumns: GridColDef[] = [
    {
      field: 'notifyFreqHrs',
      headerName: 'Frequency (Hrs)',
      minWidth: 170,
      type: 'number',
      editable: true,
      align: 'center',
      preProcessEditCellProps,
      renderEditCell: (props: GridRenderEditCellParams) => (
        <Tooltip
          open={props.error && showError}
          content={handleErrorText(props.row.notifyFreqHrs)}
          placement="bottom"
          background={red}
        >
          <GridEditInputCell {...props} />
        </Tooltip>
      ),
    },
    {
      field: 'emailNotificationEnabled',
      headerName: 'Email',
      minWidth: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { emailNotificationEnabled } = params.row;
        const content = `${
          !emailNotificationEnabled ? 'Enable' : 'Disable'
        } email notification for this alert`;

        return (
          <Tooltip content={content}>
            <Switch
              size="small"
              checked={emailNotificationEnabled}
              onChange={(event) => {
                const { checked } = event.target;
                trackUserAction(
                  type === 'UPDATE_FILTER'
                    ? TRACK_NU_EDIT_ALERT_START
                    : TRACK_SA_EDIT_ALERT_START,
                  'click',
                  {
                    field: 'emailNotification',
                    old: !checked,
                    new: checked,
                  }
                );
                setModal('confirm', {
                  type: 'update',
                  newRow: params.row,
                  checked,
                });
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Action',
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams) => (
        <Tooltip content="Delete this alert">
          <TrashIcon
            onClick={() => {
              trackUserAction(
                type === 'UPDATE_FILTER'
                  ? TRACK_NU_EDIT_ALERT_DELETE_START
                  : TRACK_SA_EDIT_ALERT_DELETE_START
              );
              setModal('confirm', { type: 'remove', newRow: row });
            }}
          />
        </Tooltip>
      ),
    },
  ];

  return [...customColumns, ...commonColumns].map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: item.maxWidth || 400,
    ...(item.field === 'notifyFreqHrs' ? { renderHeader } : {}),
  }));
};
