import {
  boolean,
  Describe,
  object,
  string,
  mask,
  defaulted,
  enums,
} from 'superstruct';
import { SetupWizardState } from 'redux/types';
import { APP_VERSION } from 'utils/constants';
import { ALL_SETUP_WIZARD_STEPS } from 'utils/types';

const SetupWizardSchema: Describe<
  Pick<SetupWizardState, 'currentStep' | 'isCompleted' | 'version'>
> = object({
  currentStep: defaulted(enums(ALL_SETUP_WIZARD_STEPS), 'role'),
  isCompleted: defaulted(boolean(), false),
  version: defaulted(string(), APP_VERSION),
});

export function validateSetupWizard(
  setupWizard: any
):
  | Omit<
      SetupWizardState,
      'totalSteps' | 'availableSteps' | 'currentStepIndex' | 'showPatBack'
    >
  | undefined {
  try {
    const validated = mask(setupWizard, SetupWizardSchema);
    return { ...validated, visible: !validated.isCompleted };
  } catch (error) {
    console.log('Failed to validate setup wizard');
  }
}
