import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { ETA_SEARCH_KEYS } from 'lib/constants';
import { borderGray, lightGray, red50, textGray, white } from 'lib/colors';
import { closeSidePanel, setAppTooltipText } from 'redux/actions';
import { clearSearchFilters, removeSearchFilter } from 'redux/thunks';
import { selectMapVessels, selectSettings } from 'redux/selectors';
import { TooltipPayload } from 'redux/types';
import { selectSidePanelVesselCount } from 'redux/reducers/sidePanel';

import { Card, Button } from '../shared';
import ButtonV2 from '../shared/ButtonV2';
import { Title } from '../shared/Typography';
import Pill from './common/Pill';
import MultipleVesselTags from './MultipleVesselTags';
import DownloadCSV from './DownloadCSV';
import AssignPIC from './AssignPIC';

import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_VF_REMOVE_ALL_SIDEPANEL,
  TRACK_VF_REMOVE_SIDEPANEL,
} from 'utils/analytics/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningIcon from '@mui/icons-material/Warning';

export enum ActionButtonState {
  normal,
  in_progress,
  completed,
}

const RootContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 4;
`;

const StyledCard = styled(Card)`
  border-radius: 0;
  background-color: ${white};
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 0.25rem;
  row-gap: 0.25rem;
  align-items: center;
  p#filter {
    color: ${textGray};
    font-size: 0.75rem;
    margin: 0;
  }
  margin-bottom: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  row-gap: 0.25rem;
  margin-top: 0.75rem;
`;

const Container = styled.div<{ $hasFilter: boolean }>`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  ${({ $hasFilter }) => ($hasFilter ? 'margin-bottom: 0.5rem;' : '')}
`;

const FilterContainer = styled.div`
  border: 1px solid ${borderGray};
  background-color: ${lightGray};
  margin: 0 -1rem 0.5rem -1rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-right: -1rem;
  margin-left: -1rem;
`;

const FilterButton = styled(Button)`
  text-transform: uppercase;
  border-radius: 0;
  margin-right: 0;
  flex: 1 1 100%;
  border-bottom: 0;
  &:nth-child(1) {
    border-right: 0;
    border-left: 0;
  }
  &:nth-child(2) {
    border-right: 0;
  }
`;

const WarningBox = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${red50};
  padding: 0.5rem;
  column-gap: 0.25rem;
  align-items: start;
  p {
    font-size: 0.7rem;
    margin: 0 0 0.5rem 0;
  }
  svg {
    margin-top: 2px;
  }
`;

function SidePanelVesselHeader() {
  const dispatch = useAppDispatch();
  const sidePanelVesselCount = useSelector(selectSidePanelVesselCount);
  const { searchFilters, userInfo } = useSelector(selectSettings);
  const { filteredVessels } = useSelector(selectMapVessels);

  const [showMoreFilters, setShowMoreFilters] = useState<boolean>(false);

  const allowShowMoreFilters = searchFilters.length > 1;
  const filterPills = useMemo(() => {
    if (!allowShowMoreFilters) return searchFilters;
    if (!showMoreFilters) return searchFilters.slice(0, 1);
    return searchFilters;
  }, [searchFilters, allowShowMoreFilters, showMoreFilters]);
  const isTMC = userInfo?.type === 'tmc';

  const onDismissClick = () => {
    dispatch(closeSidePanel());
  };

  const handleSetTooltip = useCallback((payload?: TooltipPayload) => {
    dispatch(setAppTooltipText(payload));
  }, []); // eslint-disable-line

  const clearLastFilter = () => {
    const { query, field, label } = searchFilters[searchFilters.length - 1];
    trackUserAction(TRACK_VF_REMOVE_SIDEPANEL, 'click', {
      query,
      field,
      label,
    });

    dispatch(removeSearchFilter(searchFilters.length - 1));
  };

  return (
    <RootContainer>
      <StyledCard>
        <Container $hasFilter={!!searchFilters.length}>
          {!!sidePanelVesselCount && (
            <Title
              level={2}
              style={{ display: 'flex', flexWrap: 'wrap', columnGap: '0.5rem' }}
            >
              <span>Vessel Detail</span>
              <span>{`(${sidePanelVesselCount} vessel${
                sidePanelVesselCount > 1 ? 's' : ''
              })`}</span>
            </Title>
          )}
          <ButtonV2
            size="small"
            variant="danger"
            onClick={onDismissClick}
            onMouseEnter={() =>
              handleSetTooltip({
                element: 'SIDEBAR_CLOSE_BUTTON',
                state: 'hover',
              })
            }
            onMouseOut={() => handleSetTooltip()}
          >
            Hide
          </ButtonV2>
        </Container>
        {!!searchFilters.length && (
          <>
            <FilterContainer>
              <List>
                <p id="filter">{`Filter (${searchFilters.length}):`}</p>
                {filterPills.map((filter, idx) => {
                  const queryText: string = ETA_SEARCH_KEYS.includes(
                    filter.field
                  )
                    ? `${Number(filter.query) / (3600 * 24)} days`
                    : filter.query;
                  const pillText = `${filter.label} ${queryText}`;
                  const filterParams = filterPills[idx];

                  return (
                    <Pill
                      key={idx}
                      pillText={pillText}
                      $backgroundColor={white}
                      buttonIcon={<CancelIcon />}
                      onButtonClick={() => {
                        trackUserAction(TRACK_VF_REMOVE_SIDEPANEL, 'click', {
                          query: filterParams.query,
                          field: filterParams.field,
                          label: filterParams.label,
                        });
                        dispatch(removeSearchFilter(idx));
                      }}
                    />
                  );
                })}
              </List>
              <ButtonContainer>
                <FilterButton
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    trackUserAction(TRACK_VF_REMOVE_ALL_SIDEPANEL);
                    dispatch(clearSearchFilters());
                  }}
                >
                  clear filters
                </FilterButton>
                {allowShowMoreFilters && (
                  <FilterButton
                    size="small"
                    variant="secondary"
                    onClick={() => setShowMoreFilters((e) => !e)}
                  >
                    {showMoreFilters ? 'hide' : 'show all'}
                  </FilterButton>
                )}
              </ButtonContainer>
            </FilterContainer>
            {!filteredVessels.size && (
              <WarningBox>
                <WarningIcon sx={{ color: red50 }} />
                <div>
                  <p>
                    Your filters are showing <i>zero</i> vessels. Click here to
                    clear the most recent filter
                  </p>
                  <Button
                    size="small"
                    onClick={clearLastFilter}
                    style={{ marginRight: 0 }}
                  >
                    Clear recent filter
                  </Button>
                </div>
              </WarningBox>
            )}
          </>
        )}

        <Wrapper>
          <DownloadCSV />
          {!isTMC && <AssignPIC />}
        </Wrapper>
      </StyledCard>
      {/* Multiple Tag control */}
      {sidePanelVesselCount > 1 && (
        <StyledCard style={{ marginTop: 8 }}>
          <MultipleVesselTags />
        </StyledCard>
      )}
    </RootContainer>
  );
}

export default SidePanelVesselHeader;
