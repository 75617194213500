import { ChangeEvent, memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import {
  CheckCircle,
  RadioButtonUncheckedRounded as CircleUnchecked,
} from '@mui/icons-material';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { useModal } from 'hooks';
import {
  red50 as red,
  yellow50 as yellow,
  gray70,
  green,
  midBlue,
  white,
} from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { formatDate } from 'utils/format-date';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { TRACK_BOOK_AGENCY, COMPARISON_VIEW } from 'utils/analytics/constants';

import { Tooltip } from 'components/shared';
import SummaryModal from 'components/CrewChangePanel/SendModal';
import PortDetails from 'components/CrewChangePanel/Map/PortDetails';
import { BudgetStatus, PortCardProps } from 'components/CrewChangePanel/types';
import { Card, EmailIcon, Header } from './common';
import { buttonStyles } from '../../../common';
import {
  getLocodeKeyFromPortData,
  getUnneededFlightsCount,
} from '../../../helpers';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(Card)<{
  $active: boolean;
  $budgetStatus?: BudgetStatus;
}>`
  padding: 0;
  background: ${({ $budgetStatus }) =>
    ($budgetStatus === BudgetStatus.ALL_OVER && `${red}20`) ||
    ($budgetStatus === BudgetStatus.SOME_OVER && `${yellow}20`) ||
    ($budgetStatus === BudgetStatus.ALL_UNDER && `${green}20`)};

  ${({ $active }) => $active && `border: 2px solid ${green}`};
  cursor: pointer;

  &:hover {
    transform: translate(-1px, -1px);
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.4rem 0.75rem;
`;

const TitleWrapper = styled(FlexWrapper)`
  align-items: baseline;
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 0.33rem;
    margin-top: -3px;
    color: ${gray70};
  }
`;

const SendButton = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.7rem;
  line-height: 1.4rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0 0 0.2rem 0.2rem;
  background: ${green}90;
  color: ${white};
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    background: ${green};
    color: ${white};
  }
`;

const HeaderWrapper = styled(FlexWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Badge = styled.div`
  ${buttonStyles};
  font-size: 0.6rem;
  background: ${midBlue};
  padding: 0.15rem 0.3rem;
  border-radius: 4px;
  letter-spacing: 0.3px;
  margin-top: -2px;
`;

type Props = PortCardProps & {
  showPreferredOption: boolean;
};

function CompareCard({ port, showPreferredOption, onChange }: Props) {
  const { event } = useSelector(selectCrewChangePanel);
  const { userInfo } = useSelector(selectSettings);
  const {
    filters: allFilters,
    planningData: { crew },
    tableState: { budgetDetails },
    updateFilters,
  } = useContext(CCPanelContext);
  const { modal, setModal } = useModal(null);

  const currentLocodeKey = useMemo(
    () => getLocodeKeyFromPortData(port),
    [port]
  );
  const { active, confirmed, emailSent, preferred } =
    allFilters[currentLocodeKey];
  const { name, locode, flightSource, uniqETA } = port;
  const confirmedCount = useMemo(
    () => confirmed.length + getUnneededFlightsCount(crew, port),
    [crew, port, confirmed.length]
  );
  const tooltipContent = preferred
    ? 'This port is preferred.  Deselct to remove preference.'
    : 'Select the checkbox to make this port preferred.';

  const handleSelectPreference = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    event.stopPropagation();
    // set port preference in filters
    updateFilters({
      type: 'SET_PREFERRED_PORT',
      payload: { locode: currentLocodeKey, preferred: checked },
    });
  };

  const renderPreferredCheckbox = () =>
    Boolean(confirmedCount) && (
      <>
        {showPreferredOption && (
          <Tooltip content={tooltipContent}>
            <Checkbox
              checked={preferred || false}
              onChange={handleSelectPreference}
              icon={<CircleUnchecked fontSize="inherit" />}
              checkedIcon={<CheckCircle fontSize="inherit" color="success" />}
            />
          </Tooltip>
        )}
      </>
    );

  const renderAction = () => {
    // do NOT allow sending email for original cards, if this access is disabled
    const disableEmailForOriginal =
      !userInfo?.access?.['Email multi TMC cards'] && !flightSource;
    if (disableEmailForOriginal) return null;

    return (
      Boolean(confirmedCount) && (
        <SendButton
          data-id="e2e_book-agency"
          onClick={() => {
            setModal('send');
            trackUserAction(TRACK_BOOK_AGENCY(COMPARISON_VIEW));
          }}
        >
          Book Agency
        </SendButton>
      )
    );
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === 'send' && event) {
      return (
        <SummaryModal
          event={event as CrewEvent}
          port={port}
          closeModal={() => setModal(null)}
        />
      );
    }
  };

  const basePortName = uniqETA
    ? `${locode}, ${name} (${formatDate(uniqETA, 'DD MMM, YY')})`
    : `${locode}, ${name}`;
  const fullPortName = flightSource
    ? `${basePortName} - ${flightSource}`
    : basePortName;
  const titleTestId = `e2e_compare-card-title${
    flightSource ? `-${flightSource}` : ''
  }`;
  const descriptionTestId = `e2e_compare-card-cost-details${
    flightSource ? `-${flightSource}` : ''
  }`;

  return (
    <Wrapper
      $active={active}
      $budgetStatus={budgetDetails?.status}
      onClick={() => !active && onChange(port)}
    >
      <ContentWrapper>
        <div>
          <HeaderWrapper>
            <TitleWrapper>
              {renderPreferredCheckbox()}
              <Header>
                <span data-id={titleTestId}>{fullPortName}</span>{' '}
              </Header>
              {preferred && (
                <Tooltip content="Preferred port for crew-change">
                  <Badge>Preferred</Badge>
                </Tooltip>
              )}
            </TitleWrapper>

            {emailSent && (
              <Tooltip content="Email sent to port agent">
                <EmailIcon />
              </Tooltip>
            )}
          </HeaderWrapper>
        </div>

        <div data-id={descriptionTestId}>
          <PortDetails port={port} compare />
        </div>
      </ContentWrapper>
      {renderAction()}

      {renderModal()}
    </Wrapper>
  );
}

export default memo(CompareCard);
