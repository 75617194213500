import { SyntheticEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components/macro';

import sleep from 'lib/sleep';
import { gray80, lightPurple, textGray } from 'lib/colors';

import { LottieAnimation, Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';

const Wrapper = styled.div`
  padding: auto 0.5rem;
  overflow: visible;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const AnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  width: 350px;
  height: 150px;
`;

const AnimationTitle = styled.div`
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 0.1rem;
  margin-bottom: 0.5rem;
  color: ${textGray};
`;

const Instruction = styled.div`
  font-size: 0.9rem;
  color: ${gray80};
  margin-bottom: 1.2rem;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const autoCompleteStyles = {
  maxHeight: '400px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
  overflow: 'visible',
};

type Props = {
  sources: string[];
  duplicatePort: (source: string) => void;
  closeModal: () => void;
};

function DuplicatePortModal({ sources, duplicatePort, closeModal }: Props) {
  const [source, setSource] = useState('');
  const [copying, setCopying] = useState(false);

  const handleSelect = (event: SyntheticEvent) => {
    const { textContent } = event.target as HTMLInputElement;
    setSource(textContent || '');
  };

  const handleKeyDown = async (event: SyntheticEvent) => {
    // async update for keyboard to have value available in event
    await sleep(0);
    const { value } = event.target as HTMLInputElement;
    setSource(value);
  };

  // delays added to prevent non-responsive UI issue
  const handleDuplicate = async () => {
    setCopying(true);
    await sleep(200);
    duplicatePort(source);
    await sleep();
    setCopying(false);
    closeModal();
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton
        variant="primary"
        disabled={!source || copying}
        onClick={handleDuplicate}
      >
        {copying ? 'Copying...' : 'Create Copy'}
      </StyledButton>
    </ButtonsWrapper>
  );

  const modalProps = !copying
    ? {
        title: 'Split Flights for',
        closeModal,
        actions,
      }
    : {};

  return (
    <Modal width={350} {...modalProps}>
      {copying ? (
        <AnimationWrapper>
          <LottieAnimation name="64012-loader-plane-voi" width="100%" />
          <AnimationTitle>Copying port...</AnimationTitle>
        </AnimationWrapper>
      ) : (
        <Wrapper>
          <Instruction>
            Create a copy of this port to try different flight settings.
          </Instruction>

          <Autocomplete
            freeSolo
            size="small"
            options={sources}
            value={source || null} // fallback to `null` for no option
            onChange={handleSelect}
            onKeyDown={(e) => e.key === 'Enter' && handleKeyDown(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Flight source"
                placeholder="Enter flight source name"
              />
            )}
            ListboxProps={{ style: autoCompleteStyles }}
            sx={{ mr: 2, width: '100%' }}
          />
        </Wrapper>
      )}
    </Modal>
  );
}

export default DuplicatePortModal;
