import groupBy from 'lodash/groupBy';
import { memo, ReactNode, useMemo, useState } from 'react';

import {
  FlexWrapper,
  FlightConnection,
  Price,
  ExpandIcon,
  Title,
  FlightCostList,
  FlightCostTitle,
} from './common';
import { PopupFlight } from '../../types';

type FlightCostDetails = {
  components: Array<ReactNode>;
  allFlightsCost: number;
  currency: string;
};

type Props = {
  flights: PopupFlight[];
  isCompareView?: boolean;
};

const getTotalCost = (list: PopupFlight[]) =>
  list?.reduce((acc, { price }) => acc + (price?.amount || 0), 0).toFixed(0);

function FlightCosts({ flights, isCompareView }: Props) {
  // state for flights costs exanded mode
  const [showAll, setShowAll] = useState(false);

  const groupedFlights = useMemo(
    () => groupBy(flights, 'connection'),
    [flights]
  );
  const flightDetails = useMemo(
    () =>
      Object.keys(groupedFlights).reduce<FlightCostDetails>(
        (acc, path, index) => {
          const flights = groupedFlights[path];
          const { currency = '' } = flights[0].price || {};
          const individualCost = getTotalCost(flights);
          const costComponent = (
            <FlexWrapper key={index}>
              <FlightConnection>{`${flights.length} x ${path}`}</FlightConnection>
              <Price>
                {individualCost} {currency}
              </Price>
            </FlexWrapper>
          );
          return {
            currency: acc.currency || currency,
            allFlightsCost: acc.allFlightsCost + Number(individualCost),
            components: [...acc.components, costComponent],
          };
        },
        {
          allFlightsCost: 0,
          components: [],
          currency: '',
        }
      ),
    [groupedFlights]
  );
  const { currency, allFlightsCost = 0, components = [] } = flightDetails;

  return (
    <>
      <FlexWrapper>
        <FlightCostTitle
          onClick={(event) => {
            event.stopPropagation();
            setShowAll((prev) => !prev);
          }}
        >
          <Title>Total Flights Cost</Title>
          <ExpandIcon $showAll={showAll} $compare={isCompareView} />
        </FlightCostTitle>

        <Price>
          {allFlightsCost} {currency}
        </Price>
      </FlexWrapper>
      {showAll && (
        <FlightCostList $compare={isCompareView}>{components}</FlightCostList>
      )}
    </>
  );
}

export default memo(FlightCosts);
