import isUndefined from 'lodash/isUndefined';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import type { PortShort } from '@greywing-maritime/frontend-library/dist/types/searchPorts';
import type { PortCallV2Common } from '@greywing-maritime/frontend-library/dist/types/portCalls';

import { convertDateIntoUTC, getEarliestDate } from 'utils/dates';
import { samplePortCall } from 'utils/sample-vessel';
import { PortCallV2CommonUTC } from 'utils/types';
import {
  CalculateRouteForSidePanel,
  FormattedPortCalls,
  JourneyPortVisualCard,
  JourneyVesselVisualCard,
  VesselJourneyTuple,
} from './types';

// For sidepanel
export const calculateVesselJourney = (
  ...args: VesselJourneyTuple
): (JourneyPortVisualCard | JourneyVesselVisualCard)[] => {
  const [vessel, isLoading, isInWizard, portCalls, segment] = args;
  const {
    id: vesselId,
    status,
    course,
    updatedAt,
    lastPortName,
    lastPortCode,
    nextPortName,
    nextPortCode,
  } = vessel;
  const _journey: Array<JourneyPortVisualCard | JourneyVesselVisualCard> = [];

  // Handle situation where it is in the wizard
  if (isInWizard) {
    const etd = new Date();
    etd.setDate(new Date().getDate() - 5);
    const eta = new Date();
    eta.setDate(new Date().getDate() + 3);
    _journey.push(
      {
        index: 2,
        componentType: 'port',
        type: 'Predictive',
        displayName: nextPortName,
        port: {
          name: nextPortName,
          locode: nextPortCode,
        },
        eta: eta.toISOString(),
        etd: undefined,
        utcETA: eta.toISOString(),
        utcETD: undefined,
        isFinalDestination: false,
        isCompleted: false,
      },
      {
        index: null,
        componentType: 'vessel',
        type: 'vessel',
        vesselId,
        status,
        course,
        isFinalDestination: false,
        isCompleted: false,
      },
      {
        index: 1,
        componentType: 'port',
        type: 'AIS',
        displayName: lastPortName,
        port: {
          name: lastPortName,
          locode: lastPortCode,
        },
        eta: undefined,
        etd: etd.toISOString(),
        utcETD: etd.toISOString(),
        utcETA: undefined,
        isCompleted: true,
        isFinalDestination: true,
      }
    );
    return _journey;
  }

  if (isLoading) {
    return [];
  }

  if (!portCalls.length) {
    _journey.push({
      index: null,
      componentType: 'vessel',
      type: 'vessel',
      vesselId,
      status,
      course,
      isCompleted: false,
      isFinalDestination: true,
    });
    return _journey;
  }

  const vesselObject: JourneyVesselVisualCard & { dateToSort: string } = {
    index: null,
    componentType: 'vessel',
    type: 'vessel',
    status,
    course,
    vesselId,
    dateToSort: updatedAt,
    isCompleted: false,
    isFinalDestination: false,
  };

  const final = portCalls
    .reduce<
      ((JourneyVesselVisualCard | JourneyPortVisualCard) & {
        dateToSort: string;
      })[]
    >(
      (acc, portCall) => {
        const dateToSort = getEarliestDate([portCall.utcETA, portCall.utcETD]);
        if (!dateToSort) return acc;
        if (segment === 'past' && dateToSort > updatedAt) return acc;
        if (segment === 'future' && dateToSort < updatedAt) return acc;
        acc.push({
          index: null,
          componentType: 'port',
          displayName: portCall.displayName,
          subDisplayName: portCall.subDisplayName,
          eta: portCall.eta ? String(portCall.eta) : undefined,
          etd: portCall.etd ? String(portCall.etd) : undefined,
          utcETA: portCall.utcETA || undefined,
          utcETD: portCall.utcETD || undefined,
          port: {
            locode: portCall.portDict?.locode || portCall.portLocode,
            name: portCall.portDict?.displayName || portCall.displayName,
          },
          dateToSort,
          type: portCall.type,
          lng: portCall.lng || undefined,
          lat: portCall.lat || undefined,
          timezone: portCall.timezone || undefined,
          isCompleted: moment(dateToSort).diff(moment()) < 0,
          isFinalDestination: false,
        });
        return acc;
      },
      [vesselObject]
    )
    .sort((a, b) => {
      if (a.dateToSort && b.dateToSort) {
        return moment(b.dateToSort).diff(moment(a.dateToSort));
      }
      return 0;
    })
    .map(({ dateToSort, ...others }) => others);

  // Set the index numbers to match the display on the map
  let portIndex = final.length - 1;
  const modified = final.map((portCalls) => {
    if (portCalls.componentType === 'port') {
      portIndex -= 1;
      return {
        ...portCalls,
        index: portIndex + 1,
      };
    }
    return portCalls;
  });

  // Set the last array item to be the final destination
  modified[modified.length - 1] = {
    ...modified[modified.length - 1],
    isFinalDestination: true,
  };
  return modified;
};

export function formatPortCallUserTimeV2(
  portCall: PortCallV2Common,
  dict: { [key: string]: PortShort },
  vesselId: number
): PortCallV2CommonUTC {
  const portDict = portCall.portLocode
    ? dict[portCall.portLocode] || null
    : null;
  const timezone = portDict?.timezone || portCall.timezone || '';
  if (!timezone) {
    return {
      ...portCall,
      vesselId,
      utcETA: null,
      utcETD: null,
      portDict,
      lat: portDict?.lat || portCall.lat,
      lng: portDict?.lng || portCall.lng,
      dateToSort: null,
      timezone,
    };
  }
  const utcETA = portCall.eta
    ? convertDateIntoUTC(portCall.eta, timezone)
    : null;
  const utcETD = portCall.etd
    ? convertDateIntoUTC(portCall.etd, timezone)
    : null;
  const dateToSort =
    !utcETA && !utcETD ? null : getEarliestDate([utcETA, utcETD]);
  return {
    ...portCall,
    vesselId,
    utcETA,
    utcETD,
    dateToSort,
    portDict,
    lat: portDict?.lat || portCall.lat,
    lng: portDict?.lng || portCall.lng,
    timezone,
  };
}

export const isCopiedDisplayName = (displayName: string, text: string) =>
  displayName.toLowerCase().includes(text.toLocaleLowerCase());

// calculate vessel journey for side-panel
export const getVesselJourneyForSidePanel: CalculateRouteForSidePanel =
  (isInWizard) => (vessel, portCallResponse, routeConfig) => {
    const { request, response } = portCallResponse;
    const vesselPortCalls: FormattedPortCalls | null =
      (isInWizard && {
        all_sources: samplePortCall.portCalls as PortCallV2CommonUTC[],
      }) ||
      (response
        ? {
            all_sources: response.portCalls,
            ...groupBy(response.portCalls, ({ type }) => type.toLowerCase()),
          }
        : null);

    // fall back to future ports of all sources if not `routeConfig` provided
    const activeRouteSegment =
      (!isUndefined(routeConfig) && routeConfig.segment) || 'future';
    const activeDisplayType =
      (!isUndefined(routeConfig) && routeConfig.displayType) || 'all_sources';
    const activePortCalls = vesselPortCalls?.[activeDisplayType] || [];
    const isLoading = isInWizard ? false : !request || request === 'pending';
    const vesselJourneyArgs: VesselJourneyTuple = [
      vessel,
      isLoading,
      isInWizard,
      activePortCalls,
      activeRouteSegment,
    ];
    return calculateVesselJourney(...vesselJourneyArgs);
  };
