import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import styled from 'styled-components/macro';

import { textGray } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { SwitchV2, Tooltip } from 'components/shared';
import PortDropdowns from './Dropdowns';
import ETASlider from './ETASlider';
import PortSlider from '../../../common/PortSlider';
import { Label } from '../../../common';
import { getAgencies, includePortETA } from '../../../helpers';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-wrap: wrap;
    row-gap: 1.25rem;
  }
`;

const Wrapper = styled(FlexWrapper)`
  width: 100%;
  font-size: 0.9rem;
  padding: 0.3rem 0 1.25rem 0.5rem;
  overflow-x: auto;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    padding-bottom: unset;
  }
`;

const SliderWrapper = styled(FlexWrapper)`
  margin-left: 20px;
  flex-direction: column;
  p {
    margin: 0 0 0 2rem;
    font-size: 0.7rem;
    color: ${textGray};
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    margin-left: 2.5rem;
  }
`;

const ToggleWrapper = styled(FlexWrapper)`
  margin-left: 1.25rem;
  column-gap: 0.5rem;
  flex-shrink: 0;
`;

const portNameInputStyle = {
  ml: 2.5,
  mr: 0.5,
  minWidth: '8rem',
  [`@media screen and (max-width: ${BREAK_POINT_XS})`]: { ml: 0 },
};

type Props = {
  loading: boolean;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

function PortActions({ loading, query, setQuery }: Props): JSX.Element | null {
  const responses = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.responses
  );
  const {
    portParams: { agency, etaLimit, priorities, showUrgentPorts },
    updatePlanningData,
    updatePortParams,
  } = useContext(CCPanelContext);

  const { proxPorts: portResponse, urgentPorts: urgentPortResponse } =
    responses;
  const { proxPorts, portsCount } = useMemo(() => {
    const proxPorts =
      (showUrgentPorts
        ? urgentPortResponse?.proxPorts
        : portResponse?.proxPorts) || [];
    const portsCount =
      (showUrgentPorts ? urgentPortResponse?.count : portResponse?.count) || 0;
    return { proxPorts, portsCount };
  }, [portResponse, urgentPortResponse, showUrgentPorts]);

  const handleSelectAgency = useCallback(
    (value: string) =>
      updatePortParams((prev) => ({
        ...prev,
        agency: value === 'ALL' ? value : value.toLowerCase(),
      })),
    [updatePortParams]
  );

  const handleUpdateETALimit = useCallback(
    (newValue: number) => {
      updatePortParams((prev) => ({ ...prev, etaLimit: newValue }));
      updatePlanningData((prevData) => ({
        ...prevData,
        // update `skipped` field when etaLimit is changed
        route: prevData.route.map((item) => ({
          ...item,
          skipped: !includePortETA(item.eta, newValue),
        })),
      }));
    },
    [updatePortParams, updatePlanningData]
  );

  const handleSelectPriorities = useCallback(
    (options: string[]) => {
      updatePortParams((prev) => ({ ...prev, priorities: options }));
    },
    [updatePortParams]
  );

  const handleToggleUrgentPorts = useCallback(() => {
    updatePortParams((prev) => ({
      ...prev,
      showUrgentPorts: !prev.showUrgentPorts,
    }));
  }, [updatePortParams]);

  const options = useMemo(
    () => getAgencies(proxPorts) || [],
    [proxPorts] // eslint-disable-line
  );

  useEffect(() => {
    if (options.length && !agency) {
      handleSelectAgency(options[0]);
    }
  }, [options]); // eslint-disable-line

  useEffect(() => {
    handleSelectPriorities(priorities);
  }, [priorities]); // eslint-disable-line

  return (
    <Wrapper>
      <SliderWrapper>
        <Tooltip
          content="Ports shown within this distance from vessel route"
          enterDelay={500}
          enterNextDelay={2000}
        >
          <PortSlider disabled={loading} portCount={portsCount} />
        </Tooltip>
      </SliderWrapper>

      <SliderWrapper>
        <ETASlider value={etaLimit} updateValue={handleUpdateETALimit} />
      </SliderWrapper>

      <PortDropdowns.Agency
        agency={agency}
        options={options}
        onSelect={handleSelectAgency}
      />

      <ToggleWrapper>
        <SwitchV2
          className="e2e_closest-ports-settings"
          checked={showUrgentPorts}
          onChange={handleToggleUrgentPorts}
        />
        <Label>Find Closest Ports</Label>
      </ToggleWrapper>

      <TextField
        data-id="port-name-locode"
        size="small"
        label="Port name or locode"
        placeholder="Search for port"
        value={query}
        onChange={({ target: { value } }) => setQuery(value)}
        sx={portNameInputStyle}
        inputProps={{ style: { fontSize: '0.8rem' } }}
      />

      <PortDropdowns.Priority
        priorities={priorities}
        onSelect={handleSelectPriorities}
      />
    </Wrapper>
  );
}

export default memo(PortActions);
