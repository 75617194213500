import { memo, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { MenuItem, Menu, Fade } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NestedMenuItem } from 'mui-nested-menu';
import styled from 'styled-components/macro';
import type { CrewChangePlanSummaryResp } from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { useAppDispatch, useModal, useSavedPlanExport } from 'hooks';
import { blue, red, white } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { prepareReadOnlyData } from 'utils/read-only-cc-plans';
import { deleteCrewChangePlan } from 'api/flotilla';
import { removeCrewChangePlan as removePlanFromStore } from 'redux/actions';
import { formatReadOnlyDataLink } from 'utils/crew-change-events';

import { CommonConfirmModal, Tooltip } from 'components/shared';
import { ActionButton } from 'components/CrewChangePanel/common';

const MenuButton = styled(ActionButton)<{ $wizardView?: boolean }>`
  min-height: unset;
  min-width: unset;
  font-size: 0.7rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  margin: 0;
  ${({ $wizardView }) =>
    $wizardView &&
    `
    font-size: 0.6rem;
    letter-spacing: 0;
  `};
  svg {
    font-size: 15px !important;
  }
`;

const StyledNestedMenuItem = styled(NestedMenuItem)`
  .MuiTypography-root {
    font-size: 0.9rem !important;
    line-height: 1 !important;
    font-weight: 500;
  }
`;

const StyledLeftIcon = styled(ChevronLeftIcon)`
  margin: -0.2rem -0.4rem 0 -0.2rem;
  font-size: 1.25rem !important;
`;

const getMenuItemStyles = (isDelete?: boolean) => ({
  fontSize: '0.9rem',
  lineHeight: 1,
  fontWeight: '500',
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 150ms ease',
  '&:hover': {
    background: isDelete ? red : blue,
    color: white,
  },
});

type MenuOption = {
  content: string;
  testId?: string;
  tooltip?: string;
  nestedOptions?: {
    text: string;
    testId?: string;
    tooltipContent: string;
  }[];
};

type Props = {
  isOpen: boolean;
  isCompleted: boolean;
  fetching: boolean;
  wizardView: boolean;
  userId: number | undefined;
  summary: CrewChangePlanSummaryResp;
  onOpen: () => void;
};

const getOptions = (isOwn: boolean, isCompleted: boolean): MenuOption[] => {
  const commonOptions = [
    {
      content: 'View',
      testId: 'e2e_view-plan',
      tooltip: 'View the report details in panel',
    },
    {
      content: 'Copy Link',
      testId: 'e2e_copy-link',
      tooltip: 'Copy the link of this report',
    },
    ...(isOwn
      ? [
          {
            content: 'Delete',
            testId: 'e2e_delete-plan',
            tooltip: 'Delete this report from saved plans',
          },
        ]
      : []),
  ];

  return isCompleted
    ? [
        ...commonOptions,
        {
          content: 'Download',
          testId: 'e2e_download-plan',
          nestedOptions: [
            {
              text: 'Regular',
              testId: 'e2e_download-regular-plan',
              tooltipContent: 'Download this report in excel format',
            },
            {
              text: 'Port Agent',
              tooltipContent: 'Download excel report for Port Agent',
            },
          ],
        },
      ]
    : commonOptions;
};

function ActionMenu({
  isOpen,
  isCompleted,
  fetching,
  wizardView,
  userId,
  summary,
  onOpen,
}: Props) {
  const dispatch = useAppDispatch();
  const { saveReadOnlyExcelReport } = useSavedPlanExport();

  const {
    uuid,
    creatorId,
    userNotes: { title },
  } = summary;
  const isOwnPlan = userId === creatorId;

  const { modal, setModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const options = useMemo(
    () => getOptions(isOwnPlan, isCompleted),
    [isOwnPlan, isCompleted]
  );
  const buttonDisabled = fetching || isOpen;

  const handleRemoveCrewChangeplan = useCallback(async () => {
    const { success, message } = await deleteCrewChangePlan(uuid);
    if (success) {
      dispatch(removePlanFromStore({ summary, isCompleted }));
    }
    showToaster({
      message,
      type: success ? 'success' : 'error',
      testId: 'e2e_delete-plan-success-toaster',
    });
  }, [uuid, summary, isCompleted]); // eslint-disable-line

  const handleCopyLink = useCallback(() => {
    const shareableLink = formatReadOnlyDataLink(uuid);
    if (shareableLink && navigator.clipboard) {
      navigator.clipboard.writeText(shareableLink);
      showToaster({
        message: 'Copied the link to clipboard',
        testId: 'e2e_plan-copy-link-toaster',
      });
      return;
    }
    showToaster({
      message: 'Failed to copy the link',
      type: 'info',
    });
  }, [uuid]);

  const handleDownloadReport = useCallback(
    async (isPortAgent?: boolean) => {
      const reportData = await prepareReadOnlyData(uuid);
      if (reportData) {
        setModal('downloadPlan', { reportData, isPortAgent });
        return;
      }
      showToaster({
        message: "Plan details aren't available. Cannot be downloaded.",
        type: 'error',
      });
    },
    [uuid, setModal]
  );

  const handleClickAction = (option: string) => {
    if (wizardView) return;

    switch (option) {
      case 'Delete': {
        setModal('confirmDelete');
        break;
      }
      case 'View': {
        onOpen();
        break;
      }
      case 'Copy Link': {
        handleCopyLink();
        break;
      }
      default:
        break;
    }
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    const closeModal = () => setModal(null);

    if (modal.type === 'confirmDelete') {
      const description = `The plan titled as "${title}" will be removed - with it's flights, filters, and settings.`;
      return (
        <CommonConfirmModal
          type="remove"
          description={description}
          onConfirm={handleRemoveCrewChangeplan}
          onCancel={closeModal}
        />
      );
    }

    if (modal.type === 'downloadPlan') {
      const { reportData, isPortAgent } = modal.data;
      const description = `The plan titled as "${title}" will be saved in Excel format (to the download location set in your browser).`;
      return (
        <CommonConfirmModal
          description={description}
          onConfirm={() => {
            saveReadOnlyExcelReport(reportData, isPortAgent);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    }
  };

  return (
    <>
      <MenuButton
        $wizardView={wizardView}
        className={`e2e_plan-option-${
          isCompleted ? 'completed' : 'incomplete'
        }`}
        variant="primary"
        disabled={buttonDisabled}
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();
          setAnchorEl(event.target as HTMLElement);
        }}
      >
        {fetching ? 'Loading' : (isOpen && 'Opened') || 'Options'}
        {!buttonDisabled && (
          <KeyboardArrowDownIcon sx={{ marginLeft: '0.5rem' }} />
        )}
      </MenuButton>

      <Menu
        id="fade-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{ marginTop: '1px' }}
      >
        {options.map(({ content, testId, tooltip, nestedOptions }) => {
          if (nestedOptions) {
            return (
              <StyledNestedMenuItem
                key={content}
                data-id={testId}
                parentMenuOpen
                rightIcon={null}
                label={content.toUpperCase()}
                leftIcon={<StyledLeftIcon />}
                // prevent from propagating & opening the report in CC panel
                onClick={(e: SyntheticEvent) => e.stopPropagation()}
                // position nested menu to the left
                MenuProps={{
                  anchorOrigin: { vertical: 'center', horizontal: 'left' },
                  transformOrigin: { vertical: 'center', horizontal: 'right' },
                }}
                sx={getMenuItemStyles()}
              >
                {nestedOptions.map(({ text, testId = '', tooltipContent }) => (
                  <Tooltip
                    key={text}
                    placement="left"
                    enterDelay={250}
                    content={tooltipContent}
                  >
                    <MenuItem
                      data-id={testId}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownloadReport(text === 'Port Agent');
                        setAnchorEl(null);
                      }}
                      sx={getMenuItemStyles()}
                    >
                      {text.toUpperCase()}
                    </MenuItem>
                  </Tooltip>
                ))}
              </StyledNestedMenuItem>
            );
          }

          return (
            <Tooltip
              key={content}
              placement="left"
              enterDelay={250}
              content={tooltip}
            >
              <MenuItem
                data-id={testId}
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickAction(content);
                  setAnchorEl(null);
                }}
                sx={getMenuItemStyles(content === 'Delete')}
              >
                {content.toUpperCase()}
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>

      {renderModal()}
    </>
  );
}

export default memo(ActionMenu);
