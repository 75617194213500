import { searchEngine } from 'utils/search-engine';
import {
  CustomPortSearchCommand,
  ParsedSearchType,
  SearchResult,
} from 'utils/types';
import { OtherCommands } from 'components/FlotillaSearch/helpers';
import { ALL_COMMANDS_STR } from './common';

export function resultTermHistogram(
  results: SearchResult[]
): Map<string, number> {
  const res = new Map();

  for (const result of results) {
    for (const row of result.results.results) {
      let v: string | undefined = row.match;

      if (!v) {
        for (const k in row.field) {
          if (k === 'idField') continue;
          if (!row.hasOwnProperty(k)) continue;
          if (typeof row.field[k] !== 'string' || !row.field[k]) continue;
          v = row.field[k] as string;
          break;
        }
      }

      if (!v) break;
      // Include full strings
      const prevCount = res.get(v.toLowerCase()) || 0;
      res.set(v.toLowerCase(), prevCount + 1);

      const tokens = tokenize(v);
      for (const t2 of tokens) {
        const token = t2.toLowerCase();
        const prevCount = res.get(token) || 0;
        res.set(token, prevCount + 1);
      }
    }
  }

  return res;
}

function tokenize(s: string | string[]): string[] {
  // Include alphabets, numbers and \
  if (Array.isArray(s))
    return s.reduce(
      (acc, cur) => acc.concat(cur.split(/[^a-zA-Z0-9/]+/)),
      [] as string[]
    );
  else return s.split(/[^a-zA-Z0-9/]+/);
}

function preparePortSearchMetaData(
  type: CustomPortSearchCommand,
  command: string,
  inputStr: string
) {
  const search =
    inputStr.trim() !== command
      ? inputStr.replace(`${command}:`, '').trimStart()
      : ''; // prevent showing command twice when typing
  return {
    type,
    meta: {
      possibleCommands: [command],
      command,
      search,
    },
  };
}

function stringParser(inputStr: string): ParsedSearchType {
  const trimmedStr = inputStr.trimStart();
  const tokens = tokenize(trimmedStr);
  const vesselFields = searchEngine.commandCompletion(tokens[0]);
  const possibleCommand = tokens[0];
  const possibleCommands = tokens[0]
    ? Object.keys(vesselFields).map((field) => vesselFields[field].command!)
    : ALL_COMMANDS_STR;

  if (!inputStr) {
    return {
      type: 'global',
      meta: {
        possibleCommands,
        command: '',
        search: '',
      },
    };
  }

  // Check if it matches other commands
  OtherCommands.forEach((otherCommand) => {
    if (
      otherCommand.startsWith(possibleCommand) &&
      !possibleCommands.includes(otherCommand)
    ) {
      possibleCommands.push(otherCommand);
    }
  });

  let vesselField = '';
  // In cases such as nextport, it can also match nextportcode
  if (Object.keys(vesselFields).length > 1) {
    Object.keys(vesselFields).forEach((eachVesselField) => {
      if (vesselFields[eachVesselField].command === possibleCommand) {
        vesselField = eachVesselField;
      }
    });
  }

  if (Object.keys(vesselFields).length === 1) {
    if (inputStr.trim().includes(`${possibleCommand}:`)) {
      vesselField = Object.keys(vesselFields)[0];
    }
  }
  let search = inputStr;
  if (vesselField) {
    search = inputStr.replace(`${possibleCommand}:`, '').trim();
    return {
      type: 'vesselCommand',
      vessel: {
        field: vesselField,
      },
      meta: {
        possibleCommands: possibleCommands.filter(
          (command) => command !== possibleCommand
        ),
        command: possibleCommand,
        search: search,
      },
    };
  }

  // hotels search is a 2-step process
  // initial search based on input is similar to `restrictions` search
  const customPortSearchFields = ['restrictions', 'hotels', 'futureport'];
  if (customPortSearchFields.includes(possibleCommand)) {
    const type =
      possibleCommand === 'restrictions'
        ? 'portRestrictions'
        : (possibleCommand === 'hotels' && 'hotelSearch') || 'futureport';
    return preparePortSearchMetaData(type, possibleCommand, inputStr);
  }

  if (possibleCommand === 'quickfly') {
    const updatedStr = !inputStr.includes(':') ? `${inputStr}:` : inputStr;
    search = updatedStr.replace(`${possibleCommand}:`, '').trim();
    return {
      type: 'searchFlights',
      meta: {
        possibleCommands: ['quickfly'],
        command: 'quickfly',
        search,
      },
    };
  }

  if (possibleCommand === 'deviation') {
    const updatedStr = !inputStr.includes(':') ? `${inputStr}:` : inputStr;
    search = updatedStr.replace(`${possibleCommand}:`, '').trim();
    return {
      type: 'deviation',
      meta: {
        possibleCommands: ['deviation'],
        command: 'deviation',
        search,
      },
    };
  }

  if (possibleCommand === 'port') {
    const updatedStr = !inputStr.includes(':') ? `${inputStr}:` : inputStr;
    search = updatedStr.replace(`${possibleCommand}:`, '').trimStart();
    return {
      type: 'portMap',
      meta: {
        possibleCommands: ['port'],
        command: 'port',
        search,
      },
    };
  }

  return {
    type: 'global',
    meta: {
      possibleCommands,
      command: '',
      search: inputStr,
    },
  };
}
export default stringParser;
