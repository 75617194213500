import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type { CrewChangePlanUserNotes } from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { saveCrewChangeData, updateCrewChangeData } from 'api/flotilla';
import { selectCrewChangeEvent, updateSavedCCPlans } from 'redux/actions';
import {
  selectCrewChangePanel,
  selectMapVessels,
  selectSettings,
} from 'redux/selectors';
import { RootState } from 'redux/types';
import { formatVesselPlansOnSave } from 'redux/helpers/crewChangeResources';
import { formatDate } from 'utils/format-date';
import { formatReadOnlyDataLink } from 'utils/crew-change-events';
import { validateCrewChangeData } from 'utils/validation/crew-change';
import {
  getSaveableCCReportData,
  sanitizeOlderRoutePortsData,
} from 'components/CrewChangePanel/helpers/readOnly';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { getTravelCost } from 'components/CrewChangePanel/helpers';
import {
  ReadOnlyPlanningData,
  SaveableCCReportData,
} from 'components/CrewChangePanel/types';

import useAppDispatch from './useAppDispatch';

type SaveReportProps = {
  userNotes: CrewChangePlanUserNotes;
  reportData?: ReadOnlyPlanningData;
  selectedLocode?: string; // available if only one port data is being saved
};

const initialStatus = {
  loading: false,
  success: true,
  message: '',
};

const useReadOnlyPlanningData = () => {
  const dispatch = useAppDispatch();
  const {
    userInfo,
    crewChange: {
      costParams: { costOptions },
    },
  } = useSelector(selectSettings);
  const hotelResults = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.hotelResults
  );
  const plans = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.plans
  );
  const {
    vesselId,
    isOneClickPlan,
    event: crewEvent = {},
    readOnlyPlanningData: reportData,
  } = useSelector(selectCrewChangePanel);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const {
    planningData,
    portParams: portFilters,
    filters,
    updateTableState,
    updatePlanningData,
  } = useContext(CCPanelContext);

  const [savingStatus, setSavingStatus] = useState(initialStatus);

  const vessel = useMemo(() => vessels.get(vesselId!), [vesselId, vessels]);
  const crewCount = planningData.crew.length;
  const commonCCPanelInfo = useMemo(
    () => ({
      event: (crewEvent as CrewEvent)!,
      planningData,
      allFilters: filters,
      portFilters,
      hotelResults,
    }),
    [crewEvent, planningData, filters, portFilters, hotelResults]
  );

  const handleUpdateStatus = useCallback(
    (success: boolean, message: string) => {
      setSavingStatus({ loading: false, success, message });
    },
    []
  );

  const saveReadOnlyData = useCallback(
    async ({
      userNotes,
      reportData: existingReportData,
      selectedLocode,
    }: SaveReportProps) => {
      if (!vessel) {
        return;
      }

      let reportDetails: SaveableCCReportData;

      setSavingStatus((prev) => ({
        ...prev,
        loading: true,
        message: 'Saving data & creating a shareable link.',
      }));

      // available for saving an existing report update
      if (existingReportData) {
        const { crewChangePlan, summary } = existingReportData;
        reportDetails = { crewChangePlan, userNotes, summary };
      } else {
        const selectedFilters = selectedLocode
          ? { [selectedLocode]: filters[selectedLocode] }
          : filters;
        const { ports } = planningData;
        const filteredPorts = selectedLocode
          ? ports.filter(({ locode }) => locode === selectedLocode)
          : ports;
        const ccPanelInfo = {
          ...commonCCPanelInfo,
          planningData: { ...planningData, ports: filteredPorts },
          allFilters: selectedFilters,
        };

        reportDetails = await getSaveableCCReportData(
          ccPanelInfo,
          vessel,
          userNotes,
          getTravelCost(costOptions)
        );
      }

      const validated = validateCrewChangeData(reportDetails);
      if (!validated) {
        handleUpdateStatus(false, 'Data failed validation. Cannot be copied.');
        return;
      }

      const ccPlanRequest = {
        ...validated,
        isCompleted: true,
        flotillaVesselId: vessel.id,
      };
      const { success, response } = await saveCrewChangeData(ccPlanRequest);
      if (!success || !response) {
        handleUpdateStatus(false, 'Failed to create the link.');
        return;
      }

      const { uuid: id, summary } = response;
      if (!id) {
        handleUpdateStatus(false, 'Failed to copy a shareable link!');
        return;
      }

      // update saved plans list with the newly shared crew-change plan
      dispatch(
        updateSavedCCPlans({
          [vessel.id]: formatVesselPlansOnSave(
            response,
            plans?.[vessel.id],
            userInfo
          ),
        })
      );
      handleUpdateStatus(true, 'Created a shareable link successfully.');
      return {
        link: formatReadOnlyDataLink(id),
        summary,
      };
    },
    [
      costOptions,
      dispatch,
      filters,
      commonCCPanelInfo,
      planningData,
      plans,
      userInfo,
      vessel,
      handleUpdateStatus,
    ]
  );

  const startNewCrewChange = useCallback(() => {
    const { crew = [], route = [] } = reportData?.crewChangePlan || {};
    const sanitizedRoute = sanitizeOlderRoutePortsData(route);
    const newPlanningData = {
      crew,
      route: sanitizedRoute,
      flights: [],
      ports: [],
    };

    updatePlanningData(newPlanningData);
    updateTableState({ type: 'step', payload: 'route' });
    dispatch(
      selectCrewChangeEvent({
        event: crewEvent as CrewEvent,
        vesselId: vesselId!,
        active: 'plan',
      })
    );
  }, [
    vesselId,
    crewEvent,
    updateTableState,
    reportData?.crewChangePlan,
    dispatch,
    updatePlanningData,
  ]);

  // method to save incomplete crew-change plan when crew-change panel step is updated
  // except for `crew` step
  // in order override an existing crew-change plan pass the plan ID from crew-change planning
  const saveIncompletePlan = useCallback(
    async (planId?: string) => {
      if (isOneClickPlan || !vessel) return;

      const userNotes = {
        title: `Plan for ${crewCount} crew at ${formatDate(
          new Date(),
          'HH:mm, DD MMM, YY'
        )}`,
        notes: '',
      };

      const reportDetails = await getSaveableCCReportData(
        commonCCPanelInfo,
        vessel,
        userNotes,
        getTravelCost(costOptions)
      );

      const validated = validateCrewChangeData(reportDetails);
      if (!validated) {
        return;
      }

      const ccPlanRequest = {
        ...validated,
        isCompleted: false,
        flotillaVesselId: vessel.id,
      };

      const { success, response: result } = planId
        ? await updateCrewChangeData(planId, ccPlanRequest)
        : await saveCrewChangeData(ccPlanRequest);

      if (!success || !result) {
        return;
      }
      return result.uuid;
    },
    [isOneClickPlan, crewCount, vessel, commonCCPanelInfo, costOptions]
  );

  return {
    savingStatus,
    saveReadOnlyData,
    saveIncompletePlan,
    startNewCrewChange,
  };
};

export default useReadOnlyPlanningData;
