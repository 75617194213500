import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';

import { blue } from 'lib/colors';
import { Tooltip } from 'components/shared';
import { ActionButton } from '../../common';
import { CustomAlertResponse } from '../../types';

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledButton = styled(ActionButton)`
  min-height: unset;
  color: ${blue};
  font-size: 0.7rem;
  height: 28px;
`;

type Props = {
  loading: boolean;
  response: CustomAlertResponse | null;
  fetchMore: () => void;
};

function LoadMore({ loading, response, fetchMore }: Props) {
  const loadingNewItems = Boolean(loading && response?.results?.length);
  const canLoadMore = Boolean(response?.loadMore && !loading);

  if (!response?.results?.length) {
    return null;
  }

  if (loadingNewItems) {
    return (
      <ProgressWrapper>
        <CircularProgress size={20} />
      </ProgressWrapper>
    );
  }

  return canLoadMore ? (
    <Wrapper>
      <Tooltip content="Click to load more">
        <StyledButton variant="secondary" onClick={fetchMore}>
          Load More Alerts
        </StyledButton>
      </Tooltip>
    </Wrapper>
  ) : null;
}

export default LoadMore;
