import { Loader } from 'components/shared';
import AddCrewButton from './AddCrewButton';
import { NoRowsOverlayProps } from './types';
import { EmptyText, EmptyWrapper } from '../../common';

function NoRowsOverlay({ type, loading, ...rest }: NoRowsOverlayProps) {
  return (
    <EmptyWrapper>
      {loading ? (
        <Loader size={150} />
      ) : (
        <>
          <EmptyText>{`No ${type} available.`}</EmptyText>
          {type === 'crew' && <AddCrewButton {...rest} />}
        </>
      )}
    </EmptyWrapper>
  );
}

export default NoRowsOverlay;
