import map from 'lodash/map';
import partition from 'lodash/partition';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { textGray } from 'lib/colors';
import { BREAK_POINT_XS, BREAK_POINT_M } from 'lib/breakpoints';
import { selectCrewChangeEvent } from 'redux/actions';
import { selectCrewChangePanel } from 'redux/selectors';
import { formatDate } from 'utils/format-date';
import { CrewChangeActivityType } from 'utils/types/crew-change-types';

import { Button, CloseButton } from 'components/shared';
import { HeaderTitle } from '../common';
import { hideCrewPlanning } from '../helpers';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(FlexWrapper)`
  justify-content: space-between;
  height: 60px;
  padding: 0.5rem 1rem;
  overflow-x: auto;
  overflow-y: hidden;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    height: unset;
  }
`;

const HeaderDetails = styled(FlexWrapper)`
  flex-direction: column;
`;

const SubHeader = styled(FlexWrapper)`
  align-items: baseline;
  @media screen and (max-width: ${BREAK_POINT_M}) {
    align-self: stretch;
  }
`;

const EventContainer = styled(FlexWrapper)`
  align-items: baseline;
  margin-right: 10px;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-direction: column;
  }
`;

const Label = styled.div`
  margin-right: 5px;
  font-size: 0.6rem;
  font-weight: 700;
  color: ${textGray};
`;

const Value = styled.div`
  margin-right: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  margin-right: 10px;
  align-self: start;
`;

const StyledButton = styled(Button)`
  height: 25px;
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 20px;
`;

// component used for crew details view in crew change panel
function CrewDetails({ event }: { event: CrewEvent }) {
  const dispatch = useAppDispatch();
  const ccPanelInfo = useSelector(selectCrewChangePanel);
  const { vesselId, readOnlyPlanningData: reportData } = ccPanelInfo;

  const [onsignerCount, offsignerCount] = useMemo(
    () =>
      reportData
        ? map(
            partition(
              reportData.crewChangePlan.crew,
              ({ type }) => type.toLowerCase() === 'onsigner'
            ),
            (list) => list.length
          )
        : [event.onsigners || 0, event.offsigners || 0],
    [event, reportData]
  );

  const handleSelectEvent = useCallback((reset: boolean) => {
    const payload =
      vesselId && !reset
        ? { vesselId, event, active: 'plan' as CrewChangeActivityType }
        : undefined;
    dispatch(selectCrewChangeEvent(payload));
  }, []); // eslint-disable-line

  return (
    <Wrapper>
      <HeaderDetails>
        <HeaderTitle>{`Crew Change at ${event.name || 'Unnamed'}`}</HeaderTitle>

        <SubHeader>
          <EventContainer>
            <Label>ETA</Label>
            <Value>{formatDate(event.eta)}</Value>
          </EventContainer>
          <EventContainer>
            <Label>ETD</Label>
            <Value>{formatDate(event.etd)}</Value>
          </EventContainer>
          <EventContainer>
            <Value>{onsignerCount}</Value>
            <Label>ON</Label>
          </EventContainer>
          <EventContainer>
            <Value>{offsignerCount}</Value>
            <Label>OFF</Label>
          </EventContainer>
        </SubHeader>
      </HeaderDetails>

      <ButtonsWrapper>
        {!hideCrewPlanning(ccPanelInfo) && (
          <StyledButton
            variant="primary"
            className="crew-change-plan"
            onClick={() => handleSelectEvent(false)}
          >
            Plan
          </StyledButton>
        )}
        <CloseButton size={20} onClick={() => handleSelectEvent(true)} />
      </ButtonsWrapper>
    </Wrapper>
  );
}

export default CrewDetails;
