import { UserPICInfo } from 'utils/types';

export const getUserLabel = (userInfo: UserPICInfo) => {
  let userData = [];

  if (userInfo.firstname) userData.push(userInfo.firstname);
  if (userInfo.lastname) userData.push(userInfo.lastname);

  if (userInfo.lastname || userInfo.firstname)
    userData.push(`(${userInfo.loginEmail})`);
  else userData.push(userInfo.loginEmail);

  return userData.join(' ');
};
