import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { triggerCCPanelCloseActions } from 'components/CrewChangePanel/helpers';
import { ReadOnlyPlanningData } from 'components/CrewChangePanel/types';
import {
  CCPanelPayload,
  CCPanelInfo,
  FutureRoute,
  RootState,
  ReadOnlyUpdatePayload,
  CCPanelColumnVisibility,
} from '../types';

export const initialCCPanel: CCPanelInfo = {
  active: '',
  futureRoute: [],
  readOnlyPlanningData: null,
  columnVisibility: null,
};

// actions for `sidePanel` & `mapVessels` reducer
const removeVessel = createAction<number>('sidePanel/removeVessel');
const clearSidePanelVessels = createAction('sidePanel/clearSidePanelVessels');

const crewChangePanelSlice = createSlice({
  name: 'crewChangePanel',
  initialState: initialCCPanel,
  reducers: {
    selectCrewChangeEvent: (
      state,
      action: PayloadAction<CCPanelPayload | undefined>
    ) => {
      const {
        active = '',
        event = {} as CrewEvent,
        vesselId,
        isOneClickPlan,
      } = action.payload || {};

      if (!vesselId) {
        // when panel is removed or reset with another event
        triggerCCPanelCloseActions(true);
      }

      return {
        ...state,
        ...(vesselId ? {} : { readOnlyPlanningData: null }),
        vesselId,
        active,
        event,
        isOneClickPlan,
      };
    },
    setVesselJourney: (state, action: PayloadAction<FutureRoute>) => ({
      ...state,
      futureRoute: action.payload,
    }),
    setReadOnlyPlanningData: (
      state,
      action: PayloadAction<ReadOnlyPlanningData | null>
    ) => ({
      ...state,
      readOnlyPlanningData: action.payload,
    }),
    stopOneClickCrewChange: (state) => ({ ...state, isOneClickPlan: false }),
    updateCrewChangeReport: (
      state,
      action: PayloadAction<ReadOnlyUpdatePayload>
    ) => {
      const { locodeKey, flights } = action.payload;
      const { readOnlyPlanningData: reportData } = state;
      const { crewChangePlan } = reportData!;
      return {
        ...state,
        readOnlyPlanningData: {
          ...(reportData as ReadOnlyPlanningData),
          crewChangePlan: {
            ...crewChangePlan,
            flights: {
              ...(crewChangePlan?.flights || {}),
              [locodeKey]: flights,
            },
          },
        },
      };
    },
    updateColumnVisibility: (
      state,
      action: PayloadAction<CCPanelColumnVisibility>
    ) => ({
      ...state,
      columnVisibility: {
        ...state.columnVisibility,
        ...action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeVessel, (state, action) =>
        // remove CC panel when vessel is removed from side-panel
        action.payload === state.vesselId ? initialCCPanel : state
      )
      .addCase(clearSidePanelVessels, () => initialCCPanel);
  },
});

/* ----- selectors -----*/

export const selectCrewChangePanel = ({ crewChangePanel }: RootState) =>
  crewChangePanel;

/* ----- actions -----*/

export const {
  selectCrewChangeEvent,
  setVesselJourney,
  setReadOnlyPlanningData,
  stopOneClickCrewChange,
  updateCrewChangeReport,
  updateColumnVisibility,
} = crewChangePanelSlice.actions;

/* ----- reducer -----*/

export default crewChangePanelSlice.reducer;
