import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useAppDispatch from './useAppDispatch';
import { setCanAddFilter } from 'redux/actions';
import { getOnboardContentAsync, getOnboardStepsAsync } from 'redux/thunks';
import { RootState } from 'redux/types';

function useOnboard() {
  const dispatch = useAppDispatch();
  const vessels = useSelector(
    ({ mapVessels }: RootState) => mapVessels.vesselsFull
  );
  const canAddFilter = useSelector(
    ({ onboard }: RootState) => onboard.canAddFilter
  );

  // initial fetch from backend
  useEffect(() => {
    // fetch content
    dispatch(getOnboardContentAsync());

    // fetch completed steps
    dispatch(getOnboardStepsAsync());
  }, []); // eslint-disable-line

  // check if the following is satisfied before allowing certain onboard steps
  useEffect(() => {
    if (canAddFilter) return;
    if (vessels.size) {
      dispatch(setCanAddFilter(true));
    }
  }, [vessels, canAddFilter, dispatch]);

  return null;
}

export default useOnboard;
