import { LayoverDetails } from 'components/FlotillaSearch/types';
import { FlightSegment } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

export type VesselDetails = {
  imo: string;
  name: string;
  owner: string;
  destination: string;
  eta: string;
  etd: string;
  type: string;
};

export type CrewDetails = {
  id: number;
  cid: string;
  name: string;
  rank: string;
  manningOffice: string;
  type: string; // onsigner or offsigner
  country: string;
  flight: string;
  time: string;
  departure: string;
  arrival: string;
  cost: string;
  sex: string;
  birthday: string;
  passportNumber: string;
  passportExpiry: string;
  visaCountry: string;
  visaExpiry: string;
  flightNumbers: string;
  flightLayoverTime: string;
  layoverDetails: LayoverDetails[];
  flightFareType: string;
  passportIssued: string;
  seamenBookNumber: string;
  seamenBookExpiry: string;
  seamenBookIssued: string;
  seamenBookIssuedCountryIso3: string;
  segments: FlightSegment[];
  classCodes: string;
  reason: string;
};

export type CrewWithPassport = CrewDetails & {
  file: File | null;
  downloaded: boolean;
};

export type VesselSelectedFields = { [key in keyof VesselDetails]: boolean };
export type CrewSelectedFields = { [key in keyof CrewDetails]: boolean };
export type SelectedFields = {
  vessel: VesselSelectedFields;
  crew: CrewSelectedFields;
};

export interface FieldConfig<T> {
  label: string;
  valueGetter: (t: T) => string;
}

export type VesselEmailFieldConfig = {
  [key in keyof VesselDetails]: FieldConfig<VesselDetails>;
};
export type CrewEmailFieldConfig = {
  [key in keyof CrewDetails]: FieldConfig<CrewDetails>;
};

export const vesselDetailFields: Array<keyof VesselDetails> = [
  'imo',
  'name',
  'owner',
  'destination',
  'eta',
  'etd',
  'type',
];

export const crewDetailFields: Array<keyof CrewDetails> = [
  'cid',
  'type',
  'name',
  'rank',
  'manningOffice',
  'country',
  'flight',
  'time',
  'arrival',
  'departure',
  'reason',
  'cost',
  'sex',
  'birthday',
  'passportNumber',
  'passportExpiry',
  'visaCountry',
  'visaExpiry',
  'flightNumbers',
  'classCodes',
  'flightFareType',
  'passportIssued',
  'seamenBookNumber',
  'seamenBookExpiry',
  'seamenBookIssued',
  'seamenBookIssuedCountryIso3',
];

export const vesselFieldConfig =
  vesselDetailFields.reduce<VesselEmailFieldConfig>((acc, field) => {
    return {
      ...acc,
      [field]: {
        label: field.toUpperCase(),
        valueGetter: (vessel: VesselDetails) => vessel[field],
      },
    };
  }, {} as VesselEmailFieldConfig);

export const crewFieldConfig = crewDetailFields.reduce<CrewEmailFieldConfig>(
  (acc, field) => {
    let label: string = field;
    switch (field) {
      case 'passportNumber':
        label = 'PP No.';
        break;
      case 'passportExpiry':
        label = 'PP Expiry';
        break;
      case 'visaCountry':
        label = 'Visa Country';
        break;
      case 'visaExpiry':
        label = 'Visa Expiry';
        break;
      case 'flightNumbers':
        label = 'Flight No.';
        break;
      default:
        break;
    }
    return {
      ...acc,
      [field]: {
        label: label.toUpperCase(),
        valueGetter: (crew: CrewDetails) => crew[field],
      },
    };
  },
  {} as CrewEmailFieldConfig
);

export type Company = { id: number; name: string } | null;

export type EmailDetailsInfo = {
  company?: Company;
  vesselDetails: VesselDetails;
  crewDetails: CrewDetails[];
};

export type EmailDetails = EmailDetailsInfo & {
  subject: string;
  message: string;
};

export type PackagePassportInput = {
  crewPassports: { [crewId: number]: CrewWithPassport };
  passportsPassword: string;
};

export type EmailTemplate = 'book-flight' | 'port-agency-announcement';
