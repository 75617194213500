import { memo, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { TextField } from '@mui/material';
import { Alert } from '@greywing-maritime/frontend-library/dist/types/alerts';
import styled from 'styled-components/macro';

import { TableSkeleton } from 'components/shared';
import { ActionButton, InputWrapper, Text } from '../../common';
import { AlertInputType, AlertsTableProps } from '../../types';
import { alertsTableStyles, AlertsTableWrapper } from './common';
import { getAlertsColumns } from './Header';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_NU_ADD_ALERT_START_SETTINGS,
  TRACK_NU_ADD_ALERT_ADD_SETTINGS,
  TRACK_NU_ADD_ALERT_DISMISS_SETTINGS,
} from 'utils/analytics/constants';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Wrapper = styled.div`
  width: 96%;
`;

const LoaderWrapper = styled.div`
  margin-top: 1rem;
`;

const CreateButton = styled(ActionButton)`
  margin-top: 1rem;
`;

const StyledInputWrapper = styled(InputWrapper)`
  width: 96%;
  display: grid;
  grid-template-columns: 3.5fr 2fr 0.25fr 0.25fr;
  grid-gap: 2px;
  @media only screen and (max-width: ${BREAK_POINT_XS}) {
    grid-template-columns: 2.5fr 2fr;
    grid-row-gap: 6px;
  }
`;

const StyledNotificationsIcon = styled(NotificationsIcon)`
  font-size: 18px !important;
`;

const inputStyles = {
  style: { fontSize: '0.9rem' },
};

type Props = AlertsTableProps & {
  createNotification: (alertInput: AlertInputType) => Promise<Alert | null>;
} & {
  showError: boolean;
};

function NotificationAlertsTable({
  loading,
  count,
  alerts,
  setModal,
  createNotification,
  processRowUpdate,
  showError,
}: Props) {
  const [formOpen, setFormOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [alertInput, setAlertInput] = useState<AlertInputType>({
    textFilter: '',
    notifyFreqHrs: 3,
  });
  const loadingInitial = Boolean(loading && !alerts?.length);
  const [errorMessage, setErrorMessage] = useState<string | null>('');

  if (loadingInitial) {
    return (
      <>
        <Text>Loading notification alerts.</Text>
        <LoaderWrapper>
          <TableSkeleton rows={2} />
        </LoaderWrapper>
      </>
    );
  }

  const updateAlertInput = (type: string) => (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    if (type === 'notifyFreqHrs') {
      const val = Number(value);
      if (isNaN(val)) setErrorMessage('Invalid value!');
      else if (val > 720) setErrorMessage(`Can't be greater than 720`);
      else if (val < 1) setErrorMessage(`Can't be less than 1`);
      else setErrorMessage(null);
      setAlertInput((prev) => ({ ...prev, [type]: val }));
    } else setAlertInput((prev) => ({ ...prev, [type]: value }));
  };

  const handleReset = () => {
    setFormOpen(false);
    setAlertInput({
      textFilter: '',
      notifyFreqHrs: 3,
    });
  };

  const handleCreate = async () => {
    trackUserAction(TRACK_NU_ADD_ALERT_ADD_SETTINGS, 'click', {
      value: alertInput.textFilter,
      frequency: alertInput.notifyFreqHrs,
    });
    setSubmitting(true);
    await createNotification(alertInput);
    setSubmitting(false);
    handleReset();
  };

  if (!count) {
    const disabled =
      !alertInput.textFilter.trim() || submitting || !!errorMessage;
    return (
      <Wrapper data-id="notifications_container">
        <Text>It looks like you haven't subscribed to any notification!</Text>
        {formOpen ? (
          <StyledInputWrapper>
            <TextField
              size="small"
              label="Description"
              placeholder="Notification Detail"
              value={alertInput.textFilter}
              onChange={updateAlertInput('textFilter')}
              inputProps={inputStyles}
              sx={{ mr: 1 }}
            />
            <TextField
              size="small"
              type="number"
              label="Frequency"
              placeholder="Frequency (Hours)"
              value={alertInput.notifyFreqHrs}
              onChange={updateAlertInput('notifyFreqHrs')}
              inputProps={inputStyles}
              sx={{ mr: 1 }}
              error={!!errorMessage}
              helperText={errorMessage}
            />
            <ActionButton
              style={{ maxHeight: '2.28rem' }}
              className="settings_add-notification"
              disabled={disabled}
              onClick={handleCreate}
            >
              <StyledNotificationsIcon />
              Add
            </ActionButton>
            <ActionButton
              style={{ maxHeight: '2.28rem' }}
              variant="dark"
              onClick={() => {
                trackUserAction(TRACK_NU_ADD_ALERT_DISMISS_SETTINGS);
                handleReset();
              }}
            >
              Cancel
            </ActionButton>
          </StyledInputWrapper>
        ) : (
          <CreateButton
            onClick={() => {
              trackUserAction(TRACK_NU_ADD_ALERT_START_SETTINGS);
              setFormOpen(true);
            }}
          >
            Add Your First Notification
          </CreateButton>
        )}
      </Wrapper>
    );
  }

  if (!alerts?.length) {
    return (
      <Wrapper data-id="notifications_container">
        <Text>Load more to fetch notification alerts.</Text>
      </Wrapper>
    );
  }

  const columns = getAlertsColumns('UPDATE_FILTER', setModal, showError);

  return (
    <Wrapper data-id="notifications_container">
      <Text>These are the notifications you have created.</Text>
      <AlertsTableWrapper data-id="notifications-table">
        <DataGridPro
          rows={alerts}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          hideFooterPagination
          autoHeight
          density="compact"
          sx={alertsTableStyles}
          // allow updating frequency cell
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
        />
      </AlertsTableWrapper>
    </Wrapper>
  );
}

export default memo(NotificationAlertsTable);
