import { useMediaQuery } from 'react-responsive';

import { BREAK_POINT_SM } from 'lib/breakpoints';

function useMobile(width: string = BREAK_POINT_SM) {
  const isMobile = useMediaQuery({ query: `(max-width: ${width})` });

  return isMobile;
}

export default useMobile;
