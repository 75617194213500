import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import {
  useFlightSearch,
  useHotelsSearch,
  usePortRestrictionSearch,
  usePlannedPortSearch,
  useE2ETests,
  usePortMapSearch,
} from 'hooks';
import { setHighlightedVessels } from 'redux/actions';
import { selectFlotillaSearch, selectSettings } from 'redux/selectors';
import { useVesselSearch } from 'hooks';
import { useAppDispatch } from 'hooks';
import stringParser from 'utils/search-engine/parser';
import {
  FlotillaSearchState,
  HotelsSearchResult,
  ParsedSearchType,
  SearchResult,
} from 'utils/types';
import { ETA_SLIDER_COMMANDS } from 'utils/search-engine/common';
import { SEARCHBOX_EXPAND_ANIMATION } from 'components/FlotillaSearch/common';
import {
  AllSearchResultType,
  VesselSearchResultProp,
} from 'utils/types/search';
import { getHotelSearchResultIds } from 'components/FlotillaSearch/helpers';

type SearchContextValue = {
  inputStr: string;
  isCompletelyExpanded: boolean;
  isLoading: boolean;
  resultIds: string[];
  focusedId: string;
  searchType: ParsedSearchType;
  searchState: FlotillaSearchState;
  results: AllSearchResultType[];
  autoComplete: string;
  focusInput: boolean;
  setSearchType: Dispatch<SetStateAction<ParsedSearchType>>;
  setFocusInput: Dispatch<SetStateAction<boolean>>;
  setFocusedId: Dispatch<SetStateAction<string>>;
  setInputStr: Dispatch<SetStateAction<string>>;
  setSearchState: Dispatch<SetStateAction<FlotillaSearchState>>;
  setAutoComplete: Dispatch<SetStateAction<string>>;
};

export const SearchContext = createContext({} as SearchContextValue);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const { searchIsExpanded, searchReady } = useSelector(selectFlotillaSearch);
  const [inputStr, setInputStr] = useState<string>('');
  const [isCompletelyExpanded, setIsCompletelyExpanded] =
    useState<boolean>(false);
  const [autoComplete, setAutoComplete] = useState<string>('');
  const [searchState, setSearchState] = useState<FlotillaSearchState>('input');
  const [searchType, setSearchType] = useState<ParsedSearchType>({
    type: 'global',
    meta: {
      possibleCommands: [],
      command: '',
      search: '',
    },
  });
  const [focusedId, setFocusedId] = useState<string>('');
  const [resultIds, setResultIds] = useState<string[]>([]);
  const [focusInput, setFocusInput] = useState<boolean>(false);
  const firstReady = useRef<boolean>(false);
  const { userInfo } = useSelector(selectSettings);
  const isTMC = userInfo?.type === 'tmc';

  const { isE2ETesting } = useE2ETests();

  useEffect(
    function () {
      if (searchIsExpanded) {
        setTimeout(
          () => setIsCompletelyExpanded(true),
          SEARCHBOX_EXPAND_ANIMATION
        );
      } else {
        setIsCompletelyExpanded(false);
      }
    },
    [searchIsExpanded]
  );

  useEffect(() => {
    if (firstReady.current) return;
    if (searchReady) {
      firstReady.current = true;
      setFocusInput(true);
    }
  }, [searchReady]);

  // Include vessel search
  const { results: vesselResults, isLoading: vesselIsLoading } =
    useVesselSearch({
      params: ['global', 'vesselCommand'].includes(searchType.type)
        ? searchType
        : null,
      inputStr,
    });

  // Include port restriction search
  const { results: restrictionResults, isLoading: restrictionsIsLoading } =
    usePortRestrictionSearch({
      params: searchType.type === 'portRestrictions' ? searchType : null,
    });

  // Include port map search
  const { results: portMapResults, isLoading: portMapIsLoading } =
    usePortMapSearch({
      params: searchType.type === 'portMap' ? searchType : null,
    });

  // voyage plan port search
  const { results: voyagePlanResults, loading: voyagePlanLoading } =
    usePlannedPortSearch({ inputStr });

  // flight search results
  const { results: flightResults, isLoading: flightsLoading } = useFlightSearch(
    searchType.type === 'searchFlights'
  );

  // get ports/hotels for hoel search - it's a 2 step search
  const { results: hotelsPortsResult, loading: hotelPortsLoading } =
    useHotelsSearch(searchType.type === 'hotelSearch' ? { searchType } : null);

  // Consolidate Loading
  // In the future if there are other async calls
  const isLoading = isE2ETesting
    ? false
    : restrictionsIsLoading ||
      hotelPortsLoading ||
      flightsLoading ||
      voyagePlanLoading ||
      portMapIsLoading ||
      vesselIsLoading;

  // Consolidate Results
  const processedResults: AllSearchResultType[] = useMemo(() => {
    if (searchType.type === 'futureport') {
      return voyagePlanResults ? [voyagePlanResults] : [];
    }
    if (searchType.type === 'portRestrictions') {
      return restrictionResults ? [restrictionResults] : [];
    }
    if (searchType.type === 'portMap') {
      return portMapResults ? [portMapResults] : [];
    }
    if (searchType.type === 'hotelSearch') {
      return hotelsPortsResult ? [hotelsPortsResult] : [];
    }
    if (searchType.type === 'searchFlights') {
      return flightResults ? [flightResults] : [];
    }
    const filteredVesselResults = vesselResults.reduce<
      VesselSearchResultProp[]
    >((acc, result: SearchResult) => {
      if (result.results.results.length) {
        acc.push({
          ...result,
          searchType: 'vessel',
        });
      }
      return acc;
    }, []);
    return [
      ...filteredVesselResults,
      // include planned port in common search results, i.e - search without command
      ...(voyagePlanResults ? [voyagePlanResults] : []),
    ];
  }, [
    vesselResults,
    restrictionResults,
    hotelsPortsResult,
    flightResults,
    voyagePlanResults,
    portMapResults,
    searchType.type,
  ]);

  useEffect(() => {
    if (['global', 'vesselCommand'].includes(searchType.type)) {
      const resultIds = processedResults.reduce<
        { vesselId: number; tag: string }[]
      >((acc: { vesselId: number; tag: string }[], results) => {
        if (results.searchType === 'vessel') {
          results.results.results.forEach((each) => {
            acc.push({
              vesselId: each.field.idField,
              tag: `${results.searchFieldName}-${each.field.idField}`,
            });
          });
        }
        return acc;
      }, [] as { vesselId: number; tag: string }[]);
      setResultIds(resultIds.map(({ tag }) => tag));
      dispatch(
        setHighlightedVessels(resultIds.map(({ vesselId }) => vesselId))
      );
    }

    if (searchType.type === 'futureport' && voyagePlanResults) {
      setResultIds(
        voyagePlanResults?.results.map(
          (result) => `futureport-${result.vessel.id}`
        )
      );
    }

    if (searchType.type === 'portRestrictions' && restrictionResults) {
      setResultIds(
        restrictionResults?.results.map((result) => `restrictions-${result.id}`)
      );
    }

    if (searchType.type === 'hotelSearch' && processedResults[0]?.results) {
      const searchResult = processedResults[0] as HotelsSearchResult;
      setResultIds(getHotelSearchResultIds(searchResult));
    }
  }, [processedResults, restrictionResults, voyagePlanResults]); // eslint-disable-line

  useEffect(() => {
    // This resets the focusedId everytime
    setFocusedId('');

    // Parse string
    const parsed = stringParser(inputStr.toLowerCase());
    setSearchType(parsed);
    switch (true) {
      case ETA_SLIDER_COMMANDS.includes(parsed.meta.command):
        setSearchState('slider');
        break;

      case ['quickfly', 'deviation'].includes(parsed.meta.command):
        if (!isTMC) setSearchState('text');
        break;

      // use custom state for port name or locode input
      case ['restrictions', 'hotels', 'futureport', 'port'].includes(
        parsed.meta.command
      ):
        setSearchState('port-input');
        break;

      default:
        setSearchState('input');
        break;
    }
  }, [inputStr, isTMC]);

  return (
    <SearchContext.Provider
      value={{
        autoComplete,
        inputStr,
        isCompletelyExpanded,
        isLoading,
        resultIds,
        focusedId,
        focusInput,
        setFocusedId,
        searchState,
        setAutoComplete,
        setInputStr,
        setSearchState,
        setFocusInput,
        searchType,
        setSearchType,
        results: processedResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
