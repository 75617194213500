import { ReactElement } from 'react';
import styled from 'styled-components/macro';

import { borderGray, lightGray } from 'lib/colors';

const Wrapper = styled.div<{ $backgroundColor?: string }>`
  display: inline-flex;
  height: fit-content;
  border: 1px solid ${borderGray};
  border-radius: 4px;
  ${({ $backgroundColor }) =>
    $backgroundColor ? `background-color: ${$backgroundColor};` : ''}
`;

const Text = styled.p`
  margin: 0;
  padding: 4px;
  font-size: 13px;
  border-right: 1px solid ${borderGray};
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 0 4px 4px 0;
  border: none;
  box-shadow: none;
  background-color: ${lightGray};
  font-size: 0.85rem;
  transition: background-color 150ms ease;

  &:hover {
    cursor: pointer;
    background-color: ${borderGray};
  }
`;

interface IPillProps {
  pillText: string;
  buttonIcon: ReactElement;
  onButtonClick: () => void;
  $backgroundColor?: string;
}

export default function Pill({
  pillText,
  buttonIcon,
  onButtonClick,
  $backgroundColor,
}: IPillProps) {
  return (
    <Wrapper $backgroundColor={$backgroundColor}>
      <Text>{pillText}</Text>
      <ButtonIcon onClick={onButtonClick}>{buttonIcon}</ButtonIcon>
    </Wrapper>
  );
}
