import styled from 'styled-components/macro';

import { useModal } from 'hooks';
import { fadedGreen, white } from 'lib/colors';

import { CommonConfirmModal, Tooltip } from 'components/shared';
import withPortCard from './PortCard';
import RoutePortModal from './RoutePortModal';

import { getEmptyPort, getPreviousPort } from '../../../helpers';
import { RoutePort } from '../../../types';
import AnchorIcon from '@mui/icons-material/Anchor';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const Container = styled(FlexWrapper)`
  overflow: auto;
`;

const Wrapper = styled(FlexWrapper)`
  overflow: auto;
  padding: 0.25rem 0.5rem;
`;

const ActionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1rem;
  min-height: 100%;
  width: 60px;
  word-wrap: break-word;
  background: ${fadedGreen};
  color: ${white};
  padding: 0.5rem;
  margin: 0 0.25rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 1px solid ${fadedGreen};
  border-radius: 6px;
  box-shadow: none;

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transform: translate(0, -1px);
  }
`;

const StyledAnchorIcon = styled(AnchorIcon)`
  font-size: 1.2rem !important;
  color: ${white};
  margin-bottom: 0.5rem;
`;

type Props = {
  routePorts: RoutePort[];
  addOrUpdate: (newPort: RoutePort) => void;
  remove: (order: number) => void;
};

function PlanningRouteActions({ routePorts, addOrUpdate, remove }: Props) {
  const { modal, setModal } = useModal(null);

  const handleAddPort = () => {
    setModal('routePort', {
      type: 'add',
      port: getEmptyPort(),
    });
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    const {
      port: { order },
    } = modal.data;
    const previousPort = getPreviousPort(order, routePorts);
    const closeModal = () => setModal(null);

    if (modal.type === 'routePort') {
      return (
        <RoutePortModal
          {...modal.data}
          onClick={addOrUpdate}
          closeModal={closeModal}
          previousPort={previousPort}
        />
      );
    }

    if (modal.type === 'confirmRemove') {
      return (
        <CommonConfirmModal
          type="remove"
          description="The port will be removed from the vessel route plan."
          onCancel={closeModal}
          onConfirm={() => {
            remove(order);
            closeModal();
          }}
        />
      );
    }
  };

  const tooltipContent = routePorts.length
    ? 'Add a new port in the current route'
    : 'Add a new port to create vessel route';

  return (
    <Container>
      <Tooltip content={tooltipContent}>
        <ActionButton className="e2e_add-route-port" onClick={handleAddPort}>
          <StyledAnchorIcon />
          Add New
        </ActionButton>
      </Tooltip>
      <Wrapper>
        {routePorts.map((port, index) => {
          const nextPortName =
            routePorts.length !== index + 1
              ? routePorts[index + 1]?.displayName
              : undefined;
          const PortCard = withPortCard({ port, nextPortName });
          return <PortCard key={index} setModal={setModal} />;
        })}

        {renderModal()}
      </Wrapper>
    </Container>
  );
}

function ReadOnlyRouteActions({ routePorts }: { routePorts: RoutePort[] }) {
  return (
    <Wrapper>
      {routePorts.map((port, index) => {
        const nextPortName =
          routePorts.length !== index + 1
            ? // for backward compatibility: old reports in which ports have `text` field instead of `displayName`
              // @ts-ignore
              routePorts[index + 1]?.displayName || routePorts[index + 1]?.text
            : undefined;
        const ReadOnlyPortCard = withPortCard({ port, nextPortName });
        return <ReadOnlyPortCard key={index} />;
      })}
    </Wrapper>
  );
}

export { PlanningRouteActions, ReadOnlyRouteActions };
