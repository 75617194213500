import { Tooltip } from 'components/shared';
import {
  CrewWageInfo,
  CrewWageInfoType,
} from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { Crew } from 'utils/types/crew-change-types';
import { CustomCrew } from '../types';

type Props = { crew: Crew | CustomCrew };

const DEFAULT_SALARY_TYPE = 'Custom';

const getDailyWage = (salary: number = 0) => Math.round(salary / 30);

const getSalaryType = (salary: CrewWageInfo | undefined) => {
  let type = DEFAULT_SALARY_TYPE;
  const { type: salaryType } = salary || {};

  switch (true) {
    case salaryType === CrewWageInfoType.GW_RANK_ESTIMATE:
      type = 'Estimated from Rank';
      break;

    case salaryType === CrewWageInfoType.ORG_RANK_ESTIMATE:
      type = 'Organization Rank Estimate';
      break;

    case salaryType === CrewWageInfoType.INDIVIDUAL:
      type = 'Organization Provided';
      break;

    default:
      break;
  }

  return type;
};

function CrewWage({ crew }: Props) {
  const { wage, monthlySalary } = crew;

  if (!wage) {
    return <span>-</span>;
  }

  const isUpdatedWage = wage && getDailyWage(monthlySalary?.wage) !== wage;

  const salaryType = isUpdatedWage
    ? DEFAULT_SALARY_TYPE
    : getSalaryType(monthlySalary);

  const tooltipContent = (
    <span>
      <b>Salary Type:</b> {salaryType}
    </span>
  );

  return (
    <Tooltip content={tooltipContent}>
      <span>
        {wage} {monthlySalary?.currency || ''}
      </span>
    </Tooltip>
  );
}

export default CrewWage;
