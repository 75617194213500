// import uniqBy from 'lodash/uniqBy';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { VesselCCBudget } from 'components/CrewChangePanel/types';
import {
  CCPanelRequestPayload,
  CCPanelResponsePayload,
  CrewChangeResources,
  HotelsResultPayload,
  RemoveCCPlanPayload,
  ReportFlightsPayload,
  SavedPlansPayload,
} from '../types';

export const initialCCResources: CrewChangeResources = {
  requests: { proxPorts: null },
  responses: {
    proxPorts: null,
    urgentPorts: null,
    reportFlights: null,
    vesselBudgets: null,
  },
  plans: null,
  incompletePlans: null,
  hotelResults: null,
};

const ccResourcesSlice = createSlice({
  name: 'crewChangeResources',
  initialState: initialCCResources,
  reducers: {
    setPortsRange: (state, action: PayloadAction<number>) => ({
      ...state,
      requests: {
        ...(state?.requests || {}),
        proxPorts: { ...state.requests.proxPorts!, rangeKM: action.payload },
      },
    }),
    setCCPanelRequest: (
      state,
      action: PayloadAction<CCPanelRequestPayload>
    ) => {
      const { request, type } = action.payload || {};
      return {
        ...state,
        requests: {
          ...(state?.requests || {}),
          [type]: request,
        },
      };
    },
    setCCPanelResponses: (
      state,
      action: PayloadAction<CCPanelResponsePayload>
    ) => {
      const { type, response } = action.payload;
      return {
        ...state,
        responses: { ...state.responses, [type]: response || null },
      };
    },
    setVesselBudgets: (state, action: PayloadAction<VesselCCBudget[]>) => ({
      ...state,
      responses: {
        ...state.responses,
        vesselBudgets: action.payload,
      },
    }),
    updateReportFlights: (
      state,
      action: PayloadAction<ReportFlightsPayload>
    ) => {
      const {
        responses: { reportFlights },
      } = state;
      const { portLocode, crewId, flights } = action.payload;
      const portFlightResponses = {
        ...(reportFlights?.[portLocode] || {}),
        [crewId]: flights.map((flight) => ({
          ...flight,
          id: flight.originalId || `${flight.id}--${crewId}-${portLocode}`,
          originalId: (flight.originalId || flight.id) as string,
        })),
      };

      return {
        ...state,
        responses: {
          ...state.responses,
          reportFlights: {
            ...(reportFlights || {}),
            [portLocode]: portFlightResponses,
          },
        },
      };
    },
    setCCPanelHotels: (
      state,
      action: PayloadAction<HotelsResultPayload | null>
    ) => ({
      ...state,
      hotelResults: action.payload
        ? { ...state.hotelResults, ...action.payload }
        : null,
    }),
    updateSavedCCPlans: (
      state,
      action: PayloadAction<SavedPlansPayload | null>
    ) => ({
      ...state,
      plans: action.payload ? { ...state.plans, ...action.payload } : null,
    }),
    updateIncompleteCCPlans: (
      state,
      action: PayloadAction<SavedPlansPayload | null>
    ) => ({
      ...state,
      incompletePlans: action.payload
        ? { ...state.incompletePlans, ...action.payload }
        : null,
    }),
    removeCrewChangePlan: (
      state,
      action: PayloadAction<RemoveCCPlanPayload>
    ) => {
      const { summary, isCompleted } = action.payload;
      const { uuid: planId, flotillaVesselId: vesselId } = summary;
      const vesselPlans = isCompleted
        ? state.plans?.[vesselId]
        : state.incompletePlans?.[vesselId];
      const stateKey = isCompleted ? 'plans' : 'incompletePlans';

      if (!vesselPlans) {
        return state;
      }

      const { meta, data: savedPlans } = vesselPlans;
      const { currentPage, totalPages } = meta;
      const updatedPlans = savedPlans.filter(({ uuid }) => uuid !== planId);
      if (!updatedPlans.length) {
        const isAllRemoved = currentPage === totalPages;
        return {
          ...state,
          // set vessel plans to undefined, if all are removed
          [stateKey]: {
            ...state[stateKey],
            [vesselId]: !isAllRemoved ? { meta, data: [] } : undefined,
          },
        };
      }
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          [vesselId]: { meta, data: updatedPlans },
        },
      };
    },
    resetCCPanelRequest: (state) => ({
      ...state,
      requests: initialCCResources.requests,
    }),
    resetCCPanelResponses: (state) => ({
      ...state,
      responses: initialCCResources.responses,
    }),
  },
});

/* ----- selectors -----*/

// export const selectCrewChangeResources = (state: RootState) =>
//   state.crewChangeResources;

/* ----- actions -----*/

export const {
  setPortsRange,
  setCCPanelRequest,
  setCCPanelResponses,
  setCCPanelHotels,
  setVesselBudgets,
  resetCCPanelRequest,
  resetCCPanelResponses,
  updateSavedCCPlans,
  updateIncompleteCCPlans,
  updateReportFlights,
  removeCrewChangePlan,
} = ccResourcesSlice.actions;

/* ----- reducer -----*/

export default ccResourcesSlice.reducer;
