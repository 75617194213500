import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from '@turf/turf';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { RootState } from '../types';

// Data to be used to show vessels in time travel mode is separated
// from the other ui controlling state to prevent unnecessary
// rerenders as this data gets updated
type TimeTravelDataState = {
  travels: {
    [vesselId: number]: VesselTravels | null;
  };
};

export type VesselTravelState = 'moving' | 'waiting' | 'unknown';

type VesselTravels = {
  coordinates: { [index: string]: Position };
  bearing: { [index: string]: number };
  state: { [index: string]: VesselTravelState };
  minAvailable: number;
  maxAvailable: number;
};

export const initialTimeTravelData: TimeTravelDataState = {
  travels: {},
};

const timeTravelDataSlice = createSlice({
  name: 'timeTravelData',
  initialState: initialTimeTravelData,
  // Any action that updates store should be a thunk as in `redux/thunks/settings.ts` file
  reducers: {
    updateTimeTravels: (
      state,
      action: PayloadAction<{ vesselId: number; travels: VesselTravels }>
    ) => ({
      ...state,
      travels: {
        ...state.travels,
        [action.payload.vesselId]: action.payload.travels,
      },
    }),
  },
});

/* ----- selectors -----*/

export const selectTimeTravelData = ({ timeTravelData }: RootState) =>
  timeTravelData;

export const selectTimeTravelCompletion = createSelector(
  (
    vessels: Map<number, Vessel>,
    travels: {
      [vesselId: number]: VesselTravels | null;
    }
  ) => ({
    size: vessels.size,
    completed: Object.keys(travels).filter((travelKey) =>
      Array.from(vessels.keys()).includes(Number(travelKey))
    ),
  }),
  ({ size, completed }) => Math.ceil((completed.length / size) * 100)
);

/* ----- actions -----*/

export const { updateTimeTravels } = timeTravelDataSlice.actions;

/* ----- reducer -----*/

export default timeTravelDataSlice.reducer;
