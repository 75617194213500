import { useCallback, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'hooks';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_SEARCH_CLEAR,
  TRACK_SEARCH_CLOSE,
} from 'utils/analytics/constants';
import { setSearchExpanded, startMapRuler } from 'redux/actions';
import { selectFlotillaSearch } from 'redux/selectors';
import { RootState } from 'redux/types';
import { SearchContext } from 'contexts/SearchContext';

function useRulerSearch() {
  const dispatch = useAppDispatch();
  const { searchIsExpanded } = useSelector(selectFlotillaSearch);
  const activeRuler = useSelector((state: RootState) => state.mapRuler.active);
  const {
    inputStr,
    searchType: {
      meta: { command },
    },
    setInputStr,
  } = useContext(SearchContext);

  const handleClickOutside = useCallback(() => {
    dispatch(setSearchExpanded(false));
    trackUserAction(TRACK_SEARCH_CLOSE);
  }, [dispatch]);

  // open ruler component from search-bar
  useEffect(() => {
    if (command === 'deviation' && inputStr && searchIsExpanded) {
      setInputStr('deviation: Add waypoints on map');
      if (!activeRuler) {
        dispatch(startMapRuler({ type: 'route', units: 'nauticalmiles' }));
        handleClickOutside();
      }
    }
  }, [
    inputStr,
    activeRuler,
    command,
    searchIsExpanded,
    dispatch,
    handleClickOutside,
    setInputStr,
  ]);

  // reset search-bar content when ruler is closed, if command is `ruler`
  useEffect(() => {
    if (!activeRuler && command === 'deviation' && !searchIsExpanded) {
      setInputStr('');
      // dispatch(setSearchExpanded(true));
      trackUserAction(TRACK_SEARCH_CLEAR);
    }
  }, [command, activeRuler, searchIsExpanded, setInputStr]);
}

export default useRulerSearch;
