import { PayloadAction } from '@reduxjs/toolkit';
import along from '@turf/along';
import { bearing, Position, Units, point } from '@turf/turf';
import moment, { Moment } from 'moment';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import {
  DistanceUnits,
  WaypointWithProperties,
} from '@greywing-maritime/gw-ngraph';
import { utils } from '@greywing-maritime/gw-ngraph';

import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { fetchVesselPortCallsAsync } from 'redux/thunks';
import { AppDispatch } from 'redux/types';
import { PortCallResponse, PortCallV2CommonUTC } from 'utils/types';
import {
  CalculatedVesselRoute,
  GetVesselRoute,
  GetVesselRouteProps,
} from 'utils/types/route-calculator';
import { updateTimeTravels } from 'redux/actions';
import { FlotillaMapConfig } from 'utils/flotilla-config';
import { VesselTravelState } from 'redux/reducers/timeTravelData';
import { getVesselWithPortCallDetails } from 'utils/routes';
import { MAX_TRAVEL_INDEX } from './variables';
import { getEarliestDate } from 'utils/dates';
const { routesToLineString } = utils;

type FetchAndPrepareRouteProps = {
  dispatch: AppDispatch;
  vessel: Vessel;
  portCallResponse?: PortCallResponse;
  getVesselRoute: (
    props: GetVesselRouteProps
  ) => Promise<CalculatedVesselRoute>;
};

export async function fetchAndPrepareRoute({
  dispatch,
  vessel,
  portCallResponse,
  getVesselRoute,
}: FetchAndPrepareRouteProps) {
  return new Promise(async (res) => {
    let response = portCallResponse;
    if (!portCallResponse) {
      const { payload } = (await dispatch(
        fetchVesselPortCallsAsync({ vesselId: vessel.id })
      )) as PayloadAction<PortCallResponse>;
      response = payload;
    }

    if (!response?.success || !response?.portCalls) {
      res(false);
      return;
    }

    const routes = await prepareTimeTravelRoutes({
      getVesselRoute,
      vessel,
      portCalls: response.portCalls,
    });

    const truncatedNow = moment().minute(0).second(0).millisecond(0);
    const coordinateMap: { [index: string]: Position } = {
      0: [vessel.lng, vessel.lat],
    };
    const bearingMap: { [index: string]: number } = {
      0: vessel.course || 0,
    };
    const stateMap: { [index: string]: VesselTravelState } = {};

    // If there are no routes
    if (!routes.length) {
      // Set default ranges
      // Coordinate is the current vessel coordinate
      coordinateMap[`-${MAX_TRAVEL_INDEX};-1`] = coordinateMap[0];
      coordinateMap[`1;${MAX_TRAVEL_INDEX}`] = coordinateMap[0];
      // Bearing is the current vessel source
      bearingMap[`-${MAX_TRAVEL_INDEX};-1`] = bearingMap[0];
      bearingMap[`1;${MAX_TRAVEL_INDEX}`] = bearingMap[0];
      // State is set to be unknown
      stateMap[`-${MAX_TRAVEL_INDEX};-1`] = 'unknown';
      stateMap[`1;${MAX_TRAVEL_INDEX}`] = 'unknown';
      stateMap[0] = 'unknown';
      dispatch(
        updateTimeTravels({
          vesselId: vessel.id,
          travels: {
            coordinates: coordinateMap,
            bearing: bearingMap,
            state: stateMap,
            minAvailable: 0,
            maxAvailable: 0,
          },
        })
      );

      res(true);
      return;
    }
    // calculate hourly segments
    for await (const segment of routes) {
      const { totalDistance: oriDistance, lineString, start, speed } = segment;
      let distance = oriDistance;
      const oriIndex = start.diff(truncatedNow, 'hour');
      let index = oriIndex;
      let increment = 0;
      do {
        const travellingDistance = speed * increment;
        if (increment === 0) {
          coordinateMap[index] = lineString[0];
        } else {
          if (distance < speed) {
            coordinateMap[index] = lineString[lineString.length - 1];
            bearingMap[index] = bearing(
              point(coordinateMap[index - 1]),
              point(coordinateMap[index])
            );
          } else {
            const nextPoint = along(
              {
                type: 'Feature',
                geometry: { type: 'LineString', coordinates: lineString },
                properties: {},
              },
              travellingDistance,
              {
                units: FlotillaMapConfig.routeUnits as Units,
              }
            );
            coordinateMap[index] = [
              Number(Number(nextPoint.geometry.coordinates[0]).toFixed(3)),
              Number(Number(nextPoint.geometry.coordinates[1]).toFixed(3)),
            ];
            bearingMap[index] = bearing(
              point(coordinateMap[index - 1]),
              point(coordinateMap[index])
            );
          }
        }
        stateMap[index] = 'moving';
        index += 1;
        increment += 1;
        distance -= speed;
      } while (distance > 0);

      // set initial bearing
      if (!bearingMap[oriIndex] && bearingMap[oriIndex + 1]) {
        bearingMap[oriIndex] = bearingMap[oriIndex + 1];
      } else if (!bearingMap[oriIndex]) {
        bearingMap[oriIndex] = bearingMap[0];
      }
    }

    // Identify missing ranges
    const indexExist: number[] = sortBy(Object.keys(coordinateMap).map(Number));

    // Identify min and max
    const checkMinMaxArray: number[] = indexExist.filter(
      (o) => !(o > MAX_TRAVEL_INDEX || o < -MAX_TRAVEL_INDEX)
    );
    const minAvailable = checkMinMaxArray[0];
    const maxAvailable = checkMinMaxArray[checkMinMaxArray.length - 1];

    const missing = indexExist.reduce<
      { start: number; end: number; meta: VesselTravelState }[]
    >((remaining, current, index) => {
      if (index === 0) {
        // edge case - there is only coordinates less than the max negative index
        if (
          current < -MAX_TRAVEL_INDEX &&
          !indexExist.filter((o) => o > 0).length
        ) {
          remaining.push({
            start: [...indexExist].filter((o) => o !== 0).reverse()[0] + 1,
            end: -1,
            meta: 'unknown',
          });
          remaining.push({
            start: 1,
            end: MAX_TRAVEL_INDEX,
            meta: 'unknown',
          });
          return remaining;
        }

        if (current !== -MAX_TRAVEL_INDEX) {
          remaining.push({
            start: -MAX_TRAVEL_INDEX,
            end: current - 1,
            meta: 'unknown',
          });
        }

        return remaining;
      }

      if (current - indexExist[index - 1] > 1) {
        remaining.push({
          start: indexExist[index - 1] + 1,
          end: current - 1,
          meta: !indexExist.filter((o) => o > 0).length ? 'unknown' : 'waiting',
        });
      }

      if (index === indexExist.length - 1) {
        if (current < MAX_TRAVEL_INDEX && current > -MAX_TRAVEL_INDEX) {
          remaining.push({
            start: current + 1,
            end: MAX_TRAVEL_INDEX,
            meta: 'unknown',
          });
        }
      }
      return remaining;
    }, []);

    missing.forEach((miss, index) => {
      // process first item
      if (index === 0) {
        if (miss.start === -MAX_TRAVEL_INDEX && miss.meta === 'unknown') {
          stateMap[`${miss.start};${miss.end}`] = 'unknown';
          coordinateMap[`${miss.start};${miss.end}`] =
            coordinateMap[miss.end + 1];
          bearingMap[`${miss.start};${miss.end}`] = bearingMap[miss.end + 1];
        }
        return;
      }

      stateMap[`${miss.start};${miss.end}`] = miss.meta;
      coordinateMap[`${miss.start};${miss.end}`] =
        coordinateMap[miss.start - 1];
      bearingMap[`${miss.start};${miss.end}`] = bearingMap[miss.start - 1];

      // process last item
      if (index === missing.length - 1) {
        if (miss.end === MAX_TRAVEL_INDEX && miss.meta === 'unknown') {
          stateMap[`${miss.start};${miss.end}`] = 'unknown';
        }
      }
    });

    // fill in vessel state at 0
    const getIndexNegativeOne = getTimeTravelValueIndexFromObject(stateMap, -1);
    const getIndexOne = getTimeTravelValueIndexFromObject(stateMap, 1);
    if (getIndexNegativeOne !== null && getIndexOne !== null) {
      if (
        stateMap[getIndexNegativeOne] !== 'unknown' &&
        stateMap[getIndexOne] !== 'unknown'
      ) {
        stateMap[0] = 'moving';
      } else {
        // use previous state
        stateMap[0] = stateMap[getIndexNegativeOne] || 'unknown';
      }
    } else {
      // use previous state
      stateMap[0] =
        getIndexNegativeOne !== null
          ? stateMap[getIndexNegativeOne]
          : 'unknown';
    }

    dispatch(
      updateTimeTravels({
        vesselId: vessel.id,
        travels: {
          coordinates: coordinateMap,
          bearing: bearingMap,
          state: stateMap,
          minAvailable,
          maxAvailable,
        },
      })
    );
    res(true);
  });
}

export function buildVesselFeature(
  vessel: Vessel,
  color: string,
  coordinate: Position,
  bearing: number,
  opacity: number
) {
  return {
    type: 'Feature',
    id: vessel.id,
    properties: {
      name: vessel.name,
      icon: color,
      course: bearing,
      opacity,
    },
    geometry: {
      type: 'Point',
      coordinates: coordinate,
    },
  };
}

type TravelRouteSegmentTypes =
  | 'past-port-port'
  | 'past-port-vessel'
  | 'future-vessel-port'
  | 'future-port-port';

type TravelRoute = {
  start: Moment; // utc time
  end: Moment;
  segmentType: TravelRouteSegmentTypes;
  displayType: string; // ais
  lineString: Position[];
  totalDistance: number;
  units: DistanceUnits;
  speed: number;
};

function removeNextDuplicatePortCalls(portCalls: PortCallV2CommonUTC[]) {
  return portCalls.filter((portCall, index) => {
    if (index === 0) {
      return true;
    }
    return (
      portCall.lat !== portCalls[index - 1].lat &&
      portCall.lng !== portCalls[index - 1].lng
    );
  });
}

export async function prepareTimeTravelRoutes({
  getVesselRoute,
  portCalls,
  vessel,
}: {
  getVesselRoute: GetVesselRoute;
  portCalls: PortCallV2CommonUTC[];
  vessel: Vessel;
}): Promise<TravelRoute[]> {
  const today = new Date();
  const separateByPastAndFuture = groupBy(portCalls, (portCall) => {
    return new Date(getEarliestDate([portCall.utcETA, portCall.utcETD])) < today
      ? 'past'
      : 'future';
  });
  const pastPortCalls = groupBy(separateByPastAndFuture.past, 'type');
  const futurePortCalls = groupBy(separateByPastAndFuture.future, 'type');
  let selectedPastPortCalls: PortCallV2CommonUTC[] = [],
    selectedFuturePortCalls: PortCallV2CommonUTC[] = [];
  if (pastPortCalls['VOYAGE_PLAN']) {
    selectedPastPortCalls = pastPortCalls['VOYAGE_PLAN'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (pastPortCalls['MAERSK']) {
    selectedPastPortCalls = pastPortCalls['MAERSK'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (pastPortCalls['CMA_CGM']) {
    selectedPastPortCalls = pastPortCalls['CMA_CGM'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (pastPortCalls['AIS']) {
    selectedPastPortCalls = pastPortCalls['AIS'].filter(
      (o) => o.utcETA || o.utcETD
    );
  }

  if (futurePortCalls['VOYAGE_PLAN']) {
    selectedFuturePortCalls = futurePortCalls['VOYAGE_PLAN'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (futurePortCalls['MAERSK']) {
    selectedFuturePortCalls = futurePortCalls['MAERSK'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (futurePortCalls['CMA_CGM']) {
    selectedFuturePortCalls = futurePortCalls['CMA_CGM'].filter(
      (o) => o.utcETA || o.utcETD
    );
  } else if (futurePortCalls['AIS']) {
    selectedFuturePortCalls = futurePortCalls['AIS'].filter(
      (o) => o.utcETA || o.utcETD
    );
  }

  // Sometimes there are duplicate port calls immediately after each other. Keep the first one that appears
  const filteredPortCall = [
    ...removeNextDuplicatePortCalls(selectedPastPortCalls),
    ...removeNextDuplicatePortCalls(selectedFuturePortCalls),
  ];

  if (!filteredPortCall.length) return [];
  const allWaypoints = getVesselWithPortCallDetails(filteredPortCall, vessel);
  if (!allWaypoints.length) return [];
  const pairs = allWaypoints.reduce<WaypointWithProperties[][]>(
    (pairs, waypoint, index) => {
      if (index === 0) return pairs;
      pairs.push([allWaypoints[index - 1], waypoint]);
      return pairs;
    },
    []
  );

  const travelRoutes: TravelRoute[] = [];
  const insufficientData = [];
  const invalidData = [];
  for await (const pair of pairs) {
    const nowTruncated = moment().minute(0).second(0).millisecond(0);
    const [start, end] = pair;
    let segmentType: string, startTime: Moment, endTime: Moment;
    // sometimes the start and end destination is the same
    if (start.coor === end.coor) {
      invalidData.push(pair);
      break;
    }
    // Format data based on whether it is
    // port -> vessel, vessel -> port, port -> port
    if (
      start.properties.waypointType === 'port' &&
      end.properties.waypointType === 'vessel'
    ) {
      if (!start.properties.utcETD && !start.properties.utcETA) {
        insufficientData.push(pair);
        break;
      }
      startTime = moment(start.properties.utcETD)
        .minute(0)
        .second(0)
        .millisecond(0);
      if (!start.properties.utcETD) {
        // use next hour instead for port to vessel points
        startTime = moment(start.properties.utcETA)
          .add(1, 'hour')
          .minute(0)
          .second(0)
          .millisecond(0);
      }
      segmentType = `${start.properties.segment}-port-vessel`;
      endTime = nowTruncated;
    } else if (
      start.properties.waypointType === 'vessel' &&
      end.properties.waypointType === 'port'
    ) {
      if (!end.properties.utcETA && !end.properties.utcETD) {
        insufficientData.push(pair);
        break;
      }
      endTime = moment(end.properties.utcETA)
        .minute(0)
        .second(0)
        .millisecond(0);
      if (!end.properties.utcETA) {
        // use previous day instead
        endTime = moment(end.properties.utcETD)
          .subtract(1, 'day')
          .minute(0)
          .second(0)
          .millisecond(0);
      }
      segmentType = `${end.properties.segment}-vessel-port`;
      startTime = nowTruncated;
    } else if (
      start.properties.waypointType === 'port' &&
      end.properties.waypointType === 'port'
    ) {
      if (
        (!end.properties.utcETA && !end.properties.utcETD) ||
        (!start.properties.utcETD && !start.properties.utcETA)
      ) {
        insufficientData.push(pair);
        break;
      }
      segmentType = `${start.properties.segment}-port-port`;
      startTime = start.properties.utcETD
        ? moment(start.properties.utcETD).minute(0).second(0).millisecond(0)
        : moment(start.properties.utcETA)
            .add(1, 'day')
            .minute(0)
            .second(0)
            .millisecond(0);
      endTime = end.properties.utcETA
        ? moment(end.properties.utcETA).minute(0).second(0).millisecond(0)
        : moment(end.properties.utcETD)
            .subtract(1, 'day')
            .minute(0)
            .second(0)
            .millisecond(0);
    } else {
      // Something's wrong
      console.log('UH OH..', pair);
      invalidData.push(pair);
      break;
    }
    const calculatedRoute = await getVesselRoute({
      waypoints: pair,
      units: FlotillaMapConfig.routeUnits as DistanceUnits,
    });
    const timeDiffInHours = moment(endTime).diff(moment(startTime), 'hours');
    if (timeDiffInHours < 0) {
      invalidData.push(pair);
      break;
    }
    const speed = Math.abs(
      Math.ceil(calculatedRoute.calculation.totalDistance / timeDiffInHours)
    );
    travelRoutes.push({
      start: startTime! as Moment,
      end: endTime! as Moment,
      lineString: routesToLineString(calculatedRoute.calculation.posRoute),
      totalDistance: calculatedRoute.calculation.totalDistance,
      segmentType: segmentType as TravelRouteSegmentTypes,
      units: FlotillaMapConfig.routeUnits as DistanceUnits,
      displayType: 'ais',
      speed,
    });
  }
  // error logging
  if (insufficientData.length) {
    console.log(
      'Failed to generate timetravel - Insufficient data',
      insufficientData
    );
  }
  if (invalidData.length) {
    console.log('Failed to generate timetravel - Invalid data', invalidData);
  }
  return travelRoutes;
}

export function getTimeTravelValueIndexFromObject(
  obj: { [key: string]: any },
  index: number
): string | number | null {
  if (obj[index] !== undefined) return index;
  const splittable = Object.keys(obj).filter((o) => o.split(';').length === 2);
  return splittable.reduce((found: string | null, rangeSplit: string) => {
    if (found !== null) return found;
    const [start, end] = rangeSplit.split(';');
    if (index >= Number(start) && index <= Number(end)) {
      return rangeSplit;
    }
    return found;
  }, null);
}

export function getVesselColor(state: VesselTravelState, color: string) {
  if (state === 'moving') return `vessel-${color}`;
  if (state === 'unknown') return `vessel-000000-000000-ffffff`;
  return `vessel-385dea-385dea-ffffff`;
}
