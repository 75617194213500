import { useContext, useEffect, useState } from 'react';
import type { CrewChangePlanUserNotes } from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import styled from 'styled-components/macro';

import { useReadOnlyPlanningData } from 'hooks';
import { showToaster } from 'lib/toaster';
import { green, textGray, white } from 'lib/colors';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Modal, SuccessAnimation as Animation } from 'components/shared';
import { StyledButton } from '../../common';
import { ReadOnlyPlanningData } from '../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CopyButton = styled(StyledButton)`
  color: ${white};
  background: ${green};
  border: none;
`;

const LinkText = styled.div`
  font-size: 1rem;
  color: ${textGray};
`;

type Props = {
  reportData?: ReadOnlyPlanningData;
  userNotes: CrewChangePlanUserNotes;
  closeModal: () => void;
};

function CopyLinkModal({ userNotes, reportData, closeModal }: Props) {
  const {
    reportInfo: { savedLink: shareableLink },
    updateReportInfo,
  } = useContext(CCPanelContext);

  const [showAnimation, setShowAnimation] = useState(true);
  const { savingStatus, saveReadOnlyData } = useReadOnlyPlanningData();
  const { loading, success, message: title } = savingStatus;
  const canCopyLink = !loading && success && shareableLink;

  useEffect(() => {
    // Do NOT generate link again by saving, if savedLink available
    // i.e - no changes made since saving
    if (shareableLink) return;
    saveReadOnlyData({ userNotes, reportData }).then((result) => {
      updateReportInfo({ type: 'savedLink', payload: result?.link });
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    // add delay for all animations to finish
    setTimeout(() => {
      setShowAnimation(!canCopyLink);
    }, 2000);
  }, [canCopyLink]);

  const handleCopyLink = async () => {
    if (shareableLink && navigator.clipboard) {
      navigator.clipboard.writeText(shareableLink);
      showToaster({
        message: 'Copied the link to clipboard',
        testId: 'e2e_plan-copy-link-toaster',
      });
    } else {
      showToaster({
        message: 'Failed to copy the link',
        type: 'info',
      });
    }

    closeModal();
  };

  const actions = (
    <ButtonsWrapper>
      <StyledButton variant="primary" onClick={closeModal}>
        Close
      </StyledButton>
      {canCopyLink && (
        <CopyButton variant="secondary" onClick={handleCopyLink}>
          Copy
        </CopyButton>
      )}
    </ButtonsWrapper>
  );

  const modalProps = !showAnimation
    ? { closeModal, actions, title: 'Copy Link' }
    : // allow closing by clicking outside, for failed state
      // the other 2 - loading & success - states are intermediate (not persistent)
      (!loading && !success && { closeModal }) || {};

  return (
    <Modal center width={showAnimation ? 400 : 600} {...modalProps}>
      {showAnimation ? (
        <Animation
          title={title}
          name={
            loading
              ? '97930-loading'
              : (success && '68994-success') || '90569-error'
          }
        />
      ) : (
        <FlexWrapper>
          {canCopyLink ? (
            <LinkText data-id="e2e_crew-change-link">{shareableLink}</LinkText>
          ) : (
            'Link unavailable'
          )}
        </FlexWrapper>
      )}
    </Modal>
  );
}

export default CopyLinkModal;
