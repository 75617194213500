import { memo } from 'react';
import Grow from '@mui/material/Grow';
import styled from 'styled-components/macro';
import { SearchFilter } from '@greywing-maritime/frontend-library/dist/utils/search-engine/search-engine-types';

import { ETA_SEARCH_KEYS } from 'lib/constants';
import { borderGray, gray20, textBlack } from 'lib/colors';

export const PANEL_HEIGHT = 150;

const Wrapper = styled.div`
  background-color: ${gray20};
  height: ${PANEL_HEIGHT}px;
  border-bottom: 1px solid ${borderGray};
  width: 100%;
  padding: 0.5rem 1rem;
  overflow: auto;
  z-index: 9;
`;

const FiltersCount = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`;

const Label = styled.div`
  color: #9e9e9e;
`;

const Query = styled.b`
  color: ${textBlack};
  margin-left: 0.3rem;
`;

type Props = {
  searchFilters: SearchFilter[];
};

function PanelDetail({ searchFilters }: Props) {
  const count = searchFilters.length;
  return (
    <Grow in timeout={500}>
      <Wrapper>
        <FiltersCount>
          {count} search filter{count > 1 ? 's' : ''}
        </FiltersCount>
        {searchFilters.map((filter, index) => {
          const { field, label, query: value } = filter;
          const fieldValue = ETA_SEARCH_KEYS.includes(field)
            ? `${Number(value) / (3600 * 24)} days`
            : value;

          return (
            <FlexWrapper key={index}>
              <Label>{label}</Label>
              <span>:</span>
              <Query>{fieldValue}</Query>
            </FlexWrapper>
          );
        })}
      </Wrapper>
    </Grow>
  );
}

export default memo(PanelDetail);
