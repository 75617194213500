import partition from 'lodash/partition';
import moment from 'moment';
import { memo, useContext, useMemo } from 'react';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { darkBlue, green, borderGray, red, lightBlue } from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Tooltip } from 'components/shared';
import { OnsignerIcon, OffsignerIcon } from 'components/icons';

import { Label } from '../../../common';
import { updateDeparture } from '../../../helpers';
import { DepartureInput, LocodesProps } from '../../../types';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ControlsWrapper = styled(FlexWrapper)<{ $disabled?: boolean }>`
  flex-shrink: 0;
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const DepartureTimeWrapper = styled(FlexWrapper)<{ $fetching?: boolean }>`
  margin: auto 0.3rem;
  flex-shrink: 0;
  ${({ $fetching }) =>
    $fetching &&
    `
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const Departure = styled.div`
  border: 1px solid ${lightBlue};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  transition: all 150ms ease;
`;

const ArrowIcon = styled.div<{
  $left?: boolean;
  $right?: boolean;
  $disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0.25rem;
  margin-right: ${({ $left }) => $left && `0.25rem`};
  margin-left: ${({ $right }) => $right && `0.25rem`};
  border: 1px solid ${lightBlue};
  border-radius: 4px;
  transition: all 150ms ease;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    `
    border: 1px solid ${borderGray};
    color: ${borderGray};
    cursor: default;
  `};
  &:hover {
    ${({ $disabled }) =>
      !$disabled &&
      `
      border: 1px solid ${darkBlue};
      background: ${darkBlue};
      color: #fff;
    `};
  }
  svg {
    font-size: 15px !important;
  }
`;

const StyledIcon = styled.div`
  margin-right: 0.25rem;
  span {
    display: flex;
    align-items: center;
  }
`;

type Props = LocodesProps & {
  disabled: boolean;
  loadFlights: (departures: DepartureInput) => void;
};

const formatDeparture = (dateStr: string | undefined) =>
  dateStr && formatDate(dateStr, 'DD MM YYY');

function Departures({ disabled, activeLocode, locodeKey, loadFlights }: Props) {
  const {
    fetchStatus,
    filters: flightFilters,
    planningData: { crew, ports },
    updateFilters,
  } = useContext(CCPanelContext);

  const { departures } = flightFilters?.[locodeKey] || {};
  const fetching = useMemo(() => {
    const { progress, crewType } = fetchStatus;
    const portProgress = progress?.[locodeKey] || 0;
    return {
      offsigner: portProgress < 1 && crewType?.offsigner,
      onsigner: portProgress < 1 && crewType?.onsigner,
    };
  }, [fetchStatus, locodeKey]);

  const [onsigners, offsigners] = useMemo(
    () => partition(crew, ({ type }) => type === CrewType.onsigner),
    [crew]
  );

  const { latestOnsignerDate, earliestOnsignerDate, earliestOffsignerDate } =
    useMemo(() => {
      const activePort = ports.find(({ locode }) => locode === activeLocode);
      // when selected onsigner departure is the same as port ETD
      const latestOnsignerDate =
        formatDeparture(activePort?.etd) ===
        formatDeparture(departures?.onsigner);
      // when selected onsigner departure is the same as today
      const earliestOnsignerDate =
        formatDeparture(moment().toISOString()) ===
        formatDeparture(departures?.onsigner);
      // when selected offsigner departure is the same as port's ETA
      const earliestOffsignerDate =
        formatDeparture(activePort?.eta) ===
        formatDeparture(departures?.offsigner);

      return {
        latestOnsignerDate,
        earliestOnsignerDate,
        earliestOffsignerDate,
      };
    }, [activeLocode, ports, departures]);

  const handleChangeDate = ({
    type,
    action,
  }: {
    type: string;
    action: 'add' | 'subtract';
  }) => {
    const { onsigner, offsigner } = departures!;
    switch (true) {
      /* prevent updating departure date for `offsigner`, if brefore vessel arrival */
      case type === 'offsigner' && Boolean(offsigner): {
        const departureInput = {
          type,
          locodeKey,
          departures: {
            onsigner,
            offsigner: updateDeparture(offsigner!, action),
          },
        };
        updateFilters({ type: 'UPDATE_DEPARTURES', payload: departureInput });
        loadFlights(departureInput);
        break;
      }

      case type === 'onsigner' && Boolean(onsigner): {
        const departureInput = {
          type,
          locodeKey,
          departures: {
            onsigner: updateDeparture(onsigner!, action),
            offsigner,
          },
        };
        updateFilters({ type: 'UPDATE_DEPARTURES', payload: departureInput });
        loadFlights(departureInput);
        break;
      }

      default:
        break;
    }
  };

  if (!departures) {
    return null;
  }

  return (
    <ControlsWrapper data-id="e2e_departures-section" $disabled={disabled}>
      <FlexWrapper>
        <Label style={{ marginRight: '0.2rem' }}>Departures</Label>
        {Boolean(onsigners.length) && departures.onsigner && (
          <DepartureTimeWrapper $fetching={fetching.onsigner}>
            <Tooltip content="Onsigner">
              <StyledIcon>
                <OnsignerIcon color={green} />
              </StyledIcon>
            </Tooltip>
            <ArrowIcon
              $left
              $disabled={earliestOnsignerDate}
              onClick={() =>
                handleChangeDate({
                  type: 'onsigner',
                  action: 'subtract',
                })
              }
            >
              <ArrowBackIosRoundedIcon />
            </ArrowIcon>
            <Departure>{formatDate(departures.onsigner)}</Departure>
            <ArrowIcon
              $right
              $disabled={latestOnsignerDate}
              onClick={() =>
                handleChangeDate({
                  type: 'onsigner',
                  action: 'add',
                })
              }
            >
              <ArrowForwardIosRoundedIcon />
            </ArrowIcon>
          </DepartureTimeWrapper>
        )}
        {Boolean(offsigners.length) && departures.offsigner && (
          <DepartureTimeWrapper $fetching={fetching.offsigner}>
            <Tooltip content="Offsigner">
              <StyledIcon>
                <OffsignerIcon color={red} />
              </StyledIcon>
            </Tooltip>
            <ArrowIcon
              $left
              $disabled={earliestOffsignerDate}
              onClick={() =>
                handleChangeDate({
                  type: 'offsigner',
                  action: 'subtract',
                })
              }
            >
              <ArrowBackIosRoundedIcon />
            </ArrowIcon>
            <Departure>{formatDate(departures.offsigner)}</Departure>
            <ArrowIcon
              $right
              onClick={() =>
                handleChangeDate({
                  type: 'offsigner',
                  action: 'add',
                })
              }
            >
              <ArrowForwardIosRoundedIcon />
            </ArrowIcon>
          </DepartureTimeWrapper>
        )}
      </FlexWrapper>
    </ControlsWrapper>
  );
}

export default memo(Departures);
