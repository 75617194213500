import { memo, useCallback, useContext, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components/macro';

import { useAppDispatch, useModal } from 'hooks';
import { showToaster } from 'lib/toaster';
import { green, lightFadedGreen } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { stopOneClickCrewChange } from 'redux/actions';
import { CCPanelContext } from 'contexts/CCPanelContext';
import {
  TRACK_ONE_CLICK_CREW_CHANGE_REVIEW,
  TRACK_ONE_CLICK_CREW_CHANGE_SHARE,
  TRACK_ONE_CLICK_CREW_CHANGE_STOP,
} from 'utils/analytics/constants';

import { LottieAnimation, Modal } from 'components/shared';
import ActionTimes from '../common/ActionTimes';
import ShareLinkModal from '../common/LinkModals';
import { StyledButton } from '../common';
import { ACTION_TIMES } from '../helpers';
import IosShareIcon from '@mui/icons-material/IosShare';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledText = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const AnimationWrapper = styled.div<{ $isFinished: boolean }>`
  ${({ $isFinished }) =>
    $isFinished &&
    `
    margin-top: 3.5rem;
    margin-bottom: 3rem;
  `};
`;

const ProgressWrapper = styled.div`
  width: 90%;
  margin-bottom: 1.5rem;
  margin-top: -1.5rem;
`;

const ShareIcon = styled(IosShareIcon)`
  margin-right: 0.5rem;
  font-size: 18px !important;
  color: ${green};
`;

const ShareButton = styled(StyledButton)`
  background: ${lightFadedGreen};
  color: ${green};
`;

const progressStyles = {
  width: '100%',
  borderRadius: '0.25rem',
  height: '0.5rem',
};

type Props = {
  closeModal: () => void;
};

const TOTAL_CC_STEPS = 4;

const getAnimationStyles = (isFinished: boolean) => ({
  margin: 0,
  marginTop: isFinished ? '-5rem' : 0,
  marginBottom: isFinished ? '-1rem' : '2.5rem',
});

function OneClickPlanModal({ closeModal }: Props) {
  const dispatch = useAppDispatch();
  const {
    route,
    tableState: { step },
  } = useContext(CCPanelContext);
  const { modal, setModal } = useModal(null);

  const { waypoints: routePorts = [] } = route || {};
  const finishedSteps = Object.values(ACTION_TIMES).filter(({ show }) => show);
  const progressPercent = (finishedSteps.length / TOTAL_CC_STEPS) * 100;
  // fallback option for e2e test environment
  const isFinished =
    progressPercent === 100 || Boolean(ACTION_TIMES?.flights?.show);

  const handleStopOneClickPlan = useCallback(() => {
    dispatch(stopOneClickCrewChange());
    closeModal();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (step === 'route' && !routePorts.length) {
      // stop one-click crew-change if no future route
      handleStopOneClickPlan();
      showToaster({
        message: 'Vessel route unavailable. Add a port to create vesel route.',
        type: 'warning',
      });
    }
  }, [step, routePorts, handleStopOneClickPlan]);

  const renderModal = () => {
    if (!modal?.type) return null;

    if (['copyLink', 'addUserNotes'].includes(modal?.type)) {
      return <ShareLinkModal modal={modal} setModal={setModal} />;
    }
  };

  const actions = isFinished ? (
    <ButtonWrapper>
      <StyledButton
        variant="primary"
        onClick={() => {
          trackUserAction(TRACK_ONE_CLICK_CREW_CHANGE_REVIEW);
          handleStopOneClickPlan();
        }}
      >
        Review
      </StyledButton>
      <ShareButton
        className="e2e_share-planning-link"
        variant="primary"
        onClick={() => {
          trackUserAction(TRACK_ONE_CLICK_CREW_CHANGE_SHARE);
          setModal('addUserNotes');
        }}
      >
        <ShareIcon />
        Share Planning Data
      </ShareButton>
    </ButtonWrapper>
  ) : (
    <StyledButton
      variant="delete"
      onClick={() => {
        trackUserAction(TRACK_ONE_CLICK_CREW_CHANGE_STOP);
        handleStopOneClickPlan();
      }}
    >
      Stop
    </StyledButton>
  );

  return (
    <>
      <Modal center width={500} title="One-Click Crew Change" actions={actions}>
        <AnimationWrapper $isFinished={isFinished}>
          <LottieAnimation
            name={isFinished ? '676-done' : '6520-hourglass'}
            width={isFinished ? '250px' : '225px'}
            loop={!isFinished}
            style={getAnimationStyles(isFinished)}
          />
        </AnimationWrapper>
        <ProgressWrapper>
          <LinearProgress
            variant="determinate"
            value={isFinished ? 100 : progressPercent}
            sx={progressStyles}
          />
        </ProgressWrapper>
        <StyledText id="one-click-plan">
          {isFinished
            ? 'Successfully finished crew change planning!'
            : 'Executing crew change planning...'}
        </StyledText>
        <ActionTimes />
      </Modal>

      {renderModal()}
    </>
  );
}

export default memo(OneClickPlanModal);
