import React, { forwardRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { green } from 'lib/colors';
import { selectCrewChangePanel } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import Tooltip from 'components/shared/Tooltip';
import WhereToVoteRoundedIcon from '@mui/icons-material/WhereToVoteRounded';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(FlexWrapper)`
  column-gap: 0.3rem;
`;

const StyledIcon = styled(FlexWrapper)`
  svg {
    font-size: 1.4rem !important;
    color: ${green};
  }
`;

const RouteCounter = forwardRef(
  (_, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { active: activity, readOnlyPlanningData: reportData } = useSelector(
      selectCrewChangePanel
    );
    const { planningData } = useContext(CCPanelContext);

    const isReportView = activity === 'readOnly' && reportData;
    const routePorts = isReportView
      ? reportData.crewChangePlan.route
      : planningData.route;

    if (!routePorts.length) {
      return null;
    }

    return (
      <FlexWrapper ref={ref}>
        <IconWrapper>
          {routePorts.map((port, index) => (
            <Tooltip key={index} content={port.displayName}>
              <StyledIcon>
                <WhereToVoteRoundedIcon />
              </StyledIcon>
            </Tooltip>
          ))}
        </IconWrapper>
      </FlexWrapper>
    );
  }
);

export default RouteCounter;
