import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';

import { isDevelopment } from 'lib/environments';
import rootReducer from './reducers';
import middlewares from './middlewares';

// Connect Redux to Sentry
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    // TODO: remove this check after refactor of redux states; e. g - `flotillaSearch.searchResults`
    // Avoid putting non-serializable values in redux store
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  devTools: isDevelopment,
  enhancers: [sentryReduxEnhancer],
});
