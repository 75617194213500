import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getUserCompletedOnboardingSteps,
  getUserOnboardingContent,
} from 'api/flotilla';

/* ----- Async Thunk ----- */

export const getOnboardStepsAsync = createAsyncThunk(
  'onboard/getOnboardStepsAsync',
  async () => {
    const response = await getUserCompletedOnboardingSteps();
    return response.onboardingSteps || [];
  }
);

export const getOnboardContentAsync = createAsyncThunk(
  'onboard/getOnboardContentAsync',
  async () => {
    const response = await getUserOnboardingContent();
    return response.onboardContent || [];
  }
);
