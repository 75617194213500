import React, { useState, useCallback, useEffect } from 'react';
import { white } from 'lib/colors';
import styled from 'styled-components/macro';
import { motion, AnimatePresence } from 'framer-motion';

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  pointer-events: none;
`;

const Warning = styled.div`
  background-color: rgb(0, 0, 0, 0.5);
  color: ${white};
  border-radius: 5px;
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  // margin: 0.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  max-width: 25rem;
  p {
    margin: 0;
  }
  span {
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

function calcFontSize() {
  return (
    window.innerWidth /
    parseFloat(
      (
        getComputedStyle(document.querySelector('body')!) as {
          [key: string]: any;
        }
      )['font-size']
    )
  );
}

function calcResolution() {
  return {
    vh: Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
    vw: Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ),
  };
}

const AspectRatioWarning = () => {
  const [timer, setTimer] = useState(5);
  const [curFontSize, setCurFontSize] = useState(calcFontSize());
  const [viewport, setViewPort] = useState<{ vh: number; vw: number }>(
    calcResolution()
  );

  const handleResize = useCallback(() => {
    const newResolution = calcResolution();
    if (newResolution.vw !== viewport.vw) {
      setViewPort(calcResolution());
      setCurFontSize(calcFontSize());
      if (curFontSize >= 24 || viewport['vh'] >= 768 || viewport['vw'] >= 1024)
        setTimer(5);
    }
  }, [curFontSize, viewport]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const handleTimer = setInterval(() => {
      if (timer > 0) {
        setTimer((timer) => timer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(handleTimer);
    };
  }, [timer]); // eslint-disable-line

  return (
    <AnimatePresence>
      {(curFontSize <= 24 || viewport['vh'] <= 768 || viewport['vw'] <= 1024) &&
        timer && (
          <Wrapper>
            <motion.div
              initial={{ y: 120 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ y: 120, opacity: 0 }}
              transition={{ type: 'spring' }}
            >
              <Warning>
                <p>
                  Please ensure that your window is at least 1024 x 768 pixels
                  for a better experience. Currently at ({viewport.vw} x{' '}
                  {viewport.vh} pixels).
                  <span>({timer}s)</span>
                </p>
              </Warning>
            </motion.div>
          </Wrapper>
        )}
    </AnimatePresence>
  );
};

export default AspectRatioWarning;
