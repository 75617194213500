import { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

import sleep from 'lib/sleep';
import { trackUserAction } from 'lib/amplitude';
import { searchPorts } from 'api/flotilla';
import { TRACK_SEARCH_PORT_MAP_INPUT } from 'utils/analytics/constants';
import {
  ParsedSearchType,
  PortMapSearchResult,
  SearchedPort,
} from 'utils/types';
import useAppDispatch from './useAppDispatch';
import { setMapPorts } from 'redux/actions';

type PortRestrictionSearchType = {
  params: ParsedSearchType | null;
};

function usePortMapSearch({ params }: PortRestrictionSearchType) {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<PortMapSearchResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { search: query = '' } = params?.meta || {};
  const abortController = useRef<AbortController | null>(null);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      if (abortController.current) {
        abortController.current?.abort();
      }
      if (searchText.length < 2) return;

      setLoading(true);

      const results: SearchedPort[] = [];
      let hasMore = false,
        page = 1;
      abortController.current = new AbortController();
      do {
        const { success, portsResponse } = await searchPorts(
          searchText,
          page,
          50,
          { nearbyAirports: true, pastCce: true },
          abortController.current?.signal
        );
        if (success) {
          results.push(...(portsResponse?.results || []));
          // continue fetching if there are more results & searchText is NOT empty
          hasMore = (searchText && portsResponse?.pagination?.more) || false;
          if (hasMore) page += 1;
        } else {
          hasMore = false;
        }
      } while (hasMore);
      abortController.current = null;
      setResults({
        searchType: 'portMap',
        searchFieldName: 'port',
        searchFieldDescription: '',
        priority: 10,
        filterLabel: 'ports for',
        results: results,
      });
      dispatch(setMapPorts(results));

      trackUserAction(TRACK_SEARCH_PORT_MAP_INPUT, 'typed', {
        query: searchText,
      });
      setLoading(false);
    }, 300),
    []
  );

  const handleSearchPort = useCallback(async () => {
    const searchText = query.trim();
    await debouncedSearch(searchText);

    /* --- handle case when search input is cleared after triggering request--- */

    // wait before getting the latest search input
    await sleep();
    // empty result if search input is cleared
    if (!searchText) {
      setResults(null);
      dispatch(setMapPorts([]));
    }
  }, [query, debouncedSearch, dispatch]);

  useEffect(() => {
    handleSearchPort();
  }, [handleSearchPort]);

  return { results, isLoading: loading };
}

export default usePortMapSearch;
