import { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { black, blue } from 'lib/colors';

const ParallaxWrapper = styled.div.attrs<{
  $scrollTop: number;
  $scrollSpeed: number;
  $hideAtScrollTop: number;
}>(({ $scrollTop, $scrollSpeed, $hideAtScrollTop }) => ({
  style: {
    transform: `translateY(${$scrollTop / $scrollSpeed}px)`,
    opacity: 1 - $scrollTop / $hideAtScrollTop,
  },
}))<{
  $src: string;
  $scrollTop: number;
  $scrollSpeed: number;
  $hideAtScrollTop: number;
}>`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background: ${blue};
  background-image: url(${({ $src }) => $src});
  background-position: center;
  background-size: cover;
  z-index: 0;
  min-height: 160px;
  width: calc(100% + 1rem);
  transition: background-image 0.3s ease-in-out;

  &::before {
    position: absolute;
    content: '';
    background: linear-gradient(transparent 40%, ${black} 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: -1;
  }
`;

interface IProps {
  parent?: HTMLDivElement | null;
  scrollSpeed: number;
  hideAtScrollTop: number;
  src: string;
}

const ParallaxImage = (props: IProps) => {
  const { parent, scrollSpeed, hideAtScrollTop, src: imgSrc } = props;
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = useCallback(() => {
    if (parent) {
      const position = parent.scrollTop;
      setScrollTop(position);
    }
  }, [parent]);

  useEffect(() => {
    parent && parent.addEventListener('scroll', handleScroll);
    return () => {
      parent && parent.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, parent]);

  return (
    <ParallaxWrapper
      $src={imgSrc}
      $scrollTop={scrollTop}
      $scrollSpeed={scrollSpeed}
      $hideAtScrollTop={hideAtScrollTop}
    />
  );
};

export default memo(ParallaxImage);
