import { SeaGPTChatMessage, SeaGPTChatType } from './type';
import { generate8DigitID } from './helpers';

function START_PROMPT_V2(): SeaGPTChatMessage {
  return {
    type: 'system-guide',
    message: 'How may i assist you?',
    id: generate8DigitID(),
    createdAt: new Date().toISOString(),
  };
}

const SEAGPT_CHAT_CATEGORIES = [
  'port-restrictions',
  'general',
  'agent-email',
  'draft-agent-email',
  'update',
  'web-search',
  'flight-search',
  'flight-ask',
] as const;

const SEAGPT_CHAT_TYPE = [
  'user',
  'assistant',
  'logger',
  'system-guide',
] as const;

const CHAT_COLOR_MAPPING: { [key in SeaGPTChatType]: string } = {
  assistant: `rgba(187, 199, 247, 0.2)`,
  user: `rgba(78, 205, 196, 0.2)`,
  logger: `rgba(0,0,0,0)`,
  'system-guide': `rgba(255, 165, 0, 0.2)`,
};

const CHAT_LABEL_MAPPING: { [key in SeaGPTChatType]: string } = {
  'system-guide': 'Greywing',
  assistant: 'pRoteus',
  user: 'you',
  logger: 'logger',
};

export {
  START_PROMPT_V2,
  SEAGPT_CHAT_CATEGORIES,
  SEAGPT_CHAT_TYPE,
  CHAT_LABEL_MAPPING,
  CHAT_COLOR_MAPPING,
};
