import { memo } from 'react';
import styled from 'styled-components/macro';

import { textGray, black, white, gray70, gray40 } from 'lib/colors';
import { formatDate } from 'utils/format-date';

import { FlightStepDetails } from 'components/FlotillaSearch/types';
import Tooltip from '../../Tooltip';
import { CabinClass } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 180px;
`;

const AirportCode = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
`;

const City = styled(Text)`
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FlightType = styled(Text)`
  color: ${textGray};
  font-style: italic;
`;

const CustomTime = styled(Text)`
  color: ${black};
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
`;

const FlexWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

const SeatTag = styled.div`
  font-size: 0.725rem;
  font-weight: 500;
  letter-spacing: 0.033rem;
  padding: 0.2rem 0.4rem;
  white-space: nowrap;
  border-radius: 4px;
  color: ${gray70};
  z-index: 1;
  background-color: ${white};
  border: 1px solid ${gray40};
`;

type TooltipProps = {
  type: string;
  time?: string;
};

type Props = FlightStepDetails & {
  cabinClass?: CabinClass;
  numSeatsAvailable?: number | undefined;
};

const TextWithTooltip = ({ type, time }: TooltipProps) => {
  const [hour, date] = formatDate(time, 'HH:mm, DD MMM').split(', ');
  const tooltipContent = (
    <span>
      Flight {renderType(type)?.toLowerCase()} at{' '}
      <b>
        {hour}, {date}
      </b>
    </span>
  );
  return (
    <Tooltip content={tooltipContent}>
      <CustomTime>
        {formatDate(time, 'DD MMM YY')}, {hour}
      </CustomTime>
    </Tooltip>
  );
};
const renderType = (type: string) => {
  if (type === 'Departure') return 'Departs';
  if (type === 'Arrival') return 'Arrives';
};

function FlightInfoV2({ ...flightDetails }: Props) {
  const { type, city, time, iataCode, cabinClass, numSeatsAvailable } =
    flightDetails;

  return (
    <InfoWrapper>
      <FlexWrapper>
        <AirportCode>{iataCode}</AirportCode>
        {cabinClass && numSeatsAvailable ? (
          <SeatTag>
            {numSeatsAvailable} Seat(s) - {cabinClass}
          </SeatTag>
        ) : null}
      </FlexWrapper>
      <City>{city}</City>

      <FlexWrapper>
        <FlightType>{renderType(type)}: </FlightType>
        <TextWithTooltip type={type} time={time} />
      </FlexWrapper>
    </InfoWrapper>
  );
}

export default memo(FlightInfoV2);
