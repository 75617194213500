import {
  GridAlignment,
  GridColDef,
  GridActionsCellItem,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import MuiDeleteIcon from '@mui/icons-material/DeleteOutlined';
import MuiEditIcon from '@mui/icons-material/EditOutlined';
import styled from 'styled-components/macro';

import { SetModal } from 'hooks/useModal';
import { secondsToDHM } from 'lib/common';
import { iconGray, textGray } from 'lib/colors';
import { formatDate } from 'utils/format-date';

import { Tooltip } from 'components/shared';
import { getAlternateDate } from '../../helpers';
import Info from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(Info)`
  font-size: 18px !important;
  color: ${iconGray};
  margin-left: 6px;
`;

const DateWrapper = styled.div<{ $default: boolean }>`
  ${({ $default }) => $default && `color: ${textGray}`};
`;

export const renderHeader = ({
  field,
  colDef,
}: {
  field: string;
  colDef: GridColDef;
}) => {
  const { headerName } = colDef;
  const showIcon = ['eta', 'etd'].includes(field);
  const columnHeader = (
    <FlexWrapper>
      {headerName}
      {showIcon ? <InfoIcon /> : null}
    </FlexWrapper>
  );

  return showIcon ? (
    <Tooltip
      content={`Greyed out date(s) are suggested ${field.toUpperCase()}(s)`}
    >
      {columnHeader}
    </Tooltip>
  ) : (
    columnHeader
  );
};

export const getGridColumns = (
  unit: 'NM' | 'KM' | undefined = 'NM',
  setModal: SetModal
): GridColDef[] => [
  {
    field: 'text',
    headerName: 'Port - (LOCODE) Name',
    minWidth: 180,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { displayName, port } = row;
      const portName =
        port?.locode && port?.name
          ? `(${port.locode}) ${port.name}`
          : displayName;
      return (
        <span data-id="e2e_route-port-name">{portName || 'Unknown port'}</span>
      );
    },
  },
  {
    field: 'eta',
    headerName: 'Arrival',
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { eta, etd } = row;
      // show port `eta` or get it from 'etd`, if missing
      const portETA = eta || getAlternateDate('eta', etd);
      return (
        <DateWrapper $default={!eta} data-id="e2e_route-port-eta">
          {formatDate(portETA, 'HH:mm, DD MMM YY')}
        </DateWrapper>
      );
    },
  },
  {
    field: 'etd',
    headerName: 'Departure',
    minWidth: 120,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { eta, etd } = row;
      // show port `etd` or get it from 'eta`, if missing
      const portETD = etd || getAlternateDate('etd', eta);
      return (
        <DateWrapper $default={!etd} data-id="e2e_route-port-etd">
          {formatDate(portETD, 'HH:mm, DD MMM YY')}
        </DateWrapper>
      );
    },
  },
  { field: 'type', headerName: 'type', maxWidth: 150 },
  {
    field: 'distance',
    headerName: 'Distance',
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      const distance = Math.round(params.row.distance);
      return distance ? `${distance} ${unit}` : '-';
    },
  },
  {
    field: 'time',
    headerName: `Time to Next Port`,
    minWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const { time } = params.row;
      return <span>{time ? secondsToDHM(time) : '-'}</span>;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    maxWidth: 100,
    headerAlign: 'center' as GridAlignment,
    align: 'center' as GridAlignment,
    type: 'string',
    renderCell: ({ row: port }: GridRenderCellParams) => (
      <FlexWrapper>
        <Tooltip enterDelay={200} content="Remove this port from the route">
          <GridActionsCellItem
            icon={<MuiDeleteIcon />}
            label="Delete"
            onClick={() => setModal('confirmRemove', { port })}
            color="error"
            size="small"
          />
        </Tooltip>
        <Tooltip enterDelay={200} content="Edit this port">
          <GridActionsCellItem
            icon={<MuiEditIcon />}
            label="Delete"
            onClick={() => setModal('routePort', { type: 'update', port })}
            color="info"
            size="small"
          />
        </Tooltip>
      </FlexWrapper>
    ),
  },
];

export const getReadOnlyColumns = (
  unit: 'KM' | 'NM' | undefined = 'NM'
): GridColDef[] => [
  {
    field: 'custom',
    maxWidth: 20,
    type: 'string',
    headerName: '',
  },
  {
    field: 'text',
    headerName: 'Port',
    minWidth: 180,
    renderCell: ({ row }: GridRenderCellParams) => {
      const { displayName, text, port } = row;
      const portName =
        port?.locode && port?.name
          ? `(${port.locode}) ${port.name}`
          : displayName;

      return (
        // this uses row.text as there is old data that still makes use of text instead
        <span>{portName || text || 'Unknown port'}</span>
      );
    },
  },
  {
    field: 'eta',
    headerName: 'Arrival',
    minWidth: 120,
    renderCell: (params: GridRenderCellParams) => {
      const { eta, etd } = params.row;
      // show port `eta` or get it from 'etd`, if missing
      const portETA = eta || getAlternateDate('eta', etd);
      return <DateWrapper $default={!eta}>{formatDate(portETA)}</DateWrapper>;
    },
  },
  {
    field: 'etd',
    headerName: 'Departure',
    minWidth: 120,
    renderCell: (params: GridRenderCellParams) => {
      const { eta, etd } = params.row;
      // show port `etd` or get it from 'eta`, if missing
      const portETD = etd || getAlternateDate('etd', eta);
      return <DateWrapper $default={!etd}>{formatDate(portETD)}</DateWrapper>;
    },
  },
  { field: 'type', headerName: 'type', maxWidth: 150 },
  {
    field: 'distance',
    headerName: 'Distance',
    maxWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const { distance } = params.row;
      return distance ? `${Math.round(distance)} ${unit}` : '-';
    },
  },
  {
    field: 'time',
    headerName: `Time`,
    maxWidth: 150,
    renderCell: (params: GridRenderCellParams) => {
      const { time } = params.row;
      return <span>{time ? secondsToDHM(time) : '-'}</span>;
    },
  },
];
