import uniqueId from 'lodash/uniqueId';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { selectCrewChangePanel, selectSettings } from 'redux/selectors';

import { getReadOnlyColumns } from './Header';
import { ReadOnlyRouteActions } from '../Actions/Route';
import { NoRowsOverlay } from '../../common/TableItems';
import ReadOnlyControls from '../../common/Controls/ReadOnly';
import { headerStyles, TableWrapper } from '../../common';

function ReadOnlyRouteTable(): JSX.Element {
  const {
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);
  const { readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );

  const {
    route = [],
    ports = [],
    portFilters: { unit = 'NM' } = {},
  } = reportData?.crewChangePlan || {};

  const columns = getReadOnlyColumns(unit).map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: item.maxWidth || 300,
  }));

  return (
    <>
      <ReadOnlyControls nextUnavilable={!ports.length}>
        <ReadOnlyRouteActions routePorts={route} />
      </ReadOnlyControls>

      <TableWrapper>
        <DataGridPro
          columns={columns}
          rows={route}
          checkboxSelection={false}
          disableMultipleSelection
          disableColumnMenu
          disableSelectionOnClick
          disableColumnReorder
          hideFooter
          getRowId={uniqueId}
          density={isCompact ? 'compact' : 'standard'}
          sx={headerStyles}
          getRowClassName={({ row }) => (row.added ? 'added-port' : '')}
          components={{ NoRowsOverlay }}
          componentsProps={{
            noRowsOverlay: { type: 'ports' },
          }}
        />
      </TableWrapper>
    </>
  );
}

export default memo(ReadOnlyRouteTable);
