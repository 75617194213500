import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, TextField } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import styled from 'styled-components/macro';
import { styled as muiStyled } from '@mui/material/styles';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import { CabinClass } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

import { useAppDispatch, useMobile } from 'hooks';
import sleep from 'lib/sleep';
import { blue, blueGray, white } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { BREAK_POINT_CUSTOM_XS, BREAK_POINT_XXS } from 'lib/breakpoints';
import { setCopilotSearch, updateFlightSearch } from 'redux/actions';
import { searchFlightsAsync } from 'redux/thunks';
import { selectRecentSearches } from 'redux/selectors';
import { parseFlightSearchQuery } from 'redux/helpers/flotillaSearch';
import { RootState } from 'redux/types';
import {
  TRACK_QUICKFLY_DATE_INPUT,
  TRACK_QUICKFLY_DATE,
  TRACK_QUICKFLY_INPUT,
  TRACK_QUICKFLY_FETCH_FLIGHTS,
} from 'utils/analytics/constants';
import { SearchContext } from 'contexts/SearchContext';

import { Button } from 'components/shared';
import AirportDropdown from 'components/CrewChangePanel/common/AirportDropdown';
import { getInitialFlightSearchParams } from 'components/FlotillaSearch/helpers';
import FlightDatePicker, { DateType } from './DatePicker';
import SendIcon from '@mui/icons-material/Send';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div<{ $isModal?: boolean }>`
  ${({ $isModal }) =>
    $isModal
      ? `
    margin: 0.5rem;
    margin-right: 1.5rem;
  `
      : `
    margin-top: 0.25rem;
  `};
  background: ${white};
  padding: 1rem 0.5rem;
  padding-right: 0;
  border-radius: 6px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const InputWrapper = styled(FlexWrapper)<{ $isModal?: boolean }>`
  ${({ $isModal }) =>
    $isModal &&
    `
    display: grid;
    grid-template-columns: 1fr 2rem 1fr;
    grid-gap: 0.5rem;
    margin-right: 0.75rem;
  `};
`;

const Text = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0 0.33rem;
`;

const Instruction = styled(Text)<{ $isModal?: boolean }>`
  margin-left: 0.25rem;
  margin-bottom: 1rem;
  font-weight: 400;
  ${({ $isModal }) => $isModal && `font-size: 1rem`};
`;

const ConnectText = styled(Text)`
  margin: 0 0.75rem;
  @media screen and (max-width: ${BREAK_POINT_CUSTOM_XS}) {
    margin: 0 0.25rem;
  }
`;

const ActionButton = styled(Button)<{ $fromCache?: boolean }>`
  width: 32.5%;
  height: 35px;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 3px;
  text-transform: uppercase;
  background: ${({ $fromCache }) => ($fromCache ? blueGray : blue)};
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  svg {
    font-size: 15px !important;
  }
`;

const StyledTextField = muiStyled(TextField)(() => ({
  '.MuiInputBase-root': {
    fontSize: '0.85rem',
    height: 35,
  },
  '.MuiInputLabel-root': {
    fontSize: '0.8rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type ControlInputs = {
  arrival?: AirportCommon | null;
  departure?: AirportCommon | null;
  date?: string;
};

type Props = {
  recent?: boolean;
  isModal?: boolean;
};

const CABIN_CLASSES: { [key: string]: string } = {
  all: 'ALL',
  economy: 'ECONOMY',
  business: 'BUSINESS',
  'premium economy': 'PREMIUM_ECONOMY',
  'first class': 'FIRST',
};

const getInitialCabinClass = (current: CabinClass) =>
  Object.keys(CABIN_CLASSES)
    .find((key) => CABIN_CLASSES[key] === current)
    ?.toUpperCase() || CABIN_CLASSES.all;

function FlightControls({ recent, isModal }: Props) {
  const dispatch = useAppDispatch();
  const {
    searchType: { meta },
    setInputStr,
  } = useContext(SearchContext);

  const recentSearches = useSelector(selectRecentSearches);
  const flightSearch = useSelector(
    ({ flotillaSearch }: RootState) => flotillaSearch.flightSearch
  );
  const copilotSearch = useSelector(
    ({ flotillaSearch }: RootState) => flotillaSearch.copilotSearch
  );

  const isCustomView = useMobile(BREAK_POINT_CUSTOM_XS);
  const isXXSDevice = useMobile(BREAK_POINT_XXS);

  const { date, departureAirport, arrivalAirport, cabinClass } =
    getInitialFlightSearchParams(
      flightSearch,
      recentSearches,
      recent,
      !!copilotSearch
    );

  const cabinClasses = useMemo(
    () => Object.keys(CABIN_CLASSES).map((val) => val.toUpperCase()),
    []
  );
  const { departure, arrival } = useMemo(
    () => parseFlightSearchQuery(meta.search),
    [meta.search]
  );

  const [codes, setCodes] = useState({ arrival, departure });
  const [time, setTime] = useState<DateType>({
    valid: true,
    dateStr: date,
  });
  // local state to store input updates
  // passed ro redux store when flights are fetched
  const [controlInputs, setControlInputs] = useState<ControlInputs | null>(
    null
  );
  const [cabinClassInput, setCabinClassInput] = useState({
    open: false,
    value: getInitialCabinClass(cabinClass),
  });

  const { loading, fromCache } = flightSearch;
  const disabled =
    loading ||
    !codes.departure.trim() ||
    !codes.arrival.trim() ||
    !time.valid ||
    !cabinClassInput.value;
  const dropdownStyles = {
    width:
      (isModal && '100%') || (isCustomView ? (isXXSDevice && 120) || 150 : 210),
  };

  const handleOpenCabinClass = useCallback(
    (open: boolean) => {
      // async update to prevent closing of flotilla search
      sleep(100).then(() => {
        setCabinClassInput((prev) => ({ ...prev, open }));
        // prevent closing flotilla-search when interacting with cabin-class auto-complete
        dispatch(updateFlightSearch({ listOpen: open }));
      });
    },
    [dispatch]
  );

  const handleFetchFlights = async () => {
    const searchQuery = `${codes.departure} to ${codes.arrival}`;
    const flightReqParams = {
      searchQuery,
      time: time.dateStr || new Date().toISOString(),
      cabinClass: (cabinClassInput.value !== 'ALL'
        ? CABIN_CLASSES[cabinClassInput.value.toLowerCase()]
        : undefined) as CabinClass,
      ...(fromCache ? { refresh: fromCache } : {}),
    };
    setInputStr(`quickfly: ${searchQuery}`);
    // Forced to close, when an auto copilot search is triggered, listOpen is stuck at true
    dispatch(
      updateFlightSearch({
        ...flightReqParams,
        ...(controlInputs || {}),
        listOpen: false,
      })
    );
    // delay to finish previous redux store update
    await sleep(0);
    dispatch(searchFlightsAsync(flightReqParams));
    trackUserAction(TRACK_QUICKFLY_FETCH_FLIGHTS, 'click', {
      type: fromCache ? 'Refresh Flights' : 'Get Flights',
      departure: codes.departure,
      arrival: codes.arrival,
      date: time.dateStr || new Date().toISOString(),
    });
  };

  const handleUpdateTime = (
    timeObj: DateType,
    type?: 'keyboard-input' | 'picker-input'
  ) => {
    setTime(timeObj);
    if (timeObj.valid) {
      type === 'keyboard-input' &&
        trackUserAction(TRACK_QUICKFLY_DATE, 'typed', {
          date: timeObj.dateStr,
        });
      type === 'picker-input' &&
        trackUserAction(TRACK_QUICKFLY_DATE_INPUT, 'click', {
          date: timeObj.dateStr,
        });
      setControlInputs((prev) => ({ ...(prev || {}), date: timeObj.dateStr }));
    }
  };

  const handleSelect =
    (type: 'arrival' | 'departure') => (airport: AirportCommon | null) => {
      setCodes((codes) => ({ ...codes, [type]: airport?.iataCode || '' }));
      // state update when popup is open to prevent closing search results
      if (airport) {
        trackUserAction(TRACK_QUICKFLY_INPUT(type), 'typed', {
          [type]: airport?.iataCode,
        });
        setControlInputs((prev) => ({ ...(prev || {}), [type]: airport }));
      }
    };

  /*
    initialize component state & redux store for existing values
    in case of, either triggering from a recent search
    or opening a previously closed but unfinished search
  */
  useEffect(() => {
    setCodes({
      arrival: arrivalAirport?.iataCode || '',
      departure: departureAirport?.iataCode || '',
    });
    dispatch(
      updateFlightSearch({
        date,
        departure: departureAirport,
        arrival: arrivalAirport,
        cabinClass,
      })
    );
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!copilotSearch) return;
    // only for copilot search
    dispatch(setCopilotSearch(''));
    if (arrivalAirport && departureAirport) {
      sleep().then(() => {
        handleFetchFlights();
      });
    }
  }, [copilotSearch]); // eslint-disable-line

  return (
    <Wrapper $isModal={isModal}>
      <Instruction $isModal={isModal}>
        Select departure and arrival airports
      </Instruction>
      <InputWrapper $isModal={isModal}>
        <AirportDropdown.Crew
          type="searchFlights"
          label="Departure"
          placeholder="Departure airport name"
          autoFocus
          disabled={loading}
          selected={departureAirport}
          onSelect={handleSelect('departure')}
          styles={dropdownStyles}
        />
        <ConnectText>to</ConnectText>
        <AirportDropdown.Crew
          type="searchFlights"
          label="Arrival"
          placeholder="Arrival airport name"
          disabled={loading}
          selected={arrivalAirport}
          onSelect={handleSelect('arrival')}
          styles={dropdownStyles}
        />
      </InputWrapper>

      <FlexWrapper style={{ marginTop: '0.75rem' }}>
        <Autocomplete
          freeSolo
          filterSelectedOptions
          size="small"
          disabled={loading}
          open={cabinClassInput.open}
          options={cabinClasses}
          value={cabinClassInput.value}
          onOpen={() => handleOpenCabinClass(true)}
          onClose={() => handleOpenCabinClass(false)}
          onChange={(_, newValue) =>
            setCabinClassInput((prev) => ({ ...prev, value: newValue! }))
          }
          renderInput={(params) => (
            <StyledTextField
              {...params}
              size="small"
              label="Cabin Class"
              placeholder="Select an option"
            />
          )}
          ListboxProps={{ style: dropDownStyles }}
          sx={{ width: '37.5%' }}
        />
        <FlightDatePicker
          time={time}
          disabled={loading}
          updateTime={handleUpdateTime}
        />
        <ActionButton
          $fromCache={fromCache}
          disabled={disabled}
          onClick={handleFetchFlights}
        >
          {fromCache ? 'Refresh' : 'Get Flights'}
          <StyledIcon>{fromCache ? <SyncIcon /> : <SendIcon />}</StyledIcon>
        </ActionButton>
      </FlexWrapper>
    </Wrapper>
  );
}

export default memo(FlightControls);
