import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { Crew } from './types/crew-change-types';

const crew = {
  rank: 'Mast',
  name: 'Volkov',
  countryCode: 'RUS',
  country: 'Russia',
  dueDate: '2022-01-10T15:06:55.178Z',
  variance: 11,
  manningOffice: 'Euronav-RUS',
  age: 54,
  lastVessel: {
    name: 'Corpus Christi',
  },
  ready: '2022-01-10T15:06:55.178Z',
  homeAirport: null,
  flotillaVesselId: 1234,
};

const crewList: Crew[] = [
  { id: 1, cid: '2561', type: CrewType.offsigner, ...crew },
  { id: 2, cid: '2562', type: CrewType.offsigner, ...crew },
  { id: 3, cid: '2563', type: CrewType.onsigner, ...crew },
  { id: 4, cid: '2564', type: CrewType.offsigner, ...crew },
  { id: 5, cid: '2565', type: CrewType.onsigner, ...crew },
  { id: 6, cid: '2566', type: CrewType.offsigner, ...crew },
  { id: 7, cid: '2567', type: CrewType.onsigner, ...crew },
  { id: 8, cid: '2568', type: CrewType.offsigner, ...crew },
  { id: 9, cid: '2569', type: CrewType.onsigner, ...crew },
  { id: 10, cid: '2570', type: CrewType.offsigner, ...crew },
];

const sampleEvents = [
  {
    id: 8609,
    name: 'Corpus Christi',
    locode: 'USDCCS',
    onsigners: 2,
    offsigners: 3,
    eta: '2022-01-10T15:06:55.178Z',
    etd: '2022-01-10T15:06:55.178Z',
    crew: crewList.slice(0, 5),
    externalIdentifier: null,
    airport: null,
  },
  {
    id: 8915,
    name: 'Singapore',
    locode: 'SGSIN',
    onsigners: 4,
    offsigners: 6,
    eta: '2022-01-10T15:06:55.178Z',
    etd: '2022-01-10T15:06:55.178Z',
    crew: crewList,
    externalIdentifier: null,
    airport: null,
  },
];

export default sampleEvents;
