import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useModal, useAppDispatch } from 'hooks';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_SELECT_ALL_CREW,
  TRACK_GOTO_REVIEW_ROUTE,
  TRACK_GOTO_PORTS,
  TRACK_SELECT_TOP_3_PORTS,
  TRACK_GOTO_FLIGHTS,
  TRACK_COMPARE_FLIGHTS,
  TRACK_GO_BACK,
  TRACK_CTELEPORT_FLIGHTS_STEP,
} from 'utils/analytics/constants';
import {
  completeOnboardStep,
  stopOneClickCrewChange,
  closeSidePanel,
} from 'redux/actions';
import {
  selectCrewChangePanel,
  selectOnboard,
  selectSettings,
} from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { CommonConfirmModal } from 'components/shared';
import CTeleportButton from '../CTeleportButton';
import ShareLinkModal from '../LinkModals';
import StepButton from '../StepButton';
import { ControlsChildWrapper, ControlsWrapper } from '../../common';
import {
  findStepIndex,
  getStepDetails,
  hasInvalidSelectedCrew,
  hasVendorDuplicate,
  steps,
} from '../../helpers';

type ControlProps = {
  // props passed for ports table controls
  portProps?: {
    selectPorts: () => void;
  };
  // props passed for crew table controls
  crewProps?: {
    selectAll: (() => void) | undefined; // selecting all crew in `crew` step
  };
  children: ReactNode;
  disablePrev?: boolean;
  disableNext?: boolean;
};

function Controls({
  crewProps,
  portProps,
  disablePrev,
  disableNext,
  children,
}: ControlProps) {
  const dispatch = useAppDispatch();
  const { selectAll } = crewProps || {};
  const { selectPorts } = portProps || {};
  const {
    reportInfo: { savedLink },
    planningData: { crew },
    tableState: { step, view },
    updateTableState,
    data,
    filters,
  } = useContext(CCPanelContext);
  const { stepsCompleted } = useSelector(selectOnboard);
  const { isOneClickPlan } = useSelector(selectCrewChangePanel);
  const { userInfo } = useSelector(selectSettings);
  const { modal, setModal } = useModal();

  const isCTeleportEnabled = userInfo?.access['C-Teleport Booking'] ?? false;
  const stepDetails = useMemo(() => {
    const canSelectAllCrew = step === 'crew' && !data.length && !!selectAll;
    const canSelectTop3 = step === 'ports' && !data.length && !!selectPorts;
    return getStepDetails({ canSelectAllCrew, canSelectTop3, view })[step];
  }, [step, data.length, selectAll, selectPorts, view]);
  const isCompareView = useMemo(
    () => step === 'flights' && view === 'compare',
    [step, view]
  );

  const stepIndex = findStepIndex(step);
  const showCustomStyle = step === 'crew';

  // Update onboarding
  useEffect(() => {
    if (!stepsCompleted.some((o) => o.type === 'start-cc-compare')) {
      if (view === 'compare') {
        dispatch(completeOnboardStep('start-cc-compare'));
      }
    }
  }, [stepsCompleted, view]); // eslint-disable-line

  const handleSelectStep = (type: 'next' | 'previous') => {
    // get back to `filter` view for flights table from `compare` view
    // this is just switching view, not going back to previous step
    if (isCompareView) {
      updateTableState({ type: 'view', payload: 'filter' });
      return;
    }
    const newIndex = type === 'next' ? stepIndex + 1 : stepIndex - 1;
    updateTableState({ type: 'step', payload: steps[newIndex] });
  };

  const handleBackAction = () => {
    const showConfirmation =
      step === 'flights' && view === 'filter' && hasVendorDuplicate(filters);
    // trigger a confirmation modal when user tries to go back to previous step from `flights` step
    // if there's a vendor-duplicate/source-specific port-card available
    if (showConfirmation) {
      setModal('confirmVendorDuplicate');
      return;
    }

    trackUserAction(TRACK_GO_BACK(step));
    handleSelectStep('previous');
  };

  const handleForwardAction = () => {
    switch (step) {
      case 'crew': {
        if (!data.length) {
          trackUserAction(TRACK_SELECT_ALL_CREW);
          selectAll?.();
        } else {
          if (hasInvalidSelectedCrew(crew)) {
            let message = 'Invalid crew airport. Please update.';
            if (isOneClickPlan) {
              message =
                'Invalid crew airport. Crew change planning has stopped. Please update.';
              dispatch(stopOneClickCrewChange()); // stop one-click crew-change if currently active
            }
            showToaster({ message, type: 'error' });
            return;
          }
          trackUserAction(TRACK_GOTO_REVIEW_ROUTE);
          handleSelectStep('next');
        }
        break;
      }
      case 'route': {
        // hide sidepanel when user reaches ports step
        dispatch(closeSidePanel());
        handleSelectStep('next');
        trackUserAction(TRACK_GOTO_PORTS);
        break;
      }
      case 'ports': {
        if (!data.length) {
          trackUserAction(TRACK_SELECT_TOP_3_PORTS);
          selectPorts?.();
        } else {
          handleSelectStep('next');
          trackUserAction(TRACK_GOTO_FLIGHTS);
        }
        break;
      }
      case 'flights': {
        if (view === 'filter') {
          updateTableState({ type: 'view', payload: 'compare' });
          trackUserAction(TRACK_COMPARE_FLIGHTS);
        } else {
          setModal(savedLink ? 'copyLink' : 'addUserNotes');
        }
        break;
      }
      default:
        handleSelectStep('next');
        break;
    }
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    if (['copyLink', 'addUserNotes'].includes(modal?.type)) {
      return <ShareLinkModal modal={modal} setModal={setModal} />;
    }

    if (modal.type === 'confirmVendorDuplicate') {
      const description = (
        <>
          <div data-testid="e2e_split-card-prev-step-confirm">
            Port cards with specific flight source will be removed. You can
            create them later again.
          </div>
          <div>Are you sure you want to go back?</div>
        </>
      );
      return (
        <CommonConfirmModal
          description={description}
          onConfirm={() => {
            trackUserAction(TRACK_GO_BACK(step));
            handleSelectStep('previous');
          }}
          onCancel={() => setModal(null)}
        />
      );
    }
  };

  const cTeleportPortData = useMemo(() => {
    if (view === 'compare' && isCTeleportEnabled) {
      const port = Object.values(filters).find((item) => item.active === true);
      if (port)
        return {
          eta: new Date(port.portDates?.eta || ''),
          etd: new Date(port.portDates?.etd || ''),
          airport: port.portAirport,
        };
    }
    return null;
  }, [filters, isCTeleportEnabled, view]);

  return (
    <>
      <ControlsWrapper>
        <StepButton
          variant="secondary"
          disabled={disablePrev}
          direction="left"
          hide={step === 'crew'}
          onClick={handleBackAction}
        >
          {stepDetails.prevButton}
        </StepButton>

        <ControlsChildWrapper
          $custom={showCustomStyle}
          $isCTeleportVisible={!!cTeleportPortData}
        >
          {children}
        </ControlsChildWrapper>

        {cTeleportPortData ? (
          <CTeleportButton
            type="large"
            portData={cTeleportPortData}
            trackAction={TRACK_CTELEPORT_FLIGHTS_STEP}
          />
        ) : null}
        <StepButton
          variant={view === 'compare' ? 'success' : 'primary'}
          disabled={disableNext}
          onClick={handleForwardAction}
          direction="right"
          icon={stepDetails.icon}
        >
          {stepDetails.nextButton}
        </StepButton>
      </ControlsWrapper>

      {renderModal()}
    </>
  );
}

export default Controls;
