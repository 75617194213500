import { v4 as uuidv4 } from 'uuid';
import {
  ActivityType,
  Activities,
} from '@greywing-maritime/frontend-library/dist/utils/amplitude';

import {
  TRACK_SESSION_START,
  TRACK_SESSION_END,
} from 'utils/analytics/constants';
import { checkAppVersion } from './common';

let sessionId: string | null;

export const trackUserAction = (
  eventId: string,
  activityType: ActivityType | Activities = ActivityType.click,
  options?: any
) => {
  const disableAmplitude = localStorage.getItem('disable-amplitude') || false;
  if (disableAmplitude) return;
  import('@greywing-maritime/frontend-library/dist/utils/amplitude').then(
    (amplitude) => {
      const { logUserAction } = amplitude;
      logUserAction({ type: activityType, eventName: eventId }, options);
    }
  );
};

export const trackIndirect = (eventId: string, options?: any) => {
  const disableAmplitude = localStorage.getItem('disable-amplitude') || false;
  if (disableAmplitude) return;
  import('@greywing-maritime/frontend-library/dist/utils/amplitude').then(
    (amplitude) => {
      const { ActivityType, logUserAction } = amplitude;
      logUserAction(
        {
          type: ActivityType.indirect,
          eventName: eventId,
        },
        options
      );
    }
  );
};

export const trackUserSession = () => {
  const disableAmplitude = localStorage.getItem('disable-amplitude') || false;
  if (disableAmplitude) return;
  sessionId = sessionId || uuidv4();
  if (document.visibilityState === 'visible') {
    trackIndirect(TRACK_SESSION_START, {
      sessionId,
      time: new Date().toISOString(),
    });
    checkAppVersion();
  }

  if (document.visibilityState === 'hidden') {
    trackIndirect(TRACK_SESSION_END, {
      sessionId,
      time: new Date().toISOString(),
    });
    sessionId = null;
  }
};
