import { Badge as MuiBadge, BadgeProps } from '@mui/material';
import styled from 'styled-components/macro';
import { black, blue, white } from 'lib/colors';

type NewBadgeProps = BadgeProps & {
  newColor?: string;
};

const StyledBadge = styled(MuiBadge)<{
  $backgroundColor?: string;
  $color?: string;
}>`
  .MuiBadge-badge {
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor || blue} !important;
    color: ${({ $color }) => $color || white} !important;
  }
`;

const Badge = ({ newColor, ...rest }: NewBadgeProps) => {
  if (newColor === 'dark') {
    return <StyledBadge $backgroundColor={black} $color={white} {...rest} />;
  }
  if (newColor === 'primary') {
    return <StyledBadge $backgroundColor={blue} $color={white} {...rest} />;
  }
  return <StyledBadge {...rest} />;
};

export default Badge;
