import { memo, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrivalIcon from '@mui/icons-material/FlightLand';
import DepartureIcon from '@mui/icons-material/FlightTakeoff';
import CalendarIcon from '@mui/icons-material/Event';
import moment, { Moment } from 'moment';
import styled, { css } from 'styled-components/macro';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useDebounce, usePrevious } from 'hooks';
import { blue, gray20, gray60, green, white } from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { Tooltip } from 'components/shared';

const commonStyles = css`
  width: 100%;
  height: 26px;
`;

const fontStyles = css`
  font-size: 0.85rem;
  line-height: 1.1rem;
  font-family: HK Grotesk, Roboto;
`;

const wrapperStyles = css`
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiOutlinedInput-input {
    ${fontStyles};
  }

  .MuiSvgIcon-root {
    height: 1rem;
    width: 1rem;
  }

  .MuiInputAdornment-root {
    margin-left: 2px;
  }

  .MuiOutlinedInput-root {
    border-radius: 0 0 4px 0;
    border-bottom: none !important;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled(FlexWrapper)`
  ${commonStyles}
  column-gap: 2rem;
  border-top: 1px solid ${gray60};
  padding: 0.25rem 1rem;
  letter-spacing: 0 !important; // override parent style
  background: ${gray20};
`;

const TextWrapper = styled(FlexWrapper)`
  ${fontStyles};
`;

const DateWrapper = styled.div`
  ${wrapperStyles};
  ${commonStyles}
  letter-spacing: 0 !important; // override parent

  .MuiInputBase-input {
    padding: 0.25rem 0.6rem;
    padding-right: 0;
    background: ${white};
  }
`;

const iconStyle = css`
  height: 1.25rem !important;
  width: 1.25rem !important;
`;

const StyledDepartureIcon = styled(DepartureIcon)`
  ${iconStyle};
  color: ${green};
`;

const StyledArrivalIcon = styled(ArrivalIcon)`
  ${iconStyle};
  color: ${blue};
`;

type PickerDates = {
  date?: string | null;
  inputText?: string;
};

type Props = {
  flightTime?: string; // read-only value available for already confirmed flights
  crewType: CrewType;
  onSelect: (date: string | undefined) => void;
};

const formatSelectedDate = (date: Date | Moment | string) =>
  moment(date).utcOffset(0, true).toISOString();

function FlightTimeInput({ flightTime, crewType, onSelect }: Props) {
  const [open, setOpen] = useState(false);
  const [dateObj, setDateObj] = useState<PickerDates>({
    date: null,
    inputText: '',
  });

  const prevOpen = usePrevious(open);
  const updateFinished = prevOpen && !open;
  const today = new Date();

  const debouncedInputUpdate = useDebounce((value?: string) => {
    const formattedDate = moment(value, 'HH:mm DD/MM/YY', true);
    const validDate = formattedDate.isValid();
    setDateObj({ inputText: validDate ? value : '' });
    // send updated port with new date back to parent component
    onSelect(validDate ? formatSelectedDate(formattedDate) : undefined);
  });

  const handleDateChange = useCallback(
    (newDate: Date | null, inputValue?: string | undefined) => {
      if (newDate) {
        setDateObj({ date: moment(newDate).toISOString(), inputText: '' });
        return;
      }
      debouncedInputUpdate(inputValue);
    },
    [debouncedInputUpdate]
  );

  useEffect(() => {
    if (updateFinished) {
      // send updated port with new date back to parent component
      onSelect(dateObj.date ? formatSelectedDate(dateObj.date) : undefined);
    }
  }, [updateFinished, dateObj.date, onSelect]);

  if (flightTime) {
    const tooltipContent = `Flight ${
      crewType === CrewType.offsigner ? 'departure' : 'arrival'
    } time`;
    const FlightIcon =
      crewType === CrewType.offsigner ? StyledDepartureIcon : StyledArrivalIcon;
    return (
      <Tooltip content={tooltipContent}>
        <ContentWrapper>
          <FlightIcon />
          <TextWrapper>{formatDate(flightTime, 'HH:mm DD/MM/YY')}</TextWrapper>
        </ContentWrapper>
      </Tooltip>
    );
  }

  const FlightIcon =
    crewType === CrewType.offsigner ? DepartureIcon : ArrivalIcon;
  const iconColor = crewType === CrewType.offsigner ? green : blue;

  return (
    <DateWrapper onClick={(event) => event.stopPropagation()}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disablePast
          disableMaskedInput
          defaultCalendarMonth={today}
          ampm={false}
          open={open}
          minDate={today}
          value={dateObj.date || null}
          inputFormat="HH:mm dd/MM/yy"
          onChange={handleDateChange}
          onOpen={() => setOpen(true)}
          onClose={() => {
            // adds a delay because input update is debounced
            setTimeout(() => {
              setOpen(false);
            }, 250);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              size="small"
              autoComplete="off"
              onClick={() => setOpen(true)}
              onKeyDown={(event) => {
                event.stopPropagation();
                // prevent keyboard input
                event.preventDefault();
              }}
              InputProps={{
                startAdornment: <FlightIcon sx={{ color: iconColor }} />,
                endAdornment: <CalendarIcon sx={{ color: gray60 }} />,
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: 'Click to choose a time',
              }}
            />
          )}
        />
      </LocalizationProvider>
    </DateWrapper>
  );
}

export default memo(FlightTimeInput);
