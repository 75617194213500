import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addRiskAreaTrackingRequest,
  deleteRiskAreaTrackingRequest,
  getGeofences,
  getRiskAreas,
  getRiskAreaTrackingRequests,
} from 'api/flotilla';
import { updateTrackingRequests } from 'redux/reducers/riskArea';
import { RootState } from 'redux/types';
import { defineFillLayerColors } from 'utils/risk-area-colors';
import { GeofenceWithColor } from 'utils/types';

/* ----- Async Thunks ----- */
export const fetchRiskAreaAsync = createAsyncThunk(
  'riskArea/fetchRiskAreaAsync',
  async (_, { getState }) => {
    const { riskArea } = getState() as RootState;
    if (riskArea.riskAreas.length) {
      return riskArea.riskAreas;
    }
    const { riskAreas } = await getRiskAreas();
    return riskAreas;
  }
);

export const fetchGeofenceAsync = createAsyncThunk(
  'riskArea/fetchGeofenceAsync',
  async (_, { getState }) => {
    const { riskArea } = getState() as RootState;
    if (Object.keys(riskArea.geofences).length) {
      return riskArea.geofences;
    }
    const { geofences } = await getGeofences();
    if (geofences) {
      const geofenceWithColor = defineFillLayerColors(geofences);
      return geofenceWithColor?.reduce<{ [key: number]: GeofenceWithColor }>(
        (all, geofence) => {
          all[geofence.id] = geofence;
          return all;
        },
        {}
      );
    }
    return null;
  }
);

export const fetchRiskAreaTrackingRequestAsync = createAsyncThunk(
  'riskArea/fetchRiskAreaTrackingRequestAsync',
  async (
    { vesselId, refetch }: { vesselId: number; refetch?: boolean },
    { getState, dispatch }
  ) => {
    const { riskArea } = getState() as RootState;
    if (riskArea.trackingRequests[vesselId] && !refetch) {
      return riskArea.trackingRequests[vesselId];
    }

    const { riskAreaTrackingRequests } = await getRiskAreaTrackingRequests(
      vesselId
    );
    if (!riskAreaTrackingRequests) return null;

    dispatch(
      updateTrackingRequests({
        vesselId,
        trackingRequest: riskAreaTrackingRequests,
      })
    );
    return riskAreaTrackingRequests;
  }
);

export const deleteRiskAreaTrackingRequestAsync = createAsyncThunk(
  'riskArea/deleteRiskAreaTrackingRequestAsync',
  async (
    { vesselId, riskAreaId }: { vesselId: number; riskAreaId: number },
    { dispatch }
  ): Promise<{ success: boolean; message?: string }> => {
    const { success, message } = await deleteRiskAreaTrackingRequest(
      vesselId,
      riskAreaId
    );

    if (!success) return { success: false, message };

    const { riskAreaTrackingRequests } = await getRiskAreaTrackingRequests(
      vesselId
    );

    if (!riskAreaTrackingRequests)
      return { success: false, message: 'failed to refetch data' };

    dispatch(
      updateTrackingRequests({
        vesselId,
        trackingRequest: riskAreaTrackingRequests,
      })
    );

    return { success: true };
  }
);

export const addRiskAreaTrackingRequestAsync = createAsyncThunk(
  'riskArea/addRiskAreaTrackingRequestAsync',
  async (
    { vesselId, riskAreaId }: { vesselId: number; riskAreaId: number },
    { dispatch }
  ): Promise<{ success: boolean; message?: string }> => {
    const { success, message } = await addRiskAreaTrackingRequest(
      vesselId,
      riskAreaId
    );

    if (!success) return { success: false, message };

    const { riskAreaTrackingRequests } = await getRiskAreaTrackingRequests(
      vesselId
    );

    if (!riskAreaTrackingRequests)
      return { success: false, message: 'failed to refetch data' };

    dispatch(
      updateTrackingRequests({
        vesselId,
        trackingRequest: riskAreaTrackingRequests,
      })
    );

    return { success: true };
  }
);
