import moment from 'moment';

import { getLatestAppVersion } from 'api/helpers';
import { formatDate } from 'utils/format-date';
import { UserInfo } from 'utils/types';
import { GREYWING_CMP_ACCOUNT } from 'components/CrewMatrixPlanning/constants';

import { ERROR_RELOAD_COUNT } from './constants';
import { isDevelopment, isProduction } from './environments';
import { showToaster } from './toaster';

// date format used for calculation during flight request
const FLIGHT_DATE_FORMAT = 'DD/MM/YYYY';

/********** Collection of common utils used in the app **********/

// checks for success or error with async/await
// a replacement for lodash `attempt` with promise handling
export const attemptAsync = async <T>(
  fn: () => Promise<T>
): Promise<T | Error> => {
  try {
    const result = await fn();
    return result;
  } catch (error: any) {
    return error;
  }
};

export const getRandomArrayItem = (array: any[]) =>
  array[Math.floor(Math.random() * array.length)];

// generate random string id
export const getRandomId = () => Math.random().toString(36).slice(2, 9);
// generate six digit random number id
export const generateRandomNumId = () =>
  Math.floor(100000 + Math.random() * 900000);

export const isValidEmail = (email: string) =>
  /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

export const removeWhiteSpace = (text: string) => text.replace(/\s+/g, '');

export const secondsToDHM = (seconds?: number, hideMin?: boolean): string => {
  if (!seconds) {
    return '-';
  }

  if (seconds < 60) {
    return '<1 min';
  }

  if (hideMin && seconds < 3600) {
    return '<1 hr';
  }

  const secondsInDay = 3600 * 24;
  const d = Math.floor(seconds / secondsInDay);
  const h = Math.floor((seconds % secondsInDay) / 3600);
  const m = Math.floor((seconds - (d * secondsInDay + h * 3600)) / 60);

  const days = d > 0 ? d + (d === 1 ? ' day' : ' days') : '';
  const hours = h > 0 ? h + (h === 1 ? ' hr' : ' hrs') : '';
  let minutes = '';

  if (!hideMin) {
    minutes = m > 0 ? m + (m === 1 ? ' min' : ' mins') : '';
  }

  return `${days}, ${hours}, ${minutes}`.split(', ').filter(Boolean).join(', ');
};

export const formatHour = (hour: number) => {
  if (hour === 0 || hour === 24) return '12AM';
  if (hour === 12) return '12PM';
  return hour < 12 ? `${hour}AM` : `${hour - 12}PM`;
};

// ISO date string from the start of day
// use pre-defined format for flight request
export const getStartOfDate = (date: string, useFormat?: boolean) => {
  const momentDate = useFormat
    ? moment(date, FLIGHT_DATE_FORMAT)
    : moment(date);
  return momentDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

// util used to prepare `startDate` param for flight request
export const getFlightReqTime = (date: string) => {
  const formattedDate = formatDate(date, FLIGHT_DATE_FORMAT);
  const formattedCurrentDate = formatDate(new Date(), FLIGHT_DATE_FORMAT);
  return formattedDate === formattedCurrentDate
    ? moment(date).add(2, 'minute').toISOString() // for current date, 2 mins offset from original time
    : getStartOfDate(formattedDate, true); // for future dates, set time to the start of that day
};

export const getDataId = (
  label: string,
  options: { prefix?: string; suffix?: string }
) => {
  const formattedLabel = label.toLowerCase().split(' ').join('-');
  const prefix = options.prefix ? `${options.prefix}_` : '';
  const suffix = options.suffix ? `_${options.suffix}` : '';
  return `${prefix}${formattedLabel}${suffix}`;
};

export const setErrorReloadCount = ({
  count,
  reset,
}: {
  count?: number;
  reset?: boolean;
} = {}) => {
  if (reset) {
    localStorage.setItem(ERROR_RELOAD_COUNT, '0');
    return;
  }

  const countString = localStorage.getItem(ERROR_RELOAD_COUNT);
  if (!countString) {
    localStorage.setItem(ERROR_RELOAD_COUNT, '0');
    return;
  }

  if (count) {
    localStorage.setItem(ERROR_RELOAD_COUNT, JSON.stringify(count));
  }
};

export const setWindowVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const setAnimationOptions = (data: any, loop: boolean = true) => ({
  loop,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid meet',
  },
});

// add any extra check for FRE feat
export const findFlightTrackAccess = (userInfo?: UserInfo) => {
  if (!userInfo) {
    return false;
  }

  const { access } = userInfo;
  return Boolean(access['Flight Rebooking Engine']);
};

export const findFlightBookAccess = (userInfo?: UserInfo) => {
  if (!userInfo) {
    return false;
  }

  const { access } = userInfo;
  return Boolean(access['Flight Booking']);
};

export const upgradeAppVersion = async () => {
  // get current version: handle version that has brnach included
  const [currentVersion] = (process.env.REACT_APP_VERSION_NAME || '').split(
    '+'
  );

  if (isDevelopment || !currentVersion) return;
  const { success, message, response } = await getLatestAppVersion();

  if (!success) {
    showToaster({ message, type: 'error' });
    return;
  }

  const { version: latestVersion } = response || {};
  return latestVersion &&
    latestVersion.localeCompare(currentVersion, undefined, {
      numeric: true,
    }) > 0
    ? { latestVersion, currentVersion }
    : undefined;
};

// check app version, if app is in background for more than 10 minutes
export const checkAppVersion = () => {
  const sessionStartedAt = localStorage.getItem('session_started_at');
  const timeDifference = moment().diff(moment(sessionStartedAt), 'minutes');
  if (timeDifference >= 10) {
    upgradeAppVersion();
  }
};

export const showCrewMatrixFeat = (userInfo: UserInfo | undefined) => {
  const { company, loginEmail } = userInfo || {};
  if (!company) return false;

  // check if IINO account - both staging & production
  const IINOCompanyId = isProduction ? 31 : 64;
  const isIINOAccount =
    company.id === IINOCompanyId && company.name.toLowerCase() === 'iino';
  // check if Greywing DEMO account
  const isStagingCMPAccount = loginEmail === GREYWING_CMP_ACCOUNT;
  // show crew-matrix planning feat for Greywing DEMO or IINO account(s)
  return isStagingCMPAccount || isIINOAccount;
};
