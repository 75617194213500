import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RECENT_SEARCHES_KEY } from 'lib/constants';
import {
  formatRecentSearches,
  sanitizeInitialState,
} from '../helpers/recentSearches';

import {
  Nullable,
  RecentSearches,
  RecentSearchContent,
  RootState,
} from '../types';

type CounterState = Nullable<RecentSearches>;
export const initialRecentSearches = sanitizeInitialState() as CounterState;

const recentSearches = createSlice({
  name: 'recentSearches',
  initialState: initialRecentSearches,
  reducers: {
    setRecentSearches: (state, action: PayloadAction<RecentSearchContent>) => {
      const newState = formatRecentSearches(state, action.payload);
      // update recent searches in localStorage
      localStorage.setItem(RECENT_SEARCHES_KEY, JSON.stringify(newState));
      return newState;
    },
  },
});

/* ----- selectors -----*/

export const selectRecentSearches = ({ recentSearches }: RootState) =>
  recentSearches;

/* ----- actions -----*/

export const { setRecentSearches } = recentSearches.actions;

/* ----- reducer -----*/

export default recentSearches.reducer;
