import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RiskArea } from '@greywing-maritime/frontend-library/dist/types/riskAreas';
import type { RiskAreaTrackingReqCommon } from '@greywing-maritime/frontend-library/dist/types/riskAreaTrackingRequests';
import { GeofenceWithColor } from 'utils/types';

import {
  fetchGeofenceAsync,
  fetchRiskAreaAsync,
  fetchRiskAreaTrackingRequestAsync,
} from '../thunks';
import { RiskAreaState, RootState } from '../types';

export const initialRiskArea: RiskAreaState = {
  isRiskAreasLoading: false,
  isGeofenceLoading: false,
  riskAreas: [],
  geofences: {},
  trackingRequests: {},
  trackingRequestsLoadState: {},
};

const riskAreaSlice = createSlice({
  name: 'riskArea',
  initialState: initialRiskArea,
  reducers: {
    updateTrackingRequests: (
      state,
      action: PayloadAction<{
        vesselId: number;
        trackingRequest: RiskAreaTrackingReqCommon[];
      }>
    ) => ({
      ...state,
      trackingRequests: {
        ...state.trackingRequests,
        [action.payload.vesselId]: action.payload.trackingRequest,
      },
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchRiskAreaTrackingRequestAsync.pending,
        (state, { meta: { arg } }) => ({
          ...state,
          trackingRequestsLoadState: {
            ...state.trackingRequestsLoadState,
            [arg.vesselId]: 'pending',
          },
        })
      )
      .addCase(
        fetchRiskAreaTrackingRequestAsync.fulfilled,
        (state, { meta: { arg } }) => ({
          ...state,
          trackingRequestsLoadState: {
            ...state.trackingRequestsLoadState,
            [arg.vesselId]: 'fulfilled',
          },
        })
      )
      .addCase(
        fetchRiskAreaTrackingRequestAsync.rejected,
        (state, { meta: { arg } }) => ({
          ...state,
          trackingRequestsLoadState: {
            ...state.trackingRequestsLoadState,
            [arg.vesselId]: 'rejected',
          },
        })
      )
      .addCase(
        fetchGeofenceAsync.fulfilled,
        (
          state,
          action: PayloadAction<{ [key: number]: GeofenceWithColor } | null>
        ) => {
          if (!action.payload) {
            return {
              ...state,
              isGeofenceLoading: false,
            };
          }
          return {
            ...state,
            geofences: action.payload,
            isGeofenceLoading: false,
          };
        }
      )
      .addCase(fetchGeofenceAsync.pending, (state) => {
        return { ...state, isGeofenceLoading: true };
      })
      .addCase(fetchRiskAreaAsync.pending, (state) => {
        return { ...state, isRiskAreasLoading: true };
      })
      .addCase(
        fetchRiskAreaAsync.fulfilled,
        (state, action: PayloadAction<RiskArea[] | null>) => {
          if (!action.payload) {
            return {
              ...state,
              isRiskAreasLoading: false,
            };
          }
          return {
            ...state,
            riskAreas: action.payload,
            isRiskAreasLoading: false,
          };
        }
      );
  },
});

/* ----- selectors -----*/

export const selectRiskArea = ({ riskArea }: RootState) => riskArea;

export const selectRiskAreaLoading = createSelector(
  (riskAreaState: RiskAreaState) => riskAreaState,
  ({ isGeofenceLoading, isRiskAreasLoading }) =>
    isGeofenceLoading || isRiskAreasLoading
);

/* ----- actions -----*/

export const { updateTrackingRequests } = riskAreaSlice.actions;

/* ----- reducer -----*/

export default riskAreaSlice.reducer;
