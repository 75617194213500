import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { memo, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import styled from 'styled-components/macro';

import { useDebounce } from 'hooks';
import { blue, gray20, gray30, red } from 'lib/colors';
import { FlightNumberDetails } from 'redux/types';
import { Flight } from 'utils/types/crew-change-types';

import { Button } from 'components/shared';
import Tooltip from '../../Tooltip';
import { FormWrapper, StyledInfoIcon } from '../common';
import { UpdateSearchOptions } from '../types';

const Container = styled.div`
  padding: 0.25rem 0.75rem;
  padding-top: 0.75rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
`;

const ActionButton = styled(Button)<{ $reset?: boolean; $search?: boolean }>`
  height: 100%;
  font-size: 0.8rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  margin-right: 0;
  background: ${blue};
  ${({ $search }) => $search && `margin-right: 0.5rem`};
  ${({ $reset }) =>
    $reset &&
    `
    background: ${red}40;
    color: ${red}
  `};
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 18px !important;
  }
`;

const autoCompleteStyles = {
  maxHeight: '300px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
  background: `${gray20}`,
  border: `1px solid ${gray30}`,
  borderRadius: '4px',
};

type Props = {
  flights: Flight[];
  searchOptions: FlightNumberDetails;
  updateOptions: UpdateSearchOptions;
};

const initialSearchOptions = { flightNumber: '', included: false };

function FlightNumberSearch({ flights, searchOptions, updateOptions }: Props) {
  const { included, flightNumber } = searchOptions;
  const [query, setQuery] = useState(flightNumber);
  const [numbersList, setNumbersList] = useState<string[]>([]);

  const handleReset = useCallback(() => {
    setQuery('');
    setNumbersList([]);
    updateOptions(initialSearchOptions);
  }, [updateOptions]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        setTimeout(() => {
          updateOptions({
            flightNumber: (event.target as HTMLInputElement).value,
          });
        }, 100);
      }
    },
    [updateOptions]
  );

  const debouncedSearch = useDebounce((query: string, flights: Flight[]) => {
    if (!query) {
      setNumbersList([]);
      updateOptions(initialSearchOptions);
      return;
    }

    const flightNumbers = uniq(
      flatten(flights.map(({ flightNumbers }) => flightNumbers || []))
    ).filter((text) => text.toLowerCase().includes(query.toLowerCase().trim()));

    setNumbersList(flightNumbers);
  });

  useEffect(() => {
    debouncedSearch(query, flights);
  }, [debouncedSearch, query, flights]);

  const disabledSearch = !query.trim() && !flightNumber.trim();

  return (
    <Container>
      <FlexWrapper>
        <FormWrapper>
          <FormControlLabel
            label="Apply current filters"
            control={
              <Checkbox
                className="e2e_flight-number"
                disabled={!flightNumber}
                checked={included}
                onChange={(_, included) => updateOptions({ included })}
              />
            }
          />
        </FormWrapper>
        <Tooltip content="Apply current filters with flight number to find matching flights">
          <StyledIcon>
            <StyledInfoIcon />
          </StyledIcon>
        </Tooltip>
      </FlexWrapper>

      <ContentWrapper>
        <Autocomplete
          disablePortal
          freeSolo
          disableClearable
          size="small"
          value={query}
          options={numbersList}
          onChange={(_, value) => setQuery(value || '')}
          onInputChange={(_, value) => setQuery(value)}
          onKeyDown={handleKeyDown}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Flight number"
              size="small"
              placeholder="Type to select flight numbers"
            />
          )}
          ListboxProps={{ style: autoCompleteStyles }}
        />

        <FlexWrapper>
          <ActionButton
            $search
            className="e2e_flight-number-search"
            disabled={disabledSearch}
            onClick={() => updateOptions({ flightNumber: query })}
          >
            Search
          </ActionButton>
          <ActionButton onClick={handleReset} $reset>
            Reset
          </ActionButton>
        </FlexWrapper>
      </ContentWrapper>
    </Container>
  );
}

export default memo(FlightNumberSearch);
