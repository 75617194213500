import React, { Suspense } from 'react';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import VesselDetailsActions from './VesselDetailsActions';
import CrewChangeEvents from './CrewChangeEvent/Container';
import CrewHomeAirport from './CrewHomeAirport';
import SavedCrewChangePlans from './SavedPlans';
import CrewNationality from './CrewNationality';
import VesselCourse from './VesselCourse';
import VesselInfo from './VesselInfo';
import MiscData from './MiscData';

import { CompoundSidePanelFields } from '../../utils/types';
import VesselTags from './VesselTags';

const RiskAreaTracking = React.lazy(() => import('./RiskAreaTracking'));
const NoEventContainer = React.lazy(
  () => import('./CrewChangeEvent/NoEventContainer')
);

export const compoundSidePanelFields: CompoundSidePanelFields = {
  compound_vesselButtonActions: {
    displayPriority: 0,
    persistent: true,
    shortDesc: 'Crew Change',
    longDesc: 'Vessel Crew Change',
    tooltip: 'Vessel Action Buttons',
    getDisplayElement: (vessel: Vessel) => (
      <VesselDetailsActions vessel={vessel} />
    ),
  },
  compound_stateCourseHeader: {
    // also referred to as voyage plan
    displayPriority: 1,
    persistent: false,
    shortDesc: 'Vessel Status',
    longDesc: 'Vessel Status',
    tooltip: 'A list of upcoming and historical port of calls for the vessel',
    getDisplayElement: (vessel: Vessel) => <VesselCourse vessel={vessel} />,
  },
  compound_crewChangeEvents: {
    displayPriority: 2,
    persistent: false,
    shortDesc: 'Crew Change',
    longDesc: 'Crew Change Events',
    tooltip: 'A list of upcoming crew change events',
    getDisplayElement: (vessel: Vessel) => <CrewChangeEvents vessel={vessel} />,
  },
  compound_incompletePlans: {
    displayPriority: 2,
    persistent: false,
    shortDesc: 'Unfinished Plans',
    longDesc: 'Unfinished Crew Change Plans',
    tooltip: 'A list of incomplete crew change plans of this vessel',
    getDisplayElement: (vessel: Vessel) => (
      <SavedCrewChangePlans vessel={vessel} isCompleted={false} />
    ),
  },
  compound_savedPlans: {
    displayPriority: 2,
    persistent: false,
    shortDesc: 'Saved Plans',
    longDesc: 'Saved Crew Change Plans',
    tooltip: 'A list of saved crew change plans of this vessel',
    getDisplayElement: (vessel: Vessel) => (
      <SavedCrewChangePlans vessel={vessel} isCompleted />
    ),
  },
  compound_riskAreaTracking: {
    displayPriority: 3,
    persistent: false,
    shortDesc: 'Tracking',
    longDesc: 'Risk Area Tracking',
    tooltip: 'A list of risk area tracking requests',
    getDisplayElement: (vessel: Vessel) => (
      <Suspense fallback={<></>}>
        <RiskAreaTracking vessel={vessel} />
      </Suspense>
    ),
  },
  compound_crewChangeNoEvents: {
    displayPriority: 4,
    persistent: false,
    shortDesc: 'Unassigned Crew',
    longDesc: 'Unassigned Crew',
    tooltip:
      'A list of crew who have not been assigned to any crew change events',
    getDisplayElement: (vessel: Vessel) => (
      <Suspense fallback={<></>}>
        <NoEventContainer vessel={vessel} />
      </Suspense>
    ),
  },
  compound_vesselTags: {
    displayPriority: 5,
    persistent: false,
    shortDesc: 'Vessel Tags',
    longDesc: 'Custom Tagging',
    tooltip: 'Assign custom tags on each vessel to categorise your vessels',
    getDisplayElement: (vessel: Vessel) => <VesselTags vessel={vessel} />,
  },
  compound_vesselBasicInfo: {
    displayPriority: 6,
    persistent: true,
    shortDesc: 'Vessel Info',
    longDesc: 'Highlighted Vessel Information',
    tooltip: 'A section detailing highlighted vessel information',
    getDisplayElement: (vessel: Vessel) => <VesselInfo vessel={vessel} />,
  },
  compound_crewHomeAirports: {
    displayPriority: 55,
    persistent: false,
    shortDesc: 'Crew Airports',
    longDesc: 'Crew Change Airports',
    tooltip: 'A list of home airports of the current crew',
    getDisplayElement: (vessel: Vessel) => <CrewHomeAirport vessel={vessel} />,
  },
  compound_crewNationalities: {
    displayPriority: 56,
    persistent: false,
    shortDesc: 'Crew Nationalities',
    longDesc: 'Crew Change Nationalities',
    tooltip: 'A list of nationalities of the current crew',
    getDisplayElement: (vessel: Vessel) => <CrewNationality vessel={vessel} />,
  },
  compound_miscData: {
    displayPriority: 57,
    persistent: true,
    shortDesc: 'Misc Data',
    longDesc: 'Miscellaneous Data',
    tooltip: 'A section detailing other miscellaneous vessel information',
    getDisplayElement: (vessel: Vessel) => <MiscData vessel={vessel} />,
  },
};
