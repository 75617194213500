import { Position } from '@turf/turf';
import type {
  GeoJSONSource,
  LineLayer,
  LngLat,
  LineLayout,
  LinePaint,
  MapboxGeoJSONFeature,
  MapMouseEvent,
  SymbolLayer,
} from 'mapbox-gl';
import { mapState, runWhenIdle, runWhenMapReady } from './map';

export const defaultRouteLayerSetting = (
  id: string,
  paintProps?: LinePaint,
  layoutProps?: LineLayout
): mapboxgl.LineLayer => ({
  id,
  type: 'line',
  paint: {
    'line-color': '#385dea',
    'line-width': 8,
    'line-pattern': 'line-texture',
    ...paintProps,
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
    ...layoutProps,
  },
});

/**
 * Draws feature collection routes on the map
 * @param sourceId
 * @param fc
 * @returns
 */
export function setRoutesSource(
  sourceId: string,
  fc: GeoJSON.FeatureCollection
) {
  return new Promise((res) => {
    runWhenMapReady(async (map) => {
      const source = map.getSource(sourceId);
      if (!source) {
        // console.log(`ADDING ${sourceId} SOURCE`, fc);
        map.addSource(sourceId, {
          type: 'geojson',
          data: fc,
          promoteId: 'id', // so that string id's can be queried by feature-state within properties
        });
      } else {
        (source as GeoJSONSource).setData(fc);
      }
      res(map.getSource(sourceId));
    });
  });
}

export function drawRoutes(
  sourceId: string,
  layerId: string,
  styles?: Partial<LineLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'line',
          ...(styles || defaultRouteLayerSetting(layerId)),
        },
        beforeId
      );
    }
  });
}

export function drawRouteLabel(
  sourceId: string,
  layerId: string,
  styles: Partial<SymbolLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'symbol',
          ...styles,
        },
        beforeId
      );
    }
  });
}

export const generateRouteFeature = (
  id: string,
  coordinates: Position[][],
  properties?: { [key: string]: any }
): GeoJSON.Feature => {
  return {
    id,
    type: 'Feature',
    properties: {
      id,
      ...properties,
    },
    geometry: {
      type: 'MultiLineString',
      coordinates,
    },
  };
};

export function routeFromMouseEvent(
  e: MapMouseEvent,
  sourceId: string = 'active-routes'
) {
  if (!mapState.map) return;
  const features = mapState.map!.queryRenderedFeatures(e.point);
  const coordinates = e.lngLat;
  let current: {
    feature: MapboxGeoJSONFeature;
    coordinates: LngLat;
  } | null = null;
  for (const v of features) {
    if (v.source !== sourceId) {
      continue;
    }

    current = {
      feature: v,
      coordinates,
    };
  }
  return current;
}

export function drawLineString(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.LineLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'line',
          ...styles,
        },
        beforeId
      );
    }
  });
}
