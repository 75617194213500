import attempt from 'lodash/attempt';
import isError from 'lodash/isError';
import pick from 'lodash/pick';
import partition from 'lodash/partition';
import type { Border, Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type { UserConfigResp as UserInfo } from '@greywing-maritime/frontend-library/dist/types/userConfig';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { showToaster } from 'lib/toaster';
import { removeWhiteSpace } from 'lib/common';
import { formatDate } from 'utils/format-date';

import { AgencyCostModule } from 'components/CrewChangePanel/modules';
import {
  getTravelCost,
  totalFlightCost as getTotalFlightCost,
} from 'components/CrewChangePanel/helpers';
import { CCPanelCostParams } from 'redux/types';
import { Crew, Port } from 'utils/types/crew-change-types';
import {
  ConfirmedFlight,
  CrewDetails,
  PortParams,
  ReadOnlyFlight,
} from 'components/CrewChangePanel/types';
import { LayoverDetails } from 'components/FlotillaSearch/types';

import {
  ConvertToWSForCCP,
  ConvertToWSForPortAgent,
  PortInExportData,
} from './types';

const DETAILED_REPORT_FLIGHT_FIELDS = [
  'order',
  'cid',
  'type',
  'name',
  'country',
  'flight',
  'cost',
  'time',
  'departure',
  'flightLayoverTime',
  'layoverDetails',
  'arrival',
];

const PORT_AGENT_REPORT_CREW_FIELDS = ['country', 'rank'];

export const getCustomReportName = (
  locode: string,
  vessel: Vessel,
  ports: Port[]
) => {
  const port = ports.find((port) => port.locode === locode);
  return (
    (port &&
      `${formatDate(port.eta, 'DD-MM-YYYY')}_${vessel.name}_${port.locode}`) ||
    `Crew change for ${vessel.name}`
  );
};

export const createWorkbook = async (userInfo: UserInfo | undefined) => {
  const { firstname, lastname, company } = userInfo || {};
  const userFullname =
    `${firstname || ''} || ${lastname || ''}`.trim() ||
    (company?.name && `User from ${company.name}`) ||
    'Unknown User';
  const currentTime = new Date();

  const ExcelJS = await import('exceljs');
  const workbook = new ExcelJS.Workbook();
  // set meta-data
  workbook.creator = userFullname;
  workbook.lastModifiedBy = userFullname;
  workbook.created = currentTime;
  workbook.modified = currentTime;

  return workbook;
};

// crew list with active & unneeded flight
const getCrewList = (
  crew: Crew[],
  activeFlights: (ConfirmedFlight | ReadOnlyFlight)[],
  port: PortInExportData
) => {
  const { iataCode: portAirportCode = '' } = port.selectedAirport || {};
  const crewListWithConfirmedFlights = crew.filter(({ id }) =>
    activeFlights.some(({ crew }) => crew.id === id)
  );
  const crewListWithUnneededFlights = crew.filter(
    ({ homeAirport }) => (homeAirport?.iataCode || '') === portAirportCode
  );
  return [...crewListWithConfirmedFlights, ...crewListWithUnneededFlights];
};

// util to find the details presented in report
export const getReportDetails = (
  port: PortInExportData,
  plannedCrew: Crew[],
  activeFlights: (ConfirmedFlight | ReadOnlyFlight)[] = [],
  portParams: PortParams,
  costParams: CCPanelCostParams
) => {
  const { costOptions } = costParams;
  const crewList = getCrewList(plannedCrew, activeFlights, port);

  // use the crew list with active flight
  const agencyCostModule = new AgencyCostModule(crewList, port.costs);
  const { agencyCost: agencyCostStr = '' } =
    agencyCostModule.getPreferredCostSummary(portParams.agency);
  const totalAgencyCost = parseInt(agencyCostStr) || 0;
  const agencyCost = Math.round(totalAgencyCost / crewList.length);

  const totalWageCost = activeFlights.reduce<number>((acc, flight) => {
    const crew = crewList.find(({ id }) => id === flight.crew.id);
    const { total, difference = 0 } =
      getTravelCost(costOptions)(flight, crew) || {};
    return acc + ((difference > 0 && total?.wage) || 0);
  }, 0);
  const totalFlightCost = costOptions.includes('Flight')
    ? Number(getTotalFlightCost(activeFlights))
    : 0;
  const totalCO2 = Math.round(
    activeFlights.reduce((acc, { totalCO2 }) => acc + totalCO2 || 0, 0) / 1000
  );
  const [onsigners, offsigners] = partition(
    crewList,
    ({ type }) => type === CrewType.onsigner
  );

  return {
    totalCO2,
    agencyCost,
    totalAgencyCost,
    totalFlightCost,
    totalWageCost,
    currency: activeFlights[0]?.price?.currency || 'USD',
    crewDetails: {
      onsigners,
      offsigners,
      onsignerCount: onsigners.length,
      offsignerCount: offsigners.length,
      totalCount: crewList.length,
    },
  };
};

// creates custom column header based on `crewDetails` fields
const getFlightManifestCoumnName = (text: string) =>
  (
    (text === 'order' && 'Serial no.') ||
    (text === 'country' && 'Nationality') ||
    (text === 'flightLayoverTime' && 'Total Layover') ||
    (text === 'layoverDetails' && 'Layover Details') ||
    text
  ).toUpperCase();

// sort based on incoming crew-details `type` & `country`
const sortCrewDetails = (crewList: CrewDetails[]) => {
  const [offsigners, onsigners] = partition(
    // format the layover details info into array of strings
    crewList.map(({ layoverDetails, ...rest }) => ({
      ...rest,
      // create a joined string with new layover details string to a new line
      layoverDetails: formatLayoverDetails(layoverDetails).join('\r\n'),
    })),
    ({ type }) => type === 'OFFSIGNER'
  );
  const OFFSIGNER_COUNT = offsigners.length;
  const ONSIGNER_COUNT = onsigners.length;
  // sort country & add order
  const sortedCrew = [
    ...offsigners
      .sort((a, b) => a.country.localeCompare(b.country))
      .map((item, index) => ({
        ...item,
        order: `${index + 1}.`,
      })),
    ...onsigners
      .sort((a, b) => a.country.localeCompare(b.country))
      .map((item, index) => ({
        ...item,
        order: `${OFFSIGNER_COUNT + index + 1}.`,
      })),
  ];
  return { sortedCrew, OFFSIGNER_COUNT, ONSIGNER_COUNT };
};

// formats layover details with arrival & departure info into regular string
const formatLayoverDetails = (layoverDetails: LayoverDetails[]) =>
  layoverDetails.map(
    ({ arrival, departure }) =>
      `${arrival.iataCode}: Arrives at ${formatDate(
        arrival.time,
        'hh:mm a, DD MMM'
      )} | Departs at ${formatDate(departure.time, 'hh:mm a, DD MMM')}`
  );

const createWorksheet = (workbook: Workbook, port: PortInExportData) => {
  const { locode, flightSource, uniqETA } = port;
  const basePortName = uniqETA
    ? `${locode}(${formatDate(uniqETA, 'DD-MM-YYYY')})`
    : locode;
  const worksheetName = flightSource
    ? `${basePortName}--${removeWhiteSpace(flightSource)}`
    : `${basePortName}`;

  // create worksheet with an attemp to handle error
  return attempt(() => workbook.addWorksheet(worksheetName));
};

// util to create worksheets for regular crew change report
export const convertReportToWSForCCP =
  (workbook: Workbook): ConvertToWSForCCP =>
  (port, emailDetails, additionalDetails, reportLink) => {
    const { eta, etd, locode, flightSource } = port;
    const { crewDetails: crewList, vesselDetails } = emailDetails;
    const { sortedCrew, OFFSIGNER_COUNT, ONSIGNER_COUNT } =
      sortCrewDetails(crewList);
    const {
      agencyCost,
      totalAgencyCost,
      totalWageCost,
      totalFlightCost,
      // totalCO2,
      crewDetails,
      currency,
    } = additionalDetails;

    const worksheet = createWorksheet(workbook, port);

    if (isError(worksheet)) return;

    if (reportLink) {
      // Show report link
      worksheet.getCell('A1').value = 'View full report on Greywing:';
      worksheet.getCell('A1').font = {
        size: 12,
        bold: true,
        color: { theme: 1 },
      };
      worksheet.mergeCells('A1:B1');
      // show shareable link
      worksheet.getCell('C1').value = reportLink;
      worksheet.getCell('C1').font = {
        size: 11,
        color: { argb: '0000EE' },
        underline: true,
      };
      worksheet.mergeCells('C1:H1');
    }

    // Excel Report Title
    worksheet.getCell('A2').value = `Crew Change for ${
      vesselDetails.name
    } at ${locode} ${flightSource ? `- ${flightSource}` : ''}`.trim();
    worksheet.getCell('A2').font = {
      size: 18,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.mergeCells('A2:H2');

    /* ----- Crew Change General Info ----- */

    // Title on top of the table - on 5th line
    worksheet.getCell('A5').value = 'General Info';
    worksheet.getCell('A5').font = {
      size: 14,
      italic: true,
      // color: { theme: 1 },
      underline: 'single',
    };
    worksheet.mergeCells('A5:F5');

    // Create table - starts on 7th line
    worksheet.addTable({
      name: 'general-info',
      ref: 'A7',
      headerRow: false,
      style: { showRowStripes: true },
      columns: [{ name: 'Category' }, { name: 'Value' }],
      rows: [
        ['ETA (Port local time)', formatDate(eta, 'DD MMM YY, hh:mm a')],
        ['ETD (Port local time)', formatDate(etd, 'DD MMM YY, hh:mm a')],
        ['Onsigner Count', `${crewDetails.onsignerCount}`],
        ['Offsigner Count', `${crewDetails.offsignerCount}`],
        // ['Total CO2', `${totalCO2} Tons`],
      ],
    });

    /* ----- Cost Breakdown - average & total costs ----- */

    // Title on top of the table - on 13th line
    worksheet.getCell('A13').value = 'Cost Breakdown';
    worksheet.getCell('A13').font = {
      size: 14,
      italic: true,
      color: { theme: 1 },
      underline: 'single',
    };
    worksheet.mergeCells('A13:F13');

    // Create table - starts on 15th line
    worksheet.addTable({
      name: 'cost-breakdown',
      ref: 'A15',
      headerRow: true,
      style: { theme: 'TableStyleDark3', showRowStripes: true },
      columns: [
        { name: 'Category' },
        { name: 'Per Person' },
        { name: 'Total' },
      ],
      rows: [
        [
          'Agency (avg.)',
          agencyCost ? `${agencyCost} ${currency}` : '-',
          totalAgencyCost ? `${totalAgencyCost} ${currency}` : '-',
        ],
        [
          'Extra Wages (avg.)',
          totalWageCost
            ? `${Math.round(
                totalWageCost / crewDetails.totalCount
              )} ${currency}`
            : '-',
          totalWageCost ? `${totalWageCost} ${currency}` : '-',
        ],
        [
          'Flights (avg.)',
          `${Math.round(totalFlightCost / crewDetails.totalCount)} ${currency}`,
          `${totalFlightCost} ${currency}`,
        ],
        [
          'Total',
          `${Math.round(
            agencyCost +
              (totalWageCost + totalFlightCost) / crewDetails.totalCount
          )} ${currency}`,
          `${totalAgencyCost + totalWageCost + totalFlightCost} ${currency}`,
        ],
      ],
    });

    /* ----- Vessel Info ----- */

    // Title on top of the table - on 22rd line
    worksheet.getCell('A22').value = 'Vessel Info';
    worksheet.getCell('A22').font = {
      size: 14,
      italic: true,
      color: { theme: 1 },
      underline: 'single',
    };
    worksheet.mergeCells('A22:F22');

    // Create table - starts on 24th line
    worksheet.addTable({
      name: 'vessel-details',
      ref: 'A24',
      headerRow: true,
      style: { showRowStripes: true },
      columns: Object.keys(vesselDetails).map((field: string) => ({
        name: field.toUpperCase(),
      })),
      rows: [Object.values(vesselDetails)],
    });

    /* ----- Flight Details for all crew ----- */

    // Title on top of the table - on 28th line
    worksheet.getCell('A28').value = 'Flight Manifest';
    worksheet.getCell('A28').font = {
      size: 14,
      italic: true,
      color: { theme: 1 },
      underline: 'single',
    };
    worksheet.mergeCells('A28:F28');

    // Create table - starts on 30th line
    worksheet.addTable({
      name: 'flight-manifest',
      ref: 'A30',
      headerRow: true,
      style: { showRowStripes: true },
      columns: DETAILED_REPORT_FLIGHT_FIELDS.map((text) => ({
        name: getFlightManifestCoumnName(text),
      })),
      rows: sortedCrew.map((item) =>
        Object.values(pick(item, DETAILED_REPORT_FLIGHT_FIELDS))
      ),
    });

    if (OFFSIGNER_COUNT > 0) {
      // style crew `TYPE` cells for offsigners
      Array.from({ length: OFFSIGNER_COUNT }).forEach((_, index) => {
        worksheet.getCell(`C${30 + index + 1}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'd97575' }, // light red
        };
      });
    }

    if (ONSIGNER_COUNT > 0) {
      // style crew `TYPE` cells for onsigners
      Array.from({ length: ONSIGNER_COUNT }).forEach((_, index) => {
        worksheet.getCell(`C${30 + OFFSIGNER_COUNT + index + 1}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '80cc66' }, // light green
        };
      });
    }

    // sets width for all the columns
    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    // set border for `General Info` table
    const borderStyle: Partial<Border> = {
      style: 'medium',
      color: { theme: 1 },
    };
    const tableBorder = {
      top: borderStyle,
      left: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
    };
    worksheet.getCell('A7').border = pick(tableBorder, ['top', 'left']);
    worksheet.getCell('B7').border = pick(tableBorder, ['top', 'right']);
    worksheet.getCell('A8').border = pick(tableBorder, 'left');
    worksheet.getCell('B8').border = pick(tableBorder, 'right');
    worksheet.getCell('A9').border = pick(tableBorder, 'left');
    worksheet.getCell('B9').border = pick(tableBorder, 'right');
    worksheet.getCell('A10').border = pick(tableBorder, ['bottom', 'left']);
    worksheet.getCell('B10').border = pick(tableBorder, ['bottom', 'right']);
    // worksheet.getCell('A11').border = pick(tableBorder, ['bottom', 'left']);
    // worksheet.getCell('B11').border = pick(tableBorder, ['bottom', 'right']);
  };

// util to create worksheets for port agents
export const convertReportToWSForPortAgent =
  (workbook: Workbook): ConvertToWSForPortAgent =>
  (crew, port, vesselDetails) => {
    const worksheet = createWorksheet(workbook, port);

    if (isError(worksheet)) return;

    const [offsigners, onsigners] = partition(
      crew,
      (item) => item.type === 'offsigner'
    );

    // common border style
    const borderStyle: Partial<Border> = {
      style: 'thin',
      color: { argb: '424242' }, // dark gray
    };
    const commonBorder = {
      top: borderStyle,
      left: borderStyle,
      right: borderStyle,
      bottom: borderStyle,
    };

    /* ----- 1st section: Report header ----- */

    worksheet.getCell('A2').value = 'Revision'.toUpperCase();
    worksheet.getCell('A2').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('A2').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('A2:B2');

    worksheet.getCell('C2').value = 'Husbandary Agency Services'.toUpperCase();
    worksheet.getCell('C2').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
      // alignment: { horizontal: 'center', vertical: 'middle' },
    };
    worksheet.mergeCells('C2:G2');

    worksheet.getCell('A3').value = 'Document Owner'.toUpperCase();
    worksheet.getCell('A3').style = {
      font: { size: 10 },
      alignment: { horizontal: 'center', vertical: 'middle' },
    };
    worksheet.mergeCells('A3:B3');

    worksheet.getCell('C3').font = { size: 10 };
    worksheet.mergeCells('C3:G3');

    // border around the section: from A2 to G3 - 14 cells
    Array.from({ length: 7 })
      .map((_, i) => String.fromCharCode(i + 65))
      .forEach((val, index) => {
        const firstRowCell = `${val}2`;
        worksheet.getCell(firstRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 6 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        );
        // row filler with light gray
        worksheet.getCell(firstRowCell).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        };
        const secondRowCell = `${val}3`;
        worksheet.getCell(secondRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'bottom']) ||
            (index === 6 && ['right', 'bottom']) || ['bottom']
        );
      });
    // custom border for B2 cell
    worksheet.getCell('B2').border = pick(commonBorder, [
      'right',
      'top',
      'bottom',
    ]);

    /* ----- 2nd section: Vessel name, Port name & ETA ----- */

    worksheet.getCell('A5').value = 'Vessel Name'.toUpperCase();
    worksheet.getCell('A5').font = { size: 10 };
    worksheet.getCell('B5').value = vesselDetails.name;
    worksheet.getCell('B5').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('B5').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('B5:D5');
    // custom border around vessel name
    ['B5', 'C5', 'D5'].forEach((cellName, index) => {
      worksheet.getCell(cellName).border =
        (index === 0 && pick(commonBorder, ['left', 'top', 'bottom'])) ||
        (index === 2 && pick(commonBorder, ['right', 'top', 'bottom'])) ||
        pick(commonBorder, ['top', 'bottom']);
    });

    worksheet.getCell('F5').value = 'Port Name'.toUpperCase();
    worksheet.getCell('F5').font = { size: 10 };
    worksheet.getCell('G5').value = port.name;
    worksheet.getCell('G5').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('G5').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('G5:I5');
    // custom border around port name
    ['G5', 'H5', 'I5'].forEach((cellName, index) => {
      worksheet.getCell(cellName).border =
        (index === 0 && pick(commonBorder, ['left', 'top', 'bottom'])) ||
        (index === 2 && pick(commonBorder, ['right', 'top', 'bottom'])) ||
        pick(commonBorder, ['top', 'bottom']);
    });

    worksheet.getCell('F6').value = 'Port ETA'.toUpperCase();
    worksheet.getCell('F6').font = { size: 10 };
    worksheet.getCell('G6').value = formatDate(
      port.eta,
      'HH:MM A, DD MMM, YYYY'
    );
    worksheet.getCell('G6').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('G6').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('G6:I6');
    // custom border around port ETA
    ['G6', 'H6', 'I6'].forEach((cellName, index) => {
      worksheet.getCell(cellName).border =
        (index === 0 && pick(commonBorder, ['left', 'top', 'bottom'])) ||
        (index === 2 && pick(commonBorder, ['right', 'top', 'bottom'])) ||
        pick(commonBorder, ['top', 'bottom']);
    });

    /* ----- 3rd section: Section header 1 ----- */

    worksheet.getCell('A8').value = 'Crew Department'.toUpperCase();
    worksheet.getCell('A8').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.mergeCells('A8:F8');

    worksheet.getCell('G8').value = 'Service Required'.toUpperCase();
    worksheet.getCell('G8').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.mergeCells('G8:H8');
    worksheet.getCell('I8').value = 'Insert value';
    worksheet.getCell('I8').font = {
      size: 10,
      italic: true,
      color: { argb: 'BDBDBD' }, // medium gray
    };
    worksheet.getCell('I8').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    // border around the section: from A8 to H8 - 8 cells
    Array.from({ length: 8 })
      .map((_, i) => String.fromCharCode(i + 65))
      .forEach((val, index) => {
        const cellName = `${val}8`;
        worksheet.getCell(cellName).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 7 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        );
        // row filler with light gray
        worksheet.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        };
      });
    // custom border for I8 cell
    worksheet.getCell('I8').border = pick(commonBorder, [
      'left',
      'right',
      'top',
      'bottom',
    ]);

    /* ----- 4th section: Onsigner & Offsigner details ----- */

    // show onsigner & offsigner count
    worksheet.getCell('A10').value = 'Number Onsigner'.toUpperCase();
    worksheet.getCell('A10').font = {
      size: 10,
    };
    worksheet.mergeCells('A10:B10');
    worksheet.getCell('C10').value = onsigners.length;
    worksheet.getCell('C10').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    // custom border for I8 cell
    worksheet.getCell('C10').border = pick(commonBorder, [
      'left',
      'right',
      'top',
      'bottom',
    ]);

    worksheet.getCell('F10').value = 'Number Offsigner'.toUpperCase();
    worksheet.getCell('F10').font = {
      size: 10,
    };
    worksheet.mergeCells('F10:G10');
    worksheet.getCell('H10').value = offsigners.length;
    worksheet.getCell('H10').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    // custom border for I8 cell
    worksheet.getCell('H10').border = pick(commonBorder, [
      'left',
      'right',
      'top',
      'bottom',
    ]);

    // Create table - starts on 15th line
    worksheet.addTable({
      name: 'onsigner-details',
      ref: 'A12',
      headerRow: true,
      style: { theme: 'TableStyleLight5', showRowStripes: false },
      columns: [
        { name: 'Nationality' },
        { name: 'Rank' },
        { name: 'Vaccinated' },
        { name: 'Visa' },
      ],
      rows: onsigners.map((crew) =>
        Object.values(pick(crew, PORT_AGENT_REPORT_CREW_FIELDS))
      ),
    });
    ['A', 'B', 'C', 'D'].forEach((val, index) => {
      const cellName = `${val}12`;
      worksheet!.getCell(`${cellName}`).style = {
        alignment: { horizontal: 'center', vertical: 'middle' },
        border: pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 3 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        ),
        font: { size: 10, color: { argb: '424242' } },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        },
      };
    });

    worksheet.addTable({
      name: 'offsigner-details',
      ref: 'F12',
      headerRow: true,
      style: { theme: 'TableStyleLight5', showRowStripes: false },
      columns: [
        { name: 'Nationality' },
        { name: 'Rank' },
        { name: 'Vaccinated' },
        { name: 'Visa' },
      ],
      rows: offsigners.map((crew) =>
        Object.values(pick(crew, PORT_AGENT_REPORT_CREW_FIELDS))
      ),
    });
    ['F', 'G', 'H', 'I'].forEach((val, index) => {
      const cellName = `${val}12`;
      worksheet!.getCell(`${cellName}`).style = {
        alignment: { horizontal: 'center', vertical: 'middle' },
        border: pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 3 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        ),
        font: { size: 10, color: { argb: '424242' } },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        },
      };
    });

    /* ---- 5th section: Cost ----- */
    /* Leaves 20 rows for on & offsigners to be allocated in table. */

    worksheet.getCell('A35').value = 'Cash to Master'.toUpperCase();
    worksheet.getCell('A35').font = { size: 10 };
    worksheet.mergeCells('A35:B35');

    worksheet.getCell('C35').value = 'Value';
    worksheet.getCell('D35').value = 'Currency';
    ['C35', 'D35'].forEach((val, index) => {
      worksheet!.getCell(val).style = {
        font: {
          size: 10,
          italic: true,
          color: { argb: 'BDBDBD' }, // medium gray
        },
        alignment: { horizontal: 'center', vertical: 'middle' },
        border: pick(commonBorder, [
          index === 0 ? 'left' : 'right',
          'top',
          'bottom',
        ]),
      };
    });

    /* ----- 6th section: Comment section 1 ----- */

    worksheet.getCell('A37').value = 'Comments'.toUpperCase();
    worksheet!.getCell('A37').font = { size: 10 };
    worksheet!.getCell('A37').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('A37:A39');
    // border around the comment section: from B37 to I39 - 24 cells
    Array.from({ length: 8 })
      .map((_, i) => String.fromCharCode(i + 66))
      .forEach((val, index) => {
        const firstRowCell = `${val}37`;
        worksheet.getCell(firstRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top']) ||
            (index === 7 && ['right', 'top']) || ['top']
        );
        const thirdRowCell = `${val}39`;
        worksheet.getCell(thirdRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'bottom']) ||
            (index === 7 && ['right', 'bottom']) || ['bottom']
        );
      });
    // custom border for middle row
    worksheet.getCell('B38').border = pick(commonBorder, ['left']);
    worksheet.getCell('I38').border = pick(commonBorder, ['right']);

    /* ----- 7th section: Section header 1 ----- */

    worksheet.getCell('A41').value = 'Husbandary Services'.toUpperCase();
    worksheet.getCell('A41').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.mergeCells('A41:F41');

    worksheet.getCell('G41').value = 'Service Required'.toUpperCase();
    worksheet.getCell('G41').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.mergeCells('G41:H41');
    worksheet.getCell('I41').value = 'Insert value';
    worksheet.getCell('I41').font = {
      size: 10,
      italic: true,
      color: { argb: 'BDBDBD' }, // medium gray
    };
    worksheet.getCell('I41').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    // border around the section: from A41 to H41 - 8 cells
    Array.from({ length: 8 })
      .map((_, i) => String.fromCharCode(i + 65))
      .forEach((val, index) => {
        const cellName = `${val}41`;
        worksheet.getCell(cellName).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 7 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        );
        // row filler with light gray
        worksheet.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        };
      });
    // custom border for I40 cell
    worksheet.getCell('I41').border = pick(commonBorder, [
      'left',
      'right',
      'top',
      'bottom',
    ]);

    /* ----- 8th section: Comment section 2 ----- */

    worksheet.getCell('A43').value = 'Comments'.toUpperCase();
    worksheet!.getCell('A43').font = { size: 10 };
    worksheet!.getCell('A43').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('A43:A45');
    // border around the comment section: from B43 to I45 - 24 cells
    Array.from({ length: 8 })
      .map((_, i) => String.fromCharCode(i + 66))
      .forEach((val, index) => {
        const firstRowCell = `${val}43`;
        worksheet.getCell(firstRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top']) ||
            (index === 7 && ['right', 'top']) || ['top']
        );
        const thirdRowCell = `${val}45`;
        worksheet.getCell(thirdRowCell).border = pick(
          commonBorder,
          (index === 0 && ['left', 'bottom']) ||
            (index === 7 && ['right', 'bottom']) || ['bottom']
        );
      });
    // custom border for middle row
    worksheet.getCell('B44').border = pick(commonBorder, ['left']);
    worksheet.getCell('I44').border = pick(commonBorder, ['right']);

    /* ----- 9th section: Additional doc reference ----- */
    worksheet.getCell('A49').value = 'Related Documents'.toUpperCase();
    worksheet.getCell('A49').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('A49').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('A49:I49');
    // border around the section: from A49 to I49 - 9 cells
    Array.from({ length: 9 })
      .map((_, i) => String.fromCharCode(i + 65))
      .forEach((val, index) => {
        const cellName = `${val}49`;
        worksheet.getCell(cellName).border = pick(
          commonBorder,
          (index === 0 && ['left', 'top', 'bottom']) ||
            (index === 8 && ['right', 'top', 'bottom']) || ['top', 'bottom']
        );
        // row filler with light gray
        worksheet.getCell(cellName).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EEEEEE' }, // light gray
        };
      });

    worksheet.getCell('A50').value = 'Doc No.'.toUpperCase();
    worksheet.getCell('A50').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
    };
    worksheet.getCell('A50').alignment = {
      horizontal: 'center',
      vertical: 'middle',
    };
    worksheet.mergeCells('A50:B50');

    worksheet.getCell('C50').value = 'Document Name'.toUpperCase();
    worksheet.getCell('C50').font = {
      size: 10,
      bold: true,
      color: { theme: 1 },
      // alignment: { horizontal: 'center', vertical: 'middle' },
    };
    worksheet.mergeCells('C50:I50');
    // add 5 empty cells with custom style for documents
    Array.from({ length: 5 }).forEach((_, index) => {
      const currentCellIndex = 51 + index;
      worksheet.mergeCells(`A${currentCellIndex}:B${currentCellIndex}`);
      worksheet.mergeCells(`C${currentCellIndex}:I${currentCellIndex}`);
    });

    // sets width for all the columns
    worksheet.columns.forEach((column) => {
      column.width = 20;
      column.style = {
        alignment: { vertical: 'middle' },
      };
    });
  };

// download step of saving excel file
export const downloadWorkbook = async (
  workbook: Workbook,
  filename: string
) => {
  try {
    // create buffer & save file
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
    const blob = new Blob([buffer], { type: fileType });
    // insert time-stamp in file name
    saveAs(blob, `${filename}.xlsx`);
    showToaster({
      message: 'Exported Excel report successfully!',
      testId: 'e2e_export-plan-success-toaster',
    });
  } catch (error) {
    console.log('Error when exporting crew-change report.', error);
    showToaster({
      message: 'Failed to export crew-change report.',
      type: 'error',
    });
  }
};
