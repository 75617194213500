import {
  SelectProps,
  Select as MuiSelect,
  MenuItem,
  MenuItemProps,
  InputLabel,
  FormControl,
} from '@mui/material';
type Option = { value: string | number; label: string };

type SelectFieldProps = SelectProps & {
  id: string;
  options: Option[];
  menuItemProps?: MenuItemProps;
};

function Select({
  options,
  variant,
  menuItemProps,
  ...props
}: SelectFieldProps) {
  return (
    <FormControl variant={variant}>
      {props.label && (
        <InputLabel id={`label-${props.id}`}>{props.label}</InputLabel>
      )}
      <MuiSelect labelId={props.label ? `label-${props.id}` : ''} {...props}>
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value} {...menuItemProps}>
            {o.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
}

export default Select;
