import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initAmplitude } from '@greywing-maritime/frontend-library/dist/utils/amplitude';

import { setWindowVh } from 'lib/common';
import { selectSettings, selectUserAuth } from 'redux/selectors';
import { APP_VERSION } from 'utils/constants';

// hook that runs app initializing steps/features for authed user
// add any step/feature that should run at top level regardless of route/authentication
function useInitializePlatform() {
  const { isAuthed } = useSelector(selectUserAuth);
  const { userInfo } = useSelector(selectSettings);

  /* setting up app window resizing */
  useEffect(() => {
    // This bit of code provides the method to handle 100vh issues on mobile
    window.addEventListener('resize', setWindowVh);
    setWindowVh();

    return () => {
      window.removeEventListener('resize', setWindowVh);
    };
  }, []);

  /* intializing amplitude */
  useEffect(() => {
    const skipInitAmplitude = process.env.REACT_APP_LOCAL_DEV === 'true';
    console.log('Currently on version:', APP_VERSION);

    if (!skipInitAmplitude) {
      initAmplitude({
        email: isAuthed ? userInfo?.loginEmail : undefined,
        appVersion: APP_VERSION,
        ...(userInfo?.company
          ? {
              company: {
                id: userInfo.company.id,
                name: userInfo.company.name,
              },
            }
          : {}),
      });
    }
  }, [userInfo, isAuthed]);
}

export default useInitializePlatform;
