import MUISlider from '@mui/material/Slider';
import styled from 'styled-components/macro';
import { SliderProps } from '@mui/material';

import { blue } from 'lib/colors';

const RangeSlider = styled(MUISlider)({
  color: `${blue}`,
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
});

type Props = {
  min: number;
  max: number;
  value: number;
  step?: number;
  onChange: (event: Event, value: number | number[]) => void;
  onChangeCommitted?: () => void;
};

function Slider(props: Props & SliderProps) {
  return (
    <RangeSlider
      {...props}
      valueLabelDisplay={props.valueLabelDisplay || 'off'}
    />
  );
}

export default Slider;
