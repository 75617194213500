import { Action, ThunkAction } from '@reduxjs/toolkit';
import type {
  BunkeringCurve,
  Vessel,
} from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type { SearchFilter } from '@greywing-maritime/frontend-library/dist/utils/search-engine/search-engine-types';
import type { RiskArea } from '@greywing-maritime/frontend-library/dist/types/riskAreas';
import type { RiskAreaTrackingReqCommon } from '@greywing-maritime/frontend-library/dist/types/riskAreaTrackingRequests';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import type { CabinClass } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import type { TrackFlightsSettings } from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';
import type {
  CrewChangePlanSummaryResp,
  SavedCrewChangePlanResponse,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import type { ListPortNotesResp as PortNotesResponse } from '@greywing-maritime/frontend-library/dist/types/portNotes';
import type {
  RestrictionSourceType,
  RestrictionType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { ListFlightBookingTxQueryParams } from '@greywing-maritime/frontend-library/dist/types/flightBooking';
import type { BookingTransaction } from 'components/shared/FlightBook/types';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';

import type { WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import type { ToolTipElement, ToolTipState } from 'utils/tooltips';
import {
  CrewChangeActivityType,
  Flight,
  PortRequest,
  PortResponse,
} from 'utils/types/crew-change-types';
import type { VesselRouteCalculationResult } from 'utils/types/route-calculator';
import type {
  CrewChangeStep,
  GeofenceWithColor,
  HotelsResponse,
  NotificationUpdate,
  OnboardCompletion,
  PaginatedReminders,
  PortCallResponse,
  PortCallResponseMap,
  ProximityPort,
  SearchedPort,
  UserInfo,
  UserPICInfo,
  VesselTagWithCount,
  WizardSteps,
} from 'utils/types';
import type {
  CrewChangeCost,
  PortParams as CCPanelPortParams,
  ReadOnlyCrewEvent,
  RoutePortOrVessel,
  ReadOnlyFlight,
  VesselCCBudget,
} from 'components/CrewChangePanel/types';
import type { SegmentTypes } from 'components/VesselRoutes/helpers';
import type { ResultFilters } from 'components/FlotillaSearch/types';
import type { ReadOnlyPlanningData } from 'components/CrewChangePanel/types';
import type {
  VesselInfo as CCMatrixVessel,
  CrewChangeEventSummary as MatrixEventSummary,
  CrewNotesResponse,
  OnsignerDetails,
  CrewBoardingRecord,
  CrewPersonalInfo,
  SyncDetails,
  PageMeta,
  ListScheduleRecord,
} from 'components/CrewMatrixPlanning/types';
import type { OnboardSteps } from 'components/MapQuickMenu/components/variables';

import { store } from './configureStore';
import type {
  AssistedConversationWithExtra,
  AssistedCrewChangeAgentConvoResp,
  ListAssistedCrewChangeConvosScope,
  NewSeaGptChatBlock,
  SeaGPTChatBlock,
  SeaGPTThreadIdMap,
} from 'components/SeaGPT/type';
import { SEAGPT_CHAT_CATEGORIES } from 'components/SeaGPT/common';

/* ----- Common Types ----- */

export type Nullable<T> = T | null;

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type GetState = () => RootState;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  any,
  Action<string>
>;

export type StoreAPI = {
  dispatch: AppDispatch;
  getState: () => RootState;
};

/* ----- Types for `crewChangeResources` reducer ----- */

export type HotelsResultPayload = {
  [portLocode: string]: HotelsResponse;
};

export type ReportFlights = {
  [locode: string]: {
    [readOnlyCrewId: number]: Flight[];
  };
};

export type CCPanelRequestPayload = {
  request: PortRequest;
  type: 'proxPorts';
};

export type CCPanelResponsePayload = {
  response: PortResponse;
  type: 'proxPorts' | 'urgentPorts';
};

// updates requests & reponses for report flights
export type ReportFlightsPayload = {
  portLocode: string;
  crewId: number;
  flights: Flight[];
};

export type CCPanelRequests = {
  proxPorts: PortRequest | null;
};

export type CCPanelResponses = {
  urgentPorts: PortResponse | null;
  proxPorts: PortResponse | null;
  // flights fetched for read-only report update
  reportFlights: ReportFlights | null;
  // vessel budget for crew-change
  vesselBudgets: VesselCCBudget[] | null;
};

export type SavedCrewPlans = SavedCrewChangePlanResponse & {
  // ID of new plan - indicates that the list is updated
  newlySaved?: string | null;
};

export type SavedPlansPayload = {
  [vesselId: number]: SavedCrewPlans | undefined;
};

export type CrewChangeResources = {
  requests: CCPanelRequests;
  responses: CCPanelResponses;
  plans: SavedPlansPayload | null;
  incompletePlans: SavedPlansPayload | null;
  hotelResults: { [portLocode: string]: HotelsResponse } | null;
};

export type DispatchCCPanelResponse = (payload: CCPanelResponsePayload) => {
  payload: CCPanelResponsePayload;
  type: string;
};

export type RemoveCCPlanPayload = {
  summary: CrewChangePlanSummaryResp;
  isCompleted: boolean;
};

/* ----- Types for `portsInRange` reducer ----- */

export type PortsInRange = {
  showPortsInRange: boolean;
  ports: ProximityPort[];
  rangeNauticalMiles: number;
};

/* ----- Types for `appTooltipText` reducer ----- */

export type TooltipPayload = {
  element?: ToolTipElement;
  state?: ToolTipState;
};

/* ----- Types for `flotillaSearch` reducer ----- */

export type FlightNumberDetails = {
  flightNumber: string;
  included: boolean;
};

export type FlightSearch = {
  listOpen: boolean;
  loading: boolean;
  searchQuery: string;
  departure: AirportCommon | null; // departure airport
  arrival: AirportCommon | null; // arrival airport
  date: string;
  flights: Flight[];
  fromCache: boolean;
  flightNumberDetails: FlightNumberDetails;
  cabinClass: CabinClass;
};

export type QuickFlySearch = FlightSearch & {
  modalOpen: boolean; // indicates whether the all-flights modal is open
  filters: ResultFilters; // state to maintian filters for searched flight results
};

export type FlotillaSearch = {
  searchReady: boolean;
  searchIsExpanded: boolean;
  flightSearch: QuickFlySearch;
  copilotSearch: string | null;
};

/* ----- Types for `mapRuler` reducer ----- */

export type MapRulerType = 'simple' | 'route';

export type MapRulerUnits = 'kilometers' | 'miles' | 'nauticalmiles';

export type MapRulerViewType = 'calculator' | 'settings';

export type MapRulerState = {
  active: boolean;
  view: MapRulerViewType;
  config: {
    type: MapRulerType;
    units: MapRulerUnits;
    speed: number;
    bunker?: {
      vesselId: number | null;
      curve: BunkeringCurve | null;
    };
  };
  subview: 'distance' | 'bunker';
  waypoints: WaypointWithProperties[];
  compareWaypoints: WaypointWithProperties[];
  calculation: VesselRouteCalculationResult;
  compareCalculation: VesselRouteCalculationResult;
};

/* ----- Types for `mapState` reducer ----- */

export type MapReady = {
  vessel: boolean;
  route: boolean;
  map: boolean;
  vesselIcons: boolean;
  unmount: boolean; // Unmount is used to ensure the sequence of events for vessel / route map renders occur in the right order
  forceStart: boolean; // Allow starting even when routes haven't completed
};

export type FlotillaMapState = {
  loaded?: boolean;
  vesselsLoaded?: boolean;
};

/* ----- Types for `mapVessels` reducer ----- */

export type UserInfoPayload = {
  ids: number[];
  userInfo: UserPICInfo;
};

export type VesselResponse = {
  success: boolean;
  message: string;
  id?: number;
  vessel?: { name: string };
};

export type PortCallStoreResponseV2 = {
  vesselId: number;
} & PortCallResponse;

export type MapVesselState = {
  vesselsIsLoading: boolean;
  vesselsFull: Map<number, Vessel>;
  filteredVessels: Map<number, Vessel>;
  vesselsFullUpdatedAt: string | Date | null;
  filteredVesselsUpdatedAt: string | Date | null;
  highlightedVessels: number[];
  response: VesselResponse | null;
  hasZeroVessels: boolean;
  globalTags: VesselTagWithCount[];
};

/* ----- Types for `onboard` reducer ----- */

export type OnboardState = {
  isGetContentLoading: boolean;
  isGetCompletionLoading: boolean;
  stepsContent: OnboardSteps[];
  canAddFilter: boolean;
  totalSteps: number;
  stepsCompleted: OnboardCompletion[];
  floatingPipId: string | null;
  hasBeenShown: boolean;
};

/* ----- Types for `sidePanel` reducer ----- */

export enum SidePanelContentType {
  VESSELS,
  UPDATES,
}

export type SidePanelRouteConfig = {
  displayType?: PortCallTypesCommon | string;
  segment?: SegmentTypes;
};

export type SidePanelRouteConfigPayload = {
  vesselId: number;
  config: SidePanelRouteConfig;
};

export type SidePanelVesselPortCall = {
  request: 'fulfilled' | 'pending' | 'rejected' | null;
  response: PortCallResponse | null;
};

export type SidePanelSettings = {
  visible: boolean;
  vesselIds: number[];
  vesselRouteConfig: { [key: number]: SidePanelRouteConfig };
  updates: NotificationUpdate[];
  contentType: SidePanelContentType | null;
  hiddenRouteVesselIds: number[];
  expandedCollapsibleVessel: number | null;
  noUpdateId: string;
};

/* ----- Types for `crewChangePanel` reducer ----- */

export type ColumnVisibility = {
  name: string;
  field: string;
  selected: boolean;
  fixed: boolean;
};

// include other steps in the future, if needed
// e.g - ports: ColumnVisibility[] | null;
export type CCPanelColumnVisibility = {
  [step in CrewChangeStep]?: ColumnVisibility[] | null;
};

export type ReadOnlyUpdatePayload = {
  locodeKey: string;
  flights: ReadOnlyFlight[];
};

export type CCPanelFlightParams = {
  time: number; // Hour(s)
  layover: [number, number]; // Hour(s)
  stops: number;
  allowAirportTransfer: boolean;
};

export type CCPanelCostParams = {
  hotelCostDelay: number; // number of hour(s) to show crew hotel cost
  costOptions: CrewChangeCost[];
};

export type FutureRoute = RoutePortOrVessel[];

export type CCPanelInfo = {
  vesselId?: number;
  event?: CrewEvent | ReadOnlyCrewEvent;
  active: CrewChangeActivityType;
  futureRoute: FutureRoute;
  // contains data for read-only view of cc panel
  readOnlyPlanningData: ReadOnlyPlanningData | null;
  isOneClickPlan?: boolean | undefined;
  // contains column visibiulity data for crew & flights table
  columnVisibility: CCPanelColumnVisibility | null;
};

export type CCPanelPayload = {
  vesselId: number;
  active: CrewChangeActivityType;
  event?: CrewEvent | ReadOnlyCrewEvent;
  isOneClickPlan?: boolean | undefined;
};

/* ----- Types for `settings` reducer ----- */

export type SliderQueryConfig = {
  sliderPrefix: string;
  searchFieldId: string;
  sortFunc: (a: Vessel, b: Vessel) => number;
};

export type SliderQueryCommands = {
  [key: string]: SliderQueryConfig;
};

export type QuickFlySearchParams = CCPanelFlightParams & {
  quickFlyTMC: string;
};

export type FlightParams = {
  time: number; // Hour(s)
  layover: number; // Hour(s)
  stops: number;
};

export type CCPanelSettings = {
  showGuide: boolean;
  predictive: boolean;
  compact: boolean;
  portParams: CCPanelPortParams;
  flightParams: CCPanelFlightParams;
  costParams: CCPanelCostParams;
};

export type UnitType = 'KM' | 'NM';

export type VesselsSettings = {
  predictive: boolean;
  updateFrequency: number; // minute(s)
  unit: UnitType;
};

export type AppSettings = {
  crossDevices: boolean;
  // Field we're using to color highlight the vessels.
  colorHighlightField: string;
  /** Fields displayed in the popup. Both VesselFieldId and CompoundPopupField fields are allowed. */
  popupFields: string[];
  /** Fields displayed in the sidepanel. Both VesselFieldId and CompoundSidePanelField fields are allowed. */
  sidePanelFields: string[];
  /** Field used to show a label on the vessel. If empty, no labels are shown. */
  vesselLabelField: string;
  /** Preferred TMC only used in QuickFly to filter out flights */
  quickFlyTMC: string;
  /** Search filters. */
  searchFilters: SearchFilter[];
  vessels: VesselsSettings;
  userInfo?: UserInfo;
  crewChange: CCPanelSettings;
  trackSettings: TrackFlightsSettings | null;
};

// payload coming from settings update
export type CombinedSettingsPayload = {
  crossDevices: boolean;
  vessels: VesselsSettings;
  userInfo?: UserInfo | null;
  crewChange: CCPanelSettings;
  trackSettings: TrackFlightsSettings | null;
  quickFlyTMC: string;
};

/* ----- Types for `reminders` reducer ----- */

export type RemindersState = {
  completedIsLoading: boolean;
  upcomingIsLoading: boolean;
  completedReminders: PaginatedReminders | null;
  upcomingReminders: PaginatedReminders | null;
};

/* ----- types for 'redirect` reducer ----- */

export type RedirectDetails = {
  pathname: string;
  params: { [key: string]: string };
};

/* ----- types for `recentSearches` ----- */

export type RecentSearchType = 'airport' | 'crewNation' | 'flotillaSearch';

// types for search options/items
export type CountryOption = {
  name: string;
  countryCode: string;
  countryCodeAlpha3: string;
  label?: string;
};
export type AirportOption = AirportCommon & { label?: string };

// flight search details to prefill when selected
type FlightSearchDetails = {
  departure: AirportCommon | null; // departure airport
  arrival: AirportCommon | null; // arrival airport
  date: string;
  cabinClass?: CabinClass;
};
type FlotillaSearchDetails = FlightSearchDetails; // add more details here for other search filters
export type FlotillaSearchOption = {
  inputStr: string;
  details?: FlotillaSearchDetails; // additional search details
};

export type SearchItem = AirportOption | CountryOption | FlotillaSearchOption;

export type RecentSearchContent = Partial<{
  [type in RecentSearchType]: SearchItem;
}>;

export type RecentSearches = Partial<{
  [type in RecentSearchType]: SearchItem[];
}>;

/* ----- Types for `riskArea` reducer ----- */

export type RiskAreaState = {
  riskAreas: RiskArea[];
  geofences: {
    [key: number]: GeofenceWithColor;
  };
  isGeofenceLoading: boolean;
  isRiskAreasLoading: boolean;
  trackingRequests: { [key: number]: RiskAreaTrackingReqCommon[] };
  trackingRequestsLoadState: {
    [key: number]: 'fulfilled' | 'pending' | 'rejected';
  };
};

/* ----- Types for `setupWizard` reducer ----- */

export type SetupWizardState = {
  visible: boolean;
  currentStep: WizardSteps;
  isCompleted: boolean;
  version: string;
  totalSteps: number;
  availableSteps: WizardSteps[];
  showPatBack: boolean;
};

/* ----- Types for `mapEvents` reducer ----- */

export type MapEventsState = {
  hoveredVesselId: number | null;
  addVessel: { active: boolean; input: string };
};

/* ----- Types for `copilot` reducer ----- */

export type CopilotRestrictions = {
  type: RestrictionType;
  name: string;
  locode: string;
  content: string;
  source: RestrictionSourceType;
};

export type CopilotCategories = typeof SEAGPT_CHAT_CATEGORIES[number];

export type CopilotView = 'full' | 'mini';

export type CopilotState = {
  isFlightLoading: boolean;
  emails: {
    shouldRefetchAllPages: boolean;
    view: 'inbox' | 'emailConvo';
    activeEmailConvoId: null | number;
    inboxLastScrollHeight: null | number;
    isLoading: boolean;
    convoKeys: { [key: number]: AssistedConversationWithExtra };
    convoEmailKeys: { [key: number]: AssistedCrewChangeAgentConvoResp };
    queryConvoKeys: {
      [key: string]: {
        rowIds: number[];
        nextPageToken: string;
        totalCount: number;
      };
    };
  };
  view: CopilotView;
  active: boolean;
  category: CopilotCategories;
  context: null | CopilotRestrictions; // currently only used for port restrictions
  fetchCosts: boolean;
  blocks: (SeaGPTChatBlock | NewSeaGptChatBlock)[];
  showAudioFile: boolean;
  inputValue: string;
  activeThread: string | null;
  threads: SeaGPTThreadIdMap;
  showThreadHistory: boolean;
  emailControls: {
    search: string;
    comparedId: number[];
    isCompare: boolean;
    companyFilter: ListAssistedCrewChangeConvosScope;
  };
  autoSubmit: boolean;
};

/* ----- Types for `crewChangeMatrix` reducer ----- */

// crew-change events without on-off pair details
// lighter version of matrix events, fethced in bulk (for entire calendar year)
// to populate Schedule page
export type ScheduleMatrixEvents = {
  [year: string]: MatrixEventSummary[];
};

// stored feedbacks are event based, as opposed to vessel based
export type OnsignerFeedback = {
  [eventId: string]: {
    isLiked: boolean;
    onsigner: Pick<OnsignerDetails, 'externalCrewId'>;
    reason: string;
  }[];
};

export type AddFeedbackPayload = {
  eventId: number;
  isLiked: boolean;
  onsigner: Pick<OnsignerDetails, 'externalCrewId'>;
  reason: string;
};

export type MatrixCrewNotes = {
  [crewId: string]: CrewNotesResponse;
};

export type BoardingRecords = {
  [crewId: string]: CrewBoardingRecord[];
};

export type OrgCrewSchedule = {
  meta: PageMeta;
  data: {
    [page: number]: ListScheduleRecord[];
  };
};

export type CCMatrixState = {
  loaded: boolean;
  syncDetails: SyncDetails | null;
  vessels: CCMatrixVessel[];
  scheduleEvents: ScheduleMatrixEvents | null;
  orgCrewSchedule: OrgCrewSchedule | null;
  feedback: OnsignerFeedback | null;
  crewNotes: MatrixCrewNotes | null;
  crewHistory: BoardingRecords | null;
  crewPersonalInfo: { [crewId: string]: CrewPersonalInfo } | null;
};

/* ----- Types for `portNotes` reducer ----- */

export type PortNotesPayload = {
  locode: string;
  portNotes: PortNotesResponse;
};

export type PortNotes = {
  [locode: string]: PortNotesResponse;
};

/* ----- Types for `bookings` reducer ----- */

export type BookingTransactionMap = { [key: string]: BookingTransaction };

export type BookingFilterLabels =
  | 'Attention Required'
  | 'Upcoming Bookings'
  | 'Past Bookings'
  | 'All Bookings';

export type BookingMetaData = {
  totalCount: number;
  hasNextPage: string | undefined;
};

export type ManageBookingFilters = ListFlightBookingTxQueryParams & {
  label: BookingFilterLabels;
};

export type BookingState = {
  flight: Flight | null;
  bookingModalIsActive: boolean;
  manageBookingModalIsActive: boolean;
  bookings: BookingTransactionMap;
  bookingList: string[];
  manageBookingMetaData: BookingMetaData;
  manageBookingFilters: ManageBookingFilters;
};

// types for search options/items
export type CurrencyOption = {
  Flag: string;
  CountryName: string;
  Currency: string;
  Code: string;
  Symbol: string;
};

/* ----- Types for `mapPort` reducer ----- */
export type MapPortState = {
  ports: SearchedPort[];
  pinnedPorts: SearchedPort[];
};

/* ----- Types for `infoboxPopup` reducer ----- */
export type VesselInfoPopup = {
  type: 'vessel';
  data: number; // vesselId
};
export type PortInfoPopup = {
  type: 'port';
  data: SearchedPort;
};

export type InfoboxPopupState = {
  popup: VesselInfoPopup | PortInfoPopup | null;
};

/* ----- Types for `portCall` reducer ----- */
export type LocodeMap = { [locode: string]: number[] }; // locode vs vesselIds

export type PortCallState = {
  portCalls: PortCallResponseMap;
  portCallRequests: {
    [vesselId: number]: 'fulfilled' | 'pending' | 'rejected';
  };
  portLocodeVesselMap: LocodeMap;
};
