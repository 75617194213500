import { Dictionary, flatten, groupBy } from 'lodash';
import { Position } from '@turf/turf';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import moment from 'moment';

import { textBlack, textGray } from 'lib/colors';
import { Route, WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import { MAIN_ROUTES_FEATURE_ID } from 'map/variables';
import {
  getVesselWithPortCallDetails,
  getVesselWithPortCallPositions,
} from 'utils/routes';
import { PortCallV2CommonUTC, VesselFeature } from 'utils/types';
import { GetVesselRoute } from 'utils/types/route-calculator';
import { RoutePort } from 'components/CrewChangePanel/types';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';

type TypedPortCalls = {
  type: PortCallTypesCommon;
  portCalls: PortCallV2CommonUTC[];
};

export function groupVesselJourney(events: PortCallV2CommonUTC[]) {
  const grouped: Dictionary<PortCallV2CommonUTC[]> = groupBy(events, 'type');

  return Object.keys(grouped).reduce<TypedPortCalls[]>((acc, portCallType) => {
    acc.push({
      type: portCallType as PortCallTypesCommon,
      portCalls: grouped[portCallType as PortCallTypesCommon],
    });
    return acc;
  }, []);
}

export type SegmentTypes = 'past' | 'future' | 'both';

type FormattedRouteProperties = {
  distance: number;
  path: Position[][];
  properties: {
    segment: 'past' | 'future';
    distance: number;
    units: string;
    type: string;
    from: string;
    to: string;
  };
};

export type DetailedRouteObject = {
  id?: number;
  displayType: string;
  segment: SegmentTypes;
  waypoints: WaypointWithProperties[];
  route: FormattedRouteProperties[];
};

export function formatWaypointToRouteProperties(
  rawRoute: Route[],
  waypoints: WaypointWithProperties[]
): FormattedRouteProperties[] {
  return rawRoute.map((route, index) => {
    if (index === 0) {
      const { segment, waypointType, type, port, displayName } =
        route.properties;
      const isFromVessel =
        waypoints[index].properties.waypointType === 'vessel';
      const isGoingToVessel = waypointType === 'vessel';
      // eslint-disable-next-line
      const _segment = isFromVessel
        ? 'future'
        : isGoingToVessel
        ? 'past'
        : segment;
      const nextDestinationName = port?.locode || displayName;
      const prevDestinationName =
        waypoints[index].properties.port?.locode ||
        waypoints[index].properties.displayName;
      const newProperties = {
        distance: route.distance,
        units: route.properties.units,
        segment: _segment,
        type: String(type || waypoints[index].properties.type).toLowerCase(),
        from:
          waypoints[index].properties.waypointType === 'vessel'
            ? 'vessel'
            : prevDestinationName,
        to: waypointType === 'vessel' ? 'vessel' : nextDestinationName,
        color: _segment === 'future' ? textBlack : textGray,
        arrow: _segment === 'future' ? 'route-arrow-blue' : 'route-arrow-white',
      };
      return { ...route, properties: newProperties };
    } else {
      const { segment, waypointType, type, port, displayName } =
        rawRoute[index - 1]?.properties;
      const isFromVessel = waypointType === 'vessel';
      const isGoingToVessel = route.properties.waypointType === 'vessel';
      // eslint-disable-next-line
      const _segment = isFromVessel
        ? 'future'
        : isGoingToVessel
        ? 'past'
        : segment;
      const prevDestinationName = port?.locode || displayName;
      const nextDestinationName =
        route.properties.port?.locode || route.properties.displayName;
      const newProperties = {
        distance: route.distance,
        units: route.properties.units,
        segment: _segment,
        type: String(route.properties.type || type).toLowerCase(),
        from: waypointType === 'vessel' ? 'vessel' : prevDestinationName,
        to:
          route.properties.waypointType === 'vessel'
            ? 'vessel'
            : nextDestinationName,
        color: _segment === 'future' ? textBlack : textGray,
        arrow: _segment === 'future' ? 'route-arrow-blue' : 'route-arrow-white',
      };
      return { ...route, properties: newProperties };
    }
  });
}

export function prepareCCFutureRoute({
  portCalls,
  route,
  vessel,
}: {
  portCalls: RoutePort[];
  route: Route[];
  vessel: Vessel;
}): DetailedRouteObject {
  const waypoints: WaypointWithProperties[] = portCalls.map(
    ({ lng, lat, ...properties }) => ({
      coor: [lng!, lat!],
      properties: {
        ...properties,
        waypointType: 'port',
        segment: 'future',
        lng,
        lat,
      },
    })
  );
  waypoints.unshift({
    coor: [vessel.lng, vessel.lat],
    properties: {
      waypointType: 'vessel',
    },
  });
  const formattedRoute: FormattedRouteProperties[] =
    formatWaypointToRouteProperties(route, waypoints);
  return {
    displayType: 'future',
    segment: 'future',
    waypoints,
    route: formattedRoute,
    // lineString: [],
    // totalDistance: 0,
  };
}

export async function prepareAllRoutes({
  getVesselRoute,
  portCalls,
  vessel,
}: {
  getVesselRoute: GetVesselRoute;
  portCalls: PortCallV2CommonUTC[];
  vessel: Vessel;
}) {
  const allRoutes: DetailedRouteObject[] = [];
  const allWaypoints = getVesselWithPortCallDetails(portCalls, vessel);
  const calculatedCompleteRoute = await getVesselRoute({
    waypoints: allWaypoints,
    isSplit: true,
  });

  // Reformat properties
  if (
    allWaypoints.length -
      calculatedCompleteRoute.calculation.posRoute.length !==
    1
  ) {
    console.error("UH OH!! this shouldn't happen");
  }
  const formattedRoute: FormattedRouteProperties[] =
    formatWaypointToRouteProperties(
      calculatedCompleteRoute.calculation.posRoute,
      allWaypoints
    );
  allRoutes.push({
    displayType: 'all',
    segment: 'both',
    waypoints: allWaypoints,
    route: formattedRoute,
  });
  allRoutes.push({
    displayType: 'all',
    segment: 'past',
    waypoints: allWaypoints.filter((o) => o.properties.segment !== 'future'),
    route: formattedRoute.filter((o) => o.properties.segment !== 'future'),
  });
  allRoutes.push({
    displayType: 'all',
    segment: 'future',
    waypoints: allWaypoints.filter((o) => o.properties.segment !== 'past'),
    route: formattedRoute.filter((o) => o.properties.segment !== 'past'),
  });

  const group = groupVesselJourney(portCalls);
  for await (const each of group) {
    const groupWaypoints = getVesselWithPortCallDetails(each.portCalls, vessel);
    const calculatedRoute = await getVesselRoute({
      waypoints: groupWaypoints,
    });
    const { route, calculation } = calculatedRoute;
    if (!route.length) {
      break;
    }
    const formattedRoute: FormattedRouteProperties[] =
      formatWaypointToRouteProperties(calculation.posRoute, groupWaypoints);
    allRoutes.push({
      displayType: String(each.type).toLowerCase(),
      segment: 'both',
      waypoints: groupWaypoints,
      route: formattedRoute,
    });
    allRoutes.push({
      displayType: String(each.type).toLowerCase(),
      segment: 'past',
      waypoints: groupWaypoints.filter(
        (o) => o.properties.segment !== 'future'
      ),
      route: formattedRoute.filter((o) => o.properties.segment !== 'future'),
    });
    allRoutes.push({
      displayType: String(each.type).toLowerCase(),
      segment: 'future',
      waypoints: groupWaypoints.filter((o) => o.properties.segment !== 'past'),
      route: formattedRoute.filter((o) => o.properties.segment !== 'past'),
    });
  }
  return allRoutes;
}

// this will prepare only a single type of route, e.g. all + future, ais + future, etc
export async function prepareSingleTypeRoute({
  displayType,
  segmentType,
  getVesselRoute,
  portCalls,
  vessel,
}: {
  displayType: string;
  segmentType: SegmentTypes;
  getVesselRoute: GetVesselRoute;
  portCalls: PortCallV2CommonUTC[];
  vessel: Vessel;
}) {
  const usePortCalls =
    displayType === 'all_sources'
      ? portCalls
      : portCalls.filter((o) => String(o.type).toLowerCase() === displayType);
  const allWaypoints = getVesselWithPortCallDetails(usePortCalls, vessel);

  const useWaypoints =
    segmentType === 'both'
      ? allWaypoints
      : allWaypoints.filter((o) => {
          if (segmentType === 'future') {
            return o.properties.segment !== 'past';
          }
          return o.properties.segment !== 'future';
        });

  const calculatedCompleteRoute = await getVesselRoute({
    waypoints: useWaypoints,
    isSplit: true,
  });

  const formattedRoute: FormattedRouteProperties[] =
    formatWaypointToRouteProperties(
      calculatedCompleteRoute.calculation.posRoute,
      useWaypoints
    );

  return {
    displayType,
    segment: segmentType,
    waypoints: useWaypoints,
    route: formattedRoute,
  };
}

// This will generate a single route with all types combined
export async function prepareSingleRoute({
  getVesselRoute,
  portCalls,
  vessel,
}: {
  getVesselRoute: GetVesselRoute;
  portCalls: PortCallV2CommonUTC[];
  vessel: Vessel;
}): Promise<VesselFeature[] | undefined> {
  const allRoutes = [];
  const futurePortCalls = portCalls.filter(({ utcETA, utcETD }) => {
    if (utcETA && utcETD) {
      return (
        moment(utcETD).isAfter(moment()) && moment(utcETA).isAfter(moment())
      );
    }
    if (!utcETA && utcETD) {
      return moment(utcETD).isAfter(moment());
    }
    if (utcETA && !utcETD) {
      return moment(utcETA).isAfter(moment());
    }
    return false;
  });

  const completeRoute = await getVesselRoute({
    waypoints: getVesselWithPortCallPositions(futurePortCalls, vessel),
  });

  allRoutes.push({
    type: 'Feature',
    id: `${MAIN_ROUTES_FEATURE_ID}${vessel.id}`,
    properties: {
      id: `${MAIN_ROUTES_FEATURE_ID}${vessel.id}`,
      vesselId: vessel.id,
      distance: completeRoute.calculation.totalDistance,
    },
    geometry: {
      type: 'MultiLineString',
      coordinates: flatten(
        completeRoute.calculation.posRoute.map((each) => each.path)
      ),
    },
  });
  return allRoutes as VesselFeature[];
}
