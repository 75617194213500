import React, { useCallback, useMemo, useState } from 'react';
import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import styled from 'styled-components/macro';
import { SignoutIcon } from '../../icons';
import { logoutUser } from 'utils/auth';

import { gray20, gray30, gray70, red } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import timezonesListed from 'api/data/timezones.json';
import { UserInfo } from 'utils/types';

import { Section, Text, Title as Header, ActionButton } from '../common';
import { AccountProps } from '../types';
import { useAppDispatch } from 'hooks';

const Wrapper = styled.div`
  padding: auto 0.5rem;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${gray70} !important;
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1.6fr 4fr;
  grid-gap: 10px;
  margin-bottom: 0.75rem;
  max-width: 35rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90% !important;
    grid-template-columns: 1.33fr 3fr;
  }
`;

const LogoutWrapper = styled.div`
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-bottom: 1rem;
  }
`;

const Asterisk = styled.span`
  color: ${red};
  margin-left: 2px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '0.9rem',
  fontFamily: 'HK Grotesk, Roboto',
  background: `${gray20}`,
  border: `1px solid ${gray30}`,
  borderRadius: '4px',
};

function Account({
  initialValues,
  updateSettings,
  errorMessage,
}: AccountProps) {
  const [userInfo, setUserInfo] = useState((initialValues || {}) as UserInfo);
  const timezones: string[] = useMemo(
    () =>
      JSON.parse(JSON.stringify(timezonesListed)).map(
        ({ value }: { value: string }) => value
      ),
    []
  );

  const handleUpdate = (type: string) => (event: React.SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    const updatedInfo = { ...userInfo, [type]: value || null };
    setUserInfo(updatedInfo);
    updateSettings((prev) => ({ ...prev, userInfo: updatedInfo }));
  };

  const updateTimezone = (newValue: string | null) => {
    const updatedUser = { ...userInfo, timezone: newValue };
    setUserInfo(updatedUser);
    updateSettings((prev) => ({ ...prev, userInfo: updatedUser }));
  };

  const dispatch = useAppDispatch();

  const handleClearState = useCallback(() => {
    logoutUser(dispatch, true);
  }, []); // eslint-disable-line

  return (
    <Wrapper>
      <Section>
        <HeaderWrapper>
          <Header data-id="settings_account-title">User Details</Header>
          <Text>Update user information and save.</Text>
        </HeaderWrapper>

        <InputWrapper>
          <Title>
            Account Email<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            disabled
            defaultValue={userInfo.loginEmail}
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            {/* Asterisk for required field */}
            <span>
              Notification Email<Asterisk>*</Asterisk>
            </span>
            <span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userInfo.notificationEmail || ''}
            onChange={handleUpdate('notificationEmail')}
            placeholder="Add Notification Email"
          />
        </InputWrapper>
        {Boolean(errorMessage) && (
          <InputWrapper style={{ marginTop: '-0.75rem' }}>
            <div />
            <FormHelperText error>{errorMessage}</FormHelperText>
          </InputWrapper>
        )}

        <InputWrapper>
          <Title>
            First Name<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userInfo.firstname || ''}
            onChange={handleUpdate('firstname')}
            placeholder="Add First Name"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Last Name<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userInfo.lastname || ''}
            onChange={handleUpdate('lastname')}
            placeholder="Add Last Name"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Phone<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userInfo.phone || ''}
            onChange={handleUpdate('phone')}
            placeholder="Add Phone Number"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Job Title<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userInfo.jobTitle || ''}
            onChange={handleUpdate('jobTitle')}
            placeholder="Add Job Title"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Timezone<span>:</span>
          </Title>
          <Autocomplete
            disablePortal
            freeSolo
            size="small"
            options={timezones}
            value={userInfo.timezone || null}
            onChange={(_, value) => updateTimezone(value)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Timezone" />
            )}
            ListboxProps={{ style: dropDownStyles }}
            sx={{ width: '100%' }}
          />
        </InputWrapper>

        <LogoutWrapper>
          <ActionButton
            className="e2e_logout"
            variant="dark"
            onClick={handleClearState}
          >
            <SignoutIcon />
            Logout
          </ActionButton>
        </LogoutWrapper>
      </Section>
    </Wrapper>
  );
}

export default Account;
