import uniqBy from 'lodash/uniqBy';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToaster } from 'lib/toaster';
import { fetchPortNotes } from 'api/flotilla';
import { RootState } from 'redux/types';

type Props = {
  locode: string | null | undefined;
  refetch?: boolean;
};

export const fetchPortNotesAsync = createAsyncThunk(
  'portNotes/fetchPortNotesAsync',
  async ({ locode, refetch }: Props, { getState }) => {
    if (!locode) return null;

    const { portNotes } = getState() as RootState;
    const { notes: currentNotes = [], nextPageToken } =
      portNotes?.[locode] || {};

    const token = !refetch ? nextPageToken : undefined;
    const {
      success,
      message,
      notesResponse: response,
    } = await fetchPortNotes(locode, token);

    if (!success || !response) {
      showToaster({ message, type: 'error' });
      return null;
    }

    const updatedNotes = uniqBy(
      [...response.notes, ...currentNotes],
      'id'
    ).sort((a, b) => b.createdAt.localeCompare(a.createdAt));

    return {
      locode,
      portNotes: { ...response, notes: updatedNotes },
    };
  }
);
