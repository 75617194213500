import styled from 'styled-components/macro';

import { textBlack, textGray } from 'lib/colors';

// common Wrapper
const DataGridWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 999;

  // remove cell border when clicked/focused
  .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  // hide all select option in the table
  .MuiDataGrid-columnHeaderTitleContainer span.MuiCheckbox-root {
    display: none;
  }

  .MuiTablePagination-root p {
    font-family: 'HK Grotesk';
    font-weight: 400;
    color: ${textBlack};
  }
`;

// common header styles for data grid header row
export const getHeaderStyle = (headerClassName: string) => ({
  fontFamily: 'HK Grotesk, Roboto',
  [`& ${headerClassName}`]: {
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: '0.05rem',
    fontSize: '0.75rem',
    color: `${textGray}`,
  },
});

export default DataGridWrapper;
