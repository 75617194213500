import { useSelector } from 'react-redux';
import MUITooltip, {
  TooltipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { PopperProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { blue } from 'lib/colors';
import { selectFlotillaSearch } from 'redux/selectors';

const StyledTooltip = styled(
  ({ className, ...props }: TooltipProps & { background: string }) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  )
)(({ theme, background }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background,
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: '0.8rem',
    fontFamily: 'HK Grotesk, Roboto',
    fontWeight: 'normal',
    maxWidth: '500px',
    minHeight: '1.2rem',
    minWidth: '1.2rem',
  },
  '.MuiTooltip-arrow': {
    '&:before': {
      background,
    },
  },
}));

type Props = {
  content: React.ReactNode;
  children: React.ReactElement<any, any>; // based on MUI type
  arrow?: boolean;
  background?: string;
  placement?: TooltipProps['placement'];
  enterDelay?: number;
  enterNextDelay?: number;
  PopperProps?: PopperProps;
  open?: boolean;
  disableInteractive?: boolean;
};

function Tooltip({
  content,
  children,
  arrow = true,
  background = blue,
  placement = 'top',
  enterDelay,
  enterNextDelay,
  PopperProps,
  open,
  disableInteractive,
}: Props) {
  const { searchIsExpanded } = useSelector(selectFlotillaSearch);

  return content ? (
    <StyledTooltip
      open={open}
      title={content}
      placement={placement}
      arrow={arrow}
      background={background}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      PopperProps={PopperProps}
      // prevent interaction with tooltip for flotilla search open state
      disableInteractive={searchIsExpanded || disableInteractive}
    >
      <span>{children}</span>
    </StyledTooltip>
  ) : null;
}

export default Tooltip;
