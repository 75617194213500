import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEffect, useMemo, useRef } from 'react';

import mapboxgl, {
  Alignment,
  Anchor,
  MapMouseEvent,
  PointLike,
  Popup,
  Marker,
  MapboxEvent,
} from 'mapbox-gl';
import { mapState } from 'map/map';

export type MarkerProps = {
  id: string;
  /** Longitude of the anchor location */
  longitude: number;
  /** Latitude of the anchor location */
  latitude: number;
  /** A string indicating the part of the Marker that should be positioned closest to the coordinate set via Marker.setLngLat.
   * Options are `'center'`, `'top'`, `'bottom'`, `'left'`, `'right'`, `'top-left'`, `'top-right'`, `'bottom-left'`, and `'bottom-right'`.
   * @default "center"
   */
  anchor?: Anchor;
  /**
   * The max number of pixels a user can shift the mouse pointer during a click on the marker for it to be considered a valid click
   * (as opposed to a marker drag). The default (0) is to inherit map's clickTolerance.
   */
  clickTolerance?: number;
  /** The color to use for the default marker if options.element is not provided.
   * @default "#3FB1CE"
   */
  color?: string;
  /** A boolean indicating whether or not a marker is able to be dragged to a new position on the map.
   * @default false
   */
  draggable?: boolean;
  /** The offset in pixels as a PointLike object to apply relative to the element's center. Negatives indicate left and up. */
  offset?: PointLike;
  /** `map` aligns the `Marker` to the plane of the map.
   * `viewport` aligns the `Marker` to the plane of the viewport.
   * `auto` automatically matches the value of `rotationAlignment`.
   * @default "auto"
   */
  pitchAlignment?: Alignment;
  /** The rotation angle of the marker in degrees, relative to its `rotationAlignment` setting. A positive value will rotate the marker clockwise.
   * @default 0
   */
  rotation?: number;
  /** `map` aligns the `Marker`'s rotation relative to the map, maintaining a bearing as the map rotates.
   * `viewport` aligns the `Marker`'s rotation relative to the viewport, agnostic to map rotations.
   * `auto` is equivalent to `viewport`.
   * @default "auto"
   */
  rotationAlignment?: Alignment;
  /** The scale to use for the default marker if options.element is not provided.
   * The default scale (1) corresponds to a height of `41px` and a width of `27px`.
   * @default 1
   */
  scale?: number;
  /** A Popup instance that is bound to the marker */
  popup?: Popup;
  /** CSS style override, applied to the control's container */
  style?: React.CSSProperties;
  onClick?: (e: MapboxEvent<MouseEvent>) => void;
  onDragStart?: (e: MapMouseEvent) => void;
  onDrag?: (e: MapMouseEvent) => void;
  onDragEnd?: (id: string, e: MapMouseEvent) => void;
  children?: React.ReactNode;
};

export function arePointsEqual(a?: PointLike, b?: PointLike): boolean {
  // eslint-disable-next-line no-nested-ternary
  const ax = Array.isArray(a) ? a[0] : a ? a.x : 0;
  // eslint-disable-next-line no-nested-ternary
  const ay = Array.isArray(a) ? a[1] : a ? a.y : 0;
  // eslint-disable-next-line no-nested-ternary
  const bx = Array.isArray(b) ? b[0] : b ? b.x : 0;
  // eslint-disable-next-line no-nested-ternary
  const by = Array.isArray(b) ? b[1] : b ? b.y : 0;
  return ax === bx && ay === by;
}

function MapboxMarker(props: MarkerProps) {
  const map = mapState.map;
  const thisRef = useRef({ props });
  thisRef.current.props = props;

  const marker: Marker = useMemo(() => {
    let hasChildren = false;
    React.Children.forEach(props.children, (el) => {
      if (el) {
        hasChildren = true;
      }
    });
    const options = {
      ...props,
      element: hasChildren ? document.createElement('div') : undefined,
    };

    const mk = new mapboxgl.Marker(options).setLngLat([
      props.longitude,
      props.latitude,
    ]);

    mk.on('dragstart', (e) => {
      const evt = e as MapMouseEvent;
      evt.lngLat = marker.getLngLat();
      thisRef.current.props.onDragStart?.(evt);
    });
    mk.on('drag', (e) => {
      const evt = e as MapMouseEvent;
      evt.lngLat = marker.getLngLat();
      thisRef.current.props.onDrag?.(evt);
    });
    mk.on('dragend', (e) => {
      const evt = e as MapMouseEvent;
      evt.lngLat = marker.getLngLat();
      thisRef.current.props.onDragEnd?.(thisRef.current.props.id, evt);
    });

    return mk;
  }, []); // eslint-disable-line

  useEffect(() => {
    marker.addTo(map!);

    return () => {
      marker.remove();
    };
  }, []); // eslint-disable-line

  if (
    marker.getLngLat().lng !== props.longitude ||
    marker.getLngLat().lat !== props.latitude
  ) {
    marker.setLngLat([props.longitude, props.latitude]);
  }
  if (props.offset && !arePointsEqual(marker.getOffset(), props.offset)) {
    marker.setOffset(props.offset);
  }
  if (marker.isDraggable() !== props.draggable) {
    if (props.draggable !== undefined) {
      marker.setDraggable(props.draggable);
    }
  }
  if (marker.getRotation() !== props.rotation) {
    if (props.rotation) {
      marker.setRotation(props.rotation);
    }
  }
  if (marker.getRotationAlignment() !== props.rotationAlignment) {
    if (props.rotationAlignment) {
      marker.setRotationAlignment(props.rotationAlignment);
    }
  }
  if (marker.getPitchAlignment() !== props.pitchAlignment) {
    if (props.pitchAlignment) {
      marker.setPitchAlignment(props.pitchAlignment);
    }
  }
  if (marker.getPopup() !== props.popup) {
    marker.setPopup(props.popup);
  }

  return createPortal(props.children, marker.getElement());
}

// @ts-ignore
export default React.memo(MapboxMarker);
