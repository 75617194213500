import moment from 'moment';
import {
  FlightBookingTravelerInfoUpsertDtoCommon,
  GetFlightBookingTxResp,
  FlightFareTypeCommon,
} from '@greywing-maritime/frontend-library/dist/types/flightBooking';
import { BookingTransaction, FlightBookingTicketDetails } from './types';
import _ from 'lodash';

export function formatName({
  firstName,
  lastName,
}: Pick<FlightBookingTravelerInfoUpsertDtoCommon, 'firstName' | 'lastName'>) {
  return `${String(lastName).toUpperCase()} ${firstName}`;
}

export function carrierName({
  flightNumber,
  operatingCarrierName,
}: {
  flightNumber: string;
  operatingCarrierName: string;
}) {
  return `${flightNumber.trim()} ${operatingCarrierName.trim()}`;
}

export function formatPrice({
  amount,
  currency,
}: {
  amount: number;
  currency: string;
}) {
  return `${currency} ${Number(amount).toFixed(2)}`;
}

export function formatTravellerSubmission({
  firstName,
  lastName,
  middleName,
  dob,
  passportIssueDate,
  passportExpiryDate,
  passportNo,
  passportCountryOfIssuanceIso2,
  nationalityIso2,
  countryOfBirthIso2,
  crewIdentifier,
  email,
  phoneNo,
  gender,
}: FlightBookingTravelerInfoUpsertDtoCommon) {
  // manually format inputs from backend to fit form
  const formatted: FlightBookingTravelerInfoUpsertDtoCommon = {
    email,
    phoneNo,
    gender,
    passportNo,
    passportCountryOfIssuanceIso2,
    nationalityIso2,
    countryOfBirthIso2,
    firstName: firstName.trim(),
    lastName: lastName.trim(),
    middleName: middleName?.trim() || '',
    dob: moment(dob).format('YYYY-MM-DD'),
    passportIssueDate: moment(passportIssueDate).format('YYYY-MM-DD'),
    passportExpiryDate: moment(passportExpiryDate).format('YYYY-MM-DD'),
  };
  if (crewIdentifier) formatted['crewIdentifier'] = crewIdentifier;
  return formatted;
}

const initialValues: FlightBookingTravelerInfoUpsertDtoCommon = {
  gender: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  dob: '',
  passportNo: '',
  passportExpiryDate: '',
  passportIssueDate: '',
  nationalityIso2: '',
  countryOfBirthIso2: '',
  passportCountryOfIssuanceIso2: '',
  phoneNo: '',
};

export function formatTravellerInput(
  values: FlightBookingTravelerInfoUpsertDtoCommon | null
) {
  // manually format inputs from backend to fit form
  if (!values) return initialValues;
  const {
    firstName,
    lastName,
    middleName,
    dob,
    passportIssueDate,
    passportExpiryDate,
    passportNo,
    passportCountryOfIssuanceIso2,
    nationalityIso2,
    countryOfBirthIso2,
    crewIdentifier,
    email,
    phoneNo,
    gender,
  } = values;
  return {
    firstName,
    lastName,
    passportNo,
    passportCountryOfIssuanceIso2,
    nationalityIso2,
    countryOfBirthIso2,
    crewIdentifier,
    email,
    phoneNo,
    gender,
    middleName: middleName || '',
    dob: moment(dob, 'YYYY-MM-DD').format('DD-MM-YYYY'),
    passportIssueDate: moment(passportIssueDate, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    ),
    passportExpiryDate: moment(passportExpiryDate, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    ),
  };
}

const initialFlightProposalValues: FlightBookingTicketDetails = {
  PNR: '',
  price: {
    fees: [
      {
        itemName: '',
        amount: 0,
        currency: 'USD',
      },
    ],
    taxes: [
      {
        itemName: '',
        amount: 0,
        currency: 'USD',
      },
    ],
    total: {
      itemName: '',
      amount: 0,
      currency: 'USD',
    },
  },
  segments: [
    {
      carrierName: '',
      flightNo: '',
      cabinClass: undefined,
      luggage: '',
      departureIata: '',
      departureTime: new Date(0),
      arrivalIata: '',
      arrivalTime: new Date(0),
      seat: '',
    },
  ],
  dateOfIssue: new Date(0),
  fareType: FlightFareTypeCommon.UNKNOWN,
  notes: '',
};

export function formatFlightProposal(values: BookingTransaction | null) {
  const temp = _.cloneDeep(initialFlightProposalValues);

  if (!values) return temp;
  const { flightInfo } = values;
  temp.price.fees[0].amount = Number(flightInfo.price.amount);
  temp.price.fees[0].currency = flightInfo.price.currency;
  temp.segments = flightInfo.segments.map((segment) => {
    const { flightNo, departureIata, departureTime, arrivalIata, arrivalTime } =
      segment;
    return {
      carrierName: '',
      flightNo: flightNo,
      cabinClass: flightInfo.cabinClass,
      luggage: '',
      departureIata: departureIata,
      departureTime: new Date(departureTime),
      arrivalIata: arrivalIata,
      arrivalTime: new Date(arrivalTime),
      seat: '',
    };
  });
  temp.fareType = flightInfo.fareType;

  return temp;
}

export function mergeDictWithTransaction({
  transaction,
  dictionaries,
}: GetFlightBookingTxResp): BookingTransaction {
  const flightSegments = transaction.flightInfo.segments.map((o) => ({
    ...o,
    departure: dictionaries.airports[o.departureIata] || null,
    arrival: dictionaries.airports[o.arrivalIata] || null,
  }));
  return {
    ...transaction,
    flightSegments,
  };
}

export const cabinClassOptions = [
  'ECONOMY',
  'PREMIUM_ECONOMY',
  'BUSINESS',
  'FIRST',
  'MIXED',
];
