import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { Fade, Menu, MenuItem, Switch } from '@mui/material';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import styled from 'styled-components/macro';

import { useAppDispatch, useMobile } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { updateFlightSearch } from 'redux/actions';
import { Checkbox, Tooltip } from 'components/shared';
import {
  menuItemStyles,
  switchStyles,
} from 'components/CrewChangePanel/Table/Actions/Flights/common';
import { LayoverAirport } from 'components/CrewChangePanel/types';
import { commonMenuStyles, Footer, StyledFilterButton, Title } from './common';
import { isAllDeselected, isAllSelected } from './helpers';

const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;

const iconStyles = {
  marginLeft: '-5px',
  marginRight: '5px',
};

type Props = {
  layovers: LayoverAirport[];
  updateFilters: (layovers: LayoverAirport[], type?: 'Select All') => void;
};

// This component handles filters updates for layovers
// in both crew change panel & search flight features
function LayoverSelection({ layovers, updateFilters }: Props) {
  const isMobile = useMobile(BREAK_POINT_XS);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStops, setSelectedStops] = useState(layovers);
  const [allSelected, setAllSelected] = useState(isAllSelected(layovers));

  useEffect(() => {
    if (isAllSelected(layovers)) {
      setAllSelected(true);
    }
    if (isAllDeselected(layovers)) {
      setAllSelected(false);
    }
  }, [layovers]);

  const handleUpdateFilters = (layovers: LayoverAirport[]) => {
    setSelectedStops(layovers);
    updateFilters(layovers);
  };

  const handleSelectAllLayovers = (value: boolean) => {
    const updatedLayovers = layovers.map((stop) => ({
      ...stop,
      selected: value,
    }));

    setAllSelected(value);
    setSelectedStops(updatedLayovers);
    updateFilters(updatedLayovers, 'Select All');
  };

  const handleFlightSearchAction = (value: boolean) => {
    // state update when popup is open to prevent closing search results
    dispatch(updateFlightSearch({ listOpen: value }));
  };

  const handleOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.target as HTMLElement);
    handleFlightSearchAction(true);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    handleFlightSearchAction(false);
  };

  const handleToggle = (iataCode: string) => {
    const updatedStops = selectedStops.map((stop) =>
      stop.iataCode === iataCode ? { ...stop, selected: !stop.selected } : stop
    );
    handleUpdateFilters(updatedStops);
  };

  return (
    <>
      <ButtonWrapper>
        <Tooltip content="Select layovers cities">
          <StyledFilterButton variant="primary" onClick={handleOpen} $stop>
            {!isMobile && <MultipleStopIcon fontSize="small" sx={iconStyles} />}
            Select Layovers
          </StyledFilterButton>
        </Tooltip>
      </ButtonWrapper>

      <Menu
        id="layovers-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={commonMenuStyles}
      >
        <Title>Layover Airports</Title>
        {layovers.map(({ airport, iataCode, selected }) => (
          <MenuItem key={iataCode} onClick={() => {}} sx={menuItemStyles}>
            <Switch
              size="small"
              checked={selected}
              sx={switchStyles}
              onClick={() => handleToggle(iataCode)}
            />
            {airport}
          </MenuItem>
        ))}
        <Footer>
          <Checkbox
            id="layover-selection"
            label="Select All"
            checked={allSelected}
            onChange={handleSelectAllLayovers}
          />
        </Footer>
      </Menu>
    </>
  );
}

export default memo(LayoverSelection);
