import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/types';

import { useAssignPIC } from 'hooks';
import { selectSidePanelVesselsNotPIC } from 'redux/reducers/sidePanel';

import { Button } from '../shared';
import { ActionButtonState } from './SidePanelVesselHeader';

function AssignPIC() {
  const sidePanelVesselsNotPIC = useSelector(selectSidePanelVesselsNotPIC);
  const { onAssignment, assignPICState } = useAssignPIC();
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);
  const showButton = useMemo(
    () => userInfo?.access['Assign PIC'] && sidePanelVesselsNotPIC,
    [userInfo, sidePanelVesselsNotPIC]
  );

  return showButton ? (
    <Button
      size="small"
      variant={
        assignPICState === ActionButtonState.normal ? 'secondary' : 'primary'
      }
      onClick={onAssignment}
      style={{ margin: 0 }}
    >
      {(() => {
        switch (assignPICState) {
          case ActionButtonState.normal:
            return 'ASSIGN IN-CHARGE';
          case ActionButtonState.in_progress:
            return 'ASSIGNING...';
          default:
            return 'ASSIGNED AS IN-CHARGED';
        }
      })()}
    </Button>
  ) : null;
}

export default memo(AssignPIC);
