import type { TrackFlightReqBody } from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';

import { Flight } from 'utils/types/crew-change-types';

export const getFlightTrackRequest = (flight: Flight): TrackFlightReqBody => {
  const {
    uid,
    requestId,
    source,
    type: { type },
    flightNumbers = [],
    departure,
    arrival,
    stops,
    priceInUSD,
    price,
    segments,
    totalFlightTime,
    totalCO2,
    numSeatsAvailable = 0,
    cabinClass,
  } = flight;

  // request details are separated by double hyphen
  const paramsArr = requestId.split('--');
  const searchParams = {
    startCode: paramsArr[0],
    endCode: paramsArr[1],
    startDate: paramsArr[2],
  };

  return {
    searchParams,
    uid: uid as string,
    source,
    type: { type },
    flightNumbers,
    departure,
    arrival,
    stops,
    priceInUSD: priceInUSD || price.amount,
    segments,
    totalFlightTime,
    totalCO2,
    numSeatsAvailable,
    cabinClass,
  };
};
