import { memo } from 'react';
import { TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { MuiTelInput } from 'mui-tel-input';

type FormikTextFieldProps = TextFieldProps & {
  id: string;
};

function PhoneInput(props: FormikTextFieldProps) {
  const [field, meta, helpers] = useField(props.id);
  const handleChange = (value: any) => {
    helpers.setValue(value);
  };

  return (
    <MuiTelInput
      {...props}
      {...field}
      value={field.value}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

export default memo(PhoneInput);
