import {
  SETUP_WIZARD_COMPLETED,
  SETUP_WIZARD_STEP,
  SETUP_WIZARD_STORAGE,
} from 'lib/constants';
import { APP_VERSION } from 'utils/constants';
import { ALL_SETUP_WIZARD_STEPS } from 'utils/types';
import { validateSetupWizard } from 'utils/validation/setup-wizard';
import { SetupWizardState } from '../types';

const initialSetupWizard: Omit<SetupWizardState, 'visible' | 'showPatBack'> = {
  currentStep: 'role',
  version: APP_VERSION,
  isCompleted: false,
  availableSteps: [...ALL_SETUP_WIZARD_STEPS],
  totalSteps: ALL_SETUP_WIZARD_STEPS.length,
};

export function loadInitialSetupWizard(): SetupWizardState {
  // Clear old values
  window.localStorage.removeItem(SETUP_WIZARD_STEP);
  window.localStorage.removeItem(SETUP_WIZARD_COMPLETED);

  const existingSetupWizard = localStorage.getItem(SETUP_WIZARD_STORAGE);

  if (!existingSetupWizard) {
    localStorage.setItem(
      SETUP_WIZARD_STORAGE,
      JSON.stringify(initialSetupWizard)
    );
    return { ...initialSetupWizard, showPatBack: false, visible: true };
  }
  try {
    const setupWizardState = validateSetupWizard(
      JSON.parse(existingSetupWizard)
    );
    return {
      visible: !setupWizardState?.isCompleted, // show wizard if steps are not complete
      ...initialSetupWizard,
      ...setupWizardState,
      ...(setupWizardState?.currentStep === 'contact' &&
      setupWizardState.isCompleted
        ? { currentStep: 'role' }
        : {}),
      showPatBack: false,
    };
  } catch (error) {
    localStorage.removeItem(SETUP_WIZARD_STORAGE);
    return {
      visible: true,
      showPatBack: false,
      ...initialSetupWizard,
    };
  }
}

export function saveSetupWizardToLocalStorage(
  values: Pick<SetupWizardState, 'currentStep' | 'isCompleted' | 'version'>
) {
  const { currentStep, isCompleted, version } = values;
  localStorage.setItem(
    SETUP_WIZARD_STORAGE,
    JSON.stringify({ currentStep, isCompleted, version })
  );
}
