import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { CircularProgress, Modal } from '@mui/material';

import placeholderImg from 'assets/images/empty-map-sm.png';

import LoginForm from './LoginForm';

const LoginContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ImageContainer = styled.div<{ $loaded: boolean }>`
  transition: opacity 200ms ease-in;
  opacity: ${({ $loaded }) => ($loaded ? '1' : '0')};
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    filter: blur(2px);
  }
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function LoginPage() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>('');

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    const img = new Image();
    img.src = placeholderImg;

    // Set default timeout of 3 seconds to load.
    // Otherwise just set it
    timeout = setTimeout(() => {
      setImgSrc(placeholderImg);
      setIsOpen(true);
    }, 3000);

    img.onload = () => {
      setImgSrc(placeholderImg);
      timeout = setTimeout(() => {
        setIsOpen(true);
      }, 500);
    };
    img.onerror = () => {
      timeout = setTimeout(() => {
        setIsOpen(true);
      }, 500);
    };
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return (
    <LoginContainer id="flotilla-v2">
      {!imgSrc && (
        <LoadingContainer>
          <CircularProgress size={16} />
        </LoadingContainer>
      )}
      <ImageContainer $loaded={!!imgSrc}>
        <img src={imgSrc} alt="blurred map background" />
      </ImageContainer>
      <Modal disableEscapeKeyDown open={isOpen}>
        <>
          <LoginForm />
        </>
      </Modal>
    </LoginContainer>
  );
}

export default LoginPage;
