import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { useState, useContext, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ButtonV2, SuccessAnimation as Animation } from 'components/shared';
import styled from 'styled-components/macro';
import {
  black,
  blue50,
  fadedGreen,
  lightFadedGreen,
  red,
  red10,
  gray20,
  gray50,
  inactivePrimary,
} from 'lib/colors';
// mui icons
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { selectCrewChangePanel, selectMapVessels } from 'redux/selectors';
import { getVesselById } from 'utils/vessel';
import { useMobile } from 'hooks';
import { isDevelopment } from 'lib/environments';
import { BREAK_POINT_L, BREAK_POINT_SM } from 'lib/breakpoints';
import { Modal } from 'components/shared';
import { Crew } from 'utils/types/crew-change-types';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { Tooltip } from 'components/shared';
import { crewLinkRequest } from 'api/flotilla';
import { prepareCrewLinkData } from '../../helpers';
import FlightTimeInput from './FlightTimeInput';
import { MissingDates } from '../../types';
import { showToaster } from 'lib/toaster';
import { ErrorText } from 'components/shared/Typography';

const StyledButton = styled(ButtonV2)`
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-right: 3px;
  background-color: ${black};
  border-color: transparent;
`;

const ActionButton = styled(ButtonV2)`
  font-size: 0.9rem;
  text-transform: uppercase;
  width: 7rem;
  display: flex;
  column-gap: 0.5rem;
`;

const FloatingButton = styled(StyledButton)`
  position: absolute;
  top: -24px;
  right: -27px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  display: flex;
  column-gap: 0.5rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 0.8rem;
`;

const Wrapper = styled.div`
  padding: 1rem 0;
  display: grid;
  grid-gap: 0.8rem;
  position: relative;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${BREAK_POINT_L}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div<{ active: boolean }>`
  max-width: 100%;
  overflow: hidden;
  box-shadow: ${gray50} 0px 1px 3px 0px, ${gray50} 0px 0px 0px 1px;
  border-radius: 4px;
  transition: all 150ms ease;
  &:hover {
    cursor: pointer;
    box-shadow: ${blue50} 0px 1px 3px 0px, ${blue50} 0px 0px 0px 1px;
    span {
      letter-spacing: 4px;
      transition: all 150ms ease;
    }
    svg {
      color: ${({ active }) => (active ? blue50 : inactivePrimary)};
      transition: all 150ms ease;
    }
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  padding: 0.8rem 1.5rem;
  padding-left: 0.5rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  align-items: center;
  h3 {
    max-width: 80%;
    font-size: 1rem;
    font-weight: initial;
    margin: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Tag = styled.span<{ type: CrewType }>`
  background-color: ${({ type }) =>
    type === 'onsigner' ? lightFadedGreen : red10};
  color: ${({ type }) => (type === 'onsigner' ? fadedGreen : red)};
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.2rem 1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const CrewLink = () => {
  const { vesselId, event } = useSelector(selectCrewChangePanel);
  const { vesselsFull } = useSelector(selectMapVessels);
  const vessel = getVesselById(vesselsFull, vesselId!);
  const {
    filters: allFilters,
    planningData: { flights, crew: crewList },
  } = useContext(CCPanelContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const isSmallerScreen = useMobile(BREAK_POINT_L);
  const [selectedCrew, setSelectedCrew] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [missingDates, setMissingDatess] = useState<MissingDates | null>(null);

  const crewWithAirportIDs = useMemo(
    () =>
      crewList
        .filter((c) =>
          flights.some((airport) => airport.crew && airport.crew.id === c.id)
        )
        .map((c) => c.id),
    [crewList, flights]
  );

  const disableCrewLink = useMemo(() => {
    const activePort = Object.values(allFilters).find(
      (item) => item.active === true
    );
    return activePort?.confirmed.every((item) => item.source === 'ATPI')
      ? false
      : true;
  }, [allFilters]);

  const handleClose = () => {
    setShowModal(false);
  };

  const handleProgressModalClose = () => {
    setShowProgressModal(false);
    setSuccess(false);
    setError('');
  };

  const handleOpen = () => {
    setShowModal(true);
  };

  const removeCrew = (id: number) => {
    setSelectedCrew(selectedCrew.filter((i) => i !== id));
  };

  const addCrew = (id: number) => {
    selectedCrew.includes(id)
      ? removeCrew(id)
      : setSelectedCrew([...selectedCrew, id]);
  };

  const addAllCrew = () => {
    if (selectedCrew.length === crewList.length) setSelectedCrew([]);
    else setSelectedCrew(crewList.map(({ id }) => id) || []);
  };

  const handleSelectFlightTime = useCallback(
    (crew: Crew) => (date: string | undefined) => {
      if (!date) return;

      const { id: crewId, type } = crew;
      const dateType = type === 'offsigner' ? 'departureDate' : 'arrivalDate';
      setMissingDatess((prev) => ({ ...prev, [crewId]: { [dateType]: date } }));
    },
    []
  );

  const onSubmit = useCallback(async () => {
    handleClose();
    setIsLoading(true);
    setShowProgressModal(true);
    const selectedFlights = flights.filter((flight) =>
      selectedCrew.includes(flight.crew.id)
    );
    let selectedCrewMembers = crewList.filter((c) =>
      selectedCrew.includes(c.id)
    );
    if (!isEmpty(event)) {
      const eventCrew = event.crew;
      selectedCrewMembers = selectedCrewMembers.map((c) => {
        const t = eventCrew.find((crew) => crew.cid === c.cid);
        if (t)
          return {
            firstName: t.firstName,
            middleName: t.middleName,
            lastName: t.lastName,
            ...c,
          };
        return c;
      });
    }
    const port = Object.keys(allFilters).find((key) => allFilters[key].active);
    const requestData = prepareCrewLinkData({
      event: allFilters[port!],
      vessel: vessel!,
      flights: selectedFlights,
      crew: selectedCrewMembers,
      missingDates,
    });
    // log crew-link request in development
    if (isDevelopment) {
      console.log('CrewLink Request Data', {
        filters: allFilters[port!],
        requestData,
      });
    }
    const { success, message } = await crewLinkRequest(requestData);
    setIsLoading(false);
    success ? setSuccess(true) : setError(message);
    showToaster({ message, type: success ? 'success' : 'error' });
  }, [
    flights,
    missingDates,
    allFilters,
    vessel,
    crewList,
    selectedCrew,
    event,
  ]);

  const actions = (
    <ButtonsWrapper>
      <ActionButton variant="danger" onClick={handleClose}>
        Cancel
      </ActionButton>
      <ActionButton
        style={{ justifyContent: 'flex-end' }}
        onClick={onSubmit}
        loading={isLoading}
        disabled={!Boolean(selectedCrew.length)}
      >
        Send
        <SendIcon
          color="inherit"
          sx={{ margin: '0 0.25rem', fontSize: '15px' }}
        />
      </ActionButton>
    </ButtonsWrapper>
  );

  const progressModalActions = (
    <ButtonsWrapper>
      <ActionButton variant="danger" onClick={handleProgressModalClose}>
        Close
      </ActionButton>
    </ButtonsWrapper>
  );

  return (
    <>
      <Tooltip content="All crew flights must be sourced from ATPI">
        <StyledButton onClick={handleOpen} disabled={disableCrewLink}>
          Crew Link
          <LinkIcon
            fontSize="small"
            color="inherit"
            sx={{ marginLeft: '0.5rem' }}
          />
        </StyledButton>
      </Tooltip>

      {showModal && (
        <Modal
          width={isSmallerScreen ? '80vw' : 1080}
          title="Select Crew"
          closeModal={handleClose}
          actions={actions}
        >
          <Wrapper>
            <FloatingButton onClick={addAllCrew}>
              {selectedCrew.length ? (
                <CheckBoxIcon fontSize="small" />
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="small" />
              )}
              Select All
            </FloatingButton>
            {crewList.map((crew) => {
              const { id, name, type } = crew;
              const { departure, arrival } =
                find(flights, ['crew.id', id]) || {};
              return (
                <Card
                  key={id}
                  active={selectedCrew.includes(id)}
                  onClick={() => addCrew(id)}
                >
                  <ContentWrapper>
                    <Item>
                      <CheckCircleIcon
                        fontSize="medium"
                        sx={{
                          color: selectedCrew.includes(id) ? blue50 : gray20,
                        }}
                      />
                      {!crewWithAirportIDs.includes(id) ? (
                        <AirplanemodeInactiveIcon
                          fontSize="small"
                          sx={{ color: gray50 }}
                        />
                      ) : null}
                    </Item>
                    <Item>
                      <h3>{name}</h3>
                      <Tag type={type}>{type}</Tag>
                    </Item>
                  </ContentWrapper>

                  <FlightTimeInput
                    crewType={type}
                    flightTime={
                      type === 'offsigner' ? departure?.time : arrival?.time
                    }
                    onSelect={handleSelectFlightTime(crew)}
                  />
                </Card>
              );
            })}
          </Wrapper>
        </Modal>
      )}
      {showProgressModal && (
        <Modal center width={500} actions={progressModalActions}>
          <Animation
            name={
              isLoading
                ? '97930-loading'
                : (success && '68994-success') || '90569-error'
            }
          />
          <ErrorText $fontSize="1rem">{error}</ErrorText>
        </Modal>
      )}
    </>
  );
};

export default CrewLink;
