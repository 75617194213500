import flatten from 'lodash/flatten';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import partialRight from 'lodash/partialRight';
import some from 'lodash/some';
import moment from 'moment';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { getStartOfDate } from 'lib/common';
import { fetchCrewScheduleEvents } from 'api/crew-matrix';

import { getEventOnsigners } from './common';
import {
  CrewChangeEventDetailed,
  CrewBlockoffDates,
  BlockoffDateType,
  OnsignerBasic,
} from '../types';

// get the min & max dates allowed blockoff date inputs
export const getBlockoffInputDateRange =
  (blockOffDates: CrewBlockoffDates) => (type: BlockoffDateType) => {
    let defaultRange = { minDate: undefined, maxDate: undefined };
    const {
      onsignBlockoffStartDate: onsignDate,
      onboardBlockoffStartDate: startDate,
      onboardBlockoffEndDate: endDate,
    } = blockOffDates;

    switch (type) {
      case 'onsignBlockoffStartDate':
        return {
          minDate: new Date(),
          maxDate: startDate ? new Date(startDate) : undefined,
        };
      case 'onboardBlockoffStartDate':
        return {
          minDate: onsignDate ? new Date(onsignDate) : undefined,
          maxDate: endDate ? new Date(endDate) : undefined,
        };
      case 'onboardBlockoffEndDate':
        return {
          ...defaultRange,
          minDate: startDate ? new Date(startDate) : undefined,
        };
      default:
        return defaultRange;
    }
  };

// find if an event date is within the blockoff date range
export const isEventWithinBlockoffRange =
  (type?: CrewType) =>
  (
    eventDate: string | null | undefined,
    blockoffPeriods: CrewBlockoffDates[]
  ) =>
    Boolean(eventDate) &&
    some(
      blockoffPeriods,
      ({
        onsignBlockoffStartDate,
        onboardBlockoffStartDate,
        onboardBlockoffEndDate,
      }) =>
        moment(eventDate).isBetween(
          // fallback to `onboardBlockoffStartDate` if NO `type` provided
          !type || type === CrewType.offsigner
            ? onboardBlockoffStartDate
            : onsignBlockoffStartDate,
          onboardBlockoffEndDate,
          'day',
          '[]'
        )
    );

// default blockoff sign-on date, if not provided
// to current date or six months prior to start date, whichever is the later
export const getDefaultBlockoffSignOnDate = (startDate: string) => {
  const today = moment().toISOString();
  const sixMonthsBeforeStart = moment(startDate)
    .subtract(6, 'months')
    .toISOString();
  const defaultDate = moment(today).isAfter(sixMonthsBeforeStart)
    ? today
    : sixMonthsBeforeStart;
  // set the time to 00:00:00 of the `defaultDate`
  return getStartOfDate(defaultDate);
};

// get the list of events that overlap with the new block-off period
export const getOverlappingEvents = async (
  crewId: string,
  blockoffPeriod: CrewBlockoffDates
) => {
  const { success, result = [] } = await fetchCrewScheduleEvents(crewId);

  if (!success || !result.length) {
    return [];
  }

  const isOverlappingEvent = partialRight(isEventWithinBlockoffRange(), [
    blockoffPeriod,
  ]);
  // filter out events that are NOT overlapping with the blockoff period
  return result.filter(({ eventDate }) => isOverlappingEvent(eventDate));
};

// find if the crew on which  block-off APi is called is from current event
export const shouldRefetchEvent = (
  event: CrewChangeEventDetailed | null,
  selectedCrew: OnsignerBasic | null
) => {
  if (!event || !selectedCrew) {
    return false;
  }

  const { offsigners } = event;
  const selectedOnsigners = getEventOnsigners(event);
  const alternativeOnsigners = flatten(map(offsigners, 'alternativeOnsigners'));
  return some(
    uniqBy(
      [
        ...event.onboard.crew,
        ...offsigners,
        ...selectedOnsigners,
        ...alternativeOnsigners,
      ],
      'externalCrewId'
    ),
    ['externalCrewId', selectedCrew.externalCrewId]
  );
};
