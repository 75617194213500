import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { addTagToVessels, removeTagFromVessels } from 'api/flotilla';
import { updateAddRemoveVesselTag } from 'redux/actions';
import { AppDispatch } from 'redux/types';
import { vesselHighlights } from 'utils/color-utils';
import { VesselTag, VesselTagWithCount } from 'utils/types';
import { VesselTagCreateUpdate } from './types';

export function getNewHashColour(displayName: string) {
  const colorArray = vesselHighlights.qualitative;

  // hash function
  const sum = String(displayName)
    .split('')
    .reduce((total: number, char: string, index: number) => {
      return total + String(displayName).charCodeAt(index);
    }, 0);
  const hash = [
    String(sum).substring(String(sum).length - 2, String(sum).length),
    String(sum).substring(String(sum).length - 1, String(sum).length),
  ];
  if (colorArray[Number(hash[0])]) {
    return colorArray[Number(hash[0])];
  }
  return colorArray[Number(hash[1])] || '#000000';
}

const vesselTagCreateUpdateFilter =
  createFilterOptions<VesselTagCreateUpdate>();

export const filterOptions = (
  options: VesselTagCreateUpdate[],
  params: FilterOptionsState<VesselTagCreateUpdate>
) => {
  const filtered = vesselTagCreateUpdateFilter(options, params);
  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some(
    (option) => inputValue === option.displayName
  );
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      id: -1,
      value: null,
      isNew: true,
      colour: '#000',
      displayName: inputValue,
      label: `Add "${inputValue}"`,
      count: 1,
    });
  }

  return filtered;
};

export function transformRawTags(tags: VesselTag[] | VesselTagWithCount[]) {
  return tags.map((o) => ({
    ...o,
    value: o.id,
    label: o.displayName,
    isNew: false,
    count: 'count' in o ? o.count : 1,
  }));
}

export async function addVesselTag({
  dispatch,
  requestBody,
}: {
  dispatch: AppDispatch;
  requestBody: { flotVesIds: number[]; displayName: string; colour: string };
}): Promise<VesselTag | null> {
  const response = await addTagToVessels(requestBody);
  if (response.success && response.tag) {
    dispatch(
      updateAddRemoveVesselTag({
        flotVesIds: requestBody.flotVesIds,
        tag: response.tag,
        action: 'add',
      })
    );
    return response.tag;
  }
  return null;
}

export async function removeVesselTag({
  dispatch,
  requestBody,
}: {
  dispatch: AppDispatch;
  requestBody: {
    flotVesIds: number[];
    tag: { id: number; displayName: string; colour: string };
  };
}): Promise<boolean> {
  const response = await removeTagFromVessels({
    flotVesIds: requestBody.flotVesIds,
    tagId: requestBody.tag.id,
  });
  if (response.success) {
    dispatch(
      updateAddRemoveVesselTag({
        flotVesIds: requestBody.flotVesIds,
        tag: requestBody.tag,
        action: 'remove',
      })
    );
    return true;
  }
  return false;
}
