import { useMemo } from 'react';
import { Stack, Radio, FormControlLabel } from '@mui/material';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import {
  blue,
  fadedGreen,
  lightFadedGreen,
  lightPurple,
  green,
  textBlack,
  gray60,
  gray70,
  gray80,
  iconGray,
} from 'lib/colors';
import { formatHour } from 'lib/common';
import {
  BREAK_POINT_SM,
  BREAK_POINT_XS,
  BREAK_POINT_XXS,
} from 'lib/breakpoints';
import { updateFlightSearch } from 'redux/actions';
import { FlightNumberDetails } from 'redux/types';

import {
  Dropdown,
  AirlineSelection,
  LayoverSelection,
  SwitchV2,
  Tooltip,
} from 'components/shared';
import AwesomeSlider, { Thumb } from 'components/shared/AwesomeSlider';
import { Label, StyledButton } from 'components/CrewChangePanel/common';
import {
  MAX_LAYOVER_TIME,
  MAX_FLIGHT_TIME,
  MAX_STOPS_COUNT,
  getAllFlightSources,
} from 'components/CrewChangePanel/helpers';
import {
  Airline,
  FareType,
  FlightFilters,
  FlightFilterType,
  LayoverAirport,
} from 'components/CrewChangePanel/types';
import { CommonFilterProps } from '../types';
import { getUserActionModalType, TrackUtils } from '../helpers';
import InfoIcon from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FiltersWrapper = styled.div<{ $disabled: boolean }>`
  align-self: strech;
  padding: 0.5rem;
  padding-bottom: 0.75rem;
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const Row = styled.div<{ $spaced?: boolean }>`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 3.25fr;
  grid-gap: 0;
  ${({ $spaced }) => $spaced && 'margin-bottom: 0.75rem'};
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: 1fr 3.5fr;
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    grid-template-columns: 1fr 4fr;
  }
`;

const ContentWrapper = styled(FlexWrapper)`
  justify-content: flex-start;
  width: 100%;
  flex-shrink: 0;
`;

const StyledLabel = styled(Label)`
  color: ${textBlack};
  margin-right: 1rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-right: 0.5rem;
  }
`;

const ActionButton = styled(StyledButton)<{
  $active?: boolean;
  $green: boolean;
}>`
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  min-height: unset;
  min-width: unset;
  margin-right: 8px !important;
  padding: 0.25rem 1rem;
  flex-shrink: 0;

  ${({ $active, $green }) =>
    !$active &&
    ($green
      ? `
      background-color: ${lightFadedGreen};
      color: ${green};
      `
      : `
      background-color: ${lightPurple};
      color: ${blue};
      `)};
  ${({ $active, $green }) =>
    $active &&
    $green &&
    `
      background-color: ${fadedGreen};
      color: #fff;
  `};

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    font-size: 0.65rem;
    padding: 0.33rem;
    margin-right: 6px !important;
  }

  @media screen and (max-width: ${BREAK_POINT_XXS}) {
    font-size: 0.65rem;
    padding: 0.25rem;
    margin-right: 6px !important;
  }
`;

const StyledSliderWrapper = styled.div`
  flex-shrink: 0;
  margin-left: 1rem;
  margin-right: 1.6rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    .MuiSlider-root {
      padding: 1rem 0;
    }
  }
`;

const RadioWrapper = styled(ContentWrapper)`
  margin-left: 4px;

  .MuiSvgIcon-root {
    height: 1.25rem;
    width: 1.25rem;
  }
  .MuiTypography-root {
    text-transform: uppercase;
    letter-spacing: 0.075rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: ${gray70};

    @media screen and (max-width: ${BREAK_POINT_XXS}) {
      font-size: 0.65rem;
      letter-spacing: 0.033rem;
    }
  }
`;

const ToggleWrapper = styled(FlexWrapper)`
  margin: 0.5rem 0;
  column-gap: 0.5rem;

  ${Label} {
    font-style: italic;
    font-weight: 500;
    color: ${gray60};
  }
`;

const FixedSource = styled.div`
  color: ${gray80};
  font-size: 0.9rem;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${iconGray};
  margin-left: 0.33rem;
  svg {
    font-size: 18px !important;
  }
`;

const dropdownStyles = {
  maxWidth: '100px',
  marginLeft: '8px',
  marginRight: '10px',
};

type Props = CommonFilterProps & {
  searchOptions: FlightNumberDetails;
};

const FILTERS: FlightFilterType[] = ['Lowest CO2', 'Fastest', 'Cheapest'];

const ThumbHour = (allProps: any) => {
  const { layover, range, ...props } = allProps;
  if (range) {
    const { value } = props.ownerState;
    const hourValue = props['data-index'] ? value[1] : value[0];
    const formattedHour = layover
      ? `${hourValue}H`
      : (hourValue === 0 && '12AM') ||
        (hourValue === 24 && '11:59PM') ||
        formatHour(hourValue);
    return <Thumb {...props} modifiedValue={formattedHour} />;
  }
  const modifiedValue = `${props.ownerState.value}H`;
  return <Thumb {...props} modifiedValue={modifiedValue} />;
};

function SearchFilters({
  modalView,
  flights,
  filters,
  searchOptions,
  updateFilter,
}: Props) {
  const dispatch = useAppDispatch();
  const {
    type,
    arrivalTime,
    departureTime,
    range,
    stopsCount,
    selectedStops,
    airlines,
    time: flightTime,
    layover: layoverTime,
    source: flightSource,
    fareType,
    allowAirportTransfer,
  } = filters;

  const allFiltersDisabled = useMemo(() => {
    const { included, flightNumber } = searchOptions;
    return Boolean(flightNumber) && !included;
  }, [searchOptions]);
  const sources = useMemo(() => getAllFlightSources(flights), [flights]);
  const modalType = useMemo(
    () => getUserActionModalType(modalView),
    [modalView]
  );
  const trackUtils = useMemo(() => TrackUtils(modalType), [modalType]);

  // setting the max values to current filter values if less than
  // otherwise, fallback to some default values
  const { min, max } = range;
  const maxFlightTime =
    (max?.flightTime &&
      (max.flightTime <= flightTime ? flightTime : max.flightTime)) ??
    MAX_FLIGHT_TIME;
  const maxLayoverTime =
    (max?.layoverTime &&
      (max.layoverTime <= layoverTime[1] ? layoverTime[1] : max.layoverTime)) ??
    MAX_LAYOVER_TIME;
  const maxStopsCount =
    (max?.stopsCount &&
      (max.stopsCount <= stopsCount ? stopsCount : max.stopsCount)) ??
    MAX_STOPS_COUNT;
  const testId = `e2e_search-filters-${
    modalView ? `${modalView}-modal` : 'searchbar'
  }`;

  return (
    <FiltersWrapper data-id={testId} $disabled={allFiltersDisabled}>
      <Row $spaced>
        <StyledLabel>Category:</StyledLabel>
        <ContentWrapper>
          {FILTERS.map((text) => (
            <ActionButton
              key={text}
              variant="primary"
              $active={type === text}
              $green={text === 'Lowest CO2'}
              onClick={() => {
                trackUtils.trackCategory(text);
                updateFilter({ type: text });
              }}
            >
              {text}
            </ActionButton>
          ))}
        </ContentWrapper>
      </Row>

      <Row>
        <StyledLabel>Filters:</StyledLabel>
        <ContentWrapper>
          {Boolean(stopsCount && selectedStops.length) && (
            <LayoverSelection
              layovers={selectedStops}
              updateFilters={(
                selectedStops: LayoverAirport[],
                type?: 'Select All'
              ) => {
                trackUtils.trackFlightLayovers(selectedStops, type);
                updateFilter({ selectedStops });
              }}
            />
          )}
          {Boolean(airlines.length) && (
            <AirlineSelection
              airlines={airlines}
              updateFilters={(airlines: Airline[], type?: 'Select All') => {
                trackUtils.trackFlightAirlines(airlines, type);
                updateFilter({ airlines });
              }}
            />
          )}
        </ContentWrapper>
      </Row>

      {arrivalTime && (
        <Row>
          <StyledLabel>Arrival:</StyledLabel>
          <StyledSliderWrapper>
            <Stack direction="row" alignItems="center">
              <AwesomeSlider
                size="small"
                data-id="e2e_quickfly-arrival"
                min={0}
                max={24}
                step={1}
                value={arrivalTime}
                onChange={(_, newValue) => {
                  trackUtils.trackArrival(newValue as [number, number]);
                  updateFilter({
                    arrivalTime: newValue as [number, number],
                  });
                }}
                components={{
                  Thumb: (props: any) => <ThumbHour range {...props} />,
                }}
              />
            </Stack>
          </StyledSliderWrapper>
        </Row>
      )}

      {departureTime && (
        <Row>
          <StyledLabel>Departure:</StyledLabel>
          <StyledSliderWrapper>
            <Stack direction="row" alignItems="center">
              <AwesomeSlider
                noMargin
                size="small"
                data-id="e2e_quickfly-departure"
                min={0}
                max={24}
                step={1}
                value={departureTime}
                onChange={(_, newValue) => {
                  trackUtils.trackDeparture(newValue as [number, number]);
                  updateFilter({
                    departureTime: newValue as [number, number],
                  });
                }}
                components={{
                  Thumb: (props: any) => <ThumbHour range {...props} />,
                }}
              />
            </Stack>
          </StyledSliderWrapper>
        </Row>
      )}

      <Row>
        <StyledLabel>Flight Time:</StyledLabel>
        <StyledSliderWrapper>
          <Stack direction="row" alignItems="center">
            <AwesomeSlider
              noMargin
              size="small"
              data-id="e2e_quickfly-flight-time"
              step={1}
              min={min.flightTime}
              max={maxFlightTime}
              disabled={maxFlightTime === min.flightTime}
              value={flightTime}
              onChange={(_, newValue) => {
                trackUtils.trackFlightTime(newValue as number);
                updateFilter({ time: newValue as number });
              }}
              components={{ Thumb: ThumbHour }}
            />
          </Stack>
        </StyledSliderWrapper>
      </Row>

      <Row>
        <StyledLabel>Total Layover:</StyledLabel>
        <StyledSliderWrapper>
          <Stack direction="row" alignItems="center">
            <AwesomeSlider
              noMargin
              size="small"
              data-id="e2e_quickfly-layover"
              min={min.layoverTime}
              max={maxLayoverTime}
              step={0.5}
              value={layoverTime}
              onChange={(_, newValue) => {
                trackUtils.trackLayover(newValue as [number, number]);
                updateFilter({ layover: newValue as [number, number] });
              }}
              components={{
                Thumb: (props: any) => <ThumbHour range layover {...props} />,
              }}
            />
          </Stack>
        </StyledSliderWrapper>
      </Row>

      <Row>
        <StyledLabel>Stops No.:</StyledLabel>
        <StyledSliderWrapper>
          <Stack direction="row" alignItems="center">
            <AwesomeSlider
              noMargin
              size="small"
              data-id="e2e_quickfly-stops"
              step={1}
              min={min.stopsCount}
              max={maxStopsCount}
              disabled={maxStopsCount === min.stopsCount}
              value={stopsCount}
              onChange={(_, value) => {
                trackUtils.trackStopsCount(value as number);
                updateFilter({ stopsCount: value as number });
              }}
              components={{ Thumb }}
            />
          </Stack>
        </StyledSliderWrapper>
      </Row>

      <Row>
        <StyledLabel>Transfer between Airports:</StyledLabel>
        <ToggleWrapper>
          <SwitchV2
            testId="e2e_quickfly-airport-transfer"
            checked={allowAirportTransfer}
            onChange={(_, newValue) => {
              trackUtils.toggleAirportTransfer(newValue);
              updateFilter({ allowAirportTransfer: newValue });
            }}
          />
          <Label>{allowAirportTransfer ? 'Allowed' : 'Denied'}</Label>
          <Tooltip content="If switched ON, flights with airport transfer will be included">
            <StyledIcon>
              <InfoIcon />
            </StyledIcon>
          </Tooltip>
        </ToggleWrapper>
      </Row>

      <Row>
        <StyledLabel>Fare Type:</StyledLabel>
        <RadioWrapper>
          <FormControlLabel
            label="Marine"
            onClick={() => {
              trackUtils.trackMarine(true);
              updateFilter({ fareType: FareType.marine });
            }}
            control={<Radio checked={fareType === FareType.marine} />}
          />
          <FormControlLabel
            label="General"
            onClick={() => {
              trackUtils.trackMarine(false);
              updateFilter({ fareType: FareType.general });
            }}
            control={<Radio checked={fareType === FareType.general} />}
          />
          <FormControlLabel
            label="All"
            onClick={() => {
              trackUtils.trackMarine(false);
              updateFilter({ fareType: FareType.all });
            }}
            control={
              <Radio
                data-id="e2e_show-all"
                checked={fareType === FareType.all}
              />
            }
          />
        </RadioWrapper>
      </Row>

      <Row>
        <StyledLabel>Source:</StyledLabel>
        {(filters as FlightFilters).duplicate ? (
          <FixedSource>{flightSource}</FixedSource>
        ) : (
          <Dropdown
            value={flightSource}
            options={sources}
            formStyles={dropdownStyles}
            fontSize="0.8rem"
            onChange={({ target }) => {
              trackUtils.trackSource(target.value, sources);
              updateFilter({ source: target.value });
            }}
            // state update when popup is open to prevent closing search results
            handler={(listOpen) =>
              !modalView && dispatch(updateFlightSearch({ listOpen }))
            }
          />
        )}
      </Row>
    </FiltersWrapper>
  );
}

export default SearchFilters;
