import { memo } from 'react';
import { TextFieldProps } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import {
  useController,
  Control,
  FieldValues,
  FieldPathByValue,
} from 'react-hook-form';

function PhoneInputV2<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  ...props
}: TextFieldProps & {
  name: TPath;
  control: Control<TFieldValues>;
}) {
  const {
    field,
    fieldState: { invalid, error, isTouched },
  } = useController({
    name,
    control,
  });

  return (
    <MuiTelInput
      {...props}
      {...field}
      value={field.value}
      error={isTouched && invalid}
      helperText={isTouched && error?.message}
    />
  );
}

export default memo(PhoneInputV2);
