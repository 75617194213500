import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';

import { APP_VERSION } from 'utils/constants';
import { getEnvironment, isProduction } from 'lib/environments';
import { RootState } from 'redux/types';

const DSN = process.env.REACT_APP_SENTRY_DSN;
const SHOULD_MASK = !(process.env.REACT_APP_SENTRY_MASK === 'false');
const ENABLE_REPLAY = process.env.REACT_APP_SENTRY_REPLAY === 'true';

function getProjectId(value: any) {
  if (!value) return null;
  const split = String(value).split('/');
  const lastWord = split[split.length - 1];

  return isNaN(Number(lastWord)) ? null : parseInt(lastWord);
}

function useSentry() {
  const isAuthed = useSelector(({ authed }: RootState) => authed.isAuthed);
  const userInfo = useSelector(({ settings }: RootState) => settings.userInfo);
  // prevent multiple replay sessions from starting when Sentry re-initializes
  const initiatedSentry = useRef<boolean>(false);

  useEffect(() => {
    const skipSentry = process.env.REACT_APP_LOCAL_DEV === 'true';

    const projectId = getProjectId(DSN);

    if (!skipSentry && DSN && projectId && !initiatedSentry.current) {
      const integrations: Sentry.BrowserOptions['integrations'] = [
        new Sentry.BrowserTracing(),
      ];
      if (ENABLE_REPLAY) {
        integrations.push(
          new Sentry.Replay({
            maskAllText: SHOULD_MASK,
            blockAllMedia: SHOULD_MASK,
          })
        );
      }
      Sentry.init({
        dsn: DSN,
        environment: getEnvironment(),
        integrations,
        replaysSessionSampleRate: isProduction ? 0.1 : 1,
        replaysOnErrorSampleRate: 1.0,
        tracesSampleRate: 0.25,
        tunnel: `${getAPIUrl()}/sentry?projectId=${projectId}`,
        release: `greywing-frontend@${APP_VERSION}`,
        normalizeDepth: 4,
      });
      initiatedSentry.current = true;
    }
    if (!skipSentry && DSN && projectId && initiatedSentry.current) {
      if (isAuthed && userInfo) {
        Sentry.setUser({
          email: userInfo.loginEmail || '',
          company: userInfo.company?.name || '',
        });
        console.log('Sentry Initialized with User');
      } else {
        Sentry.setUser(null);
        console.log('Sentry Initialized');
      }
    }
  }, [isAuthed, userInfo]);

  return null;
}

export default useSentry;
