import {
  memo,
  useMemo,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import {
  useAppDispatch,
  useCrewChangeCosts,
  useE2ETests,
  useMobile,
} from 'hooks';
import {
  borderGray,
  blue,
  fadedGreen,
  green,
  orange,
  white,
  red,
  red50,
  yellow50,
  midBlue,
} from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { formatDate } from 'utils/format-date';
import { updateCrewChangeReport } from 'redux/actions';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Tooltip } from 'components/shared';
import { CustomCheckbox } from 'components/Settings/common';
import { getColumnVisibility, getGridColumns } from './Header';
import ReadOnlyMenu from '../Actions/Flights/Filters/ReadOnlyMenu';
import { NoRowsOverlay } from '../../common/TableItems';
import ReadOnlyControls from '../../common/Controls/ReadOnly';
import {
  headerStyles,
  ReadOnlyRow,
  ReadOnlyLabel,
  ReadOnlyText,
  TableWrapper,
  buttonStyles,
} from '../../common';
import {
  formatPortNearbyAirport,
  formatToReadOnlyFlight,
  getBudgetDetailsForPort,
  getFlightRowClassName,
  getLocodeKeyDetails,
  getLocodeKeyFromPortData,
  getReadOnlyFlightRows,
  getReadOnlyPortCards,
  getUnneededFlightsCount,
  hasFareInfo,
} from '../../helpers';
import {
  BudgetStatus,
  DuplicateReadOnlyPort,
  FlightFilters,
  OpenReadOnlyFilter,
  ReadOnlyFlight,
  SelectInModal,
} from '../../types';

const StyledTableWrapper = styled(TableWrapper)`
  /* custom styling for additional cost cell */
  .delay {
    background: ${fadedGreen}40;
  }
  .medium-delay {
    background: ${orange};
    color: ${white};
  }
  .long-delay {
    background: ${red};
    color: ${white};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const Badge = styled.div`
  ${buttonStyles};
  font-size: 0.6rem;
  background: ${midBlue};
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
`;

const Wrapper = styled(FlexWrapper)`
  flex-shrink: 0;
  overflow-x: auto;
  width: 100% !important;
`;

const CardWrapper = styled.div<{
  $selected: boolean;
  $budgetStatus?: BudgetStatus;
}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: fit-content;
  flex-shrink: 0;
  margin: 0.25rem 0.5rem;
  min-height: 75px;
  height: 100%;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  padding: 0.4rem 0.7rem;
  flex-shrink: 0;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${({ $budgetStatus }) =>
    ($budgetStatus === BudgetStatus.ALL_OVER && `${red50}20`) ||
    ($budgetStatus === BudgetStatus.SOME_OVER && `${yellow50}20`) ||
    ($budgetStatus === BudgetStatus.ALL_UNDER && `${green}20`)};
  ${({ $selected }) => $selected && `border: 1.5px solid ${blue}`};
  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transform: translate(-1px, -1px);
  }
`;

const InfoWrapper = styled.div<{ $selected: boolean; $left?: boolean }>`
  ${({ $left, $selected }) =>
    $left && $selected && `border-left: 1px solid ${borderGray}`};
  ${({ $left, $selected }) =>
    $selected && ($left ? 'padding-left: 1.5rem' : 'padding-right: 1.5rem')};
  width: fit-content;
`;

const Title = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

const ToggleWrapper = styled(FlexWrapper)`
  margin-left: 1.25rem;
  column-gap: 0.5rem;
  flex-shrink: 0;
`;

type SelectPortCard = (
  portLocode: string,
  source?: string,
  uniqETA?: string
) => void;

type SummaryProps = {
  testId: string;
  port: DuplicateReadOnlyPort;
  isSelected: boolean;
  hideBadge: boolean;
  infoText: string;
  selectPortCard: SelectPortCard;
  budgetStatus: BudgetStatus | undefined;
};

const hasFlightFetchedDate = (flights: ReadOnlyFlight[] = []) =>
  Boolean(flights[0]?.fetchedAt);

const SummaryCard = ({
  testId,
  port,
  isSelected,
  hideBadge,
  infoText,
  budgetStatus,
  selectPortCard,
}: SummaryProps) => {
  const {
    locode: portLocode,
    name,
    flightSource,
    selectedAirport,
    uniqETA,
  } = port;
  const { totalCost } = useCrewChangeCosts(port);
  const basePortName = `${name} ${portLocode.toUpperCase()}`;
  const portName = uniqETA
    ? `${basePortName} (${formatDate(uniqETA, 'DD MMM, YY')})`
    : basePortName;

  return (
    <CardWrapper
      data-id={testId}
      $selected={isSelected}
      $budgetStatus={budgetStatus}
      onClick={() => selectPortCard(portLocode, flightSource, uniqETA)}
    >
      <InfoWrapper $selected={isSelected}>
        <TitleWrapper>
          <Title>
            <span>{portName}</span>
            {flightSource && <span> - {flightSource}</span>}
          </Title>
          {!hideBadge && port.preferred && (
            <Tooltip content="Preferred port for crew-change">
              <Badge>Preferred</Badge>
            </Tooltip>
          )}
        </TitleWrapper>
        <ReadOnlyRow>
          <ReadOnlyLabel>Airport:</ReadOnlyLabel>
          <ReadOnlyText>
            {(selectedAirport && formatPortNearbyAirport(selectedAirport)) ||
              '---'}
          </ReadOnlyText>
        </ReadOnlyRow>
        <ReadOnlyRow>
          <ReadOnlyLabel>Total Cost:</ReadOnlyLabel>
          <ReadOnlyText>{totalCost || '---'}</ReadOnlyText>
        </ReadOnlyRow>
        <ReadOnlyRow>
          <ReadOnlyLabel>{infoText}</ReadOnlyLabel>
        </ReadOnlyRow>
      </InfoWrapper>
    </CardWrapper>
  );
};

function ReadOnlyFlightsTable() {
  const dispatch = useAppDispatch();
  const reportData = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.readOnlyPlanningData
  );
  const { compact: isCompact } = useSelector(
    ({ settings }: RootState) => settings.crewChange
  );
  const vesselBudgets = useSelector(
    ({ crewChangeResources }: RootState) =>
      crewChangeResources.responses.vesselBudgets
  );

  const {
    tableState: {
      readOnlyDetails,
      budgetDetails,
      readOnlyDetails: { locode: readOnlyLocode },
    },
    updateTableState,
  } = useContext(CCPanelContext);

  const {
    crew = [],
    ports = [],
    flights = {},
  } = reportData?.crewChangePlan || {};

  const isMobile = useMobile(BREAK_POINT_XS);
  const { isE2ETesting } = useE2ETests();
  const [activeLocodeKey, setActiveLocodeKey] = useState(readOnlyLocode);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<ReadOnlyFlight | null>(null);
  const [currentFlights, setCurrentFlights] = useState<ReadOnlyFlight[]>([]);
  const [flightUpdateEnabled, setFlightUpdateEnabled] = useState(false);

  const updateLocode = (
    locode: string,
    flightSource?: string,
    uniqETA?: string
  ) => {
    updateTableState({
      type: 'readOnlyDetails',
      payload: { locode, flightSource, uniqETA },
    });
    const baseLocode = uniqETA ? `${locode}(${uniqETA})` : locode;
    const locodeKey = flightSource
      ? `${baseLocode}--${flightSource}`
      : baseLocode;
    setActiveLocodeKey(locodeKey);
  };

  const openFilterMenu: OpenReadOnlyFilter = (flight) => (event) => {
    setAnchorEl(event.target as HTMLElement);
    setSelected(flight);
  };

  const handleSelectInModal: SelectInModal = useCallback(
    (newFlight, crewId, filters) => {
      const updatedFlights = currentFlights.map((flight) =>
        flight.crew.id === crewId
          ? formatToReadOnlyFlight(flight, newFlight, filters as FlightFilters)
          : flight
      );
      setCurrentFlights(updatedFlights);
      updateTableState({
        type: 'readOnlyDetails',
        payload: { ...readOnlyDetails, saveable: true },
      });

      dispatch(
        updateCrewChangeReport({
          locodeKey: activeLocodeKey,
          flights: updatedFlights,
        })
      );
    },
    [
      activeLocodeKey,
      currentFlights,
      readOnlyDetails,
      dispatch,
      updateTableState,
    ]
  );

  useEffect(() => {
    const { locode, flightSource, portETA } = getLocodeKeyDetails(
      Object.keys(flights)[0]
    );
    updateLocode(locode, flightSource, portETA);
  }, []); // eslint-disable-line

  // update flights with locode change
  useEffect(() => {
    setCurrentFlights(flights[activeLocodeKey] || []);
  }, [flights, activeLocodeKey]);

  useEffect(() => {
    // set budget for selected port card
    updateTableState({
      type: 'budgetDetails',
      payload: getBudgetDetailsForPort(flights[activeLocodeKey], vesselBudgets),
    });
  }, [vesselBudgets, activeLocodeKey, flights, updateTableState]);

  const activePort = useMemo(
    () => ports.find((port) => port.locode === readOnlyLocode),
    [ports, readOnlyLocode]
  );
  const columns = useMemo(
    () =>
      getGridColumns({
        commonProps: {
          hasFareInfo: hasFareInfo(currentFlights),
        },
        readOnlyProps: {
          port: activePort,
          hasFetchedAt: hasFlightFetchedDate(currentFlights),
        },
        funcProps: { openFilterMenu, selectInModal: handleSelectInModal },
      }),
    [activePort, currentFlights, handleSelectInModal]
  );
  const flightRows = useMemo(() => {
    const portAirportCode = activePort?.selectedAirport?.iataCode || '';
    const onlyUpdating = isE2ETesting && flightUpdateEnabled;
    return getReadOnlyFlightRows(
      crew,
      currentFlights,
      portAirportCode,
      onlyUpdating
    );
  }, [crew, currentFlights, activePort, isE2ETesting, flightUpdateEnabled]);
  const readOnlyPortCards = useMemo(
    () => getReadOnlyPortCards(ports, flights),
    [ports, flights]
  );

  return (
    <>
      {reportData && (
        <ReadOnlyControls>
          <Wrapper>
            {readOnlyPortCards.map((port, index) => {
              const currentLocodeKey = getLocodeKeyFromPortData(port);
              const totalCrewCount =
                (flights[currentLocodeKey] || []).length +
                getUnneededFlightsCount(crew, port);
              const infoText = `${totalCrewCount}/${crew.length} crew have flights`;
              const testId = `e2e_read-only-port-card-${index + 1}`;
              return (
                <SummaryCard
                  key={`${port.id}-${index}`}
                  budgetStatus={budgetDetails?.status}
                  testId={testId}
                  port={port}
                  infoText={infoText}
                  hideBadge={readOnlyPortCards.length <= 1}
                  selectPortCard={updateLocode}
                  isSelected={currentLocodeKey === activeLocodeKey}
                />
              );
            })}
            {isE2ETesting && (
              <ToggleWrapper>
                <CustomCheckbox
                  data-id="e2e_toggle-editable-flights"
                  label="Show Editable Flights Only"
                  isCircle={false}
                  checked={flightUpdateEnabled}
                  onClick={() => setFlightUpdateEnabled((prev) => !prev)}
                />
              </ToggleWrapper>
            )}
          </Wrapper>
        </ReadOnlyControls>
      )}

      <StyledTableWrapper>
        <DataGridPro
          // TODO: remove this ignore
          // @ts-ignore
          rows={flightRows}
          columns={columns}
          columnVisibilityModel={getColumnVisibility(isMobile)}
          checkboxSelection={false}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          hideFooter
          sx={headerStyles}
          density={isCompact ? 'compact' : 'standard'}
          components={{ NoRowsOverlay }}
          getRowClassName={getFlightRowClassName({ isReportView: true })}
          componentsProps={{
            noRowsOverlay: { type: 'flights' },
          }}
        />
      </StyledTableWrapper>

      {anchorEl && selected && (
        <ReadOnlyMenu
          flight={selected}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
    </>
  );
}

export default memo(ReadOnlyFlightsTable);
