import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/types';

export const selectCrewChangeVessel = createSelector(
  [
    ({ mapVessels }: RootState) => mapVessels.vesselsFull,
    ({ crewChangePanel }: RootState) => crewChangePanel.vesselId,
  ],
  (vessels, vesselId) => (vesselId ? vessels.get(vesselId) : null)
);
