import { saveUserCompletedOnboardingSteps } from 'api/flotilla';
import { ONBOARDING_STEPS } from 'components/MapQuickMenu/components/variables';
import { array, enums, object, date, string, coerce, any } from 'superstruct';
import { OnboardCompletion } from './types';

const STEPS = ONBOARDING_STEPS.map((each) => each.stepKey);
export const StepsCompletedSchema = array(
  object({
    completed_at: coerce(date(), string(), (o) => new Date(o)),
    type: enums(STEPS),
  })
);

export const StepsContentSchema = object({
  title: string(),
  description: string(),
  url: coerce(string(), any(), (o) => o || ''),
  stepKey: enums(STEPS),
  youtubeId: coerce(string(), any(), (o) => o || ''),
});

export const StepsContentArraySchema = coerce(
  array(StepsContentSchema),
  array(any()),
  (o) => o.filter((a) => STEPS.includes(a.stepKey))
);

export const saveSteps = async (onboardingSteps: OnboardCompletion[]) => {
  try {
    return await saveUserCompletedOnboardingSteps(onboardingSteps);
  } catch (error) {
    console.error('Failed to save steps', error);
    return { success: false, message: (error as any).message };
  }
};
