import { createLogger } from 'redux-logger';

import { isDevelopment, showLogger } from 'lib/environments';
import sendEvent from './sendEvent';
import settings from './settings';
import resetStore from './resetStore';
import setupWizard from './setupWizard';
import saveSeaGPTMessages from './saveSeaGPTMessages';

const logger = createLogger({
  predicate: () => isDevelopment && showLogger,
});

// add custom middlewares here
const middlewares = [
  logger,
  sendEvent,
  settings,
  setupWizard,
  resetStore,
  saveSeaGPTMessages,
];

export default middlewares;
