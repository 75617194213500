import { memo } from 'react';
import { TextFieldProps, TextField as MuiTextField } from '@mui/material';
import { useField } from 'formik';

type FormikTextFieldProps = TextFieldProps & {
  id: string;
};

function TextField(props: FormikTextFieldProps) {
  const [field, meta] = useField(props.id);
  return (
    <MuiTextField
      {...props}
      {...field}
      value={field.value}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

export default memo(TextField);
