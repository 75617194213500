import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { showToaster } from 'lib/toaster';
import { isDevelopment } from 'lib/environments';

import { darkBlue, white } from 'lib/colors';
import { CustomCheckbox, Divider, Section, Title, Text } from '../common';
import { VesselsSettingsProps } from '../types';
import { RootState, VesselsSettings } from 'redux/types';
import { AssignPIC } from './Tables';
import { fetchCompanyUsers } from 'api/flotilla';
import { PICDataProps, UserProps } from '../types';
import { getUserName } from '../helpers';
import InfoIcon from '@mui/icons-material/Info';

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: ${darkBlue};
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
  width: fit-content;
  color: ${white};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const BadgeText = styled.div`
  font-size: 0.85rem;
  margin-left: 0.5rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 15px !important;
`;

const minuteList = [2, 5, 10, 30, 60];
const units = ['KM', 'NM'];

function Vessels({ initialValues, updateSettings }: VesselsSettingsProps) {
  const vessels = useSelector(
    ({ mapVessels }: RootState) => mapVessels.vesselsFull
  );
  const [settings, setSettings] = useState<VesselsSettings>(initialValues);
  const userInfo = useSelector(({ settings }: RootState) => settings.userInfo);

  const handleUpdate = (type: string, value?: any) => {
    const newValue = type === 'predictive' ? !settings.predictive : value;
    const updatedSettings = { ...settings, [type]: newValue };
    setSettings(updatedSettings);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      vessels: updatedSettings,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [picData, setPICData] = useState<PICDataProps[]>([]);
  const [users, setUsers] = useState<UserProps[]>([]);

  const handleFetchPICData = useCallback(async () => {
    setLoading(true);
    let vesselData = Array.from(vessels.values())
      .map(({ id, name: vesselName }) => ({
        id,
        vesselName,
        email: '-',
        pic: '-',
      }))
      .reduce(
        (
          acc: {
            [key: number]: PICDataProps;
          },
          curr
        ) => {
          acc[curr.id] = curr;
          return acc;
        },
        {}
      );

    const { success, companyUsers } = await fetchCompanyUsers();

    if (!success || !companyUsers) {
      showToaster({ message: 'Failed to fetch PIC!', type: 'error' });
      return;
    }
    if (companyUsers?.length) {
      setUsers(companyUsers.map((user) => user.user));
      companyUsers.forEach((user) => {
        user.assignedVesselIds.forEach((vessel) => {
          vesselData[vessel] = {
            ...vesselData[vessel],
            email: user.user.email,
            pic: getUserName(user.user),
          };
        });
      });
    }
    setPICData(Object.values(vesselData));
    setLoading(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    handleFetchPICData();
  }, []); // eslint-disable-line

  return (
    <>
      <Section>
        <MessageWrapper>
          <StyledInfoIcon />
          <BadgeText>Your account is tracking {vessels.size} vessels</BadgeText>
        </MessageWrapper>
      </Section>
      <Divider />

      {userInfo?.access['Assign PIC'] ? (
        <>
          <Section>
            <Title>Vessel PIC</Title>
            <AssignPIC
              loading={loading}
              picData={picData}
              users={users}
              setPICData={setPICData}
              isCompact
            />
          </Section>
          <Divider />
        </>
      ) : null}

      {isDevelopment ? (
        <>
          <Section>
            <Title>Vessel map display settings</Title>
            <Text>
              We collect data from various sources to predict where the vessel
              might have been or is going to be.
            </Text>
            <Text>
              Toggle this to hide/show these Predictive port calls for each
              vessel.
            </Text>

            <CheckboxWrapper>
              <CustomCheckbox
                label="Include Predictive"
                checked={settings.predictive}
                onClick={() => handleUpdate('predictive')}
              />
              <CustomCheckbox
                label="Do not include"
                checked={!settings.predictive}
                onClick={() => handleUpdate('predictive')}
              />
            </CheckboxWrapper>
          </Section>

          <Divider />

          <Section>
            <Title>Vessel updates</Title>
            <Text>
              How frequently would you like us to fetch updates on your vessel?
            </Text>
            <CheckboxWrapper>
              {minuteList.map((num) => (
                <CustomCheckbox
                  key={num}
                  label={`${num} minutes`}
                  checked={num === settings.updateFrequency}
                  onClick={() => handleUpdate('updateFrequency', num)}
                />
              ))}
            </CheckboxWrapper>
          </Section>

          <Divider />

          <Section>
            <Title>Display units</Title>
            <Text>Which is your preferred unit of measure?</Text>
            <CheckboxWrapper>
              {units.map((unit) => (
                <CustomCheckbox
                  key={unit}
                  label={`${
                    unit === 'KM' ? 'Kilometers' : 'Nautical miles'
                  } (${unit})`}
                  checked={settings.unit === unit}
                  onClick={() => handleUpdate('unit', unit)}
                />
              ))}
            </CheckboxWrapper>
          </Section>
        </>
      ) : null}
    </>
  );
}

export default Vessels;
