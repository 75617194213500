import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import ReadOnlyMeta from './ReadOnly';
import RouteMeta from './RouteMeta';
import FlightsMeta from './FlightsMeta';
import { MetaDivider } from '../../common';
import { mergePortsWithRoute } from '../../helpers';
import { PortMeta } from '../../types';

const Wrapper = styled.div`
  white-space: nowrap;
`;

// add metadata rendered beside icons in the steps hader
function MetaData() {
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const reportData = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.readOnlyPlanningData
  );
  const {
    filters,
    planningData,
    planningData: { ports, route },
    tableState: { step },
  } = useContext(CCPanelContext);

  const { portFilters: { etaLimit = 0 } = {} } =
    reportData?.crewChangePlan || {};
  const isReportView = activity === 'readOnly' && reportData;
  const showMetaData = isReportView || ['route', 'flights'].includes(step);

  const mergedPorts = useMemo(
    () =>
      mergePortsWithRoute(ports, route, etaLimit).map((port) => {
        const routeData = route.find(
          ({ port: routePort }) => routePort.locode === port.locode
        )!;
        // insert `estimated` status for missing ETA or ETD
        return {
          ...port,
          estimated:
            (routeData &&
              ((!routeData.eta && 'eta') || (!routeData.etd && 'etd'))) ||
            '',
        };
      }),
    [ports, route, etaLimit]
  );

  const renderTableMeta = () => {
    if (isReportView) {
      return <ReadOnlyMeta reportData={reportData} />;
    }

    switch (step) {
      case 'route':
        return <RouteMeta planningData={planningData} />;
      case 'flights':
        return (
          <FlightsMeta filters={filters} ports={mergedPorts as PortMeta[]} />
        );
      default:
        break;
    }
  };

  return showMetaData ? (
    <>
      {/* hide this divider for steps without icons */}
      {step !== 'flights' && <MetaDivider />}
      <Wrapper>{renderTableMeta()}</Wrapper>
    </>
  ) : null;
}

export default MetaData;
