import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable, PortNotes, PortNotesPayload, RootState } from '../types';
import { fetchPortNotesAsync } from 'redux/thunks';

type CounterState = Nullable<PortNotes>;

export const initialPortNotes = null as CounterState;

const portNotesSlice = createSlice({
  name: 'portNotes',
  initialState: initialPortNotes,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchPortNotesAsync.fulfilled,
      (state, action: PayloadAction<PortNotesPayload | null>) => {
        if (!action.payload) return state;

        const { locode, portNotes } = action.payload;
        return {
          ...state,
          [locode]: portNotes,
        };
      }
    );
  },
});

/* ----- selectors -----*/

export const selectPortNotes = ({ portNotes }: RootState) => portNotes;

/* ----- actions -----*/

/* ----- reducer -----*/

export default portNotesSlice.reducer;
