import debounce from 'lodash/debounce';

import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_QUICKFLY_AIRLINES,
  TRACK_QUICKFLY_AIRLINES_SELECT_ALL,
  TRACK_QUICKFLY_ARRIVAL_RANGE,
  TRACK_QUICKFLY_CATEGORY,
  TRACK_QUICKFLY_DEPARTURE_RANGE,
  TRACK_QUICKFLY_FLIGHT_RESULTS,
  TRACK_QUICKFLY_FLIGHT_TIME,
  TRACK_QUICKFLY_LAYOVERS,
  TRACK_QUICKFLY_LAYOVERS_SELECT_ALL,
  TRACK_QUICKFLY_LAYOVER_MATCHING_FLIGHT_RESULTS,
  TRACK_QUICKFLY_LAYOVER_NON_MATCHING_FLIGHT_RESULTS,
  TRACK_QUICKFLY_LAYOVER_RANGE,
  TRACK_QUICKFLY_MARINE,
  TRACK_QUICKFLY_MATCHING_FLIGHT_RESULTS_STATE,
  TRACK_QUICKFLY_NON_MATCHING_FLIGHT_RESULTS_STATE,
  TRACK_QUICKFLY_SEARCH_FILTERS_STATE,
  TRACK_QUICKFLY_SOURCE,
  TRACK_QUICKFLY_STOPS_NO,
  TRACK_QUICKFLY_AIRPORT_TRANSFER,
} from 'utils/analytics/constants';
import {
  Airline,
  FlightFilterType,
  LayoverAirport,
} from 'components/CrewChangePanel/types';
import { ModalView } from './types';

type TrackUtilsType = {
  openModal: () => void;
  toggleSearchFilters: (visible: boolean) => void;
  toggleMatchedResults: (visible: boolean) => void;
  toggleUnmatchedResults: (visible: boolean) => void;
  toggleExpandLayover: (
    unmatched: boolean | undefined,
    iataCode: string
  ) => void;
  toggleAirportTransfer: (allowed: boolean) => void;
  trackCategory: (category: FlightFilterType) => void;
  trackArrival: (arrival: [number, number]) => void;
  trackDeparture: (departure: [number, number]) => void;
  trackFlightTime: (flightTime: number) => void;
  trackLayover: (layover: [number, number]) => void;
  trackMarine: (marine: boolean) => void;
  trackStopsCount: (stopsCount: number) => void;
  trackSource: (value: string, allSources: string[]) => void;
  trackFlightLayovers: (
    layovers: LayoverAirport[],
    type?: 'Select All'
  ) => void;
  trackFlightAirlines: (airlines: Airline[], type?: 'Select All') => void;
};

const TRACK_DEBOUNCE_TIME = 1500;

// `undefined` for non-modal view of flight results - in QuickFly search results
export const getUserActionModalType = (modalView: ModalView | undefined) =>
  modalView !== 'ccpanel' &&
  (modalView === 'quickfly' ? 'expanded-modal' : 'quickfly-controls');

export const TrackUtils = (
  location: 'expanded-modal' | 'quickfly-controls' | false
): TrackUtilsType => {
  const commonTrackActions = {
    openModal: () => {
      trackUserAction(TRACK_QUICKFLY_FLIGHT_RESULTS, 'click');
    },
  };

  return {
    ...commonTrackActions,
    toggleSearchFilters: (visible: boolean) => {
      const status = !visible ? 'expand' : 'collapse';
      location &&
        trackUserAction(
          TRACK_QUICKFLY_SEARCH_FILTERS_STATE(status, location),
          'click'
        );
    },
    toggleMatchedResults: (visible: boolean) => {
      const status = !visible ? 'expand' : 'collapse';
      location &&
        trackUserAction(
          TRACK_QUICKFLY_MATCHING_FLIGHT_RESULTS_STATE(status, location),
          'click'
        );
    },
    toggleUnmatchedResults: (visible: boolean) => {
      const status = !visible ? 'expand' : 'collapse';
      location &&
        trackUserAction(
          TRACK_QUICKFLY_NON_MATCHING_FLIGHT_RESULTS_STATE(status, location),
          'click'
        );
    },
    toggleExpandLayover: (unmatched: boolean | undefined, layover: string) => {
      location &&
        trackUserAction(
          unmatched
            ? TRACK_QUICKFLY_LAYOVER_NON_MATCHING_FLIGHT_RESULTS(location)
            : TRACK_QUICKFLY_LAYOVER_MATCHING_FLIGHT_RESULTS(location),
          'click',
          { layover }
        );
    },
    toggleAirportTransfer: (allowed: boolean) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_AIRPORT_TRANSFER(location), 'click', {
          allowed,
        });
    },
    trackCategory: (category: FlightFilterType) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_CATEGORY(location), 'click', {
          category,
        });
    },
    trackArrival: debounce((arrival: [number, number]) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_ARRIVAL_RANGE(location), 'click', {
          arrival,
        });
    }, TRACK_DEBOUNCE_TIME),
    trackDeparture: debounce((departure: [number, number]) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_DEPARTURE_RANGE(location), 'click', {
          departure,
        });
    }, TRACK_DEBOUNCE_TIME),
    trackFlightTime: debounce((flightTime: number) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_FLIGHT_TIME(location), 'click', {
          flightTime,
        });
    }, TRACK_DEBOUNCE_TIME),
    trackLayover: debounce((layover: [number, number]) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_LAYOVER_RANGE(location), 'click', {
          layover,
        });
    }, TRACK_DEBOUNCE_TIME),
    trackMarine: debounce((marine: boolean) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_MARINE(location), 'click', { marine });
    }, TRACK_DEBOUNCE_TIME),
    trackStopsCount: debounce((stopsCount: number) => {
      location &&
        trackUserAction(TRACK_QUICKFLY_STOPS_NO(location), 'click', {
          stopsCount,
        });
    }, TRACK_DEBOUNCE_TIME),
    trackSource: debounce((value: string, allSources: string[]) => {
      const source =
        value === 'ALL' ? allSources.filter((text) => text !== 'ALL') : value;
      location &&
        trackUserAction(TRACK_QUICKFLY_SOURCE(location), 'click', { source });
    }, TRACK_DEBOUNCE_TIME),
    trackFlightLayovers: (
      selectedStops: LayoverAirport[],
      type?: 'Select All'
    ) => {
      const layovers = selectedStops.reduce<string[]>(
        (acc, { selected, iataCode }) => {
          if (!selected) return acc;
          return [...acc, iataCode];
        },
        []
      );
      location &&
        trackUserAction(
          type === 'Select All'
            ? TRACK_QUICKFLY_LAYOVERS_SELECT_ALL(location)
            : TRACK_QUICKFLY_LAYOVERS(location),
          'click',
          { layovers }
        );
    },
    trackFlightAirlines: (airlines: Airline[], type?: 'Select All') => {
      const airlineNames = airlines.reduce<string[]>(
        (acc, { selected, airline }) => {
          if (!selected) return acc;
          return [...acc, airline];
        },
        []
      );
      location &&
        trackUserAction(
          type === 'Select All'
            ? TRACK_QUICKFLY_AIRLINES_SELECT_ALL(location)
            : TRACK_QUICKFLY_AIRLINES(location),
          'click',
          { airlines: airlineNames }
        );
    },
  };
};
