import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import type {
  CrewChangePlanSummary,
  CrewChangePlanUserNotes,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  selectCrewChangePanel,
  selectMapVessels,
  selectSettings,
} from 'redux/selectors';
import {
  TRACK_DOWNLOAD_PORT_AGENT_CC_PLAN,
  TRACK_DOWNLOAD_REGULAR_CC_PLAN,
} from 'utils/analytics/constants';
import { CCPanelContext } from 'contexts/CCPanelContext';
import {
  formatEmailDetails,
  getLocodeKeyDetails,
} from 'components/CrewChangePanel/helpers';

import useReadOnlyPlanningData from '../useReadOnlyPlanningData';
import {
  convertReportToWSForCCP,
  convertReportToWSForPortAgent,
  createWorkbook,
  downloadWorkbook,
  getCustomReportName,
  getReportDetails,
} from './helpers';

import {
  ConvertToWSForCCP,
  ConvertToWSForPortAgent,
  TrackActionData,
} from './types';

type SaveReportProps = {
  userNotes?: CrewChangePlanUserNotes;
  selectedLocode?: string; // if available, indicates that this is a port report
  portAgent?: boolean; // if available, indicates that this is a port agent report
  skipSaving?: boolean; // if true, will not save read-only data
};

type ReportDetails = {
  shareableLink?: string;
  summary?: CrewChangePlanSummary;
};

function useCCPanelExport() {
  const {
    userInfo,
    crewChange: { costParams },
  } = useSelector(selectSettings);
  const { vesselId } = useSelector(selectCrewChangePanel);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const { saveReadOnlyData } = useReadOnlyPlanningData();
  const {
    filters: allFilters,
    planningData: { crew, ports },
    portParams,
  } = useContext(CCPanelContext);

  const vessel = useMemo(
    () => (vesselId ? vessels.get(vesselId) : undefined),
    [vesselId, vessels]
  );

  // method used to save exported excel file
  const saveExcelReport = useCallback(
    async ({
      userNotes,
      selectedLocode,
      portAgent,
      skipSaving,
    }: SaveReportProps) => {
      if (!vessel) return;

      try {
        let reportDetails: ReportDetails | undefined;

        if (!skipSaving && userNotes) {
          // save report in the back-end & generate shareable link
          reportDetails = await saveReadOnlyData({
            userNotes,
            selectedLocode,
          });
        }

        if (reportDetails?.shareableLink) {
          // show success toaster for link generation
          showToaster({ message: 'Created shareable link for the report.' });
        }

        const workbook = await createWorkbook(userInfo);
        const generateWorksheet =
          portAgent && selectedLocode
            ? convertReportToWSForPortAgent(workbook)
            : convertReportToWSForCCP(workbook);

        // save specific port report for port-agent
        // according to port-agent template
        if (portAgent && selectedLocode) {
          const {
            locode: originalLocode,
            flightSource,
            portETA: uniqETA,
          } = getLocodeKeyDetails(selectedLocode);
          const selectedPort = ports.find(
            ({ locode }) => locode === originalLocode
          );
          const portDetails = { ...selectedPort!, flightSource, uniqETA };
          (generateWorksheet as ConvertToWSForPortAgent)(
            crew,
            portDetails,
            vessel
          );
          await downloadWorkbook(
            workbook,
            getCustomReportName(selectedLocode, vessel, ports)
          );
          return reportDetails?.shareableLink;
        }

        // generate worksheet for individual port-cards
        Object.keys(allFilters)
          // only generate worksheet for selected port
          .filter((locode) => !selectedLocode || locode === selectedLocode)
          .forEach((locodeKey: string) => {
            const { confirmed: activeFlights = [] } =
              allFilters[locodeKey] || {};
            const {
              locode: originalLocode,
              flightSource,
              portETA: uniqETA,
            } = getLocodeKeyDetails(locodeKey);
            const port = {
              ...ports.find(({ locode }) => locode === originalLocode)!,
              flightSource,
              uniqETA,
            };
            const additionalDetails = getReportDetails(
              port,
              crew,
              activeFlights,
              portParams,
              costParams
            );
            const emailDetails = formatEmailDetails({
              crew,
              port,
              vessel,
              flights: activeFlights,
            });
            (generateWorksheet as ConvertToWSForCCP)(
              port,
              emailDetails,
              additionalDetails,
              reportDetails?.shareableLink
            );
          });

        await downloadWorkbook(workbook, `Crew change for ${vessel.name}`);
        trackUserAction(
          portAgent
            ? TRACK_DOWNLOAD_PORT_AGENT_CC_PLAN
            : TRACK_DOWNLOAD_REGULAR_CC_PLAN,
          'click',
          {
            reportSummary: {
              userNotes,
              crew: reportDetails?.summary?.crew,
              ports: reportDetails?.summary?.ports,
            },
          } as TrackActionData
        );
        return reportDetails?.shareableLink;
      } catch (error) {
        console.log('Error downloading crew-change plan from panel', error);
        showToaster({
          message: 'Failed to generate excel report for current plan.',
          type: 'error',
        });
      }
    },
    [
      userInfo,
      vessel,
      ports,
      crew,
      allFilters,
      portParams,
      costParams,
      saveReadOnlyData,
    ]
  );

  return { saveExcelReport };
}

export default useCCPanelExport;
