import React from 'react';
import {
  AirportLong,
  FlightSegment,
} from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import styled from 'styled-components/macro';

import { AirportsWrapper, AirportName } from './common';
import { formatBaggageInfo } from 'components/FlotillaSearch/helpers';

const StyledText = styled(AirportName)<{
  $spaced?: boolean;
  $baggage?: boolean;
}>`
  ${({ $spaced }) =>
    $spaced &&
    `
    margin-left: 0.9rem;
  `}
  ${({ $baggage }) =>
    $baggage &&
    `
    font-style: italic;
    font-weight: normal;
  `}
`;

type Props = {
  airportCodes: string[];
  segments: FlightSegment[] | undefined;
  airports:
    | {
        [iataCode: string]: AirportLong;
      }
    | undefined;
};

function FlightAirports({ airportCodes, airports, segments }: Props) {
  const baggageDetails = (segments || []).reduce<{
    [iataCode: string]: string | null;
  }>(
    (acc, { origin, baggage }) => ({
      ...acc,
      [origin.iata]: formatBaggageInfo(baggage),
    }),
    {}
  );

  return (
    <AirportsWrapper>
      {airportCodes.map((iataCode, index) => {
        const [arrival, nonAirCode = ''] = iataCode.split('-');
        const baggageInfo = baggageDetails[arrival];

        if (!nonAirCode) {
          return airports?.[iataCode] ? (
            <React.Fragment key={iataCode}>
              <StyledText>
                {index + 1}. {airports[iataCode].identifier}
              </StyledText>
              {baggageInfo && (
                <StyledText $spaced $baggage>
                  Baggage limit: {baggageInfo}
                </StyledText>
              )}
            </React.Fragment>
          ) : null;
        }

        return [
          ...(airports?.[arrival]
            ? [
                <StyledText key={arrival}>
                  {index + 1}. (a) {airports[arrival].identifier}
                </StyledText>,
              ]
            : []),
          ...(airports?.[nonAirCode]
            ? [
                <StyledText key={nonAirCode} $spaced>
                  (b) {airports[nonAirCode].identifier}
                </StyledText>,
              ]
            : []),
          ...(baggageInfo
            ? [
                <StyledText key={`baggage-info_${arrival}`} $spaced $baggage>
                  Baggage limit: {baggageInfo}
                </StyledText>,
              ]
            : []),
        ];
      })}
    </AirportsWrapper>
  );
}

export default FlightAirports;
