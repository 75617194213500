import styled from 'styled-components/macro';

import { textBlack, textGray, blue50, gray, black } from 'lib/colors';
import { Button } from 'components/shared';

export const Container = styled.div`
  position: relative;
`;

const handleArrowColor = (isCompleted?: boolean, color?: string) => {
  if (isCompleted) return gray;
  if (color === 'vessel') return blue50;
  if (color) return color;
  return black;
};

export const Indicator = styled.div<{
  $isFinalDestination?: boolean;
  $isCompleted?: boolean;
  $color?: string;
}>`
  transform: rotate(180deg) scale(0.6) !important;
  position: absolute;
  display: ${({ $isFinalDestination }) =>
    $isFinalDestination ? 'none' : 'flex'};
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 8px;
  width: 24px;
  height: 100%;

  .indicator_arrows {
    display: block;
    width: 5px;
    height: 5px;
    transform: rotate(45deg);

    border-right: 4px solid
      ${({ $isCompleted, $color }) => handleArrowColor($isCompleted, $color)};
    border-bottom: 4px solid
      ${({ $isCompleted, $color }) => handleArrowColor($isCompleted, $color)};
    border-radius: 2px;
    margin: 10px 5px;

    width: 16px;
    height: 16px;
  }

  .one,
  .two,
  .three {
    animation: arrow-animation 1s infinite;
    animation-direction: alternate;
  }

  .one {
    animation-delay: 0.1;
    margin-top: 1px;
  }

  .two {
    animation-delay: 0.2s;
    margin-top: -6px;
  }

  .three {
    animation-delay: 0.3s;
    margin-top: -6px;
  }

  @keyframes arrow-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const Wrapper = styled.div<{
  $isFinalDestination?: boolean;
  $isAnchor?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 40px;
  padding-bottom: ${({ $isFinalDestination, $isAnchor }) =>
    $isFinalDestination ? '4px' : `${$isAnchor ? '2rem' : '1rem'}`};
`;

export const VesselDetails = styled.div<{ $isCompleted?: boolean }>`
  flex-grow: 1;
  padding-right: 0.25rem;
  color: ${({ $isCompleted }) => ($isCompleted ? textGray : textBlack)};
`;

export const IconContainer = styled.div`
  position: absolute;
  left: -4px;
  top: 0px;
`;

export const IconButton = styled(Button)`
  min-width: initial;
  height: 30px;
  width: 30px;
  margin-top: 1rem;
  margin-right: 0.5rem;
`;
