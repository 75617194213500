import React, {
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components/macro';

import {
  useAppDispatch,
  useMobile,
  useModal,
  useReadOnlyPlanningData,
  useSavedPlanExport,
} from 'hooks';
import { blue, gray30, white } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { selectCrewChangeEvent } from 'redux/actions';
import {
  selectCrewChangePanel,
  selectSettings,
  selectSidePanel,
} from 'redux/selectors';
import { discardEventPlanning } from 'utils/crew-change-events';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Button, CommonConfirmModal, Tooltip } from 'components/shared';
import ActionButton from './ActionButton';
import ConfirmDownloadModal from './ConfirmDownloadModal';
import DownloadButton from './DownloadButton';
import StepsHeader from './StepsHeader';
import SettingsMenu from './SettingsMenu';
import { MetaDivider } from '../common';
import ShareLinkModal from '../common/LinkModals';
import { getDefaultReportTitle, getStepDetails } from '../helpers';

// lazy loading packages used for excel export
const SaveReportModal = React.lazy(() => import('../common/SaveReportModal'));

const InstructionsContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  padding: 0.5rem 1rem;
  background-color: rgba(33, 33, 33, 80%);
  border-radius: 0 0.25rem 0 0;
  color: ${white};
  font-size: 14px;
  line-height: 1rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    // Deduct the width of the buttons and spacing and
    // an additional gap between the two
    width: calc(100vw - 152px - 0.5rem - 1rem);
    flex-direction: row;
    padding: 0.5rem 0.5rem;
    svg {
      align-self: start;
    }
    span {
      font-size: 10px;
    }
  }
`;

const StyledDivider = styled(MetaDivider)`
  margin: 0 0.25rem;
  height: 16px;
`;

const NotesLink = styled.span`
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    text-decoration: underline;
  }
`;

const NotesText = styled.div`
  padding: 0.75rem 1rem;
  font-size: 0.9rem;
  width: 400px;
  background: ${gray30};
`;

const OtherActionsContainer = styled.div`
  position: absolute;
  bottom: 100%;
  right: 2rem;
  display: flex;
  column-gap: 1rem;

  button {
    height: 36px;
    margin: 0;
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    right: 0.5rem;
  }
`;

const SettingsButton = styled(Button)<{ $isActive: boolean }>`
  border-radius: 0.25rem 0.25rem 0 0;
  width: 36px;
  min-width: unset;
  border-bottom: 0px;
  z-index: 1;
  color: ${({ $isActive }) => ($isActive ? white : blue)};
  background-color: ${({ $isActive }) => ($isActive ? blue : white)};
  &:hover {
    transform: unset;
    box-shadow: unset;
  }
`;

function Container() {
  const dispatch = useAppDispatch();
  const sidePanel = useSelector(selectSidePanel);
  const {
    event,
    active: activity,
    readOnlyPlanningData: reportData,
  } = useSelector(selectCrewChangePanel);
  const { crewChange } = useSelector(selectSettings);

  const {
    tableState: {
      step,
      view,
      readOnlyDetails: { saveable },
    },
  } = useContext(CCPanelContext);
  const isMobile = useMobile(BREAK_POINT_XS);
  const { startNewCrewChange } = useReadOnlyPlanningData();
  const { saveReadOnlyExcelReport } = useSavedPlanExport();

  const { modal, setModal } = useModal(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const stepDetails = useMemo(() => getStepDetails()[step], [step]);
  const showGuide = crewChange.showGuide && activity === 'plan';
  const isReportView = activity === 'readOnly';

  const clearCCEvent = useCallback(() => {
    dispatch(selectCrewChangeEvent());
  }, []); // eslint-disable-line

  if (isMobile && sidePanel.visible) {
    return null;
  }

  const handleDiscard = () => {
    discardEventPlanning(event?.id, clearCCEvent, isReportView);
  };

  const renderActionButtons = () => {
    const commonTooltipContent = 'Discard current crew change process';
    const removeButton = (
      <ActionButton
        className="e2e_cc_panel-close-button"
        variant="delete"
        iconLeft={DisabledByDefaultRoundedIcon}
        onClick={handleDiscard}
        buttonText={isReportView ? 'Close' : 'Discard'}
        {...(isReportView ? {} : { tooltipContent: commonTooltipContent })}
      />
    );

    // show `START NEW` or `SAVE REPORT` button
    if (isReportView) {
      const props = {
        iconLeft: saveable ? SaveIcon : PlayCircleIcon,
        buttonText: saveable ? 'Save Report' : 'Start New',
        onClick: () =>
          saveable ? setModal('addUserNotes') : startNewCrewChange(),
        tooltipContent: saveable
          ? 'Save the current updated report'
          : 'Start planning a new crew change from this report',
      };

      return (
        <>
          <ActionButton variant={saveable ? 'success' : 'primary'} {...props} />
          {reportData && !saveable && (
            <ActionButton
              className="e2e_download-saved-report"
              variant="secondary"
              iconLeft={DownloadIcon}
              onClick={() => setModal('exportSavedPlan')}
              buttonText="Download"
              tooltipContent="Export crew-change report in Excel format"
            />
          )}
          {removeButton}
        </>
      );
    }

    return (
      <>
        {step === 'flights' && view === 'compare' && (
          <DownloadButton setModal={setModal} />
        )}
        {removeButton}
      </>
    );
  };

  const renderBadgeText = () => {
    // show instructions in planning stage
    if (showGuide) {
      return (
        <InstructionsContainer>
          <MenuBookRoundedIcon sx={{ fontSize: '18px' }} />
          <span>{stepDetails.instructions}</span>
        </InstructionsContainer>
      );
    }

    if (!isReportView || !reportData) return null;
    // show report title in read-only view stage

    const { title, notes } = reportData.userNotes;
    return (
      <InstructionsContainer>
        <ListAltIcon sx={{ fontSize: '18px' }} />
        <span data-id="e2e_cc_panel-report-title">
          {title || getDefaultReportTitle(reportData)}
        </span>
        {/* render plan notes, if available */}
        {Boolean(notes) && (
          <>
            <StyledDivider $color={white} />
            <DescriptionIcon sx={{ fontSize: '18px' }} />
            <NotesLink
              data-id="e2e_report-notes-view"
              onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
            >
              View Notes
            </NotesLink>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <NotesText data-id="e2e_cc_panel-report-notes">{notes}</NotesText>
            </Popover>
          </>
        )}
      </InstructionsContainer>
    );
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    const closeModal = () => setModal(null);

    if (modal.type === 'confirmDownload') {
      return (
        <ConfirmDownloadModal
          setModal={setModal}
          closeModal={closeModal}
          {...modal.data}
        />
      );
    }

    if (modal.type === 'exportReport') {
      return (
        <Suspense fallback={<></>}>
          <SaveReportModal closeModal={closeModal} {...(modal.data || {})} />
        </Suspense>
      );
    }

    if (['copyLink', 'addUserNotes'].includes(modal.type)) {
      return <ShareLinkModal modal={modal} setModal={setModal} />;
    }

    if (modal.type === 'exportSavedPlan' && reportData) {
      const description = `The plan titled as "${reportData.userNotes.title}" will be saved in Excel format (to the download location set in your browser).`;
      return (
        <CommonConfirmModal
          description={description}
          onConfirm={() => {
            saveReadOnlyExcelReport(reportData);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    }
  };

  return (
    <>
      {renderBadgeText()}
      <OtherActionsContainer>
        {showSettings && <SettingsMenu />}
        <SettingsButton
          variant="secondary"
          $isActive={showSettings}
          onClick={() => setShowSettings((o) => !o)}
          style={{ marginRight: 0 }}
        >
          <Tooltip enterDelay={500} content="Modify Crew Change Settings">
            <SettingsIcon
              style={{ fontSize: '18px', verticalAlign: 'middle' }}
            />
          </Tooltip>
        </SettingsButton>

        {renderActionButtons()}
      </OtherActionsContainer>
      <StepsHeader />

      {renderModal()}
    </>
  );
}

export default Container;
