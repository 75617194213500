import moment from 'moment';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import nationality from 'api/data/nationality.json';
import {
  ClassOfService,
  CrewWithNoFlight,
  PrepareCrewLinkRequest,
} from '../types';

const cabinClassMapping = {
  ANY: 'ANY',
  MIXED: 'ANY',
  ECONOMY: 'Economy',
  FIRST: 'First Class',
  BUSINESS: 'Business',
  PREMIUM_ECONOMY: 'Premium Economy',
};

const getIsoCode = (country: string, type: 'iso2' | 'iso3') => {
  const obj = nationality.find((c) => c.en_short_name === country);
  if (obj) {
    if (type === 'iso2') return obj.alpha_2_code;
    if (type === 'iso3') return obj.alpha_3_code;
  }
  return null;
};

const includeDefultDate = (date: string | undefined) =>
  date || moment().utcOffset(0, true).toISOString();

const getDateObj = (date: string) => {
  const d = moment(date.replace('Z', '')); // replace 'Z' to keep utc time
  return {
    day: d.format('DD'),
    month: d.format('MM'),
    year: d.format('YYYY'),
  };
};

const getTimeObj = (date: string) => {
  const d = moment(date.replace('Z', '')); // replace 'Z' to keep utc time
  return {
    hour: d.format('HH'),
    minute: d.format('mm'),
  };
};

const splitDate = (date: string) => ({
  day: date.split('/')[0],
  month: date.split('/')[1],
  year: date.split('/')[2],
});

export const prepareCrewLinkData: PrepareCrewLinkRequest = ({
  event,
  vessel,
  flights,
  crew,
  missingDates,
}) => {
  const modifiedFlights = flights.map((flight, index) => ({
    ...flight,
    id: index + 1,
  }));
  const crewWithFlights = flights.map((flight) => flight.crew.id);
  const crewWithNoFlights: CrewWithNoFlight[] = crew
    .filter((c) => !crewWithFlights.includes(c.id))
    .map((c, index) => ({
      flightId: modifiedFlights.length + index + 1,
      passengerId: c.id,
      from:
        c.type === 'onsigner'
          ? c.homeAirport?.iataCode ?? ''
          : event.portAirport,
      to:
        c.type === 'onsigner'
          ? event.portAirport
          : c.homeAirport?.iataCode ?? '',
      ...(c.type === 'offsigner'
        ? {
            departureDate:
              missingDates?.[c.id].departureDate ||
              event.departures!.offsigner!,
          }
        : {
            arrivalDate:
              missingDates?.[c.id].arrivalDate || event.departures!.onsigner!,
          }),
    }));

  return {
    crewLinkBookingRequest: {
      isUrgent: false,
      vesselName: vessel.name,
      crewChangeEvent: {
        locodeOrIataCode: event.locode,
        eventDate: getDateObj(includeDefultDate(event?.portDates?.eta)),
      },
      passengers: crew.map((p) => {
        const isoCode = p.country ? getIsoCode(p.country, 'iso2') : null;
        const nationality = p.country ? getIsoCode(p.country, 'iso3') : null;
        return {
          id: p.id,
          title: p.sex === 'Female' ? 'MS' : 'MR',
          firstName: (p.firstName && p.lastName ? p.firstName : p.name) ?? '',
          middleName: p.middleName ?? undefined,
          lastName: p.lastName ?? '',
          rank: p.rank ?? undefined,
          gender: p.sex === 'Female' ? 'FEMALE' : 'MALE',
          paxType: 'ADULT',
          dob: getDateObj(includeDefultDate(p.birthday)),
          nationality: nationality ?? undefined,
          contactNumber: undefined,
          emailAddress: undefined,
          travelType: p.type === CrewType.onsigner ? 'JNR' : 'LVR',
          passportNo: p.passportNumber,
          passportExpiryDate: p.passportExpiry
            ? splitDate(p.passportExpiry)
            : undefined,
          passportIssuedDate: p.passportIssued
            ? splitDate(p.passportIssued)
            : undefined,
          passportIssueCountryIso2: isoCode ?? undefined,
          seamanBook: {
            documentNumber: p.seamenBookNumber || '',
            dateOfIssue: p.seamenBookIssued
              ? splitDate(p.seamenBookIssued)
              : undefined,
            dateOfExpiry: p.seamenBookExpiry
              ? splitDate(p.seamenBookExpiry)
              : undefined,
            countryOfIssue2letterISOCode: isoCode ?? undefined,
          },
          crewIdentifier: p.cid ?? undefined,
        };
      }),
      flightItineraries: modifiedFlights.map((flight) => {
        return {
          id: flight.id,
          ...flight.crewLinkFlightSummary!,
        };
      }),
      passengerFlightItineraryGroups: modifiedFlights.map((flight) => {
        return { flightItineraryId: flight.id, passengerId: flight.crew.id };
      }),
      flightRequests: [
        ...modifiedFlights.map((flight) => {
          const {
            crew,
            arrival: { time: arrivalTime },
            departure: { time: departureTime },
          } = flight;
          // set flight time based on crew type
          const isOffsigner = crew.type === 'offsigner';
          const flightTime = isOffsigner ? departureTime : arrivalTime;
          const dateType = isOffsigner ? 'departureDate' : 'arrivalDate';
          return {
            id: flight.id,
            from: flight.departure.airportCode,
            to: flight.arrival.airportCode,
            time: getTimeObj(flightTime),
            // only one of `arrivalDate` or `departureDate` should be used
            [dateType]: getDateObj(flightTime),
            classOfService: cabinClassMapping[
              flight.cabinClass ?? 'ANY'
            ] as ClassOfService,
          };
        }),
        ...crewWithNoFlights.map((x) => {
          const flightTimeObj = x.departureDate
            ? {
                departureDate: getDateObj(x.departureDate),
                time: getTimeObj(x.departureDate),
              }
            : {
                arrivalDate: getDateObj(x.arrivalDate!),
                time: getTimeObj(x.arrivalDate!),
              };
          return {
            id: x.flightId,
            from: x.from,
            to: x.to,
            classOfService: cabinClassMapping['ANY'] as ClassOfService,
            ...flightTimeObj,
          };
        }),
      ],
      passengerFlightGroups: [
        ...modifiedFlights.map((flight) => {
          return { flightId: flight.id, passengerId: flight.crew.id };
        }),
        ...crewWithNoFlights.map((x) => {
          return { flightId: x.flightId, passengerId: x.passengerId };
        }),
      ],
    },
  };
};
