import capitalize from 'lodash/capitalize';

// capitalize multiple word string/text
export const titleize = (text: string) =>
  text.split(' ').map(capitalize).join(' ');

export function pluralize(word: string) {
  if (!word) return word;
  if (
    word.endsWith('s') ||
    word.endsWith('x') ||
    word.endsWith('z') ||
    word.endsWith('ch') ||
    word.endsWith('sh')
  ) {
    return word + 'es';
  } else if (word.endsWith('y') && !/[aeiouy]$/.test(word)) {
    return word.replace(/y$/, 'ies');
  } else if (word.endsWith('y')) {
    return word + 's';
  } else if (word.endsWith('f')) {
    return word.replace(/f$/, 'ves');
  } else if (word.endsWith('fe')) {
    return word.replace(/fe$/, 'ves');
  } else {
    return word + 's';
  }
}

export function pluralizeWithCount(word: string, value: number) {
  if (value > 1) return pluralize(word);
  return word;
}
