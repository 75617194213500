import {
  memo,
  SyntheticEvent,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { Autocomplete, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import sleep from 'lib/sleep';
import { purple, white } from 'lib/colors';
import { titleize } from 'lib/string';
import { setRecentSearches } from 'redux/actions';
import { selectRecentSearches } from 'redux/selectors';
import { CountryOption as Country } from 'redux/types';

import { AutocompleteGroup, Dropdown, Tooltip } from 'components/shared';
import ColumnsMenu from './ColumnsMenu';
import SelectCrew from './SelectCrew';
import AirportDropdown from '../../../common/AirportDropdown';
import { StyledButton } from '../../../common';
import {
  CREW_ROWS,
  formatCountries,
  getAutoGeneratedName,
} from '../../../helpers';
import {
  AddCrew,
  CrewActionProps,
  CrewAddStatus,
  CrewInput,
  SelectCrewItem,
} from '../../../types';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AddCrewWrapper = styled(FlexWrapper)`
  padding-top: 0.5rem;
`;

const ActionWrapper = styled(FlexWrapper)`
  min-height: 44px;
`;

const Wrapper = styled.div`
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-bottom: 0.5rem;
  overflow-x: auto;
`;

const InputWrapper = styled(FlexWrapper)`
  margin-left: 1rem;
  font-size: 0.8rem;
  width: 660px;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const ActionButton = styled(StyledButton)`
  flex-shrink: 0;
`;

const CloseButton = styled(ActionButton)`
  margin-left: -0.5rem;
`;

const CountryImage = styled.img`
  width: 16px;
  height: auto;
`;

const ColumnsButton = styled(ActionButton)`
  color: ${white};
  background-color: ${purple};
  flex-shrink: 0;
`;

const ColumnIcon = styled(ViewColumnIcon)`
  margin-right: 0.5rem;
  font-size: 18px !important;
`;

type AddedCrew = {
  name: string;
  country: string;
  type: string;
  homeAirport: AirportCommon | null;
};

type EventType =
  | React.SyntheticEvent
  | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  | SelectChangeEvent;

type Props = CrewActionProps & {
  crew: CrewInput | null;
  allSelected: boolean;
  reset: () => void;
  removeAll: () => void;
  toggleAll: () => void;
  addCrew: AddCrew;
  addStatus: CrewAddStatus;
  selectAirport: SelectCrewItem;
  updateStatus: (status: Partial<CrewAddStatus>) => void;
};

const commonStyles = { mr: 2 };
const dropdownStyles = {
  minWidth: '110px',
  ...commonStyles,
};
const inputStyles = {
  style: { fontSize: '0.8rem' },
};

const autoCompleteStyles = {
  maxHeight: '300px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

const initialCrewDetails: AddedCrew = {
  name: '',
  country: '',
  type: 'onsigner',
  homeAirport: null,
};

const options = ['Onsigner', 'Offsigner'];

const getUpdatedCrew = (crew: any) => ({
  ...(crew || {}),
  id: crew.id || Date.now(),
  added: true,
});

function CrewActions({
  crew: selectedCrew,
  allSelected,
  reset,
  addCrew,
  toggleAll,
  removeAll,
  addStatus,
  updateStatus,
  selectAirport,
}: Props) {
  const dispatch = useAppDispatch();
  const recentSearches = useSelector(selectRecentSearches);
  const [newCrew, setNewCrew] = useState<CrewInput | AddedCrew>(
    initialCrewDetails
  );
  const inputRef = useRef<HTMLInputElement>(null);
  // additional query text for country for resetting of autocomplete text-field value
  const [countryQuery, setCountryQuery] = useState('');
  const [columnsMenuEl, setColumnsMenuEl] = useState<null | HTMLElement>(null);

  const { addingId: isAdding, isSelecting } = addStatus;
  const { name, country, type, homeAirport } = newCrew;
  const disabledAddButton = !country || !type || !homeAirport?.iataCode;
  const showResetButton = !disabledAddButton || isAdding;
  const { crewNation = [] } = recentSearches || {};

  const countryList = useMemo(
    () => formatCountries(crewNation as Country[], Boolean(countryQuery)),
    [crewNation, countryQuery]
  );
  const selectedCountry = useMemo(
    () => countryList.find(({ name }) => name === country),
    [country] // eslint-disable-line
  );
  const addedCrews = useMemo(
    () => CREW_ROWS.filter(({ added }) => added),
    [CREW_ROWS] // eslint-disable-line
  );

  useEffect(() => {
    setNewCrew(selectedCrew || initialCrewDetails);
  }, [selectedCrew]);

  useEffect(() => {
    if (isAdding) {
      // set focus to first text input when adding a missing crew
      inputRef?.current?.focus?.();
    }
  }, [isAdding]);

  const handleSelectAirport = (airport: AirportCommon | null) => {
    const newItem = { homeAirport: airport };
    const crew = { ...newCrew, ...newItem };
    setNewCrew(crew);
    selectAirport(getUpdatedCrew(crew), newItem);
  };

  const handleChange =
    (type: string) =>
    (event: EventType, inputValue?: Country | string | null) => {
      const { value } = event.target as HTMLInputElement;
      const newValue =
        type === 'country' ? (inputValue as Country | null)?.name || '' : value;
      setNewCrew((prevValue) => ({
        ...prevValue,
        [type]: type === 'type' && newValue ? newValue.toLowerCase() : newValue,
      }));
    };

  const handleChangeCountry = (
    event: EventType,
    value?: Country | string | null
  ) => {
    const newValue = (value as Country | null)?.name || '';
    setNewCrew((prevValue) => ({ ...prevValue, country: newValue }));
    if (value) {
      dispatch(setRecentSearches({ crewNation: value as Country }));
    }
  };

  const handleCountryInputChange = (event: SyntheticEvent, value: string) => {
    setCountryQuery(value);
  };

  const handleKeyDown = async (event: EventType) => {
    // async update of country with keyboard to have value available in event
    await sleep(0);
    const { value: country } = event.target as HTMLInputElement;
    setNewCrew((crew) => ({ ...crew, country }));
  };

  const handleResetInputs = () => {
    setCountryQuery('');
    setNewCrew(initialCrewDetails);
  };

  const handleAddCrew = () => {
    const crew = getUpdatedCrew({
      ...newCrew,
      name: newCrew.name?.trim() || getAutoGeneratedName(),
    });
    addCrew(crew);
    handleResetInputs();
  };

  const handleReset = useCallback(() => {
    reset();
    updateStatus({ addingId: false });
    handleResetInputs();
  }, []); // eslint-disable-line

  const customGroupProps =
    crewNation.length && !countryQuery
      ? { groupBy: (option: Country) => option.label || '' }
      : {};

  return (
    <Wrapper>
      <ActionWrapper>
        <AddCrewWrapper>
          <InputWrapper>
            <Dropdown
              testId="crew-type-input"
              options={options}
              value={titleize(type)}
              formStyles={dropdownStyles}
              disabled={Boolean(selectedCrew)}
              onChange={handleChange('type')}
              fontSize="0.8rem"
            />
            <Autocomplete
              disablePortal
              freeSolo
              size="small"
              options={countryList}
              value={selectedCountry || null} // fallback to `null` for no option
              inputValue={countryQuery}
              getOptionLabel={(option) => (option as Country).name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 1, ml: -1, flexShrink: 0 } }}
                  {...props}
                >
                  <CountryImage
                    loading="lazy"
                    src={`https://flagcdn.com/w20/${option.countryCode.toLowerCase()}.png`}
                    // srcSet={`https://flagcdn.com/w40/${option.countryCode.toLowerCase()}.png 2x`}
                    alt={`${option.name} flag`}
                  />
                  {option.name}
                </Box>
              )}
              onChange={handleChangeCountry}
              onInputChange={handleCountryInputChange}
              onKeyDown={(e) => e.key === 'Enter' && handleKeyDown(e)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={inputRef}
                  label="Nationality"
                  placeholder="Add crew country"
                />
              )}
              renderGroup={AutocompleteGroup}
              ListboxProps={{ style: autoCompleteStyles }}
              sx={{ mr: 2, width: 180 }}
              {...customGroupProps}
            />
            <AirportDropdown.Crew
              type="ccAction"
              label="Home Airport"
              placeholder="Add crew airport"
              reset={!homeAirport?.iataCode}
              onSelect={handleSelectAirport}
            />
            <TextField
              size="small"
              label="Name (optional)"
              placeholder="Add crew name"
              value={name}
              onChange={handleChange('name')}
              sx={{ ...commonStyles, ml: 2 }}
              inputProps={inputStyles}
            />
          </InputWrapper>

          <FlexWrapper>
            <ActionButton
              variant="primary"
              disabled={disabledAddButton}
              onClick={handleAddCrew}
            >
              Add New Crew
            </ActionButton>
            {showResetButton && (
              <CloseButton variant="delete" onClick={handleReset}>
                Reset
              </CloseButton>
            )}

            {allSelected && (
              <ActionButton variant="secondary" onClick={toggleAll}>
                Clear Selection
              </ActionButton>
            )}
            {!!addedCrews.length && (
              <ActionButton variant="delete" onClick={removeAll}>
                Remove Added Crew
              </ActionButton>
            )}

            <Tooltip content="Update visibility of table columns">
              <ColumnsButton
                variant="primary"
                onClick={(event: SyntheticEvent) =>
                  setColumnsMenuEl(event.target as HTMLElement)
                }
              >
                <ColumnIcon />
                Columns
              </ColumnsButton>
            </Tooltip>
          </FlexWrapper>
        </AddCrewWrapper>
      </ActionWrapper>

      <SelectCrew
        onAddSelected={addCrew}
        selecting={isSelecting}
        reset={() => updateStatus({ isSelecting: false })}
      />

      {Boolean(columnsMenuEl) && (
        <ColumnsMenu anchorEl={columnsMenuEl} setAnchorEl={setColumnsMenuEl} />
      )}
    </Wrapper>
  );
}

export default memo(CrewActions);
