import { memo, useCallback, useContext } from 'react';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { blue, gray, white } from 'lib/colors';
import { useSelector } from 'react-redux';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { updateCCPanelSettings } from 'redux/thunks';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Checkbox, Tooltip } from 'components/shared';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: -0.25rem;
  background: ${gray};
  color: ${blue};
  width: calc(36px + 0.5rem);
  height: calc(100% + 0.25rem);
  z-index: 0;
`;

const Container = styled.div<{
  $isReportView?: boolean;
  $isCompareView: boolean;
  $isSaveableReadOnly?: boolean;
}>`
  position: absolute;
  bottom: calc(32px + 4px);
  padding: 0.5rem;
  background-color: ${gray};
  border-radius: 8px 8px 0 8px;
  min-width: 240px;
  right: ${({ $isReportView, $isCompareView, $isSaveableReadOnly }) =>
    ($isReportView &&
      ($isSaveableReadOnly
        ? 'calc(240px + 2rem - 4px)'
        : 'calc(380px + 3rem - 4px)')) ||
    ($isCompareView ? 'calc(240px + 2rem - 4px)' : 'calc(100px + 1rem - 4px)')};
  h5 {
    margin: 0 0 0.5rem;
  }
`;

const InnerContainter = styled.div`
  padding: 0.25rem;
  background-color: ${white};
  border-radius: 4px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  label {
    font-size: 0.8rem;
  }
`;

function SettingsMenu() {
  const dispatch = useAppDispatch();
  const {
    crewChange: { compact, showGuide },
  } = useSelector(selectSettings);
  const { active: activity } = useSelector(selectCrewChangePanel);

  const {
    tableState: {
      step,
      view,
      readOnlyDetails: { saveable },
    },
  } = useContext(CCPanelContext);

  const handleUpdate = useCallback(
    (type: 'compact' | 'showGuide') => (payload: boolean) => {
      dispatch(updateCCPanelSettings({ [type]: payload }));
    },
    [] // eslint-disable-line
  );

  const isReportView = activity === 'readOnly';
  const isCompareView = step === 'flights' && view === 'compare';

  return (
    <>
      <Wrapper />

      <Container
        $isSaveableReadOnly={saveable}
        $isReportView={isReportView}
        $isCompareView={isCompareView}
      >
        <InnerContainter>
          <h5>Crew Change Panel Settings</h5>
          <Tooltip
            enterDelay={500}
            content="Make row heights smaller or taller"
            placement="left"
          >
            <CheckboxWrapper>
              <Checkbox
                checkboxContainerStyle={{ padding: 0, marginLeft: 0 }}
                id="view-compact"
                label="Compact rows"
                checked={compact}
                onChange={handleUpdate('compact')}
              />
            </CheckboxWrapper>
          </Tooltip>
          <CheckboxWrapper>
            <Checkbox
              checkboxContainerStyle={{ padding: 0, marginLeft: 0 }}
              id="view-guide"
              label="Show help text"
              checked={showGuide}
              disabled={isReportView}
              onChange={handleUpdate('showGuide')}
            />
          </CheckboxWrapper>
        </InnerContainter>
      </Container>
    </>
  );
}

export default memo(SettingsMenu);
