import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { addVessel, getFlotillaVessels } from 'api/flotilla';
import { updateVesselResponse } from 'redux/actions';

/* ----- Async Thunks ----- */

export const populateVesselsAsync = createAsyncThunk(
  'mapVessels/populateVesselsAsync',
  async () => {
    const { message, success, vessels } = await getFlotillaVessels();
    if (success && vessels) {
      return vessels;
    }
    showToaster({ message, type: 'error' });
    return null;
  }
);

export const addVesselAsync = createAsyncThunk(
  'mapVessels/addVesselAsync',
  async (vesselIMO: string, { dispatch }) => {
    const response = await addVessel(vesselIMO);
    const { message, success } = response;

    dispatch(updateVesselResponse(response));

    if (success) {
      trackUserAction('add-vessel', 'happened', { vesselIMO });
      if (message.includes('Vessel exists')) {
        showToaster({ message: 'Vessel already exists.', type: 'info' });
      } else {
        showToaster({ message: 'New vessel added successfully!' });
      }

      const { success: fetchSuccess, vessels } = await getFlotillaVessels();
      if (fetchSuccess && vessels) {
        return vessels;
      }
    }

    return null;
  }
);
