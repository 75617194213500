import styled from 'styled-components/macro';
import Modal from './Modal';
import ButtonV2 from './ButtonV2';
import { useState } from 'react';
import sleep from 'lib/sleep';

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

function StopFlightTrackModal({
  closeModal,
}: {
  closeModal: () => void;
  greywingSuggestedFlightRebooked: string;
  uid: string;
}) {
  const [loading, setLoading] = useState(false);

  const handleStop = async () => {
    setLoading(true);
    await sleep(2000);
    closeModal();
  };

  const actions = (
    <ButtonsWrapper>
      <ButtonV2 onClick={handleStop} loading={loading}>
        I agree to stop tracking
      </ButtonV2>
    </ButtonsWrapper>
  );

  return (
    <Modal
      disableExternalClose
      title="Flight Price Tracker"
      closeModal={closeModal}
      actions={actions}
    >
      <p>Are you sure you want to stop tracking this flight?</p>
      <p>A to B on 23rd December 2023</p>
    </Modal>
  );
}

export default StopFlightTrackModal;
