import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToaster } from 'lib/toaster';
import { addReminder, deleteReminder, getReminders } from 'api/flotilla';
import { RootState } from 'redux/types';
import { Reminder, ReminderStatusType } from 'utils/types';
import { FlotillaStoreConfig } from 'utils/flotilla-config';

/* ----- Async Thunks ----- */

export const completedRemindersAsync = createAsyncThunk(
  'reminders/completedRemindersAsync',
  async (
    { pageToken, isMore = false }: { pageToken?: string; isMore?: boolean },
    thunkApi
  ) => {
    const { success, reminders } = await getReminders(
      pageToken,
      FlotillaStoreConfig.reminderFetchSize,
      // Note that the api still makes use of past, but the UI refers to this as completed
      'PAST'
    );
    if (!success || !reminders) {
      return null;
    }
    if (isMore) {
      const appState = thunkApi.getState() as RootState;
      const { completedReminders } = appState.reminders;
      return {
        ...reminders,
        reminders: completedReminders
          ? [...completedReminders.reminders, ...reminders!.reminders]
          : reminders!.reminders,
      };
    }
    return reminders;
  }
);

export const upcomingRemindersAsync = createAsyncThunk(
  'reminders/upcomingRemindersAsync',
  async (
    { pageToken, isMore = false }: { pageToken?: string; isMore?: boolean },
    thunkApi
  ) => {
    const { success, reminders } = await getReminders(
      pageToken,
      FlotillaStoreConfig.reminderFetchSize,
      'UPCOMING'
    );
    if (!success || !reminders) {
      return null;
    }
    if (isMore) {
      const appState = thunkApi.getState() as RootState;
      const { upcomingReminders } = appState.reminders;
      return {
        ...reminders,
        reminders: upcomingReminders
          ? [...upcomingReminders.reminders, ...reminders!.reminders]
          : reminders!.reminders,
      };
    }
    return reminders;
  }
);

export const addReminderAsync = createAsyncThunk(
  'reminders/addReminderAsync',
  async (reminder: Reminder, { dispatch }) => {
    const { success, message } = await addReminder(reminder);
    if (success) {
      showToaster({
        type: 'success',
        message: 'Succesfully added reminder.',
      });
      if (localStorage.getItem('allow-desktop-notifications') !== '0') {
        if (!window.Notification) {
          localStorage.setItem('allow-desktop-notifications', '0');
          showToaster({
            message: "Browser doesn't support desktop notifications.",
            type: 'error',
          });
        } else if (Notification.permission === 'default') {
          showToaster({
            message: 'Allow notifications to Enable Push Notifications.',
            type: 'warning',
          });
          Notification.requestPermission();
        } else if (Notification.permission === 'denied') {
          showToaster({
            message: 'Notifications are Blocked. Please Enable in Settings.',
            type: 'error',
          });
        }
      }
      await dispatch(upcomingRemindersAsync({}));
      return success;
    } else {
      showToaster({
        type: 'error',
        message,
      });
      return success;
    }
  }
);

export const removeReminderAsync = createAsyncThunk(
  'reminders/removeReminderAsync',
  async (
    { reminderId, type }: { reminderId: string; type: ReminderStatusType },
    { dispatch }
  ) => {
    const { success, message } = await deleteReminder(reminderId);
    if (success) {
      showToaster({
        type: 'success',
        message: 'Succesfully removed reminder.',
      });
      if (type === 'PAST') {
        await dispatch(completedRemindersAsync({}));
      } else {
        await dispatch(upcomingRemindersAsync({}));
      }
      return true;
    }
    showToaster({
      type: 'error',
      message,
    });
    return false;
  }
);
