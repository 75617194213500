import uniq from 'lodash/uniq';
import unionBy from 'lodash/unionBy';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import {
  RootState,
  SidePanelContentType,
  SidePanelRouteConfig,
  SidePanelRouteConfigPayload,
  SidePanelSettings,
} from '../types';
import { NotificationUpdate } from 'utils/types';
import { DisplayConfig } from 'utils/flotilla-config';

import {
  removeNotificationAsync,
  markNotificationReadAsync,
  markSelectedNotificationReadAsync,
} from '../thunks';

export const initialSidePanel: SidePanelSettings = {
  visible: false,
  vesselIds: [],
  updates: [],
  contentType: null,
  vesselRouteConfig: {},
  hiddenRouteVesselIds: [],
  expandedCollapsibleVessel: null,
  noUpdateId: '',
};

const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState: initialSidePanel,
  reducers: {
    openFlotillaSidePanel: (
      state,
      action: PayloadAction<SidePanelContentType>
    ) => ({
      ...state,
      visible: true,
      contentType: action.payload,
    }),
    addMobileVesselsToSidePanel: (state, action: PayloadAction<number[]>) => {
      // This will set the vesselIds as the vessels in the side panel
      // and will not show the side panel
      return {
        ...state,
        vesselIds: action.payload,
      };
    },
    addVesselsToSidePanel: (state, action: PayloadAction<number[]>) => {
      const updatedVesselIds = uniq([...state.vesselIds, ...action.payload]);
      const visible =
        Boolean(updatedVesselIds.length) &&
        window.innerWidth > DisplayConfig.minimumSidePanelDisplayWidthPx;

      return {
        ...state,
        visible,
        vesselIds: updatedVesselIds,
        contentType: SidePanelContentType.VESSELS,
      };
    },
    setVesselInSidePanel: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        visible: true,
        vesselIds: [action.payload],
        contentType: SidePanelContentType.VESSELS,
      };
    },
    toggleVesselsToSidePanel: (state, action: PayloadAction<number[]>) => {
      let updatedVesselIds = [...state.vesselIds];
      // add or remove vessel IDs from sidepanel
      action.payload.forEach((id) => {
        if (updatedVesselIds.includes(id)) {
          updatedVesselIds = updatedVesselIds.filter(
            (vesselId) => vesselId !== id
          );
        } else {
          updatedVesselIds = [...updatedVesselIds, id];
        }
      });

      return {
        ...state,
        visible: Boolean(updatedVesselIds.length),
        vesselIds: updatedVesselIds,
        contentType: SidePanelContentType.VESSELS,
      };
    },
    expandCollapsibleVesselInSidePanel: (
      state,
      action: PayloadAction<number | null>
    ) => ({
      ...state,
      expandedCollapsibleVessel: action.payload,
    }),
    clearSidePanelVessels: (state) => ({
      ...state,
      vesselIds: [],
      hiddenRouteVesselIds: [],
      vesselRouteConfig: {},
    }),
    removeVessel: (state, action: PayloadAction<number>) => ({
      ...state,
      vesselIds: state.vesselIds.filter((id) => id !== action.payload),
    }),
    setSidePanelRouteConfig: (
      state,
      action: PayloadAction<SidePanelRouteConfigPayload>
    ) => {
      const { vesselId, config } = action.payload;
      return {
        ...state,
        vesselRouteConfig: {
          ...state.vesselRouteConfig,
          [vesselId]: config,
        },
      };
    },
    removeSidePanelRouteConfig: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      const { payload: vesselId } = action;
      if (!vesselId) {
        return { ...state, vesselRouteConfig: {} };
      }

      const currentConfig = { ...state.vesselRouteConfig };
      delete currentConfig[vesselId];
      return { ...state, vesselRouteConfig: currentConfig };
    },
    addNotificationUpdates: (
      state,
      action: PayloadAction<NotificationUpdate[]>
    ) => ({
      ...state,
      updates: unionBy([...action.payload, ...state.updates], 'id').sort(
        (a, b) => new Date(b.id).valueOf() - new Date(a.id).valueOf()
      ),
    }),
    toggleSidePanelVesselRoute: (
      state,
      action: PayloadAction<{ visible: boolean; vesselId: number }>
    ) => {
      const { visible, vesselId } = action.payload;
      const hiddenRouteVesselIds = visible
        ? uniq([...state.hiddenRouteVesselIds, vesselId])
        : state.hiddenRouteVesselIds.filter((id) => id !== vesselId);

      return { ...state, hiddenRouteVesselIds };
    },
    closeSidePanel: (state) => ({
      ...state,
      visible: false,
      hiddenRouteVesselIds: [],
    }),
    updateNoUpdateId: (state, action: PayloadAction<string>) => ({
      ...state,
      noUpdateId: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeNotificationAsync.fulfilled, (state, action) => {
        if (!action.payload) return state;
        return {
          ...state,
          updates: state.updates.filter(({ id }) => id !== action.payload!.id),
        };
      })
      .addCase(markNotificationReadAsync.fulfilled, (state, action) => {
        if (!action.payload) return state;
        return {
          ...state,
          updates: state.updates.map((u) =>
            u.id === action.payload?.id ? action.payload : u
          ),
        };
      })
      .addCase(markSelectedNotificationReadAsync.fulfilled, (state, action) => {
        if (!action.payload) return state;
        return {
          ...state,
          updates: state.updates.map(
            // return matched update from payload or the stored update
            (u) => (action.payload || []).find(({ id }) => id === u.id) || u
          ),
        };
      });
  },
});

/* ----- selectors -----*/

export const selectSidePanel = ({ sidePanel }: RootState) => sidePanel;

export const selectSidePanelVesselCount = createSelector(
  [selectSidePanel],
  (sidePanel) => sidePanel.vesselIds.length
);

export const selectSidePanelVesselsNotPIC = createSelector(
  [
    (props: RootState) => props.settings.userInfo?.id,
    (props: RootState) => props.sidePanel.vesselIds,
    (props: RootState) => props.mapVessels.vesselsFull,
  ],
  (userInfoId, vesselIds, vesselsFull) => {
    if (!userInfoId) return false;
    return vesselIds.reduce((shouldShow, vesselId) => {
      const vessel = vesselsFull.get(vesselId);
      if (!vessel) return shouldShow;
      if (vessel.picId !== userInfoId) return true;
      return shouldShow;
    }, false);
  }
);

export const selectSidePanelVessels = createSelector(
  [
    (state: RootState) => state.sidePanel.vesselIds,
    (state: RootState) => state.mapVessels.vesselsFull,
  ],
  (vesselIds, vesselsFull) =>
    vesselIds.map((o) => vesselsFull.get(o)).filter(Boolean) as Vessel[]
);

export const selectSidePanelShowOptions = createSelector(
  [
    (state: RootState) => state.sidePanel.contentType,
    (state: RootState) => state.sidePanel.vesselIds.length,
    (state: RootState) => state.sidePanel.visible,
  ],
  (contentType, vesselLength, visible) => {
    const isVesselPanel =
      contentType === SidePanelContentType.VESSELS && Boolean(vesselLength);
    const isNotificationPanel =
      contentType === SidePanelContentType.UPDATES && visible;
    const isActive = isVesselPanel || isNotificationPanel;

    return {
      isActive, // This means that the sidepanel is rendered, even if it's just the toggle
      showToggle: isVesselPanel,
      showSidePanel: visible, // this means that the sidepanel is drawn open
      contentType,
    };
  }
);

export const selectSidePanelVesselRouteConfig = createSelector(
  [
    (state: RootState) => state.sidePanel.vesselRouteConfig,
    (_, vesselId: number) => vesselId,
  ],
  (routeConfig, vesselId) => routeConfig[vesselId] || {}
);

export const selectSidePanelVesselConfigs = createSelector(
  [
    (state: RootState) => state.sidePanel.vesselRouteConfig,
    (state: RootState) => state.sidePanel.vesselIds,
  ],
  (vesselRouteConfig, vesselIds) => {
    return Object.keys(vesselRouteConfig).reduce((selectedConfig, vesselId) => {
      if (vesselIds.includes(Number(vesselId))) {
        selectedConfig[Number(vesselId)] = vesselRouteConfig[Number(vesselId)];
      }
      return selectedConfig;
    }, {} as { [key: number]: SidePanelRouteConfig });
  }
);

export const selectSidePanelUpdates = createSelector(
  (props: RootState) => ({
    updates: props.sidePanel.updates,
    noUpdateId: props.sidePanel.noUpdateId,
  }),
  (updates) => updates
);

export const selectSidePanelUnreadNotification = createSelector(
  (state: RootState) => state.sidePanel.updates,
  (updates) =>
    updates.filter(
      (u) =>
        !u.web_notification_read_at &&
        !u.update.entity.id.includes('UNCATEGORIZED')
    ).length
);

/* ----- actions -----*/

export const {
  openFlotillaSidePanel,
  addVesselsToSidePanel,
  toggleVesselsToSidePanel,
  expandCollapsibleVesselInSidePanel,
  clearSidePanelVessels,
  removeVessel,
  setSidePanelRouteConfig,
  removeSidePanelRouteConfig,
  addNotificationUpdates,
  toggleSidePanelVesselRoute,
  closeSidePanel,
  addMobileVesselsToSidePanel,
  setVesselInSidePanel,
  updateNoUpdateId,
} = sidePanelSlice.actions;

/* ----- reducer -----*/

export default sidePanelSlice.reducer;
