import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { blue, darkBlue, fadedGreen, green, white } from 'lib/colors';
import { updateFlightSearch } from 'redux/actions';
import { selectSettings } from 'redux/selectors';
import { Flight } from 'utils/types/crew-change-types';

import SettingsModal from 'components/Settings/Modal';
import { CrewBadge } from 'components/CrewChangePanel/common';
import ConfirmModal from './ConfirmModal';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { Modals, ModalType } from './types';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_QUICKFLY_FLIGHT_TRACKING,
  TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_CLOSE,
  TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_GOTO_SETTINGS,
} from 'utils/analytics/constants';
import { ModalView } from '../AllFlightResults/types';
import { getUserActionModalType } from '../AllFlightResults/helpers';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const SelectButton = styled(Button)`
  min-height: unset;
  min-width: unset;
  margin: 0;
  margin-top: 0.5rem;
  font-size: 0.65rem;
  padding: 3px 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${white};
  background: ${blue};
  transition: all 150ms ease;
  &:hover {
    background: ${darkBlue};
  }
`;

const TrackButton = styled(SelectButton)<{ $isQuickFly?: boolean }>`
  box-shadow: none;
  ${({ $isQuickFly }) =>
    $isQuickFly
      ? `
      margin-top: 0.5rem;
      color: ${white};
      background: ${blue};
    `
      : `
      margin-top: 0;
      font-size: 0.6rem;
      padding: 3px 5px;
      letter-spacing: 1px;
      color: ${blue};
      background: ${blue}30;
  `};

  &:hover {
    color: ${white};
    background: ${green};
    box-shadow: none;
  }
  &:active {
    color: ${white};
    background: ${blue};
    box-shadow: none;
  }
`;

const TrackingBadge = styled(CrewBadge)<{ $isQuickFly?: boolean }>`
  ${({ $isQuickFly }) =>
    $isQuickFly
      ? `
      margin-top: 0.5rem;
    `
      : `
      font-size: 0.55rem;
      padding: 3px 6px;
    `};

  background: ${fadedGreen};
  letter-spacing: 0.75px;
  font-weight: 500;
`;

const TrackIcon = styled(PlayArrowIcon)`
  font-size: 8px !important;
  margin-left: 3px;
`;

type Props = {
  flight: Flight;
  trackFlight?: (flight: Flight) => void;
  isQuickFly?: boolean;
  modalView?: ModalView;
};

function FlightTrackSection({
  isQuickFly,
  modalView,
  flight,
  trackFlight,
}: Props) {
  const modalType = getUserActionModalType(modalView);
  const dispatch = useAppDispatch();
  const { trackSettings, userInfo } = useSelector(selectSettings);

  const [modals, setModals] = useState<Modals>({
    settings: false,
    confirm: false,
  });
  // check if flight source is included in disabled list
  const trackingDisabled = (userInfo?.vendorsDisabledForFRE || []).includes(
    flight.source
  );

  // hide button if flight source is disabled for tracking
  if (trackingDisabled) {
    return null;
  }

  // common method to close confirm/settings modal
  const handleModal = (type: ModalType, open: boolean) => {
    dispatch(updateFlightSearch({ listOpen: open }));
    setModals((prevModals) => ({ ...prevModals, [type]: open }));
  };

  // clicking `TRACK` button will track the flight, if settings available
  // otherwise, will open the confirm modal
  const handleClickTrack = () => {
    if (modalType) {
      trackUserAction(TRACK_QUICKFLY_FLIGHT_TRACKING(modalType), 'click', {
        flights: flight.flightNumbers,
        totalFlightTime: flight.totalFlightTime,
        arrival: flight.arrival.time,
        departure: flight.departure.time,
      });
    }
    if (trackSettings) {
      trackFlight?.(flight);
      return;
    }
    handleModal('confirm', true);
  };

  const renderModal = () => {
    if (modals.confirm) {
      return (
        <ConfirmModal
          updateModals={(value) => {
            trackUserAction(
              TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_GOTO_SETTINGS,
              'click'
            );
            setModals(value);
          }}
          setModalAction={(type, open) => {
            trackUserAction(
              TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_CLOSE,
              'click'
            );
            handleModal(type, open);
          }}
        />
      );
    }

    if (modals.settings) {
      return (
        <SettingsModal
          closeModal={() => handleModal('settings', false)}
          option="Alerts"
        />
      );
    }
  };

  return flight.tracked ? (
    <Tooltip content="This flight is being tracked" enterDelay={200}>
      <TrackingBadge
        $isQuickFly={isQuickFly}
        data-id="e2e_quickfly-flight-tracking"
      >
        Tracking
      </TrackingBadge>
    </Tooltip>
  ) : (
    <>
      <Tooltip content="Click to track this flight">
        <TrackButton
          $isQuickFly={isQuickFly}
          className="e2e_quickfly-track-button"
          variant="secondary"
          onClick={handleClickTrack}
        >
          Track
          <TrackIcon />
        </TrackButton>
      </Tooltip>
      {renderModal()}
    </>
  );
}

export default FlightTrackSection;
