import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchVesselBudgets } from 'api/flotilla';
import { setVesselBudgets } from 'redux/actions';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import useAppDispatch from './useAppDispatch';

function useVesselBudgets() {
  const dispatch = useAppDispatch();
  const vesselBudgets = useSelector(
    ({ crewChangeResources }: RootState) =>
      crewChangeResources.responses.vesselBudgets
  );
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const vesselId = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.vesselId
  );
  const {
    tableState: { step },
  } = useContext(CCPanelContext);
  // fetch vessel budgets if not already fetched
  // fetch for flights step in planning view & at the start of readOnly view
  const canFetchBudgets =
    !vesselBudgets &&
    ((activity === 'plan' && step === 'flights') || activity === 'readOnly');

  useEffect(() => {
    if (vesselId && canFetchBudgets) {
      fetchVesselBudgets(vesselId).then((response) => {
        const { success, result } = response;
        if (success && result) {
          dispatch(setVesselBudgets(result.budgets));
        }
      });
    }
  }, [vesselId, canFetchBudgets, dispatch]);
}

export default useVesselBudgets;
