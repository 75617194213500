import { Dispatch, SetStateAction } from 'react';
import { v4 as uuid } from 'uuid';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import * as Sentry from '@sentry/react';
import { severityLevelFromString } from '@sentry/utils';

import {
  AppDispatch,
  CopilotCategories,
  SidePanelContentType,
} from 'redux/types';
import {
  initStreamGpt,
  questionLatestCopilot,
  searchAirports,
  searchPorts,
  sendMessageToMM,
} from 'api/flotilla';
import {
  ExtractDraftEmailPortCallValue,
  NewSeaGptChatBlock,
  QuerySubmission,
  SeaGPTChatBlock,
  SeaGPTChatMessage,
  SeaGPTThread,
  SeaGPTThreadIdMap,
  SeaGPTPlugin,
  SeaGPTQuickFly,
  SeaGPTVivaFlightSearch,
  VivaCrewChangeInfo,
  AssistedCrewChangeConvoSummaryResp,
  AssistedCrewChangeAgentConvoResp,
} from './type';
import { CopilotCommandObject, SearchedPort, UserInfo } from 'utils/types';
import {
  openFlotillaSidePanel,
  saveGptBlock,
  setCopilotSearch,
  setSearchExpanded,
  toggleAddVessel,
  toggleCopilot,
} from 'redux/actions';
import sleep from 'lib/sleep';
import { APP_VERSION } from 'utils/constants';
import { hexToRgb } from 'utils/color-utils';
import { validateSeaGPTConversation } from './validation';

// This is currently set below the minimum value before a suggestion request will be made
// Suggestions are made after 500 ms.
const FORCE_WAIT_TIME = 300;

function replaceStartingNewLine(str: string) {
  return str.replace(/^\n/, '');
}

async function askGeneralQuestionV2({
  query,
  user,
  signal,
}: {
  query: string;
  user: UserInfo;
  signal: AbortSignal;
}): Promise<{
  success: boolean;
  message: string;
  data?: {
    caseId?: string;
    commandObject?: CopilotCommandObject;
    commandInputJson?: any;
  };
}> {
  const caseId = `general-${generate8DigitID()}`;
  const {
    success,
    command: commandObject,
    commandInputJson,
    message,
  } = await questionLatestCopilot({ query, user, caseId, signal });

  if (success) {
    return {
      success,
      message: 'OK',
      data: {
        commandObject,
        commandInputJson,
        caseId,
      },
    };
  }
  return { success: false, message };
}

type CommandExtraction = {
  commandObject: CopilotCommandObject;
  commandInputJson: any;
  dispatch: AppDispatch;
  initialMessageLength: number;
  blockId: string;
};

type NonEmailCommandResponse = {
  messages: SeaGPTChatMessage[];
  callback: (() => void) | null;
  meta?: any;
  plugin?: SeaGPTPlugin;
};

async function handleNonStreamingRequest(
  props: CommandExtraction
): Promise<NonEmailCommandResponse> {
  const command = props.commandObject.id;

  switch (command) {
    // case 'FLIGHTS':
    //   return await handleFlightCommandV2(props);
    case 'ADD_VESSEL':
      return handleAddVesselCommandV2(props);
    case 'NOTIFICATIONS_SEARCH':
      return handleNotificationSearchV2(props);
    case 'RESTRICTIONS':
      return handleRestrictionsSearchV2(props);
    case 'HOTELS':
      return handleHotelCommandV2(props);
    case 'UNKNOWN':
    default:
      return {
        messages: [
          {
            id: generateMessageId(props.blockId, props.initialMessageLength),
            createdAt: new Date().toISOString(),
            type: 'assistant',
            message: 'I am not sure what you mean. Please try again.',
          },
        ],
        callback: null,
      };
  }
}

function handleRestrictionsSearchV2({
  commandInputJson,
  commandObject,
  dispatch,
}: CommandExtraction): NonEmailCommandResponse {
  async function callback() {
    dispatch(setCopilotSearch(`restrictions: ${commandInputJson.portName}`));
    await sleep(FORCE_WAIT_TIME);
    dispatch(setSearchExpanded(true));
    dispatch(toggleCopilot());
  }

  const id = generate8DigitID();
  return {
    messages: [
      {
        id,
        createdAt: new Date().toISOString(),
        type: 'system-guide',
        message: 'Ok! Hang on as we direct you to restrictions..',
      },
    ],
    plugin: {
      name: 'port-restriction',
      messages: [
        {
          id: generateMessageId(id, 0),
          createdAt: new Date().toISOString(),
          type: 'logger',
          message: `Trying to: ${commandObject.desc}`,
        },
      ],
    },
    callback,
  };
}

function handleAddVesselCommandV2({
  commandInputJson,
  commandObject,
  dispatch,
  blockId,
  initialMessageLength,
}: CommandExtraction): NonEmailCommandResponse {
  async function callback() {
    await sleep(FORCE_WAIT_TIME);
    dispatch(toggleCopilot());
    dispatch(toggleAddVessel(commandInputJson.vesselIMO));
  }

  return {
    messages: [
      {
        id: generateMessageId(blockId, initialMessageLength + 2),
        createdAt: new Date().toISOString(),
        type: 'system-guide',
        message: 'Hang on as we direct you to add a new vessel',
      },
    ],
    plugin: {
      name: 'vessel-management',
      messages: [
        {
          id: generateMessageId(blockId, initialMessageLength),
          createdAt: new Date().toISOString(),
          type: 'logger',
          message: `Trying to: ${commandObject.desc}`,
        },
        {
          id: generateMessageId(blockId, initialMessageLength + 1),
          createdAt: new Date().toISOString(),
          type: 'logger',
          message: `Detected
          \nIMO: ${commandInputJson.vesselIMO || 'N/A'}
          \nName: ${commandInputJson.vesselName || 'N/A'}`,
        },
      ],
    },
    callback,
  };
}

// TODO: refactor sidepanel notifications to accept a search query
function handleNotificationSearchV2({
  commandInputJson,
  commandObject,
  dispatch,
  blockId,
  initialMessageLength,
}: CommandExtraction): NonEmailCommandResponse {
  async function callback() {
    dispatch(openFlotillaSidePanel(SidePanelContentType.UPDATES));
    await sleep(FORCE_WAIT_TIME);
    dispatch(toggleCopilot());
  }
  return {
    messages: [
      {
        id: generateMessageId(blockId, initialMessageLength + 1),
        createdAt: new Date().toISOString(),
        type: 'system-guide',
        message:
          'Ok! Hang on as we direct you to recent vessel and global notifications',
      },
    ],
    plugin: {
      name: 'notifications',
      messages: [
        {
          id: generateMessageId(blockId, initialMessageLength),
          createdAt: new Date().toISOString(),
          type: 'logger',
          message: `Trying to: ${commandObject.desc}`,
        },
      ],
    },
    callback,
  };
}

function handleHotelCommandV2({
  commandInputJson,
  commandObject,
  dispatch,
  initialMessageLength,
  blockId,
}: CommandExtraction): NonEmailCommandResponse {
  async function callback() {
    dispatch(setCopilotSearch(`hotels: ${commandInputJson.portName}`));
    await sleep(FORCE_WAIT_TIME);
    dispatch(setSearchExpanded(true));
    dispatch(toggleCopilot());
  }

  return {
    messages: [
      {
        type: 'system-guide',
        message: 'Ok! Hang on as we direct you to our hotels search',
        id: generateMessageId(blockId, initialMessageLength + 1),
        createdAt: new Date().toISOString(),
      },
    ],
    plugin: {
      name: 'hotel',
      messages: [
        {
          type: 'logger',
          message: `Trying to: ${commandObject.desc}`,
          id: generateMessageId(blockId, initialMessageLength),
          createdAt: new Date().toISOString(),
        },
      ],
    },
    callback,
  };
}

async function continueAgentEmailCollectionV2({
  setMessages,
  initialMessages,
  caseId,
  query,
  blockId,
  signal,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  caseId: string;
  query: string;
  blockId: string;
  signal: AbortSignal;
}): Promise<{
  success: boolean;
  agentCollectionCaseId: string | null;
  agentDraftingCaseId: string | null;
  messages: SeaGPTChatMessage[];
  crewChangeInfo: VivaCrewChangeInfo | null;
}> {
  const { success, reqId, message } = await initStreamGpt({
    caseId,
    query,
    signal,
  });

  if (success && reqId) {
    // stream email info collection
    return await streamAgentEmailCollectionV2({
      reqId,
      setMessages,
      initialMessages,
      caseId,
      blockId,
      signal,
    });
  }

  // fails
  return {
    success: false,
    agentCollectionCaseId: caseId,
    agentDraftingCaseId: null,
    crewChangeInfo: null,
    messages: [
      ...initialMessages,
      {
        id: generateMessageId(blockId, initialMessages.length),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: message || 'Failed to start agent email collection',
      },
    ],
  };
}

async function startAgentEmailCollectionV2({
  commandInputJson,
  setMessages,
  initialMessages,
  prevCaseId,
  user,
  blockId,
  signal,
}: {
  commandInputJson: any;
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  prevCaseId: string;
  user: UserInfo;
  blockId: string;
  signal: AbortSignal;
}): Promise<{
  success: boolean;
  agentCollectionCaseId: string | null;
  agentDraftingCaseId: string | null;
  messages: SeaGPTChatMessage[];
  crewChangeInfo: VivaCrewChangeInfo | null;
}> {
  const caseId = `api-${uuid()}`;
  const { success, reqId, message } = await initStreamGpt({
    caseId,
    context: {
      commandInputJson,
      user,
      prevCaseId,
    },
    signal,
  });

  if (success && reqId) {
    // stream first email response
    return await streamAgentEmailCollectionV2({
      reqId,
      setMessages,
      initialMessages,
      signal,
      blockId,
    });
  }

  // fails
  return {
    success: false,
    agentCollectionCaseId: null,
    agentDraftingCaseId: null,
    crewChangeInfo: null,
    messages: [
      ...initialMessages,
      {
        id: generateMessageId(blockId, initialMessages.length),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: message || 'Failed to start agent email collection',
      },
    ],
  };
}

/**
 * handle agent email collection
 * It will start with the caseId from command extraction,
 * A new Case ID will be created for the agent email collection
 * A new Case ID will also be created for the agent email drafting step if ready
 */
async function streamAgentEmailCollectionV2({
  reqId,
  setMessages,
  initialMessages,
  caseId,
  signal,
  blockId,
}: {
  reqId: string;
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  caseId?: string;
  signal: AbortSignal;
  blockId: string;
}): Promise<{
  success: boolean;
  agentCollectionCaseId: string | null;
  agentDraftingCaseId: string | null;
  messages: SeaGPTChatMessage[];
  crewChangeInfo: VivaCrewChangeInfo | null;
}> {
  let initialAgentEmailStreamUrl = `${process.env.REACT_APP_COPILOT_URL}/copilot/stream/v2/draft-agent-email/${reqId}`;
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    initialAgentEmailStreamUrl = `${getAPIUrl()}/api/v2/seagpt-stream/stream/v2/draft-agent-email/${reqId}`;
  }
  const initialAgentEmailStream = new EventSource(initialAgentEmailStreamUrl);
  const id = generateMessageId(blockId, initialMessages.length);
  const createdAt = new Date().toISOString();
  let initialAgentStreamData = '';
  let agentCollectionCaseId: string | null = caseId || null;
  let agentDraftingCaseId: string | null = null;
  let crewChangeInfo: VivaCrewChangeInfo | null = null;
  return new Promise((resolve) => {
    let timeout = setTimeout(() => {
      initialAgentEmailStream.close();
      resolve({
        success: false,
        agentCollectionCaseId,
        agentDraftingCaseId,
        crewChangeInfo,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialAgentStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like this request is taking longer than usual, please try again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    }, 120000); // 2 minute timeout

    signal.onabort = () => {
      clearTimeout(timeout);
      initialAgentEmailStream.close();
      resolve({
        success: false,
        agentCollectionCaseId,
        agentDraftingCaseId,
        crewChangeInfo,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialAgentStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Request Aborted.`,
          },
        ],
      });
    };
    initialAgentEmailStream.onerror = (e) => {
      console.error(e);
      Sentry.captureEvent(
        {
          message: `Connection failure for initial agent email collection to ${caseId}`,
          level: severityLevelFromString('error'),
        },
        { data: e }
      );

      clearTimeout(timeout);
      initialAgentEmailStream.close();
      resolve({
        success: false,
        agentCollectionCaseId,
        agentDraftingCaseId,
        crewChangeInfo,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialAgentStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like your connection failed, please resubmit your request again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    };
    initialAgentEmailStream.onmessage = (e) => {
      try {
        const { token, type, caseId, errorType, newConversationId, jsonData } =
          JSON.parse(e.data);
        if (type === 'newCaseId' && caseId) {
          agentCollectionCaseId = caseId as string;
        } else if (type === 'error') {
          clearTimeout(timeout);
          initialAgentEmailStream.close();
          resolve({
            success: false,
            agentCollectionCaseId,
            agentDraftingCaseId,
            crewChangeInfo,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialAgentStreamData,
                id,
                createdAt,
              },
              {
                id: generateMessageId(blockId, initialMessages.length + 1),
                createdAt: new Date().toISOString(),
                type: 'logger',
                message: `Oops, an error occured ${errorType || ''}`,
              },
            ],
          });
        } else if (type === 'finishedTalking') {
          clearTimeout(timeout);
          initialAgentEmailStream.close();
          resolve({
            success: true,
            agentCollectionCaseId,
            agentDraftingCaseId,
            crewChangeInfo,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialAgentStreamData,
                id,
                createdAt,
              },
            ],
          });
        } else if (type === 'handover') {
          if (newConversationId) {
            agentDraftingCaseId = newConversationId as string;
          }
        } else if (type === 'token') {
          initialAgentStreamData += token;
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialAgentStreamData,
              id,
              createdAt,
            },
          ]);
        } else if (type === 'jsonData') {
          crewChangeInfo = jsonData as VivaCrewChangeInfo;
        }
      } catch (error) {
        clearTimeout(timeout);
        resolve({
          success: false,
          agentCollectionCaseId,
          agentDraftingCaseId,
          crewChangeInfo,
          messages: [
            ...initialMessages,
            {
              type: 'assistant',
              message: initialAgentStreamData,
              id,
              createdAt,
            },
            {
              id: generateMessageId(blockId, initialMessages.length + 1),
              createdAt: new Date().toISOString(),
              type: 'logger',
              message: `An unknown error occured`,
            },
          ],
        });
      }
    };
  });
}

async function startAgentEmailDraftV2({
  setMessages,
  initialMessages,
  caseId,
  query,
  blockId,
  signal,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  caseId: string;
  query?: string;
  blockId: string;
  signal: AbortSignal;
}): Promise<{
  success: boolean;
  messages: SeaGPTChatMessage[];
  meta: null | {
    storePortOfCall: ExtractDraftEmailPortCallValue | undefined;
    emailJSON: any;
  };
}> {
  const { success, reqId, message } = await initStreamGpt({
    caseId,
    query,
    signal,
  });

  if (success && reqId) {
    // stream first email response
    return await streamAgentEmailDraftV2({
      caseId,
      reqId,
      setMessages,
      initialMessages,
      signal,
      blockId,
    });
  }

  // fails
  return {
    success: false,
    messages: [
      ...initialMessages,
      {
        id: generateMessageId(blockId, initialMessages.length + 1),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: message || 'Failed to start agent email drafting',
      },
    ],
    meta: null,
  };
}

// The new case id is provided from the previous step
function streamAgentEmailDraftV2({
  setMessages,
  initialMessages,
  reqId,
  blockId,
  signal,
  caseId,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  reqId: string;
  blockId: string;
  signal: AbortSignal;
  caseId: string;
}): Promise<{
  success: boolean;
  meta: null | {
    storePortOfCall: ExtractDraftEmailPortCallValue | undefined;
    emailJSON: any;
  };
  messages: SeaGPTChatMessage[];
}> {
  let initialAgentEmailStreamUrl = `${process.env.REACT_APP_COPILOT_URL}/copilot/stream/v2/draft-agent-email/${reqId}`;
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    initialAgentEmailStreamUrl = `${getAPIUrl()}/api/v2/seagpt-stream/stream/v2/draft-agent-email/${reqId}`;
  }
  const initialAgentEmailStream = new EventSource(initialAgentEmailStreamUrl);
  const id = generateMessageId(blockId, initialMessages.length);
  const createdAt = new Date().toISOString();
  let initialDraftingEmailStreamData = '';
  let storePortOfCall: ExtractDraftEmailPortCallValue | undefined;
  let emailJSON: any = null;
  return new Promise((resolve) => {
    let timeout = setTimeout(() => {
      initialAgentEmailStream.close();
      resolve({
        success: false,
        meta: null,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialDraftingEmailStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like this request is taking longer than usual, please try again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    }, 120000); // 2 minute timeout
    signal.onabort = () => {
      clearTimeout(timeout);
      initialAgentEmailStream.close();
      resolve({
        success: false,
        meta: null,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialDraftingEmailStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Request Aborted.`,
          },
        ],
      });
    };
    initialAgentEmailStream.onerror = (e) => {
      console.error(e);
      Sentry.captureEvent(
        {
          message: `Connection failure for agent email draft to ${caseId}`,
          level: severityLevelFromString('error'),
        },
        { data: e }
      );

      clearTimeout(timeout);
      initialAgentEmailStream.close();
      resolve({
        success: false,
        meta: null,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialDraftingEmailStreamData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like your connection failed, please resubmit your request again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    };
    initialAgentEmailStream.onmessage = (e) => {
      try {
        const { token, type, extractedJSONEmail, errorType, portOfCall } =
          JSON.parse(e.data);
        if (
          type === 'error' &&
          errorType !== 'PORT_OF_CALL_EXTRACTION_FAILED'
        ) {
          clearTimeout(timeout);
          initialAgentEmailStream.close();
          resolve({
            success: false,
            meta: null,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialDraftingEmailStreamData,
                id,
                createdAt,
              },
              {
                id: generateMessageId(blockId, initialMessages.length + 1),
                createdAt: new Date().toISOString(),
                type: 'logger',
                message: `Oops, an error occured ${errorType || ''}`,
              },
            ],
          });
        } else if (type === 'completedExtractPortOfCall' && portOfCall) {
          try {
            const { portOfCallName, portOfCallLocode } = JSON.parse(
              portOfCall
            ) as ExtractDraftEmailPortCallValue;
            storePortOfCall = { portOfCallName, portOfCallLocode };
          } catch (error) {
            console.error('Failed to parse', portOfCall);
          }
        } else if (type === 'startedEmailExtraction') {
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialDraftingEmailStreamData,
              id,
              createdAt,
            },
            {
              id: generateMessageId(blockId, initialMessages.length + 1),
              createdAt: new Date().toISOString(),
              type: 'logger',
              message: 'Processing email...',
            },
          ]);
        } else if (type === 'completedEmailExtraction') {
          clearTimeout(timeout);
          initialAgentEmailStream.close();
          if (extractedJSONEmail) {
            emailJSON = extractedJSONEmail;
          }
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialDraftingEmailStreamData,
              id,
              createdAt,
            },
          ]);

          resolve({
            success: true,
            meta: {
              storePortOfCall,
              emailJSON,
            },
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialDraftingEmailStreamData,
                id,
                createdAt,
              },
            ],
          });
        } else if (type === 'token') {
          initialDraftingEmailStreamData += token;
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialDraftingEmailStreamData,
              id,
              createdAt,
            },
          ]);
        }
      } catch (error) {
        console.log('Fail...', (error as any).message);
      }
    };
  });
}

function streamWebSearch({
  setMessages,
  initialMessages,
  reqId,
  blockId,
  signal,
  caseId,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  reqId: string;
  blockId: string;
  signal: AbortSignal;
  caseId: string;
}): Promise<{
  success: boolean;
  messages: SeaGPTChatMessage[];
  pluginMessages: SeaGPTChatMessage[];
}> {
  let initialWebSearchStreamUrl = `${process.env.REACT_APP_COPILOT_URL}/copilot/stream/web-search/${reqId}`;
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    initialWebSearchStreamUrl = `${getAPIUrl()}/api/v2/seagpt-stream/stream/web-search/${reqId}`;
  }
  const intialWebSearchStream = new EventSource(initialWebSearchStreamUrl);
  const id = generateMessageId(blockId, initialMessages.length);
  const createdAt = new Date().toISOString();
  let initialWebSearchData = '';
  let pluginMessages: SeaGPTChatMessage[] = [];
  return new Promise((resolve) => {
    let timeout = setTimeout(() => {
      intialWebSearchStream.close();
      resolve({
        success: false,
        pluginMessages,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialWebSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like this request is taking longer than usual, please try again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    }, 120000); // 2 minute timeout

    signal.onabort = () => {
      clearTimeout(timeout);
      intialWebSearchStream.close();
      resolve({
        success: false,
        pluginMessages,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialWebSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Request Aborted.`,
          },
        ],
      });
    };

    intialWebSearchStream.onerror = (e) => {
      console.error(e);
      Sentry.captureEvent(
        {
          message: `Connection failure for web search to ${caseId}`,
          level: severityLevelFromString('error'),
        },
        { data: e }
      );

      clearTimeout(timeout);
      intialWebSearchStream.close();
      resolve({
        success: false,
        pluginMessages,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialWebSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like your connection failed, please resubmit your request again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    };

    intialWebSearchStream.onmessage = (e) => {
      try {
        const { token, type, errorType, context, finishedTalking } = JSON.parse(
          e.data
        );

        if (type === 'token') {
          initialWebSearchData += token;
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialWebSearchData,
              id,
              createdAt,
            },
          ]);
        } else if (type === 'error') {
          clearTimeout(timeout);
          intialWebSearchStream.close();
          resolve({
            success: false,
            pluginMessages,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialWebSearchData,
                id,
                createdAt,
              },
              {
                id: generateMessageId(blockId, initialMessages.length + 1),
                createdAt: new Date().toISOString(),
                type: 'logger',
                message: `Oops, an error occured ${errorType || ''}`,
              },
            ],
          });
        } else if (type === 'context') {
          pluginMessages.push({
            id: generatePluginMessageId(blockId, 0),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Searching: ${context}`,
          });
        } else if (type === 'finishedTalking') {
          clearTimeout(timeout);
          intialWebSearchStream.close();
          resolve({
            success: true,
            pluginMessages,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: finishedTalking,
                id,
                createdAt,
              },
            ],
          });
        }
      } catch (error) {
        clearTimeout(timeout);
        resolve({
          success: false,
          pluginMessages,
          messages: [
            ...initialMessages,
            {
              type: 'assistant',
              message: initialWebSearchData,
              id,
              createdAt,
            },
            {
              id: generateMessageId(blockId, initialMessages.length + 1),
              createdAt: new Date().toISOString(),
              type: 'logger',
              message: `An unknown error occured`,
            },
          ],
        });
      }
    };
  });
}

async function startWebSearch({
  setMessages,
  initialMessages,
  caseId,
  query,
  blockId,
  signal,
  user,
  commandInputJson,
  prevCaseId,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  caseId?: string;
  query: string;
  blockId: string;
  signal: AbortSignal;
  user: UserInfo;
  commandInputJson?: any;
  prevCaseId?: string;
}): Promise<{
  success: boolean;
  messages: SeaGPTChatMessage[];
  pluginMessages: SeaGPTChatMessage[];
  caseId: string;
}> {
  const useCaseId = caseId || `web-${generate8DigitID()}`;
  const { success, reqId, message } = await initStreamGpt({
    caseId: useCaseId,
    query,
    context: { user, commandInputJson, prevCaseId },
    type: 'web-search',
    signal,
  });

  if (success && reqId) {
    // stream web search response
    return {
      ...(await streamWebSearch({
        reqId,
        caseId: useCaseId,
        setMessages,
        initialMessages,
        signal,
        blockId,
      })),
      caseId: useCaseId,
    };
  }

  return {
    success: false,
    caseId: useCaseId,
    pluginMessages: [],
    messages: [
      ...initialMessages,
      {
        id: generateMessageId(blockId, initialMessages.length + 1),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: message || 'Failed to start web search',
      },
    ],
  };
}

async function startFlightSearch({
  setMessages,
  initialMessages,
  caseId,
  query,
  blockId,
  signal,
  user,
  commandInputJson,
  prevCaseId,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  caseId?: string;
  query: string;
  blockId: string;
  signal: AbortSignal;
  user: UserInfo;
  commandInputJson?: any;
  prevCaseId?: string;
}): Promise<{
  success: boolean;
  messages: SeaGPTChatMessage[];
  pluginMessages?: SeaGPTChatMessage[];
  caseId: string;
  meta?: SeaGPTQuickFly;
}> {
  const useCaseId = caseId || `flight-${generate8DigitID()}`;
  const { success, reqId, message } = await initStreamGpt({
    caseId: useCaseId,
    query,
    context: { user, commandInputJson, prevCaseId },
    type: 'flight-search',
    signal,
  });

  if (success && reqId) {
    // stream flight search response

    const { meta, ...flightSearchResponse } = await streamFlightSearch({
      caseId: useCaseId,
      reqId,
      setMessages,
      initialMessages,
      signal,
      blockId,
    });
    if (!meta) {
      return { ...flightSearchResponse, caseId: useCaseId };
    }

    const { startAirportNameOrIATACode, endAirportNameOrIATACode, startDate } =
      meta;
    // Retrieve rest of results
    const [start, end] = await Promise.all([
      startAirportNameOrIATACode &&
        (await searchAirports(startAirportNameOrIATACode)),
      endAirportNameOrIATACode &&
        (await searchAirports(endAirportNameOrIATACode)),
    ]);
    let departure: null | AirportCommon = null,
      arrival: null | AirportCommon = null;
    if (start) {
      departure = start[0];
      flightSearchResponse.pluginMessages?.push({
        id: generatePluginMessageId(
          blockId,
          flightSearchResponse.pluginMessages?.length || 0
        ),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: `Departing from ${departure.name} (${departure.iataCode})`,
      });
    }
    if (end) {
      arrival = end[0];
      flightSearchResponse.pluginMessages?.push({
        id: generatePluginMessageId(
          blockId,
          flightSearchResponse.pluginMessages?.length || 0
        ),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: `Arriving at ${arrival.name} (${arrival.iataCode})`,
      });
    }

    return {
      ...flightSearchResponse,
      meta: {
        startAirportNameOrIATACode,
        endAirportNameOrIATACode,
        departure,
        arrival,
        date: startDate,
      },
      caseId: useCaseId,
    };
  }

  return {
    success: false,
    caseId: useCaseId,
    messages: [
      ...initialMessages,
      {
        id: generateMessageId(blockId, initialMessages.length + 1),
        createdAt: new Date().toISOString(),
        type: 'logger',
        message: message || 'Failed to start flight search',
      },
    ],
  };
}

function streamFlightSearch({
  setMessages,
  initialMessages,
  reqId,
  blockId,
  signal,
  caseId,
}: {
  setMessages: Dispatch<SetStateAction<SeaGPTChatMessage[]>>;
  initialMessages: SeaGPTChatMessage[];
  reqId: string;
  blockId: string;
  signal: AbortSignal;
  caseId: string;
}): Promise<{
  success: boolean;
  messages: SeaGPTChatMessage[];
  pluginMessages?: SeaGPTChatMessage[];
  meta?: SeaGPTVivaFlightSearch;
}> {
  let initialFlightSearchStreamUrl = `${process.env.REACT_APP_COPILOT_URL}/copilot/stream/flight-search/${reqId}`;
  if (process.env.REACT_APP_COPILOT_V2 === 'true') {
    initialFlightSearchStreamUrl = `${getAPIUrl()}/api/v2/seagpt-stream/stream/flight-search/${reqId}`;
  }
  const intialFlightSearchStream = new EventSource(
    initialFlightSearchStreamUrl
  );
  const id = generateMessageId(blockId, initialMessages.length);
  const createdAt = new Date().toISOString();
  let initialFlightSearchData = '';
  let flightSearchMeta: SeaGPTVivaFlightSearch | null = null;
  let dataIsReady: boolean | undefined;
  let pluginMessages: SeaGPTChatMessage[] = [];
  return new Promise((resolve) => {
    let timeout = setTimeout(() => {
      intialFlightSearchStream.close();
      resolve({
        success: false,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialFlightSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like this request is taking longer than usual, please try again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    }, 120000); // 2 minute timeout

    signal.onabort = () => {
      clearTimeout(timeout);
      intialFlightSearchStream.close();
      resolve({
        success: false,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialFlightSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Request Aborted.`,
          },
        ],
      });
    };

    intialFlightSearchStream.onerror = (e) => {
      console.error(e);
      Sentry.captureEvent(
        {
          message: `Connection failure for flight search to ${caseId}`,
          level: severityLevelFromString('error'),
        },
        { data: e }
      );
      clearTimeout(timeout);
      intialFlightSearchStream.close();
      resolve({
        success: false,
        messages: [
          ...initialMessages,
          {
            type: 'assistant',
            message: initialFlightSearchData,
            id,
            createdAt,
          },
          {
            id: generateMessageId(blockId, initialMessages.length + 1),
            createdAt: new Date().toISOString(),
            type: 'logger',
            message: `Oops, it looks like your connection failed, please resubmit your request again. If this error persists, please copy your Chat ID and contact the support team.`,
          },
        ],
      });
    };

    intialFlightSearchStream.onmessage = (e) => {
      try {
        const { type, errorType, jsonData, token, dataReady } = JSON.parse(
          e.data
        );

        if (type === 'questionToken') {
          initialFlightSearchData += token;
          setMessages([
            ...initialMessages,
            {
              type: 'assistant',
              message: initialFlightSearchData,
              id,
              createdAt,
            },
          ]);
        } else if (type === 'dataReadyState') {
          if (dataIsReady === undefined) {
            dataIsReady = dataReady;
            if (dataReady) {
              pluginMessages.push({
                id: generatePluginMessageId(blockId, 0),
                createdAt: new Date().toISOString(),
                type: 'logger',
                message: `Obtained all arrival, departure and date information`,
              });
            }
          }
        } else if (type === 'jsonData') {
          flightSearchMeta = jsonData;
        } else if (type === 'error') {
          clearTimeout(timeout);
          intialFlightSearchStream.close();
          resolve({
            success: false,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialFlightSearchData,
                id,
                createdAt,
              },
              {
                id: generateMessageId(blockId, initialMessages.length + 1),
                createdAt: new Date().toISOString(),
                type: 'logger',
                message: `Oops, an error occured ${errorType || ''}`,
              },
            ],
          });
        } else if (type === 'finishedTalking') {
          clearTimeout(timeout);
          intialFlightSearchStream.close();
          resolve({
            success: true,
            pluginMessages: dataIsReady ? pluginMessages : undefined,
            meta: dataIsReady
              ? (flightSearchMeta as SeaGPTVivaFlightSearch)
              : undefined,
            messages: [
              ...initialMessages,
              {
                type: 'assistant',
                message: initialFlightSearchData,
                id,
                createdAt,
              },
            ],
          });
        }
      } catch (error) {
        clearTimeout(timeout);
        resolve({
          success: false,
          messages: [
            ...initialMessages,
            {
              type: 'assistant',
              message: initialFlightSearchData,
              id,
              createdAt,
            },
            {
              id: generateMessageId(blockId, initialMessages.length + 1),
              createdAt: new Date().toISOString(),
              type: 'logger',
              message: `An unknown error occured`,
            },
          ],
        });
      }
    };
  });
}

const processPortOfCall = async ({
  portOfCallName,
  portOfCallLocode,
}: ExtractDraftEmailPortCallValue): Promise<SearchedPort | null> => {
  const term = portOfCallLocode || portOfCallName;
  return searchPorts(term)
    .then(({ success, portsResponse }) => {
      if (success && portsResponse) {
        if (portsResponse.pagination.totalCount > 0) {
          return portsResponse.results[0];
        }
      }
      return null;
    })
    .catch(() => null);
};

function generate8DigitID() {
  return uuid().substring(0, 8);
}

function generateMessageId(blockId: string, index: number) {
  return `${blockId}-${index || 0}`;
}

function generatePluginMessageId(blockId: string, index: number) {
  return `${blockId}-plugin-${index || 0}`;
}

function seaGptFormSubmission(
  dispatch: AppDispatch,
  values: QuerySubmission,
  userInfo: UserInfo,
  category: CopilotCategories,
  caseId: string | null,
  activeThread: SeaGPTThread | null
) {
  // form submission state is handled within the chat block component that is currently running
  const blockId = generate8DigitID();
  const newMessage: SeaGPTChatMessage = {
    id: generateMessageId(blockId, 0),
    type: 'user',
    message: String(values.query).trim(),
    createdAt: new Date().toISOString(),
    isSuggestion: values.isSuggestion,
  };

  if (category === 'web-search') {
    dispatch(
      saveGptBlock({
        threadId: activeThread?.id,
        isNew: false,
        id: blockId,
        handover: false,
        isAudio: values.audio,
        category,
        caseId: caseId || undefined,
        createdAt: new Date().toISOString(),
        messages: [newMessage],
      })
    );
    dispatch(
      saveGptBlock({
        threadId: activeThread?.id,
        isNew: true,
        id: generate8DigitID(),
        handover: true,
        isAudio: values.audio,
        category,
        caseId: caseId || undefined,
        createdAt: new Date().toISOString(),
        messages: [],
        meta: {
          websearch: values.query,
        },
        plugin: {
          name: 'web-search',
          messages: [],
        },
      })
    );
  } else {
    dispatch(
      saveGptBlock({
        threadId: activeThread?.id,
        isNew: true,
        id: blockId,
        handover: false,
        isAudio: values.audio,
        category,
        caseId: caseId || undefined,
        createdAt: new Date().toISOString(),
        messages: [newMessage],
      })
    );
  }
  // Send to MM
  sendMessageToMM({
    isAudio: values.audio,
    user: userInfo,
    messages: [newMessage],
    caseId: caseId || undefined,
  });
}

function generateNewConversation(title?: string): SeaGPTThread {
  const newConvoId = generate8DigitID();
  return {
    id: newConvoId,
    createdAt: new Date().toISOString(),
    title: title || 'Your first Thread',
    blocks: [],
    version: APP_VERSION,
    isNew: true,
    pinnedOn: null,
  };
}

function visualizeSineWave(
  analyser: AnalyserNode,
  canvas: HTMLCanvasElement,
  backgroundColor: string,
  strokeColor: string
) {
  const canvasCtx = canvas.getContext('2d')!;
  const { width, height } = canvas;

  let running = true;

  const bufferLength = analyser.fftSize;
  const dataArray = new Uint8Array(bufferLength);

  canvasCtx.clearRect(0, 0, width, height);

  function stop() {
    running = false;
  }

  function draw() {
    if (!running) return;
    requestAnimationFrame(draw);

    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = backgroundColor;
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = strokeColor;

    canvasCtx.beginPath();

    const sliceWidth = (width * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * height) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  }

  draw();
  return stop;
}

function visualizeFrequencyBars(
  analyser: AnalyserNode,
  canvas: HTMLCanvasElement,
  backgroundColor: string,
  strokeColor: string
) {
  const canvasCtx = canvas.getContext('2d')!;
  analyser.fftSize = 256;
  const { width, height } = canvas;
  const halfWidth = width / 2;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  const barWidth = width / bufferLength / 2;
  const barHeightPixel = height / 255 / 3;
  let running = true;

  canvasCtx.clearRect(0, 0, width, height);

  function stop() {
    running = false;
  }

  function draw() {
    if (!running) return;
    requestAnimationFrame(draw);
    analyser.getByteFrequencyData(dataArray);

    canvasCtx.fillStyle = backgroundColor;
    canvasCtx.fillRect(0, 0, width, height);

    for (let i = 0; i < bufferLength; i++) {
      const barHeight = dataArray[i] * barHeightPixel;
      const rgb = hexToRgb(strokeColor);
      if (!rgb) return;
      const color = `rgb(${Math.min(
        255,
        Math.round((height - barHeight) * 1.2)
      )}, ${Math.round(((halfWidth + barWidth * i) / width) * 255)}, ${rgb.b})`;
      canvasCtx.fillStyle = color;
      // right side
      canvasCtx.fillRect(
        halfWidth + barWidth * i + 1,
        height / 2 - barHeight,
        barWidth,
        barHeight * 2
      );
      // left side
      canvasCtx.fillRect(
        halfWidth - barWidth * i + 1,
        height / 2 - barHeight,
        barWidth,
        barHeight * 2
      );
    }
    return stop;
  }

  draw();
  return stop;
}

function initThreadHistory(userId: number): SeaGPTThreadIdMap {
  const newId = generate8DigitID();
  const defaultIdMap = {
    [userId]: {
      [newId]: {
        id: newId,
        createdAt: new Date().toISOString(),
        title: 'Your first Thread',
        blocks: [],
        version: APP_VERSION,
        isNew: true,
      },
    },
  };

  try {
    // Check if there is a chat conversation history in local storage
    const existingConversationRaw = localStorage.getItem(
      'threadHistory'
    ) as string;
    if (existingConversationRaw) {
      const parsed = JSON.parse(existingConversationRaw);
      if (parsed[userId]) {
        try {
          const validated = Object.values(
            parsed[userId] as SeaGPTThreadIdMap
          ).reduce<SeaGPTThreadIdMap>((combined, conversation) => {
            const checkIfValid = validateSeaGPTConversation(conversation);
            if (checkIfValid) {
              combined[conversation.id] = conversation;
            }
            return combined;
          }, {});
          if (parsed[userId]['emailUpdates']) {
            delete parsed[userId]['emailUpdates'];
            localStorage.setItem('threadHistory', JSON.stringify(parsed));
          }
          return Object.keys(validated).length
            ? validated
            : defaultIdMap[userId];
        } catch (error) {}
      } else {
        console.log('Doesnt exist for this user');
        parsed[userId] = defaultIdMap[userId];
        localStorage.setItem('threadHistory', JSON.stringify(parsed));
      }
    } else {
      const existingRaw = localStorage.getItem('chatHistoryBlock') as string;
      if (existingRaw) {
        const parsed: {
          blocks: (SeaGPTChatBlock | NewSeaGptChatBlock)[];
          userId: number;
        } = JSON.parse(existingRaw);
        if (parsed.userId === userId) {
          const newId = generate8DigitID();
          const migratedConversations = {
            [userId]: {
              [newId]: {
                id: newId,
                createdAt: new Date().toISOString(),
                title: 'Your first Thread',
                blocks: parsed.blocks,
                version: APP_VERSION,
                isNew: false,
              },
            },
          };
          localStorage.setItem(
            'threadHistory',
            JSON.stringify(migratedConversations)
          );
          return migratedConversations[userId];
        } else {
          localStorage.setItem('threadHistory', JSON.stringify(defaultIdMap));
        }
        localStorage.removeItem('chatHistoryBlock');
      } else {
        localStorage.setItem('threadHistory', JSON.stringify(defaultIdMap));
      }
    }
    return defaultIdMap[userId];
  } catch (error) {
    return defaultIdMap[userId];
  }
}

function joinArray(array: string[]) {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(' and ');
  } else {
    const last = array.slice(-1);
    const rest = array.slice(0, -1);
    return `${rest.join(', ')} and ${last}`;
  }
}

function fixLatestResultsCostString(data: AssistedCrewChangeConvoSummaryResp) {
  const { latestResults } = data;
  if (typeof latestResults?.appliedCosts?.appliedAgencyCosts === 'string') {
    latestResults.appliedCosts.appliedAgencyCosts = null;
    return Object.assign({}, data, { latestResults });
  }
  return data;
}

function fixConvoLatestResultsCostString(
  data: AssistedCrewChangeAgentConvoResp
) {
  const { latestResults } = data.conversation;
  if (typeof latestResults?.appliedCosts?.appliedAgencyCosts === 'string') {
    latestResults.appliedCosts.appliedAgencyCosts = null;
    return Object.assign({}, data, {
      conversation: {
        ...data.conversation,
        latestResults,
      },
    });
  }
  return data;
}

export {
  fixConvoLatestResultsCostString,
  fixLatestResultsCostString,
  joinArray,
  initThreadHistory,
  visualizeSineWave,
  visualizeFrequencyBars,
  startFlightSearch,
  streamFlightSearch,
  generateNewConversation,
  generatePluginMessageId,
  generate8DigitID,
  generateMessageId,
  processPortOfCall,
  replaceStartingNewLine,
  askGeneralQuestionV2,
  handleNonStreamingRequest,
  startAgentEmailCollectionV2,
  continueAgentEmailCollectionV2,
  startAgentEmailDraftV2,
  startWebSearch,
  seaGptFormSubmission,
};
