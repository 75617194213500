import styled from 'styled-components/macro';

import { iconGray, midBlue, textGray } from 'lib/colors';
import { Dropdown } from 'components/shared';
import { RestrictionSourceType } from '@greywing-maritime/frontend-library/dist/types/proxPorts';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div<{ $isMobile: boolean; $noSource: boolean }>`
  width: 100%;
  ${({ $isMobile, $noSource }) =>
    !$isMobile &&
    !$noSource &&
    `
    display: grid;
    grid-template-columns: 1fr 4fr;
  `};
`;

export const SearchWrapper = styled.div`
  margin: 0.5rem;
  margin-left: 0.75rem;
`;

export const SourcesWrapper = styled(FlexWrapper)`
  margin: 0.5rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const Sidebar = styled.div`
  width: 100%;
  border-right: 1px solid ${iconGray};
  padding: 0.5rem;
`;

export const SidebarContent = styled.div`
  position: sticky;
  z-index: 99;
  top: 0.5rem;
`;

export const SidebarTitle = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 0.75rem;
  margin-bottom: 0.5rem;
`;

export const ItemWrapper = styled.div<{ $active?: boolean }>`
  cursor: pointer;
  padding: 0.75rem;
  transition: all 150ms ease;
  ${({ $active }) =>
    $active &&
    `
    background: ${midBlue};
    border-radius: 0.25rem;
  `}
  &:hover {
    background: ${midBlue};
    border-radius: 0.25rem;
  }
`;

export const SourceName = styled.div<{ $active: boolean }>`
  font-size: 0.85rem;
  letter-spacing: 0.05rem;
  font-weight: bold;
  ${({ $active }) =>
    !$active &&
    `
    color: ${textGray}
  `};
`;

export const DateText = styled.div<{ $active: boolean }>`
  font-size: 0.8rem;
  margin-top: 0.2rem;
  ${({ $active }) =>
    !$active &&
    `
    color: ${textGray}
  `};
`;

export const Header = styled.div`
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const EmptyWrapper = styled.div`
  margin: 80px auto;
  text-align: center;
`;

export const Main = styled.div`
  margin-top: -10px;
  font-size: 0.9rem;
  line-height: 1.5rem;
`;

export const ContentWrapper = styled.div`
  padding: 0.5rem;
`;

type SourcesProps = {
  isMobile: boolean;
  sources: RestrictionSourceType[];
  selected: RestrictionSourceType | undefined;
  setSelected: (source: RestrictionSourceType | undefined) => void;
  searchComponent?: React.ReactNode;
};

export const Sources = ({
  isMobile,
  sources,
  selected,
  setSelected,
  searchComponent,
}: SourcesProps) => {
  if (!isMobile || sources.length < 2) return null;

  const dropdownStyles = { minWidth: '120px', ml: '0.5rem' };
  return (
    <>
      {Boolean(searchComponent) && (
        <SearchWrapper>{searchComponent}</SearchWrapper>
      )}
      <SourcesWrapper>
        Select the restrictions source:
        <Dropdown
          capitalize
          options={sources}
          value={selected || ''}
          fontSize="0.8rem"
          formStyles={dropdownStyles}
          onChange={({ target }) => {
            const newSelected = target.value.toLowerCase();
            setSelected(newSelected as RestrictionSourceType);
          }}
        />
      </SourcesWrapper>
    </>
  );
};
