import { ReactNode, memo } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import styled from 'styled-components/macro';

import { blue, midBlue } from 'lib/colors';
import { StyledButton } from 'components/CrewChangePanel/common';

const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem 1.5rem;
`;

const Wrapper = styled.div`
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.4rem 1rem;
`;

const CancelButton = styled(StyledButton)`
  background: ${midBlue};
  color: ${blue};
  border: none;
`;

type GlobalConfirmModalProps = {
  // type of the modal - whether it asserts or removes something, or just closes the modal
  type?: 'assert' | 'remove' | 'close';
  title?: string;
  loading?: boolean; // state indicating the disabled state of the action button
  description: ReactNode;
  onConfirm: () => Promise<void> | void; // confirm or remove action
  onCancel?: () => void;
  children?: React.ReactNode;
};

// Intended to work common modal for cleaner experience, to confirm something
function ConfirmModal({
  type = 'assert',
  title,
  loading,
  description,
  onConfirm,
  onCancel,
  children,
}: GlobalConfirmModalProps) {
  const isDeleteModal = type === 'remove';

  return (
    // prevent event propagation (capturing)
    <Dialog maxWidth="xs" open onClick={(e) => e.stopPropagation()}>
      <Title data-id="e2e_confirm-modal-title">
        {title || 'Are you sure?'}
      </Title>

      <DialogContent dividers>
        <Wrapper data-id="e2e_confirm-modal-content">
          {description}
          {children ? children : null}
        </Wrapper>
      </DialogContent>

      <DialogActions>
        <ButtonsWrapper>
          {onCancel ? (
            <CancelButton onClick={onCancel}>Cancel</CancelButton>
          ) : (
            <div />
          )}
          <StyledButton
            className="e2e_confirm-modal-action"
            variant={isDeleteModal ? 'delete' : 'primary'}
            disabled={loading}
            onClick={onConfirm}
          >
            {(isDeleteModal && 'Remove') ||
              (type === 'assert' ? 'Confirm' : 'Close')}
          </StyledButton>
        </ButtonsWrapper>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ConfirmModal);
