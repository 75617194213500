import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';

import { useAppDispatch } from 'hooks';
import { setUserAuth } from 'redux/actions';
import { selectUserAuth } from 'redux/selectors';
import { handoverPlatform } from 'utils/handover';
import { authenticateUser, logoutUser } from 'utils/auth';
import { initializeAppSettings } from 'utils/settings';

import ErrorBoundary from 'components/ErrorBoundary';

function Authenticated() {
  const dispatch = useAppDispatch();
  const { isAuthed } = useSelector(selectUserAuth);

  const navigate = useNavigate();
  const { hash } = useLocation();

  /* check for authentication */
  useEffect(() => {
    // TODO: remove this check when handover from v1 is decommissioned
    if (hash.length) {
      handoverPlatform(hash, navigate);
    }
    Promise.all([
      authenticateUser(dispatch),
      // initialize app settings with backend/local-storage data
      initializeAppSettings(dispatch),
    ]).then((result) => {
      const authSuccess = result.every(Boolean);
      if (!authSuccess) {
        logoutUser(dispatch);
        return;
      }
      dispatch(setUserAuth(authSuccess));
    });
  }, []); // eslint-disable-line

  return isAuthed ? (
    <ErrorBoundary>
      <Suspense fallback={<></>}>
        <DndProvider backend={HTML5Backend}>
          <Outlet />
        </DndProvider>
      </Suspense>
    </ErrorBoundary>
  ) : (
    <Navigate to="/login" />
  );
}

export default Authenticated;
