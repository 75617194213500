import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import sleep from 'lib/sleep';
import { CREW_CHANGE_CANCEL_MESSAGE } from 'lib/constants';
import { selectCrewChangeEvent } from 'redux/actions';
import { selectCrewChangePanel } from 'redux/selectors';
import { CrewChangeActivityType } from 'utils/types/crew-change-types';
import useAppDispatch from './useAppDispatch';

type ActionProps = {
  activity: CrewChangeActivityType;
  isOneClickPlan?: boolean;
};

type HookProps = {
  vesselId: number;
  event: CrewEvent;
};

// hook to handle selecting a crew change event details
function useCrewChangeEvent({ vesselId, event }: HookProps) {
  const dispatch = useAppDispatch();
  const {
    active: activity,
    event: crewChangeEvent,
    readOnlyPlanningData: reportData,
  } = useSelector(selectCrewChangePanel);

  const isCCPanelOpen = useMemo(
    () =>
      (activity === 'plan' && crewChangeEvent) ||
      (activity === 'readOnly' && reportData),
    [activity, crewChangeEvent, reportData]
  );

  const handleSelectCrewChangeEvent = useCallback(
    async (props: ActionProps): Promise<void> => {
      const { activity, isOneClickPlan } = props;
      // hide & open the table when a new event is selected
      const hideTable =
        (crewChangeEvent && crewChangeEvent?.id !== event.id) ||
        Boolean(reportData);
      if (hideTable) {
        // remove the current event
        dispatch(selectCrewChangeEvent());
        await sleep(100);
      }

      const payload = { vesselId, event, active: activity, isOneClickPlan };
      dispatch(selectCrewChangeEvent(payload));
    },
    [vesselId, event, crewChangeEvent, reportData, dispatch]
  );

  const onSelectEvent = useCallback(
    (props: ActionProps): void => {
      // check if crew-change panel is open with another event or read-only data
      if (isCCPanelOpen) {
        const canDiscard = window.confirm(CREW_CHANGE_CANCEL_MESSAGE);
        if (canDiscard) handleSelectCrewChangeEvent(props);
        return;
      }
      handleSelectCrewChangeEvent(props);
    },
    [isCCPanelOpen, handleSelectCrewChangeEvent]
  );
  return { onSelectEvent };
}

export default useCrewChangeEvent;
