import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridRowModel } from '@mui/x-data-grid-pro';
import {
  AlertType,
  UpdateAlertRequest,
} from '@greywing-maritime/frontend-library/dist/types/alerts';

import { useModal } from 'hooks';
import { showToaster } from 'lib/toaster';
import { findFlightTrackAccess } from 'lib/common';
import { selectSettings } from 'redux/selectors';
import {
  createNewAlert,
  deleteAlert,
  fetchAlerts,
  updateAlert,
} from 'api/flotilla';

import FlightPrice from './FlightPrice';
import EmailDetails from './EmailDetails';
import DesktopNotifications from './DesktopNotifications';
import {
  NotificationAlertsTable,
  SearchAlertsTable,
  ConfirmModal,
} from '../Tables';
import LoadMore from '../Alerts/LoadMore';
import { Title, Divider, Section } from '../../common';
import { getObjectDiff, updateTypeCounts } from '../../helpers';
import { AlertInputType, AlertsProps } from '../../types';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_NU_EDIT_ALERT_START,
  TRACK_SA_EDIT_ALERT_START,
} from 'utils/analytics/constants';

function Alerts({
  activePage,
  response,
  trackSettings,
  setResponse,
  setActivePage,
  updateSettings,
}: AlertsProps) {
  const { userInfo } = useSelector(selectSettings);
  const { modal, setModal } = useModal(null);
  const [loading, setLoading] = useState(false);
  const [mutation, setMutation] = useState<any>(null);

  const canTrackFlight = findFlightTrackAccess(userInfo);
  const alerts = useMemo(() => {
    return (response?.results || []).reduce(
      (acc, item) => {
        const type =
          item.type === 'UPDATE_FILTER' ? 'notifications' : 'searchAlerts';
        return { ...acc, [type]: [...acc[type], item] };
      },
      {
        notifications: [],
        searchAlerts: [],
      }
    );
  }, [response?.results]);

  const handleFetchAlerts = useCallback(async (page: number = 1) => {
    setLoading(true);
    const { success, alertResponse } = await fetchAlerts(page);

    if (!success || !alertResponse) {
      showToaster({ message: 'Failed to fetch alert!', type: 'error' });
      return;
    }

    const { results, currentPage, totalPages, typeCounts } = alertResponse;
    setResponse((prev) => ({
      initial: false,
      results: uniqBy([...(results || []), ...(prev?.results || [])], 'id'),
      loadMore: currentPage < totalPages,
      typeCounts,
    }));
    setActivePage(page);
    setLoading(false);
  }, []); // eslint-disable-line

  useEffect(() => {
    // only fetch in component mount, if NOT fetched already
    if (response.initial) {
      handleFetchAlerts();
    }
  }, []); // eslint-disable-line

  const handleCreateNotification = async (alertInput: AlertInputType) => {
    const request = { type: 'UPDATE_FILTER' as AlertType, ...alertInput };
    try {
      const { alert } = await createNewAlert(request);
      if (alert) {
        setResponse((prev) => ({
          ...prev,
          results: [...prev.results, alert],
          typeCounts: updateTypeCounts('add', alert.id, prev),
        }));
        showToaster({ message: 'Subscribed to notification succesfully!' });
      }
      return alert;
    } catch (error) {
      console.log('Error when creating alert', error);
      showToaster({
        message: 'Failed to create notification alert!',
        type: 'error',
      });
      return null;
    }
  };

  const handleDeleteAlert = useCallback((alertId: number) => {
    deleteAlert(alertId);
    setResponse((prev) => ({
      ...prev,
      typeCounts: updateTypeCounts('delete', alertId, prev),
      results: (prev?.results || []).filter(({ id }) => id !== alertId),
    }));
  }, []); // eslint-disable-line

  const handleUpdateAlert = useCallback(async (request: UpdateAlertRequest) => {
    const { success, alert: updatedAlert } = await updateAlert(request);
    if (!success || !updatedAlert) {
      showToaster({ message: 'Failed to update the alert!', type: 'error' });
      return;
    }

    setResponse((prev) => ({
      ...prev,
      results: (prev?.results || []).map((alert) =>
        alert.id === request.id ? updatedAlert : alert
      ),
    }));
  }, []); // eslint-disable-line

  const handleProcessRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
    new Promise<void>((resolve) => {
      // check if there are differences between the old and new value
      const hasDiff = getObjectDiff(newRow, oldRow);
      if (hasDiff.length) {
        hasDiff.forEach((diff: string) => {
          trackUserAction(
            newRow.type === 'UPDATE_FILTER'
              ? TRACK_NU_EDIT_ALERT_START
              : TRACK_SA_EDIT_ALERT_START,
            'click',
            {
              field: diff,
              old: oldRow[diff],
              new: newRow[diff],
            }
          );
        });
      }
      setModal('confirm', { type: 'update' });
      setMutation({ resolve, newRow, oldRow });
    });

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === 'confirm') {
      const { type, ...restData } = modal.data;
      const funcProps = {
        updateAlert: handleUpdateAlert,
        removeAlert: handleDeleteAlert,
      };

      return (
        <ConfirmModal
          actionType={type}
          closeModal={() => setModal(null)}
          funcProps={funcProps}
          data={isEmpty(restData) ? mutation : restData}
          {...(mutation ? { setData: setMutation } : {})}
        />
      );
    }
  };

  const commonProps = {
    loading,
    setModal,
    processRowUpdate: handleProcessRowUpdate,
  };

  const alertRef = useRef<HTMLDivElement | null>(null);
  const notificationAlertRef = useRef<HTMLDivElement | null>(null);
  const searchAlertRef = useRef<HTMLDivElement | null>(null);
  const [showError, setShowError] = useState<{
    notification: boolean;
    search: boolean;
  }>({
    notification: false,
    search: false,
  });

  // to prevent error tooltip from overflowing out of settings
  useEffect(() => {
    const options = {
      root: alertRef.current,
      threshold: 0.8,
    };
    const notificationAlertobserver = new IntersectionObserver((entries) => {
      try {
        const isNotificationIntersecting = entries[0].isIntersecting;
        setShowError((prev) => ({
          ...prev,
          notification: isNotificationIntersecting,
        }));
      } catch (error) {}
    }, options);
    notificationAlertobserver.observe(notificationAlertRef.current!);

    return () => {
      notificationAlertobserver.disconnect();
    };
  }, [setShowError]); // eslint-disable-line

  useEffect(() => {
    const options = {
      root: alertRef.current,
      threshold: 0.8,
    };
    const searchAlertobserver = new IntersectionObserver((entries) => {
      try {
        const isSearchIntersecting = entries[0].isIntersecting;
        setShowError((prev) => ({
          ...prev,
          notification: isSearchIntersecting,
        }));
      } catch (error) {}
    }, options);
    searchAlertobserver.observe(searchAlertRef.current!);

    return () => {
      searchAlertobserver.disconnect();
    };
  }, [setShowError]); // eslint-disable-line

  return (
    <>
      <div
        ref={alertRef}
        style={{ height: '100%', overflow: 'hidden', overflowY: 'auto' }}
      >
        <DesktopNotifications />
        <Divider />

        <EmailDetails updateSettings={updateSettings} />

        <Divider />

        {canTrackFlight && (
          <>
            <FlightPrice
              settings={trackSettings}
              updateSettings={updateSettings}
            />
            <Divider />
          </>
        )}

        <Section>
          <Title data-id="notifications-title">Subscribed Notifications</Title>
          <div ref={notificationAlertRef}>
            <NotificationAlertsTable
              alerts={alerts.notifications}
              count={response.typeCounts.UPDATE_FILTER}
              createNotification={handleCreateNotification}
              showError={showError.notification}
              {...commonProps}
            />
          </div>
        </Section>

        <div style={{ marginTop: '1.5rem' }} />

        <Section>
          <Title data-id="search-alerts-title">Search Alerts</Title>
          <div ref={searchAlertRef}>
            <SearchAlertsTable
              alerts={alerts.searchAlerts}
              count={response.typeCounts.SEARCH_FILTER}
              showError={showError.search}
              {...commonProps}
            />
          </div>
        </Section>

        <LoadMore
          loading={loading}
          response={response}
          fetchMore={() => handleFetchAlerts(activePage + 1)}
        />
      </div>

      {renderModal()}
    </>
  );
}

export default Alerts;
