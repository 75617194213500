import moment from 'moment';
import { Position } from '@turf/turf';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import { getVesselPortCalls } from 'api/flotilla';
import {
  Route,
  RouteStore,
  RouteState,
  PortCallResponse,
  PortCallV2CommonUTC,
} from 'utils/types';
import { getEarliestDate } from './dates';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';

const initialRoutesState: RouteState = {
  routes: new Map<number, Route>(),
  portCalls: new Map<number, Promise<PortCallResponse | null>>(),
};

const routes: RouteStore = {
  ...initialRoutesState,
  ...{
    storeVesselPortCalls: function (
      vesselId: number,
      portCalls: Promise<PortCallResponse | null>
    ): void {
      this.portCalls.set(vesselId, portCalls);
    },
    storeVesselRoute: function (vesselId: number, route: Route): void {
      this.routes.set(vesselId, route);
    },
  },
};

export function routeIsStale(
  vesselRouteUpdatedAt: Date | null | undefined,
  route: Route
) {
  return (
    vesselRouteUpdatedAt &&
    route.updated &&
    new Date(route.updated).getTime() < new Date(vesselRouteUpdatedAt).getTime()
  );
}

export async function fetchAndCachePortCalls(
  vesselId: number
): Promise<PortCallResponse | null> {
  const response = getVesselPortCalls(vesselId);
  routes.storeVesselPortCalls(vesselId, response);

  return response;
}

export const getCachedVesselPortCalls = async (vesselId: number) => {
  let cachedPortCall = await routes.portCalls.get(vesselId);
  if (!cachedPortCall) {
    cachedPortCall = await fetchAndCachePortCalls(vesselId);
  }
  return cachedPortCall as PortCallResponse;
};

type VesselPortCalls = {
  dateToSort: string;
  coor: Position;
  properties: any;
};

export const getVesselWithPortCallPositions = (
  portCalls: PortCallV2CommonUTC[],
  vessel: Vessel
): WaypointWithProperties[] => {
  return portCalls
    .reduce<VesselPortCalls[]>(
      (acc, portCall) => {
        if (!portCall.lat || !portCall.lng) return acc;
        const dateToSort = getEarliestDate([portCall.utcETA, portCall.utcETD]);
        if (!dateToSort) return acc;

        acc.push({
          dateToSort,
          coor: [portCall.lng, portCall.lat],
          properties: {
            ...portCall,
            componentType: 'port',
          },
        });
        return acc;
      },
      [
        {
          dateToSort: vessel.updatedAt,
          coor: [vessel.lng, vessel.lat],
          properties: {
            type: 'vessel',
            componentType: 'vessel',
          },
        },
      ]
    )
    .sort((a, b) => moment(a.dateToSort).diff(moment(b.dateToSort)))
    .map(
      ({ properties: { componentType, ...properties }, coor, dateToSort }) => {
        if (componentType === 'vessel') {
          return {
            properties: {
              segment: '',
              componentType,
              ...properties,
            },
            coor,
          };
        }
        return {
          properties: {
            segment: dateToSort > vessel.updatedAt ? 'future' : 'past',
            componentType,
            ...properties,
          },
          coor,
        };
      }
    );
};

type PortCallDetails = {
  dateToSort: string;
  coor: Position;
  waypointType: 'port' | 'vessel';
  portCall?: PortCallV2CommonUTC;
};

export const getVesselWithPortCallDetails = (
  portCalls: PortCallV2CommonUTC[],
  vessel: Vessel
): WaypointWithProperties[] => {
  return portCalls
    .reduce<PortCallDetails[]>(
      (acc, portCall) => {
        if (!portCall.lat || !portCall.lng) return acc;
        const dateToSort = getEarliestDate([portCall.utcETA, portCall.utcETD]);
        if (!dateToSort) return acc;

        acc.push({
          dateToSort,
          coor: [portCall.lng, portCall.lat],
          waypointType: 'port',
          portCall,
        });
        return acc;
      },
      [
        {
          dateToSort: vessel.updatedAt,
          coor: [vessel.lng, vessel.lat],
          waypointType: 'vessel',
        },
      ]
    )
    .sort((a, b) => moment(a.dateToSort).diff(moment(b.dateToSort)))
    .map((each) => {
      const { dateToSort, waypointType, coor, portCall } = each;
      if (each.waypointType === 'vessel') {
        return {
          properties: {
            segment: '',
            waypointType: waypointType,
          },
          coor,
        };
      }
      return {
        properties: {
          segment: dateToSort > vessel.updatedAt ? 'future' : 'past',
          waypointType: waypointType,
          ...portCall,
        },
        coor,
      };
    });
};

// TODO: Remove... eventually
export function formatPortName(
  displayName: string,
  { name, locode }: { name?: string | null; locode?: string | null }
) {
  if (!locode || !name) {
    return displayName;
  }
  return `(${locode}) ${name}`;
}

export function formatPortCallType(type: PortCallTypesCommon) {
  return type.split('_').join(' ');
}

export default routes;
