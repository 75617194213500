import { useEffect, useState, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BrowserWarning from './BrowserWarning';
import AspectRatioWarning from './AspectRatioWarning';

import { selectInitialRouteReady } from 'redux/reducers/calculatedRoute';
import { selectSettings } from 'redux/selectors';

const Warning = () => {
  const { pathname } = useLocation();
  const [allowWarning, setAllowWarning] = useState<boolean>(false);
  const [showBrowserWarning, setShowBrowserWarning] = useState('');
  const isInitialRouteReady = useSelector(selectInitialRouteReady);
  const { userInfo } = useSelector(selectSettings);

  useEffect(() => {
    if (pathname === '/login' && !userInfo && !allowWarning) {
      setAllowWarning(true);
    }
    if (isInitialRouteReady && !allowWarning) {
      setAllowWarning(true);
    }
  }, [isInitialRouteReady, allowWarning, userInfo, pathname]);

  if (!allowWarning) return null;
  return (
    <>
      {localStorage.getItem('BrowserWarning') !== '0' && (
        <BrowserWarning
          state={showBrowserWarning}
          setState={setShowBrowserWarning}
        />
      )}
      <AspectRatioWarning />
    </>
  );
};

export default memo(Warning);
