import { memo, useState } from 'react';
import { DataGridPro, GridRowId, GridRowParams } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import sleep from 'lib/sleep';

import { TableSkeleton } from 'components/shared';
import PanelDetail, { PANEL_HEIGHT } from './PanelDetails';
import { getAlertsColumns } from './Header';
import { alertsTableStyles, AlertsTableWrapper } from './common';
import { Text } from '../../common';
import { AlertsTableProps } from '../../types';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_SA_EXPAND_ALERT } from 'utils/analytics/constants';

const Wrapper = styled.div`
  width: 96%;
`;

const LoaderWrapper = styled.div`
  margin-top: 1rem;
`;

const StyledTableWrapper = styled(AlertsTableWrapper)`
  .MuiDataGrid-row {
    cursor: pointer;
  }
  .MuiDataGrid-detailPanel {
    height: 100px;
  }
`;

type Props = AlertsTableProps & {
  showError: boolean;
};

function SearchAlertsTable({
  loading,
  count,
  alerts,
  setModal,
  processRowUpdate,
  showError,
}: Props) {
  const loadingInitial = Boolean(loading && !alerts?.length);
  const [expandedRowId, setExpandedRowId] = useState<GridRowId | undefined>();

  if (loadingInitial) {
    return (
      <>
        <Text>Loading search alerts.</Text>
        <LoaderWrapper>
          <TableSkeleton rows={2} />
        </LoaderWrapper>
      </>
    );
  }

  if (!count) {
    return (
      <Wrapper data-id="search-alerts_container">
        <Text data-id="empty-search-alerts">
          It looks like you haven't subscribed to any search alert!
        </Text>
      </Wrapper>
    );
  }

  if (!alerts?.length) {
    return <Text>Load more to fetch search alerts.</Text>;
  }

  const handleExpandRow = (ids: GridRowId[]) => {
    const rowId = ids[ids.length - 1];
    trackUserAction(TRACK_SA_EXPAND_ALERT, 'click', {
      id: rowId,
      state: rowId ? 'open' : 'close',
    });
    setExpandedRowId(undefined);
    // delay to prevent glitch when opening & closing quickly
    sleep(300).then(() => {
      setExpandedRowId(rowId);
    });
  };

  const getDetailPanelContent = ({ row }: GridRowParams) => {
    return <PanelDetail searchFilters={row.searchFilters} />;
  };
  const getDetailPanelHeight = () => PANEL_HEIGHT;

  const columns = getAlertsColumns('SEARCH_FILTER', setModal, showError);

  return (
    <Wrapper data-id="search-alerts_container">
      <Text data-id="search-alerts-list">
        These are the search alerts you have created. When vessels trigger any
        of these conditions, you will be alerted.
      </Text>
      <StyledTableWrapper>
        <DataGridPro
          rows={alerts}
          columns={columns}
          hideFooter
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          hideFooterPagination
          autoHeight
          density="compact"
          sx={alertsTableStyles}
          // props to render a panel when table row is clicked
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
          onDetailPanelExpandedRowIdsChange={handleExpandRow}
          // allow updating frequency cell
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={processRowUpdate}
          // onCellEditStop={handleCellEditStop}
        />
      </StyledTableWrapper>
    </Wrapper>
  );
}

export default memo(SearchAlertsTable);
