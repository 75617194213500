import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { useAppDispatch } from 'hooks';

import { Section, Title, Divider, ActionButton } from '../common';
import styled from 'styled-components/macro';

import SwitchV2 from '../../shared/SwitchV2';
import { RootState } from 'redux/types';
import { toggleCopilotFetchCosts, clearCopilotHistory } from 'redux/actions';
import { showToaster } from 'lib/toaster';
import { isProduction } from 'lib/environments';
import { initStoreThreadHistory } from 'redux/reducers/copilot';
import { initThreadHistory } from '../../SeaGPT/helpers';

const ToggleWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  column-gap: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin-bottom: 1rem;
`;

function Copilot() {
  const dispatch = useAppDispatch();
  const fetchCosts = useSelector(
    (state: RootState) => state.copilot.fetchCosts
  );
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);

  const handlefetchCostToggle = () => {
    localStorage.setItem('copilotFetchCosts', JSON.stringify(!fetchCosts));
    dispatch(toggleCopilotFetchCosts());
  };

  const downloadFile = (blob: Blob) => {
    // create file in browser and download
    const download_date = new Date();
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download =
      'Proteus-Chat-History-' + download_date.toISOString() + '.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    const history = localStorage.getItem('threadHistory') as string;
    try {
      if (history) {
        const parsed = JSON.parse(history);
        const blob = new Blob([JSON.stringify(parsed)], {
          type: 'application/json',
        });
        downloadFile(blob);
        showToaster({
          message: 'Proteus Chat History downloaded successfully.',
          type: 'success',
        });
      } else {
        showToaster({
          message: 'Sorry, there is no chat in the history to download.',
          type: 'warning',
        });
      }
    } catch {
      const blob = new Blob([history], {
        type: 'text/plain',
      });
      downloadFile(blob);
      showToaster({
        message: 'Proteus Chat History is corrupted.',
        type: 'warning',
      });
    }
  };

  const handleClear = useCallback(() => {
    if (!userInfo) return;
    localStorage.removeItem('threadHistory');
    dispatch(clearCopilotHistory());
    const threads = initThreadHistory(userInfo.id);
    threads && dispatch(initStoreThreadHistory(threads));
    showToaster({
      message: 'Proteus Chat History cleared successfully.',
      type: 'success',
    });
  }, [dispatch, userInfo]);

  return (
    <>
      <Section>
        <Title>Automatic Proteus email fetching</Title>
        <ToggleWrapper>
          <SwitchV2 checked={fetchCosts} onChange={handlefetchCostToggle} />
          {fetchCosts
            ? 'Periodically fetching emails.'
            : 'Not fetching emails.'}
        </ToggleWrapper>
      </Section>
      {!isProduction ? (
        <>
          <Divider />
          <Section>
            <Title>Proteus Chat History</Title>
            <ButtonsWrapper>
              <ActionButton variant="secondary" onClick={handleClear}>
                Clear
              </ActionButton>
              <ActionButton onClick={handleDownload}>Download</ActionButton>
            </ButtonsWrapper>
          </Section>
        </>
      ) : null}
    </>
  );
}

export default Copilot;
