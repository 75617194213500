import { circle, point } from '@turf/turf';
import mapboxgl, { MapMouseEvent } from 'mapbox-gl';
import { ProximityPort, Waypoint } from 'utils/types';
import { mapState, runWhenMapReady, runWhenSourceLoaded } from './map';

type Port = {
  waypoint: Waypoint;
  type: string;
  id: string; // Can be any unique id, but usually the waypoint id concatenated with the type.
};

export const portTypes = {
  CURRENT_ROUTE_PORT: 'current_route_port',
};

const portsState: {
  portsGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Point> | null;
  ports: Port[];
} = {
  portsGeoJSON: null,
  ports: [],
};

// TODO: Remove after development
(window as any).inspectMapPorts = {
  portsState,
};

/**
 * Add or replace ports of a particular type.
 * @param waypoints List of waypoints to add.
 * @param type String id of the type. Use portTypes to get predefined types and behavior.
 * @param replaceOfType Replace all ports of this type. With this on, pass an empty list to waypoints to delete all.
 */
export async function updatePorts(
  waypoints: Waypoint[],
  type: string,
  replaceOfType: boolean
) {
  if (replaceOfType)
    portsState.ports = portsState.ports.filter((port) => port.type !== type);

  portsState.ports = portsState.ports.concat(
    waypoints.map((waypoint) => ({
      waypoint,
      type,
      id: type + '_' + waypoint.id,
    }))
  );

  await drawPorts();
}

/**
 * Delete a particular port using an id.
 * @param id
 * @returns
 */
export async function removePort(id: string) {
  if (!portsState.ports.find((port) => port.id === id)) return;

  portsState.ports = portsState.ports.filter((port) => port.id !== id);

  await drawPorts();
}

//*************************** Internal functions  */

/**
 * Draws the current port list onto the map.
 */
async function drawPorts() {
  generatePortsGeoJSON();

  await runWhenMapReady(async (map: mapboxgl.Map) => {
    if (portsState.portsGeoJSON) {
      if (!map.getSource('ports')) {
        map.addSource('ports', {
          type: 'geojson',
          data: portsState.portsGeoJSON,
        });

        runWhenSourceLoaded('ports', () => {
          if (!map.getLayer('ports')) {
            map.addLayer(getPortLayer());
          }
        });
      } else {
        (map.getSource('ports') as mapboxgl.GeoJSONSource).setData(
          portsState.portsGeoJSON
        );
      }
    } else {
      if (map.getLayer('ports')) map.removeLayer('ports');
      if (map.getSource('ports')) map.removeSource('ports');
    }
  });
}

function getPortLayer(): mapboxgl.AnyLayer {
  return {
    id: 'ports',
    type: 'symbol',
    source: 'ports',
    paint: {},
    layout: {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
    },
  };
}

/**
 * Retrieve the icon associated with a port. Defaults to `arrival-wp-marker`. Modify this function to add more types.
 * @param port
 * @returns
 */
function getPortIcon(port: Port): string {
  const now = new Date();

  if (port.type === portTypes.CURRENT_ROUTE_PORT)
    return port.waypoint.eta &&
      new Date(port.waypoint.eta).getTime() > now.getTime()
      ? 'arrival-wp-marker'
      : 'depart-wp-marker';

  return 'arrival-wp-marker';
}

/**
 * Generate the GeoJSON from the list of ports in state. Idempotent.
 */
function generatePortsGeoJSON() {
  if (!portsState.ports || !portsState.ports.length)
    portsState.portsGeoJSON = null;
  else
    portsState.portsGeoJSON = {
      type: 'FeatureCollection',
      features: portsState.ports.map((port) => ({
        type: 'Feature',
        id: port.id,
        properties: {
          icon: getPortIcon(port),
          eta: port.waypoint.eta,
          etd: port.waypoint.etd,
          hoursInPort: port.waypoint.hoursInPort,
          id: port.waypoint.id,
          type: port.type,
          text: port.waypoint.text,
          lat: port.waypoint.lat,
          lon: port.waypoint.lon,
        },
        geometry: {
          type: 'Point',
          coordinates: [port.waypoint.lon, port.waypoint.lat],
        },
      })),
    };
}

/**
 * Port Search Section
 */

let beaconMarker: mapboxgl.Marker;

let addPortSearchArea: (lngLat: mapboxgl.LngLat) => void;
let hidePortSearchArea: () => void;
let showPortSearchArea: (event: any) => void;

// Initial Search Area
let center = point([0, 0]);
let radius = 100 * 1.852;
let options = {
  steps: 100,
  Units: 'kilometers',
};

let portMarkerList: mapboxgl.Marker[] = [];

export function initializePortSearchArea() {
  // Pulsing Circle Animation
  if (!mapState.map) return;
  const { map } = mapState;
  const size = 200;
  const canvas = document.createElement('canvas');

  map.on('load', () => {
    // This implements `StyleImageInterface`
    // to draw a pulsing circle on the map.
    const pulsingCircle = {
      width: size,
      height: size,
      data: new Uint8ClampedArray(size * size * 4),
      context: canvas.getContext('2d'),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        canvas.width = this.width;
        canvas.height = this.height;
      },

      // Call once before every frame where the circle will be used.
      render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // Draw the circle.
        context!.clearRect(0, 0, this.width, this.height);
        context!.beginPath();
        context!.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context!.fillStyle = `rgba(195, 206, 248, ${1 - t})`;
        context!.fill();

        // Update this image's data with data from the canvas.
        this.data = context!.getImageData(0, 0, this.width, this.height).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the circle.
        map.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      },
    };
    map.addImage('pulsing-circle', pulsingCircle, { pixelRatio: 2 });
    map.addSource('pulsing-circle-source', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [0, 0], // icon position [lng, lat]
            },
          },
        ],
      },
    });
    map.addLayer({
      id: 'pulsing-circle-layer',
      type: 'symbol',
      source: 'pulsing-circle-source',
      layout: {
        'icon-image': 'pulsing-circle',
        // Make the layer invisible by default.
        visibility: 'none',
      },
    });
  });

  // Beacon Marker
  const el = document.createElement('div');
  el.className = 'marker';
  el.style.backgroundImage = `url(beacon.png)`;
  el.style.width = `40px`;
  el.style.height = `40px`;
  el.style.backgroundSize = '100%';
  beaconMarker = new mapboxgl.Marker({ element: el, draggable: true });

  // Search Area
  const searchArea = circle(center, radius, options);

  map.on('load', function () {
    map.addSource('search-area-source', {
      type: 'geojson',
      data: searchArea,
    });

    map.addLayer({
      id: 'search-area-layer',
      type: 'fill',
      source: 'search-area-source',
      layout: {
        // Make the layer invisible by default.
        visibility: 'none',
      },
      paint: {
        'fill-color': 'rgba(56, 93, 234, 1)',
        'fill-opacity': 0.2,
      },
    });

    map.addLayer({
      id: 'search-area-outline-layer',
      type: 'line',
      source: 'search-area-source',
      layout: {
        // Make the layer invisible by default.
        visibility: 'none',
      },
      paint: {
        'line-color': '#ffffff',
        'line-width': 1,
      },
    });
  });
}

const removeMarkers = () => {
  if (portMarkerList.length > 0) {
    for (let i = portMarkerList.length - 1; i >= 0; i--) {
      portMarkerList[i].remove();
    }
  }
};

export function drawPortSearchArea(
  toggle: boolean,
  setPortsInRange: ({ lat, lng }: { lat: number; lng: number }) => void
) {
  if (!mapState.map) return;
  const { map } = mapState;
  removeMarkers();

  if (!toggle) {
    map.off('click', showPortSearchArea);
    beaconMarker?.remove();
    if (map.getLayer('pulsing-circle-layer'))
      map.setLayoutProperty('pulsing-circle-layer', 'visibility', 'none');
    if (map.getLayer('search-area-layer'))
      map.setLayoutProperty('search-area-layer', 'visibility', 'none');
    if (map.getLayer('search-area-outline-layer'))
      map.setLayoutProperty('search-area-outline-layer', 'visibility', 'none');
  } else {
    addPortSearchArea = (lngLat) => {
      removeMarkers();
      const coordinates = lngLat;
      const lng = coordinates.lng;
      const lat = coordinates.lat;
      beaconMarker?.setLngLat([lng, lat]).addTo(map);

      center = point([lng, lat]);
      const searchAreaGeoJSONSource: mapboxgl.GeoJSONSource = map.getSource(
        'search-area-source'
      ) as mapboxgl.GeoJSONSource;
      searchAreaGeoJSONSource?.setData(circle(center, radius, options));

      map.setLayoutProperty('search-area-layer', 'visibility', 'visible');
      map.setLayoutProperty(
        'search-area-outline-layer',
        'visibility',
        'visible'
      );

      const pulsingCircleGeoJSONSource: mapboxgl.GeoJSONSource = map.getSource(
        'pulsing-circle-source'
      ) as mapboxgl.GeoJSONSource;
      pulsingCircleGeoJSONSource?.setData({
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [lng, lat],
            },
          },
        ],
      });

      map.setLayoutProperty('pulsing-circle-layer', 'visibility', 'visible');

      map.flyTo({
        center: [lng, lat],
        offset: [0, -150],
        zoom: 6,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });

      setPortsInRange({ lat, lng });
    };
    hidePortSearchArea = () => {
      removeMarkers();
      map.setLayoutProperty('pulsing-circle-layer', 'visibility', 'none');
      map.setLayoutProperty('search-area-layer', 'visibility', 'none');
      map.setLayoutProperty('search-area-outline-layer', 'visibility', 'none');
    };
    showPortSearchArea = (event) => {
      console.log(event);
      if (event.type === 'click') {
        addPortSearchArea(event.lngLat);
      } else addPortSearchArea(beaconMarker.getLngLat());
    };

    map.on('click', showPortSearchArea);

    if (beaconMarker) {
      beaconMarker.on('dragstart', hidePortSearchArea);
      beaconMarker.on('dragend', showPortSearchArea);
    }
  }
}

export function drawPortsInRange(
  portsInRange: ProximityPort[],
  rangeNauticalMiles: number
) {
  if (!mapState.map) return;
  const { map } = mapState;

  removeMarkers();
  portsInRange.forEach((port) => {
    const isInRange = port.distanceMetres / 1852 <= rangeNauticalMiles;
    if (isInRange) {
      const tempMarker = new mapboxgl.Marker({ color: 'red', scale: 0.8 })
        .setLngLat([port.lng, port.lat])
        .addTo(map);
      portMarkerList.push(tempMarker);
    }
  });
  radius = rangeNauticalMiles * 1.852;
  const searchAreaGeoJSONSource: mapboxgl.GeoJSONSource = map.getSource(
    'search-area-source'
  ) as mapboxgl.GeoJSONSource;
  searchAreaGeoJSONSource?.setData(circle(center, radius, options));
}

export function portFromMouseEvent(e: MapMouseEvent, sourceId: string) {
  if (!mapState.map) return;
  const features = mapState.map!.queryRenderedFeatures(e.point);
  const coordinates = e.lngLat;
  let current: {
    feature: mapboxgl.MapboxGeoJSONFeature;
    coordinates: mapboxgl.LngLat;
  } | null = null;
  for (const v of features) {
    if (v.source !== sourceId) {
      continue;
    }

    current = {
      feature: v,
      coordinates,
    };
  }
  return current;
}
