import { memo, useState } from 'react';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import {
  blue,
  lightPurple,
  green,
  red,
  textBlack,
  white,
  yellow10,
} from 'lib/colors';
import { Modal } from 'components/shared';
import { OffsignerIcon, OnsignerIcon } from 'components/icons';
import { StyledButton } from '../../../common';
import { AddCrew } from '../../../types';
import { OrgCrew } from 'utils/types/crew-change-types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-size: 1rem;
  color: ${textBlack};
  margin-bottom: 1.25rem;
`;

const ContentWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
`;

const SectionWrapper = styled(FlexWrapper)`
  padding: 0.25rem 1rem;
  width: 100%;
  justify-content: center;
`;

const CancelButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const IconWrapper = styled.div<{
  $type: 'onsigner' | 'offsigner';
  $selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 1.75rem 1.25rem 1.1rem 1.25rem;
  background: ${({ $selected }) => ($selected ? blue : yellow10)};
  transition: all 150ms ease;
  ${({ $type }) =>
    $type === 'onsigner'
      ? `
    margin-right: 2.5rem;
  `
      : `
    margin-left: 2.5rem;
  `};
  &:hover {
    ${({ $selected }) =>
      !$selected &&
      `
      cursor: pointer;
      transform: translate(-1px, -1px);
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
    `};
  }
`;

const TypeText = styled.div<{ $selected: boolean }>`
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  margin-top: 1.25rem;
  color: ${({ $selected }) => ($selected ? white : blue)};
`;

type Props = {
  selectedCrew: OrgCrew;
  onSelect: AddCrew;
  closeModal: () => void;
};

function CrewTypeModal({ selectedCrew, closeModal, onSelect }: Props) {
  const [crewType, setCrewType] = useState<CrewType | null>(null);

  const handleReset = () => {
    setCrewType(null);
    closeModal();
  };

  const handleSelectType = () => {
    if (crewType) {
      onSelect({ ...selectedCrew, added: true, type: crewType });
      handleReset();
    }
  };

  const actions = (
    <ContentWrapper>
      <CancelButton variant="secondary" onClick={handleReset}>
        Cancel
      </CancelButton>
      <StyledButton
        variant="primary"
        disabled={!crewType}
        onClick={handleSelectType}
      >
        Add to Panel
      </StyledButton>
    </ContentWrapper>
  );

  return (
    <Modal
      width={400}
      title="Select Crew Type"
      actions={actions}
      closeModal={closeModal}
    >
      <Description>
        Choose a type from below and add this crew to the planning.
      </Description>

      <SectionWrapper>
        <IconWrapper
          $type="onsigner"
          $selected={crewType === CrewType.onsigner}
          onClick={() => setCrewType(CrewType.onsigner)}
        >
          <OnsignerIcon
            color={crewType === CrewType.onsigner ? white : green}
            style={{ fontSize: '3rem' }}
          />
          <TypeText $selected={crewType === CrewType.onsigner}>
            ONSIGNER
          </TypeText>
        </IconWrapper>
        <IconWrapper
          $type="offsigner"
          $selected={crewType === CrewType.offsigner}
          onClick={() => setCrewType(CrewType.offsigner)}
        >
          <OffsignerIcon
            color={crewType === CrewType.offsigner ? white : red}
            style={{ fontSize: '3rem' }}
          />
          <TypeText $selected={crewType === CrewType.offsigner}>
            OFFSIGNER
          </TypeText>
        </IconWrapper>
      </SectionWrapper>
    </Modal>
  );
}

export default memo(CrewTypeModal);
