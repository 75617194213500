import Lottie, { EventListener } from 'react-lottie';
import styled from 'styled-components/macro';

import { FlotillaMapConfig } from 'utils/flotilla-config';

const Wrapper = styled.div<{ $height?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  width: 100%;
  height: ${({ $height }) => $height || '100%'};
`;

type Props = {
  size: number;
  eventListeners?: EventListener[];
  height?: string;
};

function Loader({ size, eventListeners, height }: Props) {
  return (
    <Wrapper $height={height}>
      <Lottie
        options={FlotillaMapConfig.animationOptions}
        width={size}
        eventListeners={eventListeners}
      />
    </Wrapper>
  );
}

export default Loader;
