export function imoChecksum(imo: string): boolean {
  // Super extra, this is just for fun
  try {
    imo = parseInt(imo).toString();
    if (imo.length !== 7) return false;
    let checksum = imo
      .split('')
      .slice(0, 6)
      .map((char, index) => parseInt(char) * (7 - index))
      .reduce((acc, cur) => acc + cur, 0);
    checksum = checksum % 10;

    if (checksum === parseInt(imo[6])) return true;
  } catch (err) {
    console.error(err);
    return false;
  }
  return false;
}
