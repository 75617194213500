import { memo, SyntheticEvent, useEffect, useState } from 'react';
import { Fade, Menu, MenuItem, Switch } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import styled from 'styled-components/macro';

import { useAppDispatch, useMobile } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { updateFlightSearch } from 'redux/actions';
import { Checkbox, Tooltip } from 'components/shared';
import {
  menuItemStyles,
  switchStyles,
} from 'components/CrewChangePanel/Table/Actions/Flights/common';
import { Airline } from 'components/CrewChangePanel/types';
import { commonMenuStyles, Footer, StyledFilterButton, Title } from './common';
import { isAllDeselected, isAllSelected } from './helpers';

const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;

const FlightIcon = styled(FlightTakeoffIcon)`
  margin-right: 0.5rem;
  font-size: 15px !important;
`;

type Props = {
  airlines: Airline[];
  updateFilters: (airlines: Airline[], type?: 'Select All') => void;
};

// This component handles filters updates for airlines
// in both crew change panel & search flight features
function AirlineSelection({ airlines, updateFilters }: Props) {
  const isMobile = useMobile(BREAK_POINT_XS);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAirlines, setSelectedAirlines] = useState(airlines);
  const [allSelected, setAllSelected] = useState(isAllSelected(airlines));

  useEffect(() => {
    if (isAllSelected(airlines)) {
      setAllSelected(true);
    }
    if (isAllDeselected(airlines)) {
      setAllSelected(false);
    }
  }, [airlines]);

  const handleUpdateFilters = (airlines: Airline[]) => {
    setSelectedAirlines(airlines);
    updateFilters(airlines);
  };

  const handleSelectAllAirlines = (value: boolean) => {
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: value,
    }));

    setAllSelected(value);
    setSelectedAirlines(updatedAirlines);
    updateFilters(updatedAirlines, 'Select All');
  };

  const handleFlightSearchAction = (value: boolean) => {
    // state update when popup is open to prevent closing search results
    dispatch(updateFlightSearch({ listOpen: value }));
  };

  const handleOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.target as HTMLElement);
    handleFlightSearchAction(true);
  };

  const handleClose = async () => {
    setAnchorEl(null);
    handleFlightSearchAction(false);
  };

  const handleToggle = (airline: string) => {
    const updatedAirlines = selectedAirlines.map((item) =>
      item.airline === airline ? { ...item, selected: !item.selected } : item
    );
    handleUpdateFilters(updatedAirlines);
  };

  return (
    <>
      <ButtonWrapper>
        <Tooltip content="Select airlines">
          <StyledFilterButton variant="primary" onClick={handleOpen}>
            {!isMobile && <FlightIcon />}
            Select Airlines
          </StyledFilterButton>
        </Tooltip>
      </ButtonWrapper>

      <Menu
        id="airlines-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={commonMenuStyles}
      >
        <Title>Airlines</Title>
        {airlines.map(({ airline, selected }) => (
          <MenuItem key={airline} onClick={() => {}} sx={menuItemStyles}>
            <Switch
              size="small"
              checked={selected}
              sx={switchStyles}
              onClick={() => handleToggle(airline)}
            />
            {airline}
          </MenuItem>
        ))}
        <Footer>
          <Checkbox
            id="airline-selection"
            label="Select All"
            checked={allSelected}
            onChange={handleSelectAllAirlines}
          />
        </Footer>
      </Menu>
    </>
  );
}

export default memo(AirlineSelection);
