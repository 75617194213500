import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PreferredPort } from '@greywing-maritime/frontend-library/dist/types/preferredPorts';

import { Nullable, RootState } from '../types';

type CounterState = Nullable<PreferredPort[]>;

export const initialPreferredPorts = null as CounterState;

const preferredPortsSlice = createSlice({
  name: 'preferredPorts',
  initialState: initialPreferredPorts,
  reducers: {
    setPreferredPorts: (_, action: PayloadAction<PreferredPort[]>) =>
      action.payload,
  },
});

/* ----- selectors -----*/

export const selectPreferredPorts = ({ preferredPorts }: RootState) =>
  preferredPorts;

/* ----- actions -----*/

export const { setPreferredPorts } = preferredPortsSlice.actions;

/* ----- reducer -----*/

export default preferredPortsSlice.reducer;
