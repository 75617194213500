import { Suspense, memo, useContext } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import CrewTable from './Crew';
import ReadOnlyCrewTable from './Crew/ReadOnly';
import RouteTable from './Route';
import ReadOnlyRouteTable from './Route/ReadOnly';
import PortsTable from './Ports';
import ReadOnlyPortsTable from './Ports/ReadOnly';
import FlightsTable from './Flights';
import ReadOnlyFlightsTable from './Flights/ReadOnly';

import { PortRow, ReadOnlyPort, SetFocusedPort } from '../types';
import { useVesselBudgets } from 'hooks';

type PortType = PortRow | ReadOnlyPort | null;
type Props = {
  focusedPort: PortType;
  setFocusedPort: SetFocusedPort<PortType>;
};

// top-level wrapper component to provide active table to context
function CCPanelTable({ focusedPort, setFocusedPort }: Props) {
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const {
    tableState: { step },
  } = useContext(CCPanelContext);
  // fetch current vessel budgets for crew change
  useVesselBudgets();

  const isReportView = activity === 'readOnly';
  const CrewTableComponent = isReportView ? ReadOnlyCrewTable : CrewTable;
  const FlightsTableComponent = isReportView
    ? ReadOnlyFlightsTable
    : FlightsTable;
  const RouteTableComponent = isReportView ? ReadOnlyRouteTable : RouteTable;

  switch (step) {
    case 'crew':
      return <CrewTableComponent />;
    case 'route':
      return <RouteTableComponent />;
    case 'ports':
      return isReportView ? (
        <ReadOnlyPortsTable
          focused={focusedPort as ReadOnlyPort}
          setFocused={setFocusedPort as SetFocusedPort<ReadOnlyPort>}
        />
      ) : (
        <PortsTable
          focused={focusedPort as PortRow}
          setFocused={setFocusedPort as SetFocusedPort<PortRow>}
        />
      );
    case 'flights':
      return <FlightsTableComponent />;

    default:
      return (
        <Suspense>
          <CrewTable />
        </Suspense>
      );
  }
}

export default memo(CCPanelTable);
