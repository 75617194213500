import uniqBy from 'lodash/uniqBy';
import { useEffect, memo, useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import { useAppDispatch, usePrevious } from 'hooks';
import { fetchVesselCrewChangePlans } from 'api/flotilla';
import { updateIncompleteCCPlans } from 'redux/actions';
import { sampleIncompletePlans } from 'utils/sample-data/crew-change-plans';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';
import { RootState } from 'redux/types';

import { red, white } from 'lib/colors';
import { Loader } from 'components/shared';
import PlanDetails from './PlanDetails';
import {
  StyledButton,
  PlansContainer,
  LoaderWrapper,
  LoaderButton,
} from './common';
import { LoadingState, FetchingType, PlanListProps } from './types';

import CardContainer from '../common/CardContainer';
import CardHeader from '../common/CardHeader';

const MotionComponent = motion(PlansContainer);

function SavedIncompletePlans({ vessel }: PlanListProps) {
  const dispatch = useAppDispatch();
  const incompletePlans = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.incompletePlans
  );
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const { isInWizard } = useContext(SidePanelFieldContext);

  const prevActivity = usePrevious(activity);
  const hasPanelClosed = prevActivity === 'plan' && !activity;

  // hide the list by default & show button to expand
  // const [listHidden, setListHidden] = useState(true);
  const [loadingState, setLoadingState] = useState<LoadingState>({
    initial: false,
    loadMore: false,
  });

  const showInitialLoader = loadingState.initial && !isInWizard;
  const vesselPlans = incompletePlans?.[vessel.id];
  const {
    data: storedPlans = [],
    meta: { currentPage = 0, totalPages = 0, totalItems = 0 } = {},
  } = vesselPlans || {};
  const incompleteCCPlans = isInWizard
    ? sampleIncompletePlans
        .slice(0, 1)
        .map((item) => ({ ...item, isCompleted: false }))
    : storedPlans;
  const hasPlans = Boolean(incompleteCCPlans.length);
  const hasMore = currentPage < totalPages;
  const sectionBackground =
    loadingState.initial || !(hasPlans || hasMore) ? white : `${red}10`;

  const updateLoadingState = (type: FetchingType, value: boolean) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleFetchIncompletePlans = async ({
    initial,
    loadMore,
    refresh,
  }: LoadingState) => {
    if (isInWizard) {
      return;
    }

    const page =
      ((initial || refresh) && 1) || (loadMore ? currentPage + 1 : 1);
    const canFetchPlans =
      refresh ||
      (initial && !incompletePlans?.[vessel.id]) ||
      (loadMore ? page <= totalPages : !totalPages);
    const fetchingType = loadMore ? 'loadMore' : 'initial';

    if (canFetchPlans) {
      updateLoadingState(fetchingType, true);
      const { success, response } = await fetchVesselCrewChangePlans(
        vessel.id,
        { page, isCompleted: false }
      );

      if (success && response) {
        const { data: newPlans, meta } = response;
        const updatedPlans = {
          meta,
          data:
            initial || refresh
              ? newPlans
              : uniqBy([...incompleteCCPlans, ...newPlans], 'uuid'),
        };
        dispatch(updateIncompleteCCPlans({ [vessel.id]: updatedPlans }));
      }
      updateLoadingState(fetchingType, false);
    }
  };

  useEffect(() => {
    handleFetchIncompletePlans({ initial: true });
  }, []); // eslint-disable-line

  // fetch incomplete plans when panel is closed (after planning)
  useEffect(() => {
    if (hasPanelClosed) {
      handleFetchIncompletePlans({ refresh: true });
    }
  }, [hasPanelClosed]); // eslint-disable-line

  if (!isInWizard && !totalItems) {
    return null;
  }

  const renderPlanList = () => {
    if (showInitialLoader) {
      return <Loader size={120} />;
    }

    if (hasPlans || hasMore) {
      return incompleteCCPlans.map((plan) => (
        <PlanDetails
          key={plan.uuid}
          summary={plan}
          wizardView={isInWizard}
          isCompleted={false}
        />
      ));
    }

    return null;
  };

  const renderLoaderContent = () => {
    if (
      isInWizard ||
      !hasMore ||
      loadingState.initial ||
      (totalPages && currentPage === totalPages)
    ) {
      return null;
    }

    return (
      <LoaderWrapper>
        {loadingState.loadMore ? (
          <CircularProgress size={20} />
        ) : (
          <LoaderButton
            variant="secondary"
            onClick={() => handleFetchIncompletePlans({ loadMore: true })}
          >
            Load More
          </LoaderButton>
        )}
      </LoaderWrapper>
    );
  };

  return (
    <CardContainer style={{ background: sectionBackground }}>
      <CardHeader>
        <h4>Unfinished Plans</h4>
        {hasPlans && (
          <StyledButton
            variant="secondary"
            size="small"
            style={{ marginRight: 0 }}
            onClick={() => handleFetchIncompletePlans({ refresh: true })}
          >
            Refresh
          </StyledButton>
        )}
      </CardHeader>

      <AnimatePresence>
        <MotionComponent>
          {renderPlanList()}
          {renderLoaderContent()}
        </MotionComponent>
      </AnimatePresence>
    </CardContainer>
  );
}

export default memo(SavedIncompletePlans);
