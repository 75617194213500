import { memo } from 'react';
import styled from 'styled-components/macro';

import { SetModal } from 'hooks/useModal';
import { borderGray, iconGray, lightPurple, textGray } from 'lib/colors';
import { formatDate } from 'utils/format-date';

import MoreButton from './MoreButton';
import { getAlternateDate } from '../../../helpers';
import { RoutePort } from '../../../types';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

const StyledCard = styled.div<{ $added?: boolean; $isReportView?: boolean }>`
  height: 100%;
  width: fit-content;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  padding: 0.5rem 0.75rem;
  ${({ $added }) =>
    $added &&
    `
    background: ${lightPurple} !important;
  `}
  &:hover {
    ${({ $isReportView }) =>
      !$isReportView &&
      `
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
      transform: translate(-1px, -1px);

    `};
  }
`;

const TitleWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const Title = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
`;

const Date = styled.div`
  font-size: 0.6rem;
  line-height: 0.9rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: ${textGray};
`;

const Line = styled.hr`
  color: ${iconGray};
  background: ${iconGray};
  height: 2px;
  width: 1rem;
`;

const IconWrapper = styled(FlexWrapper)`
  height: 22px;
  width: 22px;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${iconGray};
  background: ${lightPurple};
`;

const RightIcon = styled(ArrowForwardIosRoundedIcon)`
  font-size: 14px !important;
`;

type BaseProps = {
  port: RoutePort;
  nextPortName?: string;
};

type HOCProps = {
  setModal?: SetModal;
  addPort?: (order?: number) => void;
};

function withPortCard({ ...baseProps }: BaseProps) {
  return memo(({ setModal }: HOCProps = {}) => {
    const { port, nextPortName } = baseProps;
    const { displayName, added, eta, etd, port: portInfo } = port;
    const portName =
      portInfo?.locode && portInfo?.name
        ? `(${portInfo.locode}) ${portInfo.name}`
        : displayName;
    // @ts-ignore // this uses port.text as there might still be saved data that has it
    const cardTitle = portName || port?.text || 'Unknown port';
    const portETA = eta || getAlternateDate('eta', etd!);
    const portETD = etd || getAlternateDate('etd', eta!);

    return (
      <FlexWrapper>
        <StyledCard $added={added} $isReportView={!setModal}>
          <TitleWrapper>
            <Title>{cardTitle}</Title>
            {setModal ? (
              <MoreButton
                onEdit={() => setModal('routePort', { type: 'update', port })}
                onRemove={() => setModal('confirmRemove', { port })}
              />
            ) : null}
          </TitleWrapper>
          <Date>{formatDate(portETA)} - ETA</Date>
          <Date>{formatDate(portETD)} - ETD</Date>
        </StyledCard>

        {/* show a connector in-between ports */}
        {nextPortName ? (
          <FlexWrapper>
            <Line />
            <IconWrapper>
              <RightIcon />
            </IconWrapper>
            <Line />
          </FlexWrapper>
        ) : null}
      </FlexWrapper>
    );
  });
}

export default withPortCard;
