import { memo, SyntheticEvent } from 'react';
import { TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import type { TrackFlightsSettings } from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';
import styled from 'styled-components/macro';

import { useDebounce } from 'hooks';
import { blue, green } from 'lib/colors';
import { DEFAULT_TRACK_FLIGHT_SETTINGS } from 'redux/helpers/settings';
import {
  ActionButton,
  Title,
  Text as Description,
  Section,
  CustomCheckbox,
} from '../../common';
import { UpdateSettings } from '../../types';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_QUICKFLY_SETTINGS_DEFAULT,
  TRACK_QUICKFLY_SETTINGS_PRICE,
  TRACK_QUICKFLY_SETTINGS_DAYS,
  TRACK_QUICKFLY_SETTINGS_SAME_LAYOVERS,
  TRACK_QUICKFLY_SETTINGS_ALL_PROVIDERS,
  TRACK_QUICKFLY_SETTINGS_NOTIFICATIONS,
} from 'utils/analytics/constants';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Payments from '@mui/icons-material/Payments';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(FlexWrapper)`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 2rem;
`;

const OptionsContainer = styled.div`
  margin: 0.5rem 0;
`;

const DollarIcon = styled(Payments)`
  font-size: 18px;
  margin-right: 0.5rem;
  color: ${green};
`;

const CalendarIcon = styled(CalendarToday)`
  font-size: 18px;
  margin-right: 0.5rem;
  color: ${blue};
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0.33rem;
`;

const StyledNumberField = muiStyled(TextField)(() => ({
  width: '75px',
  '.MuiInputLabel-root,': {
    fontSize: '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
  '.MuiInputBase-input': {
    textAlign: 'center',
    padding: 2,
    fontSize: '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

type SettingsType = keyof TrackFlightsSettings;

type Props = {
  settings: TrackFlightsSettings | null;
  updateSettings: UpdateSettings;
};

function FlightPrice({ settings, updateSettings }: Props) {
  const debouncedPriceTrack = useDebounce((value) => {
    trackUserAction(TRACK_QUICKFLY_SETTINGS_PRICE, 'typed', {
      value,
    });
  }, 1500);

  const debouncedDaysTrack = useDebounce((value) => {
    trackUserAction(TRACK_QUICKFLY_SETTINGS_DAYS, 'typed', {
      value,
    });
  }, 1500);

  const handleSetDefault = () => {
    trackUserAction(TRACK_QUICKFLY_SETTINGS_DEFAULT, 'click');
    updateSettings((prevState) => ({
      ...prevState,
      trackSettings: DEFAULT_TRACK_FLIGHT_SETTINGS,
    }));
  };

  const renderCommonDetails = () => (
    <>
      <Title data-id="e2e_flight-track-title">Flight Price</Title>
      <Description>Adjust settings to track flight prices</Description>
    </>
  );

  if (!settings) {
    return (
      <Section>
        {renderCommonDetails()}
        <ActionButton
          onClick={handleSetDefault}
          style={{ marginTop: '0.8rem' }}
        >
          Open Default
        </ActionButton>
      </Section>
    );
  }

  // update number fields - `priceDiff.value` & `stopDaysBefore`
  const handleUpdateNumFields =
    (type: SettingsType) => (event: SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement;

      type === 'priceDiff' && debouncedPriceTrack(value);
      type === 'stopDaysBefore' && debouncedDaysTrack(value);

      const updatedValue =
        type === 'priceDiff'
          ? { type: 'actual', value: Number(value) }
          : Number(value);

      updateSettings((prevState) => ({
        ...prevState,
        trackSettings: {
          ...(prevState.trackSettings as TrackFlightsSettings),
          [type]: updatedValue,
        },
      }));
    };

  // update boolean fields - `stopDaysBefore`, `sameFlightsOnly`, `sameSourceOnly`
  const handleUpdateBoolFields = (type: SettingsType) => {
    type === 'sameFlightsOnly' &&
      trackUserAction(TRACK_QUICKFLY_SETTINGS_SAME_LAYOVERS, 'click', {
        value: !settings[type],
      });
    type === 'sameSourceOnly' &&
      trackUserAction(TRACK_QUICKFLY_SETTINGS_ALL_PROVIDERS, 'click', {
        value: !settings[type],
      });
    type === 'receiveNotifications' &&
      trackUserAction(TRACK_QUICKFLY_SETTINGS_NOTIFICATIONS, 'click', {
        value: !settings[type],
      });
    updateSettings((prevState) => ({
      ...prevState,
      trackSettings: {
        ...(prevState.trackSettings as TrackFlightsSettings),
        [type]: !settings[type],
      },
    }));
  };

  const renderNumberField = (type: SettingsType) => {
    const value = type === 'priceDiff' ? settings[type].value : settings[type];
    const label = type === 'priceDiff' ? 'Price' : 'Days';
    const placeholder =
      type === 'priceDiff' ? 'Add minimum price' : 'Add days difference';
    return (
      <InputContainer>
        <StyledNumberField
          placeholder={placeholder}
          size="small"
          type="number"
          label={label}
          value={value}
          onChange={handleUpdateNumFields(type)}
          inputProps={{ step: 1, min: 0 }}
        />
      </InputContainer>
    );
  };

  const { sameFlightsOnly, sameSourceOnly, receiveNotifications } = settings;

  return (
    <Section>
      {renderCommonDetails()}

      <OptionsContainer>
        <FlexWrapper>
          <DollarIcon />
          <Text>
            <span>Notify if flight price goes down more than</span>
            {renderNumberField('priceDiff')}
            <span>dollars.</span>
          </Text>
        </FlexWrapper>
        <FlexWrapper>
          <CalendarIcon />
          <Text>
            <span>Stop looking for cheaper options</span>
            {renderNumberField('stopDaysBefore')}
            <span>days before the flight leaves.</span>
          </Text>
        </FlexWrapper>
        <CustomCheckbox
          data-id="e2e_same-flight-option"
          isCircle={false}
          label="Find new flights for the same layovers."
          checked={sameFlightsOnly}
          onClick={() => handleUpdateBoolFields('sameFlightsOnly')}
        />
        <CustomCheckbox
          data-id="e2e_cheaper-flight-option"
          isCircle={false}
          label="Search for cheaper flights across all providers."
          checked={!sameSourceOnly}
          onClick={() => handleUpdateBoolFields('sameSourceOnly')}
        />
        <CustomCheckbox
          data-id="e2e_track-notification-option"
          isCircle={false}
          label="Receive notification for tracked flights."
          checked={receiveNotifications}
          onClick={() => handleUpdateBoolFields('receiveNotifications')}
        />
      </OptionsContainer>
    </Section>
  );
}

export default memo(FlightPrice);
