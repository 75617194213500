import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { vesselSearchEngine } from '@greywing-maritime/frontend-library/dist/utils/search-engine/vessels-search-config';

import {
  filterETASearchVessels,
  sliderQueryCommands,
} from 'redux/helpers/flotillaSearch';
import { SearchResult, VesselSearchResult } from './types';

export const searchEngine = vesselSearchEngine;

export const getSearchResults = async (
  searchQuery: string,
  vessels: Map<number, Vessel> | undefined
) => {
  const command =
    searchQuery.indexOf(':') > 0 ? searchQuery.split(':')[0].trim() : null;
  const sliderQueryConfig = command ? sliderQueryCommands[command] : null;
  let searchResults =
    // use custom helper to filter vessels for numeric slider search
    sliderQueryConfig && vessels
      ? filterETASearchVessels(
          searchQuery,
          vessels,
          sliderQueryConfig,
          searchEngine
        )
      : await searchEngine.searchEntities(searchQuery);
  const realizedResults: SearchResult[] = [];
  const nRows = searchResults.length;
  for (let i = 0; i < nRows; i++) {
    realizedResults.push({
      ...searchResults[i],
      results: { timeTaken: 0, results: [] },
    });
  }

  for (let rowId = 0; rowId < nRows; rowId++) {
    const rIn = await searchResults[rowId].results();
    for (const result of rIn.results) {
      let resultItem: VesselSearchResult = {
        field: result.item,
        match: (result.matches?.length && result.matches[0].value) || undefined,
      };
      realizedResults[rowId].results.results.push(resultItem);
    }
  }

  return realizedResults;
};
