import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';

import { blue, white } from 'lib/colors';

import {
  Container,
  IconContainer,
  VesselDetails,
  Wrapper,
  Indicator,
} from './common';
import { JourneyVesselVisualCard } from './types';
import CircleIcon from '@mui/icons-material/Circle';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';

const VesselStatusText = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  margin: 0.5rem 0 0.25rem 0;
  color: ${blue};
`;

const CourseBadge = styled.span`
  border-radius: 4px;
  display: inline-block;
  border: 1px solid ${blue};
  background-color: ${blue};
  font-size: 0.7rem;
  padding: 0 0.25rem;
  color: ${white};
`;

const VesselIcon = styled(DirectionsBoatIcon)`
  position: absolute;
  font-size: 15px !important;
  color: white !important;
  top: 8px;
  left: 8px;
`;

const StyledCircleIcon = styled(CircleIcon)`
  color: ${blue};
  font-size: 30px !important;
`;

type Props = {
  journey: JourneyVesselVisualCard;
};

const MAX_CONTAINER_HEIGHT = 370; // minus padding

function JourneyVesselCard({ journey }: Props) {
  const vesselRef = useRef<HTMLDivElement>(null);
  const { course, status, isFinalDestination } = journey;

  useEffect(() => {
    const currentYPosition: number = vesselRef.current?.offsetTop || 0;
    const elementHeight = vesselRef.current?.clientHeight || 0;
    if (currentYPosition > MAX_CONTAINER_HEIGHT) {
      vesselRef.current?.parentElement?.scrollTo({
        top:
          vesselRef.current?.offsetTop -
          MAX_CONTAINER_HEIGHT / 2 +
          elementHeight / 2,
      });
    }
  }, []);
  return (
    <Container ref={vesselRef}>
      <Indicator $isFinalDestination={isFinalDestination} $color="vessel">
        <span className="indicator_arrows one"></span>
        <span className="indicator_arrows two"></span>
        <span className="indicator_arrows three"></span>
      </Indicator>
      <IconContainer>
        <StyledCircleIcon />
        <VesselIcon />
      </IconContainer>

      <Wrapper $isAnchor $isFinalDestination={isFinalDestination}>
        <VesselDetails>
          {course && <CourseBadge>{`${course}°`}</CourseBadge>}
          <VesselStatusText>{status}</VesselStatusText>
        </VesselDetails>
      </Wrapper>
    </Container>
  );
}

export default JourneyVesselCard;
