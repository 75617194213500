import { memo } from 'react';
import styled from 'styled-components/macro';

import { gray70 } from 'lib/colors';
import { BREAK_POINT_L } from 'lib/breakpoints';
import NotFoundImage from './NotFoundImage';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EmptyWrapper = styled(FlexWrapper)<{ $isModal?: boolean }>`
  flex-direction: column;
  height: 100%;
  margin-top: ${({ $isModal }) => ($isModal ? '2rem' : '40%')};
`;

const EmptyText = styled(FlexWrapper)`
  display: flex;
  align-items: center;
  color: ${gray70};
  font-weight: 500;
  font-style: italic;
  justify-content: center;
  padding: 1rem;
  letter-spacing: 0.5px;

  @media screen and (max-width: ${BREAK_POINT_L}) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

type Props = {
  text: string;
  size?: number;
  isModal?: boolean; // indicates if the empty state is rendered in a modal
  style?: any; // custom styles for the empty content
};

// render empty content with a message and an image
function EmptyContent({ text, size = 200, isModal, style }: Props) {
  return (
    <EmptyWrapper $isModal={isModal} style={{ ...(style || {}) }}>
      <NotFoundImage width={size} />
      <EmptyText data-testid="e2e_modal-empty-content">{text}</EmptyText>
    </EmptyWrapper>
  );
}

export default memo(EmptyContent);
