import React from 'react';
import styled from 'styled-components/macro';

import { blue, borderGray, gray } from 'lib/colors';

const Wrapper = styled.blockquote`
  border-width: 1px 0px 0px 6px;
  border-style: solid;
  border-left-color: ${blue};
  border-top-color: ${borderGray};
  border-bottom-color: ${borderGray};
  background-color: ${gray};
  padding: 0.75rem;
  margin: 0px;
  width: 100%;
`;

type Props = {
  children: React.ReactNode;
};

function BlockQuote({ children }: Props): JSX.Element {
  return <Wrapper>{children}</Wrapper>;
}

export default BlockQuote;
