import { lazy, memo, Suspense, useState } from 'react';
import { RestrictionSourceType } from '@greywing-maritime/frontend-library/dist/types/proxPorts';

import { useMobile } from 'hooks';
import { BREAK_POINT_CUSTOM_XS } from 'lib/breakpoints';
import { formatDate } from 'utils/format-date';
import { ReadOnlyPort } from 'components/CrewChangePanel/types';

import { Modal } from 'components/shared';
import {
  Container,
  Sidebar,
  SidebarContent,
  SidebarTitle,
  ItemWrapper,
  SourceName,
  DateText,
  Header,
  EmptyWrapper,
  Main,
  Sources,
} from './common';
import SearchComponent from './SearchComponent';

const ProcessedHTML = lazy(() => import('./ProcessedHTML'));

const noPaddingContentStyles = {
  padding: 0,
  margin: 0,
  overflowX: 'hidden',
};

type Props = {
  port: ReadOnlyPort;
  closeModal: () => void;
};

function ReadOnlyRestrictionsModal({ port, closeModal }: Props) {
  const isMobile = useMobile();
  const { name, restrictionsAvailable, restrictions } = port;
  const title = `Port Restrictions ${name ? `- ${name}` : ''}`;
  const sources = (restrictionsAvailable || []).map(({ source }) => source);

  const isCustomSize = useMobile(BREAK_POINT_CUSTOM_XS);
  const [searchQuery, setSearchQuery] = useState('');
  const [matchCount, setMatchCount] = useState(0);
  const [selected, setSelected] = useState<RestrictionSourceType | undefined>(
    sources[0]
  );

  if (!restrictionsAvailable) {
    return null;
  }

  const handleUpdateSource = (source: RestrictionSourceType | undefined) => {
    setSelected(source);
    setSearchQuery('');
  };

  const renderHeaderComponent = () => {
    return (
      <SearchComponent
        count={matchCount}
        query={searchQuery}
        setQuery={setSearchQuery}
      />
    );
  };

  const renderBody = () => {
    if (!selected || !restrictions?.[selected]?.htmlContent) {
      return (
        <EmptyWrapper>
          <Header>No port restriction details available.</Header>
        </EmptyWrapper>
      );
    }

    return (
      <Main>
        <Suspense fallback={<></>}>
          <ProcessedHTML
            htmlContent={restrictions[selected]!.htmlContent}
            searchQuery={searchQuery}
            setCount={setMatchCount}
          />
        </Suspense>
      </Main>
    );
  };

  return (
    <Modal
      width={900}
      title={title}
      closeModal={closeModal}
      styles={noPaddingContentStyles}
      // Render search component in header for larger dimensions
      headerComponent={!isCustomSize && renderHeaderComponent()}
    >
      <Container $isMobile={isMobile} $noSource={!sources.length}>
        {!isMobile && Boolean(sources.length) && (
          <Sidebar>
            <SidebarContent>
              <SidebarTitle>Sources</SidebarTitle>
              {restrictionsAvailable.map(({ source, latestUpdatedAt }) => {
                const active = selected === source;
                const updateText = latestUpdatedAt
                  ? `Updated on ${formatDate(latestUpdatedAt)}`
                  : 'Last update unavailable';

                return (
                  <ItemWrapper
                    key={source}
                    $active={active}
                    onClick={() => handleUpdateSource(source)}
                  >
                    <SourceName $active={active}>
                      {source.toUpperCase()}
                    </SourceName>
                    <DateText $active={active}>{updateText}</DateText>
                  </ItemWrapper>
                );
              })}
            </SidebarContent>
          </Sidebar>
        )}

        <>
          <Sources
            isMobile={isMobile}
            sources={sources}
            selected={selected}
            setSelected={handleUpdateSource}
            // Render search component inside modal body for smaller dimensions
            searchComponent={isCustomSize && renderHeaderComponent()}
          />
          {renderBody()}
        </>
      </Container>
    </Modal>
  );
}

export default memo(ReadOnlyRestrictionsModal);
