import styled from 'styled-components/macro';

import {
  borderGray,
  darkBlue,
  gray50,
  gray90,
  lightBlue,
  orange,
  textBlack,
  white,
} from 'lib/colors';
import { Thumb } from 'components/shared/AwesomeSlider';
import {
  filterButtonStyles,
  SliderWrapper,
  StyledButton,
} from '../../../common';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import MailIcon from '@mui/icons-material/Mail';

export const ItemWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 0.25rem;
  margin-top: 0.5rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: 1fr 2.25fr;
    grid-gap: 0;
  }
`;

export const StyledSliderWrapper = styled(SliderWrapper)`
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 100% !important;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 75px;
  height: 100%;
  border: 1px solid ${borderGray};
  border-radius: 6px;
  padding: 0.5rem;
  margin-right: 10px;
  flex-shrink: 0;
  transition: all 150ms ease;
`;

export const Header = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: ${textBlack};
`;

export const FilterButton = styled(StyledButton)<{ $stop?: boolean }>`
  ${filterButtonStyles};
  background-color: ${({ $stop }) => ($stop ? `${orange}` : `${darkBlue}`)};
  color: ${white};
`;

export const EmailIcon = styled(MailIcon)`
  color: ${gray50};
  margin-left: 0.4rem;
  font-size: 16px !important;
  margin-top: -6px;
`;

export const Title = styled.div`
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-weight: 600;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${borderGray};
`;

export const menuItemStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.85rem',
  lineHeight: 1.25,
  color: gray90,
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 150ms ease',
  '&:hover': {
    background: lightBlue,
  },
};

export const switchStyles = {
  marginRight: '4px',
  marginLeft: '-6px',
};

export const ThumbHour = (allProps: any) => {
  const { range, ...props } = allProps;
  const { value } = props.ownerState;
  if (range) {
    const hourValue = props['data-index'] ? value[1] : value[0];
    return <Thumb {...props} modifiedValue={`${hourValue}H`} />;
  }
  const modifiedValue = `${value}H`;
  return <Thumb {...props} modifiedValue={modifiedValue} />;
};
