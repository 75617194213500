import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { selectUserAuth } from 'redux/selectors';

type Props = {
  verifyUser?: boolean;
};

// Wrap routes that either don't require authentication
// or will navigate to root route for active session
function UnauthenticatedLayout({ verifyUser }: Props) {
  const { isAuthed } = useSelector(selectUserAuth);

  // if the route requires an authentication check, e.g - `/login`, `/register`
  // naviate to root route for a valid session

  return verifyUser && isAuthed ? <Navigate to="/" /> : <Outlet />;
}

export default UnauthenticatedLayout;
