import toUpper from 'lodash/toUpper';
import { memo } from 'react';
import styled from 'styled-components/macro';

import CountryFlag from './CountryFlag';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

type Props = {
  countryCode: string | undefined;
};

function CrewVisaInfo({ countryCode }: Props) {
  return (
    <FlexWrapper>
      <span>{countryCode || '-'}</span>
      {countryCode && (
        <CountryFlag alpha3Code={toUpper(countryCode)} height={10} />
      )}
    </FlexWrapper>
  );
}

export default memo(CrewVisaInfo);
