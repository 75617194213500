import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch, useModal } from 'hooks';
import sleep from 'lib/sleep';
import { NOT_REQUIRED_TEXT } from 'lib/constants';
import { fetchFlights as fetchFlightsApi } from 'api/flotilla';
import { updateReportFlights } from 'redux/actions';
import { RootState } from 'redux/types';

import { CommonConfirmModal, Tooltip } from 'components/shared';
import FetchingLoaderModal from './FetchingLoaderModal';
import FlightAirports from './FlightAirports';
import AllFlightsModal from '../AllFlightsModal';
import {
  FlexWrapper,
  FlightText,
  Layover,
  ResultsButtonWrapper,
  ResultsButton,
} from './common';

import { ReadOnlyProps } from './types';
import { formatPath, getFlightReqDetails, getLayovers } from '../../../helpers';
import { EmptyFlight, ReadOnlyFlight, ReadOnlyFlightRow } from '../../../types';

function FlightConnection({
  flight: flightRow,
  port: readOnlyPort,
  onSelect,
}: ReadOnlyProps) {
  const dispatch = useAppDispatch();
  const reportFlights = useSelector(
    ({ crewChangeResources }: RootState) =>
      crewChangeResources.responses.reportFlights
  );

  const { modal, setModal } = useModal(null);
  const [fetching, setFetching] = useState(false);

  // handle cases for unavailable flights in the crew flight cell
  if (!flightRow.flight) {
    const { path, crew } = flightRow as EmptyFlight;
    const [from, to] = formatPath(path);

    // when departure & arrival are same
    if (from === to) {
      return (
        <Tooltip content="Same departure & arrival airports">
          <FlightText>{NOT_REQUIRED_TEXT}</FlightText>
        </Tooltip>
      );
    }

    return (
      <Tooltip content={`No available flight for ${crew.name}`}>
        <FlightText>Unavailable {path}</FlightText>
      </Tooltip>
    );
  }

  const {
    crew,
    port,
    flight,
    airports,
    segments,
    requestId,
    filters,
    departure,
    fetchedAt,
  } = flightRow as ReadOnlyFlightRow;

  if (!crew || !port) {
    return null;
  }

  const crewFlights = reportFlights?.[port.locode]?.[crew.id];
  const { airports: layovers = [] } = getLayovers(flightRow as ReadOnlyFlight);
  const { flightRequest, canUpdate } = getFlightReqDetails(requestId);

  const handleFetchFlights = async () => {
    if (!canUpdate || !flightRequest) {
      setModal('confirmNoFlight');
      return;
    }

    const alreadyFetchedFlights = Boolean(crewFlights);

    // do NOT fetch flights again, if already fetched for this crew, for this port
    if (alreadyFetchedFlights) {
      setModal('allFlights');
      return;
    }

    setFetching(true);
    setModal('fetchingLoader');

    const { flights } = await fetchFlightsApi(flightRequest);
    dispatch(
      updateReportFlights({
        flights,
        crewId: crew.id,
        portLocode: port.locode,
      })
    );

    setFetching(false);
    await sleep(100);
    setModal('allFlights');
  };

  const [from, to] = JSON.parse(flight!);
  const airportCodes = [from, ...(layovers?.length ? layovers : []), to];

  const renderLayovers = () => {
    if (!layovers) return null;
    return layovers.length ? (
      <>
        {' > '}
        <Layover>{layovers.join(' > ')}</Layover>
        {' > '}
      </>
    ) : (
      ' > '
    );
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    if (modal.type === 'confirmNoFlight') {
      const description =
        'The request date of this flight is from the past. Cannot fetch flights to allow any update.';
      return (
        <CommonConfirmModal
          type="close"
          title="No Update Available!"
          description={description}
          onConfirm={() => setModal(null)}
        />
      );
    }

    if (modal.type === 'fetchingLoader' || fetching) {
      return <FetchingLoaderModal crew={crew} />;
    }

    if (modal.type === 'allFlights' && filters && crewFlights) {
      const { originalId, crew, confirmed } = flightRow as ReadOnlyFlight;
      const currentFlight = {
        flightId: originalId,
        confirmed,
        crew,
        port: readOnlyPort,
        departureTime: departure?.time,
      };

      return (
        <AllFlightsModal
          isReportView
          currentFlight={currentFlight}
          filters={filters}
          flights={crewFlights}
          closeModal={() => setModal(null)}
          onSelect={onSelect}
        />
      );
    }
  };

  return (
    <FlexWrapper>
      <Tooltip
        content={
          <FlightAirports
            airportCodes={airportCodes}
            airports={airports}
            segments={segments}
          />
        }
      >
        <>
          <span>
            {from} {renderLayovers()}
            {to}
          </span>
          {/* Show `UPDATE` button if it's a recent flight, i.e - if `fetchedAt` field available in the flight */}
          {Boolean(fetchedAt) && (
            <ResultsButtonWrapper>
              <ResultsButton
                data-id="e2e_readonly-flight-update"
                onClick={handleFetchFlights}
              >
                Update
              </ResultsButton>
            </ResultsButtonWrapper>
          )}
        </>
      </Tooltip>

      {renderModal()}
    </FlexWrapper>
  );
}

export default memo(FlightConnection);
