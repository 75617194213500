/* Amplitude Analytics for v2.3.0 and above
Please update notion page before making any changes
https://www.notion.so/greywing/Amplitude-c25026cfd11c4ecc99c6bd22eecfe037 */

// Trigger Type
const START = `start`;
const OPEN = `open`;
const START_EMPTY = `start-empty`;
const START_NEW = `start-new`;
const RESTART = `restart`;
const END = `end`;
const CLOSE = `close`;
const INPUT = `input`;

// Response Type
const SUCCESS = `success`;
const FAIL = `fail`;

// Main Features
const CREW_CHANGE = `crew-change`;
const VESSEL = `vessel`;
const REMINDER = `reminder`;
const DESKTOP_NOTIFICATION = `desktop-notification`;
const SETTINGS = `settings`;
const RULER = `ruler`;
const PROXIMITY_PORT = `proximity-port`;
const SETUP_WIZARD = `setup-wizard`;
const AUTH = `auth`;
const SEARCH = `search`;
const CONTACT = `contact`;
const FLIGHT_SEARCH = `flight-search`;
const SESSION = `session`;
const ERROR = `error`;
const VESSEL_INFO = `vessel-info`;
const ONBOARD = `onboard`;
const MOBILE_ACTION_BUTTONS = `mobile-action-buttons`;
const RISK_AREA = `risk-area`;
const USER = `user`;
const SIDEPANEL_INFO = `sidepanel-info`;
const HOTELS = `hotels`;
const NOTIFICATION_UPDATES = `notification-updates`;
const VESSEL_FILTERS = `vessel-filters`;
const SEARCH_ALERTS = `search-alerts`;
const USER_CONFIGURATION = `user-config`;
const MAP = `map`;
const VESSEL_TAGGING = `vessel-tags`;
const TIME_TRAVEL = `time-travel`;
const SEAGPT = `proteus`;
const CREW_MATRIX = 'crew-matrix';
const PORT_NOTES = 'port-notes';
const PORT_INFO = 'port-info';

// Sub Features or UI Elements
const CCPANEL = 'crew-change-panel';
const CREW_STEP = `crew-step`;
const ROUTE_STEP = `route-step`;
const PORT_STEP = `port-step`;
const FLIGHT_STEP = `flight-step`;
const REGULAR_REPORT = `regular-report`;
const PORT_AGENT_REPORT = `port-agent-report`;
export const COMPARISON_VIEW = `${FLIGHT_STEP}:comparison-view`;
export const FILTERS_VIEW = `${FLIGHT_STEP}:filters-view`;
const PORT_ROW = `${PORT_STEP}:port-row`;
const PORT_ROW_EXPANDED = `${PORT_ROW}:expanded`;
const EMAIL_MODAL = `email-modal`;
const VESSEL_SIDEPANEL = `vessel-sidepanel`;
const ACTION_BAR = `action-bar`;
const SEARCH_RESULTS = `search-results`;
const SEARCH_RESULTS_VESSEL = `${SEARCH_RESULTS}:vessel`;
const SEARCH_RESULTS_PORT_RESTRICTIONS = `${SEARCH_RESULTS}:port-restrictions`;
export const SEARCH_RESULTS_PORT = `${SEARCH_RESULTS}:port`;
const RULER_CONTROLS = `ruler-controls`;
const SETUP_WIZARD_MODAL = `setup-wizard-modal`;
const OUTREACH = `outreach`;
const LOGIN_PAGE = `login-page`;
const ACTION_COLUMN = `action-column`;
const MOBILE_TAB = `mobile-tab`;
const NOTIFICATION_SIDEPANEL = `notification-sidepanel`;
const NU_SEARCHBAR = `search-bar`;
const SEARCH_CONTAINER = `search-container`; // search wizard
const SEARCH_COMMAND_PANEL = `command-panel`;
const SEARCH_RECENT_PANEL = `recent-panel`;
const ETA_SEARCH_BAR = `eta-search`;
const SETTINGS_MODAL = `settings-modal`;
const SUMMARY_FILTER_MAP = `summary-filter-map`;
const MOBILE_TAB_DETAIL = `mobile-tab-detail`;
const RULER_AVG_SPEED = `avg-speed`;
const QUICKFLY = `quickfly`;
const QUICKFLY_CONTROLS = 'quickfly-controls';
const QUICKFLY_FILTER = 'filter';
const QUICKFLY_SEARCH_FILTERS = `search-filters`;
const QUICKFLY_SETUP_TRACK = `setup-track`;
const INFOBOX = `infobox`;
const SEAGPT_MODAL = `${SEAGPT}-modal`;
const SEAGPT_MODAL_SMALL = `${SEAGPT}-modal-small`;
const SEAGPT_PARSED_EMAIL_MODAL = `${SEAGPT}-parsed-email-modal`;
const SEAGPT_COMPARE_EMAIL_MODAL = `${SEAGPT}-compare-email-modal`;
const SEAGPT_THREAD_SIDEPANEL = `${SEAGPT}-thread-sidepanel`;
const NAVIGATE_EVENT = 'navigate-to-details';
const SELECT_ONSIGNER = 'select-onsigner';
const DESELECT_ONSIGNER = 'deselect-onsigner';
const UPDATE_EVENT = 'update-event';
const ACCEPT_EVENT = 'accept-event';
const UNACCEPT_EVENT = 'unaccept-event';
const ADD_OFFSIGNER = 'add-offsigner';
const REMOE_OFFSIGNER = 'remove-offsigner';
const CALCULATE_COMPLIANCE = 'calculate-compliance';
const FORCED_CALCULATE_COMPLIANCE = 'forced_calculate-compliance';
const ONSIGNER_FEEDBACK = 'new-feedback';
const ONSIGNER_FEEDBACK_UPDATE = 'change-feedback';
const REMOVE_FEEDBACK = 'remove-feedback';
const UPDATE_REASON = 'update-reason';
const VIEW_CREW_DETAILS = 'view-crew-details';
const CONFIRM_ONSIGNER = 'confirm-onsigner';
const ONSIGNER_WO_OFFSIGNER = 'onsigner-without-offsigner';
const CCPANEL_PORT_STEP = `${CCPANEL}-${PORT_STEP}`;
const REMINDER_NOTIFICATION = `reminder-notification`;
const CTELEPORT = `c-teleport`;
const ASSIGN_TO_EXISTING_EVENT = 'assign-to-existing-event';
const ASSIGN_TO_NEW_EVENT = 'assign-to-new-event';
const MERGE_CMP_EVENTS = 'merge-events';
const CREATE_CMP_EVENT = 'create-event';
const DELETE_CMP_EVENT = 'delete-event';

// ** Tracking Events **

// C Teleport
export const TRACK_CTELEPORT_PORTS_STEP = `${CTELEPORT}:redirect:${PORT_STEP}`;
export const TRACK_CTELEPORT_FLIGHTS_STEP = `${CTELEPORT}:redirect:${COMPARISON_VIEW}`;

// Port Notes
export const TRACK_CREATE_PORT_NOTES = `${PORT_NOTES}:create:${ACTION_BAR}`;
export const TRACK_CREATE_SIDEPANEL_PORT_NOTES = `${PORT_NOTES}:create:${VESSEL_SIDEPANEL}`;
export const TRACK_CREATE_CCPANEL_PORT_NOTES = `${PORT_NOTES}:create:${CCPANEL_PORT_STEP}`;
export const TRACK_VIEW_SIDEPANEL_PORT_NOTES = `${PORT_NOTES}:view:${VESSEL_SIDEPANEL}`;
export const TRACK_VIEW_CCPANEL_PORT_NOTES = `${PORT_NOTES}:view:${CCPANEL_PORT_STEP}`;

// Crew Matrix Planning
export const TRACK_CREW_MATRIX_EVENT_SCHEDULE = `${CREW_MATRIX}:event:${NAVIGATE_EVENT}`;
export const TRACK_UPDATE_EVENT = `${CREW_MATRIX}:event:${UPDATE_EVENT}`;
export const TRACK_ACCEPT_EVENT = `${CREW_MATRIX}:event:${ACCEPT_EVENT}`;
export const TRACK_UNACCEPT_EVENT = `${CREW_MATRIX}:event:${UNACCEPT_EVENT}`;
export const TRACK_MERGE_CMP_EVENTS = `${CREW_MATRIX}:event:${MERGE_CMP_EVENTS}`;
export const TRACK_CREATE_CMP_EVENT = `${CREW_MATRIX}:event:${CREATE_CMP_EVENT}`;
export const TRACK_DELETE_CMP_EVENT = `${CREW_MATRIX}:event:${DELETE_CMP_EVENT}`;
export const TRACK_ADD_OFFSIGNER = `${CREW_MATRIX}:on-off-pairs:${ADD_OFFSIGNER}`;
export const TRACK_REMOVE_OFFSIGNER = `${CREW_MATRIX}:on-off-pairs:${REMOE_OFFSIGNER}`;
export const TRACK_SELECT_ONSIGNER = `${CREW_MATRIX}:on-off-pairs:${SELECT_ONSIGNER}`;
export const TRACK_DESELECT_ONSIGNER = `${CREW_MATRIX}:on-off-pairs:${DESELECT_ONSIGNER}`;
export const TRACK_CONFIRM_ONSIGNER = `${CREW_MATRIX}:on-off-pairs:${CONFIRM_ONSIGNER}`;
export const TRACK_ADD_ONSIGNER_WITHOUT_OFFSIGNER = `${CREW_MATRIX}:on-off-pairs:${ONSIGNER_WO_OFFSIGNER}`;
export const TRACK_CALCULATE_COMPLIANCE = `${CREW_MATRIX}:on-off-pairs:${CALCULATE_COMPLIANCE}`;
export const TRACK_FORCED_CALCULATE_COMPLIANCE = `${CREW_MATRIX}:on-off-pairs:${FORCED_CALCULATE_COMPLIANCE}`;
export const TRACK_ONSIGNER_FEEDBACK = `${CREW_MATRIX}:onsigner:${ONSIGNER_FEEDBACK}`;
export const TRACK_ONSIGNER_FEEDBACK_UPDATE = `${CREW_MATRIX}:onsigner:${ONSIGNER_FEEDBACK_UPDATE}`;
export const TRACK_REMOVE_FEEDBACK = `${CREW_MATRIX}:onsigner:${REMOVE_FEEDBACK}`;
export const TRACK_ASSIGN_TO_EXISTING_EVENT = `${CREW_MATRIX}:offsigner:${ASSIGN_TO_EXISTING_EVENT}`;
export const TRACK_ASSIGN_TO_NEW_EVENT = `${CREW_MATRIX}:offsigner:${ASSIGN_TO_NEW_EVENT}`;
export const TRACK_UPDATE_REASON = `${CREW_MATRIX}:offsigner:${UPDATE_REASON}`;
export const TRACK_VIEW_DETAILS_FROM_CREW_SEARCH = `${CREW_MATRIX}:crew-search:${VIEW_CREW_DETAILS}`;
export const TRACK_CREATE_BLOCK_OFF = `${CREW_MATRIX}:crew-block-off:create`;
export const TRACK_EDIT_BLOCK_OFF = `${CREW_MATRIX}:crew-block-off:edit`;
export const TRACK_DELETE_BLOCK_OFF = `${CREW_MATRIX}:crew-block-off:delete`;
export const TRACK_CREATE_CREW_NOTE = `${CREW_MATRIX}:crew-info:create-note`;
export const TRACK_DELETE_CREW_NOTE = `${CREW_MATRIX}:crew-info:delete-note`;

// Crew Change
export const TRACK_ADD_CREW = `${CREW_CHANGE}:add-crew:${CREW_STEP}`;
export const TRACK_REMOVE_CREW = `${CREW_CHANGE}:remove-crew:${CREW_STEP}`;
export const TRACK_SELECT_ALL_CREW = `${CREW_CHANGE}:select-all-crew:${CREW_STEP}`;
export const TRACK_GOTO_REVIEW_ROUTE = `${CREW_CHANGE}:go-to-review-route:${CREW_STEP}`;
export const TRACK_GOTO_PORTS = `${CREW_CHANGE}:go-to-ports:${ROUTE_STEP}`;
export const TRACK_SELECT_TOP_3_PORTS = `${CREW_CHANGE}:select-top-3-ports:${PORT_STEP}`;
export const TRACK_GOTO_FLIGHTS = `${CREW_CHANGE}:goto-flights:${FLIGHT_STEP}`;
export const TRACK_COMPARE_FLIGHTS = `${CREW_CHANGE}:compare-flights:${FLIGHT_STEP}`;
export const TRACK_VIEW_FLIGHTS_FILTER = `${CREW_CHANGE}:view-flights-filter:${FLIGHT_STEP}`;
export const TRACK_GO_BACK = (step: string) =>
  `${CREW_CHANGE}:go-back:${step}-step`;
export const TRACK_SELECT_ACTIVE_PORT = `${CREW_CHANGE}:select-active-port:${FLIGHT_STEP}`;
export const TRACK_BOOK_AGENCY = (view: string) =>
  `${CREW_CHANGE}:book-agency:${view}`;
export const TRACK_ADD_PORT_OF_CALL = `${CREW_CHANGE}:add-port-of-call:${ROUTE_STEP}`;
export const TRACK_REMOVE_PORT_OF_CALL = `${CREW_CHANGE}:remove-port-of-call:${ROUTE_STEP}`;
export const TRACK_SEND_EMAIL = `${CREW_CHANGE}:send-email:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_CHANGE_TEMPLATE = `${CREW_CHANGE}:send-email:template:set:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_COPY_ALL = `${CREW_CHANGE}:send-email:copy:all:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_COPY_SECTION = (section: 'crew' | 'vessel') =>
  `${CREW_CHANGE}:send-email:copy:${section}:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_UPLOAD_PASSPORT = `${CREW_CHANGE}:send-email:passport:upload:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_REMOVE_PASSPORT = `${CREW_CHANGE}:send-email:passport:remove:${EMAIL_MODAL}`;
export const TRACK_SEND_EMAIL_FETCH_PASSPORT = `${CREW_CHANGE}:send-email:passport:fetch:${EMAIL_MODAL}`;
export const TRACK_VISA_RESTRICTIONS = `${CREW_CHANGE}:visa-restrictions:${PORT_ROW_EXPANDED}`;
export const TRACK_PORT_RESTRICTIONS = `${CREW_CHANGE}:port-restrictions:${PORT_ROW_EXPANDED}`;
export const TRACK_DISCARD = `${CREW_CHANGE}:discard`;
export const TRACK_CREW_CHANGE_START_EMPTY = `${CREW_CHANGE}:${START_EMPTY}:${VESSEL_SIDEPANEL}`;
export const TRACK_CREW_CHANGE_FETCH_PREVIOUS = `${CREW_CHANGE}:fetch-previous:${VESSEL_SIDEPANEL}`;
export const TRACK_CREW_CHANGE_START_NEW = `${CREW_CHANGE}:${START_NEW}`;
export const TRACK_CREW_CHANGE_READ_ONLY_FAIL = `${CREW_CHANGE}:read-only:validation:${FAIL}`;
export const TRACK_ONE_CLICK_CREW_CHANGE_START = `${CREW_CHANGE}:one-click:start:${VESSEL_SIDEPANEL}`;
export const TRACK_ONE_CLICK_CREW_CHANGE_STOP = `${CREW_CHANGE}:one-click:stop`;
export const TRACK_ONE_CLICK_CREW_CHANGE_REVIEW = `${CREW_CHANGE}:one-click:review`;
export const TRACK_ONE_CLICK_CREW_CHANGE_SHARE = `${CREW_CHANGE}:one-click:start-share`;
export const TRACK_DOWNLOAD_REGULAR_CC_PLAN = `${CREW_CHANGE}:download-cc-plan:${REGULAR_REPORT}`;
export const TRACK_DOWNLOAD_PORT_AGENT_CC_PLAN = `${CREW_CHANGE}:download-cc-plan:${PORT_AGENT_REPORT}`;

// Fleet Controls
export const TRACK_ADD_VESSEL_OPEN = `${VESSEL}:add-vessel:${OPEN}:${ACTION_BAR}`;

// Vessel Configuration
export const TRACK_ASSIGN_PIC = `${VESSEL}:assign-pic:${VESSEL_SIDEPANEL}`;

// Vessel SidePanel
export const TRACK_VESSEL_SIDEPANEL_OPEN = `${VESSEL_SIDEPANEL}:${OPEN}`;
export const TRACK_VESSEL_SIDEPANEL_CLOSE = `${VESSEL_SIDEPANEL}:${CLOSE}`;

// Reminders
export const TRACK_REMINDER_START = `${REMINDER}:${START}:${ACTION_BAR}`;
export const TRACK_REMINDER_TOGGLE = `${REMINDER}:toggle:${ACTION_COLUMN}`;
export const TRACK_REMINDER_TOGGLE_MOBILE = `${REMINDER}:toggle:${MOBILE_TAB}`;

// Settings
export const TRACK_SETTINGS_START = `${SETTINGS}:${START}:${ACTION_BAR}`;
export const TRACK_SETTINGS_VALIDATION_FAIL = `${SETTINGS}:validation:${FAIL}`;
export const TRACK_SETTINGS_UPDATE = (activeTab: string) =>
  `${SETTINGS}:update:footer:${activeTab}:${SETTINGS_MODAL}`;

// Vessel Information
export const TRACK_DOWNLOAD_CSV = `${VESSEL}:download-csv:${VESSEL_SIDEPANEL}`;
export const TRACK_VESSEL_INFO_TOGGLE = `${VESSEL_INFO}:toggle:${ACTION_COLUMN}`;
export const TRACK_VESSEL_INFO_TOGGLE_MOBILE = `${VESSEL_INFO}:toggle:${MOBILE_TAB}`;
export const TRACK_VESSEL_SELECT = `${VESSEL_INFO}:select-vessel:${MAP}`;
export const TRACK_VESSEL_SELECT_MOBILE = `${VESSEL_INFO}:select-vessel:${MAP}:mobile`;
export const TRACK_VESSEL_MULTI_SELECT = `${VESSEL_INFO}:select-multiple-vessel:${MAP}`;

// Vessel Filters
export const TRACK_VF_TOGGLE_MOBILE = `${VESSEL_FILTERS}:toggle:${MOBILE_TAB}`;
export const TRACK_VF_ADD_FILTER = `${VESSEL_FILTERS}:add-filter:${SEARCH_RESULTS_VESSEL}`;
export const TRACK_VF_REMOVE_ALL = `${VESSEL_FILTERS}:remove-all:${SUMMARY_FILTER_MAP}`;
export const TRACK_VF_REMOVE = `${VESSEL_FILTERS}:remove:${SUMMARY_FILTER_MAP}`;
export const TRACK_VF_REMOVE_ALL_SIDEPANEL = `${VESSEL_FILTERS}:remove-all:${VESSEL_SIDEPANEL}`;
export const TRACK_VF_REMOVE_SIDEPANEL = `${VESSEL_FILTERS}:remove:${VESSEL_SIDEPANEL}`;
export const TRACK_VF_REMOVE_MOBILE = `${VESSEL_FILTERS}:remove:${MOBILE_TAB_DETAIL}`;
export const TRACK_VF_REMOVE_ALL_MOBILE = `${VESSEL_FILTERS}:remove-all:${MOBILE_TAB_DETAIL}`;

// Ruler
export const TRACK_USE_SIMPLE = `${RULER}:use-simple:${RULER_CONTROLS}`;
export const TRACK_USE_SEA_ROUTE = `${RULER}:use-sea-route:${RULER_CONTROLS}`;
export const TRACK_RULER_RESTART = `${RULER}:${RESTART}:${RULER_CONTROLS}`;
export const TRACK_RULER_START = `${RULER}:${START}:${ACTION_BAR}`;
export const TRACK_RULER_PLUS = `${RULER}:${RULER_AVG_SPEED}:increase:${RULER_CONTROLS}`;
export const TRACK_RULER_MINUS = `${RULER}:${RULER_AVG_SPEED}:decrease:${RULER_CONTROLS}`;

// Proximity Ports
export const TRACK_PROXIMITY_PORT_START = `${PROXIMITY_PORT}:${START}:${ACTION_BAR}`;

// Setup Wizard
export const TRACK_VESSEL_ADDED = `${VESSEL}:vessel-added:${SETUP_WIZARD_MODAL}`;
export const TRACK_SETUP_WIZARD_START = `${SETUP_WIZARD}:${START}:${ACTION_BAR}`;
export const TRACK_SETUP_WIZARD_START_MOBILE = `${SETUP_WIZARD}:${START}:${MOBILE_ACTION_BUTTONS}`;
export const TRACK_SETUP_ROLE = `${USER}:role:${SETUP_WIZARD_MODAL}`;
export const TRACK_SETUP_NEXT_STEP = `${SETUP_WIZARD}:next-step`;
export const TRACK_SETUP_PREV_STEP = `${SETUP_WIZARD}:prev-step`;
export const TRACK_SETUP_COMPLETE_STEP = `${SETUP_WIZARD}:complete`;
export const TRACK_SETUP_CANCEL_CLOSE = `${SETUP_WIZARD}:cancel`;
export const TRACK_VESSEL_SET_COLOR = (field: string) =>
  `${VESSEL_INFO}:set-color-field:${field}:${SETUP_WIZARD_MODAL}`;
export const TRACK_VESSEL_SET_POPUP_INFO = (field: string) =>
  `${VESSEL_INFO}:show-popup-field:${field}:${SETUP_WIZARD_MODAL}`;
export const TRACK_VESSEL_SET_POPUP_INFO_FAIL = (field: string) =>
  `${VESSEL_INFO}:show-popup-field:fail:${field}:${SETUP_WIZARD_MODAL}`;
export const TRACK_SIDEPANEL_SET_FIELD = (field: string) =>
  `${SIDEPANEL_INFO}:show:${field}:${SETUP_WIZARD_MODAL}`;

// Authentication
export const TRACK_USER_LOGOUT = `${AUTH}:user-logout:account:${SETTINGS_MODAL}`;
export const TRACK_USER_LOGIN = `${AUTH}:user-login:${LOGIN_PAGE}`;
export const TRACK_USER_LOGIN_SSO_MSAL = `${AUTH}:user-login:sso:msal:success:${LOGIN_PAGE}`;
export const TRACK_USER_LOGIN_SSO_MSAL_FAIL = `${AUTH}:user-login:sso:msal:fail:${LOGIN_PAGE}`;
export const TRACK_USER_MAGIC_LOGIN = `${AUTH}:user-magic-login`;

// Search
// deprecated
// export const TRACK_ADD_FILTER = `${SEARCH}:add-filter:${SEARCH_RESULTS_VESSEL}`;
// export const TRACK_SET_ALERT = `${SEARCH}:set-alert:${SEARCH_RESULTS_VESSEL}`;
export const TRACK_SEARCH_VESSEL_INPUT = `${SEARCH}:vessel:${INPUT}`;
export const TRACK_SEARCH_PORT_RESTRICTIONS_INPUT = `${SEARCH}:port-restrictions:${INPUT}`;
export const TRACK_SEARCH_PORT_MAP_INPUT = `${SEARCH}:port-map:${INPUT}`;
export const TRACK_SEARCH_HOTEL_PORT_INPUT = `${SEARCH}:hotel-port:${INPUT}`;
export const TRACK_SEARCH_OPEN = `${SEARCH}:open:${SEARCH_CONTAINER}`;
export const TRACK_SEARCH_CLOSE = `${SEARCH}:close:${SEARCH_CONTAINER}`;
export const TRACK_SEARCH_SELECT_COMMAND = `${SEARCH}:shortcut-commands:${SEARCH_COMMAND_PANEL}`;
export const TRACK_RECENT_SEARCH_SELECT = `${SEARCH}:recent-search:${SEARCH_RECENT_PANEL}`;
export const TRACK_ETA_SEARCH_VESSEL_INPUT = (field: string) =>
  `${SEARCH}:vessel:${INPUT}:${field}:${ETA_SEARCH_BAR}`;
export const TRACK_SEARCH_CLEAR = `${SEARCH}:clear:${SEARCH_CONTAINER}`;

// Deprecated
export const TRACK_PIRACY = `${VESSEL}:piracy:${VESSEL_SIDEPANEL}`;
export const TRACK_GW_FLIGHT_SEARCH = `${SEARCH}:gw-${FLIGHT_SEARCH}`;

// Contact
export const TRACK_HELP = `${CONTACT}:help:${START}:${OUTREACH}`;
export const TRACK_HELP_MOBILE = `${CONTACT}:help:${START}:${MOBILE_ACTION_BUTTONS}`;

// Port Restrictions
export const TRACK_VIEW_PORT_RESTRICTIONS = `${SEARCH}:port-restrictions:${SEARCH_RESULTS_PORT_RESTRICTIONS}`;

// Hotels
export const TRACK_GET_PORT_HOTELS = `${HOTELS}:select-port:${SEARCH_RESULTS}:ports`;

// Browser
export const TRACK_SESSION_START = `${SESSION}:${START}`;
export const TRACK_SESSION_END = `${SESSION}:${END}`;

// OnBoard
export const TRACK_ONBOARD_SETUP_FLOTILLA = `${ONBOARD}:setup-flotilla`;
export const TRACK_ONBOARD_ADD_VESSEL = `${ONBOARD}:add-vessel`;
export const TRACK_ONBOARD_START_CC = `${ONBOARD}:start-cc`;
export const TRACK_ONBOARD_START_CC_COMPARE = `${ONBOARD}:start-cc-compare`;
export const TRACK_ONBOARD_ADD_SEARCH_FILTER = `${ONBOARD}:add-search-filter`;
export const TRACK_ONBOARD_VIDEO = (id: string, step: string) =>
  `${ONBOARD}:video:${step}:${id}`;
export const TRACK_ONBOARD_TOGGLE = `${ONBOARD}:toggle:${ACTION_COLUMN}`;

// Fight search params in Settings modal
// Applied to both CC panel & QuickFly
export const TRACK_FLIGHT_PARAMS_TOTAL_TIME = `${FLIGHT_SEARCH}:total-time:${SETTINGS_MODAL}`;
export const TRACK_FLIGHT_PARAMS_LAYOVER_TIME = `${FLIGHT_SEARCH}:layover-time:${SETTINGS_MODAL}`;
export const TRACK_FLIGHT_PARAMS_STOPS_COUNT = `${FLIGHT_SEARCH}:stops-count:${SETTINGS_MODAL}`;
// only applied to QuickFly & not in CC Panel (crew-change planning has vessel specific TMC)
export const TRACK_FLIGHT_PARAMS_QUICKFLY_TMC = `${FLIGHT_SEARCH}:quickfly-tmc:${SETTINGS_MODAL}`;

// Notification Updates
export const TRACK_NU_TOGGLE = `${NOTIFICATION_UPDATES}:toggle:${ACTION_COLUMN}`;
export const TRACK_NU_TOGGLE_MOBILE = `${NOTIFICATION_UPDATES}:toggle:${MOBILE_TAB}`;
export const TRACK_NU_SET_TAB = `${NOTIFICATION_UPDATES}:tab:header:${NOTIFICATION_SIDEPANEL}`;
export const TRACK_NU_ADD_ALERT_ADD_NS = `${NOTIFICATION_UPDATES}:add-alert:add:${NU_SEARCHBAR}:${NOTIFICATION_SIDEPANEL}`;
export const TRACK_NU_SEARCH = `${NOTIFICATION_UPDATES}:search:${NU_SEARCHBAR}:${NOTIFICATION_SIDEPANEL}`;
export const TRACK_NU_ADD_ALERT_START_SETTINGS = `${NOTIFICATION_UPDATES}:add-alert:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_NU_ADD_ALERT_ADD_SETTINGS = `${NOTIFICATION_UPDATES}:add-alert:add:alerts:${SETTINGS_MODAL}`;
export const TRACK_NU_ADD_ALERT_DISMISS_SETTINGS = `${NOTIFICATION_UPDATES}:add-alert:dismiss:alerts:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_START = `${NOTIFICATION_UPDATES}:edit-alert:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_DELETE_START = `${NOTIFICATION_UPDATES}:delete-alert:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_DELETE = `${NOTIFICATION_UPDATES}:delete-alert:delete:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_DELETE_DISMISS = `${NOTIFICATION_UPDATES}:delete-alert:dismiss:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_DISMISS = `${NOTIFICATION_UPDATES}:edit-alert:dismiss:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_NU_EDIT_ALERT_SAVE = `${NOTIFICATION_UPDATES}:edit-alert:save:alerts:modal:${SETTINGS_MODAL}`;

// Search Alerts
export const TRACK_SA_ADD_ALERT = `${SEARCH_ALERTS}:add-alert:${SEARCH_RESULTS_VESSEL}`;
export const TRACK_SA_EXPAND_ALERT = `${SEARCH_ALERTS}:expand-alert:alerts:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_START = `${SEARCH_ALERTS}:edit-alert:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_DELETE_START = `${SEARCH_ALERTS}:delete-alert:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_DELETE = `${SEARCH_ALERTS}:delete-alert:delete:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_DELETE_DISMISS = `${SEARCH_ALERTS}:delete-alert:dismiss:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_DISMISS = `${SEARCH_ALERTS}:edit-alert:dismiss:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_SA_EDIT_ALERT_SAVE = `${SEARCH_ALERTS}:edit-alert:save:alerts:modal:${SETTINGS_MODAL}`;
export const TRACK_SA_ADD_ALERT_FILTER_MAP = `${SEARCH_ALERTS}:add-alert:add:${SUMMARY_FILTER_MAP}`;
export const TRACK_SA_ADD_ALERT_MOBILE = `${SEARCH_ALERTS}:add-alert:add:${MOBILE_TAB_DETAIL}`;

// Risk Area Tracking
export const TRACK_SHOW_ALL_RISK_AREAS = `${RISK_AREA}:show-all-areas`;
export const TRACK_HIDE_ALL_RISK_AREAS = `${RISK_AREA}:hide-all-areas`;
export const TRACK_ADD_TRACKING_REQUEST_START = `${RISK_AREA}:add-tracking-request:start`;
export const TRACK_ADD_TRACKING_REQUEST_SUBMIT = `${RISK_AREA}:add-tracking-request:submit`;
export const TRACK_DOWNLOAD_TRACKING_REQUEST_START = `${RISK_AREA}:download-csv:start`;
export const TRACK_DOWNLOAD_TRACKING_REQUEST_CANCEL = `${RISK_AREA}:download-csv:cancel`;
export const TRACK_DOWNLOAD_TRACKING_REQUEST_DOWNLOAD = `${RISK_AREA}:download-csv:submit`;

// Vessel Tagging
export const TRACK_ADD_VESSEL_TAG_SINGLE = `${VESSEL_TAGGING}:add:single-vessel:${VESSEL_SIDEPANEL}`;
export const TRACK_REMOVE_VESSEL_TAG_SINGLE = `${VESSEL_TAGGING}:remove:single-vessel:${VESSEL_SIDEPANEL}`;
export const TRACK_ADD_VESSEL_TAG_MULTI = `${VESSEL_TAGGING}:add:multi-vessel:${VESSEL_SIDEPANEL}`;
export const TRACK_REMOVE_VESSEL_TAG_MULTI = `${VESSEL_TAGGING}:remove:multi-vessel:${VESSEL_SIDEPANEL}`;

// User Configuration
export const TRACK_EDIT_NOTIFICATION_SETTINGS_START = `${USER_CONFIGURATION}:edit-notification-settings:${START}:alerts:${SETTINGS_MODAL}`;
export const TRACK_EDIT_NOTIFICATION_SETTINGS_DISMISS = `${USER_CONFIGURATION}:edit-notification-settings:dismiss:alerts:${SETTINGS_MODAL}`;
export const TRACK_EDIT_NOTIFICATION_SETTINGS_SAVE = `${USER_CONFIGURATION}:edit-notification-settings:save:alerts:${SETTINGS_MODAL}`;

// Uncategorised
export const TRACK_MOBILE_ACTION_TOGGLE = `mobile-action-menu:toggle:${MOBILE_TAB}`;
export const TRACK_PAYLOAD_RESPONSE = `fixed-download:randfile`;

// Error Boundary
export const TRACK_CATCH_ERROR = `${ERROR}:catch`;

// Redux
export const REDUX_ACTION_MAP: { [key: string]: string } = {
  'mapVessels/addVesselAsync/fulfilled': `${VESSEL}:vessel-added:${SUCCESS}`,
  'mapVessels/addVesselAsync/rejected': `${VESSEL}:vessel-added:${FAIL}`,
  'mapVessels/updateInCharge': `${VESSEL}:update-pic`,
  'reminders/addReminderAsync/fulfilled': `${REMINDER}:reminder-added:${SUCCESS}`,
  'reminders/addReminderAsync/rejected': `${REMINDER}:reminder-added:${FAIL}`,
  'reminders/removeReminderAsync/fulfilled': `${REMINDER}:reminder-removed:${SUCCESS}`,
  'reminders/removeReminderAsync/rejected': `${REMINDER}:reminder-removed:${FAIL}`,
  'desktopNotifications/toggleDesktopNotifications': `${SETTINGS}:toggle-desktop-notifications`,
  'infoboxPopup/setInfoboxPopup': `${VESSEL_INFO}:popup-appeared`,
  'infoboxPopup/clearInfoboxPopup': `${VESSEL_INFO}:popup-cleared`,
  'mapRuler/startMapRuler': `${RULER}:${START}`,
  'mapRuler/closeMapRuler': `${RULER}:${CLOSE}`,
  'portsInRange/togglePortsInRange': `${PROXIMITY_PORT}:toggle`,
  'portsInRange/setRangeNauticalMiles': `${PROXIMITY_PORT}:set-range`,
  'setupWizard/toggleWizardVisiblity': `${SETUP_WIZARD}:toggle`,
  'riskArea/addRiskAreaTrackingRequestAsync/fulfilled': `${RISK_AREA}:tracking-request-added:${SUCCESS}`,
  'riskArea/addRiskAreaTrackingRequestAsync/rejected': `${RISK_AREA}:tracking-request-added:${FAIL}`,
  'riskArea/deleteRiskAreaTrackingRequestAsync/fulfilled': `${RISK_AREA}:tracking-request-archived:${FAIL}`,
  'riskArea/deleteRiskAreaTrackingRequestAsync/rejected': `${RISK_AREA}:tracking-request-archived:${SUCCESS}`,
};

// quickFly Controls
export const TRACK_QUICKFLY_INPUT = (type: 'arrival' | 'departure') =>
  `${QUICKFLY}:${INPUT}:${type}:${QUICKFLY_CONTROLS}`;
export const TRACK_QUICKFLY_DATE = `${QUICKFLY}:${INPUT}:date:${QUICKFLY_CONTROLS}`;
export const TRACK_QUICKFLY_DATE_INPUT = `${QUICKFLY}:${INPUT}:date:date-input:${QUICKFLY_CONTROLS}`;
export const TRACK_QUICKFLY_FETCH_FLIGHTS = `${QUICKFLY}:fetch-flights:${QUICKFLY_CONTROLS}`;

type QuickFlyComp = 'expanded-modal' | 'quickfly-controls';

// quickfly search filters
export const TRACK_QUICKFLY_SEARCH_FILTERS_STATE = (
  state: 'expand' | 'collapse',
  component: QuickFlyComp
) => `${QUICKFLY}:${QUICKFLY_SEARCH_FILTERS}:${state}:${component}`;
export const TRACK_QUICKFLY_CATEGORY = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:category:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_ARRIVAL_RANGE = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:arrival-range:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_DEPARTURE_RANGE = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:departure-range:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_FLIGHT_TIME = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:flight-time:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_LAYOVER_RANGE = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:layover-range:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_STOPS_NO = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:stops-no:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_MARINE = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:marine:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_SOURCE = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:source:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_LAYOVERS = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:layovers:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_LAYOVERS_SELECT_ALL = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:layovers:select-all:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_AIRLINES = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:airlines:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_AIRLINES_SELECT_ALL = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:airlines:select-all:${QUICKFLY_SEARCH_FILTERS}:${component}`;
export const TRACK_QUICKFLY_AIRPORT_TRANSFER = (component: QuickFlyComp) =>
  `${QUICKFLY}:${QUICKFLY_FILTER}:airport-transfer:${QUICKFLY_SEARCH_FILTERS}:${component}`;

// quickfly flight results
export const TRACK_QUICKFLY_FLIGHT_RESULTS = `${QUICKFLY}:flight-results:expand-results:open:${QUICKFLY_CONTROLS}`;
export const TRACK_QUICKFLY_MATCHING_FLIGHT_RESULTS_STATE = (
  state: 'expand' | 'collapse',
  component: QuickFlyComp
) => `${QUICKFLY}:flight-results:matching:${state}:${component}`;
export const TRACK_QUICKFLY_NON_MATCHING_FLIGHT_RESULTS_STATE = (
  state: 'expand' | 'collapse',
  component: QuickFlyComp
) => `${QUICKFLY}:flight-results:non-matching:${state}:${component}`;

// quickfly result card
export const TRACK_QUICKFLY_LAYOVER_MATCHING_FLIGHT_RESULTS = (
  component: QuickFlyComp
) => `${QUICKFLY}:flight-results:matching:flight:layover:expand:${component}`;
export const TRACK_QUICKFLY_LAYOVER_NON_MATCHING_FLIGHT_RESULTS = (
  component: QuickFlyComp
) =>
  `${QUICKFLY}:flight-results:non-matching:flight:layover:expand:${component}`;
export const TRACK_QUICKFLY_MODAL_CLOSE = `${QUICKFLY}:close:expanded-modal`;

// quickfly flight tracking
export const TRACK_QUICKFLY_FLIGHT_TRACKING = (component: QuickFlyComp) =>
  `${QUICKFLY}:track:flight-results:matching:flight:${component}`;
export const TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_CLOSE = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:close:confirm-modal`;
export const TRACK_QUICKFLY_FLIGHT_TRACKING_MODAL_GOTO_SETTINGS = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:goto-settings:confirm-modal`;
export const TRACK_QUICKFLY_FLIGHT_TRACKING_SUCCESS = `${QUICKFLY}:track:success`;
export const TRACK_QUICKFLY_FLIGHT_TRACKING_FAIL = `${QUICKFLY}:track:fail`;

// quickfly flight tracking in settings modal
export const TRACK_QUICKFLY_SETTINGS_DEFAULT = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:start-default:alerts:${SETTINGS_MODAL}`;
export const TRACK_QUICKFLY_SETTINGS_PRICE = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:price:alerts:${SETTINGS_MODAL}`;
export const TRACK_QUICKFLY_SETTINGS_DAYS = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:days:alerts:${SETTINGS_MODAL}`;
export const TRACK_QUICKFLY_SETTINGS_SAME_LAYOVERS = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:same-layovers:alerts:${SETTINGS_MODAL}`;
export const TRACK_QUICKFLY_SETTINGS_ALL_PROVIDERS = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:all-providers:alerts:${SETTINGS_MODAL}`;
export const TRACK_QUICKFLY_SETTINGS_NOTIFICATIONS = `${QUICKFLY}:${QUICKFLY_SETUP_TRACK}:notifications:alerts:${SETTINGS_MODAL}`;

// infobox
export const TRACK_INFOBOX_TAP_TO_SEE_DETAILS = `${VESSEL_INFO}:open-selected-vessels:${INFOBOX}`;
export const TRACK_INFOBOX_CLEAR_SELECTION = `${VESSEL_INFO}:clear-selected-vessels:${INFOBOX}`;
export const TRACK_INFOBOX_DISMISS = `${VESSEL_INFO}:vessel-popup-info:dismiss:${INFOBOX}`;

// general app
export const TRACK_APP_INITIAL_LOAD = `load:start-up:completed`;
export const TRACK_APP_PORT_CALL_LOAD = `load:port-calls:completed`;
export const TRACK_APP_GRAPH_LOAD = `load:map-graph:completed`;
export const TRACK_APP_TIME_TRAVEL_LOAD = `load:${TIME_TRAVEL}:completed`;
export const TRACK_APP_SKIP_LOAD = `load:start-up:skip`;
export const TRACK_TEST_NOTIFICATION = (
  state: 'request' | 'show' | 'error' | 'close' | 'click'
) => `desktop-notification:${state}:test`;
export const TRACK_GENERAL_NOTIFICATION = (
  state: 'request' | 'show' | 'error' | 'close' | 'click'
) => `desktop-notification:${state}:notification-update`;

// time travel
export const TRACK_TIME_TRAVEL_START = `${TIME_TRAVEL}:start:${ACTION_BAR}`;
export const TRACK_TIME_TRAVEL_SHOW_ROUTE = `${TIME_TRAVEL}:show-route:controls`;
export const TRACK_TIME_TRAVEL_DISMISS_CONTROLS = `${TIME_TRAVEL}:dismiss:controls`;
export const TRACK_TIME_TRAVEL_DISMISS_LOADING = `${TIME_TRAVEL}:dismiss:loading-modal`;
export const TRACK_TIME_TRAVEL_PLAY = `${TIME_TRAVEL}:play:controls`;

// proteus
export const TRACK_SEAGPT_LIKE_MESSAGE_MODAL = (type: string) =>
  `${SEAGPT}:${type}:like:${SEAGPT_MODAL}`;
export const TRACK_SEAGPT_LIKE_MESSAGE_MODAL_SMALL = (type: string) =>
  `${SEAGPT}:${type}:like:${SEAGPT_MODAL_SMALL}`;
export const TRACK_SEAGPT_DISLIKE_MESSAGE_MODAL = (type: string) =>
  `${SEAGPT}:${type}:dislike:${SEAGPT_MODAL}`;
export const TRACK_SEAGPT_DISLIKE_MESSAGE_MODAL_SMALL = (type: string) =>
  `${SEAGPT}:${type}:dislike:${SEAGPT_MODAL_SMALL}`;
export const TRACK_SEAGPT_START = (
  ui: 'action-bar' | 'outreach' | 'keyboard-shortcut'
) => `${SEAGPT}:start:${ui}`;
export const TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL = `${SEAGPT}:start:concierge:agent-email:${VESSEL_SIDEPANEL}`;
export const TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL_SELECT_PORT = `${SEAGPT}:start:concierge:agent-email:select-port-modal`;
export const TRACK_SEAGPT_PUSH_TO_TALK = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:push-to-talk:start:${ui}`;
export const TRACK_SEAGPT_PUSH_TO_TALK_ERROR = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:push-to-talk:error:${ui}`;
export const TRACK_SEAGPT_START_EMAIL = `${SEAGPT}:draft-agent-email:start:email`;
export const TRACK_SEAGPT_SEND_EMAIL = `${SEAGPT}:draft-agent-email:send:email`;
export const TRACK_SEAGPT_SEND_EMAIL_ERROR = `${SEAGPT}:draft-agent-email:send:email:error`;
export const TRACK_SEAGPT_OPEN_EDIT_EMAIL = `${SEAGPT}:draft-agent-email:open:edit-email`;
export const TRACK_SEAGPT_OPEN_SAVED_EMAIL = `${SEAGPT}:draft-agent-email:open:saved-email`;

export const TRACK_REMINDER_NOTIFICATION_SENT = `${DESKTOP_NOTIFICATION}:sent:${REMINDER_NOTIFICATION}`;
export const TRACK_REMINDER_NOTIFICATION_CLICK = `${DESKTOP_NOTIFICATION}:click:${REMINDER_NOTIFICATION}`;
export const TRACK_REMINDER_NOTIFICATION_SHOW = `${DESKTOP_NOTIFICATION}:show:${REMINDER_NOTIFICATION}`;
export const TRACK_REMINDER_NOTIFICATION_ERROR = `${DESKTOP_NOTIFICATION}:error:${REMINDER_NOTIFICATION}`;
export const TRACK_REMINDER_NOTIFICATION_CLOSE = `${DESKTOP_NOTIFICATION}:close:${REMINDER_NOTIFICATION}`;

export const TRACK_SEAGPT_SELECT_THREAD = `${SEAGPT}:thread:select:${SEAGPT_MODAL}:${SEAGPT_THREAD_SIDEPANEL}`;
export const TRACK_SEAGPT_NEW_THREAD = `${SEAGPT}:thread:add:${SEAGPT_MODAL}:${SEAGPT_THREAD_SIDEPANEL}`;
export const TRACK_SEAGPT_SWITCH_COMPARE_MODE = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:compare:switch:mode:${ui}:inbox`;
export const TRACK_SEAGPT_COMPARE = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:compare:start:${ui}:inbox`;
export const TRACK_SEAGPT_SELECT_COMPARE = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:compare:select-email:${ui}:inbox`;
export const TRACK_SEAGPT_DESELECT_COMPARE = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:compare:deselect-email:${ui}:inbox`;
export const TRACK_SEAGPT_VIEW_CLARIFICATION = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:view-clarification:${ui}:inbox`;
export const TRACK_SEAGPT_VIEW_PARSED = (
  ui: typeof SEAGPT_MODAL | typeof SEAGPT_MODAL_SMALL
) => `${SEAGPT}:view-parsed-email:${ui}:inbox`;
export const TRACK_SEAGPT_VIEW_CLARIFICATION_EXTRACTION = `${SEAGPT}:view-clarification:${SEAGPT_PARSED_EMAIL_MODAL}`;
export const TRACK_SEAGPT_VIEW_ORIGINAL = `${SEAGPT}:view-original:${SEAGPT_PARSED_EMAIL_MODAL}`;
export const TRACK_SEAGPT_SELECT_INDIVIDUAL_COSTS = `${SEAGPT}:compare:select-individual-costs:${SEAGPT_COMPARE_EMAIL_MODAL}`;
export const TRACK_SEAGPT_NOTIFICATION_CLICK = `${SEAGPT}:email-notification:click`;
export const TRACK_SEAGPT_NOTIFICATION_SHOW = `${SEAGPT}:email-notification:show`;
export const TRACK_SEAGPT_NOTIFICATION_ERROR = `${SEAGPT}:email-notification:error`;

// port info
export const TRACK_PORT_INFO_PIN = (ui: string) => `${PORT_INFO}:pin:${ui}`;
export const TRACK_PORT_INFO_UNPIN = (ui: string) =>
  `${PORT_INFO}:pin:unpin:${ui}`;
export const TRACK_PORT_INFO_ZOOM = `${PORT_INFO}:zoom:${SEARCH_RESULTS_PORT}`;
export const TRACK_PORT_INFO_SELECT_PORT = `${PORT_INFO}:select-port:map`;
export const TRACK_PORT_INFO_NEARBY_AIRPORTS = `${PORT_INFO}:nearby-airports:show:map`;
export const TRACK_PORT_INFO_NEARBY_AIRPORTS_HIDE = `${PORT_INFO}:nearby-airports:hide:map`;
export const TRACK_PORT_INFO_DISMISS = `${PORT_INFO}:dismiss:${INFOBOX}`;
export const TRACK_PORT_INFO_CLICK_VESSEl = `${PORT_INFO}:port-call:select-vessel:${INFOBOX}`;
export const TRACK_PORT_INFO_CONTEXT_MENU = `${PORT_INFO}:context-menu:map`;
