import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import AsyncQueue from 'async-interval-queue';

import { updateTTReadyState } from 'redux/actions';
import {
  selectMapVessels,
  selectPortCalls,
  selectTimeTravel,
} from 'redux/selectors';
import { fetchAndPrepareRoute } from 'components/TimeTravel/helpers';

import { RouteCalculatorContext } from 'contexts/RouteCalculatorContext';
import useAppDispatch from './useAppDispatch';
import {
  selectTimeTravelCompletion,
  selectTimeTravelData,
} from 'redux/reducers/timeTravelData';

const Queue = new AsyncQueue(500);

function useTimeTravel() {
  const dispatch = useAppDispatch();
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const { portCallRequests, portCalls } = useSelector(selectPortCalls);
  const { isReady } = useSelector(selectTimeTravel);
  const { travels } = useSelector(selectTimeTravelData);

  const completion = selectTimeTravelCompletion(vessels, travels);

  const { getVesselRoute } = useContext(RouteCalculatorContext);

  const portCallsAreReady = useMemo(() => {
    const requestIds = Object.keys(portCallRequests).map(Number);
    const missingPortCallRequests = Array.from(vessels.keys()).filter(
      (vesselId) => !requestIds.includes(vesselId)
    );
    if (missingPortCallRequests.length) return false;
    return requestIds.reduce((isReady, requestId) => {
      if (portCallRequests[requestId] === 'pending') return false;
      return isReady;
    }, true);
  }, [portCallRequests, vessels]);

  useEffect(() => {
    if (isReady) return;
    const exists = Object.keys(portCallRequests).map(Number);

    Array.from(vessels.keys()).forEach((vesselId: number) => {
      const vessel = vessels.get(vesselId);
      if (!vessel) return;
      // Don't add to queue if already exist in queue
      if (travels[vesselId]) return;
      if (!exists.includes(vesselId)) {
        Queue.add(() =>
          fetchAndPrepareRoute({
            dispatch,
            vessel,
            getVesselRoute,
          })
        );
      } else {
        Queue.add(() =>
          fetchAndPrepareRoute({
            dispatch,
            vessel,
            portCallResponse: portCalls[vesselId],
            getVesselRoute,
          })
        );
      }
    });
  }, [vessels, isReady]); // eslint-disable-line

  useEffect(() => {
    if (isReady) return;
    let timeout: NodeJS.Timeout;
    if (portCallsAreReady && completion === 100) {
      timeout = setTimeout(() => {
        dispatch(updateTTReadyState(true));
      }, 1000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [portCallsAreReady, completion, dispatch, isReady]);

  useEffect(() => {
    return () => {
      dispatch(updateTTReadyState(false));
      // If the modal is exited before completion, stop queue
      Queue.stop();
    };
  }, []); // eslint-disable-line

  return null;
}

export default useTimeTravel;
