import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { useCallback, useContext, useEffect, useState } from 'react';

import { FlightFilters, UpdateFilter } from 'components/CrewChangePanel/types';
import { FILTERS_UPDATE_DELAY } from 'lib/constants';
import { CCPanelContext } from 'contexts/CCPanelContext';

// flight filters divided in 3 sections to maintain in local components
export const filtersState = {
  actionBar: [
    'layover',
    'stopsCount',
    'allowAirportTransfer',
    'source',
    'fareType',
  ] as const,
  menu: ['type', 'time'] as const,
  custom: [
    'selectedStops',
    'airlines',
    'arrivalTime',
    'departureTime',
  ] as const,
};

type ComponentType = 'actionBar' | 'menu' | 'custom';
type Props = { locodeKey: string; componentType: ComponentType };

function useFiltersChange({ locodeKey, componentType }: Props) {
  const { filters: allFilters, updateFilters } = useContext(CCPanelContext);
  // component specific filters
  const [localFilters, setLocalFilters] = useState<Pick<
    FlightFilters,
    typeof filtersState[ComponentType][number]
  > | null>(null);

  const filters = allFilters[locodeKey];

  useEffect(() => {
    setLocalFilters(pick(filters, [...filtersState[componentType]]));
  }, [filters, componentType]);

  const debouncedUpdate: UpdateFilter = useCallback( // eslint-disable-line
    debounce((item) => {
      updateFilters({
        type: 'UPDATE_FILTER',
        payload: { locodeKey, item },
      });
    }, FILTERS_UPDATE_DELAY),
    [locodeKey]
  );

  const changeFilters: UpdateFilter = useCallback(
    (item) => {
      setLocalFilters((prevFilters) =>
        prevFilters ? { ...prevFilters, ...item } : null
      );
      debouncedUpdate(item);
    },
    [debouncedUpdate]
  );

  return { localFilters, changeFilters };
}

export default useFiltersChange;
