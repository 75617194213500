import styled from 'styled-components/macro';

const Badge = styled.span<{ $backgroundColor: string; $textColor: string }>`
  border-radius: 4px;
  display: inline-block;
  border: 1px solid ${({ $backgroundColor }) => $backgroundColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  font-size: 0.7rem;
  padding: 0 0.25rem;
  color: ${({ $textColor }) => $textColor};
`;

export default Badge;
