import sanitize from 'sanitize-html';
import {
  AgentEmail,
  FileAttachment,
  SendAgentEmailReq,
} from '@greywing-maritime/frontend-library/dist/types/emailReqResp';

import {
  Company,
  CrewDetails,
  VesselDetails,
  EmailDetails,
  vesselFieldConfig,
  crewFieldConfig,
  EmailTemplate,
} from '../types';
import { showToaster } from 'lib/toaster';
import { formatDate } from 'utils/format-date';

// This allows all attributes to pass.
const sanitizeHtmlOptions: sanitize.IOptions = {
  allowedAttributes: false,
  parseStyleAttributes: false,
};

export function getEmailRequest(
  to: AgentEmail[],
  cc: string[],
  details: EmailDetails,
  template: EmailTemplate,
  attachments?: FileAttachment[]
): SendAgentEmailReq {
  const { company, vesselDetails } = details;
  return {
    to: to.map((agentEmail) => agentEmail.id),
    cc,
    subject: details.subject
      ? sanitizeText(details.subject)
      : getSubject(vesselDetails, company),
    body: getHtmlBody(details, template),
    attachments,
  };
}

function sanitizeText(text: string) {
  return sanitize(text, sanitizeHtmlOptions);
}

export function getPortAgencyPreBody(vesselDetails: VesselDetails) {
  const vesselFields = Object.keys(vesselDetails);
  const tokens = [
    `Kindly note that we intend to perform a crew change during vessel ${vesselDetails.name}'s call`,
  ];
  if (vesselFields.includes('eta')) {
    tokens.push(`on ${vesselFieldConfig.eta.valueGetter(vesselDetails)}`);
  }
  if (vesselFields.includes('destination')) {
    tokens.push(
      `in ${vesselFieldConfig.destination.valueGetter(vesselDetails)}`
    );
  }
  tokens.push(`for which we kindly ask your kind assistance and coordination:`);
  return sanitizeText(tokens.join(' '));
}

export function getSubject(
  vesselDetails: VesselDetails,
  company?: Company
): string {
  const vesselFields = Object.keys(vesselDetails);
  const tokens = [
    company?.name ? `${company.name}: Crew Change Plan` : 'Crew Change Plan',
  ];

  if (vesselFields.includes('name')) {
    tokens.push(`for ${vesselFieldConfig.name.valueGetter(vesselDetails)}`);
  }

  if (vesselFields.includes('destination')) {
    tokens.push(
      `at ${vesselFieldConfig.destination.valueGetter(vesselDetails)}`
    );
  }

  if (vesselFields.includes('eta') && vesselDetails.eta) {
    tokens.push(`on ${vesselFieldConfig.eta.valueGetter(vesselDetails)}`);
  }

  return sanitizeText(tokens.join(' '));
}

function getHtmlBody(
  emailDetails: EmailDetails,
  template: EmailTemplate
): string {
  const htmlContents: string[] = [];
  const { vesselDetails, crewDetails, message } = emailDetails;
  const vesselFields = Object.keys(vesselDetails);
  const crewFields = Object.keys(crewDetails[0]);
  const vesselDetailsSection = getVesselDetailsTable(
    vesselDetails,
    vesselFields
  );
  const suffix = `<p>Please arrange transfer from airport to vessel for on- and off signing crew as usual and kindly provide accommodation if necessary.</p>
<p>Kindly ask you to provide us with the PDA for this port call for our budget purpose.</p>
<p>You may find attached copies of national ppt, smbk, schengen visa and/or US visa as required.</p>
<p>Many thanks in advance.<br />
Please confirm receipt of this email,<br/>
Thanks.</p>
<p>++++++++++++++++++++++++++++++++++++++++++UNQTE+++++++++++++++++++++++++++++++++++++++</p>`;

  const crewDetailsSection = getCrewDetailsTable(
    crewDetails,
    crewFields,
    template,
    template === 'port-agency-announcement' ? suffix : undefined
  );

  htmlContents.push(message ? `<p>${message.replace(/\n/g, '<br>')}</p>` : '');
  htmlContents.push('<br>');
  htmlContents.push(vesselDetailsSection);
  htmlContents.push('<br>');
  htmlContents.push(crewDetailsSection);

  return sanitize(htmlContents.join(' '), sanitizeHtmlOptions);
}

export function getVesselDetailsTable(
  vesselDetails: VesselDetails,
  vesselFields: string[]
): string {
  if (!vesselFields.length) {
    return '<p>No vessel details provided.</p>';
  }

  return `
  <div style="margin: 0; width: 100%; overflow-x: auto; max-width: 686px;">
    <table style="text-align: left; width: 900px;" width="900" cellspacing="0" cellpadding="5" border="1">
      <thead>
        <tr>
          <th colspan="${vesselFields.length}"><b>Vessel</b></th>
        </tr>
        <tr>
          ${vesselFields
            .map(
              (field) =>
                `<th>${
                  vesselFieldConfig[field as keyof VesselDetails].label
                }</th>`
            )
            .join(' ')}
        </tr>
      </thead>
      <tbody>
        <tr>
        ${vesselFields
          .map(
            (field) =>
              `<td>${vesselFieldConfig[
                field as keyof VesselDetails
              ].valueGetter(vesselDetails)}</td>`
          )
          .join(' ')}
        </tr>
      </tbody>
    </table>
  </div>
  `;
}

function generateBasicBlock(
  type: 'onsigner' | 'offsigner',
  crewDetails: CrewDetails[]
) {
  const typeStr = type === 'onsigner' ? 'Onsigning Crew' : 'Offsigning Crew';
  if (!crewDetails.length) {
    return `<p><strong>${typeStr}:</strong></p><p>None.</p>`;
  }
  // basic crew details
  // example:
  // C/O John Doe (USA)
  return `<p><strong>${typeStr}:</strong></p>
${crewDetails
  .map((o) => {
    const fullStr = [];
    if (o.rank !== '-') {
      fullStr.push(o.rank);
    }
    if (o.name !== '-') {
      fullStr.push(o.name);
    }
    if (o.country !== '-') {
      fullStr.push(`(${o.country})`);
    }
    return `<p>${fullStr.join(' ')}</p>`;
  })
  .join('\n')}
`;
}

function generatePersonalDetailBlock(
  type: 'onsigner' | 'offsigner',
  crewDetails: CrewDetails[]
) {
  const typeStr = type === 'onsigner' ? 'Onsigning Crew' : 'Offsigning Crew';
  const tokens = [];
  tokens.push();

  // more crew details
  // example:
  // C/O John Doe (Pers./Training ID: 012)
  // Nationality: Polish
  // Born: 1st Jan 1900
  // Passport no. 123456789, issued 1st Jan 1900, exp 1st Jan 1900
  tokens.push(`<p><strong>Personal details of ${typeStr.toLowerCase()}:</strong></p>
  ${crewDetails
    .map((o) => {
      const fullStr = [];
      const nameStr = [];
      const passportStr = [];
      const visaStr = [];
      const seamenStr = [];
      if (o.rank !== '-') {
        nameStr.push(o.rank);
      }
      if (o.name !== '-') {
        nameStr.push(o.name);
      }
      if (o.cid !== '-') {
        nameStr.push(`(Pers./Training ID: ${o.cid})`);
      }
      if (nameStr.length) {
        fullStr.push(nameStr.join(' '));
      }
      if (o.country !== '-') {
        fullStr.push(`Nationality: ${o.country}`);
      }
      if (o.birthday !== '-') {
        fullStr.push(`Born: ${o.birthday}`);
      }
      if (o.passportNumber !== '-') {
        passportStr.push(`Passport no. ${o.passportNumber}`);
      }
      if (o.passportIssued !== '-') {
        passportStr.push(`issued ${o.passportIssued}`);
      }
      if (o.passportExpiry !== '-') {
        passportStr.push(`expires ${o.passportExpiry}`);
      }
      if (passportStr.length) {
        fullStr.push(passportStr.join(', '));
      }

      if (o.visaCountry !== '-') {
        visaStr.push(`Visa for ${o.visaCountry}`);
      }
      if (o.visaExpiry !== '-') {
        visaStr.push(`Visa expires on ${o.visaExpiry}`);
      }
      if (visaStr.length) {
        fullStr.push(visaStr.join(', '));
      }

      if (o.seamenBookNumber !== '-') {
        seamenStr.push(
          `S-Book, ${
            o.seamenBookIssuedCountryIso3 !== '-'
              ? o.seamenBookIssuedCountryIso3
              : 'national'
          } no. ${o.seamenBookNumber}`
        );
      }
      if (o.seamenBookIssued !== '-') {
        seamenStr.push(`issued ${o.seamenBookIssued}`);
      }
      if (o.seamenBookExpiry !== '-') {
        seamenStr.push(`expire ${o.seamenBookExpiry}`);
      }
      if (seamenStr.length) {
        fullStr.push(seamenStr.join(', '));
      }
      return `<p>${fullStr.join('<br>')}</p>`;
    })
    .join('\n')}`);
  return tokens.join('\n');
}

const divider = `------------------------------------------------------------------------------`;
function generateTravelDetailBlock(
  type: 'onsigner' | 'offsigner',
  crewDetails: CrewDetails[]
) {
  const typeStr =
    type === 'onsigner' ? 'Arrival onsigners' : 'Departure offsigners';
  const tokens = [`<p><strong>${typeStr}:</strong></p>`];

  crewDetails.forEach((crewFlight) => {
    const fullStr = [];
    const nameStr = [];
    if (crewFlight.rank !== '-') {
      nameStr.push(crewFlight.rank);
    }
    if (crewFlight.name !== '-') {
      nameStr.push(crewFlight.name);
    }
    if (crewFlight.country !== '-') {
      nameStr.push(`(${crewFlight.country})`);
    }
    if (nameStr.length) {
      fullStr.push(`<p></p><p>${nameStr.join(' ')}</p>`);
    }

    // display flight segment
    crewFlight.segments.length
      ? fullStr.push(`<p>${divider}<br/>
Flight\tDate\tOrigin\tDestination\tDep\tArr<br/>
${divider}
</p>
`)
      : fullStr.push(`<p>${divider}<br/>`);

    // Example
    // LO-3836 16Apr GDN (GDANSK)/WAW (WARSAW) 1205 1305 16Apr
    crewFlight.segments.forEach((segment) => {
      const segmentStr: string[] = [];
      segmentStr.push(segment.flightNumber); // flight
      segmentStr.push(formatDate(segment.origin.time, 'DDMMM')); //date
      segmentStr.push(
        `${segment.origin.iata} (${segment.origin.municipality})/${segment.dest.iata} (${segment.dest.municipality})`
      ); // Origin Destination
      segmentStr.push(formatDate(segment.origin.time, 'HHmm')); // Dep
      segmentStr.push(formatDate(segment.dest.time, 'HHmm DDMMM')); // Arr

      fullStr.push(`<p>${segmentStr.join('\t')}</p>`);
    });
    if (!crewFlight.segments.length) {
      fullStr.push(`<p>Please identify a suitable flight.</p>`);
    }
    fullStr.push(divider);
    if (crewFlight.segments.length) {
      fullStr.push(`<p>AIRLINE REF:</p><p>TICKET(S):</p><br/>`);
    }
    tokens.push(fullStr.join('\n'));
  });
  return tokens.join('\n\n');
}

export function getCrewDetailsTable(
  crewDetails: CrewDetails[],
  crewFields: string[],
  template: EmailTemplate,
  suffix?: string
): string {
  if (!crewFields.length) {
    return '<p>No crew details provided.</p>';
  }

  if (template === 'port-agency-announcement') {
    const onsigners = crewDetails.filter((crew) => crew.type === 'ONSIGNER');
    const offsigners = crewDetails.filter((crew) => crew.type === 'OFFSIGNER');

    return `<div>
${generateBasicBlock('onsigner', onsigners)}
${generateBasicBlock('offsigner', offsigners)}
<p>${divider}</p>

${onsigners.length ? generatePersonalDetailBlock('onsigner', onsigners) : ''}
${offsigners.length ? generatePersonalDetailBlock('offsigner', offsigners) : ''}
<p>${divider}</p>

${onsigners.length ? generateTravelDetailBlock('onsigner', onsigners) : ''}
${offsigners.length ? generateTravelDetailBlock('offsigner', offsigners) : ''}
${suffix ?? ''}`;
  }

  return `
  <div style="margin:0; width: 100%; overflow-x: auto; max-width: 686px;">
    <table style="text-align: left; width: 1400px;" width="1400" cellspacing="0" cellpadding="5" border="1">
      <thead>
        <tr>
          <th colspan="${crewFields.length}"><b>Crew</b></th>
        </tr>
        <tr>
          ${crewFields
            .map(
              (field) =>
                `<th>${crewFieldConfig[field as keyof CrewDetails].label}</th>`
            )
            .join(' ')}
        </tr>
      </thead>
      <tbody>
        ${crewDetails
          .map(
            (crew) =>
              `<tr>${crewFields
                .map(
                  (field) =>
                    `<td>${crewFieldConfig[
                      field as keyof CrewDetails
                    ].valueGetter(crew)}</td>`
                )
                .join(' ')}</tr>`
          )
          .join(' ')}
      </tbody>
    </table>
  </div>
  `;
}

export function copyEmailToClipboard(textBody: string) {
  try {
    // console.log('COPYING', textBody);
    const virtual = document.createElement('div');
    const parser = new DOMParser();
    const newElement = parser.parseFromString(
      `<div id="virtual-clipboard" style="background-color: white;">${textBody}</div>`,
      'text/html'
    );
    if (!newElement) throw new Error("Couldn't copy to clipboard");
    virtual.append(newElement.getElementById('virtual-clipboard')!);
    document.body.append(virtual);
    const table = document.getElementById('virtual-clipboard');
    const range = document.createRange();
    // @ts-ignore
    range.selectNode(table);

    const select = window.getSelection();
    select?.removeAllRanges();
    select?.addRange(range);
    if ('execCommand' in document) {
      // This is deprecated but I can't find any other method to copy paste tables!!
      // So for now this is still used.
      // @ts-ignore
      document.execCommand('copy');
      showToaster({
        message: 'Copied to clipboard',
        placement: 'right',
      });
    } else {
      throw new Error("Couldn't copy to clipboard");
    }

    window.getSelection()?.removeAllRanges();
    document.body.removeChild(virtual);
  } catch (error) {
    showToaster({
      message: "Couldn't copy to clipboard",
      type: 'error',
      placement: 'right',
    });
  }
}
