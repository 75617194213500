import {
  GridAlignment,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import {
  AmadeusAirport,
  Airport as GreywingAirport,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import styled from 'styled-components/macro';

import { blue, textGray, white } from 'lib/colors';
import { GreywingLogo } from 'components/icons';
import { Tooltip, ExpandButton } from 'components/shared';

import PortETA from './PortETA';
import AgencyCost from '../../common/AgencyCost';
import AirportDropdown from '../../common/AirportDropdown';
import { RoutePort, SelectPortNearbyAirport } from '../../types';
import CTeleportButton from 'components/CrewChangePanel/common/CTeleportButton';
import InfoIcon from '@mui/icons-material/Info';
import { TRACK_CTELEPORT_PORTS_STEP } from 'utils/analytics/constants';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PortName = styled.div`
  margin-right: 0.25rem;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 18px !important;
  margin-bottom: -0.05rem;
  margin-left: 2px;
`;

const Suggestion = styled.div`
  font-size: 0.75rem;
  text-transform: uppercase;
`;

const StyledLogo = styled(GreywingLogo)`
  height: 18px;
  margin-right: 4px;
`;

export const getGridColumns = (
  routePorts: RoutePort[] = [],
  selectAirport?: SelectPortNearbyAirport // available for planning state
): GridColDef[] =>
  [
    // custom icon component for detail panel toggle
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      maxWidth: !selectAirport ? 15 : 60,
      type: 'string', // overrides the type defined in `GRID_DETAIL_PANEL_TOGGLE_COL_DEF` to avoid console warning
      renderCell: (params: GridRenderCellParams) =>
        Boolean(selectAirport) ? (
          <ExpandButton expanded={params.value} />
        ) : null,
    },
    {
      field: 'name',
      headerName: 'Port',
      minWidth: 100,
      renderCell: ({ row: port }: GridRenderCellParams) => (
        <FlexWrapper>
          {port.greywingVerified ? (
            <Tooltip content="Greywing verified recommendations available">
              <FlexWrapper>
                <StyledLogo color={port.selected ? white : blue} />
                <PortName>{port.name}</PortName>
              </FlexWrapper>
            </Tooltip>
          ) : (
            <PortName>{port.name}</PortName>
          )}

          {port.scheduled && (
            <Tooltip content="Port for scheduled crew change event">
              <StyledInfoIcon sx={{ color: textGray }} />
            </Tooltip>
          )}
        </FlexWrapper>
      ),
    },
    { field: 'locode', headerName: 'Locode', maxWidth: 120 },
    {
      field: 'costDetails',
      headerName: 'Agency costs',
      minWidth: 100,
      maxWidth: 500,
      renderCell: ({ row }: GridRenderCellParams) => (
        <AgencyCost agencyDetails={row.costDetails} />
      ),
    },
    {
      field: 'closestAirports',
      headerName: 'Airport',
      minWidth: 150,
      renderCell: (params: GridRenderCellParams) => {
        const {
          locode,
          closestAirports,
          airportName, // available for read-only data
          selectedAirport,
        } = params.row;

        if (!selectAirport) {
          return <span>{airportName}</span>;
        }

        const parsedAirports = JSON.parse(closestAirports);
        if (!parsedAirports.length) return <span>-</span>;

        const { iataCode, name, address } = selectedAirport as
          | AmadeusAirport
          | GreywingAirport;
        const selected = { iataCode, name, cityName: address.cityName || '' };
        return (
          <AirportDropdown.Port
            selected={selected}
            nearbyAirports={parsedAirports}
            onSelect={selectAirport(locode)}
          />
        );
      },
    },
    {
      field: 'deviationTimeDifference',
      headerName: 'Deviation',
      maxWidth: 80,
      align: 'center' as GridAlignment,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.deviationTimeDifference) return <span>-</span>;
        if (params.row.deviationTimeDifference < 1)
          return <span>{'< 1 hr'}</span>;
        return (
          <span>{`${Number(params.row.deviationTimeDifference).toFixed(
            1
          )} hr`}</span>
        );
      },
    },
    {
      field: 'calculatedDistance',
      headerName: 'Distance',
      maxWidth: 120,
      type: 'number',
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.calculatedDistance) return <span>-</span>;
        // forced to be NM
        return <span>{`${params.row.calculatedDistance} NM`}</span>;
      },
    },
    {
      field: 'suggestion',
      headerName: 'Priority',
      minWidth: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { status } = params.row;
        return <Suggestion>{status.join(' | ')}</Suggestion>;
      },
    },
    {
      field: 'eta',
      headerName: 'ETA',
      minWidth: 140,
      renderCell: (params: GridRenderCellParams) => (
        <PortETA port={params.row} routePorts={routePorts} />
      ),
    },
    {
      field: 'bookWithCTeleport',
      headerName: 'Actions',
      minWidth: 180,
      renderCell: (params: GridRenderCellParams) => {
        const {
          eta,
          etd,
          selectedAirport: { iataCode },
        } = params.row;
        return (
          <CTeleportButton
            portData={{ eta, etd, airport: iataCode }}
            trackAction={TRACK_CTELEPORT_PORTS_STEP}
          />
        );
      },
    },
  ].map((item) => ({
    ...item,
    flex: 1,
    headerClassName: 'table-header',
    maxWidth: item.maxWidth || 300,
  }));

export const getColumnVisibility = (
  isMobile: boolean,
  isCTeleportEnabled: boolean
) => ({
  locode: !isMobile,
  calculatedDistance: !isMobile,
  pcr: !isMobile,
  bookWithCTeleport: isCTeleportEnabled,
  [GRID_DETAIL_PANEL_TOGGLE_FIELD]: !isMobile,
});
