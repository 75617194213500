import UserWithArrowSVG from './UserWithArrowSVG';
import styled from 'styled-components/macro';

type Props = {
  color?: string;
  size?: number;
  style?: { [key: string]: string | number };
};

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

function OnsignerIcon({ color, size = 24, style = {} }: Props) {
  return (
    <Wrapper>
      <UserWithArrowSVG
        color={color}
        height={size}
        width={size}
        style={{ transform: 'scaleX(-1)', ...style }}
      />
    </Wrapper>
  );
}

export default OnsignerIcon;
