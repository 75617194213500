import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  generateNewGptTopic,
  getAssistedCrewChangeConvo,
  getAssistedCrewChangeEmailConvo,
} from 'api/flotilla';
import {
  fixConvoLatestResultsCostString,
  fixLatestResultsCostString,
} from 'components/SeaGPT/helpers';
import {
  AssistedConversationWithExtra,
  AssistedCrewChangeAgentConvoResp,
  ListAssistedCrewChangeConvosScope,
} from 'components/SeaGPT/type';
import { showToaster } from 'lib/toaster';
import {
  clearSeaGptEmailPages,
  setSeaGptConvoHasUpdate,
  updateSeaGPTConvoEmail,
  updateSeaGPTThreadMeta,
} from 'redux/actions';
import { RootState } from 'redux/types';

/* ----- Async Thunks ----- */

export const generateGptTopicAsync = createAsyncThunk(
  'sea-gpt/generateGptTopicAsync',
  async (
    { caseId }: { caseId: string },
    { getState, dispatch }
  ): Promise<string | null> => {
    const { copilot } = getState() as RootState;
    if (!copilot.activeThread || copilot.activeThread === 'emailUpdates') {
      return null;
    }
    try {
      const response = await generateNewGptTopic(caseId);
      if (!response.success) {
        return null;
      }
      if (response.data) {
        dispatch(
          updateSeaGPTThreadMeta({
            id: copilot.activeThread,
            title: response.data,
          })
        );
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);

// export const fetchAssistedCrewChangeEmailsAsync = createAsyncThunk(
//   'sea-gpt/fetchAssistedCrewChangeEmailsAsync',
//   async (
//     {
//       showCompanyRes = false,
//       page = 0,
//     }: { showCompanyRes?: boolean; page?: number },
//     { getState, dispatch }
//   ): Promise<{
//     convoKeys: { [key: string]: AssistedConversationWithExtra };
//     pageKey: string;
//     pageConvoKeys: string[];
//     pagination: PageInfo;
//   } | null> => {
//     const {
//       copilot: {
//         emails: { pageConvoKeys, pagination, shouldRefetchAllPages },
//       },
//     } = getState() as RootState;
//     const pageKey = `${page}-${showCompanyRes ? 'company' : 'user'}`;
//     if (pageConvoKeys[pageKey] && !shouldRefetchAllPages) {
//       return {
//         convoKeys: {},
//         pageKey,
//         pageConvoKeys: pageConvoKeys[pageKey],
//         pagination: {
//           ...pagination,
//           currentPage: page,
//         },
//       };
//     }
//     if (shouldRefetchAllPages) {
//       dispatch(clearSeaGptEmailPages(false));
//     }
//     try {
//       const response = await getAssistedCrewChangeEmails({
//         showCompanyRes,
//         page,
//       });
//       if (!response.success) {
//         throw Error(response.message);
//       } else {
//         const { output } = response;
//         return {
//           convoKeys: output.data.reduce((all, { conversations, ...acc }) => {
//             conversations.forEach((convo) => {
//               all[`${acc.id}-${convo.id}`] = {
//                 ...convo,
//                 acc,
//                 accConvoId: `${acc.id}-${convo.id}`,
//               };
//             });
//             return all;
//           }, {} as { [key: string]: AssistedConversationWithExtra }),
//           pageKey,
//           pageConvoKeys: output.data.reduce((all, acc) => {
//             acc.conversations.forEach((convo) =>
//               all.push(`${acc.id}-${convo.id}`)
//             );
//             return all;
//           }, [] as string[]),
//           pagination: output.meta,
//         };
//       }
//     } catch (error) {
//       console.error('Failed to fetch', error);
//       showToaster({
//         message:
//           (error as Error)?.message ||
//           `Failed to fetch assisted crew change emails`,
//         type: 'error',
//       });
//       return null;
//     }
//   }
// );

export const fetchAssistedCrewChangeConvosAsync = createAsyncThunk(
  'sea-gpt/fetchAssistedCrewChangeConvosAsync',
  async (
    {
      scope = 'COMPANY',
      searchTerm,
      nextPageToken,
    }: {
      scope: ListAssistedCrewChangeConvosScope;
      searchTerm?: string;
      nextPageToken?: string;
    },
    { getState, dispatch }
  ): Promise<{
    convoKeys: { [key: string]: AssistedConversationWithExtra };
    queryPageKey: string;
    rowIds: number[];
    nextPageToken: string;
    totalCount: number;
  } | null> => {
    const {
      copilot: {
        emails: { queryConvoKeys, shouldRefetchAllPages },
      },
    } = getState() as RootState;
    const pageKey = `${scope}${searchTerm ? `-${searchTerm}` : ''}`;
    if (
      queryConvoKeys[pageKey] &&
      !queryConvoKeys[pageKey]?.nextPageToken &&
      !shouldRefetchAllPages
    ) {
      // don't do anything
      return null;
    }
    if (shouldRefetchAllPages) {
      dispatch(clearSeaGptEmailPages(false));
    }
    try {
      const response = await getAssistedCrewChangeConvo({
        scope,
        searchFilters: searchTerm ? { portName: searchTerm } : undefined,
        nextPageToken,
      });
      if (!response.success) {
        throw Error(response.message);
      } else {
        const { output } = response;
        const convoKeys = output.data.reduce<{
          [key: number]: AssistedConversationWithExtra;
        }>((obj, row) => {
          obj[row.id] = fixLatestResultsCostString(row);
          return obj;
        }, {});
        return {
          queryPageKey: pageKey,
          convoKeys,
          rowIds: output.data.map((row) => row.id),
          nextPageToken: output.nextPageToken,
          totalCount: output.totalCount,
        };
      }
    } catch (error) {
      console.error('Failed to fetch', error);
      showToaster({
        message:
          (error as Error)?.message ||
          `Failed to fetch assisted crew change emails`,
        type: 'error',
      });
      return null;
    }
  }
);

export const fetchAssistedCrewChangeConvoEmailAsync = createAsyncThunk(
  'copilot/fetchAssistedCrewChangeConvoEmailAsync',
  async (
    { id, isRetry }: { id: number; isRetry?: boolean },
    { getState }
  ): Promise<AssistedCrewChangeAgentConvoResp | null> => {
    const {
      copilot: {
        emails: { convoEmailKeys },
      },
    } = getState() as RootState;
    if (convoEmailKeys[id] && !isRetry) {
      return convoEmailKeys[id];
    }
    try {
      const response = await getAssistedCrewChangeEmailConvo(id);
      if (!response.success) {
        throw Error(response.message);
      }
      return fixConvoLatestResultsCostString(response.data);
    } catch (error) {
      console.error('Failed to fetch', error);
      showToaster({
        message:
          (error as Error)?.message ||
          `Failed to fetch assisted crew change convo email`,
        type: 'error',
      });
      return null;
    }
  }
);

export const processAssistedCrewChangeUpdate = createAsyncThunk(
  'sea-gpt/processAssistedCrewChangeUpdate',
  async (
    data: AssistedCrewChangeAgentConvoResp,
    { getState, dispatch }
  ): Promise<boolean> => {
    const convoKey = data.conversation.id;
    const {
      copilot: {
        emails: { convoKeys },
      },
    } = getState() as RootState;
    // Update existing data
    dispatch(updateSeaGPTConvoEmail(fixConvoLatestResultsCostString(data)));
    if (convoKeys[convoKey]) {
      dispatch(
        setSeaGptConvoHasUpdate({
          id: convoKey,
          data: {
            ...convoKeys[convoKey],
            ...data.conversation,
            hasUpdate: true,
          },
        })
      );
      // This is not a new conversation, therefore we don't need to fetch the entire list again
      return false;
    } else {
      // this is a new conversation, therefore we need to fetch the entire list again
      return true;
    }
  }
);

export const processNewAssistedCrewChangeEmail = createAsyncThunk(
  'sea-gpt/processAssistedCrewChangeUpdate',
  async (
    data: AssistedCrewChangeAgentConvoResp,
    { dispatch }
  ): Promise<void> => {
    dispatch(updateSeaGPTConvoEmail(fixConvoLatestResultsCostString(data)));
  }
);
