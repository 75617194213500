import styled from 'styled-components/macro';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import CircleUncheckedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { gray70, iconGray, textGray } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { ButtonV2 } from 'components/shared';
import { Thumb } from 'components/shared/AwesomeSlider';
import { CheckboxProps } from './types';
import Info from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Section = styled.div`
  margin-left: 1rem;
  width: calc(100% - 2rem);
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 97%;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.05rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
`;

export const Text = styled.div`
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: ${textGray};
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${iconGray};
  margin: 1rem 0;
`;

export const ActionButton = styled(ButtonV2).attrs((props) => ({
  size: props.size || 'small',
}))`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0 1rem;
  margin-right: 8px;
  column-gap: 0.5rem;
`;

export const ParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input,
  .MuiAutocomplete-inputRoot,
  .MuiAutocomplete-loading {
    font-size: 1rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiChip-root {
    height: 24px;
    font-size: 0.75rem;
    font-family: HK Grotesk, Roboto;
  }
`;

export const InputWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-right: 1rem;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input {
    font-family: HK Grotesk, Roboto !important;
  }
`;

export const SliderWrapper = styled.div<{ $port?: boolean }>`
  margin-bottom: ${({ $port }) => ($port ? '1rem' : '0.5rem')};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SliderLabel = styled(Text)`
  font-size: 0.85rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  color: ${gray70};
`;

export const ToggleWrapper = styled(FlexWrapper)`
  margin-top: 0.5rem;
  column-gap: 0.75rem;
`;

export const ToggleText = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
`;

export const InfoIcon = styled(Info)`
  font-size: 18px !important;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
  color: ${iconGray};
  cursor: pointer;
`;

export const commonStyles = { width: '280px', mb: 1.5 };
export const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

const FormWrapper = styled.div`
  .MuiFormControlLabel-root {
    height: 32px;
  }
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }
`;

export const CustomCheckbox = ({
  label,
  isCircle = true,
  checked,
  disabled,
  onClick,
  ...rest
}: CheckboxProps) => {
  const customProps = isCircle
    ? { icon: <CircleUncheckedIcon />, checkedIcon: <CheckCircleIcon /> }
    : {};

  return (
    <FormWrapper>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onClick}
            {...customProps}
            {...rest}
          />
        }
      />
    </FormWrapper>
  );
};

export function ThumbComponent(allProps: any) {
  const { range, ...props } = allProps;
  const { max, value } = props.ownerState;
  let unit = 'H';
  // set units based on max value provided in the slider component
  // max values should be unique for each slider (for this to work)
  // otherwise, will require a different approach
  switch (max) {
    case 6:
      unit = '';
      break;
    case 200:
      unit = 'NM';
      break;
    case 12:
      unit = 'W';
      break;
    default:
      unit = 'H';
      break;
  }
  if (range) {
    const hourValue = props['data-index'] ? value[1] : value[0];
    return <Thumb {...props} modifiedValue={`${hourValue}${unit}`} />;
  }
  const modifiedValue = `${value}${unit}`;
  return <Thumb {...props} modifiedValue={modifiedValue} />;
}
