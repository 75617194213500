import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MuiSeatIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import styled from 'styled-components/macro';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useMobile, useModal } from 'hooks';
import { BREAK_POINT_CUSTOM_XS, BREAK_POINT_SM } from 'lib/breakpoints';
import {
  blue,
  borderGray,
  gray60,
  green,
  orange,
  textGray,
  fadedGreen,
  iconGray,
} from 'lib/colors';
import {
  findFlightTrackAccess,
  findFlightBookAccess,
  secondsToDHM,
} from 'lib/common';
import { isProduction } from 'lib/environments';
import { selectSettings } from 'redux/selectors';
import { Flight } from 'utils/types/crew-change-types';
import { FlightStepDetails } from 'components/FlotillaSearch/types';

import { CommonConfirmModal } from 'components/shared';
import { FlightResultColumn } from 'components/FlotillaSearch/common';
import FlightInfo from './FlightInfo';
import Connection from './Connection';
import FareInfo from './FareInfo';
import Tooltip from '../../Tooltip';
import QuickFlyBook from '../../FlightBook';
import { SelectButton, QuickFlyFlightTrack } from '../../FlightTrack';

import { getFlightDelay } from 'components/CrewChangePanel/helpers';
import { getFlightSteps } from 'components/FlotillaSearch/helpers';
import { getUserActionModalType, TrackUtils } from '../helpers';
import { ModalView } from '../types';
import InfoIcon from '@mui/icons-material/Info';

const EMPTY_SEATS_SECTION_HEIGHT = '32px';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonFlexWrapper = styled(FlexWrapper)`
  flex-direction: column;
  row-gap: 0.5rem;
`;

const Wrapper = styled.div<{
  $last: boolean;
  $unmatched?: boolean;
  $isConfirmed?: boolean;
  $highlight?: boolean;
}>`
  position: relative;
  align-items: center;
  display: grid;
  grid-template-columns: 3.25fr 1fr;
  grid-gap: 5px;
  border-bottom: 1px solid ${borderGray};
  transition: all 200ms ease-in-out;
  ${({ $last }) => $last && 'border-bottom: none'};
  ${({ $unmatched }) => $unmatched && 'opacity: 0.4'};
  ${({ $isConfirmed }) =>
    $isConfirmed &&
    `
    background: ${fadedGreen}20;
  `};
  ${({ $highlight }) =>
    $highlight &&
    `
    cursor: default;
  `};

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: 2.5fr 1fr;
  }
`;

const LoaderWrapper = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-style: italic;
  color: ${gray60};
`;

const Text = styled.div`
  font-size: 0.82rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  text-overflow: ellipsis;
`;

const HighlightedText = styled(Text)`
  color: ${textGray};
  font-weight: 400;
`;

const FlightSectionsWrapper = styled.div<{ $hasSeat?: boolean }>`
  height: 100%;
`;

const FlightsContainer = styled(FlexWrapper)<{ $hasSeat?: boolean }>`
  height: ${({ $hasSeat }) =>
    $hasSeat ? `calc(100% - ${EMPTY_SEATS_SECTION_HEIGHT})` : '100%'};
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  overflow-x: auto;
`;

const SeatsInfoWrapper = styled(FlexWrapper)`
  width: 100%;
  min-height: ${EMPTY_SEATS_SECTION_HEIGHT};
  padding: 0.25rem;
  font-size: 0.8rem;
  color: ${textGray};
  font-style: italic;
  border-top: 1px solid ${borderGray};
  letter-spacing: 0.5px;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    padding-left: 0.25rem;
    font-size: 0.75rem;
  }
`;

const SeatsWithButton = styled(FlexWrapper)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Price = styled(Text)`
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const Badge = styled.div<{ $isMarine?: boolean; $isConfirmed?: boolean }>`
  padding: 1px 3px;
  border-radius: 6px;
  font-size: 0.75rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.33;
  margin-top: 0.5rem;
  ${({ $isMarine }) =>
    $isMarine
      ? `
    background: ${green}20;
    color: ${green};
    border: 1px solid ${green};
  `
      : `
    background: ${orange}20;
    color: ${orange};
    border: 1px solid ${orange};
  `};
  ${({ $isConfirmed }) =>
    $isConfirmed &&
    `
    background: ${blue}20;
    color: ${blue};
    border: 1px solid ${blue};
    margin-right: 4px;
  `};
`;

const LinkText = styled(Text)`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  text-decoration: underline;
  color: ${gray60};
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    color: ${blue};
  }
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  color: ${iconGray};
  margin-left: 0.33rem;
  svg {
    font-size: 1rem !important;
  }
`;

const getIconStyle = (isMobile: boolean) => ({
  color: blue,
  fontSize: isMobile ? '18px' : '20px',
  marginRight: isMobile ? '0.25rem' : '0.33rem',
});

type ResultCardProps = {
  modalView?: ModalView;
  flight: Flight;
  last: boolean;
  isSelected?: boolean; // indicates if a flight is selected, only for cc panel modal view
  unmatched?: boolean;
  // available for cc panel modal view, only for matched flights
  selectFlight?: ((flight: Flight) => void) | undefined;
  // open raw data view
  openRawData?: (id: string) => void;
};

function FlightResultCard({
  flight,
  last,
  modalView,
  isSelected,
  unmatched,
  selectFlight,
  openRawData,
}: ResultCardProps) {
  const {
    arrival,
    departure,
    originalId,
    cabinClass,
    numSeatsAvailable,
    price,
    totalFlightTime,
    stops,
    type,
    fareInformation,
    source,
    // totalCO2,
  } = flight;
  const isQuickFlyModal = modalView === 'quickfly';
  const modalType = getUserActionModalType(modalView);
  const { userInfo } = useSelector(selectSettings);

  const isMobile = useMobile(BREAK_POINT_CUSTOM_XS);
  const { modal, setModal } = useModal();

  const isModal = Boolean(modalView);
  const hasSeat = Boolean(numSeatsAvailable);
  const canTrackFlight = findFlightTrackAccess(userInfo);
  const canBookFlight = findFlightBookAccess(userInfo);
  const flightTime = useMemo(
    () => secondsToDHM(totalFlightTime * 3600),
    [totalFlightTime]
  );
  const isMarineFlight = useMemo(
    () => type.type.toLowerCase() === 'marine',
    [type]
  );
  const { toggleExpandLayover } = useMemo(
    () => TrackUtils(modalType),
    [modalType]
  );

  const [segments, setSegments] = useState<FlightStepDetails[]>([]);

  useEffect(() => {
    setSegments(getFlightSteps(flight, isMobile, isModal));
  }, [flight, isMobile, isModal]);

  const handleExpandSegment = useCallback(
    (iataCode: string) => {
      const updatedSegments = segments.map((segment) =>
        iataCode === segment.iataCode ? { ...segment, expanded: true } : segment
      );
      setSegments(updatedSegments);
      toggleExpandLayover(unmatched, iataCode);
    },
    [unmatched, segments, toggleExpandLayover]
  );

  const renderBookButton = useCallback(() => {
    if (
      unmatched ||
      modalView === 'ccpanel' ||
      !canBookFlight ||
      isProduction
    ) {
      return null;
    }
    return <QuickFlyBook flight={flight} />;
  }, [flight, unmatched, modalView, canBookFlight]);

  const renderEmptySeats = useCallback(() => {
    if (!hasSeat) {
      return null;
    }

    return (
      <SeatsInfoWrapper>
        <MuiSeatIcon sx={getIconStyle(isMobile)} />
        <SeatsWithButton>
          <span>
            Available Seat(s):{' '}
            <b>
              {cabinClass} - {numSeatsAvailable}
            </b>
          </span>
          {renderBookButton()}
        </SeatsWithButton>
      </SeatsInfoWrapper>
    );
  }, [hasSeat, renderBookButton, cabinClass, isMobile, numSeatsAvailable]);

  const renderFlightSegments = () => {
    const isModalView = Boolean(modalView);

    // no layovers - only departure & arrival
    if (segments.length <= 2) {
      return (
        <FlightsContainer $hasSeat={hasSeat}>
          {/* Departure */}
          <FlightInfo {...segments[0]} isModalView={isModalView} />
          <Connection duration={segments[1].duration} />
          {/* Arrival */}
          <FlightInfo {...segments[1]} isModalView={isModalView} />
        </FlightsContainer>
      );
    }

    return (
      <FlightsContainer $hasSeat={hasSeat}>
        {segments.map((details, index) => {
          const { iataCode, duration, expanded } = details;
          const expandProps = !expanded
            ? {
                onExpand: () => handleExpandSegment(iataCode),
              }
            : {};

          return (
            <React.Fragment key={index}>
              {/* Connection components rendered on the left of a segment.
               Don't render connection component for departure/first segment */}
              {index !== 0 && <Connection duration={duration} />}
              <FlightInfo
                {...details}
                {...expandProps}
                canExpand={!expanded}
                isModalView={isModalView}
              />
            </React.Fragment>
          );
        })}
      </FlightsContainer>
    );
  };

  const renderSelectButton = () => {
    if (!selectFlight || isSelected) {
      return null;
    }

    return (
      <Tooltip content="Click to select this flight">
        <SelectButton
          className="e2e_select-flight"
          variant="primary"
          onClick={() => {
            const timeDifference = getFlightDelay(flight);
            if (timeDifference < 0)
              setModal('selectFlight', { timeDifference });
            else selectFlight(flight);
          }}
        >
          Select
        </SelectButton>
      </Tooltip>
    );
  };

  const renderTrackButton = () => {
    // hide track button for unmatched flights & users without access
    // also hide in cc panel view
    if (modalView === 'ccpanel' || !canTrackFlight) {
      return null;
    }
    return <QuickFlyFlightTrack flight={flight} modalView={modalView} />;
  };

  const renderRawFlightViewText = () => {
    const canViewRawData = modalView && userInfo?.access?.['Show Raw Flights'];
    if (!canViewRawData) {
      return null;
    }
    const flightId = modalView === 'ccpanel' ? originalId : flight.id;
    return (
      <LinkText onClick={() => flightId && openRawData?.(flightId)}>
        View Raw Data
      </LinkText>
    );
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    const closeModal = () => setModal(null);

    if (modal.type === 'selectFlight') {
      const { timeDifference } = modal.data;
      const { crew, port } = flight;
      const isOffsigner = crew.type === CrewType.offsigner;
      const formattedDelay = secondsToDHM(Math.abs(timeDifference) * 24 * 3600);
      const description = `Selected ${crew.type} flight's ${
        isOffsigner ? 'departure' : 'arrival'
      } is ${formattedDelay} ${
        isOffsigner ? 'before' : 'after'
      } this vessel's ${isOffsigner ? 'ETA' : 'ETD'} for ${
        port.name
      }. Do you still want to select this?`;

      return (
        <CommonConfirmModal
          description={description}
          onConfirm={() => {
            selectFlight?.(flight);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    }
  };

  return (
    <Wrapper
      data-id="e2e_flight-result"
      $last={last}
      $unmatched={unmatched}
      $isConfirmed={isSelected}
      $highlight={isQuickFlyModal}
    >
      {Boolean(segments.length) ? (
        <FlightSectionsWrapper $hasSeat={hasSeat}>
          {renderFlightSegments()}
          {renderEmptySeats()}
        </FlightSectionsWrapper>
      ) : (
        <LoaderWrapper>Loading...</LoaderWrapper>
      )}

      <FlightResultColumn $shadow>
        <Price data-id="e2e_results-fight-cost">
          {price.amount.toFixed(2)} {price.currency}
        </Price>
        <Text data-id="e2e_results-fight-time">{flightTime}</Text>
        {/* hide for now */}
        {/* {totalCO2 ? (
          <HighlightedText>
            {(totalCO2 / 1000).toFixed(0)} tons CO2
          </HighlightedText>
        ) : null} */}

        <FlexWrapper>
          <HighlightedText>
            {Boolean(stops.length)
              ? `${stops.length} stop${stops.length > 1 ? 's' : ''}`
              : 'No stop'}
          </HighlightedText>

          {source && (
            <Tooltip
              content={
                <span>
                  Flight source: <b>{source}</b>
                </span>
              }
            >
              <StyledIcon>
                <InfoIcon />
              </StyledIcon>
            </Tooltip>
          )}
        </FlexWrapper>

        <FareInfo
          arrival={arrival.airportCode}
          departure={departure.airportCode}
          fareInformation={fareInformation}
        />

        <FlexWrapper>
          {isSelected && <Badge $isConfirmed>Selected</Badge>}
          <Badge $isMarine={isMarineFlight}>
            {isMarineFlight ? 'Marine' : 'Non-Marine'}
          </Badge>
        </FlexWrapper>

        <ButtonFlexWrapper>
          {renderSelectButton()}
          {renderTrackButton()}
        </ButtonFlexWrapper>
        {renderRawFlightViewText()}
      </FlightResultColumn>

      {renderModal()}
    </Wrapper>
  );
}

export default memo(FlightResultCard);
