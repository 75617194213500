import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, MapEventsState } from '../types';

// TODO: placeholder for when we re-introduce hover over routes
export const initialMapEvents: MapEventsState = {
  hoveredVesselId: null,
  addVessel: {
    active: false,
    input: '',
  },
};

const mapEventsSlice = createSlice({
  name: 'mapEvents',
  initialState: initialMapEvents,
  reducers: {
    toggleAddVessel: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      addVessel: {
        active: !state.addVessel.active,
        input: action.payload || '',
      },
    }),
    setHoveredVesselId: (state, action: PayloadAction<number | null>) => ({
      ...state,
      hoveredVesselId: action.payload,
    }),
  },
});

/* ----- selectors -----*/

export const selectMapEvents = ({ mapEvents }: RootState) => mapEvents;

/* ----- actions -----*/

export const { setHoveredVesselId, toggleAddVessel } = mapEventsSlice.actions;

/* ----- reducer -----*/

export default mapEventsSlice.reducer;
