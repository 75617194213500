import isEqual from 'lodash/isEqual';
import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch, useMobile } from 'hooks';
import { getDataId } from 'lib/common';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { blue, iconGray, lightPurple, midBlue, red } from 'lib/colors';
import { selectSettings } from 'redux/selectors';
import { updateCombinedSettings } from 'redux/thunks';
import { updateTrackFlightSettings, updateUserInfo } from 'api/flotilla';
import { TRACK_SETTINGS_UPDATE } from 'utils/analytics/constants';

import { Modal, Tooltip } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';
import MobileTabs from './MobileTabs';
import {
  Account,
  CrewChange,
  Devices,
  Alerts,
  FlightSearch,
  Vessels,
  Security,
  DemoControl,
  Copilot,
} from './components';
import {
  getEmailErrorMessage,
  initialCustomAlertResponse,
  settingsOptions as options,
} from './helpers';
import { CustomAlertResponse, SettingsItem, SettingsState } from './types';
import InfoIcon from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(75vh - 129px);
  overflow: hidden;
`;

const Sidebar = styled.div`
  width: 26%;
  border-right: 1px solid ${iconGray};
  padding: 1rem;
  padding-right: 0;
`;

const ItemWrapper = styled(FlexWrapper)<{ $active?: boolean }>`
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 12px;
  margin-right: 1rem;
  transition: all 150ms ease;
  ${({ $active }) =>
    $active &&
    `
    background: ${midBlue};
    border-radius: 0.25rem;
  `}
  &:hover {
    background: ${midBlue};
    border-radius: 0.25rem;
  }
`;

const IconWrapper = styled.div`
  width: 24px;
`;

const StyledIcon = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ $active }) => ($active ? blue : iconGray)};
  svg {
    font-size: 20px !important;
  }
`;

const ItemName = styled.div<{ $active?: boolean }>`
  margin-left: 6px;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.025rem;
  ${({ $active }) =>
    $active &&
    `
    font-weight: bold;
  `};
`;

const Main = styled.div`
  padding: 1rem 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const StyledInfoIcon = styled(InfoIcon)<{ $active?: boolean }>`
  font-size: 18px !important;
  color: ${({ $active }) => ($active ? red : iconGray)};
`;

const noPaddingContentStyles = {
  padding: 0,
  margin: 0,
  overflowX: 'hidden',
};

type Props = {
  closeModal: () => void;
  magicLink?: {
    code: string;
    email: string;
  };
  option?: string;
};

function SettingsModal({ magicLink, option, closeModal }: Props) {
  const dispatch = useAppDispatch();
  const {
    crewChange,
    crossDevices,
    vessels,
    userInfo,
    trackSettings,
    quickFlyTMC,
  } = useSelector(selectSettings);

  const isMobile = useMobile();
  const [activePage, setActivePage] = useState(1);
  const [selected, setSelected] = useState(option || options[0].name);
  const [alertResponse, setAlertResponse] = useState<CustomAlertResponse>(
    initialCustomAlertResponse
  );
  const [settings, updateSettings] = useState<SettingsState>({
    crossDevices,
    crewChange,
    vessels,
    userInfo,
    trackSettings,
    quickFlyTMC,
  });
  const isTMC = userInfo?.type === 'tmc';

  const accountErrorMessage = useMemo(
    () => getEmailErrorMessage(settings.userInfo?.notificationEmail),
    [settings.userInfo?.notificationEmail]
  );

  const handleUpdateSettings = () => {
    if (Boolean(accountErrorMessage)) {
      showToaster({
        message:
          'Cannot update. Missing notification email in Account section.',
        type: 'error',
      });
      return;
    }

    const { userInfo: userInfoInSettings } = settings;
    dispatch(updateCombinedSettings(settings));
    const userInfoUpdated = !isEqual(userInfo, settings.userInfo);
    const trackSettingsUpdated =
      settings.trackSettings && !isEqual(trackSettings, settings.trackSettings);

    // prevent making backend call if user is NOT updated
    if (
      userInfoInSettings &&
      userInfoInSettings.notificationEmail &&
      userInfoUpdated
    ) {
      // updated user info fields from settings modal form inputs
      const {
        phone,
        firstname,
        lastname,
        notificationEmail,
        timezone,
        jobTitle,
      } = userInfoInSettings;
      const updatedUser = {
        notificationEmail,
        phone,
        firstname,
        lastname,
        timezone,
        jobTitle,
      };

      updateUserInfo(updatedUser);
    }
    // prevent making backend call if track-flight settings are NOT updated
    if (trackSettingsUpdated) {
      updateTrackFlightSettings(settings.trackSettings!);
    }

    // track user action in amplitude for settings update
    trackUserAction(
      TRACK_SETTINGS_UPDATE(selected.toLowerCase().replace(/ /g, '_')),
      'click'
    );
    closeModal();
  };

  const renderSettingsDetails = () => {
    switch (selected) {
      case 'Devices':
        return (
          <Devices
            initialValue={settings.crossDevices}
            updateSettings={updateSettings}
            closeModal={closeModal}
          />
        );

      case 'Alerts':
        return (
          <Alerts
            response={alertResponse}
            activePage={activePage}
            setActivePage={setActivePage}
            trackSettings={settings.trackSettings}
            setResponse={setAlertResponse}
            updateSettings={updateSettings}
          />
        );

      case 'Vessels':
        return (
          <Vessels
            initialValues={settings.vessels}
            updateSettings={updateSettings}
          />
        );

      case 'Crew Changes':
        return (
          <CrewChange
            initialValues={settings.crewChange}
            updateSettings={updateSettings}
          />
        );

      case 'Flight Search':
        return (
          <FlightSearch
            initialValues={settings.crewChange.flightParams}
            quickFlyTMC={settings.quickFlyTMC}
            updateSettings={updateSettings}
          />
        );

      case 'Account':
        return (
          <Account
            initialValues={settings.userInfo}
            updateSettings={updateSettings}
            errorMessage={accountErrorMessage}
          />
        );

      case 'Security':
        return (
          <Security
            magicLink={magicLink?.code}
            initialValues={settings.userInfo}
          />
        );

      case 'Demo Control':
        return <DemoControl />;

      case 'Proteus':
        return <Copilot />;

      default:
        return null;
    }
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton variant="primary" onClick={handleUpdateSettings}>
        Update
      </StyledButton>
    </ButtonsWrapper>
  );

  const selectedOptions = useMemo(
    () =>
      options.reduce((all, option) => {
        // hide proteus in settings based on user acess
        if (
          option.name === 'Proteus' &&
          (!settings.userInfo?.access['SeaGPT'] || isTMC)
        )
          return all;
        // hide vessels in settings based on user acess
        if (option.name === 'Vessels' && isTMC) return all;
        if (option.hide) return all;
        all.push({ ...option, active: selected === option.name });
        return all;
      }, [] as SettingsItem[]),
    [selected, settings, isTMC]
  );

  return (
    <Modal
      width={900}
      title="Settings"
      actions={actions}
      closeModal={closeModal}
      styles={noPaddingContentStyles}
    >
      {isMobile ? (
        <MobileTabs
          options={selectedOptions}
          activeTab={selected}
          setActiveTab={setSelected}
          detailsComponent={renderSettingsDetails()}
        />
      ) : (
        <Wrapper>
          <Sidebar>
            {selectedOptions.map(({ name, active, icon: Icon }) => {
              const hasInvalidField = name === 'Account' && accountErrorMessage;
              return (
                <ItemWrapper
                  key={name}
                  $active={active}
                  data-id={getDataId(name, { suffix: 'settings' })}
                  onClick={() => setSelected(name)}
                >
                  <FlexWrapper>
                    <IconWrapper>
                      <StyledIcon $active={active}>
                        <Icon />
                      </StyledIcon>
                    </IconWrapper>
                    <ItemName $active={active}>{name}</ItemName>
                  </FlexWrapper>
                  {hasInvalidField && (
                    <Tooltip content="Required field(s) missing">
                      <FlexWrapper>
                        <StyledInfoIcon $active={active} />
                      </FlexWrapper>
                    </Tooltip>
                  )}
                </ItemWrapper>
              );
            })}
          </Sidebar>
          <Main>{renderSettingsDetails()}</Main>
        </Wrapper>
      )}
    </Modal>
  );
}

export default memo(SettingsModal);
