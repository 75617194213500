import styled from 'styled-components/macro';

import { Modal } from 'components/shared';
import { TravelRequirements } from '../../../types';

import { StyledButton } from '../../../common';

const Wrapper = styled.div`
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0 1.25rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SectionWrapper = styled.div<{ $hasInfo: boolean }>`
  ${({ $hasInfo }) =>
    !$hasInfo &&
    `
    display: flex;
    align-items: center;
  `};
`;

const Header = styled.div`
  font-size: 1.1rem;
  line-height: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
`;

const Spacer = styled.div`
  margin-bottom: 1.25rem;
`;

const Text = styled.div`
  margin-left: 0.5rem;
`;

type Props = {
  details: {
    country: string;
    offsigner: TravelRequirements;
    onsigner: TravelRequirements;
  };
  closeModal: () => void;
};

const hasCrewInfo = (object: { [key: string]: string }) =>
  Boolean(Object.values(object).filter(Boolean).length);

function TravelRequirementsModal({ details, closeModal }: Props) {
  const { country, offsigner, onsigner } = details;

  const actions = (
    <StyledButton variant="primary" onClick={closeModal}>
      Close
    </StyledButton>
  );

  return (
    <Modal
      width={400}
      title="Travel Requirements"
      closeModal={closeModal}
      actions={actions}
    >
      <Wrapper>
        <FlexWrapper>
          <Header>Country: </Header>
          <Text>{country}</Text>
        </FlexWrapper>

        <Spacer />

        <SectionWrapper $hasInfo={hasCrewInfo(offsigner)}>
          <Header>Offsigner:</Header>
          {hasCrewInfo(offsigner) ? (
            <ul>
              {Object.values(offsigner).map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          ) : (
            <Text>No offsigner travel requirements available.</Text>
          )}
        </SectionWrapper>

        <Spacer />

        <SectionWrapper $hasInfo={hasCrewInfo(offsigner)}>
          <Header>Onsigner:</Header>
          {hasCrewInfo(offsigner) ? (
            <ul>
              {Object.values(onsigner).map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          ) : (
            <Text>No onsigner travel requirements available.</Text>
          )}
        </SectionWrapper>
      </Wrapper>
    </Modal>
  );
}

export default TravelRequirementsModal;
