import { memo } from 'react';
import { useDownloadCSV } from 'hooks';
import { Button } from '../shared';
import { ActionButtonState } from './SidePanelVesselHeader';

function DownloadCSV() {
  const { onDownload, downloadCSVState } = useDownloadCSV();
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={() => onDownload()}
      style={{ margin: 0 }}
    >
      {(() => {
        switch (downloadCSVState) {
          case ActionButtonState.normal:
            return 'DOWNLOAD CSV';
          case ActionButtonState.in_progress:
            return 'DOWNLOADING...';
          default:
            return 'DOWNLOAD CSV';
        }
      })()}
    </Button>
  );
}

export default memo(DownloadCSV);
