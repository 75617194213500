import { combineReducers, PayloadAction } from '@reduxjs/toolkit';

import authReducer, { initialAuth } from './auth';
import crewChangeResourcesReducer, {
  initialCCResources,
} from './crewChangeResources';
import portsInRangeReducer, { initialPortsInRange } from './portsInRange';
import appTooltipReducer, { initialAppTooltip } from './appTooltip';
import setupWizardReducer, { initialSetupWizard } from './setupWizard';
import desktopNotificationsReducer, {
  initialDesktopNotifications,
} from './desktopNotifications';
import flotillaSearchReducer, { initialFlotillaSearch } from './flotillaSearch';
import mapRulerReducer, { initialMapRuler } from './mapRuler';
import mapStateReducer, { initialMapState } from './mapState';
import infoboxPopupReducer, { initialInfoboxPopup } from './infoboxPopup';
import mapVesselsReducer, { initialMapVessels } from './mapVessels';
import sidePanelReducer, { initialSidePanel } from './sidePanel';
import crewChangePanelReducer, { initialCCPanel } from './crewChangePanel';
import crewChangeMatrixReducer, { initialCCMatrix } from './crewChangeMatrix';
import settingsReducer, { initialSettings } from './settings';
import remindersReducer, { initialReminders } from './reminders';
import onboardReducer, { initialOnboardState } from './onboard';
import redirectReducer, { initialRedirect } from './redirect';
import recentSearchesReducer, { initialRecentSearches } from './recentSearches';
import riskAreaReducer, { initialRiskArea } from './riskArea';
import portCallsReducer, { initialPortCalls } from './portCalls';
import timeTravelReducer, { initialTimeTravel } from './timeTravel';
import timeTravelDataReducer, { initialTimeTravelData } from './timeTravelData';
import mapEventsReducer, { initialMapEvents } from './mapEvents';
import calculatedRouteReducer, {
  initialCalculatedRoute,
} from './calculatedRoute';
import copilotReducer, { initialCopilot } from './copilot';
import portNotesReducer, { initialPortNotes } from './portNotes';
import bookingReducer, { initialBooking } from './booking';
import preferredPortsReducer, { initialPreferredPorts } from './preferredPorts';
import mapPortsReducer, { initialMapPort } from './mapPorts';

const appInitialState = {
  authed: initialAuth,
  appTooltip: initialAppTooltip,
  crewChangePanel: initialCCPanel,
  crewChangeMatrix: initialCCMatrix,
  crewChangeResources: initialCCResources,
  flotillaSearch: initialFlotillaSearch,
  infoboxPopup: initialInfoboxPopup,
  mapRuler: initialMapRuler,
  mapState: initialMapState,
  mapEvents: initialMapEvents,
  mapVessels: initialMapVessels,
  portsInRange: initialPortsInRange,
  preferredPorts: initialPreferredPorts,
  reminders: initialReminders,
  settings: initialSettings,
  sidePanel: initialSidePanel,
  setupWizard: initialSetupWizard,
  onboard: initialOnboardState,
  desktopNotifications: initialDesktopNotifications,
  // redirect: initialRedirect,
  recentSearches: initialRecentSearches,
  riskArea: initialRiskArea,
  portCalls: initialPortCalls,
  timeTravel: initialTimeTravel,
  timeTravelData: initialTimeTravelData,
  calculatedRoute: initialCalculatedRoute,
  copilot: initialCopilot,
  portNotes: initialPortNotes,
  booking: initialBooking,
  mapPort: initialMapPort,
};

const appReducer = combineReducers({
  authed: authReducer,
  crewChangeResources: crewChangeResourcesReducer,
  portsInRange: portsInRangeReducer,
  appTooltip: appTooltipReducer,
  setupWizard: setupWizardReducer,
  desktopNotifications: desktopNotificationsReducer,
  flotillaSearch: flotillaSearchReducer,
  mapRuler: mapRulerReducer,
  mapState: mapStateReducer,
  mapEvents: mapEventsReducer,
  mapVessels: mapVesselsReducer,
  infoboxPopup: infoboxPopupReducer,
  sidePanel: sidePanelReducer,
  crewChangePanel: crewChangePanelReducer,
  crewChangeMatrix: crewChangeMatrixReducer,
  settings: settingsReducer,
  reminders: remindersReducer,
  riskArea: riskAreaReducer,
  onboard: onboardReducer,
  redirect: redirectReducer, // contains app redirect details
  recentSearches: recentSearchesReducer,
  portCalls: portCallsReducer,
  timeTravel: timeTravelReducer,
  timeTravelData: timeTravelDataReducer,
  calculatedRoute: calculatedRouteReducer,
  copilot: copilotReducer,
  portNotes: portNotesReducer,
  booking: bookingReducer,
  preferredPorts: preferredPortsReducer,
  mapPort: mapPortsReducer,
});

type Reducer = ReturnType<typeof appReducer>;

const rootReducer = (state: any, action: PayloadAction<any>) => {
  const { type, payload } = action;

  if (type === 'store/reset') {
    // initial authed will be true, if the application loads with a previous session
    return appReducer(
      {
        ...appInitialState,
        authed: { isAuthed: false, isAdmin: false },
        // maintain this state for possible redict after login based on payload
        redirect: payload.clearRedirect ? initialRedirect : state.redirect,
      },
      action
    );
  }

  return appReducer(state as Reducer, action);
};

export default rootReducer;
