import { useContext } from 'react';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { blue, darkBlue, white } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { trackConfirmedFlight } from 'lib/alasql/flights';
import { trackFlight as trackFlightApi } from 'api/flotilla';
import { updateFlightSearch } from 'redux/actions';
import { FlightSearchResult } from 'utils/types';
import {
  TRACK_QUICKFLY_FLIGHT_TRACKING_SUCCESS,
  TRACK_QUICKFLY_FLIGHT_TRACKING_FAIL,
} from 'utils/analytics/constants';
import { Flight } from 'utils/types/crew-change-types';
import { SearchContext } from 'contexts/SearchContext';

import FlightTrackSection from './FlightTrackSection';
import Button from '../Button';
import { ModalView } from '../AllFlightResults/types';

import { getFlightTrackRequest } from './helpers';

export const SelectButton = styled(Button)`
  min-height: unset;
  min-width: unset;
  margin: 0;
  margin-top: 0.5rem;
  font-size: 0.65rem;
  padding: 3px 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${white};
  background: ${blue};
  transition: all 150ms ease;
  &:hover {
    background: ${darkBlue};
  }
`;

type Props = {
  flight: Flight;
  onTrack?: (flightId: string) => void;
  modalView?: ModalView;
};

function QuickFlyFlightTrack(props: Props) {
  const dispatch = useAppDispatch();
  const { results } = useContext(SearchContext);

  const { flight: currentFlight, modalView } = props;
  const flights = (results as FlightSearchResult[])[0].results || [];

  const handleTrackFlight = async (trackedFlight: Flight) => {
    // send track flight request to backend
    const { success, message } = await trackFlightApi(
      getFlightTrackRequest(trackedFlight)
    );

    if (success) {
      const flightList = flights.map((flight) =>
        flight.id === trackedFlight.id
          ? { ...trackedFlight, tracked: true }
          : flight
      );
      // update store for successful tracking
      dispatch(updateFlightSearch({ flights: flightList }));
    }
    const testId = `e2e_flight-track-${success ? 'success' : 'error'}-toaster`;
    showToaster({ message, type: success ? 'success' : 'error', testId });
    trackUserAction(
      success
        ? TRACK_QUICKFLY_FLIGHT_TRACKING_SUCCESS
        : TRACK_QUICKFLY_FLIGHT_TRACKING_FAIL,
      'happened',
      {
        flight: flights,
      }
    );
  };

  return (
    <FlightTrackSection
      isQuickFly
      modalView={modalView}
      flight={currentFlight}
      trackFlight={handleTrackFlight}
    />
  );
}

function CCPanelFlightTrack(props: Props) {
  const { flight, onTrack } = props;

  const handleTrackFlight = async (selectedFlight: Flight) => {
    const { originalId: flightId } = selectedFlight;
    // send track flight request to backend
    const { success, message } = await trackFlightApi(
      getFlightTrackRequest(selectedFlight)
    );

    if (success && flightId) {
      onTrack?.(flightId); // update flight in table
      trackConfirmedFlight(flightId); // update store for successful tracking
    }
    showToaster({ message, type: success ? 'success' : 'error' });
  };

  return <FlightTrackSection flight={flight} trackFlight={handleTrackFlight} />;
}

export { QuickFlyFlightTrack, CCPanelFlightTrack };
