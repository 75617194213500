import styled from 'styled-components/macro';

import { textGray } from 'lib/colors';
import { LottieAnimation } from 'components/shared';
import { ResultCard as Card } from '../../common';

const AnimationWrapper = styled.div<{ $isModal?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  ${({ $isModal }) =>
    $isModal &&
    `
    padding: 1rem;
    height: 200px;
  `};
`;

const StyledCard = styled(Card)`
  max-width: 100%;
  padding-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const AnimationTitle = styled.div`
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
  color: ${textGray};
`;

type Props = {
  isModal?: boolean;
  width?: string;
};

function FetchingLoader({ isModal, width }: Props) {
  const renderLoaderContent = () => (
    <AnimationWrapper $isModal={isModal}>
      <AnimationTitle data-id="e2e_flight-search-loader">
        Fetching flights...
      </AnimationTitle>
      <LottieAnimation name="64012-loader-plane-voi" width={width || '100%'} />
    </AnimationWrapper>
  );

  return isModal ? (
    renderLoaderContent()
  ) : (
    <StyledCard>{renderLoaderContent()}</StyledCard>
  );
}

export default FetchingLoader;
