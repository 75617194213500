import styled from 'styled-components/macro';
import CloseIcon from '@mui/icons-material/Close';

type Style = { [key: string]: string | number };

const IconWrapper = styled.div<{ $size: number; $circular?: boolean }>`
  height: ${({ $size }) => `${$size + $size * 0.25}px`};
  width: ${({ $size }) => `${$size + $size * 0.25}px`};
  padding: ${({ $size }) => `${$size * 0.25}px`};
  border-radius: ${({ $circular }) => ($circular ? '50%' : '6px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f4;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e7e7e9;
  }
`;

const StyledCloseIcon = styled(CloseIcon)<{
  $size: number;
  $iconStyle?: Style;
}>`
  font-size: ${({ $size }) => `${$size}px !important`};
  ${({ $iconStyle }) => $iconStyle};
`;

type Props = {
  size?: number;
  circular?: boolean;
  iconStyle?: Style; // custom icon styling
  onClick: () => void;
};

function CloseButton({
  size = 20,
  circular,
  iconStyle,
  onClick,
  ...rest
}: Props): JSX.Element {
  return (
    <IconWrapper
      data-testid="e2e_modal-close-button"
      $size={size}
      $circular={circular}
      onClick={onClick}
      {...rest}
    >
      <StyledCloseIcon $size={size} $iconStyle={iconStyle} />
    </IconWrapper>
  );
}

export default CloseButton;
