import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  fetchPortHotels,
  searchPortsForRestrictions as searchPorts,
} from 'api/flotilla';
import { TRACK_SEARCH_HOTEL_PORT_INPUT } from 'utils/analytics/constants';
import {
  HotelsResponse,
  HotelsSearchResult,
  ParsedSearchType,
} from 'utils/types';
import { RestrictionResults as HotelsPort } from 'utils/types/search';

type SearchType = 'hotelSearch';
type Props = {
  searchType: ParsedSearchType;
} | null;

// common formatting of search results for hotel search
const formatHotelSearchResults = (results: HotelsPort[] | HotelsResponse) => {
  return {
    searchType: 'hotelSearch' as SearchType,
    searchFieldName: 'hotels',
    searchFieldDescription: '',
    priority: 10,
    filterLabel: 'hotels nearby the port',
    results,
  };
};

// hook used for 2-step hotels search
function useHotelsSearch(props: Props) {
  const [results, setResults] = useState<HotelsSearchResult | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { searchType } = props || {};
  const { search = '', additionalQuery: portName = '' } =
    searchType?.meta || {};

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (query: string, isHotel: boolean) => {
      if (!query) {
        return;
      }

      setLoading(true);

      // if port is selected, `locode` will be available
      // hotels will be fetched at this step
      if (isHotel) {
        // find out hotel locode from full port name/text - `(LOCODE) City`
        const locode = query.match(/\((.*?)\)/)?.[1];
        if (!locode) {
          showToaster({
            message: 'Invalid port. Hotels cannot be fetched.',
            type: 'error',
          });
          setLoading(false);
          return;
        }

        const { success, message, hotelSearchResult } = await fetchPortHotels(
          locode
        );

        if (success && hotelSearchResult) {
          setResults(
            formatHotelSearchResults(hotelSearchResult as HotelsResponse)
          );
        } else {
          showToaster({ message, type: 'error' });
        }
        setLoading(false);
        return;
      }

      /* otherwise search for ports based on search query */

      const { success, results } = await searchPorts(query);
      if (success && results) {
        setResults(formatHotelSearchResults(results));
      }
      trackUserAction(TRACK_SEARCH_HOTEL_PORT_INPUT, 'typed', { query });
      setLoading(false);
    }, 300),
    []
  );

  useEffect(() => {
    const portQuery = search.trim();
    const isHotel = Boolean(portName);
    const searchText = isHotel ? portName.trim() : portQuery;
    debouncedSearch(searchText, isHotel)?.then(() => {
      // empty result if search input is cleared
      if (!portQuery) {
        setResults(null);
      }
    });
  }, [search, portName, debouncedSearch]);

  return { results, loading };
}

export default useHotelsSearch;
