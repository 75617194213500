import uniqBy from 'lodash/uniqBy';
import {
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Autocomplete, Popper, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled as muiStyled } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import styled, { css } from 'styled-components/macro';

import { useDebounce, usePrevious } from 'hooks';
import { white } from 'lib/colors';
import { searchPorts } from 'api/flotilla';
import { SearchedPort } from 'utils/types';

import { portConversion, scrolledToEnd } from '../../../helpers';
import { PortDateType, RoutePort } from '../../../types';

const wrapperStyles = css`
  .MuiFormControl-root,
  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.85rem;
    line-height: 1.1rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiSvgIcon-root {
    height: 1rem;
    width: 1rem;
  }

  .MuiInputAdornment-root {
    margin-left: 2px;
  }
`;

const AutocompleteWrapper = styled.div<{
  $isCompact?: boolean;
}>`
  ${wrapperStyles};
  width: '90%';
  .MuiInputBase-root {
    padding: 0 0.5rem !important;
    height: ${({ $isCompact }) => ($isCompact ? '26px' : '36px')};
    background: ${white};
  }
`;

const DateWrapper = styled.div<{ $isCompact?: boolean }>`
  ${wrapperStyles};
  width: '90%';
  height: ${({ $isCompact }) => ($isCompact ? '26px' : '34px')};

  .MuiInputBase-input {
    padding: ${({ $isCompact }) =>
      $isCompact ? '0.25rem 0.6rem' : '0.5rem 0.9rem'};
    padding-right: 0;
    background: ${white};
  }
`;

const StyledPopper = muiStyled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-loading': {
    fontSize: '0.8rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const listStyles = {
  maxHeight: '250px',
  fontSize: '0.85rem',
  padding: 0,
  lineHeight: '1.2rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type CommonTypes = {
  isCompact?: boolean;
  port: RoutePort;
  onSelect: (port: RoutePort) => void;
};
type NameInputProps = CommonTypes;
type DateInputProps = CommonTypes & {
  type: PortDateType;
};
type PickerDates = {
  date?: Date | null;
  inputText?: string;
};

const formatPortDate = (date: Date | Moment | string) =>
  moment(date).utcOffset(0, true).toISOString();

const PortInputs = {
  Name: memo(({ port, isCompact, onSelect }: NameInputProps) => {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<SearchedPort | RoutePort | null>(
      portConversion.routeToSearched(port)
    );
    const [portsList, setPortsList] = useState<SearchedPort[]>([]);
    const [pagination, setPagination] = useState({ more: false, page: 1 });

    const handleFetchPorts = useDebounce(
      async (text: string, page?: number) => {
        setLoading(true);
        const { success, portsResponse } = await searchPorts(text, page);

        if (!success || !portsResponse?.results?.length) {
          setLoading(false);
          return;
        }

        const { results: ports, pagination } = portsResponse;
        setPortsList((prevPorts) =>
          uniqBy([...prevPorts, ...(ports || [])], 'id')
        );
        setPagination((prev) => ({
          more: pagination?.more,
          page: page || prev.page,
        }));
        setLoading(false);
      }
    );

    // call ports api with query update in dropdown input field
    useEffect(() => {
      if (query.length) {
        handleFetchPorts(query);
      }
    }, [query]); // eslint-disable-line

    const handleSelectPort = (
      event: SyntheticEvent,
      value: SearchedPort | string | null
    ) => {
      let newRoutePort = port;
      const selectedPort = !value || typeof value === 'string' ? null : value;
      setSelected(selectedPort);

      if (selectedPort) {
        newRoutePort = portConversion.searchedToRoute(selectedPort, port);
      } else {
        newRoutePort = { ...port, displayName: '' };
      }
      onSelect(newRoutePort);
    };

    return (
      <AutocompleteWrapper $isCompact={isCompact}>
        <Autocomplete
          freeSolo
          size="small"
          options={portsList}
          value={(selected as SearchedPort) || null} // fallback to `null` for no option
          inputValue={query}
          onChange={handleSelectPort}
          onInputChange={(_, value: string) => setQuery(value)}
          getOptionLabel={(option) => (option as SearchedPort).text}
          loading={loading}
          renderInput={(params) => (
            <TextField {...params} placeholder="Type port name" />
          )}
          ListboxProps={{
            id: 'port-dropdown',
            style: listStyles,
            onScroll: (event: SyntheticEvent) => {
              if (scrolledToEnd(event) && pagination.more) {
                handleFetchPorts(query, pagination.page + 1);
              }
            },
          }}
          PopperComponent={StyledPopper}
          sx={{ minWidth: 150 }}
        />
      </AutocompleteWrapper>
    );
  }),
  Date: memo(({ type, port, isCompact, onSelect }: DateInputProps) => {
    const initialDate = port[type] ? new Date(port[type]!.slice(0, -1)) : null;
    const [open, setOpen] = useState(false);
    const [dateObj, setDateObj] = useState<PickerDates>({
      date: initialDate,
      inputText: '',
    });

    const prevOpen = usePrevious(open);
    const updateFinished = prevOpen && !open;
    const minDate = useMemo(() => {
      const today = new Date();
      const minETDDate = port.eta ? new Date(port.eta) : today;
      return type === 'etd' ? minETDDate : today;
    }, [type, port.eta]);

    const debouncedInputUpdate = useDebounce((value?: string) => {
      const formattedDate = moment(value, 'HH:mm DD/MM/YY', true);
      const validDate = formattedDate.isValid();
      setDateObj({ inputText: validDate ? value : '' });
      // send updated port with new date back to parent component
      onSelect({
        ...port,
        [type]: validDate ? formatPortDate(formattedDate) : undefined,
      });
    });

    const handleDateChange = useCallback(
      (newDate: Date | null, inputValue?: string | undefined) => {
        if (newDate) {
          setDateObj({ date: newDate, inputText: '' });
          return;
        }
        debouncedInputUpdate(inputValue);
      },
      [debouncedInputUpdate]
    );

    useEffect(() => {
      if (updateFinished) {
        // send updated port with new date back to parent component
        onSelect({
          ...port,
          [type]: dateObj.date ? formatPortDate(dateObj.date) : undefined,
        });
      }
    }, [updateFinished, dateObj.date, port, type, onSelect]);

    return (
      <DateWrapper $isCompact={isCompact}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            disablePast
            disableMaskedInput
            defaultCalendarMonth={minDate}
            ampm={false}
            open={open}
            minDate={minDate}
            value={dateObj.date || null}
            inputFormat="HH:mm dd/MM/yy"
            onChange={handleDateChange}
            onOpen={() => setOpen(true)}
            onClose={() => {
              // adds a delay because input update is debounced
              setTimeout(() => {
                setOpen(false);
              }, 250);
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                className={`e2e_route-port-${type}-input`}
                size="small"
                autoComplete="off"
                onClick={() => setOpen(true)}
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
              />
            )}
          />
        </LocalizationProvider>
      </DateWrapper>
    );
  }),
};

export default PortInputs;
