import { useLayoutEffect, useState } from 'react';

import {
  CC_PANEL_COLLAPSED_HEIGHT,
  CC_PANEL_INITIAL_HEIGHT,
  CC_PANEL_RESIZE_BAR_HEIGHT,
} from 'lib/constants';
import usePrevious from './usePrevious';

const getPanelMaxHeight = (event: MouseEvent | TouchEvent) => {
  if (!event.view) {
    return window.innerHeight * 0.9;
  }

  const { innerHeight: windowHeight } = event.view.window;
  switch (true) {
    case windowHeight >= 1200:
      return windowHeight * 0.9;

    default:
      return windowHeight * 0.95;
  }
};

function usePanelResize(collapsed: boolean) {
  const [resizing, setResizing] = useState<boolean>(false);
  const prevCollapsed = usePrevious(collapsed);
  const panel = document.getElementById('resizable-container');
  const resizebar = document.getElementById('resize-bar');

  // update panel height with `collapse` button click
  useLayoutEffect(() => {
    switch (true) {
      case !panel:
        break;
      // set to initial height when expanded
      case panel!.style.height === CC_PANEL_COLLAPSED_HEIGHT &&
        prevCollapsed &&
        !collapsed:
        panel!.style.height = CC_PANEL_INITIAL_HEIGHT;
        break;
      // set to collapsed height when minimized
      case !prevCollapsed && collapsed:
        panel!.style.height = CC_PANEL_COLLAPSED_HEIGHT;
        break;
      default:
        break;
    }
  }, [panel, collapsed, prevCollapsed, resizing]);

  useLayoutEffect(() => {
    let pos: number;

    // method to handle resize with mouse move on CC panel resize bar
    const handleResize = (e: MouseEvent) => {
      const MAX_HEIGHT = getPanelMaxHeight(e);
      const dy = pos - e.y;
      pos = e.y;
      if (panel) {
        panel.style.height =
          panel.offsetHeight <= MAX_HEIGHT + 1
            ? parseInt(getComputedStyle(panel, '').height) + dy + 'px'
            : `${MAX_HEIGHT}px`;
      }
    };

    // method to handle resize with touch on CC panel resize bar
    const handleTouchResize = (e: TouchEvent) => {
      const MAX_HEIGHT = getPanelMaxHeight(e);
      const touched = e.touches[0];
      const dy = pos - touched.clientY;
      pos = touched.clientY;
      if (panel) {
        panel.style.height =
          panel.offsetHeight <= MAX_HEIGHT + 1
            ? parseInt(getComputedStyle(panel, '').height) + dy + 'px'
            : `${MAX_HEIGHT}px`;
      }
    };

    // method to handle panel resize with window resize
    const handleWindowResize = (event: Event) => {
      const MAX_HEIGHT = (event.target as Window).innerHeight * 0.9;
      if (panel && panel.offsetHeight > MAX_HEIGHT) {
        panel.style.height = `${MAX_HEIGHT}px`;
      }
    };

    if (panel && resizebar) {
      const gripHeight = parseInt(getComputedStyle(resizebar).height);
      resizebar.addEventListener('mousedown', (e) => {
        const { y } = panel.getBoundingClientRect();
        const diff = e.clientY - y;
        if (diff <= gripHeight && diff > 0) {
          pos = e.y;
          setResizing(true);
          document.addEventListener('mousemove', handleResize);
        }
      });

      resizebar.addEventListener('touchstart', (e) => {
        try {
          const touched = e.touches[0];
          pos = touched.clientY;
          const getHeight = parseInt(getComputedStyle(panel, '').height);

          // calculate if touched resize bar
          const screenHeight = window.innerHeight;
          const topSectionHeight = screenHeight - getHeight;
          if (
            touched.clientY > topSectionHeight &&
            touched.clientY < topSectionHeight + CC_PANEL_RESIZE_BAR_HEIGHT
          ) {
            setResizing(true);
            document.addEventListener('touchmove', handleTouchResize);
          }
        } catch (error) {
          console.error('Error resizing on mobile', (error as any).message);
        }
      });
    }

    document.addEventListener('mouseup', () => {
      setResizing(false);
      document.removeEventListener('mousemove', handleResize);
    });

    document.addEventListener('touchend', () => {
      setResizing(false);
      document.removeEventListener('touchmove', handleTouchResize);
    });

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [panel, resizebar]);

  return {
    resizing,
    panelHeight: panel?.offsetHeight || parseInt(CC_PANEL_COLLAPSED_HEIGHT, 10),
  };
}

export default usePanelResize;
