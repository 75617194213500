import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBatchPortCalls, getVesselPortCalls } from 'api/flotilla';
import type { BatchGetPortCallsResp } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import {
  updatePortLocodeVesselMap,
  updateVesselPortCallBatches,
  updateVesselPortCalls,
} from 'redux/actions';
import { formatPortCallUserTimeV2 } from 'components/SidePanel/VesselCourse/helpers';

import type { LocodeMap, RootState } from 'redux/types';
import type { PortCallResponse, PortCallResponseMap } from 'utils/types';
import { getEarliestDate } from 'utils/dates';

/* ----- Async Thunks ----- */

export const fetchVesselPortCallsAsync = createAsyncThunk(
  'portCalls/fetchVesselPortCallsAsync',
  async (
    { vesselId, refetch = false }: { vesselId: number; refetch?: boolean },
    { getState, dispatch }
  ): Promise<PortCallResponse | null> => {
    const { portCalls } = getState() as RootState;
    if (portCalls.portCalls[vesselId] && !refetch) {
      return portCalls.portCalls[vesselId];
    }
    try {
      const response = await getVesselPortCalls(vesselId);
      if (!response.success) {
        return null;
      }
      dispatch(updateVesselPortCalls({ vesselId, ...response }));
      const locodeMap = response.portCalls.reduce((obj, portCall) => {
        if (!portCall.portLocode) return obj;
        if (!portCall.utcETA && !portCall.utcETD) return obj;
        const earliestDate = getEarliestDate([
          portCall.utcETA,
          portCall.utcETD,
        ]);
        if (new Date(earliestDate).getTime() < Date.now()) return obj;
        if (!obj[portCall.portLocode]) {
          obj[portCall.portLocode] = [vesselId];
        } else {
          obj[portCall.portLocode].push(vesselId);
        }
        return obj;
      }, {} as LocodeMap);
      dispatch(updatePortLocodeVesselMap(locodeMap));
      return response;
    } catch (error) {
      return null;
    }
  }
);

export const fetchBatchVesselPortCallsAsync = createAsyncThunk(
  'portCalls/fetchBatchVesselPortCallsAsync',
  async (
    { vesselIds }: { vesselIds: number[] },
    { dispatch }
  ): Promise<BatchGetPortCallsResp | null> => {
    try {
      const response = await getBatchPortCalls({ flotVesIds: vesselIds });
      if (response.success && response.portCalls) {
        // transform data
        const transformed = Object.keys(response.portCalls).reduce(
          (objMap, vesselId) => {
            const { portCallsV2, meta, dictionary } =
              response.portCalls![vesselId];
            const portMap = dictionary.ports.reduce(
              (map, port) => ({ ...map, [port.locode]: port }),
              {}
            );
            const formattedPortCallsArray = portCallsV2.map((o) =>
              formatPortCallUserTimeV2(o, portMap, Number(vesselId))
            );
            formattedPortCallsArray.forEach((portCall) => {
              const { portLocode } = portCall;
              if (!portLocode) return;
              if (!portCall.utcETA && !portCall.utcETD) return;
              // Check if it is a future port call
              const earliestDate = getEarliestDate([
                portCall.utcETA,
                portCall.utcETD,
              ]);
              if (new Date(earliestDate).getTime() < Date.now()) return;
              if (!objMap.locodeMap[portLocode]) {
                objMap.locodeMap[portLocode] = [Number(vesselId)];
              } else if (
                !objMap.locodeMap[portLocode].includes(Number(vesselId))
              ) {
                objMap.locodeMap[portLocode].push(Number(vesselId));
              }
            });

            objMap.vesselIdMap[Number(vesselId)] = {
              portCalls: formattedPortCallsArray,
              meta,
              success: response.success,
              message: response.message,
            };
            return objMap;
          },
          { vesselIdMap: {}, locodeMap: {} } as {
            vesselIdMap: PortCallResponseMap;
            locodeMap: LocodeMap;
          }
        );

        dispatch(updateVesselPortCallBatches(transformed.vesselIdMap));
        dispatch(updatePortLocodeVesselMap(transformed.locodeMap));
        return response.portCalls!;
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);
