import {
  object,
  boolean,
  string,
  array,
  enums,
  Describe,
  number,
  tuple,
  nullable,
  optional,
  coerce,
  record,
  defaulted,
  StructError,
  mask,
} from 'superstruct';
import uniq from 'lodash/uniq';
import { SearchFilter } from '@greywing-maritime/frontend-library/dist/types/alerts';
import {
  PriceDiffTypes,
  TrackFlightsSettings,
} from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';
import { USER_TYPES } from '@greywing-maritime/frontend-library/dist/types/userConfig';

import { trackUserAction } from 'lib/amplitude';
import { TRACK_SETTINGS_VALIDATION_FAIL } from 'utils/analytics/constants';
import { SIDE_PANEL_DEFAULT_EXCLUSION } from 'lib/constants';
import { DEFAULT_POPUP_FIELDS } from 'redux/helpers/settings';
import { PortParams as CCPanelPortParams } from 'components/CrewChangePanel/types';
import {
  AppSettings,
  CCPanelCostParams,
  CCPanelFlightParams,
  CCPanelSettings,
  VesselsSettings,
} from 'redux/types';
import { UserInfo } from 'utils/types';
import {
  HIGHLIGHTABLE_FIELDS,
  ALL_SIDEPANEL_FIELDS,
  ALL_POPUPABLE_FIELDS,
  ALL_LABELABLE_FIELDS,
} from 'utils/vesselFields';
import { DEFAULT_ETA_LIMIT } from 'components/CrewChangePanel/helpers';

const VesselsSchema: Describe<VesselsSettings> = object({
  predictive: defaulted(boolean(), true),
  updateFrequency: defaulted(number(), 5),
  unit: defaulted(enums(['KM', 'NM']), 'NM'),
});

const CCPanelPortParamsSchema: Describe<CCPanelPortParams> = object({
  unit: defaulted(enums(['KM', 'NM']), 'NM'),
  range: number(),
  agency: string(),
  priorities: array(string()),
  showUrgentPorts: boolean(),
  etaLimit: defaulted(number(), DEFAULT_ETA_LIMIT),
});

const CCPanelFlightParamsSchema: Describe<CCPanelFlightParams> = object({
  time: number(),
  layover: tuple([number(), number()]),
  stops: number(),
  allowAirportTransfer: defaulted(boolean(), false),
});

const CCPanelCostParamsSchema: Describe<CCPanelCostParams> = object({
  hotelCostDelay: number(),
  costOptions: array(enums(['Flight', 'Agency', 'Wage', 'Hotel'])),
});

const CCPanelSettingsSchema: Describe<CCPanelSettings> = object({
  showGuide: boolean(),
  predictive: boolean(),
  compact: boolean(),
  portParams: CCPanelPortParamsSchema,
  flightParams: CCPanelFlightParamsSchema,
  costParams: CCPanelCostParamsSchema,
});

const UserInfoSchema: Describe<UserInfo> = object({
  id: number(),
  type: enums([
    USER_TYPES.OMEGA,
    USER_TYPES.CLIENT,
    USER_TYPES.EDITOR,
    USER_TYPES.ADMIN,
    USER_TYPES.SUPPLIER,
    USER_TYPES.TMC,
    USER_TYPES.CUSTOMER_SUCCESS,
  ]),
  firstname: nullable(string()),
  lastname: nullable(string()),
  phone: nullable(string()),
  jobTitle: defaulted(nullable(string()), null), // added on 5th Sept 2023
  loginEmail: string(),
  notificationEmail: nullable(string()),
  timezone: nullable(string()),
  access: record(string(), boolean()),
  company: nullable(
    object({
      id: number(),
      name: string(),
    })
  ),
  allowedTravelVendors: array(string()),
  vendorsDisabledForFRE: array(string()),
});

const TrackFlightSettingsSchema: Describe<TrackFlightsSettings> = object({
  receiveNotifications: boolean(),
  sameFlightsOnly: boolean(),
  sameSourceOnly: boolean(),
  stopDaysBefore: number(),
  priceDiff: object({
    type: enums([PriceDiffTypes.ACT, PriceDiffTypes.PCT]),
    value: number(),
  }),
});

const SearchFilterSchema: Describe<SearchFilter> = object({
  query: string(),
  field: string(),
  label: string(),
  exclude: boolean(),
});

const SettingsSchema = object({
  crossDevices: boolean(),
  colorHighlightField: enums(HIGHLIGHTABLE_FIELDS),
  popupFields: array(enums(ALL_POPUPABLE_FIELDS)),
  sidePanelFields: array(enums(ALL_SIDEPANEL_FIELDS)),
  vesselLabelField: enums(ALL_LABELABLE_FIELDS),
  searchFilters: array(SearchFilterSchema),
  vessels: VesselsSchema,
  crewChange: CCPanelSettingsSchema,
  userInfo: optional(UserInfoSchema),
  trackSettings: nullable(TrackFlightSettingsSchema),
  quickFlyTMC: defaulted(string(), 'ALL'),
});

export const SettingsForceCoerceSchema = object({
  colorHighlightField: coerce(enums(HIGHLIGHTABLE_FIELDS), string(), (o) => {
    if (!HIGHLIGHTABLE_FIELDS.includes(o)) {
      return 'type';
    }
    return o;
  }),
  popupFields: defaulted(
    coerce(
      array(enums(ALL_POPUPABLE_FIELDS)),
      array(string()),
      (o: string[]) => {
        return uniq(
          o.reduce<string[]>((fields, field) => {
            if (ALL_POPUPABLE_FIELDS.includes(field)) {
              fields.push(field);
            }
            return fields;
          }, [])
        );
      }
    ),
    DEFAULT_POPUP_FIELDS
  ),
  sidePanelFields: defaulted(
    coerce(
      array(enums(ALL_SIDEPANEL_FIELDS)),
      array(string()),
      (o: string[]) => {
        return uniq(
          o.reduce<string[]>((fields, field) => {
            if (ALL_SIDEPANEL_FIELDS.includes(field)) {
              fields.push(field);
            }
            return fields;
          }, [])
        );
      }
    ),
    ALL_SIDEPANEL_FIELDS.filter(
      (fieldId) => !SIDE_PANEL_DEFAULT_EXCLUSION.includes(fieldId)
    )
  ),
  vesselLabelField: coerce(enums(ALL_LABELABLE_FIELDS), string(), (o) => {
    if (!ALL_LABELABLE_FIELDS.includes(o)) {
      return 'label_name';
    }
    return o;
  }),
});

export function validateAppSettings(settings: any): AppSettings | undefined {
  // This step checks if all values are valid and returns settings object
  try {
    const validated = mask(settings, SettingsSchema);
    return validated;
  } catch (error) {
    console.log('Error while validating settings data', error);
    // This step checks if there are coercable fields
    if (error instanceof StructError) {
      const COERCABLE_FIELDS = [
        'colorHighlightField',
        'popupFields',
        'sidePanelFields',
        'vesselLabelField',
      ];
      const failedPaths: { key: string; value: any }[] = [];
      const hasCoercableFields = error
        .failures()
        .reduce((coerce, { value, path }) => {
          // console.error(path, key, value);
          failedPaths.push({ key: path[0], value });
          if (COERCABLE_FIELDS.includes(path[0])) {
            return true;
          }
          return coerce;
        }, false);
      // console.log('Failed at', failedPaths);
      trackUserAction(TRACK_SETTINGS_VALIDATION_FAIL, 'happened', {
        fields: failedPaths,
      });

      if (hasCoercableFields) {
        try {
          const transformValidate = mask(
            {
              ...settings,
              ...mask(settings, SettingsForceCoerceSchema),
            },
            SettingsSchema
          );
          return transformValidate;
        } catch (error) {
          console.log('Still errored..', error);
        }
      }
    }
  }
}

export default SettingsSchema;
