import { memo } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components/macro';

import { SettingsItem } from './types';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const wrapperStyles = {
  marginBottom: '1rem',
  position: 'sticky',
  top: 0,
  background: '#fff',
  maxWidth: 'inherit',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)',
  zIndex: 99999,
};

const tabStyles = {
  fontSize: '0.75rem',
  fontFamily: 'HK Grotesk, Roboto',
  fontWeight: 'bold',
};

type Props = {
  options: SettingsItem[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  detailsComponent: React.ReactNode;
};

function MobileTabs({
  options,
  activeTab,
  setActiveTab,
  detailsComponent,
}: Props) {
  return (
    <Wrapper>
      <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        value={activeTab}
        onChange={(_: any, newValue: string) => setActiveTab(newValue)}
        sx={wrapperStyles}
      >
        {options.map(({ name }, index) => (
          <Tab
            key={`${name}_${index}`}
            label={name}
            value={name}
            sx={tabStyles}
          />
        ))}
      </Tabs>
      <div>{detailsComponent}</div>
    </Wrapper>
  );
}

export default memo(MobileTabs);
