import { SearchFilter } from '@greywing-maritime/frontend-library/dist/types/alerts';

import { ETA_SEARCH_KEYS } from 'lib/constants';
import { UserInfo } from 'utils/types';
import {
  getHighlightableFields,
  getLabellableFields,
  getPopuppableFields,
  getSidePanelDisplayableFields,
} from 'utils/vesselFields';

import {
  AppDispatch,
  AppThunk,
  CCPanelSettings,
  CombinedSettingsPayload,
  GetState,
  VesselsSettings,
} from '../types';

/* ----- Async Thunks ---- */

// NONE

/* ----- Sync Thunks ---- */

// Settings should be updated with thunks which dispatch action that has payload of `AppSettings` type
// The payloads dispatched with action here are caught in middleware & saved in back-end

export const UPDATE_APP_SETTINGS = 'settings/updateAppSettings';
export const UPDATE_APP_COMBINED_SETTINGS =
  'settings/updateAppCombinedSettings';

export const updateColorHighlightField =
  (highlightField: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const highlightableFields = getHighlightableFields();
    const payload = {
      ...state,
      colorHighlightField:
        highlightField && highlightableFields.includes(highlightField)
          ? highlightField
          : 'type',
    };
    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const updateLabelField =
  (label: string): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const labellableFields = getLabellableFields();
    const payload = !labellableFields.includes(label)
      ? {
          ...state,
          vesselLabelField: label,
        }
      : state;

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const updatePopUpFields =
  (fields: string[]): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const popuppableFields = getPopuppableFields();
    const payload = {
      ...state,
      popupFields: fields.filter((fieldId) =>
        popuppableFields.includes(fieldId)
      ),
    };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const updateSidePanelFields =
  (fields: string[]): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const sidePanelDisplayableFields = getSidePanelDisplayableFields();
    const payload = {
      ...state,
      sidePanelFields: fields.filter((fieldId) =>
        sidePanelDisplayableFields.includes(fieldId)
      ),
    };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const addSearchFilter =
  (newFilter: SearchFilter): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const filters = Array.from(state.searchFilters);
    const hasQuery = filters.some(
      (filter) =>
        filter.field === newFilter.field &&
        filter.query.toLowerCase() === newFilter.query.toLowerCase()
    );

    if (hasQuery) return state;

    const hasETAFilter =
      ETA_SEARCH_KEYS.includes(newFilter.field) &&
      filters.some(({ field }) => ETA_SEARCH_KEYS.includes(field));
    const updatedFilters = [
      ...(hasETAFilter
        ? filters.filter(({ field }) => ETA_SEARCH_KEYS.includes(field))
        : filters),
      newFilter,
    ];

    const payload = { ...state, searchFilters: updatedFilters };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const removeSearchFilter =
  (index: number): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const newFilters = Array.from(state.searchFilters);
    newFilters.splice(index, 1);
    const payload = { ...state, searchFilters: newFilters };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const clearSearchFilters =
  (): AppThunk => (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const payload = { ...state, searchFilters: [] };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const updateCCPanelSettings =
  (newSettings: Partial<CCPanelSettings>): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const payload = {
      ...state,
      crewChange: {
        ...state.crewChange,
        ...newSettings,
      },
    };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const setUserInfo =
  (userInfo: UserInfo): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();

    try {
      if ((window as any).hj) {
        (window as any).hj('identify', userInfo.id, {
          email: userInfo.loginEmail,
          company: userInfo.company?.id,
        });
      }
    } catch (err) {
      console.error('Could not set user info to hj.');
    }
    const payload = { ...state, userInfo };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

export const updateVesselsSettings =
  (newSettings: VesselsSettings): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const payload = {
      ...state,
      vessels: { ...state.vessels, ...newSettings },
    };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

// action to set flotilla configs across multiple devices
export const toggleCrossDevices =
  (value: boolean): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const payload = { ...state, crossDevices: value };

    dispatch({ type: UPDATE_APP_SETTINGS, payload });
  };

// combined update from settings section of the app
export const updateCombinedSettings =
  (newSettings: CombinedSettingsPayload): AppThunk =>
  (dispatch: AppDispatch, getState: GetState) => {
    const { settings: state } = getState();
    const {
      crossDevices,
      vessels,
      crewChange,
      userInfo,
      trackSettings,
      quickFlyTMC,
    } = newSettings;
    const payload = {
      ...state,
      crossDevices,
      vessels,
      crewChange,
      userInfo,
      trackSettings,
      quickFlyTMC,
    };

    dispatch({ type: UPDATE_APP_COMBINED_SETTINGS, payload });
  };
