import { ReactNode, useRef } from 'react';
import styled from 'styled-components/macro';

import { useOneClickPlan } from 'hooks';
import { blue, gray20, green, textBlack, white } from 'lib/colors';
import { SvgIconComponent } from '@mui/icons-material';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

type Direction = 'left' | 'right';

const Button = styled.button<{
  $backgroundColor: string;
  $color: string;
  $direction?: Direction;
  $isRight?: boolean;
  $disabled?: boolean;
}>`
  font-family: HK Grotesk, Roboto;
  border: none;
  box-shadow: none;

  min-height: 70px;
  width: 64px;
  align-self: stretch;

  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 0.5rem;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

  font-size: 9px;
  font-weight: 700;
  letter-spacing: 1.38px;
  text-transform: uppercase;
  color: ${({ $color }) => $color};

  border-radius: ${({ $direction }) => ($direction === 'left' ? '0' : '4px')}
    ${({ $direction }) => ($direction === 'right' ? '0' : '4px')}
    ${({ $direction }) => ($direction === 'left' ? '4px' : '0')}
    ${({ $direction }) => ($direction === 'right' ? '4px' : '0')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  opacity: ${({ $disabled }) => ($disabled ? '0.6' : 1)};

  ${({ $disabled }) =>
    $disabled
      ? ''
      : `
  &:hover {
    cursor: pointer;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transform: translate(0, -1px);
  }
  `}
  svg {
    font-size: 1.5rem !important;
  }
`;

const getButtonDetails = (variant: string) => {
  const color = ['primary', 'success'].includes(variant) ? white : blue;
  const backgroundColor =
    (variant === 'success' && green) || (variant === 'primary' ? blue : gray20);
  const iconColor = ['primary', 'success'].includes(variant)
    ? white
    : textBlack;

  return { color, backgroundColor, iconColor };
};

type StepButtonProps = {
  children: ReactNode;
  onClick: () => void;
  variant?: string;
  disabled?: boolean;
  hide?: boolean;
  direction: 'left' | 'right';
  icon?: SvgIconComponent;
};

function StepButton({
  children,
  onClick,
  disabled,
  variant = 'primary',
  direction,
  hide,
  icon: Icon,
}: StepButtonProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useOneClickPlan({ buttonRef });

  if (hide) {
    return null;
  }

  const { color, backgroundColor, iconColor } = getButtonDetails(variant);
  const buttonProps = direction === 'right' ? { ref: buttonRef } : {};

  return (
    <Button
      {...buttonProps}
      $color={color}
      $backgroundColor={backgroundColor}
      $direction={direction}
      $disabled={disabled}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      className={`e2e_cc-panel-${direction}-step-button`}
    >
      {Icon ? (
        <Icon sx={{ color: iconColor }} />
      ) : (
        <EastRoundedIcon
          sx={{
            color: iconColor,
            transform: direction === 'right' ? 'scaleX(1)' : 'scaleX(-1)',
          }}
        />
      )}
      {children}
    </Button>
  );
}

export default StepButton;
