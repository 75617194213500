import type { FillPaint, FillLayout, MapMouseEvent } from 'mapbox-gl';
import { mapState } from 'map/map';

export const defaultFillLayerSetting = (
  id: string,
  paintProps?: FillPaint,
  layoutProps?: FillLayout
): mapboxgl.FillLayer => ({
  id,
  type: 'fill',
  paint: {
    'fill-color': '#385dea',
    'fill-opacity': 0.5,
    ...paintProps,
  },
  layout: {
    visibility: 'visible',
    ...layoutProps,
  },
});

export function layerSourcesFromMouseEvent(
  e: MapMouseEvent,
  sourceIds: string[]
) {
  if (!mapState.map) return;
  const features = mapState.map!.queryRenderedFeatures(e.point);
  const coordinates = e.lngLat;
  let current: {
    feature: mapboxgl.MapboxGeoJSONFeature;
    coordinates: mapboxgl.LngLat;
  } | null = null;
  for (const v of features) {
    if (!sourceIds.includes(v.source)) {
      continue;
    }

    current = {
      feature: v,
      coordinates,
    };
  }
  return current;
}
