import { memo, SyntheticEvent, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import styled, { css } from 'styled-components/macro';

import { useFiltersChange, useModal } from 'hooks';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { blue, blueGray, fadedGreen, orange, purple, white } from 'lib/colors';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import {
  AwesomeSlider,
  Checkbox,
  Dropdown,
  SwitchV2,
  Tooltip,
} from 'components/shared';
import { Thumb } from 'components/shared/AwesomeSlider';
import FiltersMenu from './FiltersMenu';
import ColumnsMenu from './ColumnsMenu';
import { ThumbHour } from '../common';
import ShareLinkModal from '../../../../common/LinkModals';
import { filterButtonStyles, Label, StyledButton } from '../../../../common';
import { MAX_LAYOVER_TIME, MAX_STOPS_COUNT } from '../../../../helpers';
import {
  ActionStatus,
  FareType,
  FlightFilters as FiltersType,
} from '../../../../types';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const FlexWrapper = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const Wrapper = styled(FlexWrapper)`
  margin-top: 0.8rem;
`;

const ButtonWrapper = styled.div<{ $disabled?: boolean }>`
  flex-shrink: 0;
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

const buttonStyles = css`
  ${filterButtonStyles};
  color: ${white};
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
`;

const ColumnsButton = styled(StyledButton)`
  ${buttonStyles};
  background-color: ${blueGray};
  gap: 0.5rem;
  svg {
    font-size: 18px !important;
  }
`;

const FilterButton = styled(StyledButton)<{
  $color?: string;
}>`
  ${buttonStyles};
  background-color: ${({ $color }) => $color || purple};
  gap: 0.5rem;
  svg {
    font-size: 18px !important;
  }
`;

const SliderWrapper = styled.div<{ $width?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : '100px')};
  margin-left: 0.75rem;
  margin-right: 20px;
  flex-shrink: 0;
  .MuiSlider-valueLabel {
    padding: 0.08rem 0.3rem !important;
    letter-spacing: 0.05rem;
    top: -6px;
  }
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90px;
    margin-left: 5px;
  }
`;

const CheckboxWrapper = styled(Label)<{ $isSelected: boolean }>`
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  transition: all 150ms;

  label {
    align-self: stretch;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    letter-spacing: 0.09rem;
    font-weight: 500;
  }

  #marine:focus + #checkbox-style {
    box-shadow: none;
  }
  ${({ $isSelected }) =>
    $isSelected
      ? `
          color: ${white};
          background-color: ${blue};
          #checkbox-style {
            border: 1px solid ${blue};
          }
          svg {
            stroke-width: 4px;
          }
        `
      : ''}
`;

const ToggleWrapper = styled(FlexWrapper)`
  margin-left: 1.25rem;
  column-gap: 0.5rem;
  flex-shrink: 0;
`;

const dropdownStyles = {
  minWidth: '60px',
  marginLeft: '8px',
  marginRight: '10px',
};

type Props = {
  locodeKey: string;
  sources: string[] | undefined;
  actionStatus: ActionStatus;
  toggleConfirmAll: (value: boolean) => void;
};

const getRangeValues = (filters: FiltersType) => {
  const { range, layover, stopsCount } = filters;
  // setting the max values to current filter values if less than
  // otherwise, fallback to some default values
  const { min, max } = range;
  const maxLayoverTime =
    (max?.layoverTime &&
      (max.layoverTime <= layover[1] ? layover[1] : max.layoverTime)) ??
    MAX_LAYOVER_TIME;
  const maxStopsCount =
    (max?.stopsCount &&
      (max.stopsCount <= stopsCount ? stopsCount : max.stopsCount)) ??
    MAX_STOPS_COUNT;
  return { minStopsCount: min.stopsCount, maxLayoverTime, maxStopsCount };
};

const FlightFilters = ({
  locodeKey,
  sources,
  actionStatus,
  toggleConfirmAll,
}: Props) => {
  const columnVisibility = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.columnVisibility
  );

  const { modal, setModal } = useModal(null);
  const {
    fetchStatus: { progress },
    filters: allFilters,
  } = useContext(CCPanelContext);

  const { flights: flightColumns } = columnVisibility || {};
  const filters = allFilters[locodeKey];
  const [filterMenuEl, setFilterMenuEl] = useState<null | HTMLElement>(null);
  const [columnsMenuEl, setColumnsMenuEl] = useState<null | HTMLElement>(null);
  const { localFilters, changeFilters } = useFiltersChange({
    locodeKey,
    componentType: 'actionBar',
  });

  const { allCompareable, allConfirmed } = actionStatus;
  const progressCompleted = progress?.[locodeKey] === 1;
  const filtersDisabled = !progressCompleted || allCompareable;
  const { minStopsCount, maxLayoverTime, maxStopsCount } = useMemo(
    () => getRangeValues(filters),
    [filters]
  );

  if (!localFilters) {
    return null;
  }

  const { layover, stopsCount, fareType, source, allowAirportTransfer } =
    localFilters;
  const showMarineOnly = fareType === FareType.marine;

  const handleOpenFilterMenu = (event: SyntheticEvent) => {
    setFilterMenuEl(event.target as HTMLElement);
  };

  const handleOpenColumnsMenu = (event: SyntheticEvent) => {
    setColumnsMenuEl(event.target as HTMLElement);
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    if (['copyLink', 'addUserNotes'].includes(modal?.type)) {
      return <ShareLinkModal modal={modal} setModal={setModal} />;
    }
  };

  return (
    <Wrapper>
      {flightColumns && (
        <Tooltip content="Update visibility of table columns">
          <ColumnsButton variant="primary" onClick={handleOpenColumnsMenu}>
            <ViewColumnIcon />
            Columns
          </ColumnsButton>
        </Tooltip>
      )}

      {/* button unavailable if there's no active flight */}
      {typeof allConfirmed === 'boolean' && (
        <ButtonWrapper
          data-id={`e2e_flights-${
            allConfirmed ? 'unconfirm' : 'confirm'
          }-button`}
          $disabled={!progressCompleted}
        >
          <Tooltip
            content={
              allConfirmed
                ? 'Click to deselct all flights'
                : 'Click to confirm all flights'
            }
          >
            <FilterButton
              variant="primary"
              $color={allConfirmed ? orange : fadedGreen}
              onClick={() => toggleConfirmAll(!allConfirmed)}
            >
              {!allConfirmed && <CheckCircleIcon />}
              {allConfirmed ? 'Unconfirm All' : 'Confirm All'}
            </FilterButton>
          </Tooltip>
        </ButtonWrapper>
      )}

      <FlexWrapper $disabled={filtersDisabled}>
        <ButtonWrapper data-id="e2e_flight-more-filters-button">
          <Tooltip content="Please select a flight to change before changing filters">
            <FilterButton variant="primary" onClick={handleOpenFilterMenu}>
              <FilterAltIcon />
              More Filters
            </FilterButton>
          </Tooltip>
        </ButtonWrapper>

        <FlexWrapper>
          <Label style={{ marginRight: '0.25rem' }}>Total Layover Time</Label>
          <SliderWrapper $width={160}>
            <Stack spacing={2} direction="row" alignItems="center">
              <AwesomeSlider
                data-id="e2e_flight-filters-layover"
                size="small"
                min={0}
                max={maxLayoverTime}
                step={0.5}
                value={layover}
                onChange={(_, newValue) =>
                  changeFilters({ layover: newValue as [number, number] })
                }
                components={{
                  Thumb: (props: any) => <ThumbHour range {...props} />,
                }}
              />
            </Stack>
          </SliderWrapper>
        </FlexWrapper>

        <FlexWrapper>
          <Label>Stops</Label>
          <SliderWrapper $width={80}>
            <Stack spacing={2} direction="row" alignItems="center">
              <AwesomeSlider
                data-id="e2e_flight-filters-stops"
                min={minStopsCount}
                max={maxStopsCount}
                disabled={maxStopsCount === minStopsCount}
                value={stopsCount}
                size="small"
                step={1}
                onChange={(_, value) =>
                  changeFilters({ stopsCount: value as number })
                }
                components={{ Thumb }}
              />
            </Stack>
          </SliderWrapper>
        </FlexWrapper>

        {!filters.duplicate && !!sources?.length && (
          <FlexWrapper>
            <Label>Source</Label>
            <Dropdown
              value={source}
              options={sources}
              formStyles={dropdownStyles}
              fontSize="0.8rem"
              onChange={({ target }) => changeFilters({ source: target.value })}
            />
          </FlexWrapper>
        )}

        <Tooltip content="Toggle marine flight status">
          <CheckboxWrapper $isSelected={showMarineOnly}>
            <Checkbox
              checkboxContainerStyle={{
                marginLeft: '0.25rem',
                display: 'flex',
                justifyContent: 'center',
              }}
              id="marine"
              label="Marine"
              checked={showMarineOnly}
              onChange={(value) =>
                changeFilters({
                  fareType: value ? FareType.marine : FareType.all,
                })
              }
            />
          </CheckboxWrapper>
        </Tooltip>

        <ToggleWrapper>
          <SwitchV2
            className="e2e_toggle-airport-transfer"
            checked={allowAirportTransfer}
            onChange={(_, newValue) =>
              changeFilters({ allowAirportTransfer: newValue })
            }
          />
          <Label>Transfer between Airports</Label>
        </ToggleWrapper>
      </FlexWrapper>

      {Boolean(filterMenuEl) && (
        <FiltersMenu
          locodeKey={locodeKey}
          anchorEl={filterMenuEl}
          setAnchorEl={setFilterMenuEl}
        />
      )}

      {Boolean(columnsMenuEl) && (
        <ColumnsMenu anchorEl={columnsMenuEl} setAnchorEl={setColumnsMenuEl} />
      )}

      {renderModal()}
    </Wrapper>
  );
};

export default memo(FlightFilters);
