import { clearLocalStorage } from '@greywing-maritime/frontend-library/dist/utils/auth';

import { clearMap } from 'map/map';
import { trackUserAction } from 'lib/amplitude';
import { resetStore, setUserAuth } from 'redux/actions';
import { populateVesselsAsync } from 'redux/thunks';
import { AppDispatch } from 'redux/types';
import { TRACK_USER_LOGOUT } from './analytics/constants';
import { refreshTokenForUser } from 'api/flotilla';

const checkUserAuthentication = async () => {
  console.log('Checking user authentication...');
  try {
    const authenticated = await refreshTokenForUser();
    console.log('Authentication checked', authenticated);
    return authenticated;
  } catch (error) {
    console.log('Authentication cannot be verified', error);
    return false;
  }
};

export const authenticateUser = async (dispatch: AppDispatch) => {
  const isAuthed = await checkUserAuthentication();
  // TODO: relocate vessel populate logic to `useVesselUpdate` hook, once handover is demmossioned
  if (isAuthed) {
    dispatch(populateVesselsAsync());
  }
  return isAuthed;
};

// util executing steps for logout
export const logoutUser = (
  dispatch: AppDispatch,
  // pass true for cases when redirect needs to be cleared
  // e.g - active logout triggered by user
  clearRedirect: boolean = false
) => {
  const loggedInTime = new Date().toISOString();
  const userEmail = localStorage.getItem('icEmail') || '';

  // Ideally, we should clear session related data from local storage upon logout. However,
  // there could be quite a few issues if we do it. These are related to how we are redirecting
  // user back to platform when auth failed above a certain number of times.
  // We need to simplify that workflow before we can clear local storage with confidence.

  trackUserAction(TRACK_USER_LOGOUT, 'click', {
    email: userEmail,
    time: loggedInTime,
  });

  // clear values
  clearMap();
  clearLocalStorage();
  sessionStorage.clear();

  // clear magic login disable amplitude
  localStorage.removeItem('disable-amplitude');

  // dispatch actions
  dispatch(resetStore({ clearRedirect }));
  dispatch(setUserAuth(false));
};
