import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

import { RootState } from '../types';

// Set the initial auth based on whether a token exists or has expired
// This prevents the initial load from flickering between loading and loaded
// when a previous session exists
export const initialAuth = getAuthToken().success;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthed: initialAuth,
    isAdmin: localStorage.getItem('disable-amplitude') === 'true',
  },
  reducers: {
    setUserAuth: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAuthed: action.payload,
    }),
    setIsAdmin: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAdmin: action.payload,
    }),
  },
});

/* ----- selectors -----*/

export const selectUserAuth = ({ authed }: RootState) => authed;

/* ----- actions -----*/

export const { setUserAuth, setIsAdmin } = authSlice.actions;

/* ----- reducer -----*/

export default authSlice.reducer;
