import { memo } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';
import type { PortInfo } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import type { ListPortNotesResp as PortNotesResponse } from '@greywing-maritime/frontend-library/dist/types/portNotes';

import { SetModal } from 'hooks/useModal';
import {
  blue,
  gray10,
  gray50,
  gray60,
  lightPurple,
  textBlack,
  textGray,
} from 'lib/colors';
import { selectSettings } from 'redux/selectors';
import { formatDate } from 'utils/format-date';
import { Port } from 'utils/types/crew-change-types';

import { Loader, Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';
import {
  PortNotesLoadingState,
  ReadOnlyPort,
} from 'components/CrewChangePanel/types';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: center;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
`;

const Description = styled.div`
  font-size: 1rem;
  color: ${textGray};
`;

const NotesWrapper = styled.div`
  border: 1px solid ${gray50};
  border-radius: 6px;
  background: ${gray10};
  margin-top: 0.75rem;
`;

const Notes = styled.div`
  padding: 0.75rem;
  border-bottom: 1px solid ${gray50};
  &:last-child {
    border: none;
  }
`;

const NotesMeta = styled.i`
  color: ${gray60};
  font-size: 0.8rem;
`;

const NotesContentWrapper = styled(FlexWrapper)`
  align-items: flex-start;
  column-gap: 0.5rem;
  margin-top: 0.25rem;
`;

const StyledDescriptionIcon = styled(DescriptionOutlinedIcon)`
  font-size: 1rem !important;
  margin-top: 1px;
`;

const NotesText = styled.div`
  font-size: 0.95rem;
  font-weight: 500;
  color: ${textBlack};
`;

const CloseButton = styled(StyledButton)`
  background: ${lightPurple};
  color: ${blue};
  border: none;
`;

const ActionButton = styled(StyledButton)`
  width: fit-content;
  padding: auto 1.5rem;
  color: ${blue};
  margin-top: 1rem;
`;

type Props = {
  port: Port | ReadOnlyPort | PortInfo;
  notesResponse: PortNotesResponse;
  loadingState: PortNotesLoadingState;
  loadMore: () => void;
  closeModal: () => void;
  setModal: SetModal;
};

function NotesResultModal({
  port,
  notesResponse,
  loadingState,
  loadMore,
  setModal,
  closeModal,
}: Props) {
  const { userInfo } = useSelector(selectSettings);

  const { firstname: userFirstName = '' } = userInfo || {};
  const { notes = [], totalCount, nextPageToken } = notesResponse;

  const renderModalContent = () => {
    if (loadingState.initial) {
      return (
        <ContentWrapper>
          <Loader size={150} />
        </ContentWrapper>
      );
    }

    return (
      <>
        <Description>
          {totalCount} note{totalCount > 1 ? 's' : ''} available for port{' '}
          <b>
            {port.name} ({port.locode})
          </b>
        </Description>

        <NotesWrapper>
          {notes.map(({ id, text, createdAt, userDisplayName }, index) => (
            <Notes key={id} data-id={`e2e_saved-port-notes-${index + 1}`}>
              <NotesMeta>
                Created at {formatDate(createdAt, 'hh:mm A on DD MMM, YYYY')} by{' '}
                <b>
                  {userDisplayName.trim() === userFirstName
                    ? 'You'
                    : userDisplayName}
                </b>
              </NotesMeta>

              <NotesContentWrapper>
                <StyledDescriptionIcon />
                <NotesText data-id="e2e_port-notes-text">{text}</NotesText>
              </NotesContentWrapper>
            </Notes>
          ))}
        </NotesWrapper>

        {/* fetch more if pageToken is available */}
        {Boolean(nextPageToken) && (
          <ContentWrapper>
            {loadingState.loadMore ? (
              <CircularProgress size={20} />
            ) : (
              <ActionButton variant="secondary" onClick={loadMore}>
                Load More
              </ActionButton>
            )}
          </ContentWrapper>
        )}
      </>
    );
  };

  const actions = (
    <ButtonsWrapper>
      <CloseButton
        // className="e2e_submit-new-route-port"
        variant="secondary"
        onClick={closeModal}
      >
        Close
      </CloseButton>
      <StyledButton
        variant="primary"
        // open modal to add new notes
        onClick={() => setModal('portNotes')}
      >
        Create New
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal
      width={600}
      title={`Port Notes - ${port.locode}`}
      actions={actions}
      closeModal={closeModal}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default memo(NotesResultModal);
