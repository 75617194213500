import CircularProgress from '@mui/material/CircularProgress';

import styles from '../../styles/components/shared/Button.module.scss';

interface IButtonProps {
  className?: string;
  children?: React.ReactNode;
  variant?: string;
  buttonText?: string;
  size?: 'small' | undefined;
  onClick: any;
  style?: any;
  onMouseEnter?: any;
  onMouseOut?: any;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const BUTTON_VARIANT: {
  [key: string]: string;
} = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DELETE: 'delete',
  TRASH: 'trash',
  ICON: 'icon',
};

export default function Button({
  children,
  className,
  variant = BUTTON_VARIANT.PRIMARY,
  buttonText = '',
  onClick,
  style,
  size,
  onMouseEnter,
  onMouseOut,
  loading = false,
  disabled,
  type,
}: IButtonProps) {
  return (
    <button
      className={`${styles[variant]} ${size ? styles.sm : ''} ${
        className || ''
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseOut}
      style={style}
      disabled={!!disabled}
      type={type}
    >
      {loading && (
        <div className={styles.spinnerWrapper}>
          <CircularProgress size={12} />
        </div>
      )}
      {children || buttonText}
    </button>
  );
}
