import { useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import {
  AddAgentReq,
  AgentEmail,
} from '@greywing-maritime/frontend-library/dist/types/emailReqResp';
import styled from 'styled-components/macro';

import { gray20, gray80, textBlack } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { addAgent } from 'api/flotilla';

import { Modal } from 'components/shared';
import { StyledButton } from '../common';
import { isValidEmail } from 'lib/common';

const Wrapper = styled.div`
  padding: auto 0.5rem;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${textBlack} !important;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-gap: 10px;
  margin-bottom: 1rem;
  width: 100%;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const Instruction = styled.div`
  font-size: 0.9rem;
  color: ${gray80};
  margin-bottom: 1.5rem;
`;

const FieldText = styled(Instruction)`
  color: ${textBlack};
  margin-bottom: 0;
`;

const dropDownStyles = {
  maxHeight: '160px',
  fontSize: '0.8rem',
  lineHeight: '0.9rem',
  fontFamily: 'HK Grotesk, Roboto',
  background: `${gray20}`,
};

const regions = [
  'Asia',
  'Europe',
  'Americas',
  'Middle East',
  'Africa',
  'Oceania',
  'North America',
  'South America',
  'North Africa',
  'South Africa',
  'West Africa',
  'East Africa',
  'Worldwide',
];

type Props = {
  email: string;
  agency: string;
  ports: string[];
  addAgents: (agents: AgentEmail[]) => void;
  closeModal: () => void;
};

function AddAgentModal({
  email,
  agency: portAgent,
  ports,
  addAgents,
  closeModal,
}: Props) {
  const [addingAgent, setAddingAgent] = useState(false);
  const [request, setRequest] = useState<AddAgentReq>({
    email,
    ports,
    region: '',
    agency: portAgent,
  });

  const validRequest = useMemo(() => {
    const { email, ports, region, agency } = request;
    return (
      isValidEmail(email) &&
      Boolean(ports.length && region.trim().length && agency.trim().length)
    );
  }, [request]);

  const handleUpdate = (type: string, value: string | null | undefined) => {
    setRequest((prevReq) => ({ ...prevReq, [type]: value || '' }));
  };

  const handleAddAgent = () => {
    if (validRequest) {
      setAddingAgent(true);
      addAgent(request).then((response) => {
        const { success, message, addAgentResp } = response;
        const result = addAgentResp?.[ports[0]];

        if (success && (result as AgentEmail)?.id) {
          addAgents([result as AgentEmail]);
          closeModal();
          return;
        }

        showToaster({
          message: message || 'Failed to add agent',
          type: 'error',
        });
        setAddingAgent(false);
      });
    }
  };

  const actions = (
    <StyledButton
      variant="primary"
      onClick={handleAddAgent}
      disabled={!validRequest || addingAgent}
    >
      {addingAgent ? 'Adding...' : 'Add Agent'}
    </StyledButton>
  );

  return (
    <Modal
      width={500}
      title="Agent Details"
      closeModal={closeModal}
      actions={actions}
    >
      <Wrapper>
        <Instruction>
          No agent with this email is available. Update details and add the
          agent.
        </Instruction>

        <InputWrapper>
          <Title>
            Port<span>:</span>
          </Title>
          <FieldText>{request.ports[0]}</FieldText>
        </InputWrapper>

        <InputWrapper>
          <Title>
            Email<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={request.email}
            onChange={({ target: { value } }) => handleUpdate('email', value)}
            placeholder="Add agent email"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Agency<span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={(request.agency || '').toUpperCase()}
            onChange={({ target: { value } }) => handleUpdate('agency', value)}
            placeholder="Add agency"
          />
        </InputWrapper>

        <InputWrapper>
          <Title>
            Region<span>:</span>
          </Title>
          <Autocomplete
            disablePortal
            freeSolo
            size="small"
            options={regions}
            value={request.region || null}
            onChange={(_, value) => handleUpdate('region', value)}
            renderInput={(params) => (
              <TextField {...params} placeholder="Add Region" />
            )}
            ListboxProps={{ style: dropDownStyles }}
            sx={{ width: '100%' }}
          />
        </InputWrapper>
      </Wrapper>
    </Modal>
  );
}

export default AddAgentModal;
