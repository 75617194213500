import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch, useMobile, useWindowSize } from 'hooks';
import { blue, midPurple } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { toggleModalView, updateFlightSearch } from 'redux/actions';
import { selectFlotillaSearch } from 'redux/selectors';
import { FlightNumberDetails } from 'redux/types';
import { TRACK_SEARCH_CLOSE } from 'utils/analytics/constants';
import { StyledCard } from 'components/FlotillaSearch/common';
import { getFlightResults } from 'components/FlotillaSearch/helpers';

import { FlightNumberSearch, SearchFilters } from './SearchOptions';
import {
  FlightResultCard,
  FlightResultCardV2,
  SearchResultText,
} from './Components';
import { CardHeader, CardInfo, CardTitle, TitleWrapper } from './common';
import Button from '../Button';

import { getUserActionModalType, TrackUtils } from './helpers';
import {
  AllResultsListProps,
  ExpandSection,
  UpdateSearchOptions,
} from './types';

import ExpandButton from '../ExpandButton';
import FlightControls from '../FlightControls';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';

const Wrapper = styled.div`
  width: 100%;
`;

const Container = styled(Wrapper)`
  flex-shrink: 0;
`;

const List = styled.div`
  margin: 0;
  padding: 0;
`;

const StyledButton = styled(Button)`
  width: calc(100% - 1rem);
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin: 0.5rem;
  background: ${midPurple};
  color: ${blue};
  text-transform: uppercase;
`;

const StyledIcon = styled(ExitToAppRoundedIcon)`
  margin-left: 0.75rem;
  font-size: 18px !important;
`;

// This parent component renders flights results in 3 places
// QuickFly searchbar view, QuickFly modal view, and CC panel modal view
function AllFlightResults({
  from,
  to,
  recent,
  flights,
  filters,
  modalView,
  updateFilter,
  selectProps,
  openRawData,
}: AllResultsListProps) {
  const dispatch = useAppDispatch();
  const {
    flightSearch: { flightNumberDetails },
  } = useSelector(selectFlotillaSearch);

  const isMobile = useMobile();
  const [width] = useWindowSize();
  const [expanded, setExpanded] = useState({
    filters: !isMobile,
    matched: true,
    unmatched: true,
  });
  // state for additional search options along with filters
  const [searchOptions, setSearchOptions] = useState<FlightNumberDetails>(
    flightNumberDetails || {
      flightNumber: '',
      included: false,
    }
  );

  const modalType = useMemo(
    () => getUserActionModalType(modalView),
    [modalView]
  );
  const trackUtils = useMemo(() => TrackUtils(modalType), [modalType]);
  const { matched, unmatched } = useMemo(
    () => getFlightResults(flights, filters, searchOptions),
    [flights, filters, searchOptions]
  );

  const showResultV2 = useMemo(() => {
    return (
      width >= 1080 &&
      (modalType === 'expanded-modal' || modalView === 'ccpanel')
    );
  }, [width, modalType, modalView]);

  const handleExpanded = (type: ExpandSection) => {
    setExpanded((expanded) => ({ ...expanded, [type]: !expanded[type] }));
  };

  const handleDetachResults = useCallback(() => {
    trackUtils.openModal();
    dispatch(toggleModalView(true));
    trackUserAction(TRACK_SEARCH_CLOSE);
  }, [trackUtils, dispatch]);

  const updateSearchOptions: UpdateSearchOptions = useCallback(
    (item) => {
      const flightNumberDetails = { ...searchOptions, ...item };
      if (modalView === 'ccpanel') setSearchOptions(flightNumberDetails);
      else dispatch(updateFlightSearch({ flightNumberDetails }));
    },
    [modalView, searchOptions, dispatch]
  );

  useEffect(() => {
    if (modalView !== 'ccpanel' && flightNumberDetails) {
      setTimeout(() => {
        setSearchOptions(flightNumberDetails);
      }, 100);
    }
  }, [modalView, flightNumberDetails]);

  const matchedListTestId = `e2e_matched-flights-list-${
    modalView ? `${modalView}-modal` : 'searchbar'
  }`;
  const unmatchedListTestId = `e2e_unmatched-flights-list-${
    modalView ? `${modalView}-modal` : 'searchbar'
  }`;

  return (
    <Container>
      {modalView === 'quickfly' && <FlightControls recent={recent} isModal />}
      {Boolean(flights?.length) && (
        <StyledCard>
          <CardHeader $expanded={expanded.filters}>
            <TitleWrapper
              $filters
              onClick={() => {
                trackUtils.toggleSearchFilters(expanded.filters);
                handleExpanded('filters');
              }}
            >
              <CardTitle data-id="e2e_filter-title">Search Options</CardTitle>
              <ExpandButton expanded={expanded.filters} />
            </TitleWrapper>
          </CardHeader>

          {expanded.filters && (
            <>
              <FlightNumberSearch
                flights={flights}
                searchOptions={searchOptions}
                updateOptions={updateSearchOptions}
              />
              <SearchFilters
                modalView={modalView}
                flights={flights}
                filters={filters}
                searchOptions={searchOptions}
                updateFilter={updateFilter}
              />
            </>
          )}
        </StyledCard>
      )}

      {/* Show Expand button to detatch flight results
        available only for QuickFly search results view */}
      {!modalView && (
        <Wrapper data-id="e2e_quickfly-results-expand-button">
          <StyledButton onClick={handleDetachResults}>
            Expand Results
            <StyledIcon />
          </StyledButton>
        </Wrapper>
      )}

      <StyledCard>
        <CardHeader $expanded={expanded.matched}>
          <TitleWrapper
            onClick={() => {
              trackUtils.toggleMatchedResults(expanded.matched);
              handleExpanded('matched');
            }}
          >
            <CardInfo>
              <SearchResultText
                matched
                from={from}
                to={to}
                matchedFlights={matched}
                unmatchedFlights={unmatched}
                searchOptions={searchOptions}
              />
            </CardInfo>
            {Boolean(matched.length) && (
              <ExpandButton expanded={expanded.matched} />
            )}
          </TitleWrapper>
        </CardHeader>

        {expanded.matched && (
          <List data-id={matchedListTestId}>
            {matched.map((flight, index) => {
              const isSelected =
                selectProps?.selected &&
                selectProps.selected.originalId === flight.originalId;
              return showResultV2 ? (
                <FlightResultCardV2
                  key={flight.id}
                  flight={flight}
                  last={index + 1 === matched.length}
                  isSelected={isSelected}
                  selectFlight={selectProps?.selectFlight}
                  modalView={modalView}
                  openRawData={openRawData}
                />
              ) : (
                <FlightResultCard
                  key={flight.id}
                  flight={flight}
                  last={index + 1 === matched.length}
                  isSelected={isSelected}
                  selectFlight={selectProps?.selectFlight}
                  modalView={modalView}
                  openRawData={openRawData}
                />
              );
            })}
          </List>
        )}
      </StyledCard>

      {Boolean(unmatched.length) && (
        <StyledCard>
          <CardHeader $expanded={expanded.unmatched}>
            <TitleWrapper
              onClick={() => {
                trackUtils.toggleUnmatchedResults(expanded.unmatched);
                handleExpanded('unmatched');
              }}
            >
              <CardInfo>
                <SearchResultText
                  from={from}
                  to={to}
                  matchedFlights={matched}
                  unmatchedFlights={unmatched}
                  searchOptions={searchOptions}
                />
              </CardInfo>
              {Boolean(unmatched.length) && (
                <ExpandButton expanded={expanded.unmatched} />
              )}
            </TitleWrapper>
          </CardHeader>

          {expanded.unmatched && (
            <List data-id={unmatchedListTestId}>
              {unmatched.map((flight, index) => {
                const isSelected =
                  selectProps?.selected &&
                  selectProps.selected.originalId === flight.originalId;
                return showResultV2 ? (
                  <FlightResultCardV2
                    key={flight.id}
                    flight={flight}
                    last={index + 1 === unmatched.length}
                    modalView={modalView}
                    isSelected={isSelected}
                    openRawData={openRawData}
                    unmatched
                  />
                ) : (
                  <FlightResultCard
                    key={flight.id}
                    flight={flight}
                    last={index + 1 === unmatched.length}
                    modalView={modalView}
                    isSelected={isSelected}
                    openRawData={openRawData}
                    unmatched
                  />
                );
              })}
            </List>
          )}
        </StyledCard>
      )}
    </Container>
  );
}

export default memo(AllFlightResults);
