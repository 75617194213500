import { memo, useMemo } from 'react';
import { useGridApiContext, GridRenderEditCellParams } from '@mui/x-data-grid';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

type EditDateCellProps = GridRenderEditCellParams<Date | string | null> & {
  disablePast?: boolean;
};

const GridEditDateInput = styled(InputBase)({
  fontSize: 'inherit',
  padding: '0.25rem',
});

// util for format different date types for consistency
export const formatCellDate = (date: Date | string | null) => {
  if (typeof date !== 'string') {
    return date;
  }
  const dateParts = date.split('/').map(Number);
  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
};

function GridEditDateCell({
  id,
  field,
  value,
  disablePast,
}: EditDateCellProps) {
  const apiRef = useGridApiContext();

  const handleChange = (newValue: Date | string | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  const dateOptions = useMemo(
    () => ({
      ...(!disablePast ? { maxDate: formatCellDate(new Date())! } : {}),
      minDate: formatCellDate(!disablePast ? '01/01/1941' : new Date())!,
    }),
    [disablePast]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...dateOptions}
        disableMaskedInput
        disablePast={Boolean(disablePast)}
        disableFuture={!disablePast}
        value={value ? formatCellDate(value) : null}
        inputFormat="dd/MM/yyyy"
        renderInput={({
          inputRef,
          inputProps,
          InputProps,
          disabled,
          error,
        }: any) => (
          <GridEditDateInput
            autoFocus
            ref={inputRef}
            {...InputProps}
            disabled={disabled}
            error={error}
            inputProps={inputProps}
            sx={{
              svg: { height: '1.25rem', width: '1.25rem' },
              button: { padding: '0', margin: '0' },
            }}
          />
        )}
        onChange={handleChange}
      />
    </LocalizationProvider>
  );
}

export default memo(GridEditDateCell);
