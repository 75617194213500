import styled from 'styled-components/macro';

import {
  bluePurple,
  darkBlue,
  midBlue,
  textBlack,
  textGray,
  yellowOrange,
} from 'lib/colors';

export const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 150ms ease;
`;

export const FlightText = styled.i`
  color: ${textGray};
  margin-right: 0.5rem;
`;

export const Layover = styled.span`
  color: ${yellowOrange};
`;

export const AirportsWrapper = styled.div`
  padding: 0.25rem;
`;

export const AirportName = styled.div`
  margin-bottom: 1px;
  font-weight: bold;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ResultsButtonWrapper = styled.div<{ $confirmed?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 130px;
  height: 100%;
  border-radius: 0 4px 4px 0;
  background: ${({ $confirmed }) =>
    $confirmed
      ? `
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 1) 60%,
        rgba(255, 255, 255, 0) 100%
      );
  `
      : `
      linear-gradient(
        270deg,
        rgba(242, 241, 249, 1) 10%,
        rgba(255, 255, 255, 0) 100%
      );
  `};
`;

export const ResultsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${textBlack};
  background: ${midBlue};
  border: 1.25px solid ${darkBlue};
  width: fit-content;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    background: ${bluePurple};
  }
`;

export const EmptyFooter = styled.div`
  height: 1.75rem;
`;
