import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';

import CardHeader from './common/CardHeader';
import { renderOption, renderTags } from './VesselTags/common';
import { VesselTagCreateUpdate } from './VesselTags/types';

import { selectMapVessels, selectSidePanel } from 'redux/selectors';

import { SidePanelFieldContext } from 'contexts/SidepanelContext';
import { useAppDispatch } from 'hooks';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_ADD_VESSEL_TAG_MULTI,
  TRACK_REMOVE_VESSEL_TAG_MULTI,
} from 'utils/analytics/constants';
import {
  addVesselTag,
  filterOptions,
  getNewHashColour,
  removeVesselTag,
  transformRawTags,
} from './VesselTags/helpers';
import { Tooltip } from '../shared';
import InfoIcon from '@mui/icons-material/Info';
import styled from 'styled-components/macro';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 20px !important;
    margin-left: 0.5rem;
  }
`;

function MultipleVesselTags() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const { vesselIds: activeVesselIds } = useSelector(selectSidePanel);
  const { searchGlobal, filteredCommonTags } = useContext(
    SidePanelFieldContext
  );
  const { globalTags } = useSelector(selectMapVessels);

  const [tags, setTags] = useState<VesselTagCreateUpdate[]>(
    // @ts-ignore
    transformRawTags(filteredCommonTags || [])
  );
  const [searchTagResult, setSearchTagResult] = useState<
    VesselTagCreateUpdate[]
  >([]);

  // Update initial search results when global tags change
  useEffect(() => {
    setSearchTagResult(transformRawTags(globalTags));
  }, [globalTags]);

  // Update with relevant tag when common tags change
  useEffect(() => {
    if (
      tags.map((o) => o.id).join(',') ===
      filteredCommonTags.map((o) => o.id).join(',')
    )
      return;
    setTags(transformRawTags(filteredCommonTags));
  }, [filteredCommonTags]); // eslint-disable-line

  const handleInputChange = (
    e: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const searched = searchGlobal(value || '');
    setSearchTagResult(transformRawTags(searched));
  };

  const handleChange = useCallback(
    async (
      e: React.SyntheticEvent,
      v: (VesselTagCreateUpdate | string)[],
      reason: AutocompleteChangeReason
    ) => {
      setLoading(true);
      if (reason === 'createOption') {
        if (typeof v[v.length - 1] === 'string') {
          const newDisplayName = String(v[v.length - 1]).trim();
          if (
            !newDisplayName ||
            tags.filter((o) => o.displayName === newDisplayName).length
          ) {
            setLoading(false);
            return Promise.resolve();
          }
          const newColour = getNewHashColour(newDisplayName);

          const addedTag = await addVesselTag({
            dispatch,
            requestBody: {
              flotVesIds: activeVesselIds,
              displayName: newDisplayName,
              colour: newColour,
            },
          });

          if (addedTag) {
            trackUserAction(TRACK_ADD_VESSEL_TAG_MULTI, 'click', {
              tag: newDisplayName,
              vesselIds: activeVesselIds,
            });
            showToaster({
              message: `Sucessfully tagged ${activeVesselIds.length} vessels.`,
              type: 'success',
            });
            setTags([
              ...tags,
              {
                label: newDisplayName,
                id: addedTag.id,
                value: addedTag.id,
                isNew: false,
                displayName: newDisplayName,
                colour: newColour,
                count: 1,
              },
            ]);
          }
        }
      }
      if (reason === 'selectOption') {
        // @ts-ignore
        if (typeof v[v.length - 1] !== 'string') {
          const selectedTag = v[v.length - 1] as VesselTagCreateUpdate;

          const addedTag = await addVesselTag({
            dispatch,
            requestBody: {
              flotVesIds: activeVesselIds,
              displayName: selectedTag.displayName,
              colour: selectedTag.colour,
            },
          });

          if (addedTag) {
            trackUserAction(TRACK_ADD_VESSEL_TAG_MULTI, 'click', {
              tag: selectedTag.displayName,
              vesselIds: activeVesselIds,
            });
            showToaster({
              message: `Sucessfully tagged ${activeVesselIds.length} vessels.`,
              type: 'success',
            });
            setTags([
              ...tags,
              {
                ...selectedTag,
                label: selectedTag.displayName,
                id: addedTag.id,
                value: addedTag.id,
              },
            ]);
          }
        }
      }
      if (reason === 'removeOption') {
        // Identify missing tag
        const ids = (v as VesselTagCreateUpdate[]).map((o) => o.id);
        const missing = tags.filter((o) => !ids.includes(o.id));

        // Remove
        const removedTag = missing[0];
        if (!removedTag) return;

        const isRemoved = await removeVesselTag({
          dispatch,
          requestBody: {
            flotVesIds: activeVesselIds,
            tag: {
              id: removedTag.id,
              colour: removedTag.colour,
              displayName: removedTag.displayName,
            },
          },
        });
        if (isRemoved) {
          trackUserAction(TRACK_REMOVE_VESSEL_TAG_MULTI, 'click', {
            tag: removedTag.displayName,
            vesselIds: activeVesselIds,
          });
          setTags(v as VesselTagCreateUpdate[]);
        }
      }
      setLoading(false);
      return Promise.resolve();
    },
    [tags, activeVesselIds] // eslint-disable-line
  );
  return (
    <>
      <CardHeader>
        <h4>
          Common Vessel Tags
          <Tooltip
            content={`The tags that are common to all of the ${activeVesselIds.length} vessels selected from the list below`}
          >
            <IconWrapper>
              <InfoIcon />
            </IconWrapper>
          </Tooltip>
        </h4>
      </CardHeader>
      <div>
        <Autocomplete
          multiple
          noOptionsText="search or add new tags"
          renderTags={renderTags}
          value={tags}
          getOptionLabel={(option) => (option as VesselTagCreateUpdate).label!}
          freeSolo
          options={searchTagResult}
          renderOption={renderOption}
          onInputChange={handleInputChange}
          disableClearable
          openOnFocus
          onChange={handleChange}
          loading={loading}
          disabled={loading}
          isOptionEqualToValue={(option, selected) =>
            option.value === selected.value
          }
          // Override default
          filterOptions={filterOptions}
          filterSelectedOptions
          size="small"
          renderInput={(params) => (
            <TextField
              label="Add or remove tags"
              autoComplete="off"
              {...params}
            />
          )}
        />
      </div>
    </>
  );
}

export default MultipleVesselTags;
