/* eslint-disable */
// from https://github.com/lukeed/dset/blob/master/src/index.js
export function dset(obj: any, keys: any, val: any) {
  keys.split && (keys = keys.split('.'));
  var i = 0,
    l = keys.length,
    t = obj,
    x,
    k;
  while (i < l) {
    k = keys[i++];
    if (k === '__proto__' || k === 'constructor' || k === 'prototype') break;
    t = t[k] =
      i === l
        ? val
        : typeof (x = t[k]) === typeof keys
        ? x
        : keys[i] * 0 !== 0 || !!~('' + keys[i]).indexOf('.')
        ? {}
        : [];
  }
}
