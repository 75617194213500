import filter from 'lodash/filter';
import { memo } from 'react';
import { FareInformation } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import styled from 'styled-components/macro';

import {
  blue,
  gray60,
  gray50,
  white,
  textBlack,
  purple,
  iconGray,
} from 'lib/colors';
import Tooltip from 'components/shared/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';
import ChatIcon from '@mui/icons-material/Chat';

const Wrapper = styled.div`
  color: ${textBlack};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoText = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: ${gray60};
  letter-spacing: 0.5px;
  text-decoration: underline;
  text-transform: uppercase;
  text-align: center;
  text-overflow: ellipsis;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    color: ${blue};
  }
`;

const HeaderText = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0.5rem;
`;

const DetailsWrapper = styled.div`
  border: 1px solid ${gray50};
  border-radius: 0.25rem;
  margin: 0.5rem;
`;

const ContentWrapper = styled.div<{ $multiple: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  row-gap: 0.25rem;
  padding: 0.5rem;
  border-radius: 6px;
  border-bottom: 1px solid ${gray50};
  ${({ $multiple }) =>
    $multiple
      ? `
    border-radius: 6px 6px 0 0;
  `
      : `
    border-bottom: none;
  `};
  &:last-child {
    ${({ $multiple }) =>
      $multiple &&
      `
      border-bottom: none;
      border-radius: 0 0 6px 6px;
    `};
  }
`;

const InfoGrid = styled(FlexWrapper)`
  margin-left: 1.25rem;
  font-size: 0.8rem;
  line-height: 1.25;
`;

const MessageIcon = styled(ChatIcon)`
  font-size: 1rem !important;
  color: ${iconGray};
  cursor: pointer;
  transition: color 200ms ease-in-out;

  &:hover {
    color: ${blue};
  }
`;

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 12px !important;
  margin-right: 0.75rem;
  color: ${purple};
`;

type Props = {
  arrival: string;
  departure: string;
  fareInformation?: FareInformation[];
  tableView?: boolean;
};

const renderCost = (
  costs?: {
    amount?: number;
    currency?: string;
  }[]
) => {
  if (!costs?.length) return null;

  const filteredCosts = filter(costs, 'amount');
  return (
    <InfoGrid>
      <b style={{ marginRight: '0.5rem' }}>Cost:</b>
      <div>
        {filteredCosts.length > 1 ? (
          filteredCosts.map(({ amount, currency }, index) => (
            <div key={index}>
              {index + 1}. {amount} {currency || 'USD'}
            </div>
          ))
        ) : (
          <>
            {filteredCosts[0].amount} {filteredCosts[0].currency || 'USD'}
          </>
        )}
      </div>
    </InfoGrid>
  );
};

function FareInfo({ arrival, departure, fareInformation, tableView }: Props) {
  if (!fareInformation) {
    return tableView ? <span>-</span> : null;
  }

  const renderTooltipContent = () => (
    <Wrapper>
      <HeaderText data-id="e2e_fare-info-tooltip-header">
        Fare Information ({departure} to {arrival})
      </HeaderText>
      <DetailsWrapper>
        {fareInformation.map((info, index) => {
          const { description, monetaryValue } = info;
          return (
            <ContentWrapper key={index} $multiple={fareInformation.length > 1}>
              <FlexWrapper>
                <StyledCircleIcon />
                <span>{description}</span>
              </FlexWrapper>

              {renderCost(monetaryValue)}
            </ContentWrapper>
          );
        })}
      </DetailsWrapper>
    </Wrapper>
  );

  return (
    <Tooltip content={renderTooltipContent()} background={white} arrow={false}>
      {tableView ? (
        <MessageIcon />
      ) : (
        <InfoText data-id="e2e_fare-info">Fare Info</InfoText>
      )}
    </Tooltip>
  );
}

export default memo(FareInfo);
