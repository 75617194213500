import { memo, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SliderValueLabel } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';
import styled from 'styled-components/macro';

import { useAppDispatch, useDebounce } from 'hooks';
import { NM_TO_KM } from 'lib/constants';
import { gray40, textBlack } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { setPortsRange } from 'redux/actions';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { AwesomeSlider } from 'components/shared';
import { Thumb } from 'components/shared/AwesomeSlider';

const Wrapper = styled.div`
  width: 180px;
  margin-left: 1.2rem;
  margin-top: 3px;
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 150px;
    margin-left: -2rem;
    margin-right: 1.2rem;
  }
`;

export const StyledValueLabel = MuiStyled(SliderValueLabel)(() => ({
  backgroundColor: gray40,
  fontFamily: 'HK Grotesk',
  fontSize: '0.5rem',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  padding: '0.25rem',
  color: textBlack,
  '&::before': {
    bottom: 'unset',
    top: '-8px',
  },
  '&.MuiSlider-valueLabelOpen': {
    top: 'unset',
    bottom: '-45px',
  },
}));

type EventType = Event | SyntheticEvent;

type Props = {
  portCount: number | null;
  disabled: boolean;
};

const ThumbComponent = (props: any) => {
  const { ...rest } = props;
  const modifiedValue = `${rest.ownerState.value} NM`;
  return <Thumb {...rest} modifiedValue={modifiedValue} />;
};

const ValueLabelComponent = (props: any) => {
  const { value } = props;
  const modifiedValue = `${value} ports within`;
  return <StyledValueLabel {...props} value={modifiedValue} />;
};

function PortSlider({ portCount, disabled }: Props) {
  const dispatch = useAppDispatch();
  const portsRequest = useSelector(
    ({ crewChangeResources }: RootState) =>
      crewChangeResources.requests.proxPorts
  );
  const {
    portParams: { range: rangeNM },
    updatePortParams,
  } = useContext(CCPanelContext);

  const initialRange = portsRequest ? portsRequest.rangeKM / NM_TO_KM : rangeNM;
  const [value, setValue] = useState<number>(initialRange);

  const debouncedUpdate = useDebounce((newValue: number) => {
    dispatch(setPortsRange(newValue * NM_TO_KM));
    updatePortParams((prev) => ({ ...prev, range: newValue }));
  });

  useEffect(() => {
    setValue(rangeNM);
    debouncedUpdate(rangeNM);
  }, [rangeNM]);  // eslint-disable-line

  const handleUpdateRange = (e: EventType, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  const handleFetchOnUpdate = (e: EventType, newValue: number | number[]) => {
    // fetch ports for mouse-up event
    debouncedUpdate(newValue as number);
  };

  return (
    <Wrapper>
      <AwesomeSlider
        min={0}
        max={500}
        step={5}
        value={value}
        disabled={disabled}
        onChange={handleUpdateRange}
        onChangeCommitted={handleFetchOnUpdate}
        valueLabelFormat={() => portCount}
        valueLabelDisplay={portCount !== null ? 'on' : 'off'}
        components={{
          Thumb: ThumbComponent,
          ValueLabel: ValueLabelComponent,
        }}
      />
    </Wrapper>
  );
}

export default memo(PortSlider);
