import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import styled from 'styled-components/macro';

import { gray40 } from 'lib/colors';

const FlexWrapper = styled.div`
  width: 96%;
  display: flex;
  align-items: baseline;
  border: 1px solid ${gray40};
  border-radius: 5px;
`;

const Wrapper = styled.div<{ $width: number; $showBorder: boolean }>`
  width: ${({ $width }) => `${$width}%`};
  border-right: ${({ $showBorder }) =>
    $showBorder ? `1px solid ${gray40}` : 'none'};
`;

const ItemWrapper = styled.div<{ $hideBorder?: boolean }>`
  padding: 0.5rem 0.8rem;
  ${({ $hideBorder }) =>
    !$hideBorder &&
    `
    border-bottom: 1px solid ${gray40};
  `};
`;

function TableSkeleton({
  rows,
  columns = 3,
}: {
  rows: number;
  columns?: number;
}) {
  const columnWidth = 100 / columns;

  const renderTableColumn = (showBorder: boolean) => {
    return (
      <Wrapper $width={columnWidth} $showBorder={showBorder}>
        <ItemWrapper>
          <Typography component="div" variant="h4">
            <Skeleton animation="wave" />
          </Typography>
        </ItemWrapper>

        {Array(rows)
          .fill('')
          .map((_, index) => (
            <ItemWrapper key={index} $hideBorder={index + 1 === rows}>
              <Skeleton animation="wave" />
            </ItemWrapper>
          ))}
      </Wrapper>
    );
  };

  return (
    <FlexWrapper>
      {Array(columns)
        .fill('')
        .map((_, index) => (
          <React.Fragment key={index}>
            {renderTableColumn(index + 1 !== columns)}
          </React.Fragment>
        ))}
    </FlexWrapper>
  );
}

export default TableSkeleton;
