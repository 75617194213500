import { gray40 } from 'lib/colors';
import UserWithArrowSVG from './UserWithArrowSVG';
import styled from 'styled-components/macro';

type IconProps = {
  color?: string;
  selected: boolean;
  isOffsigner: boolean;
  size?: string | number;
};

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

// This is a slightly different icon which doesn't center the user icon for both on and offsigners
function OnOffSigner({ color, selected, isOffsigner, size }: IconProps) {
  return (
    <Wrapper>
      <UserWithArrowSVG
        color={selected ? color : gray40}
        height={size}
        width={size}
        style={{
          transform: isOffsigner ? 'scaleX(-1)' : 'scaleX(1)',
        }}
      />
    </Wrapper>
  );
}

export default OnOffSigner;
