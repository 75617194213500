import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { featureCollection } from '@turf/turf';
import type { MapMouseEvent } from 'mapbox-gl';
import styled from 'styled-components/macro';
import moment from 'moment';

import {
  selectCrewChangePanel,
  selectMapVessels,
  selectSidePanel,
} from 'redux/selectors';
// Components
import MapboxPopup from './shared/MapboxPopup';
import Badge from './SidePanel/common/Badge';

// Context
import { CCPanelContext } from 'contexts/CCPanelContext';

// Map
import { formatNumberWithUnits } from 'lib/map-ruler';
import {
  drawVessels,
  generateVesselFeature,
  setVesselsSource,
} from 'map/vessels';
import {
  mapState,
  removeLayerImmediately,
  removeSource,
  setHoverState,
  setSource,
} from 'map/map';
import {
  drawRoutes,
  setRoutesSource,
  routeFromMouseEvent,
  drawRouteLabel,
} from 'map/routes';
import {
  CREW_CHANGE_ROUTE,
  CREW_CHANGE_ROUTE_ARROWS,
  CREW_CHANGE_VESSEL,
  ACTIVE_VESSEL_FEATURE,
} from 'map/variables';

// Styles
import { black, textGray, white } from 'lib/colors';
import { ICON_COLOR, TEXT_COLOR } from './SidePanel/common/variables';
import {
  DetailedRouteObject,
  prepareCCFutureRoute,
} from './VesselRoutes/helpers';
import { MapboxMarker } from './shared';
import { PortMarkers } from 'utils/types';

type ActiveVesselFeature = GeoJSON.Feature;

const MarkerStyle = styled.div<{ $backgroundColor: string }>`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${white};
  color: ${white};
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
    font-size: 0.6rem;
    text-align: center;
  }
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ $backgroundColor }) => $backgroundColor || black};
    z-index: 1;
  }
`;
type HoveredFeature = {
  feature: mapboxgl.MapboxGeoJSONFeature;
  coordinates: mapboxgl.LngLat;
};

const PopUp = styled.div`
  padding: 0.5rem;

  h3 {
    font-size: 0.7rem;
    text-transform: uppercase;
    color: ${textGray};
    margin: 0.25rem 0 0 0;
  }

  p {
    font-size: 1rem;
    margin: 0;
  }
`;

// These are vessels selected from sidepanel
function CrewChangeVessel() {
  const sidePanel = useSelector(selectSidePanel);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const { vesselId } = useSelector(selectCrewChangePanel);
  const { route, tableState } = useContext(CCPanelContext);
  const mapShouldUnmount = useRef<boolean>(false);

  // When hovering over a route
  const [hoveredFeature, setHoveredFeature] = useState<
    HoveredFeature | undefined
  >();
  const [hoveredPortMarker, setHoveredPortMarker] = useState<
    PortMarkers | undefined
  >();

  const [isSourceReady, setIsSourceReady] = useState<boolean>(false);

  const vesselWithData = useMemo(
    () => vessels.get(vesselId!),
    [vesselId, vessels]
  );

  // Start and Cleanup Procedure
  useEffect(() => {
    setIsSourceReady(false);
    Promise.all([
      setVesselsSource(CREW_CHANGE_VESSEL, featureCollection([])),
      setRoutesSource(CREW_CHANGE_ROUTE, featureCollection([])),
    ]).then(() => {
      drawVessels(CREW_CHANGE_VESSEL, CREW_CHANGE_VESSEL, {
        layout: {
          'icon-image': 'vessel-385dea-385dea-ffffff',
          'icon-size': 0.6,
          'icon-rotate': ['get', 'course'],
          'icon-allow-overlap': true,
          'text-field': ['get', 'name'],
          'text-radial-offset': 1,
          'text-ignore-placement': true,
          'text-allow-overlap': true,
          'text-variable-anchor': [
            'top',
            'top-left',
            'top-right',
            'bottom',
            'bottom-left',
            'bottom-right',
          ],
          'text-font': ['literal', ['HK Grotesk Medium', 'Roboto Medium']],
          'text-size': ['interpolate', ['linear'], ['zoom'], 3, 12, 6, 16],
        },
        paint: {
          'icon-opacity': 1,
          'text-halo-color': 'hsla(224, 54%, 20%, 0.8)',
          'text-halo-width': 2.25,
          'text-color': '#fff',
        },
      });
      drawRoutes(
        CREW_CHANGE_ROUTE,
        CREW_CHANGE_ROUTE,
        {
          paint: {
            'line-color': ['get', 'color'],
            'line-width': [
              'case',
              [
                'boolean',
                ['coalesce', ['feature-state', 'hover'], false],
                true,
              ],
              4,
              2,
            ],
            'line-dasharray': [4, 2],
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
        },
        CREW_CHANGE_VESSEL
      );
      drawRouteLabel(
        CREW_CHANGE_ROUTE,
        CREW_CHANGE_ROUTE_ARROWS,
        {
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 10,
            'icon-image': ['get', 'arrow'],
            'icon-rotate': 180, // the arrow direction is off, so we need to rotate
            'icon-size': 0.25,
            'icon-allow-overlap': true,
          },
        },
        CREW_CHANGE_VESSEL
      );
      setIsSourceReady(true);
      mapShouldUnmount.current = true;
    });
  }, []);

  // Clean up only when component unmounts
  useEffect(() => {
    return () => {
      // In React 18, all components will mount, unmount then mount again.
      // To prevent this from unmounting unnecessarily at the start, we use a ref value
      if (!mapShouldUnmount.current) return;
      removeLayerImmediately(CREW_CHANGE_VESSEL).then((stillExist) => {
        !stillExist && removeSource(CREW_CHANGE_VESSEL);
      });
      Promise.all([
        removeLayerImmediately(CREW_CHANGE_ROUTE),
        removeLayerImmediately(CREW_CHANGE_ROUTE_ARROWS),
      ]).then(() => {
        removeSource(CREW_CHANGE_ROUTE);
      });
    };
  }, []); // eslint-disable-line

  const handleMouseEnter = useCallback(
    (e: MapMouseEvent) => {
      const selectedFeature = routeFromMouseEvent(e, CREW_CHANGE_ROUTE);
      if (selectedFeature) {
        if (hoveredFeature) {
          setHoverState(
            CREW_CHANGE_ROUTE,
            hoveredFeature.feature.id as string,
            false
          );
        }
        setHoverState(
          CREW_CHANGE_ROUTE,
          selectedFeature!.feature?.id as string,
          true
        );
        setHoveredFeature(selectedFeature);
      }
    },
    [hoveredFeature]
  );

  // eslint-disable-next-line
  const handleMouseLeave = useCallback(
    (e: MapMouseEvent) => {
      const hasFeature = routeFromMouseEvent(e, CREW_CHANGE_ROUTE);
      if (hoveredFeature && !hasFeature) {
        setHoverState(
          CREW_CHANGE_ROUTE,
          hoveredFeature.feature.id as string,
          false
        );
        setHoveredFeature(undefined);
      }
    },
    [hoveredFeature]
  );

  // handlers
  useEffect(() => {
    mapState.map?.on('mousemove', CREW_CHANGE_ROUTE, handleMouseEnter);
    mapState.map?.on('mousemove', handleMouseLeave);
    return () => {
      mapState.map?.off('mousemove', CREW_CHANGE_ROUTE, handleMouseEnter);
      mapState.map?.off('mousemove', handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave, sidePanel.vesselIds]);

  // Build vessel feature
  const activeVesselFeature: ActiveVesselFeature | null = useMemo(() => {
    if (!vesselWithData) return null;
    return generateVesselFeature(
      `${ACTIVE_VESSEL_FEATURE}${vesselWithData.id}`,
      [vesselWithData.lng, vesselWithData.lat],
      {
        course: vesselWithData.course,
        name: vesselWithData.name,
      }
    );
  }, [vesselWithData]);

  const showRoute = useMemo(() => {
    if (!route) return false;
    return tableState.step !== 'crew';
  }, [route, tableState.step]);

  // Build route features
  const activeFeature: DetailedRouteObject | null = useMemo(() => {
    if (!route || !vesselWithData) return null;
    return prepareCCFutureRoute({
      portCalls: route.waypoints,
      vessel: vesselWithData,
      route: route.posRoute.map((o) => ({
        ...o,
        properties: { ...o.properties, segment: 'future' },
      })),
    });
  }, [vesselWithData, route]);

  // Presentation vessel
  useEffect(() => {
    if (!isSourceReady || !activeVesselFeature) return;
    setSource(CREW_CHANGE_VESSEL, featureCollection([activeVesselFeature]));
  }, [activeVesselFeature, isSourceReady]);

  // Presentation routes
  useEffect(() => {
    if (!isSourceReady || !activeFeature) return;
    const { route, segment, displayType } = activeFeature;
    const ROUTE_FC = route.reduce<GeoJSON.FeatureCollection>(
      (fc, { properties, path }, index) => {
        fc.features.push({
          type: 'Feature',
          properties: {
            ...properties,
            id: `crew-change-${segment}-${displayType}-${index}`,
          },
          geometry: {
            type: 'MultiLineString',
            coordinates: path,
          },
        });
        return fc;
      },
      {
        type: 'FeatureCollection',
        features: [],
      }
    );
    setSource(CREW_CHANGE_ROUTE, showRoute ? ROUTE_FC : featureCollection([]));
  }, [activeFeature, isSourceReady, showRoute]);

  const hoveredFeatureColors = useMemo(() => {
    if (!hoveredFeature) return null;
    const {
      feature: { properties },
    } = hoveredFeature;
    const selectedColor = properties!.type
      ? (ICON_COLOR as any)[String(properties!.type).toLowerCase()] ||
        ICON_COLOR.DEFAULT
      : ICON_COLOR.DEFAULT;

    const selectedTextColor = properties!.type
      ? (TEXT_COLOR as any)[String(properties!.type).toLowerCase()] ||
        TEXT_COLOR.DEFAULT
      : TEXT_COLOR.DEFAULT;
    return { selectedColor, selectedTextColor };
  }, [hoveredFeature]);

  const portMarkers = useMemo(() => {
    if (!activeFeature) return [];

    const waypoints = activeFeature.waypoints.reduce<PortMarkers[]>(
      (fc, { properties }) => {
        if (properties.waypointType === 'port') {
          const backgroundColor = properties!.type
            ? (ICON_COLOR as any)[String(properties!.type).toLowerCase()] ||
              ICON_COLOR.DEFAULT
            : ICON_COLOR.DEFAULT;
          fc.push({
            backgroundColor,
            ...properties,
            // During first initialisation, order is not available yet
            index: properties.order ? properties.order : properties.index,
          });
        }
        return fc;
      },
      []
    );
    return waypoints;
  }, [activeFeature]);

  const handleHoverPort = useCallback(
    (index: number) => {
      setHoveredPortMarker(portMarkers.find((o) => o.index === index));
    },
    [portMarkers]
  );

  if (!showRoute) return null;
  return (
    <>
      {portMarkers.map((waypoint) => (
        <MapboxMarker
          id={String(waypoint.index)}
          key={waypoint.index}
          longitude={waypoint.lng}
          latitude={waypoint.lat}
        >
          <MarkerStyle
            $backgroundColor={waypoint.backgroundColor}
            onMouseEnter={() => handleHoverPort(waypoint.index)}
            onMouseLeave={() => setHoveredPortMarker(undefined)}
          >
            <span>{waypoint.index}</span>
          </MarkerStyle>
        </MapboxMarker>
      ))}
      {hoveredPortMarker ? (
        <MapboxPopup
          lng={hoveredPortMarker.lng}
          lat={hoveredPortMarker.lat}
          closeButton={false}
          offset={[0, -12]}
        >
          <PopUp>
            <p>{hoveredPortMarker.displayName}</p>
            {hoveredPortMarker.eta && (
              <h3>ETA: {moment(hoveredPortMarker.eta).format('DD MMM YY')}</h3>
            )}
            {hoveredPortMarker.etd && (
              <h3>ETD: {moment(hoveredPortMarker.etd).format('DD MMM YY')}</h3>
            )}
          </PopUp>
        </MapboxPopup>
      ) : null}
      {hoveredFeature && !hoveredPortMarker ? (
        <MapboxPopup
          lng={hoveredFeature.coordinates.lng}
          lat={hoveredFeature.coordinates.lat}
          closeButton={false}
        >
          <PopUp>
            <Badge
              $textColor={hoveredFeatureColors!.selectedTextColor}
              $backgroundColor={hoveredFeatureColors!.selectedColor}
            >
              {hoveredFeature.feature.properties?.type}
            </Badge>
            <h3>Distance</h3>
            <p>
              {formatNumberWithUnits(
                hoveredFeature.feature.properties?.distance
              )}
            </p>
            <p>{`${hoveredFeature.feature.properties?.from} -> ${hoveredFeature.feature.properties?.to}`}</p>
          </PopUp>
        </MapboxPopup>
      ) : null}
    </>
  );
}

export default CrewChangeVessel;
