import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import type { PortCallResponse, PortCallV2CommonUTC } from 'utils/types';
import {
  fetchBatchVesselPortCallsAsync,
  fetchVesselPortCallsAsync,
} from '../thunks';
import type {
  RootState,
  PortCallStoreResponseV2,
  PortCallState,
  LocodeMap,
} from '../types';
import { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

export const initialPortCalls: PortCallState = {
  portCalls: {},
  portCallRequests: {},
  portLocodeVesselMap: {},
};

const portCallsSlice = createSlice({
  name: 'portCalls',
  initialState: initialPortCalls,
  reducers: {
    updatePortLocodeVesselMap: (state, action: PayloadAction<LocodeMap>) => {
      const currentMap: LocodeMap = Object.assign(
        {},
        state.portLocodeVesselMap
      );
      Object.keys(action.payload).forEach((locode) => {
        if (currentMap[locode]) {
          currentMap[locode] = uniq([
            ...currentMap[locode],
            ...action.payload[locode],
          ]);
        } else {
          currentMap[locode] = action.payload[locode];
        }
      });
      return {
        ...state,
        portLocodeVesselMap: currentMap,
      };
    },
    updateVesselPortCallBatches: (
      state,
      action: PayloadAction<{ [vesselId: number]: PortCallResponse }>
    ) => ({ ...state, portCalls: { ...state.portCalls, ...action.payload } }),
    updateVesselPortCalls: (
      state,
      action: PayloadAction<PortCallStoreResponseV2>
    ) => {
      const { vesselId, ...response } = action.payload;
      return {
        ...state,
        portCalls: {
          ...state.portCalls,
          [vesselId]: response,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBatchVesselPortCallsAsync.pending,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            ...arg.vesselIds.reduce((obj, vesselId) => {
              obj[vesselId] = 'pending';
              return obj;
            }, {} as { [key: number]: 'pending' }),
          },
        })
      )
      .addCase(
        fetchBatchVesselPortCallsAsync.fulfilled,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            ...arg.vesselIds.reduce((obj, vesselId) => {
              obj[vesselId] = 'fulfilled';
              return obj;
            }, {} as { [key: number]: 'fulfilled' }),
          },
        })
      )
      .addCase(
        fetchBatchVesselPortCallsAsync.rejected,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            ...arg.vesselIds.reduce((obj, vesselId) => {
              obj[vesselId] = 'rejected';
              return obj;
            }, {} as { [key: number]: 'rejected' }),
          },
        })
      )
      .addCase(
        fetchVesselPortCallsAsync.pending,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            [arg.vesselId]: 'pending',
          },
        })
      )
      .addCase(
        fetchVesselPortCallsAsync.fulfilled,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            [arg.vesselId]: 'fulfilled',
          },
        })
      )
      .addCase(
        fetchVesselPortCallsAsync.rejected,
        (state, { meta: { arg } }) => ({
          ...state,
          portCallRequests: {
            ...state.portCallRequests,
            [arg.vesselId]: 'rejected',
          },
        })
      );
  },
});

/* ----- selectors -----*/

export const selectPortCalls = ({ portCalls }: RootState) => portCalls;

function checkIfAllRequestsAreMade(
  checkRequests: string[],
  filteredVessels: number[]
) {
  return filteredVessels.reduce((o, vesselId) => {
    if (!o) return o;
    if (!checkRequests.includes(String(vesselId))) {
      return false;
    }
    return o;
  }, true);
}

function checkIfThereArePending(
  portCallRequests: {
    [vesselId: number]: 'fulfilled' | 'pending' | 'rejected';
  },
  filteredVessels: number[]
) {
  return filteredVessels.reduce((o, vesselId) => {
    if (o) return o;
    if (portCallRequests[vesselId] === 'pending') {
      return true;
    }
    return o;
  }, false);
}

export const selectPortCallsIsLoading = createSelector(
  [
    (props: RootState) => props.portCalls.portCallRequests,
    (props: RootState) => props.mapVessels.filteredVessels,
    (props: RootState) => props.calculatedRoute.hoveredVesselRoutes,
  ],
  (portCallRequests, filteredVessels, hoveredVesselRoutes) => {
    if (
      !checkIfAllRequestsAreMade(
        Object.keys(portCallRequests),
        Array.from(filteredVessels.keys())
      )
    )
      return true;

    if (
      !checkIfAllRequestsAreMade(
        Object.keys(hoveredVesselRoutes),
        Array.from(filteredVessels.keys())
      )
    )
      return true;
    return checkIfThereArePending(
      portCallRequests,
      Array.from(filteredVessels.keys())
    );
  }
);

const selectItemId = (state: RootState, id: number | null) => id;

export const selectSinglePortCall = createSelector(
  [selectPortCalls, selectItemId],
  (portCalls: PortCallState, id: number | null) => ({
    request: (id && portCalls.portCallRequests[id]) || null,
    response: (id && portCalls.portCalls[id]) || null,
  })
);

type UpcomingVesselPortCall = {
  vessel: Vessel;
  portCall: PortCallV2CommonUTC;
};

export const selectUpcomingPortCallsAtLocode = createSelector(
  [
    ({ portCalls }: RootState) => portCalls.portLocodeVesselMap,
    ({ portCalls }: RootState) => portCalls.portCalls,
    ({ mapVessels }: RootState) => mapVessels.vesselsFull,
    (_, locode: string | null) => locode,
  ],
  (locodeVessel, portCallVesselMap, vessels, locode) => {
    if (!locode) return [];
    if (!locodeVessel[locode]) return [];
    const unsortedPortCalls = locodeVessel[locode].reduce(
      (allFuturePortCalls, vesselId) => {
        const vessel = vessels.get(vesselId)!;
        const portCalls = portCallVesselMap[vesselId];
        const futurePortCalls = portCalls.portCalls.filter(
          (o) =>
            o.dateToSort &&
            new Date(o.dateToSort) > new Date() &&
            o.portLocode === locode
        );

        allFuturePortCalls.push(
          ...futurePortCalls.map((o) => ({ vessel, portCall: o }))
        );
        return allFuturePortCalls;
      },
      [] as UpcomingVesselPortCall[]
    );
    return sortBy(unsortedPortCalls, (o) => new Date(o.portCall.dateToSort!));
  }
);

/* ----- actions -----*/

export const {
  updateVesselPortCalls,
  updateVesselPortCallBatches,
  updatePortLocodeVesselMap,
} = portCallsSlice.actions;

/* ----- reducer -----*/

export default portCallsSlice.reducer;
