import { memo, useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styled from 'styled-components/macro';

import { white } from 'lib/colors';
import { useAppDispatch, useDebounce } from 'hooks';
import { updateFlightSearch } from 'redux/actions';
import { formatInputDate } from 'components/CrewChangePanel/helpers';

const DateWrapper = styled.div`
  width: 30%;
  margin: 0 0.5rem;

  .MuiFormControl-root,
  .MuiAutocomplete-root {
    width: 100%;
  }

  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiSvgIcon-root {
    height: 1.25rem;
    width: 1.25rem;
  }

  .MuiInputAdornment-root {
    margin-left: 2px;
  }

  .MuiInputBase-input {
    padding-right: 0;
    background: ${white};
  }
`;

export type DateType = {
  valid: boolean;
  dateStr: string | undefined;
};

type Props = {
  disabled: boolean;
  time: DateType;
  updateTime: (
    timeObj: DateType,
    type?: 'keyboard-input' | 'picker-input'
  ) => void;
};

function FlightDatePicker({ time, disabled, updateTime }: Props) {
  const dispatch = useAppDispatch();
  const initialDate =
    time.valid && time.dateStr ? new Date(time.dateStr) : new Date();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(initialDate);

  const handleOpenCalendar = useCallback(
    (value: boolean) => {
      setOpen(value);
      // prevent closing search results
      dispatch(updateFlightSearch({ listOpen: value }));
    },
    [dispatch]
  );

  const handleDateChange = useDebounce(
    (newDate: Date | null, inputValue?: string | undefined) => {
      setDate(newDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (!inputValue && !newDate) {
        updateTime({ valid: true, dateStr: undefined });
      }

      const isValidDate = Boolean(
        newDate && !isNaN(newDate.getTime()) && newDate >= today
      );

      if (inputValue) {
        const formattedDate = formatInputDate(inputValue);
        updateTime(
          { valid: isValidDate, dateStr: formattedDate },
          'keyboard-input'
        );
        return;
      }

      updateTime(
        {
          valid: isValidDate,
          dateStr: newDate ? newDate.toISOString() : undefined,
        },
        'picker-input'
      );
    },
    300
  );

  return (
    <DateWrapper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          disablePast
          disableMaskedInput
          defaultCalendarMonth={initialDate}
          disabled={disabled}
          open={open}
          value={date}
          inputFormat="dd/MM/yy"
          onChange={handleDateChange}
          onOpen={() => handleOpenCalendar(true)}
          onClose={() => {
            // adds a delay because input update is debounced
            setTimeout(() => {
              handleOpenCalendar(false);
            }, 200);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              size="small"
              autoComplete="off"
              onClick={(e) => handleOpenCalendar(true)}
            />
          )}
        />
      </LocalizationProvider>
    </DateWrapper>
  );
}

export default memo(FlightDatePicker);
