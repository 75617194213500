import { memo, useContext, useEffect, useMemo, useState } from 'react';

import { useModal } from 'hooks';
import sleep from 'lib/sleep';
import { NOT_REQUIRED_TEXT } from 'lib/constants';
import { getCommonFlights } from 'lib/alasql/flights';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Tooltip } from 'components/shared';
import FlightAirports from './FlightAirports';
import AllFlightsModal from '../AllFlightsModal';
import RowFlightLoader from '../RowFlightLoader';

import {
  FlexWrapper,
  FlightText,
  Layover,
  ResultsButtonWrapper,
  ResultsButton,
} from './common';
import { PlanningProps } from './types';
import { formatPath, getCurrentFilters } from '../../../helpers';
import { EmptyFlight, FlightRow } from '../../../types';

function FlightConnection({
  flight: flightRow,
  isCompareView,
  onSelect,
}: PlanningProps) {
  const { fetchStatus, filters: allFilters } = useContext(CCPanelContext);
  const { modal, setModal } = useModal(null);
  const [hovered, setHovered] = useState(false);
  const [flightUnavailable, setFlightUnavailable] = useState(false);

  const { crew } = flightRow;
  const { crewType, progress } = fetchStatus;
  const { filters, locodeKey = '' } = useMemo(
    () => getCurrentFilters(allFilters),
    [allFilters]
  );
  // get the flights for a crew, after filtering for port & departure date
  const crewFlights = useMemo(
    () => (filters ? getCommonFlights(filters, crew) : []),
    [filters, crew]
  );
  const progressFinished = progress?.[locodeKey] === 1;

  useEffect(() => {
    let shouldUpdate = true;
    const unavailable =
      progressFinished || (crewType && crew?.type && !crewType[crew.type]); // `crewType` available only when updating departure date
    sleep(0).then(() => {
      if (shouldUpdate) setFlightUnavailable(Boolean(unavailable));
    });
    return () => {
      shouldUpdate = false;
    };
  }, [progressFinished, crewType, crew?.type]);

  const handleCloseModal = () => {
    setModal(null);
    setHovered(false);
  };

  const commonModalProps = {
    flights: crewFlights,
    closeModal: handleCloseModal,
    onSelect,
  };

  // button to trigger the all lfights result modal
  const renderActionButton = () => {
    // don't show in ready-only & compare view
    // otherwise, show when hovered after all crew flights are fetched
    const hideAllFlightsButton = isCompareView || !progressFinished || !hovered;
    return (
      !hideAllFlightsButton && (
        <ResultsButtonWrapper $confirmed={confirmed}>
          <ResultsButton onClick={() => setModal('allFlights')}>
            All Flights
          </ResultsButton>
        </ResultsButtonWrapper>
      )
    );
  };

  // handle cases for unavailable flights in the crew flight cell
  if (!flightRow.flight) {
    const { path, crew } = flightRow as EmptyFlight;
    const [from, to] = formatPath(path);

    // when departure & arrival are same
    if (from === to) {
      return (
        <Tooltip content="Same departure & arrival airports">
          <FlightText>{NOT_REQUIRED_TEXT}</FlightText>
        </Tooltip>
      );
    }

    // when there's no flight fetched for a connection/path
    if (!crewFlights?.length) {
      const flightText = flightUnavailable ? (
        <FlightText>No Flight: {path}</FlightText>
      ) : (
        <RowFlightLoader from={from} to={to} />
      );
      return flightUnavailable ? (
        <Tooltip content={`No available flights from ${from} to ${to}`}>
          {flightText}
        </Tooltip>
      ) : (
        flightText
      );
    }

    // when there's NO matched flight for applied filters
    // but flights fetched for the connection/path
    return (
      <FlexWrapper
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Tooltip content="No matched flight for applied filters">
          <>
            {flightUnavailable ? (
              <FlightText>Unmatched {path}</FlightText>
            ) : (
              <RowFlightLoader from={from} to={to} />
            )}
            {renderActionButton()}
          </>
        </Tooltip>
        {modal?.type === 'allFlights' && filters && (
          <AllFlightsModal
            currentFlight={{ crew }}
            filters={filters}
            {...commonModalProps}
          />
        )}
      </FlexWrapper>
    );
  }

  const {
    originalId,
    port,
    flight,
    layovers,
    airports,
    departure,
    confirmed,
    segments,
  } = flightRow as FlightRow;

  if (!crew || !port) {
    return null;
  }

  const [from, to] = JSON.parse(flight!);
  const airportCodes = [from, ...(layovers?.length ? layovers : []), to];
  const currentFlight = {
    flightId: originalId,
    departureTime: departure?.time || '',
    crew,
    confirmed,
  };

  const renderLayovers = () => {
    if (!layovers) return null;
    return layovers.length ? (
      <>
        {' > '}
        <Layover>{layovers.join(' > ')}</Layover>
        {' > '}
      </>
    ) : (
      ' > '
    );
  };

  return (
    <FlexWrapper
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Tooltip
        content={
          <FlightAirports
            airportCodes={airportCodes}
            airports={airports}
            segments={segments}
          />
        }
      >
        <>
          <span>
            {from} {renderLayovers()}
            {to}
          </span>
          {renderActionButton()}
        </>
      </Tooltip>

      {modal?.type === 'allFlights' && filters && (
        <AllFlightsModal
          currentFlight={currentFlight}
          filters={filters}
          {...commonModalProps}
        />
      )}
    </FlexWrapper>
  );
}

export default memo(FlightConnection);
