import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OnboardContent,
  ONBOARDING_STEPS,
  StepsKey,
} from 'components/MapQuickMenu/components/variables';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_ONBOARD_SETUP_FLOTILLA,
  TRACK_ONBOARD_ADD_VESSEL,
  TRACK_ONBOARD_START_CC,
  TRACK_ONBOARD_ADD_SEARCH_FILTER,
} from 'utils/analytics/constants';
import { addVesselAsync } from 'redux/thunks';
import { saveSteps } from 'utils/onboard';
import { OnboardCompletion } from 'utils/types';

import { updateStoreSettings } from './settings';
import { selectCrewChangeEvent } from './crewChangePanel';
import { getOnboardContentAsync, getOnboardStepsAsync } from '../thunks';
import { AppSettings, CCPanelPayload, OnboardState, RootState } from '../types';

export const initialOnboardState: OnboardState = {
  isGetContentLoading: true,
  isGetCompletionLoading: true,
  stepsContent: [],
  canAddFilter: false,
  totalSteps: ONBOARDING_STEPS.length,
  stepsCompleted: [],
  floatingPipId: null,
  hasBeenShown: !!localStorage.getItem('onboardShown'),
};

const onboardSlice = createSlice({
  name: 'onboard',
  initialState: initialOnboardState,
  reducers: {
    setOnboardHasBeenShown: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasBeenShown: action.payload,
    }),
    setCompletedOnboardSteps: (
      state,
      action: PayloadAction<OnboardCompletion[]>
    ) => ({
      ...state,
      stepsCompleted: action.payload,
    }),
    setCanAddFilter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      canAddFilter: action.payload,
    }),
    completeOnboardStep: (state, action: PayloadAction<StepsKey>) => {
      if (!state.stepsCompleted.some((o) => o.type === action.payload)) {
        const updatedSteps: OnboardCompletion[] = [
          ...state.stepsCompleted,
          {
            type: action.payload,
            completed_at: new Date(),
          },
        ];
        trackUserAction(TRACK_ONBOARD_SETUP_FLOTILLA);
        saveSteps(updatedSteps);
        return {
          ...state,
          stepsCompleted: updatedSteps,
        };
      }
      return state;
    },
    setFloatingPipId: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        floatingPipId: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getOnboardContentAsync.fulfilled,
        (state, action: PayloadAction<OnboardContent[]>) => {
          const mergedContent = ONBOARDING_STEPS.map((initial) => {
            const fetchedContent = action.payload.find(
              (o) => o.stepKey === initial.stepKey
            );
            if (!fetchedContent) return initial;
            return {
              ...initial,
              ...fetchedContent,
            };
          });
          return {
            ...state,
            isGetContentLoading: false,
            stepsContent: mergedContent,
          };
        }
      )
      .addCase(getOnboardContentAsync.rejected, (state) => ({
        ...state,
        isGetContentLoading: false,
        stepsContent: ONBOARDING_STEPS,
      }))
      .addCase(
        getOnboardStepsAsync.fulfilled,
        (state, action: PayloadAction<OnboardCompletion[]>) => ({
          ...state,
          isGetCompletionLoading: false,
          stepsCompleted: action.payload,
        })
      )
      .addCase(getOnboardStepsAsync.rejected, (state) => ({
        ...state,
        isLoading: false,
        stepsCompleted: [],
      }))
      .addCase(
        selectCrewChangeEvent,
        (state, action: PayloadAction<CCPanelPayload | undefined>) => {
          const remainingSteps = state.totalSteps - state.stepsCompleted.length;
          if (!remainingSteps) return state;
          if (!state.stepsCompleted.some((o) => o.type === 'start-cc')) {
            if (action.payload?.active) {
              const updatedSteps: OnboardCompletion[] = [
                ...state.stepsCompleted,
                {
                  type: 'start-cc',
                  completed_at: new Date(),
                },
              ];
              trackUserAction(TRACK_ONBOARD_START_CC);
              saveSteps(updatedSteps);
              return {
                ...state,
                stepsCompleted: updatedSteps,
              };
            }
          }
        }
      );
    builder.addCase(
      updateStoreSettings,
      (state, action: PayloadAction<AppSettings>) => {
        const remainingSteps = state.totalSteps - state.stepsCompleted.length;
        if (!remainingSteps) return state;
        if (!state.stepsCompleted.some((o) => o.type === 'add-search-filter')) {
          if (action.payload.searchFilters.length) {
            const updatedSteps: OnboardCompletion[] = [
              ...state.stepsCompleted,
              {
                type: 'add-search-filter',
                completed_at: new Date(),
              },
            ];
            trackUserAction(TRACK_ONBOARD_ADD_SEARCH_FILTER);
            saveSteps(updatedSteps);
            return {
              ...state,
              stepsCompleted: updatedSteps,
            };
          }
        }
        return state;
      }
    );
    builder.addCase(addVesselAsync.fulfilled, (state, action) => {
      const remainingSteps = state.totalSteps - state.stepsCompleted.length;
      if (!remainingSteps) return state;
      if (!state.stepsCompleted.some((o) => o.type === 'add-vessel')) {
        if (action.payload) {
          const updatedSteps: OnboardCompletion[] = [
            ...state.stepsCompleted,
            {
              type: 'add-vessel',
              completed_at: new Date(),
            },
          ];
          trackUserAction(TRACK_ONBOARD_ADD_VESSEL);
          saveSteps(updatedSteps);
          return {
            ...state,
            stepsCompleted: updatedSteps,
          };
        }
      }
      return state;
    });
  },
});

/* ----- selectors -----*/

export const selectOnboard = ({ onboard }: RootState) => onboard;

export const selectOnboardCalculatedState = createSelector(
  [
    (state: RootState) => state.onboard.isGetCompletionLoading,
    (state: RootState) => state.onboard.isGetContentLoading,
    (state: RootState) => state.onboard.stepsCompleted,
    (state: RootState) => state.onboard.totalSteps,
  ],
  (
    isGetCompletionLoading,
    isGetContentLoading,
    stepsCompleted,
    totalSteps
  ) => ({
    isLoading: isGetCompletionLoading || isGetContentLoading,
    isCompleted: stepsCompleted.length === totalSteps,
    remainingSteps: totalSteps - stepsCompleted.length,
  })
);

/* ----- actions -----*/

export const {
  completeOnboardStep,
  setFloatingPipId,
  setCanAddFilter,
  setCompletedOnboardSteps,
  setOnboardHasBeenShown,
} = onboardSlice.actions;

/* ----- reducer -----*/

export default onboardSlice.reducer;
