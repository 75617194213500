import { featureCollection } from '@turf/turf';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import mapboxgl from 'mapbox-gl';
import * as Sentry from '@sentry/react';

import { GenerateFlotillaMapConfig } from 'utils/flotilla-config';
import { getMapViewport } from 'utils/generate-flotilla-map';
import { computeBBox } from 'utils/geo';
import { HandlerThunk } from 'utils/types';
import { defaultFillLayerSetting } from './fill';
import { defaultRouteLayerSetting } from './routes';
import { MAIN_VESSELS_LAYER_ID_SMALL } from './variables';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_VESSEL_MULTI_SELECT } from 'utils/analytics/constants';

export type MapRoot = {
  map: mapboxgl.Map;
  container: HTMLDivElement;
  initialized: boolean;
};

/********************************** Mutable State ***************************/
export const mapState: {
  map: mapboxgl.Map | null;
  container: HTMLDivElement | null;
  initialized: boolean;
} = {
  map: null,
  container: null,
  initialized: false,
};

let handlers: {
  init: HandlerThunk[];
} = {
  init: [],
};

// TODO: Remove after development
(window as any).inspectMap = {
  mapState,
};

/********************************** Map Functions ***************************/
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

/**
 * Queue operations until the map is loaded.
 * @param func Closured thunk to be run when the map loads.
 * @returns Promise that blocks until the function is run.
 */
export async function runWhenMapReady(
  func: (map: mapboxgl.Map) => Promise<any>
) {
  if (mapState.map && mapState.initialized) {
    if (mapState.map.isStyleLoaded()) return func(mapState.map);
    else {
      mapState.map.once('styledata', runMapReadyHandlers);
    }
  }

  return new Promise((resolve) => {
    handlers.init.push(async () => {
      resolve(await func(mapState.map!));
    });
  });
}

export function runWhenIdle(cb: (map: mapboxgl.Map) => void) {
  if (!mapState.map) return;
  mapState.map!.once('idle', () => {
    mapState.map && cb(mapState.map);
  });
}

export function runWhenStyleData(cb: (map: mapboxgl.Map) => void) {
  if (!mapState.map) return;
  mapState.map.once('styledata', (e) => {
    mapState.map && cb(mapState.map);
  });
}

/**
 * Initialize the map for the first time. Subsequent runs will be idempotent.
 * @param mapContainer Container for the map, will be stored in the internal state of this module.
 * @param initBounds Initial bounds for the map. Can be undefined.
 */
export function initMap(
  mapContainer: HTMLDivElement,
  initBounds?: mapboxgl.LngLatBoundsLike
) {
  return new Promise((res) => {
    try {
      mapState.map = new mapboxgl.Map({
        container: mapContainer,
        style: 'mapbox://styles/greywing-operations/ckvc7m1vlagg415qq2356iddy',
        bounds: initBounds,
      });

      mapState.map.boxZoom.disable();
      mapState.map.dragRotate.disable();
      mapState.map.touchZoomRotate.disableRotation();

      mapState.container = mapContainer;

      mapState.map.once('load', () => {
        if (mapState.map) {
          mapState.initialized = true;

          if (mapState.map.isStyleLoaded()) runMapReadyHandlers();
          else mapState.map.once('style.load', runMapReadyHandlers);

          mapState.map.on('error', (ev: mapboxgl.ErrorEvent) => {
            console.error('Catch Mapbox Error:', ev.error);
          });
          mapState.map.on('styleimagemissing', (e: any) => {
            console.log('MISSING', e);
          });
          // Relocating this here ensures that all the appropriate images are loaded before it resolves
          runWhenMapReady((map) => loadImages(map).then(() => res(true)));
        }
      });
    } catch (error) {
      // Mapbox GL probably failed to load
      Sentry.captureException(error);
    }
  });
}

// clear map state in logout
export const clearMap = () => {
  if (mapState.map) {
    mapState.map = null;
  }
};

export function runWhenSourceLoaded(sourceId: string, func: () => any) {
  if (!mapState.map) return;

  const hasAllImages =
    mapState.map.hasImage('depart-wp-marker') &&
    mapState.map.hasImage('arrival-wp-marker') &&
    mapState.map.hasImage('line-texture') &&
    mapState.map.hasImage('flight-image');

  if (mapState.map.isSourceLoaded(sourceId)) {
    console.log('Source ', sourceId, ' loaded, running func');
    func();
  }

  let sourceLoadedHandler = (e: mapboxgl.MapDataEvent & mapboxgl.EventData) => {
    console.log('Runnning', sourceId);
    if (
      (e.sourceId === sourceId || mapState.map?.isSourceLoaded(sourceId)) &&
      hasAllImages
    ) {
      console.log('Source is now loaded - ', e, ', running func');
      func();
      mapState.map!.off('idle', sourceLoadedHandler);
    } else {
      console.log('Not everything is ready');
    }
  };

  mapState.map.on('idle', sourceLoadedHandler);
}

//**************************** Internal functions ******************************/

async function runMapReadyHandlers() {
  const runningHandlers = handlers.init;
  handlers.init = [];
  for (let i = 0; i < runningHandlers.length; i++) {
    try {
      await runningHandlers[i]();
    } catch (err) {
      console.error('Error running init handler - ', runningHandlers[i]);
    }
  }
}

/**
 * Load the base images needed for map functionality. Custom images are handled in specific modules like vessels.
 * @param map
 * @returns Promise that completes when images have been loaded.
 */
function loadImages(map: mapboxgl.Map): Promise<Array<null>> {
  function imagePromise(url: string, name: string): Promise<null> {
    return new Promise(function (resolve) {
      loadImage(map, url, name, () => resolve(null));
    });
  }

  return Promise.all([
    imagePromise('departure.png', 'depart-wp-marker'),
    imagePromise('arrival.png', 'arrival-wp-marker'),
    imagePromise('linestyle.png', 'line-texture'),
    imagePromise('flight.png', 'flight-image'),
    imagePromise('route-arrow-black-small.png', 'route-arrow-black'),
    imagePromise('route-arrow-white-small.png', 'route-arrow-white'),
    imagePromise('route-arrow-blue-small.png', 'route-arrow-blue'),
    imagePromise('anchor-blue.png', 'anchor'),
    imagePromise('anchor-black.png', 'anchor-pin'),
    imagePromise('tower-control-purple.png', 'tower'),
  ]);
}

function loadImage(
  map: mapboxgl.Map,
  image: string,
  name: string,
  callback?: () => void
) {
  map.loadImage(image, (err, img) => {
    if (map.hasImage(name)) {
      return;
    }

    if (err || !img) {
      console.log(err);
      return;
    }
    map.addImage(name, img);

    callback?.();
  });
}

export function removeLayer(layerId: string) {
  return new Promise((res) => {
    runWhenIdle((map) => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
        res(!!map.getLayer(layerId));
      } else {
        res(false);
      }
    });
  });
}

// alternative implementation of `removeLayer`, doesn't wait for map to be idle
export function removeLayerImmediately(layerId: string) {
  return new Promise((res) => {
    const map = mapState.map;
    if (!map) return;
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId);
      res(!!map.getLayer(layerId));
    } else {
      res(false);
    }
  });
}

export function removeSource(sourceId: string, layerId?: string) {
  runWhenIdle((map) => {
    if (map.getSource(sourceId) && !map.getLayer(layerId || sourceId)) {
      map.removeSource(sourceId);
    }
  });
}

// alternative implementation of `removeSource`, donesn't wait for map to be idle
export function removeSourceWhenActive(sourceId: string, layerId?: string) {
  const { map } = mapState;
  if (map && map.getSource(sourceId) && !map.getLayer(layerId || sourceId)) {
    map.removeSource(sourceId);
  }
}

// Use this only when the map has completed a series of heavy duty map work
export function setSource(
  sourceId: string,
  fc: GeoJSON.FeatureCollection = featureCollection([]),
  promoteId: string = 'id'
) {
  return new Promise(async (res) => {
    try {
      const map = mapState.map;
      const source = map!.getSource(sourceId);
      if (!source) {
        // console.log(`ADDING ${sourceId} SOURCE`, fc);
        map!.addSource(sourceId, {
          type: 'geojson',
          data: fc,
          promoteId, // so that string id's can be queried by feature-state within properties
        });
      } else {
        (source as mapboxgl.GeoJSONSource).setData(fc);
      }
      res(map!.getSource(sourceId));
    } catch (error) {
      console.log('Setting Source', error);
      res(false);
    }
  });
}

export function setFeatureCursorPointer(hasFeature: boolean) {
  if (!mapState.map) return;
  if (hasFeature) {
    mapState.map!.getCanvas().style.cursor = 'pointer';
  } else {
    mapState.map!.getCanvas().style.cursor = '';
  }
}

export function setHoverState(
  sourceId: string,
  featureId: string | number,
  hoverState: boolean
) {
  if (!mapState.map!.getSource(sourceId)) return;
  mapState.map!.setFeatureState(
    {
      source: sourceId,
      id: featureId,
    },
    { hover: hoverState }
  );
  if (hoverState) {
    mapState.map!.getCanvas().style.cursor = 'pointer';
  } else {
    mapState.map!.getCanvas().style.cursor = '';
  }
}

export function flyToOnIdle(coor: { lng: number; lat: number }) {
  const map = mapState.map;
  map!.once('idle', () =>
    map!.flyTo({
      center: coor,
    })
  );
}

export function drawCircleLayer(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.CircleLayer>,
  beforeId?: string
) {
  if (!mapState.map) return;
  const { map } = mapState;
  try {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'circle',
          ...styles,
        },
        beforeId
      );
    }
  } catch (error) {
    console.log('Draw symbol error', error);
  }
}

export function drawSymbolLayer(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.SymbolLayer>,
  beforeId?: string
) {
  if (!mapState.map) return;
  const { map } = mapState;
  try {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'symbol',
          ...styles,
        },
        beforeId
      );
    }
  } catch (error) {
    console.log('Draw symbol error', error);
  }
}

export function drawLineLayer(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.LineLayer>,
  beforeId?: string
) {
  if (!mapState.map) return;
  const { map } = mapState;
  try {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'line',
          ...(styles || defaultRouteLayerSetting(layerId)),
        },
        beforeId
      );
    }
  } catch (error) {
    console.log('Draw line error', error);
  }
}

export function drawFillLayer(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.FillLayer>,
  beforeId?: string
) {
  if (!mapState.map) return;
  const { map } = mapState;
  try {
    const beforeSymbolId =
      beforeId && map.getLayer(beforeId) ? beforeId : undefined;

    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'fill',
          ...(styles || defaultFillLayerSetting(layerId)),
        },
        beforeSymbolId
      );
    }
  } catch (error) {
    console.log('Draw line error', error);
  }
}

export function flyTo(coor: { lng: number; lat: number }, zoom?: number) {
  const map = mapState.map;
  map!.flyTo({
    center: coor,
    ...(zoom ? { zoom } : {}),
  });
}

export function zoomToVessels(vessels: Vessel[], sidebarOn: boolean = false) {
  const map = mapState.map;
  if (!map || !vessels || vessels.length < 2) return;

  const targetBBox = computeBBox(vessels);

  if (sidebarOn) {
    const viewport = getMapViewport();
    const baseCoord = map.unproject(new mapboxgl.Point(0, 0));
    const sidebarCoord = map.unproject(
      new mapboxgl.Point(0, GenerateFlotillaMapConfig.sidebarWidth)
    );
    const endCoord = map.unproject(
      new mapboxgl.Point(viewport.bottom, viewport.right)
    );
    const totalWidth = Math.abs(endCoord.lat - baseCoord.lat);
    const sidebarWidth = Math.abs(sidebarCoord.lat - baseCoord.lat);
    const targetWidth = Math.abs(targetBBox.maxLng - targetBBox.minLng);
    const targetFrac = targetWidth / totalWidth;

    if (targetFrac < GenerateFlotillaMapConfig.zoomLimitFrac)
      map.fitBounds(
        new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(targetBBox.minLng - 1, targetBBox.minLat - 1),
          new mapboxgl.LngLat(
            targetBBox.maxLng + sidebarWidth * targetFrac,
            targetBBox.maxLat + 1
          )
        )
      );
  } else {
    map.fitBounds(
      new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(targetBBox.minLng + 1, targetBBox.minLat + 1),
        new mapboxgl.LngLat(targetBBox.maxLng + 1, targetBBox.maxLat + 1)
      )
    );
  }
}

// Select multiple vessels
export function initMultipleVesselSelect(
  addVesselsToSidePanel: (v: number[]) => void
) {
  const map = mapState.map;
  const canvas = map?.getCanvasContainer().parentElement;

  // Variable to hold starting xy coords when 'mousedown' triggers
  let start: mapboxgl.Point = new mapboxgl.Point(0, 0);

  // Variable to hold current xy coords when 'mousemove' or 'mouseup' triggers
  let current: mapboxgl.Point;

  // Variable for the draw box element
  let box: HTMLDivElement | undefined;

  // Setting `true` dispatches event before other functions call `mousedown`
  // Necessary for disabling default map dragging behavior
  if (canvas) canvas.addEventListener('mousedown', mouseDown, true);

  // Returns the xy coords of the mouse position
  function mousePos(canvas: HTMLElement, e: MouseEvent) {
    const rect = canvas.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - canvas.clientLeft,
      e.clientY - rect.top - canvas.clientTop
    );
  }

  function mouseDown(e: MouseEvent) {
    // Early return if shift key is not also pressed
    // TODO: implement this for tablet, press and drag?
    if (!(e.shiftKey && e.button === 0)) return;

    // Disable default drag zooming when the shift key is held down
    map!.dragPan.disable();

    // Call functions for the following events
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('keydown', onKeyDown);

    // Capture starting xy coords
    if (canvas) start = mousePos(canvas, e);
  }

  function onMouseMove(e: MouseEvent) {
    if (!canvas) return;
    // Capture the ongoing xy coords
    current = mousePos(canvas, e);

    // Append the box element if it doesn't exist
    // TODO: Does `box` get overrode or anything as a part of the event loop?
    if (!box) {
      box = document.createElement('div');
      box.classList.add('boxdraw');
      canvas.appendChild(box);
    }

    const minX = Math.min(start.x, current.x),
      maxX = Math.max(start.x, current.x),
      minY = Math.min(start.y, current.y),
      maxY = Math.max(start.y, current.y);

    // Adjust width and xy positions of the box element ongoing
    const pos = `translate(${minX}px, ${minY}px)`;
    box.style.transform = pos;
    box.style.zIndex = '1000';
    box.style.width = `${maxX - minX}px`;
    box.style.height = `${maxY - minY}px`;
    box.style.border = '3px dashed #f24726';
    box.style.backgroundColor = 'rgba(255, 0, 0, 0.3)';
  }

  function onMouseUp(e: MouseEvent) {
    // Capture xy coords
    finish(canvas ? [start, mousePos(canvas, e)] : null);
  }

  function onKeyDown(e: KeyboardEvent) {
    // If the ESC key is pressed
    if (e.keyCode === 27) finish(null);
  }

  function finish(bbox: [mapboxgl.Point, mapboxgl.Point] | null) {
    // Remove these events now that finish has been called
    document.removeEventListener('mousemove', onMouseMove);
    document.removeEventListener('keydown', onKeyDown);
    document.removeEventListener('mouseup', onMouseUp);

    if (box) {
      if (box.parentNode) box.parentNode.removeChild(box);
      box = undefined;
    }

    // If bbox exists, use this value as argument for `queryRenderedFeatures
    if (bbox) {
      const features = mapState.map!.queryRenderedFeatures(bbox, {
        layers: [MAIN_VESSELS_LAYER_ID_SMALL],
      });
      // Adds all selected vessels to side panel
      const vesselIds: number[] = features.map(
        (feature) => feature?.properties?.vesselId
      );
      trackUserAction(TRACK_VESSEL_MULTI_SELECT, 'click', {
        vesselIds,
        count: vesselIds.length,
      });
      if (vesselIds.length) addVesselsToSidePanel(vesselIds);
    }

    // Renable default map dragging functionality
    map!.dragPan.enable();
  }
}
