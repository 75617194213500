import { getCrewChangeData } from 'api/flotilla';
import {
  ReadOnlyPlanningData,
  RoutePort,
} from 'components/CrewChangePanel/types';
import { selectCrewChangeEvent, setReadOnlyPlanningData } from 'redux/actions';
import { AppDispatch } from 'redux/types';

// sanitize route ports list in read-only crew-change data
// to make route data back-ward compatible or to handle any data mismatch
const sanitizeRoute = (route: any[]): RoutePort[] => {
  return route.map((item) => {
    if (!item.port) {
      return {
        ...item,
        time: item.time || 0,
        order: item.order || item.index,
        componentType: 'port',
        displayName: item.displayName || item.text || '',
        port: { locode: item.locode || '', name: item.name || '' },
      };
    }
    return { ...item };
  });
};

export const prepareReadOnlyData = async (
  id: string
): Promise<ReadOnlyPlanningData | null> => {
  const { success, response } = await getCrewChangeData(id);
  if (!success || !response) {
    return null;
  }

  const { uuid, creatorId, ...rest } = response;
  const readOnlyData = { id: uuid, ...rest };
  const { crewChangePlan } = readOnlyData;
  return {
    ...readOnlyData,
    crewChangePlan: {
      ...crewChangePlan,
      route: sanitizeRoute(crewChangePlan.route),
    },
  };
};

export const openReadOnlyPanel = async (id: string, dispatch: AppDispatch) => {
  const readOnlyData = await prepareReadOnlyData(id);

  // skip updating redux store, if readonly data is unavailable
  if (!readOnlyData) return;

  const { flotillaVesselId: vesselId, crewChangePlan } = readOnlyData;
  dispatch(setReadOnlyPlanningData(readOnlyData));
  dispatch(
    selectCrewChangeEvent({
      vesselId,
      event: crewChangePlan.event,
      active: 'readOnly',
    })
  );
};
