import { Middleware } from '@reduxjs/toolkit';
import { SeaGPTThread, SeaGPTThreadIdMap } from 'components/SeaGPT/type';
import { StoreAPI } from 'redux/types';

const saveSeaGPTMessages: Middleware<{}, any> =
  ({ getState }: StoreAPI) =>
  (next) =>
  async (action) => {
    if (action.type === 'copilot/startNewSeaGPTThread') {
      const currentRaw = localStorage.getItem('threadHistory') as string;
      const userId = getState().settings.userInfo?.id;
      if (!userId || !currentRaw) return;
      try {
        const parsed = JSON.parse(currentRaw);
        const userConversations = parsed[userId] as SeaGPTThreadIdMap | null;
        if (!userConversations) return;
        localStorage.setItem(
          'threadHistory',
          JSON.stringify({
            ...parsed,
            [userId]: {
              ...parsed[userId],
              [action.payload.id]: action.payload,
            },
          })
        );
      } catch (error) {
        console.error('Failed to save to local storage', error);
      }
    }
    if (action.type === 'copilot/saveGptMessage') {
      const currentRaw = localStorage.getItem('threadHistory') as string;
      const userId = getState().settings.userInfo?.id;
      const activeConversationId = getState().copilot.activeThread;
      if (!userId || !activeConversationId || !currentRaw) return;

      try {
        const parsed = JSON.parse(currentRaw);
        const userConversations = parsed[userId] as SeaGPTThreadIdMap | null;
        if (!userConversations) return;
        const conversation = userConversations[
          activeConversationId
        ] as SeaGPTThread;
        if (!conversation) return;
        conversation.blocks = conversation.blocks.map((block) => {
          if (block.id !== action.payload.blockId) return block;
          return {
            ...block,
            messages: block.messages.map((oldMessage) => {
              if (oldMessage.id !== action.payload.message.id)
                return oldMessage;
              return action.payload.message;
            }),
          };
        });
        localStorage.setItem(
          'threadHistory',
          JSON.stringify({
            ...parsed,
            [userId]: {
              ...parsed[userId],
              [activeConversationId]: conversation,
            },
          })
        );
      } catch (error) {
        console.error('Failed to save to local storage', error);
      }
    }
    if (action.type === 'copilot/saveGptBlock') {
      const currentRaw = localStorage.getItem('threadHistory') as string;
      const userId = getState().settings.userInfo?.id;
      const activeThread =
        action.payload?.threadId || getState().copilot.activeThread;
      if (!userId || !activeThread || !currentRaw) return;

      try {
        const parsed = JSON.parse(currentRaw);
        const userConversations = parsed[userId] as SeaGPTThreadIdMap | null;
        if (!userConversations) return;
        const conversation = userConversations[activeThread] as SeaGPTThread;
        if (!conversation) return;
        conversation.blocks = [
          ...conversation.blocks.filter((o) => o.id !== action.payload.id),
          action.payload,
        ];
        localStorage.setItem(
          'threadHistory',
          JSON.stringify({
            ...parsed,
            [userId]: {
              ...parsed[userId],
              [activeThread]: conversation,
            },
          })
        );
      } catch (error) {
        console.error('Failed to save to local storage', error);
      }
    }
    if (action.type === 'copilot/updateSeaGPTThreadMeta') {
      const currentRaw = localStorage.getItem('threadHistory') as string;
      const userId = getState().settings.userInfo?.id;
      const activeConversationId = getState().copilot.activeThread;
      if (!userId || !activeConversationId || !currentRaw) return;

      try {
        const parsed = JSON.parse(currentRaw);
        const userConversations = parsed[userId] as SeaGPTThreadIdMap | null;
        if (!userConversations) return;
        const conversation = userConversations[
          activeConversationId
        ] as SeaGPTThread;
        if (!conversation) return;
        localStorage.setItem(
          'threadHistory',
          JSON.stringify({
            ...parsed,
            [userId]: {
              ...parsed[userId],
              [activeConversationId]: {
                ...conversation,
                ...action.payload,
              },
            },
          })
        );
      } catch (error) {
        console.error('Failed to save to local storage', error);
      }
    }
    next(action);
  };

export default saveSeaGPTMessages;
