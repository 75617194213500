import {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import localforage from 'localforage';
import {
  Graph,
  LinkWithDescription,
  MultiRoute,
  NetworkLineString,
  NodeData,
  DistanceUnits,
  DeviatedPath,
} from '@greywing-maritime/gw-ngraph';
import { shortest, utils } from '@greywing-maritime/gw-ngraph';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { PathFinderOptions } from 'ngraph.path';
import { feature } from '@turf/helpers';

import { FlotillaMapConfig } from 'utils/flotilla-config';
import {
  CalculatedVesselRoute,
  GetDeviationProps,
  GetVesselRoute,
  RouteCalculatorDispatches,
  RouteCalculatorState,
  VesselRouteCalculationConfig,
} from 'utils/types/route-calculator';

import { trackIndirect } from 'lib/amplitude';
import { TRACK_APP_GRAPH_LOAD } from 'utils/analytics/constants';
import { simpleHash } from 'utils/hash';

const { routesToPath, setOptions } = utils;
const { getShortestRouteHacky } = shortest;

const createWorker = createWorkerFactory(
  () => import('../workers/calc-route.worker')
);
const createMapWorker = createWorkerFactory(
  () => import('../workers/map.worker')
);
type NetworkFeatureCollection = GeoJSON.FeatureCollection<NetworkLineString> & {
  name: string;
};

export const RouteCalculatorContext = createContext(
  {} as RouteCalculatorDispatches & RouteCalculatorState
);

type RouteCalculatorProps = {
  children: ReactNode;
};

const RouteCalculatorProvider = ({ children }: RouteCalculatorProps) => {
  const worker = useWorker(createWorker);
  const mapWorker = useWorker(createMapWorker);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [graph, setGraph] = useState<Graph | undefined>();
  const [useSuez, setUseSuez] = useState<boolean>(true); // eslint-disable-line
  const [usePanama, setUsePanama] = useState<boolean>(true); // eslint-disable-line
  const [useIRTC, setUseIRTC] = useState<boolean>(true); // eslint-disable-line
  const [networkJSONFC, setNetworkJSONFC] =
    useState<GeoJSON.FeatureCollection>();

  const cache = useRef<{ [key: string]: CalculatedVesselRoute }>({});
  const deviationCache = useRef<{ [key: string]: DeviatedPath }>({});

  const time = useRef({ calculated: 0, timeTaken: 0 });

  const graphOptions: PathFinderOptions<NodeData, LinkWithDescription> =
    useMemo(
      () => setOptions(!useIRTC, useSuez, usePanama),
      [useSuez, usePanama, useIRTC]
    );

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      const checkGeoJSON =
        await require('../assets/network/network-20km-linestring.json');

      const existingNetworkJSON: NetworkFeatureCollection | null =
        await localforage.getItem('network-20');
      let selectedNetwork: NetworkFeatureCollection | null =
        existingNetworkJSON;
      if (!existingNetworkJSON) {
        selectedNetwork = checkGeoJSON;
        localforage.setItem('network-20', selectedNetwork);
      } else {
        if (existingNetworkJSON.name !== checkGeoJSON.name) {
          selectedNetwork = checkGeoJSON;
          localforage.setItem('network-20', selectedNetwork);
        }
      }
      if (selectedNetwork) {
        const start = window.performance.now();
        const builtGraph = await mapWorker.buildGraph(selectedNetwork);
        setGraph(builtGraph);
        setNetworkJSONFC(selectedNetwork);
        const finish = window.performance.now();
        trackIndirect(TRACK_APP_GRAPH_LOAD, {
          timeTaken: Number(finish - start).toFixed(0),
        });
        console.log(
          `%cGraph built in ${Number(finish - start).toFixed(0)} ms.`,
          'color: green'
        );
      } else {
        setError('Unable to fetch maritime network file.');
      }
      setIsLoading(false);
    })();
  }, [mapWorker]);

  const getVesselRoute: GetVesselRoute = useCallback(
    ({
      waypoints,
      isSplit = false,
      isRefetch = false,
      units = FlotillaMapConfig.routeUnits as DistanceUnits,
      connectAllWaypoints = false,
    }) =>
      new Promise(async (res) => {
        const config: VesselRouteCalculationConfig = {
          units,
          useSuez,
          usePanama,
          useIRTC,
          type: isSplit ? 'split' : 'combined',
          connectAllWaypoints,
        };

        const noResult: CalculatedVesselRoute = {
          config,
          calculation: {
            totalDistance: 0,
            time: 0,
            posRoute: [],
          },
          route: [],
        };

        if (!graph) {
          res(noResult);
          return;
        }

        const stringied: string = `${JSON.stringify(
          waypoints
        )}-${JSON.stringify(config)}`;
        // simple hash stringied without using a library
        const hashed = Math.abs(simpleHash(stringied)).toString();
        const checkCache = cache.current[hashed];
        if (checkCache && !isRefetch) {
          res(checkCache);
          return;
        }

        const start = window.performance.now();

        // Shift this calculation to a webworker
        const finalRoute: MultiRoute | null = await worker.calcMultiRoute({
          graph,
          waypoints,
          graphOptions,
          options: {
            units,
            connectAllWaypoints,
          },
        });
        const finish = window.performance.now();
        const timeTaken = Number(finish - start);
        // console.log('calc', timeTaken);
        time.current = {
          calculated: time.current.calculated + 1,
          timeTaken: (time.current.timeTaken += timeTaken),
        };

        // console.log(`Calculated in ${Number(finish - start).toFixed(0)} ms`);
        if (finalRoute) {
          if (isSplit) {
            const splitPaths = (finalRoute as MultiRoute).routes.map((route) =>
              feature(
                {
                  type: 'MultiLineString',
                  coordinates: route.path,
                },
                {
                  distance: route.distance,
                  ...route.properties,
                }
              )
            );

            const calcResult: CalculatedVesselRoute = {
              config,
              calculation: {
                totalDistance: finalRoute.distance,
                time: timeTaken,
                posRoute: finalRoute.routes,
              },
              route: splitPaths as GeoJSON.Feature[],
            };

            (cache as MutableRefObject<{}>).current = {
              ...cache.current,
              [hashed]: calcResult,
            };

            res(calcResult);
          } else {
            const routeToFeature = feature(
              {
                type: 'MultiLineString',
                coordinates: routesToPath((finalRoute as MultiRoute).routes),
              },
              { distance: finalRoute.distance }
            );

            const calcResult: CalculatedVesselRoute = {
              config,
              calculation: {
                totalDistance: finalRoute.distance,
                time: timeTaken,
                posRoute: finalRoute.routes,
              },
              route: [routeToFeature as GeoJSON.Feature],
            };

            (cache as MutableRefObject<{}>).current = {
              ...cache.current,
              [hashed]: calcResult,
            };
            res(calcResult);
          }
        } else {
          res(noResult);
        }
      }),
    [graph, graphOptions] // eslint-disable-line
  );

  const getDeviation = useCallback(
    ({
      existingPath,
      newPosition,
      units = FlotillaMapConfig.routeUnits as DistanceUnits,
    }: GetDeviationProps): DeviatedPath => {
      const stringied: string = `${existingPath
        .map((o) => o.join(','))
        .join(';')}-${newPosition.join(',')}-${units}`;
      const hashed = simpleHash(stringied);
      const checkCache = deviationCache.current[hashed];

      if (checkCache !== undefined) {
        return checkCache;
      }

      const result = getShortestRouteHacky({
        existingPath,
        newPosition,
        units,
      });

      const resultResponse = {
        path: result.path,
        deviationDistance: Number(result.deviationDistance.toFixed(1)),
      };

      (deviationCache as MutableRefObject<{}>).current = {
        ...deviationCache.current,
        [hashed]: resultResponse,
      };
      return resultResponse;
    },
    []
  );

  return (
    <RouteCalculatorContext.Provider
      value={{
        isLoading,
        isReady: !isLoading && !error,
        error,
        graphOptions,
        getVesselRoute,
        getDeviation,
        network: networkJSONFC,
      }}
    >
      {children}
    </RouteCalculatorContext.Provider>
  );
};

export default RouteCalculatorProvider;
