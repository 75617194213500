import { memo } from 'react';

import { PlanListProps } from './types';
import CompletePlans from './CompletePlans';
import IncompletePlans from './IncompletePlans';

function SavedPlans(props: PlanListProps) {
  return props.isCompleted ? (
    <CompletePlans {...props} />
  ) : (
    <IncompletePlans {...props} />
  );
}

export default memo(SavedPlans);
