import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectMapVessels } from 'redux/selectors';
import { populateVesselsAsync } from 'redux/thunks';
import { AppConfig } from 'utils/flotilla-config';
import useAppDispatch from './useAppDispatch';

function useVesselUpdate() {
  const dispatch = useAppDispatch();
  const { vesselsFullUpdatedAt } = useSelector(selectMapVessels);

  // Initial load of all vessels
  // TODO: uncomment this part, once handover is decommissioned
  // useEffect(() => {
  //   dispatch(populateVesselsAsync());
  //   }, []); // eslint-disable-line

  // Update vessel objects every 5 minutes
  useEffect(() => {
    let vesselsUpdateInterval = null as null | NodeJS.Timeout;

    vesselsUpdateInterval = setInterval(() => {
      if (vesselsFullUpdatedAt) {
        dispatch(populateVesselsAsync());
      }
    }, AppConfig.vesselsUpdatedIntervalMs);

    return () => {
      if (vesselsUpdateInterval) clearInterval(vesselsUpdateInterval);
    };
  }, [vesselsFullUpdatedAt]); // eslint-disable-line

  return null;
}

export default useVesselUpdate;
