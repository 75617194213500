import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useInitializePlatform, useMobile, useSentry } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';

import Warning from 'components/Warning';
import LoginPage from 'components/UserAccount/Login/LoginPage';
import AuthenticatedLayout from 'components/Layouts/Authenticated';
import UnauthenticatedLayout from 'components/Layouts/Unauthenticated';
import { BASE_MATRIX_ROUTE } from 'components/CrewMatrixPlanning/constants';
import StopTrackingFlight from 'pages/StopTrackingFlight';

const AppRedirects = lazy(() => import('./pages/Redirects'));
const SignupPage = lazy(() => import('./pages/SignupPage'));
const AuthPage = lazy(() => import('./pages/AuthPage'));
const FlotillaPage = lazy(() => import('./pages/FlotillaPage'));
const CrewMatrixPage = lazy(() => import('./contexts/CCMatrixContext'));

LicenseInfo.setLicenseKey(
  '6e0bc3c0385d3ca6925a43e7656126f4T1JERVI6Mzk0NzAsRVhQSVJZPTE2Nzg2MDUwMjYwMDAsS0VZVkVSU0lPTj0x'
);

const ROUTES = {
  redirects: [
    '/ports/restrictions',
    '/crew-change-plan',
    '/sidepanel-updates',
    '/magic-link',
  ],
  crewChangeMatrix: [
    `/${BASE_MATRIX_ROUTE}`,
    `/${BASE_MATRIX_ROUTE}/:vesselId`,
  ],
};

function App() {
  const isMobile = useMobile(BREAK_POINT_XS);
  useInitializePlatform();
  useSentry();
  return (
    // List of routes - wrap with `AuthenticatedLayout` if route require authentication
    <>
      <Warning />
      <Routes>
        {/* App redirects */}
        {ROUTES.redirects.map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense fallback={<></>}>
                <AppRedirects />
              </Suspense>
            }
          />
        ))}

        {/* Unauthenticated routes */}
        <Route element={<UnauthenticatedLayout verifyUser />}>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/register/:type?"
            element={
              <Suspense fallback={<></>}>
                <SignupPage />
              </Suspense>
            }
          />
          <Route
            path="/auth/:type/:status?"
            element={
              <Suspense fallback={<></>}>
                <AuthPage />
              </Suspense>
            }
          />
        </Route>

        <Route element={<UnauthenticatedLayout />}>
          <Route
            path="/flights/:uid/stop-tracking"
            element={<StopTrackingFlight />}
          />
        </Route>

        {/* Authenticated routes */}
        <Route element={<AuthenticatedLayout />}>
          <Route
            path="/"
            element={
              <Suspense fallback={<></>}>
                <FlotillaPage />
              </Suspense>
            }
          />
          {ROUTES.crewChangeMatrix.map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<></>}>
                  <CrewMatrixPage />
                </Suspense>
              }
            />
          ))}
        </Route>

        {/* Any other unspecified routes */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>

      <ToastContainer limit={isMobile ? 1 : undefined} />
    </>
  );
}

export default App;
