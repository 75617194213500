import styled, { css } from 'styled-components/macro';

import { borderGray, white } from 'lib/colors';
import { BREAK_POINT_XS, BREAK_POINT_XXS } from 'lib/breakpoints';
import { FilterButton } from 'components/CrewChangePanel/Table/Actions/Flights/common';

export const StyledFilterButton = styled(FilterButton)`
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    font-size: 0.65rem;
    padding: 0.5rem;
    margin-right: 6px !important;
  }

  @media screen and (max-width: ${BREAK_POINT_XXS}) {
    font-size: 0.65rem;
    padding: 0.2rem 0.33rem;
    margin-right: 6px !important;
  }
`;

export const commonMenuStyles = {
  maxHeight: '600px',
};

const commonTextStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  position: sticky;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  padding: 0.5rem 0;
  background: ${white};
  z-index: 999;
`;

export const Title = styled.div`
  ${commonTextStyles};
  top: 0;
  border-bottom: 1px solid ${borderGray};
  margin-top: -0.5rem;
  padding-left: 1rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
`;

export const Footer = styled.div`
  ${commonTextStyles};
  bottom: 0;
  border-top: 1px solid ${borderGray};
  margin-bottom: -0.5rem;
  padding-left: 0.5rem;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.08);
`;
