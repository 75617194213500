import partition from 'lodash/partition';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {
  DataGridPro,
  GridAlignment,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components/macro';
import sortBy from 'lodash/sortBy';

import {
  MISSING_FLIGHT_TEXT,
  NOT_REQUIRED_TEXT,
  REQUEST_BOOKING_TEXT,
} from 'lib/constants';
import {
  fadedGreen,
  gray50,
  green,
  red,
  textBlack,
  textGray,
} from 'lib/colors';

import { CrewVisaInfo, Tooltip } from 'components/shared';
import { StyledButton } from '../common';
import { getSummaryCrewRowClassName } from '../helpers';
import { CrewDetails, SelectedFields, VesselDetails } from '../types';

export const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 999;

  .MuiDataGrid-row.requested-flight {
    background-color: ${fadedGreen}30 !important;
    &:hover {
      background-color: ${fadedGreen}40 !important;
    }
  }
  .MuiDataGrid-row.missing-flight {
    background-color: ${red}30 !important;
    &:hover {
      background-color: ${red}40 !important;
    }
  }

  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }
  .selected {
    color: ${textBlack};
  }
  .unselected {
    color: ${gray50};
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 3px;
    margin-left: -2px;
  }
`;

const FlightText = styled.span<{ $empty: boolean }>`
  ${({ $empty }) =>
    $empty &&
    `
    color: ${textGray};
    font-style: italic;
  `};
`;

const AddButton = styled(StyledButton)`
  min-height: unset;
  min-width: unset;
  font-size: 0.7rem;
  letter-spacing: 0.5px;
`;

const Badge = styled.div`
  padding: 2px 4px;
  color: ${green};
  background: ${green}20;
  border: 1px solid ${green};
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.6rem;
  letter-spacing: 0.5px;
`;

const tableStyles = {
  fontFamily: 'HK Grotesk, Roboto',
  fontSize: '0.8rem',
  '& .table-header': {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.675rem',
    letterSpacing: '0.05rem',
    color: `${textGray}`,
  },
};

const checkboxStyle = {
  '& .MuiSvgIcon-root': { fontSize: 18 },
};

type CrewColumnProps = {
  addCrew: (crewId: number) => void;
};

type UpdateFields = Dispatch<SetStateAction<SelectedFields>>;
type FieldProps<T> = {
  selectedFields: { [key in keyof T]: boolean };
  updateFields: UpdateFields;
};
type VesselTableProps = FieldProps<VesselDetails> & { vessel: VesselDetails };
type CrewTableProps = FieldProps<CrewDetails> &
  CrewColumnProps & {
    crew: CrewDetails[];
    isSelectable: boolean;
  };

const renderColumnHeader =
  (type: 'vessel' | 'crew', isChecked: boolean, updateFields: UpdateFields) =>
  (params: GridColumnHeaderParams) => {
    const { field } = params;
    let headerName = field;

    switch (field) {
      case 'cost':
        headerName = 'Flight Cost';
        break;
      case 'passportNumber':
        headerName = 'PP No.';
        break;
      case 'passportExpiry':
        headerName = 'PP Expiry';
        break;
      case 'visaCountry':
        headerName = 'Visa Country';
        break;
      case 'visaExpiry':
        headerName = 'Visa Expiry';
        break;
      case 'flightNumbers':
        headerName = 'Flight No.';
        break;
      case 'flightFareType':
        headerName = 'Fare Type';
        break;
      case 'seamenBookIssuedCountryIso3':
        headerName = 'SB Issued Country';
        break;
      case 'classCodes':
        headerName = 'Class Code';
        break;
      default:
        break;
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      updateFields((fields) => ({
        ...fields,
        [type]: { ...fields[type], [field]: checked },
      }));
    };

    return (
      <HeaderWrapper>
        <Checkbox
          size="small"
          checked={isChecked}
          onChange={handleChange}
          sx={checkboxStyle}
        />
        {headerName}
      </HeaderWrapper>
    );
  };

const vesselColumns: GridColDef[] = [
  { field: 'imo', maxWidth: 75 },
  { field: 'name', minWidth: 100 },
  { field: 'owner', minWidth: 120 },
  { field: 'destination', minWidth: 180 },
  { field: 'eta', minWidth: 80 },
  { field: 'etd', minWidth: 80 },
  { field: 'type', minWidth: 150 },
];

const isCrewMissingFlight = (crew: CrewDetails) =>
  [MISSING_FLIGHT_TEXT, REQUEST_BOOKING_TEXT].includes(crew.flight);

// min widths are set such that it would minimise the chance of the default presented text
// being truncated.
const getCrewColumns = ({ addCrew }: CrewColumnProps): GridColDef[] => [
  {
    field: 'type',
    minWidth: 100,
  },
  {
    field: 'cid',
    minWidth: 80,
    headerAlign: 'center',
    align: 'center',
  },
  { field: 'name', minWidth: 160 },
  { field: 'flightFareType', minWidth: 80 },
  {
    field: 'flight',
    minWidth: 130, // assume at least 1 layover
    renderCell: ({ row }: GridRenderCellParams) => (
      <FlightText
        $empty={row.flight === NOT_REQUIRED_TEXT || isCrewMissingFlight(row)}
      >
        {row.flight}
      </FlightText>
    ),
  },
  {
    field: 'flightNumbers',
    minWidth: 110, // assume at least 2 flight numbers
    renderCell: ({ row }: GridRenderCellParams) => {
      const missingFlight = isCrewMissingFlight(row);
      if (!missingFlight) {
        return <span>{row.flightNumbers}</span>;
      }
      return row.flight === MISSING_FLIGHT_TEXT ? (
        <Tooltip content="Add this crew to the email">
          <AddButton
            className="e2e_add-missing-flight-crew"
            variant="primary"
            onClick={() => addCrew(row.id)}
          >
            Add
          </AddButton>
        </Tooltip>
      ) : (
        <Badge data-id="e2e_added-missing-flight-crew">Added</Badge>
      );
    },
  },
  { field: 'departure', minWidth: 110 },
  { field: 'arrival', minWidth: 110 },
  { field: 'classCodes', minWidth: 160 }, // assume at least 2 flight numbers
  { field: 'cost', minWidth: 90 },
  { field: 'reason', minWidth: 120, editable: true, type: 'string' },
  { field: 'time', minWidth: 110 },
  { field: 'manningOffice', minWidth: 100 },
  { field: 'country', minWidth: 80 },
  { field: 'sex', minWidth: 80 },
  { field: 'birthday', minWidth: 90 },
  { field: 'passportNumber', minWidth: 90 },
  { field: 'passportExpiry', minWidth: 90 },
  {
    field: 'visaCountry',
    minWidth: 90,
    align: 'center' as GridAlignment,
    renderCell: ({ row: crew }: GridRenderCellParams) => (
      <CrewVisaInfo countryCode={crew.visaCountry} />
    ),
  },
  { field: 'visaExpiry', minWidth: 90 },
  { field: 'passportIssued', minWidth: 90 },
  { field: 'seamenBookNumber', minWidth: 90 },
  { field: 'seamenBookExpiry', minWidth: 90 },
  { field: 'seamenBookIssued', minWidth: 90 },
  {
    field: 'seamenBookIssuedCountryIso3',
    minWidth: 90,
  },
];

function VesselTable({
  vessel,
  selectedFields,
  updateFields,
}: VesselTableProps) {
  const columns = vesselColumns.map((item) => {
    const selected = selectedFields[item.field as keyof VesselDetails];
    return {
      ...item,
      flex: 1,
      sortable: false,
      headerClassName: 'table-header',
      renderHeader: renderColumnHeader('vessel', selected, updateFields),
      cellClassName: selected ? 'selected' : 'unselected',
      maxWidth: item.maxWidth || 250,
    };
  });
  const rows = [{ id: 1, ...vessel }];

  return (
    <TableWrapper>
      <DataGridPro
        rows={rows}
        columns={columns}
        density="compact"
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        hideFooterPagination
        autoHeight
        sx={tableStyles}
      />
    </TableWrapper>
  );
}

function CrewTable({
  crew,
  selectedFields,
  updateFields,
  addCrew,
  isSelectable,
}: CrewTableProps): JSX.Element {
  const [crewWithReason, setCrewWithReason] = useState<{
    [id: string]: string;
  }>({});
  const columns = useMemo(
    () =>
      getCrewColumns({ addCrew }).map((item) => {
        if (!isSelectable) {
          return {
            ...item,
            flex: 1,
            sortable: false,
            headerClassName: 'table-header',
            maxWidth: item.maxWidth || 250,
          };
        }
        const selected = selectedFields[item.field as keyof CrewDetails];
        return {
          ...item,
          flex: 1,
          sortable: false,
          headerClassName: 'table-header',
          renderHeader: renderColumnHeader('crew', selected, updateFields),
          cellClassName:
            selected || item.field === 'type' ? 'selected' : 'unselected',
          maxWidth: item.maxWidth || 250,
        };
      }),
    [selectedFields, updateFields, addCrew, isSelectable]
  );

  const rows = useMemo(() => {
    const [missingFlightCrew, otherCrew] = partition(crew, isCrewMissingFlight);
    // Sort by Onsigner first then
    // Sort the crew by flight number to ensure that the crew members with the same
    // flight combinations appear as a group

    const sorted = sortBy([...otherCrew], ['type', 'flightNumbers'])
      .reverse()
      .map((o) => ({ ...o, reason: crewWithReason[o.id] ?? o.reason }));
    return [...sorted, ...sortBy(missingFlightCrew, 'type').reverse()];
  }, [crew, crewWithReason]);

  const handleRowUpdate = (newRow: CrewDetails, oldRow: CrewDetails) =>
    new Promise<GridRowModel>((resolve) => {
      if (oldRow.reason !== newRow.reason) {
        setCrewWithReason((prev) => ({ ...prev, [newRow.id]: newRow.reason }));
        resolve(newRow);
      } else {
        resolve(newRow);
      }
    });

  return (
    <TableWrapper>
      <DataGridPro
        key={`select-${isSelectable}`}
        rows={rows}
        columns={columns}
        density="compact"
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        hideFooterPagination
        autoHeight
        getRowClassName={getSummaryCrewRowClassName}
        sx={tableStyles}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={handleRowUpdate}
      />
    </TableWrapper>
  );
}

export { CrewTable, VesselTable };
