import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  createCrewNoteAsync,
  deleteCrewNoteAsync,
  fetchCrewMatrixVesselsAsync,
  fetchCrewNotesAsync,
  fetchCrewHistoryAsync,
  fetchCrewPersonalInfoAsync,
  fetchDataSyncDetailsAsync,
  fetchVesselScheduleEventsAsync,
} from 'redux/thunks';
import {
  VesselInfo as CCMatrixVessel,
  CrewBoardingRecord,
  CrewChangeEventDetailed,
  CrewChangeEventSummary,
  CrewNote,
  CrewNotesResponse,
  CrewPersonalInfo,
  ListCrewScheduleResponse,
  SyncDetails,
} from 'components/CrewMatrixPlanning/types';
import {
  CCMatrixState,
  AddFeedbackPayload,
  ScheduleMatrixEvents,
} from '../types';
import {
  CMP_FEEDBACK,
  sanitizeCMPFeedback,
  saveFeedbackToLocalStorage,
  updateEventInStore,
} from 'redux/helpers/crewChangeMatrix';

const cmpFeedbackStr = localStorage.getItem(CMP_FEEDBACK);
export const initialCCMatrix: CCMatrixState = {
  loaded: false,
  syncDetails: null,
  vessels: [],
  scheduleEvents: null,
  orgCrewSchedule: null,
  crewNotes: null,
  crewHistory: null,
  crewPersonalInfo: null,
  feedback: cmpFeedbackStr
    ? sanitizeCMPFeedback(JSON.parse(cmpFeedbackStr))
    : null,
};

const crewChangeMatrixSlice = createSlice({
  name: 'crewChangeMatrix',
  initialState: initialCCMatrix,
  reducers: {
    addScheduleEvents: (
      state,
      action: PayloadAction<ScheduleMatrixEvents | null>
    ) => {
      const { scheduleEvents: prevScheduleEvents } = state;
      return {
        ...state,
        loaded: true,
        scheduleEvents: merge(action.payload || {}, prevScheduleEvents || {}),
      };
    },
    // used for adding & updatng a detailed event
    updateScheduleEvent: (
      state,
      action: PayloadAction<{
        event: CrewChangeEventDetailed;
        currentYear: number; // year of the updated event
      }>
    ) => {
      const { scheduleEvents } = state;
      const { event, currentYear } = action.payload;
      return {
        ...state,
        // use detaled event to find & update matching summary event in store
        scheduleEvents: currentYear
          ? {
              ...(state.scheduleEvents || {}),
              [currentYear]: updateEventInStore(
                scheduleEvents?.[currentYear] || [],
                event
              ),
            }
          : scheduleEvents,
      };
    },
    removeScheduleEvent: (
      state,
      action: PayloadAction<{ eventId: number; currentYear: number }>
    ) => {
      const { scheduleEvents } = state;
      const { eventId, currentYear } = action.payload;
      return {
        ...state,
        scheduleEvents: {
          ...(scheduleEvents || {}),
          [currentYear]: (scheduleEvents?.[currentYear] || []).filter(
            ({ id }) => id !== eventId
          ),
        },
      };
    },
    addOnsignerFeedback: (state, action: PayloadAction<AddFeedbackPayload>) => {
      const { eventId, ...restPayload } = action.payload;
      const currentEventFeedbacks = state.feedback?.[eventId] || [];
      const updatedFeedback = {
        ...(state.feedback || {}),
        [eventId]: uniqBy(
          [{ ...restPayload }, ...currentEventFeedbacks],
          'onsigner.externalCrewId'
        ),
      };
      saveFeedbackToLocalStorage(updatedFeedback);
      return { ...state, feedback: updatedFeedback };
    },
    removeOnsignerFeedback: (
      state,
      action: PayloadAction<{ eventId: number; crewId: string }>
    ) => {
      const { eventId, crewId } = action.payload;
      const currentEventFeedbacks = state.feedback?.[eventId] || [];
      const updatedFeedback = {
        ...(state.feedback || {}),
        [eventId]: currentEventFeedbacks.filter(
          ({ onsigner }) => onsigner.externalCrewId !== crewId
        ),
      };
      saveFeedbackToLocalStorage(updatedFeedback);
      return { ...state, feedback: updatedFeedback };
    },
    addOrgCrewSchedules: (
      state,
      action: PayloadAction<{
        page: number;
        replace?: boolean;
        result: ListCrewScheduleResponse;
      } | null>
    ) => {
      if (!action.payload) return state;

      const { orgCrewSchedule } = state;
      const { page, result, replace } = action.payload;
      const { data: schedules, meta } = result;
      return {
        ...state,
        // keep previous state if `replace` is false
        orgCrewSchedule: {
          meta: { ...((!replace && orgCrewSchedule?.meta) || {}), ...meta },
          data: {
            ...((!replace && orgCrewSchedule?.data) || {}),
            [page]: schedules,
          },
        },
      };
    },
    resetOrgCrewSchedule: (state) => ({
      ...state,
      orgCrewSchedule: null,
    }),
  },
  extraReducers(builder) {
    builder.addCase(
      fetchDataSyncDetailsAsync.fulfilled,
      (state, action: PayloadAction<SyncDetails | null>) => ({
        ...state,
        syncDetails: action.payload,
      })
    );
    builder.addCase(
      fetchCrewMatrixVesselsAsync.fulfilled,
      (state, action: PayloadAction<CCMatrixVessel[]>) => ({
        ...state,
        vessels: action.payload,
      })
    );
    // update vessel schedule events in the background with an API call
    // this is to handle the cases where one event update makes changes to other vessel events
    builder.addCase(
      fetchVesselScheduleEventsAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          vesselId: number;
          events: CrewChangeEventSummary[];
        }>
      ) => {
        const { vesselId, events: incomingVesselEvents } = action.payload;
        // group incoming vessel events by year
        const incomingVesselEventsByYear = groupBy(
          incomingVesselEvents,
          ({ eventDate }) => new Date(eventDate).getFullYear()
        );
        return {
          ...state,
          // replace the events inside store with the events from the payload
          scheduleEvents: mapValues(
            state.scheduleEvents,
            (yearEvents, year) => {
              // partition year events based on vesselId
              const [, otherEvents] = partition(yearEvents, [
                'vessel.id',
                vesselId,
              ]);
              // replace the vessel events with the incoming vessel events
              return sortBy(
                {
                  ...otherEvents,
                  ...incomingVesselEventsByYear[year],
                },
                'eventDate'
              );
            }
          ),
        };
      }
    );
    builder.addCase(
      fetchCrewNotesAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          crewId: string;
          result: CrewNotesResponse | null;
        }>
      ) => {
        const { crewNotes } = state;
        const { crewId, result } = action.payload;
        if (!result) {
          return state;
        }
        const updatedCrewNoteDetails = {
          meta: result.meta,
          data: uniqBy(
            [...(result.data || []), ...(crewNotes?.[crewId]?.data || [])],
            'id'
          ),
        };
        return {
          ...state,
          crewNotes: {
            ...(crewNotes || {}),
            [crewId]: updatedCrewNoteDetails,
          },
        };
      }
    );
    builder.addCase(
      createCrewNoteAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          crewId: string;
          result: CrewNote | null;
        }>
      ) => {
        const { crewNotes } = state;
        const { crewId, result } = action.payload;
        if (!result) {
          return state;
        }
        const { meta, data: savedNotes = [] } = crewNotes?.[crewId] || {};
        const updatedCrewNoteDetails = {
          meta: {
            totalItems: (meta?.totalItems || 0) + 1,
            currentPage: meta?.currentPage || 0,
            totalPages: meta?.totalPages ?? 1,
          },
          data: [result, ...savedNotes],
        };
        return {
          ...state,
          crewNotes: {
            ...(crewNotes || {}),
            [crewId]: updatedCrewNoteDetails,
          },
        };
      }
    );
    builder.addCase(
      deleteCrewNoteAsync.fulfilled,
      (
        state,
        action: PayloadAction<{ crewId: string; noteId: number } | null>
      ) => {
        const { crewNotes } = state;
        if (!action.payload || !crewNotes?.[action.payload.crewId]) {
          return state;
        }
        const { crewId, noteId } = action.payload;
        const { meta, data: savedNotes = [] } = crewNotes[crewId];
        const updatedCrewNoteDetails = {
          meta: {
            totalItems: (meta?.totalItems && meta.totalItems - 1) || 0,
            currentPage: meta?.currentPage || 0,
            totalPages: meta?.totalPages ?? 1,
          },
          data: savedNotes.filter(({ id }) => id !== noteId),
        };
        return {
          ...state,
          crewNotes: {
            ...(crewNotes || {}),
            [crewId]: updatedCrewNoteDetails,
          },
        };
      }
    );
    builder.addCase(
      fetchCrewHistoryAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          crewId: string;
          records: CrewBoardingRecord[];
        }>
      ) => {
        const { crewId, records } = action.payload;
        return {
          ...state,
          crewHistory: {
            ...(state.crewHistory || {}),
            [crewId]: records,
          },
        };
      }
    );
    builder.addCase(
      fetchCrewPersonalInfoAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          crewId: string;
          personalInfo: CrewPersonalInfo | null;
        }>
      ) => {
        const { crewId, personalInfo } = action.payload;
        if (!personalInfo) return state;
        return {
          ...state,
          crewPersonalInfo: {
            ...(state.crewPersonalInfo || {}),
            [crewId]: personalInfo,
          },
        };
      }
    );
  },
});

/* ----- selectors -----*/

// export const selectCrewChangeMatrix = ({ crewChangeMatrix }: RootState) =>
//   crewChangeMatrix;

/* ----- actions -----*/

export const {
  addScheduleEvents,
  updateScheduleEvent,
  removeScheduleEvent,
  addOnsignerFeedback,
  removeOnsignerFeedback,
  addOrgCrewSchedules,
  resetOrgCrewSchedule,
} = crewChangeMatrixSlice.actions;

/* ----- reducer -----*/

export default crewChangeMatrixSlice.reducer;
