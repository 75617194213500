import isBoolean from 'lodash/isBoolean';
import { Dispatch, memo, SetStateAction } from 'react';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_NU_EDIT_ALERT_DELETE,
  TRACK_NU_EDIT_ALERT_DELETE_DISMISS,
  TRACK_NU_EDIT_ALERT_DISMISS,
  TRACK_NU_EDIT_ALERT_SAVE,
  TRACK_SA_EDIT_ALERT_DELETE,
  TRACK_SA_EDIT_ALERT_DELETE_DISMISS,
  TRACK_SA_EDIT_ALERT_DISMISS,
  TRACK_SA_EDIT_ALERT_SAVE,
} from 'utils/analytics/constants';
import { CommonConfirmModal } from 'components/shared';
import { FuncProps } from '../../types';

type Props = {
  actionType: 'update' | 'remove';
  data: any;
  funcProps: FuncProps;
  closeModal: () => void;
  setData?: Dispatch<SetStateAction<any>>;
};

function ConfirmModal({
  actionType,
  data,
  setData,
  funcProps,
  closeModal,
}: Props) {
  const modalDescription = `Do you want to ${actionType} this alert?`;
  const modalTypeProp = actionType === 'remove' ? { type: actionType } : {};

  const handleDiscard = () => {
    const { oldRow, resolve, newRow } = data;
    const modalType = oldRow?.type || newRow?.type;
    if (actionType === 'remove') {
      trackUserAction(
        modalType === 'UPDATE_FILTER'
          ? TRACK_NU_EDIT_ALERT_DELETE_DISMISS
          : TRACK_SA_EDIT_ALERT_DELETE_DISMISS
      );
    } else {
      trackUserAction(
        modalType === 'UPDATE_FILTER'
          ? TRACK_NU_EDIT_ALERT_DISMISS
          : TRACK_SA_EDIT_ALERT_DISMISS
      );
    }
    if (resolve) {
      resolve(oldRow); // Resolve with the old row to not update the internal state
      setData?.(null);
    }
    closeModal();
  };

  const handleConfirm = () => {
    const { removeAlert, updateAlert } = funcProps;
    const { newRow, resolve, checked } = data;
    switch (actionType) {
      case 'remove': {
        trackUserAction(
          newRow.type === 'UPDATE_FILTER'
            ? TRACK_NU_EDIT_ALERT_DELETE
            : TRACK_SA_EDIT_ALERT_DELETE,
          'click',
          newRow.type === 'UPDATE_FILTER'
            ? {
                query: newRow.textFilter,
              }
            : {
                filters: newRow.searchFilters,
              }
        );
        removeAlert(newRow.id);
        break;
      }

      case 'update': {
        trackUserAction(
          newRow.type === 'UPDATE_FILTER'
            ? TRACK_NU_EDIT_ALERT_SAVE
            : TRACK_SA_EDIT_ALERT_SAVE
        );
        const { id, notifyFreqHrs, emailNotificationEnabled } = newRow;
        const request = {
          id,
          notifyFreqHrs,
          emailNotificationEnabled: isBoolean(checked)
            ? checked
            : emailNotificationEnabled,
        };
        updateAlert(request);
        break;
      }

      default:
        break;
    }

    if (resolve) {
      resolve(newRow);
      setData?.(null);
    }
    showToaster({
      message: `Alert ${actionType}d successfully!`,
      type: 'success',
    });
    closeModal();
  };

  return (
    <CommonConfirmModal
      {...modalTypeProp}
      description={modalDescription}
      onCancel={handleDiscard}
      onConfirm={handleConfirm}
    />
  );
}

export default memo(ConfirmModal);
