import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SetStateAction,
  Dispatch,
  MutableRefObject,
} from 'react';
import { featureCollection } from '@turf/helpers';
import type { MapMouseEvent } from 'mapbox-gl';

import { Port } from 'utils/types/crew-change-types';
import { buildPortFeatures } from '../helpers';
import {
  drawCircleLayer,
  mapState,
  removeLayer,
  removeSource,
  setSource,
} from 'map/map';
import { CREW_CHANGE_PROXIMITY_PORT } from 'map/variables';
import { yellowOrange, white } from 'lib/colors';
import { portFromMouseEvent } from 'map/ports';
import { PortRow } from '../types';
import {
  GridRowId,
  gridVisibleSortedRowIdsSelector,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';

type ProximityPortRows = {
  ports: Port[];
  setHoveredRow: Dispatch<SetStateAction<PortRow | null>>;
  hoveredRow: PortRow | null;
  handleExpandRow: (ids: GridRowId[]) => void;
  apiRef: MutableRefObject<GridApiPro>;
};

function ProximityPorts({
  ports,
  setHoveredRow,
  hoveredRow,
  handleExpandRow,
  apiRef,
}: ProximityPortRows) {
  const portFC = useMemo(() => buildPortFeatures({ ports }), [ports]);
  const [isSourceReady, setIsSourceReady] = useState<boolean>(false);

  // Startup Logic
  useEffect(() => {
    setSource(CREW_CHANGE_PROXIMITY_PORT, featureCollection([])).then(() => {
      drawCircleLayer(CREW_CHANGE_PROXIMITY_PORT, CREW_CHANGE_PROXIMITY_PORT, {
        paint: {
          'circle-radius': 5,
          'circle-color': yellowOrange,
          'circle-stroke-color': white,
          'circle-opacity': 0.75,
          'circle-stroke-width': 1,
          'circle-stroke-opacity': 0.65,
        },
      });
      setIsSourceReady(true);
    });
    return () => {
      removeLayer(CREW_CHANGE_PROXIMITY_PORT).then(() => {
        removeSource(CREW_CHANGE_PROXIMITY_PORT);
      });
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!isSourceReady) return;
    setSource(CREW_CHANGE_PROXIMITY_PORT, portFC);
  }, [isSourceReady, portFC]);

  const handleMouseEnter = useCallback(
    (e: MapMouseEvent) => {
      const selectedFeature = portFromMouseEvent(e, CREW_CHANGE_PROXIMITY_PORT);
      if (!selectedFeature) return;
      // Because features will have sub properties transformed into strings, we need to convert back the fields we need
      setHoveredRow({
        ...selectedFeature.feature.properties,
        costs: JSON.parse(selectedFeature.feature.properties?.costs),
      } as PortRow);
      if (mapState.map) mapState.map.getCanvas().style.cursor = 'pointer';
    },
    [setHoveredRow]
  );

  const handleMouseLeave = useCallback(
    (e: MapMouseEvent) => {
      const hasFeature = portFromMouseEvent(e, CREW_CHANGE_PROXIMITY_PORT);
      if (hoveredRow && !hasFeature) {
        setHoveredRow(null);
        if (mapState.map) mapState.map.getCanvas().style.cursor = '';
      }
    },
    [hoveredRow, setHoveredRow]
  );

  const handlePortClick = useCallback(
    (e: MapMouseEvent) => {
      const selectedFeature = portFromMouseEvent(e, CREW_CHANGE_PROXIMITY_PORT);
      if (!selectedFeature) return;
      if (selectedFeature.feature.properties) {
        handleExpandRow([selectedFeature.feature.properties.id]);

        const rowIndex = gridVisibleSortedRowIdsSelector(apiRef).findIndex(
          (id) => id === selectedFeature.feature.properties?.id
        );
        // Scroll to expanded row
        apiRef.current.scrollToIndexes({ rowIndex });
      }
    },
    [handleExpandRow, apiRef]
  );

  // handlers
  useEffect(() => {
    mapState.map?.on('mousemove', CREW_CHANGE_PROXIMITY_PORT, handleMouseEnter);
    mapState.map?.on('mousemove', handleMouseLeave);
    return () => {
      mapState.map?.off(
        'mousemove',
        CREW_CHANGE_PROXIMITY_PORT,
        handleMouseEnter
      );
      mapState.map?.off('mousemove', handleMouseLeave);
    };
  }, [handleMouseEnter, handleMouseLeave, ports]);

  useEffect(() => {
    mapState?.map?.on('click', CREW_CHANGE_PROXIMITY_PORT, handlePortClick);
    return () => {
      mapState?.map?.off('click', CREW_CHANGE_PROXIMITY_PORT, handlePortClick);
    };
  }, [handlePortClick]);

  return null;
}

export default ProximityPorts;
