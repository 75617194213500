import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { selectCrewChangePanel } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';

import DetailsPopup from './DetailsPopup';
import { getLocodeKeyFromPortData } from '../../helpers';
import { PopupPort } from '../../types';

type Props = {
  port: PopupPort;
  compare?: boolean;
};

// Wrapper component passed detail component correct props
// based on planning or read-only view
function PortDetails(props: Props) {
  const { port } = props;
  const { active: activity, readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const { filters, portParams, planningData } = useContext(CCPanelContext);
  const isReportView = activity === 'readOnly';
  // use custom `locodeKey` instead of `port.locode` to get duplicated port flights
  const locodeKey = getLocodeKeyFromPortData(port);

  if (!isReportView) {
    const { crew } = planningData;
    const flights = filters[locodeKey]?.confirmed || [];
    return (
      <DetailsPopup {...props} portDetails={{ portParams, crew, flights }} />
    );
  }

  const { crew = [], flights, portFilters } = reportData?.crewChangePlan || {};

  const portDetails = {
    crew,
    portParams: portFilters!,
    flights: flights?.[locodeKey] || [],
  };

  return <DetailsPopup {...props} portDetails={portDetails} />;
}

export default PortDetails;
