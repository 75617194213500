import delay from 'lodash/delay';
import once from 'lodash/once';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fadedGreen } from 'lib/colors';
import { RootState } from 'redux/types';
import { CCMatrixContext } from 'contexts/CCMatrixContext';
import { CrewChangeEventDetailed } from 'components/CrewMatrixPlanning/types';

const createWorker = createWorkerFactory(
  () => import('../../workers/crew-matrix.worker')
);

const logStatus = (message: string, color: string) => {
  const commonStyle =
    'font-size: 0.7rem; font-weight: bold; font-style: italic; letter-spacing: 1px;';
  console.log(`%cCMP: ${message}`, `color: ${color}; ${commonStyle}}`);
};

let attempt = 0;
let socketRef: WebSocket | null = null;

function useCMPWebSocket() {
  const worker = useWorker(createWorker);
  const isAuthed = useSelector(({ authed }: RootState) => authed.isAuthed);
  const { id: userId } =
    useSelector(({ settings }: RootState) => settings.userInfo) || {};

  const { currentEvent, confirmEventUpdate } = useContext(CCMatrixContext);

  const handleUpdateEvent = useCallback(
    (event: CrewChangeEventDetailed) => {
      // only update user's current event
      if (currentEvent?.id === event.id) {
        // trigger a confirmation modal to inform user
        const confirmEventUpdateForWS = once(confirmEventUpdate);
        confirmEventUpdateForWS(event, 'websocket');
      }
    },
    [currentEvent, confirmEventUpdate]
  );

  const connectCMPWebSocket = useCallback(async () => {
    if (!isAuthed || !userId || socketRef) {
      return;
    }

    logStatus('Connecting to WebSocket...', fadedGreen);

    const webSocket = await worker.connectWS({
      userId,
      callback: handleUpdateEvent,
    });

    if (!webSocket) {
      socketRef = null;
      return;
    }

    webSocket.onopen = () => {
      attempt = 0;
    };

    webSocket.onclose = (event: CloseEvent) => {
      switch (true) {
        case event.code === 1000:
          // let the Websocket close normally
          logStatus('WebSocket connection closed.', 'red');
          return;

        case attempt >= 10:
          logStatus(
            'WebSocket connection failed. Max attempts reached.',
            'red'
          );
          return;

        default: {
          const intervalSeconds = 2 ** (attempt + 1);
          logStatus(
            `WebSocket connection failed. Retrying in ${intervalSeconds}s.`,
            'orange'
          );
          delay(connectCMPWebSocket, intervalSeconds * 1000);
        }
      }
    };

    webSocket.onerror = () => {
      attempt++;
      if (webSocket) {
        webSocket.close();
        socketRef = null;
      }
    };

    socketRef = webSocket;
  }, [isAuthed, userId, worker, handleUpdateEvent]);

  useEffect(() => {
    return () => {
      if (socketRef) {
        socketRef.close(1000);
        socketRef = null;
      }
    };
  }, []);

  return { connectCMPWebSocket };
}

export default useCMPWebSocket;
