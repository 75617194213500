import { AllSearchableFieldsMetadata } from '@greywing-maritime/frontend-library/dist/utils/search-engine/search-engine-types';
import { searchableVesselFields } from '@greywing-maritime/frontend-library/dist/utils/search-engine/vessels-search-config';
import { sliderQueryCommands } from 'redux/helpers/flotillaSearch';
import {
  customSearchableFields,
  quickFlySearchDetails,
} from 'components/FlotillaSearch/helpers';

export const AllSearchableFieldsMetaData: AllSearchableFieldsMetadata = {
  ...quickFlySearchDetails, // show QuickFly search at the top
  ...searchableVesselFields,
  ...customSearchableFields,
};

export const MappedMetaDataCommand = Object.keys(
  AllSearchableFieldsMetaData
).reduce((acc, field) => {
  const { command } = AllSearchableFieldsMetaData[field];
  if (command) {
    acc[command] = field;
  }
  return acc;
}, {} as { [key: string]: string });

export const ETA_SLIDER_COMMANDS = Object.keys(sliderQueryCommands);
export const ALL_COMMANDS_STR = Object.keys(MappedMetaDataCommand);
