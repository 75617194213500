import { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { selectCrewChangePanel, selectMapVessels } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { RouteCalculatorContext } from 'contexts/RouteCalculatorContext';
import {
  formatPortDates,
  getPortETA,
} from 'components/CrewChangePanel/helpers';
import { AddPortDatesAndDeviation } from 'components/CrewChangePanel/types';

function usePortUtils() {
  const { getVesselRoute, getDeviation } = useContext(RouteCalculatorContext);
  const {
    route: vesselRoute,
    planningData: { route },
  } = useContext(CCPanelContext);
  const { vesselId } = useSelector(selectCrewChangePanel);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);

  const vessel = vessels.get(vesselId!);
  const vesselCoords: [number, number] = [vessel!.lng, vessel!.lat];

  const addPortDatesAndDeviation: AddPortDatesAndDeviation = async (ports) => {
    const promises = ports.map(async (port) => {
      const routePort = route.find((p) => p.port?.locode === port.locode);

      if (routePort) {
        const { eta, etd } = routePort;
        const newDates = formatPortDates({ eta, etd });
        return { ...port, ...newDates };
      }

      const portETAProps = {
        port,
        coords: vesselCoords,
        getVesselRoute,
      };

      const eta = await getPortETA(portETAProps);
      const newDates = formatPortDates({ eta });
      const newDeviation = getDeviation({
        existingPath: vesselRoute!.path.coordinates,
        newPosition: [port.lng, port.lat],
        units: 'nauticalmiles',
      });

      const hourDifference = newDeviation.deviationDistance
        ? moment
            .duration(
              newDeviation.deviationDistance / (port.calcSpeed || 13),
              'hours'
            )
            .asHours()
        : 0;

      return {
        ...port,
        ...newDates,
        calculatedDistance: port.isPartOfRoute
          ? 0
          : newDeviation.deviationDistance,
        deviationTimeDifference: port.isPartOfRoute ? 0 : hourDifference,
      };
    });

    return Promise.all(promises);
  };

  return { addPortDatesAndDeviation };
}

export default usePortUtils;
