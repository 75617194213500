import { useSelector } from 'react-redux';
import { useAssistedCrewChanges } from '../components/SeaGPT/hooks';
import { RootState } from 'redux/types';

function useInboxHandler() {
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);
  const hasAccess = userInfo?.access['SeaGPT'] || false;
  useAssistedCrewChanges(hasAccess);

  return null;
}

export default useInboxHandler;
