import head from 'lodash/head';
import last from 'lodash/last';
import isNumber from 'lodash/isNumber';
import {
  COMMAND_REGEX,
  SearchableFieldMetadata,
} from '@greywing-maritime/frontend-library/dist/utils/search-engine/search-engine-types';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { searchableVesselFields } from '@greywing-maritime/frontend-library/dist/utils/search-engine/vessels-search-config';
import { SearchEngine } from '@greywing-maritime/frontend-library/dist/utils/search-engine/search-engine';

import { FlightResultWithCache } from 'utils/types';
import { FareType } from 'components/CrewChangePanel/types';
import { ResultFilters } from 'components/FlotillaSearch/types';
import {
  QuickFlySearchParams,
  SliderQueryCommands,
  SliderQueryConfig,
} from '../types';

export const DEFAULT_FLIGHT_RESULT: FlightResultWithCache = {
  flights: [],
  fromCache: false,
};

export const INITIAL_TIME_RANGE: [number, number] = [0, 24];
export const initialTimeAndStopsCount = {
  min: { stopsCount: 0, layoverTime: 0, flightTime: 0 },
  max: null,
};

export const initialResultFilters: ResultFilters = {
  type: 'Cheapest',
  time: 24,
  layover: [2, 8],
  stopsCount: 1,
  source: 'ALL',
  fareType: FareType.marine,
  airlines: [],
  selectedStops: [],
  arrivalTime: INITIAL_TIME_RANGE,
  departureTime: INITIAL_TIME_RANGE,
  range: initialTimeAndStopsCount,
  allowAirportTransfer: false,
};

// initialize filters in QuickFly feature from crew change related flight settings
// to make sure QuickFly filters align with crew change flight settings
export const initQuickFlyFiltersFromSettings = (
  flightSearchParams: QuickFlySearchParams
) => {
  const {
    time,
    layover,
    stops: stopsCount,
    allowAirportTransfer,
    quickFlyTMC,
  } = flightSearchParams;
  return {
    ...initialResultFilters,
    time,
    layover,
    stopsCount,
    allowAirportTransfer,
    source: quickFlyTMC,
  };
};

export const parseFlightSearchQuery = (searchQuery: string) => {
  const queryArr = searchQuery.split(' ');
  return {
    departure: (head(queryArr) || '').toUpperCase(),
    arrival: (last(queryArr) || '').toUpperCase(),
  };
};

export const sliderQueryCommands: SliderQueryCommands = {
  eta: {
    sliderPrefix: 'eta',
    searchFieldId: 'secondsToNextPort',
    sortFunc: (a: Vessel, b: Vessel) => {
      if (isNumber(a.secondsToNextPort) && isNumber(b.secondsToNextPort)) {
        return a.secondsToNextPort - b.secondsToNextPort;
      }
      return 1;
    },
  },
  eventeta: {
    sliderPrefix: 'event',
    searchFieldId: 'upcomingCrewChangeEvents',
    sortFunc: (a: Vessel, b: Vessel) => {
      const a0 = a.upcomingCrewChangeEvents
        .filter((e) => e.eta && !isNaN(new Date(e.eta).getTime()))
        .map(
          (e) => (new Date(e.eta!).getTime() - new Date().getTime()) / 1000
        )[0];

      const b0 = b.upcomingCrewChangeEvents
        .filter((e) => e.eta && !isNaN(new Date(e.eta).getTime()))
        .map(
          (e) => (new Date(e.eta!).getTime() - new Date().getTime()) / 1000
        )[0];

      return a0 - b0;
    },
  },
};

// filter vessels for ETA search
export function filterETASearchVessels(
  term: string,
  vessels: Map<number, Vessel>,
  sliderQueryConfig: SliderQueryConfig,
  searchEngine: SearchEngine
) {
  const commandMatch = term.match(COMMAND_REGEX);
  const command = commandMatch?.length ? commandMatch[1] : null;

  const query = !command || !commandMatch ? term : commandMatch[2];

  const selectedTime = Number(query.trim());
  let results: Vessel[] = [];
  let timeTaken = performance.now();

  const filteredVesselIds = searchEngine.filterNumericValsEntityIds(
    vessels,
    query,
    sliderQueryConfig.searchFieldId
  );

  vessels.forEach((v) => {
    if (filteredVesselIds.includes(v.id)) {
      results.push(v);
    }
  });

  const searchFieldId = sliderQueryConfig.searchFieldId;
  const searchableVesselField: SearchableFieldMetadata | undefined =
    searchableVesselFields[searchFieldId];

  return [
    {
      searchFieldName: searchFieldId,
      searchFieldDescription:
        searchableVesselField?.searchConfig?.searchFieldDescription || '',
      searchResultDescFn: searchableVesselField?.countDescFunc,
      filterLabel: searchableVesselField?.countDescFunc(
        0,
        String(selectedTime),
        true
      ),
      priority: 1,
      results: async () => {
        return {
          results: results.sort(sliderQueryConfig.sortFunc).map((vessel) => ({
            item: {
              idField: vessel.id,
              [searchFieldId]: vessel[searchFieldId],
            },
            matches: [],
          })),
          timeTaken: performance.now() - timeTaken,
        };
      },
    },
  ];
}
