import type {
  Expression,
  GeoJSONSource,
  MapMouseEvent,
  MapboxGeoJSONFeature,
  Source,
  StyleFunction,
  SymbolLayer,
  SymbolLayout,
  SymbolPaint,
} from 'mapbox-gl';
import { Position } from '@turf/turf';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import * as Sentry from '@sentry/react';

import { VesselFieldId } from 'utils/types';
import {
  getFieldHighlight,
  getLabellableFields,
  labelFields,
  stringifyFieldValue,
} from 'utils/vesselFields';
import { getVesselSvg, VesselFieldHighlight } from 'utils/highlight-vessels';
import haversine from 'utils/haversine';
import { mapState, runWhenIdle } from './map';

/**
 * Default settings for a vessel layer
 * @param paintProps
 * @param layoutProps
 * @param id
 * @param iconImage
 * @param sourceId
 * @returns SymbolLayer
 */
function getVesselLayer(
  paintProps: Partial<SymbolPaint>,
  layoutProps: Partial<SymbolLayout>,
  id: string,
  iconImage: string | StyleFunction | Expression,
  sourceId?: string
): SymbolLayer {
  return {
    id: id,
    type: 'symbol',
    source: sourceId ? sourceId : 'vessels',
    paint: {
      ...paintProps,
    },
    layout: {
      'icon-image': iconImage,
      'icon-rotate': ['get', 'course'],
      ...layoutProps,
    },
  };
}

export async function loadVesselImages(
  colors: string[],
  backgroundColor?: string,
  logoColor?: string
): Promise<(string | false)[] | false> {
  if (!mapState.map) return false;
  const { map } = mapState;
  return await Promise.all<string | false>(
    Array.from(colors).map(
      (color) =>
        new Promise((resolve) => {
          const imageId = `vessel-${color}${
            backgroundColor ? `-${backgroundColor}` : ''
          }${logoColor ? `-${logoColor}` : ''}`;
          if (map.hasImage(imageId)) {
            resolve(imageId);
            return;
          }

          const img = new Image();
          img.onload = () => {
            /**
             * For some reason the preceeding function doesn't detect the image in mapbox
             * but it exists here. This happens during hot reload.
             */
            if (map.hasImage(imageId)) {
              resolve(imageId);
              return;
            }
            map.addImage(imageId, img);
            resolve(imageId);
          };
          img.src = URL.createObjectURL(
            new Blob([getVesselSvg(color, backgroundColor, logoColor)], {
              type: 'image/svg+xml',
            })
          );
          img.onerror = () => {
            resolve(false);
          };
        })
    )
  );
}

/**
 * Check if cursor is within range of a vessel on a specific source
 * @param e
 * @param sourceId
 * @returns
 */
export function vesselFromMouseEvent(
  e: MapMouseEvent,
  sourceId: string = 'vessels'
) {
  // This seems to cause issues when logging out if not included.
  if (!mapState.map) return;
  const features = mapState.map!.queryRenderedFeatures(e.point);
  const target = e.lngLat;
  let current: {
    feature: MapboxGeoJSONFeature;
    distance: number;
  } | null = null;
  for (const v of features) {
    if (v.source !== sourceId) {
      continue;
    }

    const coords: number[] = (v.geometry as any).coordinates;
    const point = { lng: coords[0], lat: coords[1] };
    const distance = haversine(point, target);
    if (current === null) {
      current = {
        feature: v,
        distance: distance,
      };
    } else {
      if (distance < current.distance) {
        current = {
          feature: v,
          distance: distance,
        };
      }
    }
  }
  return current;
}

/**
 * Check if cursor is within range of a vessel on specified layers
 * @param e
 * @param layerIds
 * @returns
 */

export function queryFeatureAtLayer(e: MapMouseEvent, layerIds: string[]) {
  if (!mapState.map) return;
  const { map } = mapState;
  const isReady = layerIds.reduce((isReady, layer) => {
    if (!map.getLayer(layer)) {
      return false;
    }
    return isReady;
  }, true);
  if (!isReady) return;

  const features = map.queryRenderedFeatures(e.point, {
    layers: layerIds,
  });

  const target = e.lngLat;
  if (!features.length) return;
  const selectedFeature = features.reduce<{
    feature: MapboxGeoJSONFeature;
    distance: number;
  } | null>((selected, feature) => {
    const distance = haversine(
      {
        lng: (feature.geometry as any).coordinates[0],
        lat: (feature.geometry as any).coordinates[1],
      },
      target
    );
    if (!selected) {
      return { feature, distance };
    } else {
      if (distance < selected.distance) {
        return { feature, distance };
      }
    }
    return selected;
  }, null);
  return selectedFeature;
}
/**
 * Defines geojson source for vessels on the map
 * @param sourceId
 * @param fc
 * @returns
 */

// As this is
export function setVesselsSource(
  sourceId: string,
  fc: GeoJSON.FeatureCollection
) {
  return new Promise<Source>((res) => {
    runWhenIdle((map) => {
      const source = map.getSource(sourceId);
      if (!source) {
        // console.log(`ADDING ${sourceId} SOURCE`, fc);
        map.addSource(sourceId, {
          type: 'geojson',
          data: fc,
          promoteId: 'id',
        });
      } else {
        (source as GeoJSONSource).setData(fc);
      }
      res(map.getSource(sourceId));
    });
  });
}

/**
 * In order for the large vessel symbol to always be in front of the small vessel AND
 * for the vessel name labels to not overlap one another AND for the vessel icons to still overlap
 * each other between each other within the same layer (small to small) the following must be done
 * 1. Small layer only renders the icon
 * 2. Large layer renders both icon and text label
 * 3. The text label must always be on
 */
export function createSmallVesselStyle(
  sourceId: string,
  layerId: string
): SymbolLayer {
  const iconImage: string | StyleFunction | Expression = ['get', 'icon'];

  return getVesselLayer(
    {
      'icon-opacity': [
        'case',
        ['boolean', ['coalesce', ['feature-state', 'hover'], false], true],
        0,
        ['boolean', ['get', 'greyed'], true],
        0.25,
        1,
      ],
    },
    {
      'icon-size': 0.4,
      'icon-allow-overlap': true,
    },
    layerId,
    iconImage,
    sourceId
  );
}

export function createLargeVesselStyle(
  sourceId: string,
  layerId: string
): SymbolLayer {
  const iconImage: string | StyleFunction | Expression = ['get', 'icon'];

  return getVesselLayer(
    {
      'text-opacity': 1,
      'icon-opacity': [
        'case',
        ['boolean', ['coalesce', ['feature-state', 'hover'], false], true],
        1,
        ['boolean', ['get', 'highlighted'], true],
        1,
        0,
      ],
      'text-color': '#fff',
      'text-halo-color': [
        'case',
        ['==', ['get', 'selected'], 1],
        'hsla(224, 54%, 20%, 0.8)',
        'hsla(224, 54%, 45%, 0.8)',
      ],
      'text-halo-width': 2.25,
    },
    {
      'icon-size': 0.6,
      'icon-allow-overlap': true,
      'text-padding': 0,
      'text-optional': true,
      'text-field': ['get', 'label'],
      'text-radial-offset': 1,
      'text-ignore-placement': true,
      'text-allow-overlap': false,
      'text-variable-anchor': [
        'top',
        'top-left',
        'top-right',
        'bottom',
        'bottom-left',
        'bottom-right',
      ],
      'text-font': ['literal', ['HK Grotesk Medium', 'Roboto Medium']],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        ['case', ['==', ['get', 'selected'], 1], 13, 12],
        6,
        ['case', ['==', ['get', 'selected'], 1], 17, 16],
      ],
    },
    layerId,
    iconImage,
    sourceId
  );
}

export function drawVessels(
  sourceId: string,
  layerId: string,
  styles?: Partial<SymbolLayer>,
  beforeId?: string
) {
  if (!mapState.map) return;
  const { map } = mapState;
  // runWhenIdle((map) => {
  if (map.getSource(sourceId) && !map.getLayer(layerId)) {
    map.addLayer(
      {
        id: layerId,
        source: sourceId,
        type: 'symbol',
        ...styles,
      },
      beforeId
    );
  }
  // });
}

export function vesselColorBuilder(
  vessels: Map<number, Vessel>,
  colorHighlightField: VesselFieldId | null
) {
  let vesselHighlight: VesselFieldHighlight<any> | null = null;
  try {
    vesselHighlight = colorHighlightField
      ? getFieldHighlight(colorHighlightField, Array.from(vessels.values()))
      : null;
  } catch (err) {
    Sentry.captureException(err);
  }

  if (colorHighlightField && !vesselHighlight) {
    Sentry.captureException(
      new Error(
        `No VesselFieldHighlight found for '${colorHighlightField}'. Falling back to default color.'`
      )
    );
  }

  let vesselColors: { id: number; color: string }[] = [];
  if (vesselHighlight) {
    try {
      // If this is not present, compiler complains about vesselHighlight possibly being null when calling getHighlightColor().
      const highlight = vesselHighlight;
      vesselColors = Array.from(vessels.values()).map((vessel) => {
        let color = highlight.getHighlightColor(vessel);
        if (color) {
          return {
            id: vessel.id,
            color: color.replace('#', ''),
          };
        } else {
          throw new Error(
            `color is empty after attempting to highlight by field ${colorHighlightField} for vessel ${vessel.id}.`
          );
        }
      });
      return vesselColors;
    } catch (err) {
      Sentry.captureException(err);
      console.log(
        `Error highlighting by field ${colorHighlightField}. Falling back to default color.`
      );
      return [];
    }
  }
  return [];
}

export async function buildBaseVesselFC(
  vessels: Map<number, Vessel>,
  vesselColors: { id: number; color: string }[],
  vesselLabelField: string | null,
  highlightedVesselIds: number[],
  zoomVessel: boolean
): Promise<GeoJSON.FeatureCollection> {
  // Build features with color and highlight parameters
  return {
    type: 'FeatureCollection',
    features: Array.from(vessels).map<GeoJSON.Feature>(([vesselId, vessel]) => {
      const color = vesselColors.find((color) => color.id === vesselId);
      const highlighted = highlightedVesselIds.includes(vesselId as number);
      const highlightWithoutZoom = !zoomVessel && highlighted;
      return {
        type: 'Feature',
        id: vesselId,
        properties: {
          id: vesselId,
          name: vessel.name,
          course: vessel.course,
          vesselId: vesselId,
          icon: color ? `vessel-${color.color}` : 'vessel-large',
          greyed: highlightWithoutZoom
            ? false
            : Boolean(highlightedVesselIds.length), // always grey out unfiltered vessels for ETA search
          highlighted: highlightWithoutZoom
            ? false
            : !!(highlightedVesselIds.length && highlighted),
          label:
            vesselLabelField && getLabellableFields().includes(vesselLabelField)
              ? stringifyFieldValue(vessel, labelFields[vesselLabelField])
              : null,
        },
        geometry: {
          type: 'Point',
          coordinates: [vessel.lng, vessel.lat],
        },
      };
    }),
  };
}

export function setWaypointMarkerSource(
  sourceId: string,
  fc: GeoJSON.FeatureCollection
) {
  return new Promise<Source>((res) => {
    runWhenIdle((map) => {
      const source = map.getSource(sourceId);
      // console.log('ADDING WAYPOINT SOURCE', fc);
      if (!source) {
        map.addSource(sourceId, {
          type: 'geojson',
          data: fc,
        });
      } else {
        (source as GeoJSONSource).setData(fc);
      }
      res(map.getSource(sourceId));
    });
  });
}

export function drawWaypointMarker(
  sourceId: string,
  layerId: string,
  styles?: Partial<SymbolLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map.getSource(sourceId) && !map.getLayer(layerId)) {
      map.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'symbol',
          ...styles,
        },
        beforeId
      );
    }
  });
}

export const generateVesselFeature = (
  id: string,
  coordinates: Position,
  properties?: { [key: string]: any }
): GeoJSON.Feature => {
  return {
    id,
    type: 'Feature',
    properties: {
      id,
      ...properties,
    },
    geometry: {
      type: 'Point',
      coordinates,
    },
  };
};
