import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UPCOMING_BOOKING_STATES } from 'components/shared/FlightBook/components/Header';
import { BookingTransaction } from 'components/shared/FlightBook/types';

import { Flight } from 'utils/types/crew-change-types';
import {
  BookingMetaData,
  BookingState,
  BookingTransactionMap,
  RootState,
} from '../types';

// This is shared between both
export const initialBooking: BookingState = {
  flight: null,
  bookingModalIsActive: false,
  manageBookingModalIsActive: false,
  bookings: {},
  bookingList: [],
  manageBookingMetaData: {
    totalCount: 0,
    hasNextPage: undefined,
  },
  manageBookingFilters: {
    states: UPCOMING_BOOKING_STATES,
    pageSize: 5,
    departureTimeSort: undefined,
    label: 'Upcoming Bookings',
  },
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState: initialBooking,
  reducers: {
    setManageBookingMetaData: (
      state,
      action: PayloadAction<BookingMetaData>
    ) => ({
      ...state,
      manageBookingMetaData: action.payload,
    }),
    startFlightBooking: (state, action: PayloadAction<Flight>) => ({
      ...state,
      flight: action.payload,
      bookingModalIsActive: true,
    }),
    closeBookingModal: (state) => ({
      ...state,
      flight: null,
      bookingModalIsActive: false,
    }),
    handleManageBookingModal: (state, action: PayloadAction<boolean>) => ({
      ...state,
      manageBookingModalIsActive: action.payload,
    }),
    updateExistingBooking: (
      state,
      action: PayloadAction<BookingTransaction>
    ) => ({
      ...state,
      bookings: {
        ...state.bookings,
        [action.payload.id]: action.payload,
      },
    }),
    updateBookings: (
      state,
      action: PayloadAction<{
        bookings: BookingTransactionMap;
        bookingList: string[];
      }>
    ) => ({
      ...state,
      bookings: {
        ...state.bookings,
        ...action.payload.bookings,
      },
      bookingList: action.payload.bookingList,
    }),
    updateBookingFilters: (
      state,
      action: PayloadAction<{ field: string; value: any }>
    ) => ({
      ...state,
      manageBookingFilters: {
        ...state.manageBookingFilters,
        [action.payload.field]: action.payload.value,
      },
    }),
  },
});

/* ----- selectors -----*/

export const selectBooking = ({ booking }: RootState) => booking;

export const selectBookingFilters = ({ booking }: RootState) =>
  booking.manageBookingFilters;

export const selectBookingWithTransactionId = createSelector(
  [
    (state: RootState) => state.booking.bookings,
    (state: RootState, transactionId: string) => transactionId,
  ],
  (bookings, transactionId) => bookings[transactionId] || null
);

/* ----- actions -----*/

export const {
  startFlightBooking,
  closeBookingModal,
  handleManageBookingModal,
  updateExistingBooking,
  updateBookings,
  updateBookingFilters,
  setManageBookingMetaData,
} = bookingSlice.actions;

/* ----- reducer -----*/

export default bookingSlice.reducer;
