import { filter, find, some, uniqBy } from 'lodash';
import { RefObject, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { JourneyPortVisualCard } from 'components/SidePanel/VesselCourse/types';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';
import { selectVesselById } from 'redux/reducers/mapVessels';
import { selectSinglePortCall } from 'redux/reducers/portCalls';
import { selectCrewChangePanel, selectPreferredPorts } from 'redux/selectors';
import { RootState } from 'redux/types';

type Props = {
  vesselId?: number; // available when used in side-panel
  buttonRef?: RefObject<HTMLButtonElement>; // available when used in crew0change panel
};

// hook used in both crew-change panel & side-panel
function useOneClickPlan({ vesselId, buttonRef }: Props) {
  // availability of context is dependent on where it is used
  const { getVesselJourney } = useContext(SidePanelFieldContext);

  const { isOneClickPlan, active, event } = useSelector(selectCrewChangePanel);
  const preferredPorts = useSelector(selectPreferredPorts);
  const vessel = useSelector((state: RootState) =>
    vesselId ? selectVesselById(state, vesselId) : null
  );
  const selectedPortCall = useSelector((state: RootState) =>
    vesselId ? selectSinglePortCall(state, vesselId) : null
  );

  const { locode } = event || {};
  const { disabled, textContent: nextButtonText } = buttonRef?.current || {};

  // used in side-panel
  const isOneClickAvailable = useMemo(() => {
    // early return for unavailable values & to prevent use in crew-change panel
    if (isOneClickPlan || active === 'plan' || !vessel || !selectedPortCall) {
      return false;
    }

    // filter out vessel's current location from future route
    const allFuturePorts = filter(getVesselJourney(vessel, selectedPortCall), [
      'componentType',
      'port',
    ]) as JourneyPortVisualCard[];
    const allowedPorts = uniqBy(
      [...(preferredPorts || []), ...(locode ? [{ locode, name: '' }] : [])],
      'locode'
    );
    // find if any of the allowedPorts is in the future route
    return some(allFuturePorts, ({ port }) =>
      find(allowedPorts, ['locode', port.locode])
    );
  }, [
    active,
    locode,
    isOneClickPlan,
    vessel,
    preferredPorts,
    selectedPortCall,
    getVesselJourney,
  ]);

  // used in crew-change panel
  useEffect(() => {
    if (!isOneClickPlan) return;

    if (buttonRef && !disabled && nextButtonText !== 'Share Planning Data') {
      setTimeout(() => {
        buttonRef.current?.click?.();
      }, 1000);
    }
  }, [isOneClickPlan, disabled, nextButtonText, buttonRef]);

  return { isOneClickAvailable };
}

export default useOneClickPlan;
