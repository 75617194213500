import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { trackUserAction } from 'lib/amplitude';
import { searchPortsForRestrictions } from 'api/flotilla';
import { TRACK_SEARCH_PORT_RESTRICTIONS_INPUT } from 'utils/analytics/constants';
import { ParsedSearchType, PortRestrictionSearchResult } from 'utils/types';

type PortRestrictionSearchType = {
  params: ParsedSearchType | null;
};

function usePortRestrictionSearch({ params }: PortRestrictionSearchType) {
  const [results, setResults] = useState<PortRestrictionSearchResult | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { search: query = '' } = params?.meta || {};

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (searchText: string) => {
      if (!searchText) {
        return;
      }

      setLoading(true);

      const { results = [] } = await searchPortsForRestrictions(searchText);
      setResults({
        searchType: 'portRestrictions',
        searchFieldName: 'restrictions',
        searchFieldDescription: '',
        priority: 10,
        filterLabel: 'port restrictions for',
        results,
      });
      trackUserAction(TRACK_SEARCH_PORT_RESTRICTIONS_INPUT, 'typed', {
        query: searchText,
      });
      setLoading(false);
    }, 300),
    []
  );

  useEffect(() => {
    const searchText = query.trim();
    debouncedSearch(searchText)?.then(() => {
      // empty result if search input is cleared
      if (!searchText) {
        setResults(null);
      }
    });
  }, [query, debouncedSearch]);

  return { results, isLoading: loading };
}

export default usePortRestrictionSearch;
