import partition from 'lodash/partition';
import moment from 'moment';
import {
  BaggageInfo,
  FlightSegment,
  Layover as FlightStop,
} from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

import { blue, green } from 'lib/colors';
import { removeWhiteSpace, secondsToDHM } from 'lib/common';
import { Flight } from 'utils/types/crew-change-types';
import {
  HotelsResponse,
  HotelsSearchResult,
  PortCallResponseMap,
} from 'utils/types';
import { RestrictionResults as HotelsPort } from 'utils/types/search';
import { initialTimeAndStopsCount } from 'redux/helpers/flotillaSearch';
import {
  FlightNumberDetails,
  FlightSearch,
  FlotillaSearchOption,
  RecentSearches,
} from 'redux/types';
import {
  CustomFilterValues,
  FlightParamsRange,
  FlightFilters,
  ReadOnlyFlightFilters,
} from 'components/CrewChangePanel/types';
import {
  allAirlinesSelected,
  allLayoversSelected,
  filterFlightFareType,
  filterFlightForAirportTransfer,
  filterFlightSchedules,
  flightSortUtils,
  getFlightResultsModalWidth,
  getLayovers,
  prepareCustomFilterRange,
  prepareTimeRange,
} from 'components/CrewChangePanel/helpers';
import { FlightStepDetails, LayoverDetails, ResultFilters } from './types';
import { SearchFilter } from '@greywing-maritime/frontend-library/dist/types/alerts';
import { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { plannedPortSearchResults } from './components/PlannedPortSearch/helpers';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

type CustomSearchMetaData = {
  [type: string]: {
    shortDesc: string;
    longDesc: string;
    label: string;
  };
};

const generateCountDescFunc =
  (prescript: string) =>
  (count: number, term: string, representative?: boolean) =>
    representative ? prescript : `${count > 1 ? 's' : ''} ${prescript} ${term}`;

export const OtherCommands = [
  'restrictions',
  'quickfly',
  'hotels',
  'futureport',
  'port',
];

export const quickFlySearchDetails = {
  searchFlights: {
    command: 'quickfly',
    commandDescription: 'Enter arrival & departure airports',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'Flights',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Search flights'),
  },
};

export const customSearchableFields = {
  futureport: {
    command: 'futureport',
    commandDescription: 'Enter port LOCODE from voyage plan',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'Voyage Plan Port LOCODE',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Voyage plan port'),
  },
  portRestrictions: {
    command: 'restrictions',
    commandDescription: 'Enter a LOCODE',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'LOCODE',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Port restrictions'),
  },
  hotelSearch: {
    command: 'hotels',
    commandDescription: 'Enter port name or LOCODE',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'LOCODE',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Hotels search'),
  },
  deviation: {
    command: 'deviation',
    commandDescription: 'Click on map to add waypoints',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'Distance between waypoints',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Measure distance'),
  },
  portMap: {
    command: 'port',
    commandDescription: 'Enter a LOCODE or Port Name',
    searchConfig: {
      searchPriority: 1,
      searchFieldDescription: 'Port',
      indexTransformer: undefined,
    },
    countDescFunc: generateCountDescFunc('Port'),
  },
};

export const customSearchMetaData: CustomSearchMetaData = {
  futureport: {
    shortDesc: '',
    longDesc: 'Upcoming port calls',
    label: 'search voyage plan port for',
  },
  portRestrictions: {
    shortDesc: 'Port Restrictions',
    longDesc: 'Port Restrictions Data',
    label: 'port restrictions for',
  },
  searchFlights: {
    shortDesc: 'Search Flights',
    longDesc: 'Search Flights',
    label: 'search flights for',
  },
  hotelSearch: {
    shortDesc: 'Search Hotels',
    longDesc: 'Search Nearby Hotels',
    label: 'search hotels for',
  },
  deviation: {
    shortDesc: 'Measure Distance',
    longDesc: 'Distance between waypoints',
    label: 'add waypoints',
  },
  portMap: {
    shortDesc: 'Port',
    longDesc: 'Search all Ports',
    label: 'search port for',
  },
};

/* ----- helpers for flight search ----- */

const isLayoverExpanded = (
  stopsCount: number,
  isMobile?: boolean,
  isModal?: boolean
) => {
  // in mobile view, no stop is expanded
  // in other views (e.g - QuickFly), one stop is expanded
  if (isMobile) return false;
  // QuickFly view
  if (!isModal) return stopsCount === 1;

  const modalWidth = getFlightResultsModalWidth();
  // results modal view in CC panel
  switch (true) {
    case modalWidth <= 620:
      return stopsCount === 1;
    case modalWidth <= 768:
      return stopsCount === 2;
    default:
      return true;
  }
};

// get the details for layover time & tooltip content
export const getLayoverDetails = (
  stop: FlightStop,
  segments: FlightSegment[]
): LayoverDetails => {
  const { airportCode, depAirportCode = '', layoverMs } = stop;
  const { arrivalTime, departureTime } = segments.reduce(
    (acc, segment) => {
      const { origin, dest } = segment;
      if (dest.iata === airportCode) {
        return { ...acc, arrivalTime: dest.time };
      }
      if ([airportCode, depAirportCode].includes(origin.iata)) {
        return { ...acc, departureTime: origin.time };
      }
      return acc;
    },
    {
      arrivalTime: '',
      departureTime: '',
    }
  );
  return {
    arrival: { iataCode: airportCode, time: arrivalTime },
    departure: { iataCode: depAirportCode || airportCode, time: departureTime },
    layoverTime: secondsToDHM(layoverMs / 1000),
  };
};

export const formatBaggageInfo = (baggage?: BaggageInfo) => {
  if (!baggage) {
    return null;
  }
  const { weight, unit, numPieces } = baggage;
  return weight ? `${weight} ${unit}` : `${numPieces} bag(s)`;
};

// create uniform details of each segement of the flight
// `duration` field currently indicates the time required to reach destination - the segment where `duration` is added
export const getFlightSteps = (
  flight: Flight,
  isMobile?: boolean,
  isModal?: boolean
): FlightStepDetails[] => {
  const { airports, arrival, departure, segments, stops } = flight;
  return [
    // Departure airport details
    {
      type: 'Departure',
      iconDetails: { name: FlightTakeoffIcon, color: blue },
      iataCode: departure.airportCode,
      city: airports[departure.airportCode]?.municipality || '',
      time: departure.time,
      departureFlightNumber: removeWhiteSpace(segments[0].flightNumber),
      expanded: true,
      baggage: formatBaggageInfo(segments[0].baggage),
    },
    // LAYOVERS
    ...stops.map((stop, index) => {
      const { airportCode, depAirportCode = '' } = stop;
      const layoverDetails = getLayoverDetails(stop, segments);
      // find the correct layover airport from `airports` field
      const layoverAirport = airports[airportCode] || airports[depAirportCode];
      const layoverDisplayCode =
        (airports[airportCode] &&
          airports[depAirportCode] &&
          `${airportCode}-${depAirportCode}`) ||
        layoverAirport?.iataCode;

      return {
        type: `Layover 0${index + 1}`,
        iconDetails: undefined,
        iataCode: layoverDisplayCode || '',
        city: layoverAirport?.municipality || '',
        time: layoverDetails.arrival.time,
        arrivalFlightNumber: removeWhiteSpace(segments[index].flightNumber),
        departureFlightNumber: removeWhiteSpace(
          segments[index + 1].flightNumber
        ),
        expanded: isLayoverExpanded(stops.length, isMobile, isModal),
        duration: secondsToDHM(segments[index].flightTime * 3600),
        layoverDetails,
        baggage: formatBaggageInfo(segments[index].baggage),
      };
    }),
    // Arriaval airport details
    {
      type: 'Arrival',
      iconDetails: { name: FlightLandIcon, color: green },
      iataCode: arrival.airportCode,
      city: airports[arrival.airportCode]?.municipality || '',
      time: arrival.time,
      expanded: true,
      arrivalFlightNumber: removeWhiteSpace(
        segments[segments.length - 1].flightNumber
      ),
      duration: secondsToDHM(segments[segments.length - 1].flightTime * 3600),
      // Do not show baggage info for arrival
      // baggage: formatBaggageInfo(segments[segments.length - 1].baggage),
    },
  ];
};

export const initiateFlightFilters = (
  flights: Flight[]
): { range: FlightParamsRange; customFilters: CustomFilterValues } => {
  const initialCustomFilters = { selectedStops: [], airlines: [] };

  if (!flights.length) {
    return {
      range: initialTimeAndStopsCount,
      customFilters: initialCustomFilters,
    };
  }

  return {
    range: prepareTimeRange(flights),
    customFilters: prepareCustomFilterRange(flights),
  };
};

const filterFlightNumbers = (query: string) => (flight: Flight) =>
  (flight.flightNumbers || []).some((text) =>
    removeWhiteSpace(text).toLowerCase().includes(query.toLowerCase().trim())
  );

// appply filters to flights fetched for QuickFly
const applyFilterToFlight =
  (
    filters: ResultFilters | FlightFilters | ReadOnlyFlightFilters,
    query?: string
  ) =>
  (flight: Flight) => {
    const { segments, source, totalFlightTime } = flight;
    const { airports: layovers = [], time: layoverTime = 0 } =
      getLayovers(flight);

    return (
      (query ? filterFlightNumbers(query)(flight) : true) &&
      // filter for flight provider ( select all if source is `ALL`)
      (filters.source === 'ALL' || source === filters.source) &&
      // filter for flight time
      totalFlightTime <= filters.time &&
      // filter for layover time
      (layoverTime
        ? filters.layover[0] <= layoverTime && layoverTime <= filters.layover[1]
        : true) &&
      // filter for stops count
      layovers.length <= filters.stopsCount &&
      // filter whether is marine flight
      filterFlightFareType(flight, filters.fareType) &&
      (filters.selectedStops.length
        ? // filters for selected layovers/stops
          allLayoversSelected(filters.selectedStops, layovers)
        : // Include all flights if `filters.selectedStops` is empty
          true) &&
      (filters.airlines.length
        ? // filters for airlines
          allAirlinesSelected(filters.airlines, segments)
        : // Include all flights if `filters.airlines` is empty
          true) &&
      // filter for arrival & departure time
      filterFlightSchedules(filters, flight) &&
      // filter for airport transfer
      filterFlightForAirportTransfer(layovers, filters.allowAirportTransfer)
    );
  };

export const getFlightResults = (
  flights: Flight[] = [],
  filters: ResultFilters | FlightFilters | ReadOnlyFlightFilters,
  searchOption: FlightNumberDetails
) => {
  const { included, flightNumber: query } = searchOption;
  const [matchedFlights, unmatchedFlights] = partition(
    flights,
    query && !included
      ? filterFlightNumbers(query)
      : applyFilterToFlight(filters, query)
  );

  return {
    matched: matchedFlights.sort(flightSortUtils[filters.type]),
    unmatched: unmatchedFlights.sort(flightSortUtils[filters.type]),
  };
};

export const findRecentFlightSearch = (
  recentFlotillaSearches: FlotillaSearchOption[]
) =>
  recentFlotillaSearches.find(({ inputStr }) => {
    const [command] = inputStr.split(':').map((str) => str.trim());
    return command === 'quickfly';
  });

// initial flight serch params to fill the inputs in QuickFly feat
// used when opening up minimized flotilla searchbar
// or selecting a recent flight search
// will be empty for a new flight search started from Flotilla Search bar
export const getInitialFlightSearchParams = (
  activeSearch: FlightSearch,
  storedAppSearches: RecentSearches | null,
  isRecent?: boolean, // true, if this is an existing flight search triggered from recent section
  isCopilotSearch?: boolean // true, if this is an existing flight search triggered from copilot
) => {
  if (!isRecent || !storedAppSearches || isCopilotSearch) {
    return {
      date: activeSearch.date,
      departureAirport: activeSearch.departure,
      arrivalAirport: activeSearch.arrival,
      cabinClass: activeSearch.cabinClass,
    };
  }

  const { flotillaSearch: recentFlotillaSearches = [] } =
    storedAppSearches || {};
  const storedDetails = findRecentFlightSearch(
    recentFlotillaSearches as FlotillaSearchOption[]
  )?.details;
  const isBeforeToday = moment(storedDetails?.date).diff(moment(), 'days') < 0;

  return {
    date: (isBeforeToday && '') || storedDetails?.date || '',
    departureAirport: storedDetails?.departure || null,
    arrivalAirport: storedDetails?.arrival || null,
    cabinClass: storedDetails?.cabinClass,
  };
};

export const isHotelResponse = (result: HotelsSearchResult) =>
  result.results.hasOwnProperty('hotels');

// get the result IDs of hotel search - it can be ports or hotels
export const getHotelSearchResultIds = (searchResult: HotelsSearchResult) => {
  const hasHotelResponse = isHotelResponse(searchResult);
  // this can be ports or hotels
  const { results: hotelSearchResults } = searchResult;
  const resultsArray = hasHotelResponse
    ? (hotelSearchResults as HotelsResponse).hotels
    : (hotelSearchResults as HotelsPort[]);
  return (resultsArray || []).map((result) => `hotels-search-${result.id}`);
};

export const formatSearchInput = (inputStr: string) => {
  const [command, searchText] = inputStr.split(':').map((str) => str.trim());
  const formattedText = ['eventeta', 'eta'].includes(command)
    ? secondsToDHM(Number(searchText))
    : searchText;
  return { command, searchText: formattedText };
};

export const applyCustomFilters = (
  filters: SearchFilter[],
  portCalls: PortCallResponseMap,
  vessels: Map<number, Vessel>
) => {
  const filteredVessels = filters.reduce((acc, filter) => {
    if (filter.field === 'futureport') {
      return plannedPortSearchResults(portCalls, acc, filter.query);
    }
    return acc;
  }, vessels);

  return filteredVessels;
};
