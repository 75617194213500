import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { vesselHighlights, interpolate } from './color-utils';
import {
  HighlightParameters,
  VesselFieldId,
  VesselFieldValue,
  VesselFieldHighlightConfig,
} from './types';

export class VesselFieldHighlight<T extends VesselFieldValue> {
  field: VesselFieldId;
  values: T[];
  emptyValueList: any[];
  highlightConfig: VesselFieldHighlightConfig;
  highlightParameters: HighlightParameters<T>;

  constructor(
    field: VesselFieldId,
    values: T[],
    emptyValueList: any[],
    highlightConfig: VesselFieldHighlightConfig
  ) {
    this.field = field;
    this.values = values;
    this.emptyValueList = emptyValueList;
    this.highlightConfig = highlightConfig;
    this.highlightParameters = this.buildHighlightParameters();
  }

  getHighlightParameters(): HighlightParameters<T> {
    return this.highlightParameters;
  }

  buildHighlightParameters(): HighlightParameters<T> {
    const { field, values, emptyValueList, highlightConfig } = this;

    const highlightParams: HighlightParameters<T> = {
      field: this.field,
      emptyCheck: (v) => emptyValueList.includes(v),
      emptyExample: null,
      sequential: null,
      qualitative: null,
    };

    const processed: T[] = values
      .filter((v) => !highlightParams.emptyCheck(v))
      .sort((a, b) => {
        // already filtered out empty values
        if (a! > b!) {
          return 1;
        } else if (a === b) {
          return 0;
        } else {
          return -1;
        }
      });

    const emptyValues = values.filter((v) => highlightParams.emptyCheck(v));
    if (emptyValues.length) {
      highlightParams.emptyExample = emptyValues[0];
    }

    if (
      highlightConfig.colorPalette === 'sequential' &&
      highlightConfig.highlightType !== 'enum'
    ) {
      highlightParams.sequential = {
        min: processed[0],
        max: processed[processed.length - 1],
      };
    } else if (highlightConfig.colorPalette === 'qualitative') {
      highlightParams.qualitative = {
        buckets: Array.from(new Set(processed)).sort(),
      };
    } else if (highlightConfig.colorPalette === 'qualitative_contract') {
      // no need for highlightParams for qualitative_contract
    } else {
      throw new Error(`Field's highlight config is not quite right: '${field}`);
    }

    return highlightParams;
  }

  getHighlightColor(vessel: Vessel): string | null {
    const { field, highlightParameters, highlightConfig } = this;

    if (highlightConfig.colorPalette === 'sequential') {
      const { sequential } = highlightParameters;
      if (!sequential) {
        throw new Error(
          `Field has sequential palette type but sequantial parameters were not generated: ${field}`
        );
      }

      const tmp = vessel[field as keyof Vessel];

      if (highlightParameters.emptyCheck(tmp)) {
        return vesselHighlights.empty;
      }

      if (typeof tmp !== 'number' && highlightConfig.highlightType !== 'date') {
        throw new Error(
          `sequential color palette called for unsupported type for field '${field}'. Type: ${typeof tmp}`
        );
      }

      const isNumber = typeof tmp === 'number';

      const value = isNumber ? tmp : new Date(tmp as string).getTime();

      const max: number = isNumber
        ? (sequential.max as number)
        : new Date(sequential.max as string).getTime();
      const min: number = isNumber
        ? (sequential.min as number)
        : new Date(sequential.min as string).getTime();

      const colorIndex =
        ((value - min) / (max - min)) *
        (vesselHighlights.sequential.length - 1);
      const color = interpolate(
        vesselHighlights.sequential[Math.floor(colorIndex)],
        vesselHighlights.sequential[Math.ceil(colorIndex)],
        colorIndex - Math.floor(colorIndex)
      );
      if (!color) {
        console.log('null color after interpolation');
        // In this situation, use the 'empty' color instead
        return vesselHighlights.empty;
      }

      return color;
    } else if (highlightConfig.colorPalette === 'qualitative') {
      const { qualitative } = highlightParameters;
      if (!qualitative) {
        throw new Error(
          `Field has qualitative palette type but qualitative parameters were not generated: ${field}`
        );
      }

      const value = vessel[field as keyof Vessel];
      const bucketIndex = qualitative.buckets.findIndex((val) => val === value);

      if (highlightParameters.emptyCheck(value)) {
        return vesselHighlights.empty;
      }

      return vesselHighlights.qualitative[
        bucketIndex % vesselHighlights.qualitative?.length
      ];
    } else if (highlightConfig.colorPalette === 'qualitative_contract') {
      const value = vessel['crewEarliestDueDate'];
      if (value) {
        const date1 = new Date();
        const date2 = new Date(value);
        // Calculate the time difference in milliseconds
        var timeDiff = date2.getTime() - date1.getTime();
        // Convert the time difference to days
        var diffInDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        if (diffInDays > 30) {
          return vesselHighlights.qualitative_contract[1];
        } else if (diffInDays <= 30 && diffInDays >= -30) {
          return vesselHighlights.qualitative_contract[2];
        } else if (diffInDays < -30 && diffInDays > -90) {
          return vesselHighlights.qualitative_contract[3];
        } else if (diffInDays <= -90) {
          return vesselHighlights.qualitative_contract[4];
        }
      }
      return vesselHighlights.qualitative_contract[0];
    } else {
      throw new Error(
        `Unexpected highlight params for field '${field}'. highlightParameters: ${highlightParameters}'`
      );
    }
  }
}

export function getVesselSvg(
  stroke: string,
  background: string = 'fff',
  logo?: string
): string {
  const logoColor = logo ? logo : stroke;
  return `
    <svg width="66" height="88" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M10.666 9.362c0 8.737 1.71 22.646 1.71 22.646h7.247s1.71-13.909
        1.71-22.646C21.333 2.229
        15.999 0 15.999 0s-5.333 2.229-5.333 9.362z"
        id="a"/>
    </defs>
    <g fill="none" fill-rule="evenodd"  transform="scale(2.5)">
      <g transform="translate(.974)">
        <path stroke="#${stroke}" stroke-width="1"
          d="M16.003 1.125A8.507 8.507 0 0 1 19.08 4.26c.72 1.265 1.252 2.937 1.252 5.103 0
            2.061-.097 4.414-.243 6.8l-.046.721-.06.89c-.392 5.633-1.006
          11.146-1.25 13.235h-5.47c-.23-1.98-.793-7.031-1.186-12.346l-.063-.887-.06-.89-.046-.719-.063-1.077c-.11-2.015-.18-3.976-.18-5.727
          0-5.25 3.213-7.582 4.337-8.237z" stroke-line-join="square" fill="#${background}"/>
      </g>
      <path d="M21.345 17.616v.094h-.072a1.64 1.64 0 0 0-.158-.641 1.962 1.962 0 0 0-.342-.512
        2.556 2.556 0 0 0-.478-.389 4.802 4.802 0 0 0-1.022-.49l-4.007-1.383a3.114 3.114 0 0
        0-.798-.159v-.065l4.107-1.47-3.625-1.16c-.542-.172-.964-.45-1.267-.835a1.451 1.451 0 0
        1-.338-.937c0-.12.015-.247.043-.382l.072.015a1.279 1.279 0 0 0-.021.248c0
        .094.029.219.086.375.058.156.16.311.31.465.302.312.68.552 1.136.72l4.108-1.455a.69.69 0
        0 0 .439-.634v-.058h.072v1.6h-.072v-.065c0-.101-.033-.177-.097-.227a.305.305 0 0
        0-.184-.076.548.548 0 0 0-.158.022l-3.504 1.095 4.13 1.34-.007.065-3.986 1.426 3.561
        1.067c.417.125.794.317 1.13.576.628.48.942 1.09.942 1.83z" fill="#${logoColor}"
        fill-rule="nonzero"/>
    </g>
  </svg>`;
}
