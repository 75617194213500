import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { updateAvailableSteps, startNewWizard } from 'redux/actions';
import {
  selectMapVessels,
  selectSettings,
  selectSetupWizard,
} from 'redux/selectors';
import { ALL_SETUP_WIZARD_STEPS } from 'utils/types';

import useAppDispatch from './useAppDispatch';

function useSetupWizard() {
  const dispatch = useAppDispatch();
  const { userInfo } = useSelector(selectSettings);
  const { vesselsFull, vesselsIsLoading } = useSelector(selectMapVessels);
  const { currentStep, availableSteps } = useSelector(selectSetupWizard);
  const isTMC = userInfo?.type === 'tmc';

  useEffect(() => {
    if (
      currentStep === 'assign-pic' &&
      !availableSteps.includes('assign-pic')
    ) {
      dispatch(startNewWizard());
    }
  }, [availableSteps]); // eslint-disable-line

  useEffect(() => {
    if (!userInfo || (vesselsIsLoading && vesselsFull.size)) return;
    function disableAssignPIC() {
      dispatch(
        updateAvailableSteps(
          ALL_SETUP_WIZARD_STEPS.filter((o) => o !== 'assign-pic')
        )
      );
    }

    if (!vesselsIsLoading && vesselsFull.size) {
      // check if all vessels are already assigned to this user
      const hasUnassignedVessels = Array.from(vesselsFull.values()).reduce(
        (state, vessel) => {
          if (vessel.picId !== userInfo?.id) return true;
          return state;
        },
        false
      );

      if (!hasUnassignedVessels) {
        // if all vessels are already assigned, then hide assign-pic step
        disableAssignPIC();
        return;
      }
    }
    if (isTMC) {
      dispatch(
        updateAvailableSteps(
          ALL_SETUP_WIZARD_STEPS.filter(
            (o) => o !== 'assign-pic' && o !== 'add-vessel'
          )
        )
      );
    } else if (!userInfo?.access['Assign PIC']) {
      disableAssignPIC();
    } else {
      dispatch(updateAvailableSteps([...ALL_SETUP_WIZARD_STEPS]));
    }
  }, [userInfo, dispatch, vesselsFull, vesselsIsLoading, isTMC]);

  return null;
}

export default useSetupWizard;
