import debounce from 'lodash/debounce';
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import styled from 'styled-components/macro';

import { useDebounce, useMobile } from 'hooks';
import { iconGray, textGray } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import CloseButton from '../CloseButton';
import SearchIcon from '@mui/icons-material/Search';

const Wrapper = styled.div``;

const Count = styled.div`
  margin-top: 3px;
  margin-bottom: -5px;
  font-size: 0.75rem;
  color: ${textGray};
`;

const inputStyles = {
  style: {
    fontSize: '0.9rem',
  },
};

type SearchProps = {
  count: number;
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
};

// scroll to first occurance of matched content (wrapped with `mark` tag)
const onScrollToView = debounce(() => {
  document.getElementsByClassName('matched-content')?.[0]?.scrollIntoView?.();
}, 500);

function SearchComponent({ count, query, setQuery }: SearchProps) {
  const isMobile = useMobile(BREAK_POINT_XS);
  // state to update text inside searchbar
  const [searchText, setSearchText] = useState(query);

  useEffect(() => {
    if (query.length) {
      onScrollToView();
    }
  }, [query]);

  const debouncedUpdate = useDebounce((newValue: string) => {
    setQuery(newValue);
  }, 300);

  const handleUpdateQuery = (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setSearchText(value);
    debouncedUpdate(value);
  };

  const handleClear = () => {
    setQuery('');
    setSearchText('');
  };

  const commonStyles = {
    mr: isMobile ? 1.5 : 4,
    width: isMobile ? 150 : 200,
  };

  return (
    <Wrapper>
      <TextField
        size="small"
        placeholder="Search in restrictions..."
        value={searchText}
        onChange={handleUpdateQuery}
        sx={commonStyles}
        inputProps={inputStyles}
        variant="standard"
        InputProps={{
          startAdornment: !isMobile && (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: iconGray }} />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end" disablePointerEvents={!query}>
              <CloseButton circular size={16} onClick={handleClear} />
            </InputAdornment>
          ),
        }}
      />

      {Boolean(query) && (
        <Count>
          {count ? <b>{count}</b> : 'No'} matched result{count > 1 ? 's' : ''}
        </Count>
      )}
    </Wrapper>
  );
}

export default SearchComponent;
