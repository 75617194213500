import { memo } from 'react';
import styled from 'styled-components/macro';

import { getCountryFromAlpha2, getCountryFromAlpha3 } from 'lib/countries';
import defaultFlag from 'assets/images/default-flag.jpeg';

const FlagImage = styled.img<{ $height: number }>`
  height: ${({ $height }) => `${$height}px`};
  width: auto;
`;

type Props = {
  alpha3Code?: string;
  alpha2Code?: string;
  height: number;
};

function CountryFlag({ alpha3Code, alpha2Code, height }: Props) {
  const { countryCode } =
    (alpha3Code && getCountryFromAlpha3(alpha3Code)) ||
    (alpha2Code && getCountryFromAlpha2(alpha2Code)) ||
    {};
  if (!countryCode) return <>{alpha2Code || alpha3Code}</>;
  const flagUrl = `https://flagcdn.com/w40/${(
    countryCode || ''
  ).toLowerCase()}.png`;

  return (
    <FlagImage
      $height={height}
      src={flagUrl}
      onError={({ target }) => {
        (target as HTMLImageElement).src = defaultFlag;
      }}
    />
  );
}

export default memo(CountryFlag);
