import styled from 'styled-components/macro';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  min-height: 26px;
  h4 {
    display: flex;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    align-items: center;
  }
`;

export default CardHeader;
