import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Autocomplete, FormHelperText, Popper, TextField } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { gray20, gray30, textBlack } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { updateUserInfo } from 'api/flotilla';
import timezonesListed from 'api/data/timezones.json';
import { selectSettings } from 'redux/selectors';
import { setUserInfo } from 'redux/thunks';
import { UserInfo } from 'utils/types';

import { Title, Text, Section, ActionButton, InputWrapper } from '../../common';
import { getEmailErrorMessage } from '../../helpers';
import { UpdateSettings } from '../../types';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_EDIT_NOTIFICATION_SETTINGS_DISMISS,
  TRACK_EDIT_NOTIFICATION_SETTINGS_SAVE,
  TRACK_EDIT_NOTIFICATION_SETTINGS_START,
} from 'utils/analytics/constants';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BoldText = styled.b`
  color: ${textBlack};
  font-weight: bold;
`;

const StyledWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

const NameWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  width: 100%;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  margin-top: 1rem;
`;

const StyledPopper = muiStyled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    fontSize: '1rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const inputStyles = {
  style: { fontSize: '0.9rem' },
};

const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '0.9rem',
  fontFamily: 'HK Grotesk, Roboto',
  background: `${gray20}`,
  border: `1px solid ${gray30}`,
  borderRadius: '4px',
};

type Props = { updateSettings: UpdateSettings };

function EmailDetails({ updateSettings }: Props) {
  const dispatch = useAppDispatch();
  const { userInfo } = useSelector(selectSettings);
  const [emailDetailsOpen, openEmailDetails] = useState(false);
  const [userDetails, setUserDetails] = useState<UserInfo>(
    userInfo || ({} as UserInfo)
  );
  const [updating, setUpdating] = useState(false);

  const timezones: string[] = useMemo(
    () =>
      JSON.parse(JSON.stringify(timezonesListed)).map(
        ({ value }: { value: string }) => value
      ),
    []
  );

  const errorMessage = useMemo(
    () => getEmailErrorMessage(userDetails?.notificationEmail),
    [userDetails?.notificationEmail]
  );

  const updateEmailDetails =
    (type: string) => (event: React.SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement;
      const updatedInfo = { ...userDetails, [type]: value || null };
      setUserDetails(updatedInfo);
    };

  const updateTimezone = (newValue: string | null) => {
    const updatedUser = { ...userDetails, timezone: newValue };
    setUserDetails(updatedUser);
  };

  const handleSaveEmailDetails = async () => {
    if (!userDetails || !userDetails.notificationEmail) return;

    trackUserAction(TRACK_EDIT_NOTIFICATION_SETTINGS_SAVE);
    setUpdating(true);
    updateSettings((prev) => ({ ...prev, userInfo: userDetails }));

    const { phone, firstname, lastname, notificationEmail, timezone } =
      userDetails;
    const userReq = {
      notificationEmail,
      phone,
      firstname,
      lastname,
      timezone,
    };
    await updateUserInfo(userReq);

    dispatch(setUserInfo(userDetails));
    setUpdating(false);
    showToaster({ message: 'Email details saved successfully.' });
    openEmailDetails(false);
  };

  const renderNotificationsText = () => {
    const { notificationEmail, timezone } = userInfo || {};

    if (emailDetailsOpen) {
      return (
        <span>
          {!notificationEmail
            ? 'Provide the following to receive notifications to your email'
            : 'Update your email details to receive notifications.'}
        </span>
      );
    }

    if (!notificationEmail) {
      return (
        <span>Your email details is needed to receive notifications.</span>
      );
    }

    const timezoneText = (
      <span style={{ marginLeft: '5px' }}>
        with working timezone <BoldText>{timezone}</BoldText>
      </span>
    );

    return (
      <span>
        Notifications are sent to <BoldText>{notificationEmail}</BoldText>
        {timezone ? timezoneText : ''}.
      </span>
    );
  };

  return (
    <Section>
      <Title data-id="email-details-title">Email Details</Title>
      <Text>{renderNotificationsText()}</Text>
      {emailDetailsOpen ? (
        <InputWrapper>
          <StyledWrapper>
            <TextField
              size="small"
              label="Notification Email"
              placeholder="Add Email Address"
              value={userDetails.notificationEmail || ''}
              onChange={updateEmailDetails('notificationEmail')}
              inputProps={inputStyles}
              sx={{ width: '100%' }}
            />
            {Boolean(errorMessage) && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}
          </StyledWrapper>

          <NameWrapper>
            <TextField
              size="small"
              label="First Name"
              placeholder="Add First Name"
              value={userDetails.firstname || ''}
              onChange={updateEmailDetails('firstname')}
              inputProps={inputStyles}
              sx={{ mr: 1.5 }}
            />
            <TextField
              size="small"
              label="Last Name"
              placeholder="Add Last Name"
              value={userDetails.lastname || ''}
              onChange={updateEmailDetails('lastname')}
              inputProps={inputStyles}
            />
          </NameWrapper>
          <Autocomplete
            size="small"
            options={timezones}
            value={userDetails.timezone || null}
            onChange={(_, value) => updateTimezone(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Timezone"
                placeholder="Select Timezone"
              />
            )}
            ListboxProps={{ style: dropDownStyles }}
            PopperComponent={StyledPopper}
            sx={{ mt: 2, width: '100%' }}
          />
          <ButtonsWrapper>
            <ActionButton
              variant="dark"
              disabled={updating}
              onClick={() => {
                setUserDetails(userInfo || ({} as UserInfo));
                openEmailDetails(false);
                trackUserAction(TRACK_EDIT_NOTIFICATION_SETTINGS_DISMISS);
              }}
              style={{ marginRight: '1rem' }}
            >
              Cancel
            </ActionButton>
            <ActionButton
              disabled={updating || Boolean(errorMessage)}
              onClick={handleSaveEmailDetails}
            >
              Save
            </ActionButton>
          </ButtonsWrapper>
        </InputWrapper>
      ) : (
        <ActionButton
          onClick={() => {
            trackUserAction(TRACK_EDIT_NOTIFICATION_SETTINGS_START);
            openEmailDetails(true);
          }}
          style={{ marginTop: '0.8rem' }}
        >
          Edit
        </ActionButton>
      )}
    </Section>
  );
}

export default memo(EmailDetails);
