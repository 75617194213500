import {
  findPortOrder,
  removePastPorts,
} from 'components/FlotillaSearch/components/PlannedPortSearch/helpers';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectMapVessels, selectPortCalls } from 'redux/selectors';
import {
  PortCallV2CommonUTC,
  VoyagePlanPortResult,
  VoyagePlanSearchResult,
} from 'utils/types';

type PlanPortCalls = {
  [vesselId: number]: PortCallV2CommonUTC[];
};
type Props = { inputStr?: string };

function usePlannedPortSearch({ inputStr }: Props) {
  const { portCalls: storePortCalls } = useSelector(selectPortCalls);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const [planPortCalls, setPlanPortCalls] = useState<PlanPortCalls | null>(
    null
  );
  const [results, setResults] = useState<VoyagePlanSearchResult | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!inputStr) return;
    const isEmptyPortCalls = isEmpty(storePortCalls);
    setLoading(isEmptyPortCalls);
    if (!isEmptyPortCalls) {
      setPlanPortCalls(removePastPorts(storePortCalls));
    }
  }, [inputStr, storePortCalls]);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (queryStr: string, portsData: PlanPortCalls) => {
      const [command, query] = (queryStr || '')
        .split(':')
        .map((text) => text.trim());

      if (command === 'futureport' && !query) {
        setResults(null);
        return;
      }

      const searchText =
        command === 'futureport'
          ? (query || '').trim().toLowerCase()
          : (queryStr || '').toLowerCase();
      // find the port search results
      const plannedPortSearchResults = Object.keys(portsData).reduce<{
        [vesselId: number]: VoyagePlanPortResult;
      }>((acc, key) => {
        const vesselId = Number(key);
        const portCalls = portsData[vesselId];
        const vessel = vessels.get(vesselId);
        // find the ports based on search query
        const searchedPorts = portCalls
          // filter searched ports based on query text
          .filter(({ displayName, portLocode }) =>
            [displayName, portLocode || ''].some((text) =>
              text.toLowerCase().includes(searchText)
            )
          )
          // include port order for future journey
          .map((portCall) => ({
            ...portCall,
            order: findPortOrder(portCall, portCalls),
          }));
        return vessel && searchedPorts.length
          ? {
              ...acc,
              [vessel.id]: {
                vessel,
                // prevent duplicate results & sort based on future order
                portCalls: uniqBy(searchedPorts, 'displayName').sort(
                  (a, b) => (a.order || 0) - (b.order || 0)
                ),
              },
            }
          : acc;
      }, {});
      setResults({
        searchType: 'futureport',
        searchFieldName: 'futureport',
        searchFieldDescription: '',
        priority: 10,
        filterLabel: 'voyage plan port for',
        results: Object.values(plannedPortSearchResults),
      });
    }, 300),
    [vessels]
  );

  useEffect(() => {
    if (!inputStr || !planPortCalls) return;
    debouncedSearch(inputStr, planPortCalls);
  }, [inputStr, planPortCalls, debouncedSearch]);

  return { results, loading };
}

export default usePlannedPortSearch;
