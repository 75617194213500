import { memo, useContext } from 'react';
import styled from 'styled-components/macro';

import { CCPanelContext } from 'contexts/CCPanelContext';
import { Modal, SuccessAnimation } from 'components/shared';
import { StyledButton } from '../common';
import ActionTimes from '../common/ActionTimes';

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  locodeKey: string;
  agentsCount: number;
  closeModal: () => void;
};

function AnimationModal({ locodeKey, agentsCount, closeModal }: Props) {
  const { updateFilters } = useContext(CCPanelContext);

  const handleSend = () => {
    updateFilters({ type: 'SEND_EMAIL', payload: { locodeKey } });
    closeModal();
  };

  const actions = (
    <ButtonsWrapper>
      <StyledButton onClick={handleSend}>Continue</StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal center width={500} actions={actions}>
      <SuccessAnimation
        title="Crew change plan sent successfully!"
        name="676-done"
      />
      <ActionTimes agentsCount={agentsCount} />
    </Modal>
  );
}

export default memo(AnimationModal);
