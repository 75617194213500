import { memo } from 'react';
import styled from 'styled-components/macro';

import { titleize } from 'lib/string';
import { gray20, green, red } from 'lib/colors';

import { Modal, Tooltip } from 'components/shared';
import { OffsignerIcon, OnsignerIcon } from 'components/icons';
import FetchingLoader from 'components/FlotillaSearch/components/FlightSearch/FetchingLoader';
import { EmptyFooter } from './common';

import { getFlightResultsModalWidth } from 'components/CrewChangePanel/helpers';
import { ReadOnlyFlightCrew } from 'components/CrewChangePanel/types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const modalStyles = {
  margin: 0,
  padding: '0.25rem 0',
  paddingLeft: '0.75rem',
  overflowX: 'hidden',
  background: gray20,
};

type Props = {
  crew: ReadOnlyFlightCrew;
};

function FetchingloaderModal({ crew }: Props) {
  const { name: crewName, type } = crew;
  const modalWidth = getFlightResultsModalWidth();

  const renderTitle = () => {
    const isOnsigner = type === 'onsigner';
    return (
      <FlexWrapper style={{ gap: '0.5rem' }}>
        New Flights for {crewName}
        <Tooltip content={titleize(type)}>
          <FlexWrapper>
            {isOnsigner ? (
              <OnsignerIcon color={green} size={28} />
            ) : (
              <OffsignerIcon color={red} size={28} />
            )}
          </FlexWrapper>
        </Tooltip>
      </FlexWrapper>
    );
  };

  return (
    <Modal
      disableExternalClose
      width={modalWidth}
      height="90vh"
      actions={<EmptyFooter />}
      title={renderTitle()}
      styles={modalStyles}
    >
      <FetchingLoader isModal width="80%" />
    </Modal>
  );
}

export default memo(FetchingloaderModal);
