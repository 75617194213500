import styled from 'styled-components/macro';

import { gray80, bluePurple, textBlack, white } from 'lib/colors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export const Wrapper = styled.div<{ $compare?: boolean }>`
  ${({ $compare }) =>
    $compare &&
    `
    font-size: 0.8rem;
    line-height: 1.2rem;
  `};
`;

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.b<{ $color?: string }>`
  margin-right: 0.25rem;
  ${({ $color }) => $color && `color: ${$color}`};
`;

export const Price = styled.span<{ $color?: string; $isTotal?: boolean }>`
  margin-left: 0.5rem;
  ${({ $color }) => $color && `color: ${$color}`};
  ${({ $isTotal }) => $isTotal && 'font-weight: bold'};
`;

export const FlightConnection = styled(Title)`
  font-weight: 400;
`;

export const FlightCostList = styled.div<{ $compare?: boolean }>`
  margin-left: 0.5rem;
  color: ${bluePurple};
  ${({ $compare }) =>
    $compare &&
    `
    color: ${gray80};
  `};
`;

export const FlightCostTitle = styled.span`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const ExpandIcon = styled(KeyboardArrowDownIcon)<{
  $showAll?: boolean;
  $compare?: boolean;
}>`
  font-size: 0.8rem;
  color: ${white};
  margin-left: 0.25rem;
  cursor: pointer;
  transition: all 150ms ease;
  ${({ $showAll }) =>
    $showAll &&
    `
    transform: rotate(180deg);
    margin-top: 2px;
  `};
  ${({ $compare }) =>
    $compare &&
    `
    color: ${textBlack}
  `};
  font-size: 18px !important;
`;
