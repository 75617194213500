import filter from 'lodash/filter';
import map from 'lodash/map';
import some from 'lodash/some';
import toUpper from 'lodash/toUpper';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import {
  Airport,
  AmadeusAirport,
  TravelRequirementStatus,
  TravelRequirementType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import { PreferredPort } from '@greywing-maritime/frontend-library/dist/types/preferredPorts';
import { point } from '@turf/helpers';

import { searchPorts } from 'api/flotilla';
import { NM_TO_KM, PROXPORTS_PAGE_SIZE } from 'lib/constants';
import { fadedGreen, red, yellowOrange } from 'lib/colors';
import { getAlpha2CodeFromCountry } from 'lib/countries';
import { WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import { correctLongitudeCoor } from 'utils/correct-coordinate';
import {
  Crew,
  Port,
  PortRequest,
  PortResponse,
} from 'utils/types/crew-change-types';

import { MIN_DEVIATION, NO_DEVIATION, PREFERRED } from './constants';
import { formatPortNearbyAirport } from './flights';
import {
  AddPortDatesAndDeviation,
  CustomPort,
  PortETAProps,
  PortRequestTuple,
  PortRow,
  ReadOnlyPort,
  RoutePort,
  StepData,
  TravelRequirements,
} from '../types';

export const PORT_PRIORITIES = [NO_DEVIATION, PREFERRED, MIN_DEVIATION];

// confirm locode validity before using it
const isValidLocode = (locode: string | null | undefined) => {
  if (!locode) return false;

  const isValidChars = /^[a-z]+$/i.test(locode);
  return locode.length === 5 && isValidChars;
};

const getPriority = (
  port: Port | ReadOnlyPort,
  preferredPorts: PreferredPort[]
) => {
  const { distanceKM, locode: portLocode, isPartOfRoute } = port;
  const distanceNM = distanceKM / NM_TO_KM;
  const isCurrentPortPreferred = preferredPorts.some(
    ({ locode }) => locode === portLocode
  );
  let priorities = [];

  // handle exclusive cases
  switch (true) {
    case isPartOfRoute:
      priorities.push(NO_DEVIATION);
      break;

    case distanceNM < 20:
      priorities.push(MIN_DEVIATION);
      break;

    default:
      break;
  }

  // check if port is preferred - insert to the status, if so
  return isCurrentPortPreferred ? [...priorities, PREFERRED] : priorities;
};

export const includePortETA = (eta: string | undefined, limit: number) =>
  Boolean(eta) && moment(eta).diff(moment(), 'weeks') < limit;

// get missing eta/etd depending on the other
// the fallback alternate date is of 1 day difference
export const getAlternateDate = (type: 'eta' | 'etd', date: string) => {
  return type === 'etd'
    ? moment(date).add(1, 'day').toISOString()
    : moment(date).subtract(1, 'day').toISOString();
};

export const formatPortDates = (dates: {
  eta?: string | undefined;
  etd?: string | undefined;
}) => {
  const { eta, etd } = dates;
  // one of `eta` ot `etd` will always be available
  return {
    eta: eta || getAlternateDate('eta', etd!),
    etd: etd || getAlternateDate('etd', eta!),
  };
};

export const mergePorts = (ports: Port[], response: PortResponse | null) => {
  const { requestPorts = [], proxPorts = [], count = 0 } = response || {};
  const hasMorePorts = proxPorts?.length && ports.length < count;
  return hasMorePorts
    ? // merge incoming ports with existing ones
      uniqBy([...requestPorts, ...proxPorts, ...ports], 'locode')
    : // return existing prox-ports
      (Boolean(response?.count) && response?.proxPorts) || [];
};

export const getPortResponseDetails =
  (ports: Port[], response: PortResponse | null) =>
  async (addPortDatesAndDeviation: AddPortDatesAndDeviation) => {
    // insert calculated eta/etd dates inside fetched ports
    const newPorts = await addPortDatesAndDeviation(
      mergePorts(ports, response)
    );
    const newResponse = {
      ...response,
      // update `currentPage` if there's upcoming ports
      // otherwise set it to `currentPage` in `response`
      currentPage:
        ports.length < (response?.count || 0)
          ? Math.floor(newPorts.length / PROXPORTS_PAGE_SIZE)
          : response?.currentPage || 1,
      proxPorts: newPorts,
    } as PortResponse;
    return { newPorts, newResponse };
  };

export const getDefaultPortAirport = (
  port: Port
): AmadeusAirport | Airport | undefined => {
  const { amadeus, greywing } = port.nearbyAirports;
  const matchCountry = ({ address }: { address: { countryCode: string } }) =>
    address.countryCode === port.locode.slice(0, 2);

  return (
    (amadeus?.airports?.length && amadeus.airports.find(matchCountry)) ||
    greywing?.airports.find(matchCountry)
  );
};

// find ETA for each port in ports table
export const getPortETA = async (args: PortETAProps) => {
  const { coords, port, getVesselRoute } = args;
  const { lng, lat, calcSpeed } = port;
  const coordsArr = [coords, [lng, lat]];

  const waypoints: WaypointWithProperties[] = coordsArr.map(([lng, lat]) => ({
    coor: [correctLongitudeCoor(lng), lat],
    properties: {},
  }));
  const {
    calculation: { totalDistance },
  } = await getVesselRoute({
    waypoints,
    isSplit: true,
    connectAllWaypoints: true,
  });
  const days = moment
    .duration(totalDistance / (calcSpeed || 13), 'hours')
    .asDays();

  return moment().add(days, 'days').toISOString();
};

export const formatAgency = (text: string) =>
  text.toLowerCase().split(` (get cost)`)[0];

export const formatPorts = ({
  data,
  ports,
}: {
  data: StepData;
  ports: Port[];
}): CustomPort[] => {
  const selectedPortIds = (data as Port[]).map(({ id }) => id);
  return ports.map((port) => ({
    ...port,
    selected: selectedPortIds.includes(port.id),
    selectedAirport: port.selectedAirport || getDefaultPortAirport(port),
  }));
};

// get the ports eligible for one click crew change
// ports part of route & preferred ports are allowed only
export const getOneClickPlanPorts = (
  proxPorts: Port[],
  preferredPorts: PreferredPort[] | null
) =>
  preferredPorts
    ? map(
        filter(
          proxPorts,
          ({ locode, isPartOfRoute }) =>
            isPartOfRoute && some(preferredPorts, ['locode', locode])
        ),
        (port) => ({
          ...port,
          selected: true,
          selectedAirport: port.selectedAirport || getDefaultPortAirport(port),
        })
      )
    : [];

const getValidLocodes = async (waypoints: RoutePort[]): Promise<string[]> => {
  const promises = waypoints
    .filter(({ port }) => port.name || port.locode)
    .map(async ({ port }) => {
      if (port.locode) return port.locode;
      const { success, portsResponse: result } = await searchPorts(port.name!);
      if (!success || !result?.results.length) return '';
      return result.results[0].locode;
    });
  // filter out invalid locodes or custom locations
  return Promise.all(promises).then((results) => results.filter(isValidLocode));
};

// utility to get formatted ports request
export const getPortRequest = async (
  ...args: PortRequestTuple
): Promise<PortRequest> => {
  const [range, vessel, route, locode, crewList] = args;
  const { id, lat, lng } = vessel;
  const { waypoints = [], path } = route;
  const crewNationalities = uniq(
    crewList
      .map(({ country }) => getAlpha2CodeFromCountry(country))
      .filter((alpha2Code) => alpha2Code)
  ) as string[];
  const filteredWaypoints = waypoints.filter(
    ({ type }) => type.toUpperCase() === 'MAERSK'
  );
  const waypointPortLocodes = await getValidLocodes(
    filteredWaypoints.length ? filteredWaypoints : waypoints
  );
  return {
    rangeKM: range * NM_TO_KM, // 5 NM
    proximityMonths: 3,
    numCrew: crewList.length,
    vesselId: id,
    vesselCurrentLocation: { lat, lng },
    waypointPortLocodes,
    // crew change event port locodes which are absent in `waypointPortLocodes`
    requestPorts:
      locode && isValidLocode(locode) && !waypointPortLocodes.includes(locode)
        ? [locode]
        : [],
    // array of crew country alpha-2 codes
    crewNationalities,
    // no `fullPath` field for unavilable vessel route
    ...(path.coordinates.length ? { fullPath: path } : {}),
  };
};

export const isVerifiedPort = (port: Port | ReadOnlyPort) =>
  (port.restrictionsAvailable || []).some(
    ({ source }) => source === 'greywing'
  );

export const getPortRows = (
  ports: Port[],
  preferredPorts: PreferredPort[] | null,
  etaLimit: number // filter out ports that are beyond this ETA limit (in weeks)
) =>
  ports
    .filter(({ eta }) => includePortETA(eta, etaLimit))
    .map((port, index: number) => {
      const { amadeus, greywing } = port.nearbyAirports;
      const nearbyAirports = amadeus?.airports?.length
        ? amadeus.airports
        : greywing?.airports || [];

      return {
        ...port,
        id: port.id || index,
        name: port.name,
        locode: port.locode,
        costDetails: port.costs,
        // mui throws warning in console for array
        closestAirports: JSON.stringify(nearbyAirports),
        calculatedDistance: port.calculatedDistance || 0,
        deviationTimeDifference: port.deviationTimeDifference || 0,
        selectedAirport: port.selectedAirport,
        distanceNM: port.distanceKM / NM_TO_KM,
        eta: port.eta || '',
        coordinates: { lat: port.lat, lon: port.lng },
        airportsCount: nearbyAirports.length || '-',
        status: getPriority(port, preferredPorts || []),
        greywingVerified: isVerifiedPort(port),
      };
    })
    .sort((a, b) => a.distanceNM - b.distanceNM);

export const getReadOnlyPortRows = (
  ports: ReadOnlyPort[],
  preferredPorts: PreferredPort[] | null
) =>
  ports
    .map((port, index: number) => ({
      ...port,
      id: port.id || index,
      name: port.name,
      locode: port.locode,
      costDetails: port.costs,
      calculatedDistance: port.calculatedDistance || 0,
      deviationTimeDifference: port.deviationTimeDifference || 0,
      airportName: port.selectedAirport
        ? formatPortNearbyAirport(port.selectedAirport)
        : '',
      distanceNM: port.distanceKM / NM_TO_KM,
      eta: port.eta || '',
      coordinates: { lat: port.lat, lon: port.lng },
      status: getPriority(port, preferredPorts || []),
      greywingVerified: isVerifiedPort(port),
    }))
    .sort((a, b) => a.distanceNM - b.distanceNM);

export const getPortWaypoint = (
  port: Port | PortRow | ReadOnlyPort
): GeoJSON.FeatureCollection => ({
  type: 'FeatureCollection',
  features: [
    {
      id: port.locode,
      geometry: { coordinates: [port.lng, port.lat], type: 'Point' },
      properties: {
        id: port.locode,
        icon: 'depart-wp-marker',
        lat: port.lat,
        lng: port.lng,
        text: port.name,
      },
      type: 'Feature',
    },
  ],
});

// list of avaialble port agents
export const getAgencies = (ports: Port[]) =>
  ports
    .reduce((acc: string[], port) => {
      const { costs } = port;
      return costs
        ? uniq([
            ...acc,
            ...Object.keys(costs).filter((agency) => costs[agency]),
          ])
        : acc;
    }, [])
    .map(toUpper);

const scheduleMergedPorts = (locode?: string | null) => (ports: Port[]) => {
  const eventPort = ports.filter((port) => port.locode === locode);
  return uniqBy(
    [
      // attach `scheduled` flag & show event port at the start
      ...eventPort.map((port) => ({ ...port, scheduled: true })),
      ...ports,
    ],
    'id'
  );
};

export const arrangePorts = (
  ports: PortRow[],
  filters: { query: string; priorities: string[]; locode?: string | null }
) => {
  const { query, priorities, locode } = filters;
  const setScheduled = scheduleMergedPorts(locode);

  const filteredPorts = ports.filter(({ name, locode, status }) => {
    const formattedQuery = query.trim().toLowerCase();
    const isPrioritized = priorities.length
      ? priorities.some((priority) => (status || []).includes(priority))
      : true; // show all ports if no priority is set
    const itemsToSearch = [locode.toLowerCase(), name.toLowerCase()];
    const isQueried = formattedQuery
      ? itemsToSearch.some((text) => text.includes(formattedQuery))
      : true;

    return isQueried && isPrioritized;
  });

  return setScheduled(filteredPorts);
};

export const getTravelRequirementsList = (
  crewList: Crew[],
  travelRequirements: TravelRequirementType
): TravelRequirementType =>
  Object.keys(travelRequirements).reduce((acc, countryCode) => {
    const isCrewSelected = crewList.some(
      ({ country }) =>
        country && country === travelRequirements[countryCode].countryName
    );
    return isCrewSelected
      ? { ...acc, [countryCode]: travelRequirements[countryCode] }
      : acc;
  }, {});

export const formatPortTravelRequirements = ({
  offsigner,
  onsigner,
}: {
  offsigner: { summary: TravelRequirementStatus[] };
  onsigner: { summary: TravelRequirementStatus[] };
}) => {
  const getDetails = (summary: TravelRequirementStatus[]) =>
    summary.reduce((acc: TravelRequirements, detail) => {
      switch (true) {
        case detail.type.toLowerCase() === 'visa':
          return { ...acc, visa: detail.headline.split('.')[0] };
        case detail.type.toLowerCase() === 'doc_required':
          return { ...acc, documents: detail.headline.split('.')[0] };
        case detail.type.toLowerCase() === 'covid_19_test':
          return { ...acc, covid19: detail.headline.split('.')[0] };
        case detail.type.toLowerCase() === 'quarantine':
          return { ...acc, quarantine: detail.headline.split('.')[0] };
        default:
          return acc;
      }
    }, {} as TravelRequirements);

  const noOffsignerRequirements = offsigner.summary.every(
    ({ status }) => status.toLowerCase() === 'not_required'
  );
  const noOnsignerRequirements = onsigner.summary.every(
    ({ status }) => status.toLowerCase() === 'not_required'
  );
  let color = fadedGreen;

  switch (true) {
    case noOffsignerRequirements && noOnsignerRequirements:
      color = fadedGreen;
      break;

    case !noOffsignerRequirements && !noOnsignerRequirements:
      color = red;
      break;

    default:
      color = yellowOrange;
      break;
  }

  return {
    color,
    offsigner: getDetails(offsigner.summary),
    onsigner: getDetails(onsigner.summary),
  };
};

export function buildPortFeatures({
  ports,
}: {
  ports: Port[];
}): GeoJSON.FeatureCollection {
  if (!ports.length)
    return {
      type: 'FeatureCollection',
      features: [],
    };

  return ports.reduce<GeoJSON.FeatureCollection>(
    (acc, port) => {
      if (port.selected) return acc;
      acc.features.push(point([port.lng, port.lat], port));
      return acc;
    },
    {
      type: 'FeatureCollection',
      features: [],
    }
  );
}
