import { memo, useMemo } from 'react';
import styled from 'styled-components/macro';

import { white, yellowOrange } from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { Flight } from 'utils/types/crew-change-types';
import {
  formatFlightPath,
  getLayovers,
} from 'components/CrewChangePanel/helpers';
import Tooltip from './Tooltip';

const Wrapper = styled.div`
  margin: 0 1rem;
`;

const FelxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCard = styled.div`
  padding: 1rem;
  margin-bottom: 0.5rem;
  background: ${white};
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);

  h3 {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

const CodeWrapper = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const FlightInfoCard = styled(StyledCard)`
  font-size: 0.9rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const InfoRow = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 4fr;
  width: 70%;
`;

const RowTitle = styled(FelxWrapper)`
  justify-content: space-between;
`;

const RowInfo = styled.div`
  margin-left: 1rem;
`;

const Layover = styled.span`
  color: ${yellowOrange};
`;

const FlightPath = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const AirportsWrapper = styled.div`
  padding: 0.25rem;
`;

const AirportName = styled.div`
  margin-bottom: 1px;
  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  flight: Flight;
};

function FlightRawData({ flight }: Props) {
  const { rawFlight: rawData, airports, arrival, departure } = flight;
  const { from, to, layovers, airportCodes } = useMemo(() => {
    const [from, to] = JSON.parse(formatFlightPath(flight));
    const { airports: layovers = [] } = getLayovers(flight);
    const airportCodes = [from, ...(layovers?.length ? layovers : []), to];
    return { from, to, layovers, airportCodes };
  }, [flight]);

  const renderAirportList = () => (
    <AirportsWrapper>
      {airportCodes
        .filter((iataCode) => Boolean(airports?.[iataCode]))
        .map((iataCode, index) => (
          <AirportName key={iataCode}>
            {index + 1}. {airports![iataCode].identifier}
          </AirportName>
        ))}
    </AirportsWrapper>
  );

  const renderLayovers = () => {
    if (!layovers) return null;
    return layovers.length ? (
      <>
        {' > '}
        <Layover>{layovers.join(' > ')}</Layover>
        {' > '}
      </>
    ) : (
      ' > '
    );
  };

  return (
    <Wrapper>
      <FlightInfoCard>
        {departure.time && (
          <InfoRow>
            <RowTitle>
              Departure<span>:</span>
            </RowTitle>
            <RowInfo>
              {formatDate(departure.time, 'hh:mm a, DD MMM YY')}
            </RowInfo>
          </InfoRow>
        )}
        {arrival.time && (
          <InfoRow>
            <RowTitle>
              Arrival<span>:</span>
            </RowTitle>
            <RowInfo>{formatDate(arrival.time, 'hh:mm a, DD MMM YY')}</RowInfo>
          </InfoRow>
        )}
        <InfoRow>
          <RowTitle>
            Flight path<span>:</span>
          </RowTitle>
          <RowInfo>
            <Tooltip content={renderAirportList()}>
              <FlightPath>
                {from} {renderLayovers()}
                {to}
              </FlightPath>
            </Tooltip>
          </RowInfo>
        </InfoRow>
      </FlightInfoCard>

      {rawData ? (
        <StyledCard>
          <CodeWrapper>{JSON.stringify(rawData || {}, null, 2)}</CodeWrapper>
        </StyledCard>
      ) : (
        <StyledCard style={{ textAlign: 'center' }}>
          <h3>Flight raw data unavailable.</h3>
        </StyledCard>
      )}
    </Wrapper>
  );
}

export default memo(FlightRawData);
