import uniq from 'lodash/uniq';
import {
  SyntheticEvent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Stack from '@mui/material/Stack';
import styled from 'styled-components/macro';

import { midBlue, yellow50, textBlack, white } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { formatDate } from 'utils/format-date';
import { CCPanelContext } from 'contexts/CCPanelContext';

import AwesomeSlider, { Thumb } from 'components/shared/AwesomeSlider';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SliderWrapper = styled.div<{ $selected?: boolean }>`
  width: 100px;
  margin-left: 10px;
  margin-right: 20px;
  flex-shrink: 0;

  .MuiSlider-root.MuiSlider-marked {
    margin-bottom: 0;
  }
  .MuiSlider-valueLabel {
    padding: 0.08rem 0.3rem !important;
    letter-spacing: 0.05rem;
    top: -6px;
  }
  .MuiSlider-markLabel {
    height: 0;
    display: none;
  }

  ${({ $selected }) =>
    $selected &&
    `
    .MuiSlider-thumb {
      background-color: ${midBlue} !important;
      color: ${textBlack} !important;
    }
    .MuiSlider-track {
      color: ${yellow50} !important;
    }
    .MuiSlider-rail {
      background-color: ${white} !important;
    }
  `};

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90px;
    margin-left: 5px;
  }
`;

const Text = styled.div`
  margin-left: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

type Props = {
  locode: string;
  selected?: boolean;
  etaDates: string[];
};

type Mark = {
  value: number;
  dateStr: string;
  label: string;
  selected: boolean;
};

const ThumbDate = (props: any) => {
  const { value, marks } = props.ownerState;
  const indexValue = props['data-index'] ? value[1] : value[0];
  const label = marks.find(({ value }: Mark) => value === indexValue)?.label;
  return <Thumb {...props} size="small" modifiedValue={label || ''} />;
};

function SelectETASlider({ locode, selected, etaDates }: Props) {
  const {
    planningData: { route },
    updatePlanningData,
  } = useContext(CCPanelContext);

  const { marks, minValue, maxValue, initialValue } = useMemo(() => {
    const marks = etaDates.map((dateStr, index) => ({
      value: index + 1,
      dateStr,
      selected: !route.find(({ eta }) => eta === dateStr)?.skipped,
      label: formatDate(dateStr, 'DD MMM'),
    }));
    const filteredMarks = marks.filter(({ selected }) => selected);
    // provide fall-back values
    return {
      marks,
      minValue: marks[0]?.value || 1,
      maxValue: marks[marks.length - 1]?.value || 1,
      initialValue: [
        filteredMarks[0]?.value || 1,
        filteredMarks[filteredMarks.length - 1]?.value || 1,
      ],
    };
  }, [etaDates, route]);

  const [value, setValue] = useState(initialValue); // pick the first two dates as starting

  const handleUpdateRange = useCallback(
    (event: Event, newValue: number | number[]) => {
      if (typeof newValue === 'number') return;

      setValue(newValue);
      const newValArr = uniq(newValue); // filter out duplicate values
      // find out all the marks between the two slider thumbs
      const filteredMarks =
        newValArr.length > 1
          ? marks.slice(newValArr[0] - 1, newValArr[1])
          : marks.filter((_, index) => index === newValArr[0] - 1);
      // get the array of date string of the selected marks
      const selectedETADates = filteredMarks.map(({ dateStr }) => dateStr);
      // update the `route` in planning data with skipped `routePorts`
      updatePlanningData((prev) => ({
        ...prev,
        route: prev.route.map((item) => ({
          ...item,
          // update skipped for the specific port only
          ...(item.port.locode === locode
            ? {
                skipped: !item.eta || !selectedETADates.includes(item.eta),
              }
            : {}),
        })),
      }));
    },
    [locode, marks, updatePlanningData]
  );

  useEffect(() => {
    // update value for change in top-level ETA limit slider
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FlexWrapper>
      <SliderWrapper
        $selected={selected}
        onClick={(e: SyntheticEvent) => {
          // prevent opening port-panel
          e.stopPropagation();
        }}
      >
        <Stack spacing={2} direction="row" alignItems="center">
          <AwesomeSlider
            disableSwap
            step={null}
            min={minValue}
            max={maxValue}
            marks={marks}
            value={value}
            size="small"
            onChange={handleUpdateRange}
            components={{
              Thumb: (props: any) => <ThumbDate {...props} />,
            }}
          />
        </Stack>
      </SliderWrapper>

      <Text>({value[1] - value[0] + 1})</Text>
    </FlexWrapper>
  );
}

export default memo(SelectETASlider);
