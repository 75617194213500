import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../types';

export const initialDesktopNotifications =
  localStorage.getItem('allow-desktop-notifications') !== '0';

const desktopNotificationsSlice = createSlice({
  name: 'desktopNotifications',
  initialState: initialDesktopNotifications,
  reducers: {
    toggleDesktopNotifications: (_, action: PayloadAction<boolean>) =>
      action.payload,
  },
});

/* ----- selectors -----*/

export const selectDesktopNotifications = ({
  desktopNotifications,
}: RootState) => desktopNotifications;

/* ----- actions -----*/

export const { toggleDesktopNotifications } = desktopNotificationsSlice.actions;

/* ----- reducer -----*/

export default desktopNotificationsSlice.reducer;
