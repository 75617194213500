import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getToolTip } from 'utils/tooltips';
import { RootState, TooltipPayload } from '../types';

export const initialAppTooltip = getToolTip() as string;

const appTooltipSlice = createSlice({
  name: 'appTooltip',
  initialState: initialAppTooltip,
  reducers: {
    setAppTooltipText: (
      state: string,
      action: PayloadAction<TooltipPayload | undefined>
    ) => {
      const { element, state: tooltipState } = action.payload || {};
      const newToolTipText = getToolTip(element, tooltipState);
      return newToolTipText !== state ? newToolTipText : state;
    },
  },
});

/* ----- selectors -----*/

export const selectAppTooltip = ({ appTooltip }: RootState) => appTooltip;

/* ----- actions -----*/

export const { setAppTooltipText } = appTooltipSlice.actions;

/* ----- reducer -----*/

export default appTooltipSlice.reducer;
