import { memo } from 'react';
import styled from 'styled-components/macro';

import { red } from 'lib/colors';
import { Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';
import { ModalType, UpdateModals } from './types';

const Wrapper = styled.div`
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionButton = styled(StyledButton)<{ $discard?: boolean }>`
  width: fit-content;
  flex-shrink: 0;
  ${({ $discard }) =>
    $discard &&
    `
    background: ${red}30;
    color: ${red};
  `};
`;

type Props = {
  updateModals: UpdateModals;
  setModalAction: (type: ModalType, open: boolean) => void;
};

function ConfirmModal({ updateModals, setModalAction }: Props) {
  const actions = (
    <ButtonsWrapper>
      <ActionButton $discard onClick={() => setModalAction('confirm', false)}>
        Cancel
      </ActionButton>
      <ActionButton
        className="e2e_open-settings-from-tracking"
        onClick={() => updateModals({ confirm: false, settings: true })}
      >
        Go To Settings
      </ActionButton>
    </ButtonsWrapper>
  );

  return (
    <Modal
      width={350}
      title="Settings Reminder"
      actions={actions}
      closeModal={() => setModalAction('confirm', false)}
    >
      <Wrapper>
        Flight track settings are NOT configured. You need to set-up flight
        tracking in app settings.
      </Wrapper>
    </Modal>
  );
}

export default memo(ConfirmModal);
