import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../types';

type TimeTravelState = {
  active: boolean;
  isReady: boolean;
  isPlaying: boolean;
  showRoute: boolean;
};

export const initialTimeTravel: TimeTravelState = {
  active: false,
  isReady: false,
  isPlaying: false,
  showRoute: true,
};

const timeTravelSlice = createSlice({
  name: 'timeTravel',
  initialState: initialTimeTravel,
  // Any action that updates store should be a thunk as in `redux/thunks/settings.ts` file
  reducers: {
    updateTTRoute: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showRoute: action.payload,
    }),
    updateTTActive: (state, action: PayloadAction<boolean>) => ({
      ...state,
      active: action.payload,
    }),
    updateTTReadyState: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isReady: action.payload,
    }),
    startPlayingTT: (state) => ({
      ...state,
      isPlaying: true,
    }),
    stopPlayingTT: (state) => ({
      ...state,
      isPlaying: false,
    }),
  },
});

/* ----- selectors -----*/

export const selectTimeTravel = ({ timeTravel }: RootState) => timeTravel;

/* ----- actions -----*/

export const {
  updateTTActive,
  updateTTReadyState,
  startPlayingTT,
  stopPlayingTT,
  updateTTRoute,
} = timeTravelSlice.actions;

/* ----- reducer -----*/

export default timeTravelSlice.reducer;
