import { object, string, size, boolean } from 'superstruct';
import { validationErrMessage } from 'utils/validations';

export const StopTrackingRequestSchema = object({
  greywingSuggestedFlightRebooked: validationErrMessage(
    boolean(),
    'A value must be provided.'
  ),
  reason: validationErrMessage(
    size(string(), 3, Infinity),
    'A valid reason must be provided.'
  ),
  selection: validationErrMessage(
    size(string(), 3, Infinity),
    'A valid reason must be provided.'
  ),
});
