import flatten from 'lodash/flatten';
import flowRight from 'lodash/flowRight';
import map from 'lodash/map';
import { memo, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  TextField,
  Popper,
  CircularProgress,
} from '@mui/material';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';

import { useDebounce, useModal } from 'hooks';
import { gray30, green, gray60, red, white } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { BREAK_POINT_M } from 'lib/breakpoints';
import { fetchSearchedOrgCrew } from 'api/flotilla';
import { selectCrewChangeVessel } from 'redux/selectors';
import { OrgCrew } from 'utils/types/crew-change-types';

import { AutocompleteGroup } from 'components/shared';
import CrewTypeModal from './CrewTypeModal';
import { StyledButton } from '../../../common';
import {
  calculateOrgCrewTypes,
  CREW_ROWS,
  formatListCrewItem,
  getVesselEventsCrewList,
} from '../../../helpers';
import { AddCrew, CrewInput, ListItemCrew } from '../../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled(FlexWrapper)<{ $selecting: boolean }>`
  align-items: flex-end;

  @media screen and (max-width: ${BREAK_POINT_M}) {
    width: ${({ $selecting }) => ($selecting ? '450px' : '400px')};
  }
`;

const InputWrapper = styled(FlexWrapper)`
  margin: 0.5rem 0 0 0.75rem;
  font-size: 0.8rem;

  .MuiInputLabel-root,
  .MuiOutlinedInput-input {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const ActionButton = styled(StyledButton)`
  flex-shrink: 0;
  margin-left: 1rem;
`;

const CloseButton = styled(StyledButton)`
  flex-shrink: 0;
`;

const OptionWrapper = styled.span<{ $type?: CrewType }>`
  background: ${({ $type }) =>
    $type ? ($type === 'onsigner' && `${green}30`) || `${red}30` : white};
`;

const ErrorWrapper = styled.div`
  margin-left: 0.75rem;
  margin-top: -0.25rem;
  font-size: 0.8rem;
  font-weight: bold;
  font-style: italic;
  letter-spacing: 0.5px;
  color: ${gray60};
`;

const StyledPopper = muiStyled(Popper)(() => ({
  '& .MuiAutocomplete-loading, .MuiAutocomplete-paper': {
    fontSize: '0.85rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const autoCompleteStyles = {
  maxHeight: '350px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
  border: `1px solid ${gray30}`,
  borderRadius: '4px',
};

type Props = {
  selecting: boolean;
  reset: () => void;
  onAddSelected: AddCrew;
};

function SelectCrew({ selecting, reset, onAddSelected }: Props) {
  const vessel = useSelector(selectCrewChangeVessel);

  const inputRef = useRef<HTMLInputElement>(null);
  const { modal, setModal } = useModal();

  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);
  const [crewList, setCrewList] = useState<(CrewInput | OrgCrew)[]>([]);
  const [selectedCrew, setSelectedCrew] = useState<ListItemCrew | null>(null);

  const { imo: vesselIMO, upcomingCrewChangeEvents: vesselEvents = [] } =
    vessel || {};

  const handleSearchOrgCrew = useDebounce(async (query: string) => {
    // set crew list to empty if query is less than 2 characters
    if (query.length < 2) {
      setCrewList([]);
      return;
    }

    setLoading(true);

    const { success, message, response } = await fetchSearchedOrgCrew(query);
    if (!success || !response) {
      showToaster({ message, type: 'error' });
      return;
    }

    const { crew: newCrewList = [] } = response;
    const getAvailableCrewForEvent = flowRight([
      getVesselEventsCrewList(flatten(map(vesselEvents, 'crew')), CREW_ROWS),
      calculateOrgCrewTypes,
    ]);
    const filteredList = getAvailableCrewForEvent(newCrewList, vesselIMO);

    setLoading(false);
    setCrewList(filteredList);
  });

  useEffect(() => {
    handleSearchOrgCrew(query);
  }, [query, handleSearchOrgCrew]);

  useEffect(() => {
    if (selecting) {
      // set focus to input when adding from events
      inputRef?.current?.focus?.();
    }
  }, [selecting]);

  if (!vessel) {
    return null;
  }

  const handleReset = () => {
    setSelectedCrew(null);
    setQuery('');
    reset();
  };

  const handleChangeCrew = (
    event: SyntheticEvent,
    value: ListItemCrew | string | null
  ) => {
    if (typeof value === 'string' || !value) {
      setSelectedCrew(null);
      handleReset();
    }
    setSelectedCrew(value as ListItemCrew);
  };

  const handleAddSelected = () => {
    if (!selectedCrew) return;
    if (!selectedCrew.type) {
      setModal('selectCrewType', { selectedCrew });
      return;
    }
    onAddSelected(
      {
        ...selectedCrew,
        existing: true,
        added: true,
      } as CrewInput,
      true
    );
    handleReset();
  };

  const handleSetCrewType: AddCrew = (crew) => {
    onAddSelected(crew);
    handleReset();
    setModal(null);
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === 'selectCrewType') {
      return (
        <CrewTypeModal
          {...modal.data}
          onSelect={handleSetCrewType}
          closeModal={() => setModal(null)}
        />
      );
    }
  };

  const inputLabel = focused
    ? 'Organization crew'
    : 'Type crew name, rank, country, or airport';
  const showWarning = query.length > 0 && query.length < 2;
  const noOptionsText = loading ? 'Loading...' : 'No matching crew';

  return (
    <>
      <Container $selecting={selecting}>
        <InputWrapper>
          <Autocomplete
            size="small"
            freeSolo={query.length < 2} // hide noOptionsText when query is less than 2 characters
            noOptionsText={noOptionsText}
            options={crewList}
            value={selectedCrew || null} // fallback to `null` for no option
            inputValue={query}
            getOptionLabel={(option) =>
              formatListCrewItem(option as ListItemCrew)
            }
            isOptionEqualToValue={(option, value) => option.cid === value?.cid}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onChange={handleChangeCrew}
            onInputChange={(_, value) => setQuery(value)}
            groupBy={(option) => (option as ListItemCrew).label || ''}
            renderGroup={AutocompleteGroup}
            renderOption={(props, option) => (
              <OptionWrapper
                data-id="e2e_org-crew"
                $type={option.type}
                {...props}
              >
                {formatListCrewItem(option)}
              </OptionWrapper>
            )}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                inputRef={inputRef}
                label={inputLabel}
                placeholder="Name (Rank) - Country - Airport"
                InputProps={{
                  ...params.InputProps,
                  // show loader when fetching crew
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="primary" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              id: 'select-crew',
              style: autoCompleteStyles,
            }}
            PopperComponent={StyledPopper}
            sx={{ width: 650 }}
          />
        </InputWrapper>

        <ActionButton
          className="e2e_add-selected-org-crew"
          variant="primary"
          disabled={!selectedCrew}
          onClick={handleAddSelected}
        >
          Add Selected
        </ActionButton>
        {selecting && (
          <CloseButton variant="delete" onClick={handleReset}>
            Reset
          </CloseButton>
        )}

        {renderModal()}
      </Container>

      {showWarning && <ErrorWrapper>Type 2 or more characters...</ErrorWrapper>}
    </>
  );
}

export default memo(SelectCrew);
