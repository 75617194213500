import once from 'lodash/once';
import uniqueId from 'lodash/uniqueId';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro } from '@mui/x-data-grid-pro';

import {
  useAppDispatch,
  useDebounce,
  useModal,
  useReadOnlyPlanningData,
} from 'hooks';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { setVesselJourney } from 'redux/actions';
import {
  selectCrewChangePanel,
  selectMapVessels,
  selectSettings,
} from 'redux/selectors';
import { TRACK_REMOVE_PORT_OF_CALL } from 'utils/analytics/constants';
import { JourneyVessel } from 'components/SidePanel/VesselCourse/types';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { RouteCalculatorContext } from 'contexts/RouteCalculatorContext';

import { CommonConfirmModal } from 'components/shared';
import { getGridColumns, renderHeader } from './Header';
import { PlanningRouteActions } from '../Actions/Route';
import RoutePortModal from '../Actions/Route/RoutePortModal';
import Controls from '../../common/Controls';
import { NoRowsOverlay } from '../../common/TableItems';

import { headerStyles, TableWrapper } from '../../common';
import {
  formatRoutePorts,
  sortRoutePorts,
  addPortToRow,
  updateVesselRoute,
  addDistanceAndTime,
  setActionTime,
  getPreviousPort,
  formatPortDates,
} from '../../helpers';
import { RoutePort } from '../../types';

function RouteTable(): JSX.Element {
  const {
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);
  const dispatch = useAppDispatch();
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const { vesselId, futureRoute: journey } = useSelector(selectCrewChangePanel);

  const {
    data,
    reportInfo: { incompletePlanId },
    updateReportInfo,
    updatePlanningData,
    route: vesselRoute,
    setRoute,
  } = useContext(CCPanelContext);
  const { getVesselRoute: calculateRoute } = useContext(RouteCalculatorContext);

  const { saveIncompletePlan } = useReadOnlyPlanningData();
  const { modal, setModal } = useModal();
  const [routePorts, setRoutePorts] = useState<RoutePort[]>(
    (data as RoutePort[]) || []
  );

  const { distances, unit } = vesselRoute || {};
  const vessel = useMemo(() => vessels.get(vesselId!), [vesselId, vessels]);
  const futurePorts = useMemo(
    () => journey.filter(({ componentType }) => componentType === 'port'),
    [journey]
  );

  const handleUpdatePanelData = useDebounce(
    (ports: RoutePort[], gaps: number[]) => {
      updatePlanningData((prevData) => ({
        ...prevData,
        route: addDistanceAndTime(ports, gaps),
      }));
    },
    300
  );

  // set the time required to finish this step
  useEffect(() => {
    setActionTime('route', 'start');
    // trigger saving of incomplete plan
    once(saveIncompletePlan)(incompletePlanId).then((planId) => {
      updateReportInfo({ type: 'incompletePlanId', payload: planId });
    });

    return () => {
      setActionTime('route', 'end');
    };
  }, []); // eslint-disable-line

  // initialize route ports state from redux store
  useEffect(() => {
    if (futurePorts.length && !routePorts.length) {
      const futurePortsOnly = futurePorts.filter(
        (port) => port.componentType === 'port'
      ) as RoutePort[];
      setRoutePorts(formatRoutePorts(futurePortsOnly));
    }
  }, [futurePorts, routePorts]); // eslint-disable-line

  // update `vesselRoute` with adding/removing/updating of a port in the table
  useEffect(() => {
    updateVesselRoute(journey, vessel!, calculateRoute).then((newRoute) => {
      setRoute(newRoute);
    });
  }, [journey, vessel]); // eslint-disable-line

  useEffect(() => {
    if (distances?.length) {
      handleUpdatePanelData(routePorts, distances);
    }
  }, [routePorts, distances]); // eslint-disable-line

  const handleUpdateRoutePorts = useCallback(
    async (ports: RoutePort[], canUpdateJourney?: boolean) => {
      const sortedList = sortRoutePorts(ports);
      setRoutePorts(sortedList);
      const vesselLocation = journey.filter(
        ({ componentType }) => componentType === 'vessel'
      ) as JourneyVessel[];

      if (canUpdateJourney) {
        const updatedJourney = [...vesselLocation, ...sortedList];
        dispatch(setVesselJourney(updatedJourney));
      }
    },
    [journey] // eslint-disable-line
  );

  const handleAddOrUpdate = (port: RoutePort) => {
    const addedPort = { ...port, ...formatPortDates(port) };
    const { ports, invalidMessage } = addPortToRow(addedPort, routePorts);
    if (invalidMessage) {
      return showToaster({ message: invalidMessage, type: 'error' });
    }
    handleUpdateRoutePorts(ports, true);
  };

  const handleRemove = (order: number) => {
    trackUserAction(TRACK_REMOVE_PORT_OF_CALL);
    const portList = routePorts.filter((port) => order !== port.order);
    handleUpdateRoutePorts(portList, true);
    setModal(null);
  };

  const columns = useMemo(
    () =>
      getGridColumns(unit, setModal).map((item) => ({
        ...item,
        flex: 1,
        sortable: false,
        headerClassName: 'table-header',
        maxWidth: item.maxWidth || 300,
        renderHeader,
      })),
    [unit, setModal]
  );
  const rows = useMemo(
    () => addDistanceAndTime(routePorts, distances),
    [routePorts, distances]
  );

  const renderModal = () => {
    if (!modal?.type) return null;

    const {
      port: { order },
    } = modal.data;
    const previousPort = getPreviousPort(order, routePorts);
    const closeModal = () => setModal(null);

    if (modal.type === 'routePort') {
      return (
        <RoutePortModal
          {...modal.data}
          onClick={handleAddOrUpdate}
          closeModal={closeModal}
          previousPort={previousPort}
        />
      );
    }

    if (modal.type === 'confirmRemove') {
      return (
        <CommonConfirmModal
          type="remove"
          description="The port will be removed from the vessel route plan."
          onCancel={closeModal}
          onConfirm={() => {
            handleRemove(order);
            closeModal();
          }}
        />
      );
    }
  };

  return (
    <>
      <Controls disableNext={!rows.length}>
        {Boolean(journey.length) && (
          <PlanningRouteActions
            routePorts={routePorts}
            addOrUpdate={handleAddOrUpdate}
            remove={handleRemove}
          />
        )}
      </Controls>

      <TableWrapper>
        <DataGridPro
          columns={columns}
          rows={rows}
          checkboxSelection={false}
          disableMultipleSelection
          disableColumnMenu
          disableSelectionOnClick
          disableColumnReorder
          hideFooter
          getRowId={uniqueId}
          density={isCompact ? 'compact' : 'standard'}
          sx={headerStyles}
          getRowClassName={({ row }) => (row.added ? 'added-port' : '')}
          components={{ NoRowsOverlay }}
          componentsProps={{
            noRowsOverlay: { loading: !journey.length, type: 'ports' },
          }}
        />
      </TableWrapper>

      {renderModal()}
    </>
  );
}

export default memo(RouteTable);
