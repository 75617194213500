import { removeWhiteSpace, secondsToDHM } from 'lib/common';
import {
  MISSING_FLIGHT_TEXT,
  NOT_REQUIRED_TEXT,
  REQUEST_BOOKING_TEXT,
} from 'lib/constants';
import { formatDate } from 'utils/format-date';
import { Crew } from 'utils/types/crew-change-types';
import { getLayoverDetails } from 'components/FlotillaSearch/helpers';

import { getLayovers } from './common';
import { getLatestCrewVisa } from './crew';
import { getPortAgencyPreBody, getSubject } from './email-fields';
import { formatFlightPath } from './flights';
import {
  CrewDetails,
  EmailDetailInputs,
  EmailDetailsInfo,
  EmailTemplate,
  SelectedFields,
  VesselDetails,
} from '../types';

const getEmailBody = ({
  company,
  vessel,
  template,
}: {
  company?: string;
  vessel: VesselDetails;
  template: EmailTemplate;
}): string => {
  if (template === 'port-agency-announcement') {
    const message = `Good day,
${getPortAgencyPreBody(vessel)}

Please be so kind and confirm:

1. Crew change cost
2. Immigration formalities – PP/ SBK copies herewith attached
3. Restrictions due to covid-19

Additionally, kindly confirm receipt of this message as well as your attendance to this crew change.

In case any further information is required on our behalf, pls don’t hesitate to contact me at anytime.`;
    return message;
  } else if (template === 'book-flight') {
    const message = `Good day,

This is the ${
      company || ''
    } crewing team reaching out to arrange a crew change for our subject vessel ${
      vessel.name
    }.

We have identified the below Marine Fares on Greywing and would appreciate if you could provide us quotations for the same fares or feel free to suggest better options. Also advise on any visa requirements/restrictions for on/offsigners, including transit visa, immigration requirements.

Below are vessel and on/off crew member information, including passport details:`;

    return message;
  }
  // invalid template
  return '';
};

export const getEmailMessage = (data: {
  name: string;
  emailDetails: EmailDetailsInfo;
  template: EmailTemplate;
}): {
  subject: string;
  message: string;
} => {
  const { name, emailDetails, template } = data;
  const { company, vesselDetails } = emailDetails;
  const subject = getSubject(vesselDetails, company);
  const message = getEmailBody({
    company: name,
    vessel: vesselDetails,
    template,
  });
  return { subject, message };
};

const formatCrewWithoutFlight = (crew: Crew, added?: boolean): CrewDetails => ({
  id: crew.id,
  cid: crew.cid || '-',
  name: crew.name || '-',
  rank: crew.rank || '-',
  manningOffice: crew.manningOffice || '-',
  type: crew.type.toUpperCase(),
  country: crew.country || '-',
  sex: crew.sex || '-',
  birthday: crew.birthday || '-',
  passportNumber: crew.passportNumber || '-',
  passportExpiry: crew.passportExpiry || '-',
  passportIssued: crew.passportIssued || '-',
  seamenBookNumber: crew.seamenBookNumber || '-',
  seamenBookExpiry: crew.seamenBookExpiry || '-',
  seamenBookIssued: crew.seamenBookIssued || '-',
  seamenBookIssuedCountryIso3: crew.seamenBookIssuedCountryIso3 || '-',
  visaCountry: getLatestCrewVisa(crew).visaCountry || '-',
  visaExpiry: getLatestCrewVisa(crew).visaExpiry || '-',

  // fields for flight
  flight: added ? REQUEST_BOOKING_TEXT : MISSING_FLIGHT_TEXT,
  flightFareType: '-',
  time: '-',
  departure: '-',
  arrival: '-',
  flightNumbers: '-',
  flightLayoverTime: '',
  layoverDetails: [],
  segments: [],
  cost: '-',
  classCodes: '-',
  reason: 'Crew Change',
});

export const formatEmailDetails = ({
  crew: crewList,
  port,
  vessel,
  flights,
  additionalCrew,
}: EmailDetailInputs): EmailDetailsInfo => {
  const vesselDetails = {
    imo: vessel.imo || '-',
    name: vessel.name || '-',
    owner: vessel.owner || '-',
    destination: port?.name || '-',
    eta: port.eta ? formatDate(port.eta, 'HH:mm, DD MMM') : '-',
    etd: port.etd ? formatDate(port.etd, 'HH:mm, DD MMM') : '-',
    type: vessel.type || '-',
  };
  const crewDetails = crewList.reduce((acc: CrewDetails[], crew) => {
    const crewFlight = flights.find((f) => f.crew.id === crew.id);
    const flightPath = crewFlight && formatFlightPath(crewFlight);
    const notRequiredFlight =
      crew.homeAirport?.iataCode! === port.selectedAirport?.iataCode!;

    // handle cases when crew don't have a flight
    if (!flightPath && !notRequiredFlight) {
      // find if the crew is missing flight & included by user
      const includeMissingFlightCrew = additionalCrew?.some(
        ({ id }) => crew.id === id
      );
      return [
        ...acc,
        // include only the selected crew by user for the email
        // format before including to the output
        ...(includeMissingFlightCrew
          ? [formatCrewWithoutFlight(crew, true)]
          : []),
      ];
    }

    let connection = '';
    const {
      stops = [],
      segments = [],
      arrival,
      departure,
      price,
      flightNumbers = [],
      totalFlightTime,
    } = crewFlight || {};
    const { airports: layovers = [], time: layoverTime = 0 } = crewFlight
      ? getLayovers(crewFlight)
      : {};
    const flightTime = totalFlightTime
      ? secondsToDHM(totalFlightTime * 3600)
      : undefined;

    if (flightPath) {
      const [from, to] = JSON.parse(flightPath);
      connection = [from, ...layovers, to].join(' > ');
    }

    const details: CrewDetails = {
      id: crew.id,
      cid: crew.cid || '-',
      name: crew.name || '-',
      rank: crew.rank || '-',
      manningOffice: crew.manningOffice || '-',
      type: crew.type.toUpperCase(),
      country: crew.country || '-',
      flight: connection || NOT_REQUIRED_TEXT,
      time: flightTime || '-',
      departure: departure ? formatDate(departure.time, 'HH:mm, DD MMM') : '-',
      arrival: arrival ? formatDate(arrival.time, 'HH:mm, DD MMM') : '-',
      cost:
        price?.amount && price?.currency
          ? `${price?.amount.toFixed(0)} ${price?.currency}`
          : '-',
      sex: crew.sex || '-',
      birthday: crew.birthday || '-',
      passportNumber: crew.passportNumber || '-',
      passportExpiry: crew.passportExpiry || '-',
      flightNumbers: flightNumbers.map(removeWhiteSpace).join(', '),
      // flight layover time & details for the excel report
      flightLayoverTime: secondsToDHM(layoverTime * 3600),
      layoverDetails: stops.map((stop) => getLayoverDetails(stop, segments)),
      // additional things for crewlink integration
      flightFareType: crewFlight?.type?.type || '-',
      passportIssued: crew.passportIssued || '-',
      seamenBookNumber: crew.seamenBookNumber || '-',
      seamenBookExpiry: crew.seamenBookExpiry || '-',
      seamenBookIssued: crew.seamenBookIssued || '-',
      seamenBookIssuedCountryIso3: crew.seamenBookIssuedCountryIso3 || '-',
      visaCountry: getLatestCrewVisa(crew).visaCountry || '-',
      visaExpiry: getLatestCrewVisa(crew).visaExpiry || '-',
      segments,
      classCodes:
        crewFlight?.crewLinkFlightSummary?.segments
          .map(
            (o) =>
              `${(o.airlineCode || '').trim()} (${o.bookingClassCode || ''})`
          )
          .join(', ') || '-',
      reason: 'Crew Change',
    };

    return [...acc, details];
  }, []);

  return { vesselDetails, crewDetails };
};

// filters only the selected/checked items in send modal
// include all fields for port agency announcement template
export const filterEmailDetails = (
  emailDetails: EmailDetailsInfo,
  selectedFields: SelectedFields,
  template: EmailTemplate
): EmailDetailsInfo => {
  const { vessel, crew } = selectedFields;
  const { company, vesselDetails, crewDetails } = emailDetails;
  const filteredVesselDetails = Object.keys(vesselDetails).reduce(
    (acc, field) =>
      vessel[field as keyof VesselDetails]
        ? { ...acc, [field]: vesselDetails[field as keyof VesselDetails] }
        : acc,
    {} as VesselDetails
  );
  const filteredCrewDetails = crewDetails.map((crewInfo) =>
    Object.keys(crewInfo).reduce(
      (acc, field) =>
        crew[field as keyof CrewDetails]
          ? { ...acc, [field]: crewInfo[field as keyof CrewDetails] }
          : acc,
      {} as CrewDetails
    )
  );

  return {
    company,
    vesselDetails: filteredVesselDetails,
    crewDetails:
      template === 'port-agency-announcement'
        ? crewDetails
        : filteredCrewDetails,
  };
};

// insert crew with missing flights inside crew details prepared for email
export const includeCrewWithoutFlight = (
  crewDetails: CrewDetails[],
  planningCrew: Crew[]
) =>
  planningCrew.map((crew) => {
    const crewWithFlight = crewDetails.find(({ id }) => id === crew.id);
    return crewWithFlight || formatCrewWithoutFlight(crew);
  });

export const getSummaryCrewRowClassName = ({ row }: { row: CrewDetails }) => {
  const crewWithoutFlight = [
    REQUEST_BOOKING_TEXT,
    MISSING_FLIGHT_TEXT,
  ].includes(row.flight);

  return crewWithoutFlight
    ? (row.flight === MISSING_FLIGHT_TEXT && 'missing-flight') ||
        'requested-flight'
    : '';
};
