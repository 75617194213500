import { createAsyncThunk } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import { setManageBookingMetaData, updateBookings } from 'redux/actions';
import { RootState } from 'redux/types';
import { getFlightBookings, getTmcFlightBookings } from 'api/flotilla';
import { mergeDictWithTransaction } from 'components/shared/FlightBook/helpers';
import type { ListFlightBookingTxQueryParams } from '@greywing-maritime/frontend-library/dist/types/flightBooking';
import { BookingTransaction } from 'components/shared/FlightBook/types';

/* ----- Async Thunks ----- */
export const fetchBookingsAsync = createAsyncThunk(
  'booking/fetchBookings',
  async (
    {
      nextPageToken,
      ...states
    }: ListFlightBookingTxQueryParams & { nextPageToken?: string },
    thunkApi
  ) => {
    const { dispatch, getState } = thunkApi;
    const submissionObject = Object.assign(
      {},
      states,
      nextPageToken ? { pageToken: nextPageToken } : undefined
    );
    if (nextPageToken) {
      delete submissionObject.pageSize;
    }
    const { success, data } = await getFlightBookings(submissionObject);
    if (success && data) {
      const bookingObject = data.transactions.reduce<{
        [id: string]: BookingTransaction;
      }>((objMap, booking) => {
        objMap[booking.id] = mergeDictWithTransaction({
          transaction: booking,
          dictionaries: data.dictionaries,
        });
        return objMap;
      }, {});
      const bookingTransactions = data.transactions.map((o) => o.id);
      let bookingList: string[] = bookingTransactions;
      if (nextPageToken) {
        const existingList = [...(getState() as RootState).booking.bookingList];
        bookingList = uniq(existingList.concat(bookingTransactions));
      }
      dispatch(
        setManageBookingMetaData({
          hasNextPage: data.nextPageToken || undefined,
          totalCount: data.totalCount,
        })
      );
      dispatch(
        updateBookings({
          bookings: bookingObject,
          bookingList,
        })
      );

      return data.transactions;
    }
    return null;
  }
);

export const fetchTmcBookingsAsync = createAsyncThunk(
  'booking/fetchBookings',
  async (
    {
      nextPageToken,
      ...states
    }: ListFlightBookingTxQueryParams & { nextPageToken?: string },
    thunkApi
  ) => {
    const { dispatch, getState } = thunkApi;
    const submissionObject = Object.assign(
      {},
      states,
      nextPageToken ? { pageToken: nextPageToken } : undefined
    );
    if (nextPageToken) {
      delete submissionObject.pageSize;
    }
    const { success, data } = await getTmcFlightBookings(submissionObject);
    if (success && data) {
      const bookingObject = data.transactions.reduce<{
        [id: string]: BookingTransaction;
      }>((objMap, booking) => {
        objMap[booking.id] = mergeDictWithTransaction({
          transaction: booking,
          dictionaries: data.dictionaries,
        });
        return objMap;
      }, {});
      const bookingTransactions = data.transactions.map((o) => o.id);
      let bookingList: string[] = bookingTransactions;
      if (nextPageToken) {
        const existingList = [...(getState() as RootState).booking.bookingList];
        bookingList = uniq(existingList.concat(bookingTransactions));
      }
      dispatch(
        setManageBookingMetaData({
          hasNextPage: data.nextPageToken || undefined,
          totalCount: data.totalCount,
        })
      );
      dispatch(
        updateBookings({
          bookings: bookingObject,
          bookingList,
        })
      );

      return data.transactions;
    }
    return null;
  }
);
