import { Dispatch, memo, SetStateAction, useContext } from 'react';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import styled from 'styled-components/macro';

import { useFiltersChange } from 'hooks';
import {
  blue,
  fadedGreen,
  green,
  lightFadedGreen,
  lightPurple,
} from 'lib/colors';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { AwesomeSlider, CloseButton } from 'components/shared';
import CustomFilters from './CustomFilters';
import { ItemWrapper, StyledSliderWrapper, ThumbHour } from '../common';
import {
  MenuTitle,
  MenuSeparator as Divider,
  MenuFiltersWrapper,
  MenuDescription,
} from '../../../../common';
import { MAX_FLIGHT_TIME } from '../../../../helpers';
import { filterButtonStyles, Label, StyledButton } from '../../../../common';
import { FlightFilterType } from '../../../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MenuWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-right: 1rem;
  margin-bottom: 0.25rem;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
  align-self: strech;
`;

const ActionButton = styled(StyledButton)<{
  $active?: boolean;
  $green: boolean;
}>`
  ${filterButtonStyles};
  ${({ $active, $green }) =>
    !$active &&
    ($green
      ? `
      background-color: ${lightFadedGreen};
      color: ${green};
      `
      : `
      background-color: ${lightPurple};
      color: ${blue};
      `)};
  ${({ $active, $green }) =>
    $active &&
    $green &&
    `
      background-color: ${fadedGreen};
      color: #fff;
  `};
`;

const menuStyles = {
  style: { width: 550 },
};

const FLIGHT_TYPES: FlightFilterType[] = ['Lowest CO2', 'Fastest', 'Cheapest'];

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  locodeKey: string;
};

function FiltersMenu({ anchorEl, locodeKey, setAnchorEl }: Props) {
  const { filters: allFilters } = useContext(CCPanelContext);

  const filters = allFilters[locodeKey];
  const { min, max } = filters.range;
  const { localFilters, changeFilters } = useFiltersChange({
    locodeKey,
    componentType: 'menu',
  });

  if (!localFilters) {
    return null;
  }

  const { type, time } = localFilters;
  // setting the max values to current filter value if less than
  // otherwise, fallback to some default max value
  const maxFlightTime =
    (max?.flightTime && (max.flightTime <= time ? time : max.flightTime)) ??
    MAX_FLIGHT_TIME;

  return (
    <Menu
      id="flight-filters-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      TransitionComponent={Fade}
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={menuStyles}
    >
      <MenuWrapper>
        <MenuTitle>Flight Filters</MenuTitle>
        <StyledCloseButton
          data-id="e2e_more-filters-close-button"
          size={20}
          onClick={() => setAnchorEl(null)}
        />
      </MenuWrapper>

      <Divider />

      <MenuFiltersWrapper>
        <MenuDescription>
          Apply or update filters to find best flights. Only unconfirmed flights
          will be updated in the panel.
        </MenuDescription>
        <ItemWrapper>
          <Label>Category</Label>
          <ButtonsWrapper>
            {FLIGHT_TYPES.map((text) => (
              <ActionButton
                key={text}
                variant="primary"
                $active={type === text}
                $green={text === 'Lowest CO2'}
                onClick={() => changeFilters({ type: text })}
              >
                {text}
              </ActionButton>
            ))}
          </ButtonsWrapper>
        </ItemWrapper>

        <CustomFilters locodeKey={locodeKey} />

        <ItemWrapper>
          <Label>Total Flight Time</Label>
          <StyledSliderWrapper>
            <Stack spacing={2} direction="row" alignItems="center">
              <AwesomeSlider
                data-id="e2e_filters-flight-time"
                min={min.flightTime}
                max={maxFlightTime}
                disabled={maxFlightTime === min.flightTime}
                value={time}
                size="small"
                step={1}
                onChange={(_, newValue) =>
                  changeFilters({ time: newValue as number })
                }
                components={{ Thumb: ThumbHour }}
              />
            </Stack>
          </StyledSliderWrapper>
        </ItemWrapper>
      </MenuFiltersWrapper>
    </Menu>
  );
}

export default memo(FiltersMenu);
