import React, { forwardRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { green } from 'lib/colors';
import { selectCrewChangePanel } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import Tooltip from 'components/shared/Tooltip';
import AnchorRoundedIcon from '@mui/icons-material/AnchorRounded';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 1.4rem !important;
    color: ${green};
  }
`;

const PortCounter = forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { active: activity, readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const { planningData } = useContext(CCPanelContext);

  const isReportView = activity === 'readOnly' && reportData;
  const portList = isReportView
    ? reportData.crewChangePlan.ports
    : planningData.ports;

  return (
    <Wrapper ref={ref}>
      {portList.map((port) => (
        <Tooltip content={port.name} key={port.id}>
          <StyledIcon>
            <AnchorRoundedIcon />
          </StyledIcon>
        </Tooltip>
      ))}
    </Wrapper>
  );
});

export default PortCounter;
