import { MouseEvent, useState } from 'react';
import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styled from 'styled-components/macro';

import { blue, gray80, white } from 'lib/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const Wrapper = styled.div`
  margin-top: -2px;
  margin-right: -8px;
`;

const menuItemStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.9rem',
  lineHeight: 1,
  fontWeight: '500',
  color: gray80,
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 100ms ease',
  '&:hover': {
    background: blue,
    color: white,
  },
  svg: {
    fontSize: '16px !important',
  },
};

type Props = {
  onEdit: () => void;
  onRemove: () => void;
};

function MoreButton({ onEdit, onRemove }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Wrapper>
        <IconButton size="small" onClick={handleClick}>
          <MoreVertIcon
            sx={{
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
            }}
            fontSize="inherit"
          />
        </IconButton>
      </Wrapper>

      <Menu
        id="fade-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{ marginTop: '1px' }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
            setAnchorEl(null);
          }}
          sx={menuItemStyles}
        >
          <EditIcon sx={{ marginRight: '0.5rem' }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            onRemove();
            setAnchorEl(null);
          }}
          sx={menuItemStyles}
        >
          <DeleteRoundedIcon sx={{ marginRight: '0.5rem' }} />
          Remove
        </MenuItem>
      </Menu>
    </>
  );
}

export default MoreButton;
