import { BREAK_POINT_XS } from 'lib/breakpoints';
import styled from 'styled-components/macro';
import ButtonV2 from '../ButtonV2';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Button } from 'components/shared';
import IconButton from 'components/shared/IconButton';
import { red } from 'lib/colors';

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: auto 85px;

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    grid-template-columns: auto;
    #submit-area {
      display: none;
    }
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  > div {
    align-self: flex-start;
    flex: 1 1 100%;
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-direction: column;
  }
`;

export const Label = styled.label`
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  font-size: 1rem;
  font-weight: 700;
`;

export const FormContainer = styled.div`
  p,
  h3 {
    margin: 0 0 0.5rem 0;
  }
  height: 100%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    padding-right: 0;
  }
`;

export const FormInputs = styled.div`
  position: relative;
  padding: 1rem;
  overflow-y: auto;
  height: calc(100% - 130px); // 2*65px - header + footer
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  column-gap: 0.25rem;
  overflow-x: hidden;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  #action {
    display: none;
    @media screen and (max-width: ${BREAK_POINT_XS}) {
      display: block;
    }
  }
`;

export const TallButton = styled(ButtonV2)`
  padding: 1rem 0.5rem;
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  font-size: 0.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none !important;
  #loading {
    border-radius: 0 4px 4px 0 !important;
  }
  svg {
    font-size: 18px !important;
  }
`;

export const HeaderWithButton = styled.div`
  display: flex;
  column-gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  min-height: 42px;
  width: 100%;
  height: 65px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  h3 {
    margin: 0;
    padding: 1rem;
  }
`;

export const SubmitArea = styled.div`
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    display: none;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  column-gap: 2rem;
  min-height: 45px;
  font-weight: 600;
  .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 8px !important;
  row-gap: 0.5rem;
`;

export const StyledButton = styled(Button)`
  transform: unset !important;
  margin-right: unset;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  font-weight: 600;
  height: 2.5rem;
`;

export const MultipleButtons = styled.div`
  display: flex;
  column-gap: 0.5rem;
`;

export const StyledButtonV2 = styled(ButtonV2)`
  min-height: 100%;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 0.7rem;
  border-radius: 0 4px 4px 0;
`;

export const CloseButton = styled(IconButton)`
  background-color: ${red} !important ;
`;
