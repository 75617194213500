import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { loadInitialAppSettings } from '../helpers/settings';
import { AppSettings, RootState } from '../types';

export const initialSettings: AppSettings = loadInitialAppSettings();

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettings,
  // Any action that updates store should be a thunk as in `redux/thunks/settings.ts` file
  reducers: {
    // Updates store after saving to the backend
    updateStoreSettings: (state, action: PayloadAction<AppSettings>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

/* ----- selectors -----*/

export const selectSettings = ({ settings }: RootState) => settings;

export const selectUserInfo = createSelector(
  [selectSettings],
  ({ userInfo }) => userInfo
);

/* ----- actions -----*/

export const { updateStoreSettings } = settingsSlice.actions;

/* ----- reducer -----*/

export default settingsSlice.reducer;
