import { SyntheticEvent, useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styled from 'styled-components/macro';
import { showToaster } from 'lib/toaster';

import { gray80, lightPurple } from 'lib/colors';

import { Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';
import { UserProps, PICDataSetStateProps } from '../../types';
import { assignPIC } from 'api/flotilla';
import { updateInCharge } from 'redux/actions';
import { useAppDispatch } from 'hooks';
import { getUserName } from '../../helpers';

const Wrapper = styled.div`
  padding: auto 0.5rem;
  overflow: visible;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const Instruction = styled.div`
  font-size: 0.9rem;
  color: ${gray80};
  margin-bottom: 1.2rem;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const autoCompleteStyles = {
  maxHeight: '400px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
  overflow: 'visible',
};

type Props = {
  updatePICData: PICDataSetStateProps;
  closeModal: () => void;
  vessel: number;
  users: UserProps[];
};

const getUserNameWithEmail = (user: UserProps) => {
  const userName = getUserName(user);
  if (userName === '-') return user.email;
  return `${getUserName(user)} (${user.email})`;
};

function AssignPICModal({ updatePICData, closeModal, vessel, users }: Props) {
  const [selected, setSelected] = useState<UserProps | string | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAppDispatch();

  const handleAssignPIC = useCallback(async () => {
    if (!selected || typeof selected === 'string') return;

    setSubmitting(true);
    const { success, message } = await assignPIC(selected.id, [vessel]);
    showToaster({
      message,
      type: success ? 'success' : 'error',
    });
    setSubmitting(false);

    if (success) {
      updatePICData((prev) => {
        return prev.map((obj) => {
          if (obj.id === vessel) {
            return {
              ...obj,
              email: selected.email,
              pic: getUserName(selected),
            };
          }
          return obj;
        });
      });
      dispatch(
        updateInCharge({
          ids: [vessel],
          userInfo: {
            id: selected.id,
            firstname: selected.firstName,
            lastname: selected.lastName,
            loginEmail: selected.email,
          },
        })
      );
      closeModal();
    }
  }, [selected, vessel, updatePICData, dispatch, closeModal]);

  const handleSelect = (
    event: SyntheticEvent,
    value: UserProps | string | null
  ) => {
    const selectedUser = !value || typeof value === 'string' ? null : value;
    setSelected(selectedUser);
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton
        variant="primary"
        disabled={!selected || submitting}
        onClick={handleAssignPIC}
      >
        Assign
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal
      width={400}
      title="Assign New PIC"
      actions={actions}
      closeModal={closeModal}
    >
      <Wrapper>
        <Instruction>
          Type User Name or Email to assign them as PIC for the vessel.
        </Instruction>

        <Autocomplete
          freeSolo
          size="small"
          options={users}
          value={selected || null} // fallback to `null` for no option
          onChange={handleSelect}
          getOptionLabel={(user) =>
            `${getUserNameWithEmail(user as UserProps)}`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Name or Email"
              placeholder="Enter PIC Name or Email"
            />
          )}
          ListboxProps={{ style: autoCompleteStyles }}
          sx={{ mr: 2, width: '100%' }}
        />
      </Wrapper>
    </Modal>
  );
}

export default AssignPICModal;
