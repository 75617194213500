import isEqual from 'lodash/isEqual';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { CrewChangeEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

export const hasHistoricalEvent = (
  vessel: Vessel,
  ccEvent: CrewChangeEvent | null
) => {
  const vesselEvents = vessel.upcomingCrewChangeEvents
    .map(({ id }) => id)
    .sort((a, b) => a - b);
  const fetchedEvents = (ccEvent?.crewChangeEvents || [])
    .map(({ id }) => id)
    .sort((a, b) => a - b);

  return !isEqual(vesselEvents, fetchedEvents);
};
