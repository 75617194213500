import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

import { logInDevelopment } from 'lib/log';
import { getPortRequestProgress } from 'lib/alasql/flights';
import { Port } from 'utils/types/crew-change-types';

import {
  getLocodeKeyDetails,
  initialFetchStatus,
  initialFlightFilters,
} from './common';
import {
  getBestFlights,
  getLocodeKeyFromPortData,
  getPortDepartures,
} from './flights';
import { mergePortsWithRoute } from './route';
import { READ_ONLY_FLIGHT_FILTERS } from './readOnly';
import {
  ActiveFlight,
  FlightFiltersReducer,
  FlightRow,
  FlightFilters,
  ConfirmedFlight,
  FlightParamsRange,
  FetchStatusReducer,
  MergedPort,
  CommonReducer,
  TableState,
  ReportInfoState,
} from '../types';

const NESTED_FILTERS = [...READ_ONLY_FLIGHT_FILTERS];

// sets the initial values of the dynamically calculated filter values
export const setFlightParamValues = (
  currentFilters: FlightFilters,
  range: FlightParamsRange
) => {
  const { time, layover, stopsCount } = currentFilters;
  const { min, max } = range;

  if (!max) {
    return { time, layover, stopsCount };
  }

  // show filter values set in settings, if between dynamic min & max
  // otherwise, show the mean value between min & max
  return {
    time:
      min.flightTime <= time && time <= max.flightTime ? time : max.flightTime,
    stopsCount:
      min.stopsCount <= stopsCount && stopsCount <= max.stopsCount
        ? stopsCount
        : max.stopsCount,
    layover, // use layover from settings, instead of dynamic range
    range,
  };
};

export const flightFiltersReducer: FlightFiltersReducer = (state, action) => {
  const { type, payload } = action;

  logInDevelopment('Filters updated - ', {
    type,
    payload,
    initialState: state,
  });

  switch (type) {
    /* ------------------------------------------- */
    /* ------------ Iterating update ------------- */
    /* ------------------------------------------- */

    case 'INITIALIZE_ALL_FILTERS': {
      const { ports, routePorts } = payload;
      return mergePortsWithRoute(ports, routePorts).reduce((acc, port) => {
        const { locode, eta, etd, uniqETA } = port;
        const locodeKey = uniqETA ? `${locode}(${uniqETA})` : locode;
        return {
          ...acc,
          [locodeKey]: {
            ...(state[locodeKey] || initialFlightFilters),
            uniqETA,
            portDates: { eta, etd },
            locode: port.locode,
            departures: getPortDepartures(port as MergedPort),
          },
        };
      }, {});
    }

    case 'HYDRATE_FILTERS': {
      const { ports, flightParams, defaultTMC } = payload;
      const { time, layover, stops, allowAirportTransfer } = flightParams;
      return Object.keys(state).reduce((acc, locodeKey, index) => {
        // find out original locode from filters key
        const { locode: originalLocode, flightSource } =
          getLocodeKeyDetails(locodeKey);
        const port = (ports as Port[]).find(
          ({ locode }) => locode === originalLocode
        )!;
        const {
          source: prevSource,
          settingsLoaded: alreadyLoadedFromSettings,
        } = state[locodeKey];
        // update filters with flight & cost params from settings if NOT already
        const settingsFlightParams = {
          time,
          layover,
          stopsCount: stops,
          allowAirportTransfer,
        };
        // hydrate the current filters with flight & cost params from settings
        const commonFilters = {
          ...state[locodeKey],
          ...(!alreadyLoadedFromSettings ? settingsFlightParams : {}),
          source:
            flightSource ||
            // fallback to default TMC for initial filters hydration/update
            (alreadyLoadedFromSettings ? prevSource : defaultTMC),
          settingsLoaded: true,
        };

        return {
          ...acc,
          [locodeKey]: {
            ...commonFilters,
            active: index === 0,
            portAirport: port.selectedAirport?.iataCode || '',
          },
        };
      }, {});
    }

    case 'CHANGE_PORT': {
      const { port, source } = payload;
      const { locode, uniqETA } = port;
      const baseLocode = uniqETA ? `${locode}(${uniqETA})` : locode;
      const locodeKey = source
        ? `${baseLocode}--${source}`
        : getLocodeKeyFromPortData(port);

      return Object.keys(state).reduce(
        (acc, locode) => ({
          ...acc,
          [locode]: {
            ...state[locode],
            active: locode === locodeKey,
          },
        }),
        {}
      );
    }

    case 'UPDATE_PORT_AIRPORT': {
      const { locode, airport, updateFetchStatus } = payload;
      updateFetchStatus({ type: 'RESET_PORT_PROGRESS', payload: { locode } });
      // update port airports for original & duplicated ports with matching locode
      return Object.keys(state).reduce(
        (acc, locodeKey) => ({
          ...acc,
          [locodeKey]: {
            ...state[locodeKey],
            ...(locode === getLocodeKeyDetails(locodeKey).locode // use original locode
              ? // reset the confirmed flights for this port-card
                { portAirport: airport.iataCode, confirmed: [] }
              : {}),
          },
        }),
        {}
      );
    }

    case 'SET_PREFERRED_PORT': {
      const { locode: preferredLocodeKey, preferred: checked } = payload;
      return Object.keys(state).reduce(
        (acc, locodeKey) => ({
          ...acc,
          [locodeKey]: {
            ...state[locodeKey],
            preferred: locodeKey === preferredLocodeKey ? checked : false,
          },
        }),
        {}
      );
    }

    case 'REMOVE_DUPLICATE': {
      const { locodeKey } = payload;
      const { active: currentActive } = state[locodeKey]; // find if removing card is active
      return Object.keys(state)
        .filter((key) => key !== locodeKey)
        .reduce(
          (acc, key, index) => ({
            ...acc,
            [key]: {
              ...state[key],
              ...(currentActive ? { active: index === 0 } : {}),
            },
          }),
          {}
        );
    }

    // update flight filters for all port-cards to settings flight params
    case 'UPDATE_SETTINGS_PARAMS': {
      const { flightParams } = payload;
      const { time, layover, stops, allowAirportTransfer } = flightParams;
      return Object.keys(state).reduce(
        (acc, locodeKey) => ({
          ...acc,
          [locodeKey]: {
            ...state[locodeKey],
            time,
            layover,
            stopsCount: stops,
            allowAirportTransfer,
          },
        }),
        {}
      );
    }

    /* --------------------------------------- */
    /* ---------- Individual update ---------- */
    /* --------------------------------------- */

    case 'UPDATE_DYNAMIC_FILTERS': {
      const { locodeKey, crew, allFilterRanges, confirm } = payload;
      const { flightParamsRange, selectedStops, airlines } = allFilterRanges;
      const portFilters = {
        ...state[locodeKey],
        ...setFlightParamValues(state[locodeKey], flightParamsRange),
        selectedStops,
        airlines,
      };
      // set confirmed flights when flights are initially fetched
      const confirmedFlights = confirm
        ? (getBestFlights(crew, portFilters) as FlightRow[])
            .filter(({ flight }) => flight)
            .map((flight) => ({
              ...flight,
              confirmed: true,
              // insert filters to flight to make it available for potential read-only report
              filters: pick(portFilters, NESTED_FILTERS),
            }))
        : state[locodeKey].confirmed;

      return {
        ...state,
        [locodeKey]: { ...portFilters, confirmed: confirmedFlights },
      };
    }

    case 'TOGGLE_CONFIRMED': {
      const { flight, locodeKey, change } = payload;
      // insert filters to flight to make it available for potential read-only report
      const confirmedFlight = {
        ...flight,
        confirmed: true,
        filters: pick(state[locodeKey], NESTED_FILTERS),
      };
      const updatedConfirmedFlights = change
        ? (state[locodeKey].confirmed || []).filter(
            ({ id: flightId }) => flight.id !== flightId
          )
        : uniqBy(
            (state[locodeKey].confirmed || []).concat(confirmedFlight),
            'id'
          );

      return {
        ...state,
        [locodeKey]: {
          ...state[locodeKey],
          confirmed: updatedConfirmedFlights,
        },
      };
    }

    // confirm/unconfirm all flights
    case 'TOGGLE_CONFIRM_ALL': {
      const { locodeKey, value, tableFlights } = payload;
      const { confirmed: existingConfirmed } = state[locodeKey];
      // confirm all flights if `value` is `true`
      const confirmedFlights = value
        ? (tableFlights as (ActiveFlight | ConfirmedFlight)[]).reduce(
            (acc, f) => {
              if (!f.flight) return acc;
              // check if a flight from table is already confirmed
              const alreadyConfirmed = existingConfirmed.some(
                ({ crew }) => crew.id === f.crew.id
              );
              // carry on with existing flight if already confirmed
              if (alreadyConfirmed) return [...acc, f as ConfirmedFlight];
              // otherwise insert the updated filters
              return [
                ...acc,
                {
                  ...f,
                  confirmed: true,
                  filters: pick(state[locodeKey], NESTED_FILTERS),
                },
              ];
            },
            [] as ConfirmedFlight[]
          )
        : [];

      return {
        ...state,
        [locodeKey]: {
          ...state[locodeKey],
          confirmed: confirmedFlights,
        },
      };
    }

    // replace confirmed flights in `AllFlightResults` modal
    case 'CONFIRM_FLIGHTS_IN_MODAL': {
      const { locodeKey, flight } = payload;
      const { confirmed: existingConfirmed = [] } = state[locodeKey];
      return {
        ...state,
        [locodeKey]: {
          ...state[locodeKey],
          // include the new selected flight from modal in confirmed flight list
          // add updated filters inside to preserve the flight's filters
          confirmed: uniqBy(
            [
              {
                ...flight,
                confirmed: true,
                filters: pick(state[locodeKey], NESTED_FILTERS),
              },
              ...existingConfirmed,
            ],
            'crew.id'
          ),
        },
      };
    }

    // update confirmed flights when flight is being tracked
    case 'TRACK_FLIGHT': {
      const { locodeKey, flightId } = payload;
      return {
        ...state,
        [locodeKey]: {
          ...state[locodeKey],
          // update tracked flight in confirmed array
          confirmed: state[locodeKey].confirmed.map((flight) =>
            flightId === flight.originalId
              ? { ...flight, tracked: true }
              : flight
          ),
        },
      };
    }

    case 'UPDATE_FILTER': {
      const { locodeKey, item } = payload;
      return {
        ...state,
        [locodeKey]: { ...state[locodeKey], ...item },
      };
    }

    case 'UPDATE_SELECTED_STOPS': {
      const { layovers, locodeKey } = payload;
      return {
        ...state,
        [locodeKey]: { ...state[locodeKey], selectedStops: layovers },
      };
    }

    case 'UPDATE_SELECTED_AIRLINES': {
      const { airlines, locodeKey } = payload;
      return {
        ...state,
        [locodeKey]: { ...state[locodeKey], airlines },
      };
    }

    case 'UPDATE_DEPARTURES': {
      const { locodeKey, departures } = payload;
      return {
        ...state,
        [locodeKey]: { ...state[locodeKey], departures },
      };
    }

    case 'DUPLICATE_PORT': {
      const { locode, duplicateDetails, updateFetchStatus } = payload;
      const { source, crew, port } = duplicateDetails;
      const baseLocode = port.uniqETA ? `${locode}(${port.uniqETA})` : locode;
      const locodeKey = `${baseLocode}--${source}`;
      const newFilters = {
        ...state[baseLocode],
        source,
        duplicate: true,
        preferred: false,
        emailSent: false, // set email sent status as `false` for duplicate card
        confirmed: [], // set confirmed to empty before calculating
      };
      // set confirmed flights when duplicating
      const confirmedFlights = (getBestFlights(crew, newFilters) as FlightRow[])
        .filter(({ flight }) => flight)
        // insert filters to flight to make it available for potential read-only report
        .map((flight) => ({
          ...flight,
          confirmed: true,
          filters: pick(newFilters, NESTED_FILTERS),
        }));

      updateFetchStatus({
        type: 'SET_PROGRESS_ON_DUPLICATE',
        payload: { locodeKey },
      });

      return {
        ...state,
        ...(state[locode]
          ? { [locode]: { ...state[locode], active: false } }
          : {}),
        [locodeKey]: { ...newFilters, confirmed: confirmedFlights },
      };
    }

    case 'SEND_EMAIL': {
      const { locodeKey } = payload;
      return {
        ...state,
        [locodeKey]: { ...state[locodeKey], emailSent: true },
      };
    }

    case 'RESET_FILTERS':
      return {};

    default:
      break;
  }

  return state;
};

export const tableReducer: CommonReducer<TableState> = (state, action) => {
  const { type, payload } = action;
  return { ...state, [type]: payload };
};

export const reportInfoReducer: CommonReducer<ReportInfoState> = (
  state,
  action
) => {
  const { type, payload } = action;
  return { ...state, [type]: payload };
};

export const fetchStatusReducer: FetchStatusReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'INTIALIZE_FETCH_STATE': {
      const { ports, routePorts } = payload;
      const { progress } = state;
      const allPortsProgress = mergePortsWithRoute(ports, routePorts).reduce(
        (acc, port) => {
          const { locode, uniqETA } = port;
          const locodeKey = uniqETA ? `${locode}(${uniqETA})` : locode;
          return { ...acc, [locodeKey]: progress?.[locodeKey] || 0 };
        },
        {}
      );

      return { ...state, progress: allPortsProgress };
    }

    case 'INITIAL_FETCH_COMPLETED':
      return {
        ...state,
        initialized: true,
        progress: Object.keys(state.progress || {}).reduce(
          (acc, locodeKey) => ({ ...acc, [locodeKey]: 1 }),
          {}
        ),
      };

    case 'RESET_ON_DEPARTURE_UPDATE': {
      const { crewType, locodeKey } = payload;
      const { crewType: currentStatus = {}, progress } = state;
      // max value is 2 - when onsigner & offsigner updates both active
      const activeDepartureUpdateCount =
        Object.values(currentStatus).filter(Boolean).length;
      const currentProgress = progress?.[locodeKey] || 0;
      return {
        ...state,
        crewType: { ...currentStatus, [crewType]: true },
        progress: {
          ...(state.progress || {}),
          // reset to 0 if first departure update
          // otherwise, continue with the current progress
          [locodeKey]: !activeDepartureUpdateCount ? 0 : currentProgress,
        },
      };
    }

    case 'DEPARTURE_UPDATE_COMPLETED': {
      const { locodeKey, crewType } = payload;
      const { crewType: currentStatus = {}, progress } = state;
      // max value is 2 - onsigner & offsigner update
      const activeDepartureUpdateCount =
        Object.values(currentStatus).filter(Boolean).length;
      const currentProgress = progress?.[locodeKey] || 0;
      return {
        ...state,
        crewType: { ...currentStatus, [crewType]: false },
        progress: {
          ...(state.progress || {}),
          // set to 1 if last departure update
          // otherwise, continue with the current progress
          [locodeKey]: activeDepartureUpdateCount > 1 ? currentProgress : 1,
        },
      };
    }

    // reset port progress when port airport is updated in ports table
    // new flights will be fetched at this stage
    case 'RESET_PORT_PROGRESS': {
      const { locode } = payload;
      const { progress } = state;
      return {
        ...state,
        // find the matching port-cards locode (original & duplicates) & reset progress
        progress: Object.keys(progress || {}).reduce(
          (acc, locodeKey) => ({
            ...acc,
            [locodeKey]: !locodeKey.includes(locode)
              ? progress?.[locodeKey] || 0
              : 0,
          }),
          {}
        ),
      };
    }

    case 'SET_PROGRESS_ON_DUPLICATE': {
      const { locodeKey } = payload;
      return {
        ...state,
        progress: { ...(state.progress || {}), [locodeKey]: 1 },
      };
    }

    // state updated just after saving flights for a connection in local db
    case 'UPDATE_FETCH_PROGRESS': {
      const { source, uniqETA, port, reset } = payload;
      const { locode } = port;
      const portProgress = Object.keys(state.progress || {}).reduce(
        (acc, locodeKey) => {
          const baseLocode = uniqETA ? `${locode}(${uniqETA})` : locode;
          const customLocodeKey = source
            ? `${baseLocode}--${source}`
            : baseLocode;
          const canUpdateProgress =
            // no vendor duplicate & for matching locode (may include duplicate for ETA)
            (!source && locodeKey === baseLocode) ||
            // for vendor duplicate, check if current locodeKey includes the formatted key
            locodeKey.includes(customLocodeKey);
          return {
            ...acc,
            [locodeKey]: canUpdateProgress
              ? (reset && 0) || getPortRequestProgress(port)
              : state.progress?.[locodeKey] || 0,
          };
        },
        {}
      );

      return { ...state, progress: portProgress };
    }

    case 'RESET_FETCH_STATUS':
      return { ...initialFetchStatus, progress: state.progress };

    default:
      break;
  }

  return state;
};
