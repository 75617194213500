import {
  GridAlignment,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { SvgIconComponent } from '@mui/icons-material';
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded';
import PaymentsIcon from '@mui/icons-material/Payments';
import FlightIcon from '@mui/icons-material/Flight';
import styled from 'styled-components/macro';

import {
  darkBlue,
  green,
  iconGray,
  lightPurple,
  red,
  textBlack,
} from 'lib/colors';
import { removeWhiteSpace } from 'lib/common';
import { formatDate } from 'utils/format-date';
import { ColumnVisibility } from 'redux/types';
import { Flight } from 'utils/types/crew-change-types';

import { CrewVisaInfo, Tooltip } from 'components/shared';
import { CCPanelFlightTrack } from 'components/shared/FlightTrack';
import { OnsignerIcon, OffsignerIcon } from 'components/icons';
import FareInfo from 'components/shared/AllFlightResults/Components/FareInfo';

import EventDetails from './EventDetails';
import FlightConnection from './FlightConnection';
import LayoverDetails from './LayoverDetails';
import { ActionButton, buttonStyles } from '../../common';
import { getFlightCellClassName } from '../../helpers';
import {
  FlightTableView,
  OpenReadOnlyFilter,
  ReadOnlyPort,
  SelectInModal,
  ToggleConfirm,
} from '../../types';

const StyledActionButton = styled(ActionButton)<{ $confirmed?: boolean }>`
  font-size: 0.6rem;
  font-weight: 500;
  padding: 0.25rem 0.4rem;
  ${({ $confirmed }) =>
    $confirmed
      ? `
    background: ${darkBlue};
    color: #fff;
  `
      : `
    background: ${lightPurple};
    color: ${textBlack};
  `};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    font-size: 18px !important;
    color: ${iconGray};
    margin-left: 6px;
  }
`;

const Badge = styled.div`
  ${buttonStyles};
  font-size: 0.6rem;
  background: ${lightPurple};
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
`;

const IconWrapper = styled.div`
  margin-right: 0.2rem;
  span {
    display: flex;
    align-items: center;
  }
`;

type Visibility = { [name: string]: boolean };

export const MOBILE_HIDDEN_COLUMNS = ['totalCO2', 'source'];

type CommonProps = {
  hasFareInfo?: boolean;
};

type PlanningProps = {
  view?: FlightTableView;
};

type ReadOnlyProps = {
  port?: ReadOnlyPort;
  hasFetchedAt?: boolean;
};

type FuncProps = {
  onToggle?: ToggleConfirm; // unavailable for read-only view
  onTrack?: (flightId: string) => void; // unavailable for read-only view
  selectInModal?: SelectInModal;
  openFilterMenu?: OpenReadOnlyFilter;
};

type GridColumnProps = {
  commonProps: CommonProps;
  planningProps?: PlanningProps;
  readOnlyProps?: ReadOnlyProps;
  funcProps: FuncProps;
};

const renderHeader = ({
  field,
  colDef,
}: {
  field: string;
  colDef: GridColDef;
}) => {
  const { headerName } = colDef;
  let ColumnIcon: SvgIconComponent | undefined;
  let tooltipText: string | undefined;
  switch (true) {
    case field === 'delay':
      ColumnIcon = WatchLaterRoundedIcon;
      tooltipText = 'Delay between crew change event & flight time';
      break;
    case field === 'additionalCost':
      ColumnIcon = PaymentsIcon;
      tooltipText = 'Additional wage & hotel costs for delay';
      break;
    case field === 'totalTravelCost':
      ColumnIcon = PaymentsIcon;
      tooltipText = 'Total travel cost for this flight';
      break;
    case field === 'flight':
      ColumnIcon = FlightIcon;
      tooltipText = 'Crew flights with layover stops';
      break;
    default:
      break;
  }
  const columnHeader = (
    <FlexWrapper>
      {headerName}
      {ColumnIcon ? <ColumnIcon /> : null}
    </FlexWrapper>
  );

  return ColumnIcon || tooltipText ? (
    <Tooltip content={tooltipText}>{columnHeader}</Tooltip>
  ) : (
    columnHeader
  );
};

export const getGridColumns = ({
  commonProps,
  planningProps,
  readOnlyProps,
  funcProps,
}: GridColumnProps): GridColDef[] => {
  const { hasFareInfo } = commonProps;
  const { view = '' } = planningProps || {};
  const { hasFetchedAt, port: readOnlyPort } = readOnlyProps || {};
  const { onToggle, onTrack, selectInModal, openFilterMenu } = funcProps;
  const emptyColumn = {
    field: 'custom',
    minWidth: 80,
    type: 'string',
    headerName: '',
    renderCell: ({ row }: GridRenderCellParams) =>
      row.flight && (
        <Tooltip content="See filters applied for this flight">
          <StyledActionButton
            $confirmed
            variant="primary"
            className="e2e_selected-filters"
            onClick={openFilterMenu?.(row)}
          >
            Filters
          </StyledActionButton>
        </Tooltip>
      ),
  };
  const confirmColumns = [
    {
      field: 'confirmed',
      headerName: view === 'compare' ? 'Status' : 'Action',
      maxWidth: view === 'compare' ? 100 : 90,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: (params: GridRenderCellParams) => {
        const { confirmed, flight } = params.row;
        if (view === 'compare') {
          return <Badge>{flight ? 'Selected' : 'Unneeded'}</Badge>;
        }

        const tooltipText = confirmed
          ? 'Deselect this flight to apply filter updates'
          : 'Select to confirm this flight';
        return (
          Boolean(flight) &&
          onToggle && (
            <Tooltip content={tooltipText}>
              <StyledActionButton
                className={`e2e_flight-row-${confirmed ? 'change' : 'select'}`}
                $confirmed={confirmed}
                variant="primary"
                onClick={() => onToggle(params.row, confirmed)}
              >
                {confirmed ? 'Change' : 'Select'}
              </StyledActionButton>
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'tracked',
      headerName: 'Track',
      maxWidth: 90,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      hideable: true,
      renderCell: (params: GridRenderCellParams) => (
        <CCPanelFlightTrack flight={params.row as Flight} onTrack={onTrack} />
      ),
    },
  ];
  const commonColumns = [
    {
      field: 'crew',
      headerName: 'Name',
      minWidth: 150,
      valueGetter: ({ row }: GridValueGetterParams) => row.crew.name,
      renderCell: (params: GridRenderCellParams) => {
        const { crew } = params.row;
        const isOnsigner = crew.type.toLowerCase() === 'onsigner';
        const CrewIcon = isOnsigner ? OnsignerIcon : OffsignerIcon;
        return (
          <FlexWrapper>
            <Tooltip content={isOnsigner ? 'Onsigner' : 'Offsigner'}>
              <IconWrapper>
                <CrewIcon
                  size={20}
                  color={isOnsigner ? green : red}
                  style={{ marginRight: '0.25rem' }}
                />
              </IconWrapper>
            </Tooltip>
            {crew.name || '---'}
          </FlexWrapper>
        );
      },
    },
    {
      field: 'flight',
      headerName: 'Flight',
      minWidth: 150,
      renderCell: ({ row }: GridRenderCellParams) => (
        <FlightConnection
          flight={row}
          port={readOnlyPort} // available only in read-only view
          onSelect={selectInModal}
          isCompareView={view === 'compare'}
        />
      ),
    },
    {
      field: 'rank',
      headerName: 'Rank',
      minWidth: 80,
      valueGetter: ({ row }: GridValueGetterParams) => row.crew.rank,
      renderCell: ({ row }: GridRenderCellParams) => (
        <span>{row.crew.rank || '-'}</span>
      ),
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 80,
      valueGetter: ({ row }: GridValueGetterParams) => row.crew.country,
      renderCell: ({ row }: GridRenderCellParams) => (
        <span>{row.crew.country || '-'}</span>
      ),
    },
    // available only for planning
    ...(onToggle
      ? [
          {
            field: 'seamenBookNumber',
            headerName: 'SB No.',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.seamenBookNumber,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.seamenBookNumber || '-'}</span>
            ),
          },
          {
            field: 'seamenBookIssued',
            headerName: 'SB Issue',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.seamenBookIssued,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.seamenBookIssued || '-'}</span>
            ),
          },
          {
            field: 'seamenBookExpiry',
            headerName: 'SB Expiry',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.seamenBookExpiry,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.seamenBookExpiry || '-'}</span>
            ),
          },
          {
            field: 'passportNumber',
            headerName: 'PP No.',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.passportNumber,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.passportNumber || '-'}</span>
            ),
          },
          {
            field: 'passportIssued',
            headerName: 'PP Issue',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.passportIssued,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.passportIssued || '-'}</span>
            ),
          },
          {
            field: 'passportExpiry',
            headerName: 'PP Expiry',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.passportExpiry,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.passportExpiry || '-'}</span>
            ),
          },
          {
            field: 'visaCountry',
            headerName: 'Visa Country',
            align: 'center' as GridAlignment,
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.visaCountry,
            renderCell: ({ row }: GridRenderCellParams) => (
              <CrewVisaInfo countryCode={row.crew.visaCountry} />
            ),
          },
          {
            field: 'visaExpiry',
            headerName: 'Visa Expiry',
            minWidth: 80,
            valueGetter: ({ row }: GridValueGetterParams) =>
              row.crew.visaExpiry,
            renderCell: ({ row }: GridRenderCellParams) => (
              <span>{row.crew.visaExpiry || '-'}</span>
            ),
          },
        ]
      : []),
    { field: 'cost', headerName: 'Cost', minWidth: 80 },
    {
      field: 'delay',
      headerName: 'Delay',
      minWidth: 80,
      sortable: false,
      cellClassName: getFlightCellClassName(!onToggle),
      renderCell: (params: GridRenderCellParams) => (
        <EventDetails flightDetails={params.row} type="delay" />
      ),
    },
    {
      field: 'additionalCost',
      headerName: 'Delay Cost',
      minWidth: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <EventDetails flightDetails={params.row} type="additionalCost" />
      ),
    },
    {
      field: 'totalTravelCost',
      headerName: 'Total Cost',
      minWidth: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <EventDetails flightDetails={params.row} type="totalCost" />
      ),
    },
    { field: 'time', headerName: 'Time', minWidth: 100 },
    {
      field: 'flightLayoverTime',
      headerName: 'Total Layover',
      minWidth: 100,
      renderCell: ({ row }: GridRenderCellParams) => (
        <LayoverDetails flightRow={row} />
      ),
    },
    {
      field: 'flightNumbers',
      headerName: 'Flight Numbers',
      minWidth: 130,
      valueGetter: ({ row: { flightNumbers } }: GridValueGetterParams) =>
        flightNumbers?.length
          ? flightNumbers.map(removeWhiteSpace).join(', ')
          : '-',
      renderCell: (params: GridRenderCellParams) => {
        const { flightNumbers } = params.row;
        return flightNumbers?.length
          ? flightNumbers.map(removeWhiteSpace).join(', ')
          : '-';
      },
    },
    {
      field: 'departureTime',
      headerName: 'Departure',
      minWidth: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { departure } = params.row;
        return (
          <span>
            {departure ? formatDate(departure.time, 'HH:mm, DD MMM') : ''}
          </span>
        );
      },
    },
    {
      field: 'arrivalTime',
      headerName: 'Arrival',
      minWidth: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { arrival } = params.row;
        return (
          <span>
            {arrival ? formatDate(arrival.time, 'HH:mm, DD MMM') : ''}
          </span>
        );
      },
    },
    {
      field: 'totalCO2',
      headerName: 'CO2 (ton)',
      minWidth: 40,
      renderCell: (params: GridRenderCellParams) => {
        const { totalCO2 } = params.row;
        return <span>{totalCO2 ? (totalCO2 / 1000).toFixed(0) : '-'}</span>;
      },
    },
    { field: 'source', headerName: 'Source', minWidth: 30 },
    ...(hasFareInfo
      ? [
          {
            field: 'fareInformation',
            headerName: 'Fare Info',
            align: 'center' as GridAlignment,
            minWidth: 30,
            renderCell: (params: GridRenderCellParams) => {
              const { arrival, departure, fareInformation } = params.row;
              return (
                <FareInfo
                  arrival={arrival?.airportCode}
                  departure={departure?.airportCode}
                  fareInformation={fareInformation}
                  tableView
                />
              );
            },
          },
        ]
      : []),
    ...(!onToggle && hasFetchedAt
      ? [
          {
            field: 'fetchedAt',
            headerName: 'Fetched At',
            minWidth: 50,
            renderCell: (params: GridRenderCellParams) => {
              const { fetchedAt } = params.row;
              if (!fetchedAt) return <span>-</span>;
              return <span>{formatDate(fetchedAt, 'HH:mm, DD MMM')}</span>;
            },
          },
        ]
      : []),
  ];

  return [...(onToggle ? confirmColumns : [emptyColumn]), ...commonColumns].map(
    (item: GridColDef) => ({
      ...item,
      flex: 1,
      headerClassName: 'table-header',
      maxWidth: item.maxWidth || 300,
      renderHeader,
    })
  );
};

export const getColumnVisibility = (
  isMobile: boolean,
  flightColumns?: ColumnVisibility[] | null
) => {
  if (!flightColumns) {
    return Object.keys(MOBILE_HIDDEN_COLUMNS).reduce(
      (acc, field) => ({ ...acc, [field]: !isMobile }),
      {} as Visibility
    );
  }

  return flightColumns.reduce(
    (acc, item) => ({
      ...acc,
      [item.field]:
        item.selected &&
        !(isMobile && MOBILE_HIDDEN_COLUMNS.includes(item.field)),
    }),
    {} as Visibility
  );
};
