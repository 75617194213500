import uniq from 'lodash/uniq';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { addVesselAsync, populateVesselsAsync } from '../thunks';
import { getUserLabel } from '../helpers/user';
import {
  VesselResponse,
  RootState,
  UserInfoPayload,
  MapVesselState,
} from '../types';
import { VesselTag, UpdateAddRemoveVesselTag } from 'utils/types';

// This is used to work with native `Map` & `Set` in redux store
// NOTE: Same version of `immer` must be installed as with `redux-toolkit`
// TODO: Ideally, we shouldn't use `Map` & `Set` in redux store.
// We need to convert `vessel` & `vesselsFull` to `{ [id: number]: Vessel }` objects in future refactor
enableMapSet();

export const initialMapVessels: MapVesselState = {
  vesselsIsLoading: false,
  vesselsFull: new Map(),
  filteredVessels: new Map(),
  vesselsFullUpdatedAt: null,
  filteredVesselsUpdatedAt: null,
  highlightedVessels: [],
  response: null,
  hasZeroVessels: false,
  globalTags: [],
};

const mapVesselsSlice = createSlice({
  name: 'mapVessels',
  initialState: initialMapVessels,
  reducers: {
    updateGlobalTags: (state, action: PayloadAction<any[]>) => ({
      ...state,
      globalTags: action.payload,
    }),
    populateFilteredVessels: (
      state,
      action: PayloadAction<Map<number, Vessel>>
    ) => ({
      ...state,
      filteredVessels: action.payload,
      filteredVesselsUpdatedAt: new Date(),
    }),
    setHighlightedVessels: (state, action: PayloadAction<number[]>) => ({
      ...state,
      highlightedVessels: uniq(Array.from(action.payload)),
    }),
    updateInCharge: (state, action: PayloadAction<UserInfoPayload>) => {
      const { vesselsFull } = state;
      const { ids: vesselIds, userInfo } = action.payload;
      const newVessels = new Map(vesselsFull);

      vesselIds.forEach((id: number) => {
        if (!newVessels.has(id)) return;
        newVessels.set(id, {
          ...newVessels.get(id)!,
          picId: userInfo.id,
          picLabel: getUserLabel(userInfo),
        });
      });

      return {
        ...state,
        vesselsFull: newVessels,
        vesselsFullUpdatedAt: new Date(),
      };
    },
    updateVesselResponse: (state, action: PayloadAction<VesselResponse>) => ({
      ...state,
      response: action.payload,
    }),
    clearVesselResponse: (state) => ({ ...state, response: null }),
    updateAddRemoveVesselTag: (
      state,
      action: PayloadAction<UpdateAddRemoveVesselTag>
    ) => {
      const newVessels = new Map(state.vesselsFull);
      const newFilteredVessels = new Map(state.filteredVessels);
      action.payload.flotVesIds.forEach((id: number) => {
        const existingVessel = newVessels.get(id)!;
        // @ts-ignore
        const alreadyExists = !!existingVessel?.vesselTags?.filter(
          (o: VesselTag) => o.id === action.payload.tag.id
        ).length;
        if (newVessels.has(id)) {
          if (action.payload.action === 'add') {
            if (!alreadyExists) {
              newVessels.set(id, {
                ...existingVessel,
                // @ts-ignore
                vesselTags: [...existingVessel.vesselTags, action.payload.tag],
              });
            }
          } else if (action.payload.action === 'remove') {
            newVessels.set(id, {
              ...existingVessel,
              // @ts-ignore
              vesselTags: existingVessel.vesselTags.filter(
                (o: VesselTag) => o.id !== action.payload.tag.id
              ),
            });
          }
        }
        if (newFilteredVessels.has(id)) {
          if (action.payload.action === 'add') {
            if (!alreadyExists) {
              newFilteredVessels.set(id, {
                ...existingVessel,
                // @ts-ignore
                vesselTags: [...existingVessel.vesselTags, action.payload.tag],
              });
            }
          } else if (action.payload.action === 'remove') {
            newFilteredVessels.set(id, {
              ...existingVessel,
              // @ts-ignore
              vesselTags: existingVessel.vesselTags.filter(
                (o: VesselTag) => o.id !== action.payload.tag.id
              ),
            });
          }
        }
      });

      return {
        // don't include an update to updatedAt
        ...state,
        vesselsFull: newVessels,
        filteredVessels: newFilteredVessels,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(populateVesselsAsync.pending, (state) => ({
        ...state,
        vesselsIsLoading: true,
      }))
      .addCase(populateVesselsAsync.fulfilled, (state, action) => ({
        ...state,
        vesselsIsLoading: false,
        ...(action.payload && {
          vesselsFull: action.payload,
          vesselsFullUpdatedAt: new Date(),
          hasZeroVessels: !action.payload.size,
        }),
      }))
      .addCase(addVesselAsync.pending, (state) => ({
        ...state,
        vesselsIsLoading: true,
      }))
      .addCase(addVesselAsync.fulfilled, (state, action) => ({
        ...state,
        vesselsIsLoading: false,
        ...(action.payload && {
          vesselsFull: action.payload,
          vesselsFullUpdatedAt: new Date(),
          hasZeroVessels: !action.payload.size,
        }),
      }));
  },
});

/* ----- selectors -----*/

export const selectMapVessels = ({ mapVessels }: RootState) => mapVessels;

export const selectVesselById = createSelector(
  [
    (state: RootState) => state.mapVessels.vesselsFull,
    (state: RootState, id: number) => id,
  ],
  (vessels, id) => vessels.get(id)!
);

export const selectVesselNames = createSelector(
  [(state: RootState) => state.mapVessels.vesselsFull],
  (vessels) => Array.from(vessels.values()).map((v) => v.name)
);

/* ----- actions -----*/

export const {
  populateFilteredVessels,
  setHighlightedVessels,
  updateInCharge,
  updateVesselResponse,
  clearVesselResponse,
  updateAddRemoveVesselTag,
  updateGlobalTags,
} = mapVesselsSlice.actions;

/* ----- reducer -----*/

export default mapVesselsSlice.reducer;
