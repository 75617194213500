import { memo, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';

import { gray60 } from 'lib/colors';
import { getPortFlightsCount } from 'lib/alasql/flights';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { LottieAnimation } from 'components/shared';
import { getLocodeKeyDetails } from '../../../helpers';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: center;
`;

const TextWrapper = styled(FlexWrapper)`
  flex-direction: column;
  flex-shrink: 0;
`;

const ContentWrapper = styled(FlexWrapper)`
  margin: 0;
  width: fit-content;
`;

const Text = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;
  margin-right: 0.5rem;
  flex-shrink: 0;
  font-style: italic;
  font-weight: 500;
  letter-spacing: 0.5px;
`;

const FlightsCount = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: ${gray60};
  letter-spacing: 0.5px;
  margin-bottom: 0.25rem;
`;

type Props = {
  locodeKey: string;
  airportCode: string;
};

function CustomLoader({ locodeKey, airportCode }: Props) {
  const {
    fetchStatus: { initialized, progress },
    planningData: { ports },
  } = useContext(CCPanelContext);
  const [countResult, setCountResult] = useState<{
    [locode: string]: number;
  } | null>(null);

  const portProgress = progress?.[locodeKey] || 0;
  const { locode: originalLocode } = useMemo(
    () => getLocodeKeyDetails(locodeKey),
    [locodeKey]
  );
  const port = useMemo(
    () => ports.find((port) => port.locode === originalLocode),
    [originalLocode, ports]
  );
  const portName =
    (port?.name && originalLocode && `${port.name} (${originalLocode})`) ||
    originalLocode ||
    '';

  // calculate fetched flights count with progress update
  useEffect(() => {
    switch (true) {
      case portProgress > 0 && !initialized: {
        setCountResult(getPortFlightsCount(originalLocode, airportCode));
        break;
      }
      case portProgress === 1 && initialized: {
        setCountResult(null);
        break;
      }
      default:
        break;
    }
  }, [originalLocode, airportCode, initialized, portProgress]);

  const flightsCount = countResult?.[originalLocode] || 0;

  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          {flightsCount > 0 && (
            <FlightsCount>{flightsCount} flights fetched.</FlightsCount>
          )}
          <Text>{`Hold tight. We're searching flights for ${portName} ...`}</Text>
        </TextWrapper>

        <LottieAnimation
          name="72169-plane-flies-around-the-earth"
          height={60}
        />
      </ContentWrapper>
    </Wrapper>
  );
}

export default memo(CustomLoader);
