import {
  useState,
  useEffect,
  useReducer,
  createContext,
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  useContext,
  Suspense,
} from 'react';
import { useSelector } from 'react-redux';
import Slide from '@mui/material/Slide';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import {
  useAppDispatch,
  useMobile,
  useModal,
  usePanelResize,
  usePrevious,
} from 'hooks';
import { blue, borderGray, textBlack, white } from 'lib/colors';
import {
  CC_PANEL_COLLAPSED_HEIGHT,
  CC_PANEL_INITIAL_HEIGHT,
  CC_PANEL_INITIAL_MOBILE_HEIGHT,
  CC_PANEL_RESIZE_BAR_HEIGHT,
} from 'lib/constants';
import { BREAK_POINT_L, BREAK_POINT_XS } from 'lib/breakpoints';
import { dropFlightsTables, initFlightTables } from 'lib/alasql/flights';
import { PlanningData } from 'utils/types';
import { flyTo as zoomToCoords } from 'map/map';
import { closeSidePanel, setVesselJourney } from 'redux/actions';
import {
  selectCrewChangePanel,
  selectCrewChangeVessel,
  selectPortCalls,
  selectSidePanel,
} from 'redux/selectors';
import { RootState } from 'redux/types';

import CrewChangeVessel from 'components/CrewChangeVessel';
import CrewChangeTable from 'components/CrewChangePanel/Table';
import {
  Container as HeaderContainer,
  CrewDetails,
} from 'components/CrewChangePanel/Header';
import PortPopups from 'components/CrewChangePanel/Map/Popups';
import OneClickPlanModal from 'components/CrewChangePanel/OneClickModal';
import {
  calculateJourney,
  fetchStatusReducer,
  flightFiltersReducer,
  getVesselRoute,
  initialFetchStatus,
  initialPlanningData,
  initialPortParams,
  initialTableState,
  reportInfoReducer,
  resetCCPanelResources,
  tableReducer,
  triggerCCPanelCloseActions,
} from 'components/CrewChangePanel/helpers';
import {
  FetchStatus,
  FlightFilters,
  PortParams,
  PortRow,
  ReadOnlyPort,
  ReportInfoState,
  RoutePortOrVessel,
  StepData,
  TableState,
  VesselRoute,
} from 'components/CrewChangePanel/types';
import { JourneyVessel } from 'components/SidePanel/VesselCourse/types';
import { RouteCalculatorContext } from './RouteCalculatorContext';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const TableWrapper = styled.div<{
  $sidepanelVisible: boolean;
  $isOneClickPlan?: boolean;
}>`
  user-select: none;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  bottom: 0;
  min-height: ${CC_PANEL_COLLAPSED_HEIGHT};
  height: ${CC_PANEL_INITIAL_HEIGHT};
  width: ${({ $sidepanelVisible }) =>
    $sidepanelVisible ? 'calc(100% - max(300px, 25vw))' : '100vw'};
  background-color: ${white};
  z-index: 999;
  &:after {
    ${({ $isOneClickPlan }) =>
      // overlay for one-click plan
      $isOneClickPlan &&
      `
      display: block;
      height: 100%;
      right: 0;
      z-index: 99999;
      background: rgba(255, 255, 255, 0.5);
    `};
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    height: ${CC_PANEL_INITIAL_MOBILE_HEIGHT};
  }
`;

const ResizeBar = styled.div<{ $resizing: boolean }>`
  position: relative;
  background: ${({ $resizing }) => ($resizing ? blue : borderGray)};
  height: ${CC_PANEL_RESIZE_BAR_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: row-resize;
`;

const GripIcon = styled(DragHandleIcon)<{ $resizing: boolean }>`
  color: ${({ $resizing }) => ($resizing ? '#fff' : `${textBlack}`)};
`;

type Action = { type: string; payload?: any };

type ContextState = {
  data: StepData;
  route: VesselRoute | null;
  fetchStatus: FetchStatus;
  filters: { [locodeKey: string]: FlightFilters };
  portParams: PortParams;
  tableState: TableState;
  planningData: PlanningData;
  reportInfo: ReportInfoState;
  setRoute: Dispatch<SetStateAction<VesselRoute | null>>;
  updatePlanningData: Dispatch<SetStateAction<PlanningData>>;
  updatePortParams: Dispatch<SetStateAction<PortParams>>;
  updateFilters: Dispatch<Action>;
  updateTableState: Dispatch<Action>;
  updateReportInfo: Dispatch<Action>;
  updateFetchStatus: Dispatch<Action>;
};

export const CCPanelContext = createContext({} as ContextState);

export default function CrewChangePanel() {
  const dispatch = useAppDispatch();
  const sidePanel = useSelector(selectSidePanel);
  const {
    event,
    active: activity,
    isOneClickPlan,
    vesselId,
  } = useSelector(selectCrewChangePanel);
  const { portCalls } = useSelector(selectPortCalls);
  const vessel = useSelector(selectCrewChangeVessel);
  const { portParams: portFilters } = useSelector(
    ({ settings }: RootState) => settings.crewChange
  );

  const isSmallerScreen = useMobile(BREAK_POINT_L);
  const isMobile = useMobile(BREAK_POINT_XS);
  const { modal, setModal } = useModal();
  const { getVesselRoute: calculateRoute } = useContext(RouteCalculatorContext);

  const [route, setRoute] = useState<VesselRoute | null>(null);
  const [focusedPort, setFocusedPort] = useState<PortRow | ReadOnlyPort | null>(
    null
  );
  const [planningData, updatePlanningData] =
    useState<PlanningData>(initialPlanningData);
  const [portParams, updatePortParams] = useState(initialPortParams);
  const [filters, updateFilters] = useReducer(flightFiltersReducer, {});
  const [tableState, updateTableState] = useReducer(
    tableReducer,
    initialTableState
  );
  const [reportInfo, updateReportInfo] = useReducer(reportInfoReducer, {});
  const [fetchStatus, updateFetchStatus] = useReducer(
    fetchStatusReducer,
    initialFetchStatus
  );

  const { ports, route: routePorts } = planningData;
  const { collapse, step } = tableState;
  const { resizing, panelHeight } = usePanelResize(collapse);
  const prevResizing = usePrevious(resizing);

  useLayoutEffect(() => {
    // update collapse state with resizing
    if (prevResizing && !resizing) {
      updateTableState({ type: 'collapse', payload: panelHeight <= 75 });
    }
  }, [resizing, prevResizing]); // eslint-disable-line

  useEffect(() => {
    if (isSmallerScreen && activity === 'plan') {
      dispatch(closeSidePanel());
    }
    if (isMobile) {
      updateTableState({ type: 'compact', payload: true });
    }
    // intialize local state of port filters from settings
    updatePortParams(portFilters);
    initFlightTables();

    return () => {
      dropFlightsTables();
      triggerCCPanelCloseActions();
      // reset planning data when new event is selected
      updatePlanningData(initialPlanningData);
      updateTableState({ type: 'step', payload: 'crew' });
      resetCCPanelResources(dispatch);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (ports.length) {
      updateFilters({
        type: 'INITIALIZE_ALL_FILTERS',
        payload: { ports, routePorts },
      });
      updateFetchStatus({
        type: 'INTIALIZE_FETCH_STATE',
        payload: { ports, routePorts },
      });
    }
  }, [ports, routePorts]);

  useEffect(() => {
    if (isOneClickPlan) {
      setModal('one-click-plan');
      return;
    }
    // prevent closing the modal immediately
    setTimeout(() => {
      setModal(null);
    }, 1000);
  }, [isOneClickPlan]); // eslint-disable-line

  useEffect(() => {
    // show vessel route & enlarged vessel when in planning state
    if (activity === 'plan' && vesselId) {
      const { route = [] } = planningData || {};
      if (vessel) {
        const { id: vesselId, lat, lng, status, course } = vessel;
        zoomToCoords({ lat, lng });
        // set vessel journey with existing route, if available
        // when starting a crew-change from available data e.g - from shared report
        if (Boolean(route.length)) {
          const vesselDetails: JourneyVessel = {
            index: null,
            type: 'vessel',
            componentType: 'vessel',
            vesselId,
            status,
            course,
          };
          const journey: RoutePortOrVessel[] = [vesselDetails, ...route];
          dispatch(setVesselJourney(journey));
          return;
        }

        // otherwise, calculate the route & vessel journey
        getVesselRoute(
          vessel,
          portCalls[vessel.id]?.portCalls,
          calculateRoute,
          dispatch
        ).then((newRoute) => {
          setRoute(newRoute);
          // Calculate the very first journey
          dispatch(setVesselJourney(calculateJourney(vessel, newRoute)));
        });
      }
    }

    // reset planning data & resources when planning is discarded
    if (activity === 'crew') {
      updateFilters({ type: 'RESET_FILTERS' });
      updatePlanningData(initialPlanningData);
      updateTableState({ type: 'step', payload: 'crew' });
      resetCCPanelResources(dispatch);
    }

    window.onbeforeunload = () => activity === 'plan' || null;

    return () => {
      window.onbeforeunload = () => null;
    };
  }, [activity]); // eslint-disable-line

  useEffect(() => {
    if (focusedPort) {
      setTimeout(() => {
        setFocusedPort(null);
      }, 3000);
    }
  }, [focusedPort]);

  // reset savedLink with changes in planningData, portParams, or filters
  useEffect(() => {
    updateReportInfo({ type: 'savedLink' });
  }, [planningData, portParams, filters]);

  // show cc panel in read-only view for empty event
  if (!event && activity !== 'readOnly') {
    return null;
  }

  const renderModal = () => {
    if (!modal?.type) return null;

    if (modal.type === 'one-click-plan') {
      return <OneClickPlanModal closeModal={() => setModal(null)} />;
    }
  };

  return (
    <CCPanelContext.Provider
      value={{
        data: planningData[step],
        route,
        fetchStatus,
        filters,
        portParams,
        planningData,
        tableState,
        reportInfo,
        setRoute,
        updateFetchStatus,
        updateFilters,
        updatePortParams,
        updateTableState,
        updateReportInfo,
        updatePlanningData,
      }}
    >
      <Slide direction="up" in timeout={{ enter: 500, exit: 500 }}>
        <TableWrapper
          id="resizable-container"
          $sidepanelVisible={sidePanel.visible}
          $isOneClickPlan={isOneClickPlan}
        >
          <ResizeBar id="resize-bar" $resizing={resizing}>
            <GripIcon fontSize="small" $resizing={resizing} />
          </ResizeBar>

          {activity === 'crew' ? (
            <CrewDetails event={event as CrewEvent} />
          ) : (
            <HeaderContainer />
          )}
          {!collapse && (
            <Suspense fallback={<></>}>
              <CrewChangeTable
                focusedPort={focusedPort}
                setFocusedPort={setFocusedPort}
              />
            </Suspense>
          )}
        </TableWrapper>
      </Slide>
      {/* Render Crew Change Route on Map */}
      <CrewChangeVessel />
      {/* render selected port popups */}
      <PortPopups focusedPort={focusedPort} />

      {renderModal()}
    </CCPanelContext.Provider>
  );
}
