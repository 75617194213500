import { memo, useEffect, useMemo } from 'react';

import { useCrewChangeCosts } from 'hooks';
import {
  drawSymbolLayer,
  removeLayerImmediately,
  removeSourceWhenActive,
  setSource,
} from 'map/map';
import { formatDate } from 'utils/format-date';

import { selectMapState } from 'redux/selectors';
import { useSelector } from 'react-redux';

import FlightCosts from './FlightCosts';
import { Wrapper, FlexWrapper, Title, Price } from './common';
import { getFlightsInTable, getPortWaypoint } from '../../helpers';
import { PopupPort, PortPopupDetails, PortRow } from '../../types';

type Props = {
  port: PopupPort;
  portDetails: PortPopupDetails;
  compare?: boolean; // available for `compare` view of flights table
};

// This component is used in port popup on map & `compare` view in flights table
function Details({ port, portDetails, compare }: Props) {
  const { crew, flights: fixedFlights } = portDetails;

  const {
    totalCrewCount,
    totalAgencyCost,
    totalWageCost,
    totalHotelCost,
    totalCost,
    preferredAgent,
    currency,
  } = useCrewChangeCosts(port);
  const { name, locode, selected, flightSource, uniqETA } = port;
  const customId = useMemo(
    () => (selected ? locode : `selected--${locode}`),
    [selected, locode]
  );
  const { isReady: isMapReady } = useSelector(selectMapState);

  useEffect(() => {
    if (!isMapReady.map) return;
    setSource(customId, getPortWaypoint(port)).then(() => {
      drawSymbolLayer(customId, customId, {
        type: 'symbol',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
        },
        paint: {
          'icon-opacity': 1,
        },
      });
    });

    return () => {
      if (!isMapReady.map) return;
      removeLayerImmediately(customId).then((stillExist) => {
        !stillExist && removeSourceWhenActive(customId);
      });
    };
  }, [customId,isMapReady.map]); // eslint-disable-line

  const distanceNM = (port as PortRow).calculatedDistance;
  const portFlights = getFlightsInTable(fixedFlights);

  const renderAgencyCost = () => {
    if (!preferredAgent) {
      return null;
    }

    if (fixedFlights?.length) {
      return totalAgencyCost ? (
        <FlexWrapper>
          <Title>
            {totalCrewCount} x Agency ({preferredAgent.toUpperCase()})
          </Title>
          <Price>
            {totalAgencyCost} {currency}
          </Price>
        </FlexWrapper>
      ) : null;
    }

    return totalAgencyCost ? (
      <FlexWrapper>
        <b>
          {crew.length} x Agency ({preferredAgent.toUpperCase()}):
        </b>
        <Price>
          {totalAgencyCost} {currency}
        </Price>
      </FlexWrapper>
    ) : null;
  };

  const renderTotalCost = () => {
    if (!portFlights.length) {
      return null;
    }

    return (
      <FlexWrapper>
        <Title>Total Crew Change Cost</Title>
        <Price $isTotal>{totalCost || '---'}</Price>
      </FlexWrapper>
    );
  };

  const renderAdditionalCost = () => {
    const testId = `e2e_${compare ? 'compare' : 'map-popup'}-cost-summary`;
    return (
      <>
        {Boolean(totalWageCost) && (
          <FlexWrapper>
            <Title data-id={testId}>Total Wage Cost</Title>
            <Price>
              {totalWageCost} {currency}
            </Price>
          </FlexWrapper>
        )}
        {Boolean(totalHotelCost) && (
          <FlexWrapper>
            <Title>Total Hotel Cost</Title>
            <Price>
              {totalHotelCost} {currency}
            </Price>
          </FlexWrapper>
        )}
      </>
    );
  };

  // const renderTotalCO2 = () => {
  //   const totalCO2Kg = fixedFlights.reduce(
  //     (acc, { totalCO2 }) => acc + Math.ceil(totalCO2),
  //     0
  //   );

  //   return Boolean(fixedFlights?.length) ? (
  //     <FlexWrapper>
  //       <Title $color={compare ? green : fadedGreen}>Total CO2</Title>
  //       <Price $color={compare ? green : fadedGreen}>
  //         {(totalCO2Kg / 1000).toFixed(0)} Tons
  //       </Price>
  //     </FlexWrapper>
  //   ) : null;
  // };

  const portName = uniqETA
    ? `${locode}, ${name} (${formatDate(uniqETA, 'DD MMM, YY')})`
    : `${locode}, ${name}`;

  return (
    <Wrapper $compare={compare}>
      {/* show card's own title in comparison view */}
      {!compare && (
        <div>
          <b>{portName}</b> {flightSource && <b>- {flightSource}</b>}
        </div>
      )}
      {/* show distance before fixing flights */}
      {!fixedFlights?.length && (
        <FlexWrapper>
          <Title>Distance:</Title>{' '}
          {(distanceNM === 0 && '---') ||
            (distanceNM > 1 ? `${distanceNM.toFixed(2)} NM` : '<1 NM')}
        </FlexWrapper>
      )}
      {Boolean(totalCrewCount) && (
        <FlexWrapper>
          <Title>
            {totalCrewCount}/{crew.length} Crew
          </Title>{' '}
          ({((totalCrewCount / crew.length) * 100).toFixed(0)}%)
        </FlexWrapper>
      )}
      {renderAgencyCost()}
      {renderAdditionalCost()}
      {Boolean(portFlights.length) && (
        <FlightCosts flights={portFlights} isCompareView={compare} />
      )}

      {/* hide CO2 info for now */}
      {/* {renderTotalCO2()} */}

      {renderTotalCost()}
      {compare && !fixedFlights?.length && <Title>No confirmed flights.</Title>}
    </Wrapper>
  );
}

export default memo(Details);
