import { Form } from 'formik';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { blue, textBlack, white } from 'lib/colors';
import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100 * var(--vh));

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    flex-direction: column-reverse;
  }
`;

export const FormContainer = styled.div`
  background: ${white};
  flex: 1 1 100%;
  display: flex;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    border-radius: 0.5rem 0.5rem 0 0;
    margin-top: -0.5rem;
  }
`;

export const ImageContainer = styled.div`
  height: inherit;
  flex: 1 1 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    height: calc(25 * var(--vh));
    flex: unset;
  }
`;

export const FormWrapper = styled(Form)`
  align-self: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow-y: auto;
  max-height: calc(100 * var(--vh));

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    max-height: calc(75 * var(--vh));
    align-self: start;
  }
`;

export const NonFormWrapper = styled.div`
  align-self: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow-y: auto;
  max-height: calc(100 * var(--vh));

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    max-height: calc(75 * var(--vh));
    align-self: start;
  }
`;

export const CodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  input {
    text-align: center;
  }
`;

export const LinkText = styled.p`
  text-decoration: underline;
  color: ${blue};
  font-size: 0.8rem;
  margin: 0.5rem 0;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 1rem;
  color: ${textBlack};
  svg {
    font-size: 20px !important;
    cursor: pointer;
  }
`;
