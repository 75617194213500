import { NotificationUpdate } from './types';

import { AppDispatch } from 'redux/types';

export interface WebSocketMessage {
  type: string;
  payload?: any;
}

export interface PingMessage extends WebSocketMessage {}

export interface PongMessage extends WebSocketMessage {}

export interface NotificationUpdatesRequestMessage extends WebSocketMessage {
  minId: number | null;
  maxId: number | null;
}

export interface NotificationUpdatesResponseMessage extends WebSocketMessage {
  payload: NotificationUpdate[];
}

export interface WebSocketConfig {
  onNotificationsCb: (notifications: NotificationUpdate[]) => void;
  dispatch: AppDispatch;
  allowNotifications: boolean;
}

export function ping(ws: WebSocket) {
  if (ws.readyState === ws.OPEN) {
    const pingMsg: PingMessage = { type: 'ping' };
    ws.send(JSON.stringify(pingMsg));
  }
}

export function getNotifications(
  ws: WebSocket,
  msg: NotificationUpdatesRequestMessage
) {
  if (ws.readyState === ws.OPEN) {
    ws.send(JSON.stringify(msg));
  }
}
