import moment from 'moment';
import type {
  CrewChangePlanSummary,
  CrewChangePlanSummaryResp,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { formatDate } from 'utils/format-date';

/* @param: difference in days */
const generateDate = (difference: number = 1) =>
  moment().subtract(difference, 'day').toISOString();

const commonSumary: CrewChangePlanSummary = {
  crew: {
    onsignerCount: 2,
    offsignerCount: 3,
    homeAirports: [],
  },
  ports: [
    {
      locode: 'SGSIN',
      name: 'Singapore',
      crewCompletion: 5,
      totalCost: 750,
      travelSources: ['ATPI', 'Global GDS'],
    },
    {
      locode: 'BEBRT',
      name: 'Berendrecht',
      crewCompletion: 5,
      totalCost: 1050,
      travelSources: ['ATPI', 'Global GDS'],
    },
    {
      locode: 'GBBOS',
      name: 'Boston',
      crewCompletion: 5,
      totalCost: 800,
      travelSources: ['ATPI', 'Global GDS'],
    },
  ],
};

export const samplePlans: CrewChangePlanSummaryResp[] = [
  {
    uuid: '90af09f4-082f-4722-923a-ac68238d955b',
    createdAt: generateDate(),
    updatedAt: generateDate(),
    creatorId: 69,
    creator: {
      id: 69,
      email: 'test-client@client.com',
      name: 'Hendy',
    },
    flotillaVesselId: 4975,
    userNotes: {
      title: 'First Report',
      notes: '',
    },
    summary: commonSumary,
    isCompleted: true,
  },
  {
    uuid: '6b8b5efb-14a5-4803-877e-596794762c31',
    createdAt: generateDate(3),
    updatedAt: generateDate(3),
    creatorId: 81,
    creator: {
      id: 81,
      email: 'demo-client@client.com',
      name: 'Albert',
    },
    flotillaVesselId: 4975,
    userNotes: {
      title: 'Crew Change Plan',
      notes: '',
    },
    summary: commonSumary,
    isCompleted: true,
  },
  {
    uuid: '83e41ca4-3ab3-431a-a355-25bc501dc1e1',
    createdAt: generateDate(5),
    updatedAt: generateDate(5),
    creatorId: 81,
    creator: {
      id: 81,
      email: 'main-client@client.com',
      name: 'Hrishi',
    },
    flotillaVesselId: 4975,
    userNotes: {
      title: 'Saved Report - New',
      notes: '',
    },
    summary: commonSumary,
    isCompleted: true,
  },
  {
    uuid: '3116dca7-c862-4bed-840d-a95d63e4ef37',
    createdAt: generateDate(7),
    updatedAt: generateDate(7),
    creatorId: 81,
    creator: {
      id: 81,
      email: 'test-client@client.com',
      name: 'Hendy',
    },
    flotillaVesselId: 4975,
    userNotes: {
      title: 'Another Report',
      notes: '',
    },
    summary: commonSumary,
    isCompleted: true,
  },
  {
    uuid: '3f0016bf-090e-4789-a417-8be47443166f',
    createdAt: generateDate(8),
    updatedAt: generateDate(8),
    creatorId: 81,
    creator: {
      id: 81,
      email: 'demo-client@client.com',
      name: 'Albert',
    },
    flotillaVesselId: 4975,
    userNotes: {
      title: 'Last Report',
      notes: '',
    },
    summary: commonSumary,
    isCompleted: true,
  },
];

// generate smaple incomplete plans from samplePalns with `isCompleted` flag set to false
export const sampleIncompletePlans: CrewChangePlanSummaryResp[] =
  samplePlans.map((plan) => ({
    ...plan,
    uuid: `${plan.uuid}-incomplete`,
    userNotes: {
      ...plan.userNotes,
      title: `Plan for 5 crew at ${formatDate(
        plan.createdAt,
        'HH:mm, DD MMM, YY'
      )}`,
    },
    isCompleted: false,
  }));
