import { memo } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
`;

type Props = {
  rows?: number;
  showAvatar?: boolean;
};

function CardSkeleton({ rows = 2, showAvatar = true }: Props) {
  const renderAvatar = () => {
    return showAvatar ? (
      <Skeleton animation="wave" variant="circular" width={40} height={40} />
    ) : null;
  };

  const renderSubheader = () => {
    return Array.from({ length: rows }).map((_, index) => {
      // different width for last row
      const width = index + 1 === rows ? '60%' : '80%';
      return (
        <Skeleton
          key={index}
          animation="wave"
          height={10}
          width={width}
          style={{ marginBottom: 3 }}
        />
      );
    });
  };

  return (
    <Wrapper>
      <Card sx={{ width: '100%', m: 0.5 }}>
        <CardHeader
          avatar={renderAvatar()}
          title={
            <Skeleton
              animation="wave"
              height={18}
              width="50%"
              style={{ marginBottom: 5 }}
            />
          }
          subheader={renderSubheader()}
        />
      </Card>
    </Wrapper>
  );
}

export default memo(CardSkeleton);
