import { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import type { CrewChangePlanUserNotes } from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import styled from 'styled-components/macro';

import { SetModal } from 'hooks/useModal';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import {
  gray50,
  gray80,
  lightPurple,
  red,
  textBlack,
  textGray,
} from 'lib/colors';
import { selectCrewChangePanel } from 'redux/selectors';

import { Modal } from 'components/shared';
import { StyledButton } from 'components/CrewChangePanel/common';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: auto 0.5rem;
  overflow: visible;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const Instruction = styled.div`
  font-size: 0.9rem;
  color: ${gray80};
  margin-bottom: 1.2rem;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const InputWrapper = styled.div<{ $isNotes?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 10px;
  align-items: ${({ $isNotes }) => ($isNotes ? 'flex-start' : 'center')};
  margin-bottom: 0.75rem;
  width: 100%;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90% !important;
    grid-template-columns: 1.33fr 3fr;
  }
`;

const Title = styled(FlexWrapper)`
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledTextArea = styled(TextareaAutosize)`
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  font-family: HK Grotesk, Roboto;
  border: 1px solid ${gray50};
  border-radius: 4px;
  &:hover {
    border: 1px solid ${textBlack};
  }
  &::placeholder {
    color: ${textGray} !important;
  }
`;

const Asterisk = styled.span`
  color: ${red};
  margin-left: 2px;
`;

type Props = {
  setModal: SetModal;
};

function UserNotesModal({ setModal }: Props) {
  const { readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );

  const [userNotes, setUserNotes] = useState<CrewChangePlanUserNotes>({
    title: '',
    notes: '',
  });

  const closeModal = () => setModal(null);

  const handleUpdate = (type: string) => (event: SyntheticEvent) => {
    const { value } = event.target as HTMLInputElement;
    setUserNotes((prevNotes) => ({ ...prevNotes, [type]: value }));
  };

  const handleCreateLink = () => {
    const formattedDetails = {
      title: userNotes.title.trim(),
      notes: userNotes.notes.trim(),
    };
    setModal('copyLink', { userNotes: formattedDetails, reportData });
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton
        variant="primary"
        disabled={!userNotes.title.trim()}
        onClick={handleCreateLink}
      >
        Create Link
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal
      width={500}
      title="Save Report"
      actions={actions}
      closeModal={closeModal}
    >
      <Wrapper>
        <Instruction>
          Add title & notes to save this crew-change report.
        </Instruction>

        <InputWrapper>
          <Title>
            {/* Asterisk for required field */}
            <span>
              Title<Asterisk>*</Asterisk>
            </span>
            <span>:</span>
          </Title>
          <StyledTextField
            size="small"
            value={userNotes.title}
            onChange={handleUpdate('title')}
            placeholder="Add report title"
          />
        </InputWrapper>

        <InputWrapper $isNotes>
          <Title>
            Notes<span>:</span>
          </Title>
          <StyledTextArea
            minRows={2}
            value={userNotes.notes}
            onChange={handleUpdate('notes')}
            placeholder="Add notes for this report"
          />
        </InputWrapper>
      </Wrapper>
    </Modal>
  );
}

export default UserNotesModal;
