import { TextField } from '@mui/material';
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components/macro';

import { gray40, gray70, green, textBlack, textGray, white } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { getCrewPassport } from 'api/flotilla';

import { packagePassportsBlobUrl, passportPasswordIsValid } from '../helpers';
import { CrewDetails, CrewWithPassport } from '../types';

import { ButtonV2 } from '../../shared';
import { ErrorText } from '../../shared/Typography';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_SEND_EMAIL_FETCH_PASSPORT,
  TRACK_SEND_EMAIL_REMOVE_PASSPORT,
  TRACK_SEND_EMAIL_UPLOAD_PASSPORT,
} from 'utils/analytics/constants';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  font-size: 0.8rem;
  min-height: 25px;
  column-gap: 0.5rem;

  input[type='file'] {
    color: ${textGray};
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-direction: column;
    justify-content: unset;
    align-items: flex-start;
    row-gap: 0.5rem;
    border-bottom: 1px solid ${textGray};
    padding-bottom: 0.5rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.25rem;

  #name {
    font-weight: 500;
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
  }
`;

const Badge = styled.span`
  border-radius: 0.25rem;
  background-color: ${green};
  color: ${white};
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
`;

const InputWrapper = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1rem;ui
`;

type CrewPassportProps = {
  crew: CrewWithPassport;
  disableChange: boolean;
  updatePassports: Dispatch<
    SetStateAction<{ [crewId: number]: CrewWithPassport }>
  >;
};

const MAX_FILE_SIZE = 1024 * 1024 * 2; // 2 MB

function CrewPassport({
  crew,
  disableChange,
  updatePassports,
}: CrewPassportProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileError, setFileError] = useState<string>('');
  useEffect(() => {
    if (!inputRef.current || !crew.file) return;
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(crew.file);
    if (inputRef.current) {
      inputRef.current.files = dataTransfer.files;
    }
  }, [crew, updatePassports]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!inputRef.current) return;
      setFileError('');
      if (e.target.files) {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > MAX_FILE_SIZE) {
          inputRef.current.files = new DataTransfer().files;
          setFileError('File size is too large. Max file size is 2MB.');
          return;
        }
        trackUserAction(TRACK_SEND_EMAIL_UPLOAD_PASSPORT);
        updatePassports((prev) => ({
          ...prev,
          [crew.id]: { ...crew, file: file, downloaded: false },
        }));
      } else {
        trackUserAction(TRACK_SEND_EMAIL_REMOVE_PASSPORT);
        updatePassports((prev) => ({
          ...prev,
          [crew.id]: { ...crew, file: null },
        }));
      }
    },
    [updatePassports, crew]
  );
  return (
    <Container>
      <NameWrapper>
        <span id="name">{crew.name}</span>
        {crew.downloaded && <Badge>Downloaded from Crewing Software</Badge>}
      </NameWrapper>
      <div>
        <input
          disabled={disableChange}
          ref={inputRef}
          onChange={handleChange}
          name={`crew-${crew.id}`}
          type="file"
          accept="image/*,application/pdf"
        />
        {fileError && <ErrorText>{fileError}</ErrorText>}
      </div>
    </Container>
  );
}

const LabelWrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const Label = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${textBlack};
`;

const SubLabel = styled.div`
  font-size: 0.9rem;
  color: ${gray70};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PassportsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid ${gray40};
  padding: 0.5rem;
  border-radius: 4px;
`;

type AddPassportProps = {
  crew: CrewDetails[];
  crewPassports: { [crewId: number]: CrewWithPassport };
  updatePassports: Dispatch<
    SetStateAction<{ [crewId: number]: CrewWithPassport }>
  >;
  setPassportsPassword: Dispatch<SetStateAction<string>>;
  passportsPassword: string;
};

const MemoizedCrewPassport = memo(CrewPassport);

function AddPassport({
  setPassportsPassword,
  passportsPassword,
  updatePassports,
  crewPassports,
  crew,
}: AddPassportProps) {
  const [showSection, setShowSection] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const attachedPassports = useMemo(
    () =>
      Object.keys(crewPassports).reduce((count, key) => {
        if (crewPassports[Number(key)].file) {
          return count + 1;
        }
        return count;
      }, 0),
    [crewPassports]
  );

  // The crew CID is required to fetch the passport
  const crewWithCid = useMemo(
    () => crew.filter((o) => o.cid !== '-' && !!o.cid),
    [crew]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasError(false);
    setPassportsPassword(e.target.value);
    setHasError(!passportPasswordIsValid(e.target.value));
  };

  const handlePreview = async () => {
    const url = await packagePassportsBlobUrl({
      crewPassports,
      passportsPassword,
    });
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleFetchPassport = useCallback(async () => {
    if (!showSection) setShowSection(true);
    setIsLoading(true);
    trackUserAction(TRACK_SEND_EMAIL_FETCH_PASSPORT);
    const passports = await Promise.all(
      crewWithCid.map((o) => getCrewPassport(o.cid!))
    );
    const updatedCrew = passports.reduce(
      (crewMap, { success, data, filename }, index) => {
        if (success && data) {
          const crew = crewWithCid[index];
          let useFilename = `${crew.cid}.pdf`;
          try {
            if (filename) {
              useFilename = filename
                .split('attachment;filename=')[1]
                .replaceAll('"', '');
            }
          } catch (error) {}
          const pdf = new File([data], useFilename);
          crewMap[crew.id] = {
            ...crew,
            downloaded: true,
            file: pdf,
          };
        }
        return crewMap;
      },
      Object.assign({}, crewPassports)
    );
    updatePassports(updatedCrew);
    setIsLoading(false);
  }, [crewWithCid, crewPassports, updatePassports, showSection]);

  return (
    <>
      <LabelWrapper style={{ marginTop: '1rem' }}>
        <Label>Email Attachments</Label>
        <SubLabel>
          {showSection && (
            <span>{`Passports (${attachedPassports}/${crew.length})`}</span>
          )}
          <ButtonV2
            variant="primary"
            size="small"
            onClick={handleFetchPassport}
            loading={isLoading}
          >
            Download Passports
          </ButtonV2>
        </SubLabel>
      </LabelWrapper>
      {showSection && (
        <>
          <PassportsContainer>
            {Object.keys(crewPassports).map((id) => (
              <MemoizedCrewPassport
                disableChange={isLoading}
                updatePassports={updatePassports}
                crew={crewPassports[Number(id)]}
                key={id}
              />
            ))}
          </PassportsContainer>
          <InputWrapper>
            <p>
              You are required to set a password to protect your attachments as
              you send them over the wire.
              <br />
              The receiver will require this password in order to view the
              passports.
            </p>
            <TextField
              onChange={handleChange}
              type="password"
              label="Password"
              size="small"
              error={hasError}
              helperText={
                hasError &&
                'Password has to be at least 6 characters long, contain one number and one letter'
              }
              style={{ width: 240 }}
            />
          </InputWrapper>
          <div style={{ marginTop: '1rem' }}>
            <ButtonV2
              variant="outline-black"
              onClick={handlePreview}
              disabled={
                attachedPassports === 0 ||
                !passportPasswordIsValid(passportsPassword)
              }
              size="small"
            >
              Download Zipped file
            </ButtonV2>
          </div>
        </>
      )}
    </>
  );
}

export default memo(AddPassport);
