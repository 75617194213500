import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { stepsToIndex } from 'components/SetupWizard/helpers';
import { loadInitialSetupWizard } from 'redux/helpers/setupWizard';
import { WizardSteps } from 'utils/types';

import { RootState, SetupWizardState } from '../types';

export const initialSetupWizard: SetupWizardState = loadInitialSetupWizard();

const setupWizard = createSlice({
  name: 'setupWizard',
  initialState: initialSetupWizard,
  reducers: {
    updateAvailableSteps: (state, action: PayloadAction<WizardSteps[]>) => ({
      ...state,
      availableSteps: action.payload,
    }),
    toggleWizardVisiblity: (state, action: PayloadAction<boolean>) => ({
      ...state,
      visible: action.payload,
    }),
    startNewWizard: (state) => ({
      ...state,
      currentStep: state.availableSteps[0],
      isCompleted: false,
    }),
    gotoWizardStep: (state, action: PayloadAction<WizardSteps>) => ({
      ...state,
      currentStep: action.payload,
    }),
    nextWizardStep: (state) => {
      const currentIndex = state.availableSteps.findIndex(
        (o) => o === state.currentStep
      );
      const nextStep = state.availableSteps[currentIndex + 1];
      return {
        ...state,
        currentStep: nextStep || state.currentStep,
      };
    },
    prevWizardStep: (state) => {
      const currentIndex = state.availableSteps.findIndex(
        (o) => o === state.currentStep
      );
      const prevStep = state.availableSteps[currentIndex - 1];
      return {
        ...state,
        currentStep: prevStep || state.currentStep,
      };
    },
    completeWizard: (state) => ({
      ...state,
      currentStep: 'role',
      isCompleted: true,
      showPatBack: true,
    }),
    updatePatBack: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showPatBack: action.payload,
    }),
  },
});

/* ----- selectors -----*/

export const selectSetupWizard = ({ setupWizard }: RootState) => setupWizard;

// starts from 1
export const selectSetupWizardCurrentIndex = createSelector(
  (wizardState: SetupWizardState) => wizardState,
  (wizardState) =>
    stepsToIndex(wizardState.currentStep, wizardState.availableSteps)
);

/* ----- actions -----*/

export const {
  toggleWizardVisiblity,
  nextWizardStep,
  prevWizardStep,
  gotoWizardStep,
  completeWizard,
  startNewWizard,
  updateAvailableSteps,
  updatePatBack,
} = setupWizard.actions;

/* ----- reducer -----*/

export default setupWizard.reducer;
