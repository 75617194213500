import { useEffect } from 'react';
import {
  fetchGeofenceAsync,
  fetchRiskAreaAsync,
  fetchRiskAreaTrackingRequestAsync,
} from 'redux/thunks';

import useAppDispatch from './useAppDispatch';

function useRiskArea(vesselId: number) {
  const dispatch = useAppDispatch();

  // fetch risk areas
  useEffect(() => {
    dispatch(fetchRiskAreaAsync());
    dispatch(fetchGeofenceAsync());
  }, []); // eslint-disable-line

  // fetch tracking requests
  useEffect(() => {
    dispatch(fetchRiskAreaTrackingRequestAsync({ vesselId }));
  }, [vesselId]); // eslint-disable-line

  return null;
}

export default useRiskArea;
