import moment from 'moment-timezone';
import { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';

import { textBlack, textGray, white } from 'lib/colors';
import { DATE_FORMAT, formatDate } from 'utils/format-date';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import { ViewNotesButton } from 'components/shared';
import { Title } from 'components/shared/Typography';
import {
  Container,
  IconContainer,
  VesselDetails,
  Wrapper,
  Indicator,
} from './common';
import Badge from '../common/Badge';
import { ICON_COLOR, TEXT_COLOR } from '../common/variables';

import { JourneyPortVisualCard } from './types';
import { formatPortCallType } from 'utils/routes';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import { isCopiedDisplayName } from './helpers';
import CircleIcon from '@mui/icons-material/Circle';
import AnchorRoundedIcon from '@mui/icons-material/AnchorRounded';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DateText = styled.p<{ $isCompleted?: boolean }>`
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0.5rem 0 0.25rem 0;
  color: ${({ $isCompleted }) => ($isCompleted ? textGray : textBlack)};
`;

const TimeSinceText = styled.p`
  font-size: 0.7rem;
  text-transform: uppercase;
  margin: 0;
  color: ${textGray};
`;

const MetaText = styled.p`
  font-size: 0.7rem;
  margin: 0;
  text-transform: uppercase;
`;

const HeaderWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`;

const ActionWrapper = styled(FlexWrapper)`
  align-items: baseline;
  column-gap: 0.5rem;
`;

const PortIndex = styled(FlexWrapper)<{ $backgroundColor: string }>`
  font-size: 0.6rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${white};
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
`;

const StyledAnchorIcon = styled(AnchorRoundedIcon)`
  position: absolute;
  font-size: 15px !important;
  color: white !important;
  top: 8px;
  left: 8px;
`;

const StyledCircleIcon = styled(CircleIcon)`
  font-size: 30px !important;
`;

type Props = {
  journey: JourneyPortVisualCard;
};

export default function JourneyPortCard({ journey }: Props) {
  const { isInWizard } = useContext(SidePanelFieldContext);
  const {
    index,
    displayName,
    subDisplayName,
    eta,
    etd,
    type,
    isCompleted,
    isFinalDestination,
    port,
    meta,
    utcETA,
    utcETD,
    timezone,
  } = journey;

  const formattedEta = eta && formatDate(eta, DATE_FORMAT.DD_MMM_YY_HH_mm);
  const formattedEtd = etd && formatDate(etd, DATE_FORMAT.DD_MMM_YY_HH_mm);

  // timeSince will only appear if either etd or eta exists
  const timeSince = useMemo(() => {
    if (!utcETA && !utcETD) return null;
    if (utcETA && utcETD) {
      // Check that if both eta and etd is in the future, use ETA instead of ETD
      if (moment(utcETA).diff(moment()) && moment(utcETD).diff(moment())) {
        return moment(utcETA).from(moment());
      }
    }
    return moment(utcETD || utcETA).from(moment());
  }, [utcETA, utcETD]);

  const formattedDate = [formattedEta, formattedEtd]
    .filter(Boolean)
    .join(` \u2192 `);

  const selectedColor = type
    ? (ICON_COLOR as any)[String(type).toLowerCase()] || ICON_COLOR.DEFAULT
    : ICON_COLOR.DEFAULT;

  const selectedTextColor = type
    ? (TEXT_COLOR as any)[String(type).toLowerCase()] || TEXT_COLOR.DEFAULT
    : TEXT_COLOR.DEFAULT;

  const formattedPortName = useMemo(() => {
    if (!port.locode || !port.name) return displayName!;
    const copiedDisplayName = displayName
      ? isCopiedDisplayName(displayName, port.locode || '') &&
        isCopiedDisplayName(displayName, port.name || '')
      : false;
    if (!copiedDisplayName) {
      return `(${port.locode}) ${port.name}`;
    }
    return displayName!;
  }, [port, displayName]);

  return (
    <Container>
      <Indicator
        $isFinalDestination={isFinalDestination}
        $color={selectedColor}
      >
        <span className="indicator_arrows one"></span>
        <span className="indicator_arrows two"></span>
        <span className="indicator_arrows three"></span>
      </Indicator>
      <IconContainer>
        <StyledCircleIcon sx={{ color: selectedColor }} />
        <StyledAnchorIcon />
      </IconContainer>

      <Wrapper $isFinalDestination={isFinalDestination}>
        <VesselDetails
          data-id="e2e_journey-port-details"
          $isCompleted={isCompleted}
        >
          <HeaderWrapper>
            <Badge
              $textColor={selectedTextColor}
              $backgroundColor={selectedColor}
            >
              {formatPortCallType(type as PortCallTypesCommon)}
            </Badge>
            {!isInWizard && (
              <PortIndex $backgroundColor={selectedColor}>
                <span>{index}</span>
              </PortIndex>
            )}
          </HeaderWrapper>
          <Title
            level={4}
            style={{ color: isCompleted ? textGray : textBlack }}
          >
            {formattedPortName}
          </Title>
          {subDisplayName && <MetaText>{subDisplayName}</MetaText>}
          {meta?.function && <MetaText>{meta.function}</MetaText>}
          {formattedDate !== 'Invalid date' && (
            <DateText $isCompleted={isCompleted}>{`${formattedDate}${
              timezone ? ` ${moment().tz(timezone).format('ZZ')}` : ''
            }`}</DateText>
          )}
          <ActionWrapper>
            {timeSince && <TimeSinceText>{timeSince}</TimeSinceText>}
            {port.locode && <ViewNotesButton port={port} view="sidepanel" />}
          </ActionWrapper>
        </VesselDetails>
      </Wrapper>
    </Container>
  );
}
