import isNumber from 'lodash/isNumber';
import filter from 'lodash/filter';
import omit from 'lodash/omit';
import once from 'lodash/once';
import map from 'lodash/map';
import {
  memo,
  useState,
  useEffect,
  useCallback,
  SyntheticEvent,
  useContext,
  useMemo,
  Suspense,
} from 'react';
import { useSelector } from 'react-redux';
import {
  DataGridPro,
  GridEventListener,
  GridEvents,
  GridRowId,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';
import {
  AmadeusAirport,
  Airport as GreywingAirport,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';

import {
  useAppDispatch,
  useDebounce,
  useMobile,
  usePortUtils,
  usePrevious,
  useReadOnlyPlanningData,
} from 'hooks';
import sleep from 'lib/sleep';
import { showToaster } from 'lib/toaster';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { fetchCrewChangePorts } from 'api/flotilla';
import { Port, PortRequest, PortResponse } from 'utils/types/crew-change-types';
import {
  selectCrewChangePanel,
  selectMapVessels,
  selectPreferredPorts,
  selectSettings,
} from 'redux/selectors';
import {
  setCCPanelRequest,
  setCCPanelResponses,
  stopOneClickCrewChange,
} from 'redux/actions';
import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Loader } from 'components/shared';
import ProximityPorts from 'components/CrewChangePanel/Map/ProximityPorts';
import PanelDetail, { PANEL_HEIGHT } from './PortPanel';
import { getColumnVisibility, getGridColumns } from './Header';
import PortActions from '../Actions/Ports';
import PortPopups from '../../Map/Popups';
import Controls from '../../common/Controls';
import { Header, NoRowsOverlay } from '../../common/TableItems';
import { headerStyles, TableWrapper } from '../../common';
import {
  arrangePorts,
  formatPortNearbyAirport,
  formatPorts,
  getPortRequest,
  getPortResponseDetails,
  getPortRows,
  isRouteUpdated,
  setActionTime,
  includePortETA,
  getOneClickPlanPorts,
} from '../../helpers';
import {
  PortRequestTuple,
  PortRow,
  SelectPortNearbyAirport,
  SetFocusedPort,
} from '../../types';

const StyledTableWrapper = styled(TableWrapper)<{ $empty: boolean }>`
  .MuiDataGrid-row {
    cursor: pointer;
  }
  .Mui-selected div button.MuiDataGrid-detailPanelToggleCell {
    color: #fff;
  }
  .MuiDataGrid-virtualScrollerContent {
    ${({ $empty }) =>
      $empty &&
      `
      opacity: 0;
      height: 100%;
    `};
  }
`;

const InitialLoading = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  focused: PortRow | null;
  setFocused: SetFocusedPort<PortRow>;
};

function PortsTable({ focused, setFocused }: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { urgentPorts: urgentPortResponse, proxPorts: proxPortResponse } =
    useSelector(
      ({ crewChangeResources }: RootState) => crewChangeResources.responses
    );
  const { proxPorts: portsRequest } = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.requests
  );
  const { vesselId, event, isOneClickPlan } = useSelector(
    selectCrewChangePanel
  );
  const { compact: isCompact } = useSelector(
    ({ settings }: RootState) => settings.crewChange
  );
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const preferredPorts = useSelector(selectPreferredPorts);

  const {
    data,
    route,
    filters,
    reportInfo: { incompletePlanId },
    portParams: { range, etaLimit, priorities, showUrgentPorts },
    updateReportInfo,
    updateFilters,
    updateFetchStatus,
    updatePlanningData,
    tableState: { step },
    planningData: { crew, route: routePorts },
  } = useContext(CCPanelContext);
  const { addPortDatesAndDeviation } = usePortUtils();
  const { saveIncompletePlan } = useReadOnlyPlanningData();

  const { portResponse, proxPorts, count, currentPage, totalPages } =
    useMemo(() => {
      const proxPorts =
        (showUrgentPorts
          ? urgentPortResponse?.proxPorts
          : proxPortResponse?.proxPorts) || [];
      const count =
        (showUrgentPorts
          ? urgentPortResponse?.count
          : proxPortResponse?.count) || 0;
      const currentPage =
        (showUrgentPorts
          ? urgentPortResponse?.currentPage
          : proxPortResponse?.currentPage) || 0;
      const totalPages =
        (showUrgentPorts
          ? urgentPortResponse?.totalPages
          : proxPortResponse?.totalPages) || 0;
      return {
        portResponse: showUrgentPorts ? urgentPortResponse : proxPortResponse,
        proxPorts,
        count,
        currentPage,
        totalPages,
      };
    }, [proxPortResponse, urgentPortResponse, showUrgentPorts]);

  const apiRef = useGridApiRef();
  const isMobile = useMobile(BREAK_POINT_XS);

  const [loading, setLoading] = useState(false);
  const [ports, setPorts] = useState<Port[]>(
    formatPorts({ data, ports: proxPorts })
  );
  const [expandedRowId, setExpandedRowId] = useState<GridRowId | undefined>();
  const [hoveredRow, setHoveredRow] = useState<PortRow | null>(null);
  const [query, setQuery] = useState('');
  const prevReq = usePrevious(portsRequest);
  const urgentPortsToggled = showUrgentPorts !== usePrevious(showUrgentPorts);

  const vessel = useMemo(
    () => (vesselId ? vessels.get(vesselId) : undefined),
    [vessels, vesselId]
  );

  const handleFetchPorts = useDebounce(
    async (request: PortRequest, scroll?: boolean) => {
      const page = scroll ? currentPage + 1 : 1;

      if (!totalPages || page <= totalPages) {
        setLoading(true);
        // omit `fullPath` field if the request is for vessel-nearby ports
        const updatedRequest = showUrgentPorts
          ? omit(request, 'fullPath')
          : request;
        const response = await fetchCrewChangePorts(updatedRequest, page);
        const updateResponse = getPortResponseDetails(proxPorts, response);
        const { newPorts, newResponse } = await updateResponse(
          addPortDatesAndDeviation
        );
        setPorts(formatPorts({ data, ports: newPorts }));
        // update redux store with fetched port response
        if (response) {
          dispatch(
            setCCPanelResponses({
              response: newResponse,
              type: showUrgentPorts ? 'urgentPorts' : 'proxPorts',
            })
          );
        }
        setLoading(false);
      }
    }
  );

  const handleFetchOnLoad = async () => {
    if (!route) {
      return;
    }

    const routeChanged = isRouteUpdated(route, portsRequest);

    if (vessel && (!portResponse || routeChanged)) {
      const requestArgs: PortRequestTuple = [
        range,
        vessel,
        route,
        event?.locode,
        crew,
      ];
      const newRequest = await getPortRequest(...requestArgs);
      dispatch(setCCPanelRequest({ request: newRequest, type: 'proxPorts' }));
      handleFetchPorts(newRequest);
    }
  };

  const handleRowOver: GridEventListener<GridEvents.rowMouseEnter> =
    useDebounce((params: GridRowParams) => {
      // don not create hover over popup for seleced/expanded port
      if (params.row.id !== expandedRowId || !params.row.selected) {
        setHoveredRow(params.row as PortRow);
      }
    });

  useEffect(() => {
    apiRef.current.subscribeEvent(GridEvents.rowMouseEnter, handleRowOver);
    // trigger saving of incomplete plan
    once(saveIncompletePlan)(incompletePlanId).then((planId) => {
      updateReportInfo({ type: 'incompletePlanId', payload: planId });
    });
    setActionTime('ports', 'start');

    // when ports table mounted, update route `skipped` status based on ETA time limit
    updatePlanningData((prevData) => ({
      ...prevData,
      route: prevData.route.map((item) => ({
        ...item,
        skipped: item.skipped || !includePortETA(item.eta, etaLimit),
      })),
    }));

    return () => {
      setActionTime('ports', 'end');
    };
  }, []); // eslint-disable-line

  // Auto hide popup after 3 secs
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!!hoveredRow) {
      interval = setTimeout(() => {
        setHoveredRow(null);
      }, 3000);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [hoveredRow]);

  useEffect(() => {
    // async fetching to allow ports response be updated in redux
    // and have new value for subsequent pagination update
    const interval = setTimeout(() => {
      handleFetchOnLoad();
    }, 100);
    return () => {
      clearTimeout(interval);
    };
  }, [route]); // eslint-disable-line

  useEffect(() => {
    const rangeUpdated =
      prevReq && portsRequest && prevReq.rangeKM !== portsRequest.rangeKM;
    // fetch ports when range is changed by user or closest/urgent ports filter is toggled
    if (rangeUpdated || urgentPortsToggled) {
      handleFetchPorts(portsRequest);
    }
    // fetch ports with change in ports range & urgent/closest ports toggle
  }, [prevReq?.rangeKM, portsRequest?.rangeKM, urgentPortsToggled]); // eslint-disable-line

  useEffect(() => {
    // remove hovered & focused popups when agency filter updated
    setFocused(null);
  }, [filters.agency]); // eslint-disable-line

  const handleBatchSelect = () => {
    const selectedPorts = isOneClickPlan
      ? getOneClickPlanPorts(proxPorts, preferredPorts)
      : map(
          filter(rows, (_, index) => index <= 2), // select first 3 ports
          (port) => ({ ...port, selected: true }) // insert selected flag
        );

    // filter out ports with ETA outside the limit
    const plannedPorts = filter(selectedPorts, ({ eta }) =>
      includePortETA(eta, etaLimit)
    );
    // update planning data
    updatePlanningData((prevData) => ({ ...prevData, ports: plannedPorts }));
    // discard ongoing one-click plan if no ports available for planning
    if (isOneClickPlan && !plannedPorts.length) {
      dispatch(stopOneClickCrewChange());
      showToaster({
        message:
          'No preferred ports available within the ETA limit. Select a port from the table to continue planning.',
        type: 'warning',
      });
    }
  };

  const handleSelect = (ids: (number | string)[]) => {
    const selectedPorts = ports
      .filter((port) => ids.includes(port.id))
      .map((port) => ({ ...port, selected: true }));

    setHoveredRow(null);
    setPorts((ports) => formatPorts({ data: selectedPorts, ports }));
    updatePlanningData((prevData) => ({ ...prevData, ports: selectedPorts }));
  };

  const handleExpandRow = (ids: GridRowId[]) => {
    if (!ids.length) {
      setExpandedRowId(undefined);
      return;
    }

    const focusedRowId = ids[ids.length - 1];
    // when clicking already open row
    if (focusedRowId === expandedRowId) {
      setExpandedRowId(undefined);
      return;
    }

    setExpandedRowId(undefined);
    sleep(300).then(() => {
      setExpandedRowId(focusedRowId);
    });
  };

  const handleClickRow = (params: GridRowParams, event: SyntheticEvent) => {
    const { id, selectedAirport: airport } = params.row;
    const {
      iataCode,
      name,
      address: { cityName = '' },
    } = airport as AmadeusAirport | GreywingAirport;
    const nameInput = { iataCode, name, cityName };
    const { innerHTML } = event.target as HTMLElement;
    // prevent expanding row when clicked on dropdown
    if (formatPortNearbyAirport(nameInput) !== innerHTML) {
      handleExpandRow([id]);
    }
  };
  const { userInfo } = useSelector(selectSettings);
  const isCTeleportEnabled = userInfo?.access['C-Teleport Booking'] ?? false;

  const handleSelectAirport: SelectPortNearbyAirport = useCallback(
    (locode) => (airport) => {
      // add selected airport to port
      const formatSelectedPorts = (ports: Port[]) =>
        ports.map((port) =>
          port.locode === locode ? { ...port, selectedAirport: airport } : port
        );

      setPorts(formatSelectedPorts);
      dispatch(
        setCCPanelResponses({
          response: {
            ...(portResponse || {}),
            proxPorts: formatSelectedPorts(proxPorts),
          } as PortResponse,
          type: 'proxPorts',
        })
      );
      updatePlanningData(({ ports: prevPorts, ...rest }) => ({
        ...rest,
        ports: formatSelectedPorts(prevPorts),
      }));
      // update `portAirport` in flights filter,
      // if port for this airport change matches the currently active port in filters
      updateFilters({
        type: 'UPDATE_PORT_AIRPORT',
        payload: { locode, airport, updateFetchStatus },
      });
    },
    [portResponse, proxPorts] // eslint-disable-line
  );

  const getDetailPanelContent = useCallback(({ row }: GridRowParams) => {
    return (
      <Suspense fallback={<></>}>
        <PanelDetail<PortRow> port={row} onFind={setFocused} />
      </Suspense>
    );
  }, []); // eslint-disable-line
  const getDetailPanelHeight = useCallback(() => PANEL_HEIGHT, []);

  const rows = arrangePorts(
    getPortRows(ports, preferredPorts, etaLimit),
    { query, priorities, locode: event?.locode } // pass arguments for filtering
  );
  const columns = useMemo(
    () => getGridColumns(routePorts, handleSelectAirport),
    [routePorts, handleSelectAirport]
  );
  const hasMore = proxPorts.length < count;
  const tableLoading = !portResponse || loading;

  return (
    <>
      <Controls
        disableNext={!rows.length}
        portProps={{ selectPorts: handleBatchSelect }}
      >
        {!portResponse && tableLoading && (
          <InitialLoading>
            <CircularProgress size={20} />
          </InitialLoading>
        )}
        {portResponse && (
          <PortActions
            query={query}
            loading={tableLoading}
            setQuery={setQuery}
          />
        )}
      </Controls>
      <StyledTableWrapper $empty={!portResponse}>
        <DataGridPro
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          columnVisibilityModel={getColumnVisibility(
            isMobile,
            isCTeleportEnabled
          )}
          selectionModel={(data as Port[]).map(({ id }) => id)}
          onSelectionModelChange={handleSelect}
          checkboxSelection
          disableSelectionOnClick
          keepNonExistentRowsSelected
          disableColumnMenu
          disableColumnReorder
          hideFooter
          sx={headerStyles}
          loading={tableLoading}
          density={isCompact ? 'compact' : 'standard'}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          getRowClassName={({ row }) => (row.scheduled ? 'scheduled-port' : '')}
          detailPanelExpandedRowIds={expandedRowId ? [expandedRowId] : []}
          onDetailPanelExpandedRowIdsChange={handleExpandRow}
          onRowClick={handleClickRow}
          // allow scroll if response is available
          {...(portResponse
            ? {
                onRowsScrollEnd: () =>
                  hasMore && handleFetchPorts(portsRequest!, true),
              }
            : {})}
          isRowSelectable={({ row: { airportsCount: count } }) =>
            isNumber(count) && count > 0
          }
          components={{
            NoRowsOverlay,
            Header,
            LoadingOverlay: () =>
              !portResponse ? (
                <Loader size={150} />
              ) : (
                (tableLoading && <LinearProgress />) || null
              ),
          }}
          componentsProps={{
            noRowsOverlay: { loading: tableLoading, type: 'ports' },
            header: {
              buttonText: hasMore
                ? 'Load more'
                : (!rows.length && !tableLoading && 'Reload') || '',
              onClick: () => handleFetchPorts(portsRequest!, hasMore),
            },
          }}
        />
      </StyledTableWrapper>
      {/* Render proximity ports on map */}
      {step === 'ports' && (
        <ProximityPorts
          ports={ports}
          hoveredRow={hoveredRow}
          setHoveredRow={setHoveredRow}
          handleExpandRow={handleExpandRow}
          apiRef={apiRef}
        />
      )}
      {/* render popups with hover & focus */}
      <PortPopups hoveredPort={hoveredRow} focusedPort={focused} />
    </>
  );
}

export default memo(PortsTable);
