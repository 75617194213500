import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Grow from '@mui/material/Grow';
import styled from 'styled-components/macro';

import { useModal } from 'hooks';
import { flyTo } from 'map/map';
import { blue, borderGray, gray, purple } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { selectCrewChangePanel } from 'redux/selectors';
import {
  TRACK_VISA_RESTRICTIONS,
  TRACK_PORT_RESTRICTIONS,
} from 'utils/analytics/constants';
import { CCPanelContext } from 'contexts/CCPanelContext';

import {
  Button,
  Card,
  RestrictionsModal,
  ViewNotesButton,
} from 'components/shared';
import ReadOnlyRestrictionsModal from 'components/shared/RestrictionsModal/ReadOnly';
import TravelRequirementsModal from './TravelRequirementsModal';
import {
  formatPortTravelRequirements,
  getTravelRequirementsList,
} from '../../../helpers';
import { PortRow, ReadOnlyPort } from '../../../types';

export const PANEL_HEIGHT = 90;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

const Body = styled.div`
  margin-top: 0.2rem;
`;

const CardBody = styled(FlexWrapper)`
  margin-top: 0.5rem;
`;

const DetailPanelWrapper = styled(FlexWrapper)`
  align-items: baseline;
  background-color: ${gray};
  height: ${PANEL_HEIGHT}px;
  font-size: 0.8rem;
  line-height: 1.1rem;
  padding-left: 20px;
  padding-top: 10px;
  border-bottom: 1px solid ${borderGray};
  overflow: auto;
  z-index: 9;
  overflow-x: auto;
`;

const StyledCard = styled(Card)<{ $width?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : 'fit-content')};
  margin-right: 0.75rem;
  padding: 0.5rem 0.75rem;
  padding-bottom: 0.6rem;
  flex-shrink: 0;
`;

const ActionButton = styled(Button)<{ $background?: string }>`
  height: 22px;
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  background-color: ${({ $background }) => `${$background}`};
`;

export type PanelProps<T> = {
  port: T;
  onFind: React.Dispatch<React.SetStateAction<T | null>>;
  isReportView?: boolean;
};

export default function PanelDetail<T extends PortRow | ReadOnlyPort>({
  isReportView,
  port,
  onFind,
}: PanelProps<T>): JSX.Element {
  const { readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const { planningData } = useContext(CCPanelContext);
  const { modal, setModal } = useModal(null);

  const { crew: readOnlyCrew = [] } = reportData?.crewChangePlan || {};
  const {
    name,
    lat,
    lng,
    country: { travelRequirements },
  } = port;
  const crewList = isReportView ? readOnlyCrew : planningData.crew;
  const requirementsList = useMemo(
    () => getTravelRequirementsList(crewList, travelRequirements),
    [crewList, travelRequirements]
  );

  const handleClickRestrictions = () => {
    trackUserAction(TRACK_PORT_RESTRICTIONS);
    setModal('restrictions');
  };

  const handleFocusOnPort = () => {
    onFind(port);
    flyTo({ lat, lng }, 3);
  };

  const renderTravelRequirements = () => {
    if (!Object.keys(requirementsList).length) {
      return <Body>No travel requirements available.</Body>;
    }

    return (
      <CardBody>
        {Object.keys(requirementsList).map((countryCode) => {
          const { countryName: country = 'Unknown' } =
            requirementsList[countryCode] || {};
          const { color, offsigner, onsigner } = formatPortTravelRequirements(
            requirementsList[countryCode]
          );

          return (
            <ActionButton
              key={countryCode}
              $background={color}
              onClick={() => {
                trackUserAction(TRACK_VISA_RESTRICTIONS);
                setModal('travelRequirements', {
                  country,
                  offsigner,
                  onsigner,
                });
              }}
            >
              {country}
            </ActionButton>
          );
        })}
      </CardBody>
    );
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    const closeModal = () => setModal(null);

    if (modal.type === 'restrictions') {
      return isReportView ? (
        <ReadOnlyRestrictionsModal
          port={port as ReadOnlyPort}
          closeModal={closeModal}
        />
      ) : (
        <RestrictionsModal
          type="port"
          restrictionDetails={port}
          closeModal={closeModal}
        />
      );
    }

    if (modal.type === 'travelRequirements') {
      return (
        <TravelRequirementsModal
          details={modal.data!}
          closeModal={closeModal}
        />
      );
    }
  };

  return (
    <>
      <Grow in timeout={500}>
        <DetailPanelWrapper>
          <StyledCard>
            <Title>Port:</Title> {name}
            <CardBody>
              <ActionButton $background={blue} onClick={handleFocusOnPort}>
                See on Map
              </ActionButton>
            </CardBody>
          </StyledCard>

          <StyledCard>
            <Title>Port Restrictions:</Title>
            <CardBody>
              <ActionButton
                $background={purple}
                onClick={handleClickRestrictions}
              >
                Click for details
              </ActionButton>
            </CardBody>
          </StyledCard>

          <ViewNotesButton port={port} view="ccpanel" />

          {/* Temporarily hiding for all users */}
          {false && (
            <StyledCard>
              <Title>Travel Requirements:</Title>
              {renderTravelRequirements()}
            </StyledCard>
          )}
        </DetailPanelWrapper>
      </Grow>

      {renderModal()}
    </>
  );
}
