import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { useCrewChangeEvent, useOneClickPlan } from 'hooks';
import {
  blue,
  borderGray,
  gray10,
  green,
  red,
  textGray,
  white,
  fadedGreen,
} from 'lib/colors';
import { selectCrewChangePanel } from 'redux/selectors';
import { formatDate } from 'utils/format-date';
import { isHistoricalEvent } from 'utils/crew-change-events';
import { OnOffSigner } from 'components/icons';
import { Button, Tooltip } from 'components/shared';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_ONE_CLICK_CREW_CHANGE_START } from 'utils/analytics/constants';
import { selectSinglePortCall } from 'redux/reducers/portCalls';
import { RootState } from 'redux/types';
import SendSeaGptEmailModal from './SendSeaGptEmailModal';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ShowMoreContainer = React.lazy(() => import('./ShowMoreContainer'));

const Container = styled.div`
  background: ${gray10};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding: 0.5rem;
`;

const Header = styled.div<{ isForecast: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h5 {
    font-size: 14px;
    margin: 0;
  }
  ${({ isForecast }) =>
    isForecast &&
    `
    background-color: ${fadedGreen}30;
    border-radius: 0.25rem;
    padding-left:0.5rem;
    h5{
      font-size:13px;
    }
    `};
`;

const NotButton = styled.div`
  font-size: 12px;
  height: 32px;
  width: 150px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid ${borderGray};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  color: ${textGray};
`;

const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TimeContainer = styled.div`
  flex: 0 1 120px;
`;

const TimeWrapper = styled.div`
  font-size: 0.7rem;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  #title {
    color: ${textGray};
  }
  #time {
    font-weight: 700;
  }
`;

const IconContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  font-size: 24px;
`;

const IconGroup = styled.div<{ $color: string }>`
  display: flex;
  column-gap: 0.25rem;
  align-items: center;
  color: ${({ $color }) => $color};
`;

const OpenCloseContainer = styled.div`
  position: relative;
  #line {
    display: block;
    height: 2px;
    width: 100%;
    background-color: ${borderGray};
    margin: 12px 0;
  }
`;

const IconCircle = styled.div`
  position: absolute;
  top: 2px;
  left: 50%;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  transform: translateX(-50%);
  background-color: ${blue};
  color: ${white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FeatureButton = styled(Button)`
  width: 100%;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  margin-top: 0.5rem;
`;

const StyledIcon = styled(AutoAwesomeRoundedIcon)`
  margin-left: 0.5rem;
  font-size: 18px !important;
`;

type OpenCloseProps = {
  showMore: boolean;
  setShowMore: Dispatch<SetStateAction<boolean>>;
};

type CrewChangeEventProps = {
  vesselId: number;
  event: CrewEvent;
};

function OpenClose({ showMore, setShowMore }: OpenCloseProps) {
  return (
    <OpenCloseContainer>
      <span id="line" />
      <IconCircle onClick={() => setShowMore((o) => !o)}>
        {showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconCircle>
    </OpenCloseContainer>
  );
}

function EventDetails({ vesselId, event }: CrewChangeEventProps) {
  const { active, event: crewChangeEvent } = useSelector(selectCrewChangePanel);
  const selectedPortCall = useSelector((state: RootState) =>
    selectSinglePortCall(state, vesselId)
  );
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);
  const { isOneClickAvailable } = useOneClickPlan({ vesselId });

  const canUseAI = userInfo?.access['SeaGPT'];
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const { id, name, eta, etd, crew, locode } = event;
  const inProgress = event.id === crewChangeEvent?.id && active === 'plan';

  const [showMore, setShowMore] = useState<boolean>(false);
  const { onSelectEvent } = useCrewChangeEvent({ vesselId, event });

  const crewCount = useMemo(() => {
    return (crew || []).reduce(
      (acc, crewMember) => {
        if (crewMember.type === 'onsigner') {
          acc.onsigner += 1;
        } else {
          acc.offsigner += 1;
        }
        return acc;
      },
      { onsigner: 0, offsigner: 0 }
    );
  }, [crew]);

  const renderActionButton = () => {
    if (isHistoricalEvent(event)) {
      return null;
    }

    return inProgress ? (
      <NotButton>IN PROGRESS</NotButton>
    ) : (
      <Tooltip
        content="Plan a crew change for this set of crew"
        enterDelay={500}
      >
        <Button
          onClick={() => onSelectEvent({ activity: 'plan' })}
          style={{ marginRight: 0, fontSize: '12px' }}
        >
          START PLAN
        </Button>
      </Tooltip>
    );
  };

  const canSendEmail =
    canUseAI && crew.length && (!!locode || !!selectedPortCall.response);

  return (
    <Container>
      <Header isForecast={name?.toLowerCase().includes('forecast') ?? false}>
        {name ? <h5>{name}</h5> : <h5>Unnamed ({id})</h5>}
        {renderActionButton()}
      </Header>

      <InfoContainer>
        <TimeContainer>
          <TimeWrapper>
            <span id="title">ETA</span>
            <span id="time">{eta ? formatDate(eta) : 'Not specified'}</span>
          </TimeWrapper>
          <TimeWrapper>
            <span id="title">ETD</span>
            <span id="time">{etd ? formatDate(etd) : 'Not specified'}</span>
          </TimeWrapper>
        </TimeContainer>
        <IconContainer>
          <IconGroup $color={red}>
            <span>{crewCount.offsigner}</span>
            <OnOffSigner isOffsigner selected size={26} color={red} />
          </IconGroup>
          <IconGroup $color={green}>
            <span>{crewCount.onsigner}</span>
            <OnOffSigner isOffsigner={false} selected size={26} color={green} />
          </IconGroup>
        </IconContainer>
      </InfoContainer>

      <OpenClose showMore={showMore} setShowMore={setShowMore} />

      <Suspense fallback={<></>}>
        <ShowMoreContainer
          showMore={showMore}
          handleCrew={() => onSelectEvent({ activity: 'crew' })}
          crews={crew || []}
        />
      </Suspense>

      {isOneClickAvailable && (
        <FeatureButton
          variant="primary"
          onClick={() => {
            trackUserAction(TRACK_ONE_CLICK_CREW_CHANGE_START, 'click', {
              id,
              name,
            });
            onSelectEvent({ activity: 'plan', isOneClickPlan: true });
          }}
        >
          One-Click Crew Change
        </FeatureButton>
      )}
      {canSendEmail && (
        <FeatureButton
          variant="secondary"
          onClick={() => setShowAddModal(true)}
        >
          Send Proteus Email <StyledIcon />
        </FeatureButton>
      )}
      {showAddModal && (selectedPortCall.response || locode) && (
        <SendSeaGptEmailModal
          vesselId={vesselId}
          crewEvent={event}
          portsResponse={selectedPortCall.response}
          onClose={() => setShowAddModal(false)}
        />
      )}
    </Container>
  );
}

export default EventDetails;
