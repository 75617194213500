import {
  define,
  object,
  pattern,
  string,
  is,
  optional,
  Struct,
} from 'superstruct';
import { WizardSteps } from 'utils/types';

export function stepsToIndex(
  currentStep: WizardSteps,
  availableSteps: WizardSteps[]
): number {
  const rawIndex = availableSteps.findIndex((o) => o === currentStep);
  return rawIndex !== -1 ? rawIndex + 1 : rawIndex;
}

const email = () => pattern(string(), /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+$/);
const message = <T>(struct: Struct<T, any>, message: string): Struct<T, any> =>
  define('message', (value) => (is(value, struct) ? true : message));

export const NotificationContactSchema = object({
  email: message(
    email(),
    `A valid email is required. You won't receive any email notifications if you leave this empty.`
  ),
  firstname: message(optional(string()), 'Please include a valid name.'),
  lastname: message(optional(string()), 'Please include a valid name.'),
  phone: message(optional(string()), 'Please include a valid name.'),
  timezone: optional(string()), // included here but it is not a visible field for the user
});
