import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import moment from 'moment';
import { createSelector } from '@reduxjs/toolkit';

import { formatDate } from 'utils/format-date';
import { VesselInfo, VesselSummary } from 'components/CrewMatrixPlanning/types';
import { RootState } from 'redux/types';

type CalendarEventsProps = {
  selectedVessel: VesselInfo | null;
  scheduleDate: string;
};

const getScheduleEvents = (state: RootState) =>
  state.crewChangeMatrix.scheduleEvents;
const getFeedbacks = (state: RootState) => state.crewChangeMatrix.feedback;

// wrapper function to provide a selector that returns feedbacks
export const getEventFeedbacks = (eventId: number | undefined) =>
  createSelector(
    getFeedbacks,
    (feedbacks) => (feedbacks && eventId && feedbacks[eventId]) || []
  );

// wrapper function to provide a selector that returns summary events
export const getVesselSummaryEvents = (vesselId: number) =>
  createSelector(getScheduleEvents, (scheduleEvents) =>
    sortBy(
      filter(
        uniqBy(flatten(values(scheduleEvents)), 'id'),
        ({ vessel }) => vessel?.id === vesselId
      ),
      'eventDate'
    )
  );

// wrapper function to provide a selector that returns summary event dates
export const getVesselEventDates = (vesselId: number) =>
  createSelector(getVesselSummaryEvents(vesselId), (vesselSummaryEvents) =>
    groupBy(vesselSummaryEvents, ({ eventDate }) =>
      formatDate(eventDate, 'DD MMM, YYYY')
    )
  );

// wrapper function to provide a selector that returns events of a month
export const getVesselEventsOfMonth = (props: CalendarEventsProps) =>
  createSelector(getScheduleEvents, (scheduleEvents) => {
    const { selectedVessel, scheduleDate } = props;
    // util to find the selected vessel events for this month
    // if no vessel selected, find events for all vessels
    return uniqBy(flatten(Object.values(scheduleEvents || {})), 'id').filter(
      ({ vessel, eventDate }) =>
        (selectedVessel ? selectedVessel.id === vessel.id : true) &&
        moment(eventDate).isSame(moment(scheduleDate), 'month')
    );
  });

// wrapper function to provide a selector that returns formatted list of vessel events in calendar
export const getCalendarVesselSummaryList = (props: CalendarEventsProps) =>
  createSelector(
    ({ crewChangeMatrix }: RootState) => crewChangeMatrix.vessels,
    getVesselEventsOfMonth(props),
    (vessels, eventsOfMonth) => {
      if (!props.selectedVessel) {
        if (
          new Date(props.scheduleDate) < new Date() &&
          !eventsOfMonth.length
        ) {
          return [];
        }
        // This will return all vessels
        return vessels
          .map<VesselSummary>((vessel) => {
            // check for events of the month
            const events = eventsOfMonth.filter(
              (event) => event.vessel.id === vessel.id
            );
            const eventDates = events.map(({ eventDate }) => eventDate);
            // count the number of times each event date appears and return the maximum
            const rowCount = Math.max(
              ...eventDates.map(
                (eventDate) =>
                  eventDates.filter((date) => date === eventDate).length
              ),
              1
            );

            return {
              id: vessel.id,
              rowCount,
              eventCount: events.length,
              eventDates,
              vesselName: vessel.vesselName,
            };
          })
          .sort((a, b) => a.vesselName.localeCompare(b.vesselName));
      } else {
        if (!eventsOfMonth.length) {
          // this will return only selected vessel
          return [
            {
              id: props.selectedVessel.id,
              rowCount: 1,
              eventCount: 0,
              eventDates: [],
              vesselName: props.selectedVessel.vesselName,
            },
          ];
        }

        const events = eventsOfMonth.filter(
          (event) => event.vessel.id === props.selectedVessel!.id
        );
        const eventDates = events.map(({ eventDate }) => eventDate);
        // count the number of times each event date appears and return the maximum
        const rowCount = Math.max(
          ...eventDates.map(
            (eventDate) =>
              eventDates.filter((date) => date === eventDate).length
          ),
          1
        );

        return [
          {
            id: props.selectedVessel.id,
            rowCount,
            eventCount: events.length,
            eventDates,
            vesselName: props.selectedVessel.vesselName,
          },
        ];
      }
    }
  );
