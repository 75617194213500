import React, { useEffect } from 'react';
import { blue, white, purple } from 'lib/colors';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components/macro';
import Bowser from 'bowser';

const Wrapper = styled.div`
  position: fixed;
  z-index: 10000;
  background-color: ${blue};
  color: ${white};
  font-size: 12.5px;
  width: 100%;
  min-height: 2.5rem;
  padding: 0.4rem 0 0.4rem 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${purple};
`;

const StyledLink = styled.div`
  margin: 0 1rem;
  border: 1px solid ${white};
  border-radius: 2px;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledLogo = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 0.8rem;
  padding: 1px;
  border-radius: 6px;
  background-color: ${white};
  display: flex;
  justify-content: fit-content;
`;

const StyledCloseIcon = styled(CloseIcon)`
  margin: 0 1rem 0 auto;
  cursor: pointer;
`;

interface IProps {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}

const BrowserWarning = (props: IProps) => {
  const { state, setState } = props;

  const handleClose = () => {
    localStorage.setItem('BrowserWarning', '0');
    setState('');
  };

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const browserVersion = browser.getBrowserVersion();
    if (browserName !== 'Chrome') {
      setState(
        'You are recommended to use Chrome browser for the best experience.'
      );
    } else if (
      browserName === 'Chrome' &&
      parseInt(browserVersion.split('.')[0]) < 100
    ) {
      setState('Please update your browser to the latest version.');
    }
  }, []); // eslint-disable-line

  return state.length !== 0 ? (
    <Wrapper>
      <StyledLogo>
        <img
          className="greywing-icon"
          src="./greywing-icon.svg"
          alt="greywing icon"
        />
      </StyledLogo>
      {state}
      <StyledLink
        onClick={() => {
          window.open(
            'https://www.google.com/chrome/',
            '_blank',
            'noopener noreferrer'
          );
          handleClose();
        }}
      >
        {state.includes('update') ? 'Update' : 'Install'} Chrome
      </StyledLink>
      <StyledCloseIcon onClick={handleClose} />
    </Wrapper>
  ) : null;
};

export default BrowserWarning;
