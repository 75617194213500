import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { setRecentSearches } from 'redux/actions';
import { SearchContext } from 'contexts/SearchContext';
import { RootState } from 'redux/types';

import useAppDispatch from './useAppDispatch';

const FLIGHT_SEARCH_FIELDS = [
  'date',
  'departure',
  'arrival',
  'cabinClass',
] as const;

function useRecentFlotillaSearches() {
  const dispatch = useAppDispatch();
  const flightSearch = useSelector(
    ({ flotillaSearch }: RootState) => flotillaSearch.flightSearch
  );
  const flightDetails = useMemo(
    () => pick(flightSearch, FLIGHT_SEARCH_FIELDS),
    [flightSearch]
  );

  const {
    isLoading,
    inputStr,
    searchType,
    results: allResults,
  } = useContext(SearchContext);

  // eslint-disable-next-line
  const debouncedUpdate = useCallback(
    debounce(() => {
      const [, searchText = ''] = inputStr.split(':').map((str) => str.trim());
      if (!isLoading && searchText.length >= 3 && allResults.length) {
        dispatch(setRecentSearches({ flotillaSearch: { inputStr } }));
      }
    }, 500),
    [isLoading, inputStr, allResults]
  );

  const updateFlightSearch = useCallback(() => {
    // don't update stored flight if details unavailable
    if (!flightDetails.departure && !flightDetails.arrival) {
      return;
    }

    const payload = {
      flotillaSearch: { inputStr, details: flightDetails },
    };
    dispatch(setRecentSearches(payload));
  }, [dispatch, inputStr, flightDetails]);

  useEffect(() => {
    const { type } = searchType;
    switch (type) {
      // don't include search for deviation in store
      case 'deviation':
        break;

      case 'searchFlights':
        // update recent searches for flight search items
        updateFlightSearch();
        break;

      default:
        // update recent searches for other flotilla search items
        debouncedUpdate();
        break;
    }
  }, [searchType, updateFlightSearch, debouncedUpdate]);
}

export default useRecentFlotillaSearches;
