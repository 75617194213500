import { PriceDiffTypes } from '@greywing-maritime/frontend-library/dist/types/trackFlightsTypes';

import { SIDE_PANEL_DEFAULT_EXCLUSION } from 'lib/constants';
import { sanitizeAppSettings } from 'utils/settings';
import { getSidePanelDisplayableFields } from 'utils/vesselFields';
import { validateAppSettings } from 'utils/validation/settings';
import { FLOTILLA_SETTINGS_KEY } from 'utils/types';
import {
  DEFAULT_COST_OPTIONS,
  DEFAULT_FLIGHT_TIME,
  DEFAULT_HOTEL_COST_DELAY,
  DEFAULT_LAYOVER_RANGE,
  DEFAULT_STOPS_COUNT,
  initialPortParams,
} from 'components/CrewChangePanel/helpers';

import { AppSettings, CCPanelSettings, UnitType } from '../types';

const getAppSettingsFromLocalStorage = (): AppSettings | null => {
  let flotillaSettings: AppSettings | null = null;
  try {
    const tmp = window.localStorage.getItem(FLOTILLA_SETTINGS_KEY);
    if (tmp) {
      flotillaSettings = JSON.parse(tmp);
    }
  } catch (err) {
    console.log(
      'error parsing flotilla config state from local storage. erasing it...',
      err
    );
    window.localStorage.removeItem(FLOTILLA_SETTINGS_KEY);
  }

  return flotillaSettings;
};

const initialCrewChangeSettings: CCPanelSettings = {
  showGuide: true,
  predictive: true,
  compact: false,
  // set initial values of port & flight filters in crew change panel
  portParams: initialPortParams,
  flightParams: {
    time: DEFAULT_FLIGHT_TIME,
    layover: DEFAULT_LAYOVER_RANGE,
    stops: DEFAULT_STOPS_COUNT,
    allowAirportTransfer: false,
  },
  costParams: {
    hotelCostDelay: DEFAULT_HOTEL_COST_DELAY,
    costOptions: DEFAULT_COST_OPTIONS,
  },
};

const initialVesselsSettings = {
  predictive: true,
  updateFrequency: 5,
  unit: 'NM' as UnitType,
};

export const DEFAULT_POPUP_FIELDS = [
  'imo',
  'secondsToNextPort',
  'speed',
  'nextPortName',
  'arrival',
  'compound_localTime',
];

export const DEFAULT_TRACK_FLIGHT_SETTINGS = {
  receiveNotifications: false,
  priceDiff: { type: PriceDiffTypes.ACT, value: 200 },
  stopDaysBefore: 2,
  sameFlightsOnly: false,
  sameSourceOnly: false,
};

export const DEFAULT_SETTINGS = {
  crossDevices: true,
  colorHighlightField: 'type',
  popupFields: DEFAULT_POPUP_FIELDS,
  sidePanelFields: getSidePanelDisplayableFields().filter(
    (fieldId) => !SIDE_PANEL_DEFAULT_EXCLUSION.includes(fieldId)
  ),
  vesselLabelField: 'label_name',
  quickFlyTMC: 'ALL',
  searchFilters: [],
  userInfo: undefined,
  crewChange: initialCrewChangeSettings,
  vessels: initialVesselsSettings,
  trackSettings: null,
};

export const loadInitialAppSettings = (): AppSettings => {
  const stored = getAppSettingsFromLocalStorage();
  const saveableSettings = stored || DEFAULT_SETTINGS;
  return {
    ...DEFAULT_SETTINGS,
    ...(validateAppSettings(sanitizeAppSettings(saveableSettings)) || {}),
  };
};
