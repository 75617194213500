import styled from 'styled-components/macro';

import { textGray } from 'lib/colors';
import { HeaderProps } from './types';
import { ActionButton } from '../../common';

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-top-left-radius: 0.25rem;
  font-size: 0.8rem;
  line-height: 1rem;
  height: fit-content;
  width: fit-content;
  padding: 0.5rem 1rem;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.2);
  z-index: 99;
`;

const StyledButton = styled(ActionButton)`
  margin: auto 2px;
`;

const Bar = styled.div`
  border-right: 2.5px solid ${textGray};
  height: 0.8rem;
  margin: auto 0.6rem;
`;

function Header({ countText, buttonText, onClick }: HeaderProps) {
  const showBar = Boolean(countText && buttonText);
  const showFooter = Boolean(countText || buttonText);
  return showFooter ? (
    <Wrapper>
      {countText || ''}
      {buttonText && (
        <>
          {showBar ? <Bar /> : null}
          <StyledButton variant="primary" onClick={onClick}>
            {buttonText}
          </StyledButton>
        </>
      )}
    </Wrapper>
  ) : null;
}

export default Header;
