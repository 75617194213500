import {
  SyntheticEvent,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { MenuItem, Menu, Fade } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { NestedMenuItem } from 'mui-nested-menu';
import styled from 'styled-components/macro';

import { useCCPanelExport } from 'hooks';
import { SetModal } from 'hooks/useModal';
import { blue, white } from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { Tooltip } from 'components/shared';
import ActionButton from './ActionButton';
import { getLocodeKeyDetails } from '../helpers';

const StyledNestedMenuItem = styled(NestedMenuItem)`
  .MuiTypography-root {
    display: flex;
    align-items: center;
    font-size: 0.9rem !important;
    line-height: 1 !important;
    font-weight: 500;
  }
`;

type Props = {
  setModal: SetModal;
};

const textStyles = {
  lineHeight: 1,
  fontWeight: '500',
  transition: 'all 150ms ease',
  '&:hover': {
    background: blue,
    color: white,
  },
};

function DownloadButton({ setModal }: Props) {
  const {
    filters,
    reportInfo: { savedLink },
  } = useContext(CCPanelContext);
  const { saveExcelReport } = useCCPanelExport();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { selectedLocode, confirmedFlights } = useMemo(() => {
    const selectedLocode = Object.keys(filters).find(
      (locodeKey) => filters[locodeKey].active
    );
    const { confirmed: confirmedFlights = [] } = selectedLocode
      ? filters[selectedLocode]
      : {};
    return { selectedLocode, confirmedFlights };
  }, [filters]);

  const handleDownloadRegularReport = useCallback(
    (data: { selectedLocode?: string; portAgent?: true } = {}) => {
      // if full regular report alerady saved
      //  download/export report without saving/additional steps
      if (savedLink) {
        saveExcelReport({ ...data, skipSaving: true });
        return;
      }
      // otherwise, open modal for additional steps
      setModal('confirmDownload', data);
    },
    [savedLink, saveExcelReport, setModal]
  );

  if (!confirmedFlights.length) {
    return null;
  }

  if (!selectedLocode) {
    return null;
  }

  // check if multiple port cards available
  if (Object.keys(filters).length <= 1) {
    return (
      <>
        <ActionButton
          className="e2e_download-report"
          variant="primary"
          buttonText="Download"
          tooltipContent="Export crew-change report in Excel format"
          iconLeft={SimCardDownloadRoundedIcon}
          iconRight={anchorEl ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
          onClick={(event?: SyntheticEvent) =>
            setAnchorEl(event?.currentTarget as HTMLElement)
          }
        />

        <Menu
          id="fade-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={(e) => {
            // @ts-ignore
            e.stopPropagation();
            setAnchorEl(null);
          }}
          TransitionComponent={Fade}
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          sx={{ marginTop: '1px' }}
        >
          <MenuItem
            data-id="e2e_download-full-report"
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadRegularReport();
              setAnchorEl(null);
            }}
            sx={textStyles}
          >
            Download Regular Report
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              handleDownloadRegularReport({ selectedLocode, portAgent: true });
              setAnchorEl(null);
            }}
            sx={textStyles}
          >
            Download For Port Agent
          </MenuItem>
        </Menu>
      </>
    );
  }

  const {
    locode: originalLocode,
    portETA: uniqETA,
    flightSource,
  } = getLocodeKeyDetails(selectedLocode);
  const baseLocode = uniqETA
    ? `${originalLocode}(${formatDate(uniqETA, 'DD-MM-YYYY')})`
    : originalLocode;
  const portLocodeText = flightSource
    ? `${baseLocode}--${flightSource}`
    : baseLocode;

  return (
    <>
      <ActionButton
        className="e2e_download-report"
        variant="primary"
        buttonText="Download"
        tooltipContent="Export crew-change report in Excel format"
        iconLeft={SimCardDownloadRoundedIcon}
        iconRight={anchorEl ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon}
        onClick={(event?: SyntheticEvent) =>
          setAnchorEl(event?.target as HTMLElement)
        }
      />

      <Menu
        id="fade-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{ marginTop: '1px' }}
      >
        <MenuItem
          data-id="e2e_download-full-report"
          onClick={(e) => {
            e.stopPropagation();
            handleDownloadRegularReport();
            setAnchorEl(null);
          }}
          sx={textStyles}
        >
          Download Full Report
        </MenuItem>

        <StyledNestedMenuItem
          parentMenuOpen
          rightIcon={null}
          label={`Download For ${portLocodeText || 'Current Port'}`}
          leftIcon={<KeyboardArrowLeftIcon />}
          // position nested menu to the left
          MenuProps={{
            anchorOrigin: { vertical: 'center', horizontal: 'left' },
            transformOrigin: { vertical: 'center', horizontal: 'right' },
          }}
          sx={textStyles}
        >
          <Tooltip
            placement="left"
            enterDelay={250}
            content="Download this report in excel format"
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setModal('confirmDownload', { selectedLocode });
                setAnchorEl(null);
              }}
              sx={textStyles}
            >
              Regular
            </MenuItem>
          </Tooltip>
          <Tooltip
            placement="left"
            enterDelay={250}
            content="Download excel report for Port Agent"
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation();
                setModal('confirmDownload', {
                  selectedLocode,
                  portAgent: true,
                });
                setAnchorEl(null);
              }}
              sx={textStyles}
            >
              Port Agent
            </MenuItem>
          </Tooltip>
        </StyledNestedMenuItem>
      </Menu>
    </>
  );
}

export default memo(DownloadButton);
