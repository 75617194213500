import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import { red, green, textBlack, white, blue, purple } from 'lib/colors';
import { VesselFieldId } from 'utils/types';

const ICON_COLOR: { [key: PortCallTypesCommon | string]: string } = {
  voyage_plan: red,
  'user input': blue,
  predictive: green,
  dataloy: '#DE1077',
  ais: textBlack,
  maersk: '#42B0D5',
  DEFAULT: textBlack,
  cma_cgm: purple,
};

const TEXT_COLOR: { [key: PortCallTypesCommon | string]: string } = {
  voyage_plan: white,
  'user input': white,
  predictive: white,
  dataloy: white,
  ais: white,
  maersk: white,
  DEFAULT: white,
  cma_cgm: white,
};

const FIELDS_IN_COURSE_HEADER: Array<VesselFieldId> = [
  'imo',
  'secondsToNextPort',
  'speed',
];

export { ICON_COLOR, TEXT_COLOR, FIELDS_IN_COURSE_HEADER };
