import styled from 'styled-components/macro';

import { Button } from 'components/shared';

export const StyledButton = styled(Button)`
  height: 25px;
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  max-height: 400px;
  overflow-y: auto;
  padding: 0.25rem 0;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderButton = styled(Button)`
  width: 95%;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const Text = styled.div`
  font-size: 0.9rem;
  margin: 0.25rem;
`;

export const ButtonWrapper = styled.div`
  padding: 0 0.5rem 0.25rem;
`;

export const NewPlanButton = styled(Button)`
  width: 100%;
  font-size: 0.9rem;
  padding: 0.5rem 0;
`;
