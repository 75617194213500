import React from 'react';

const UserWithArrowSVG = ({
  color = 'black',
  height = 18,
  width = 24,
  style,
}: {
  color?: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
}) => (
  <svg
    viewBox="0 -20.754 756.366 526.858"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: color, width, height, ...style }}
  >
    <path d="M 477.51 92.666 C 469.574 92.063 462.051 96.232 458.34 103.307 L 382.048 249.013 C 379.497 253.843 379.08 259.52 380.864 264.701 C 382.649 269.883 386.471 274.099 391.456 276.334 L 541.279 344.182 C 548.56 347.471 557.054 346.126 562.936 340.765 C 568.818 335.402 570.941 327.031 568.373 319.572 L 548.908 263.043 L 731.842 200.053 C 745.738 195.269 753.1 180.176 748.315 166.279 L 731.013 116.032 C 726.228 102.135 711.135 94.774 697.239 99.558 L 514.306 162.548 L 494.841 106.019 C 492.246 98.482 485.446 93.271 477.51 92.666 Z" />
    <path d="M 270.145 243.487 C 368.68 243.487 430.264 136.821 380.996 51.487 C 358.131 11.884 315.875 -12.513 270.145 -12.513 C 171.611 -12.513 110.027 94.154 159.294 179.487 C 182.159 219.091 224.415 243.487 270.145 243.487 Z M 224.445 291.487 C 125.945 291.487 46.145 371.287 46.145 469.787 C 46.145 486.187 59.445 499.487 75.845 499.487 L 464.445 499.487 C 480.845 499.487 494.145 486.187 494.145 469.787 C 494.145 371.287 414.345 291.487 315.845 291.487 L 224.445 291.487 Z" />
  </svg>
);

export default UserWithArrowSVG;
