import join from 'lodash/join';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import partialRight from 'lodash/partialRight';
import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { formatDate } from 'utils/format-date';
import { Port } from 'utils/types/crew-change-types';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { RootState } from 'redux/types';

import SelectETASlider from './SelectETASlider';
import { includePortETA } from '../../../helpers';
import { ReadOnlyPort, RoutePort } from '../../../types';

type Props = {
  port: Port | ReadOnlyPort;
  routePorts: RoutePort[];
};

const formatETA = (eta: string) => {
  if (!eta) return '-';
  const etaDifference = moment(eta).diff(moment(), 'days');
  return etaDifference ? formatDate(eta) : '<1 day';
};

function PortETA({ port, routePorts }: Props) {
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const reportData = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.readOnlyPlanningData
  );
  const { portParams } = useContext(CCPanelContext);

  const { eta, locode, selected } = port;
  const isReportView = activity === 'readOnly' && Boolean(reportData);
  const { crewChangePlan } = reportData || {};
  const etaLimit =
    (isReportView && crewChangePlan?.portFilters.etaLimit) ||
    portParams.etaLimit;

  const portETAs = useMemo(
    () =>
      uniqBy(
        routePorts
          .filter(
            ({ eta, port }) =>
              locode === port.locode &&
              // filter out ETAs beyond the ETA limit
              includePortETA(eta, etaLimit)
          )
          .map(({ eta }) => eta),
        // format & get only uniq dates to prevent possible duplicates
        partialRight(formatDate, 'DD MMM, YY')
      ) as string[],
    [locode, etaLimit, routePorts]
  );

  if (portETAs.length <= 1) {
    return <span>{eta ? formatETA(eta) : '---'}</span>;
  }

  if (isReportView) {
    return <span>{eta ? join(map(portETAs, formatETA), ' | ') : '---'}</span>;
  }

  return (
    <SelectETASlider
      locode={port.locode}
      etaDates={portETAs}
      selected={selected}
    />
  );
}

export default memo(PortETA);
