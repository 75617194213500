import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { trackUserAction } from 'lib/amplitude';
import { TRACK_ASSIGN_PIC } from 'utils/analytics/constants';
import { assignMyselfAsPIC } from 'api/flotilla';
import { updateInCharge } from 'redux/actions';
import { selectSidePanelVessels } from 'redux/reducers/sidePanel';
import { RootState } from 'redux/types';

import { ActionButtonState } from 'components/SidePanel/SidePanelVesselHeader';
import useAppDispatch from './useAppDispatch';

const useAssignPIC = () => {
  const dispatch = useAppDispatch();
  const sidePanelVessels = useSelector(selectSidePanelVessels);
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);

  const [assignPICState, setAssignPICState] = useState<ActionButtonState>(
    ActionButtonState.normal
  );

  useEffect(() => {
    setAssignPICState(ActionButtonState.normal);
  }, [sidePanelVessels]);

  const onAssignment = async () => {
    if (assignPICState === ActionButtonState.normal) {
      trackUserAction(TRACK_ASSIGN_PIC);
      setAssignPICState(ActionButtonState.in_progress);
      try {
        const res = await assignMyselfAsPIC(sidePanelVessels.map((o) => o.id));
        if (res.success) {
          setAssignPICState(ActionButtonState.completed);
          if (userInfo) {
            dispatch(
              updateInCharge({
                ids: sidePanelVessels.map((o) => o.id),
                userInfo,
              })
            );
          } else {
            console.log(
              'no userinfo in config. cannot update in-charge for vessels locally.'
            );
          }
        } else {
          setAssignPICState(ActionButtonState.normal);
          console.error('Failed to assign PIC', res);
        }
      } catch (err) {
        setAssignPICState(ActionButtonState.normal);
        console.error('Error assigning PIC', err);
      }
    }
  };

  return { onAssignment, assignPICState };
};

export default useAssignPIC;
