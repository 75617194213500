import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useAppDispatch from 'hooks/useAppDispatch';
import { SetModal } from 'hooks/useModal';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_UPDATE_EVENT } from 'utils/analytics/constants';
import { fetchVesselScheduleEventsAsync } from 'redux/thunks';
import { RootState } from 'redux/types';
import {
  fetchMatrixEventDetails,
  mergeMatrixEvents,
  updateCurrentMatrixEvent,
} from 'api/crew-matrix';
import { CCMatrixContext } from 'contexts/CCMatrixContext';
import { BASE_MATRIX_ROUTE } from 'components/CrewMatrixPlanning/constants';
import {
  formatSearchQuery,
  getMergeEventsCompliance,
  getSummaryEventsOnSelectedDate,
} from 'components/CrewMatrixPlanning/helpers';
import { CrewChangeEventDetailed } from 'components/CrewMatrixPlanning/types';

type Props = {
  setModal: SetModal;
};

// hook to handle merging of 2 events in CMP
function useMergeCompliance({ setModal }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const scheduleEvents = useSelector(
    ({ crewChangeMatrix }: RootState) => crewChangeMatrix.scheduleEvents
  );

  const { currentEvent, updateCurrentEvent } = useContext(CCMatrixContext);

  const [isMerging, setIsMerging] = useState(false);

  // find if current event has confirmed onsigners by user
  const hasConfirmedOnsigner = useMemo(
    () =>
      currentEvent?.offsigners.some(
        ({ selectedOnsigner }) => selectedOnsigner?.selectedByHuman
      ),
    [currentEvent?.offsigners]
  );

  // get vessel events on same date
  const getEventsOnSameDate = useCallback(
    (eventDate: string) =>
      currentEvent
        ? getSummaryEventsOnSelectedDate(
            currentEvent.vessel.id,
            scheduleEvents
          )(eventDate).filter(({ id }) => id !== currentEvent.id)
        : [],
    [currentEvent, scheduleEvents]
  );

  const getMergeCompliance = useCallback(
    async (eventId: number) => {
      const { result: event } = await fetchMatrixEventDetails(eventId);
      if (currentEvent && event) {
        return getMergeEventsCompliance(currentEvent.offsigners, event);
      }
    },
    [currentEvent]
  );

  // update event date, includes the case of merging events as well
  const handleUpdateEvent = useCallback(
    // `isChecking` is true when initially updating event date
    // it's false when user confirms to update event date anyway (event with merge conflicts)
    async (eventDate?: string, isChecking?: boolean) => {
      if (
        !currentEvent ||
        !eventDate ||
        // if event date is not changed
        currentEvent.eventDate === eventDate
      ) {
        return;
      }

      const eventsOnSameDate = getEventsOnSameDate(eventDate);
      if (isChecking && eventsOnSameDate.length) {
        setModal('mergeEvents', {
          eventDate,
          events: eventsOnSameDate,
        });
        return;
      }

      const { success, eventUpdatedAt, message, result } =
        await updateCurrentMatrixEvent(currentEvent, eventDate);

      if (!success || !result) {
        showToaster({ message, placement: 'left', type: 'error' });
        return;
      }

      const { id: vesselId, imo: vesselImo } = currentEvent.vessel;
      const updatedEvent: CrewChangeEventDetailed = {
        ...currentEvent,
        ...result,
        // override `updatedAt` with value from headers
        updatedAt: eventUpdatedAt || currentEvent.updatedAt,
      };
      // this should update context level state - `currentEvent`
      updateCurrentEvent(updatedEvent);
      // update vessel schedule events in store to reflect changes to any related event(s)
      dispatch(fetchVesselScheduleEventsAsync({ vesselId, vesselImo }));
      // track user action for successful update of event date
      trackUserAction(TRACK_UPDATE_EVENT, 'click', { event: updatedEvent });
      showToaster({
        message,
        placement: 'left',
        testId: 'e2e_cmp-update-event-date-toaster',
      });
      // update event details url params with new event date
      navigate(
        `/${BASE_MATRIX_ROUTE}/${vesselId}?${formatSearchQuery(updatedEvent)}`
      );
      // close modal after successful update
      setModal(null);
    },
    [
      currentEvent,
      getEventsOnSameDate,
      updateCurrentEvent,
      navigate,
      setModal,
      dispatch,
    ]
  );

  // merge with any other vessel event
  const handleMergeEvents = useCallback(
    async (
      eventId: number,
      eventDate: string,
      forceMerge?: boolean // `true` when merging event with conflicts
    ) => {
      if (!currentEvent) return;

      setIsMerging(true);

      // check if there's any merge conflicts, when NOT forcing the merge
      if (!forceMerge) {
        const compliance = await getMergeCompliance(eventId);
        if (compliance?.conflicts?.length) {
          setIsMerging(false);
          // show merge compliance modal if there's nay confict
          setModal('mergeCompliance', {
            compliance,
            eventDate,
            // callback provided to modal to forcibly merge the events
            onMerge: () => handleMergeEvents(eventId, eventDate, true),
          });
          return;
        }
      }

      // merge events
      const { success, message } = await mergeMatrixEvents(
        currentEvent,
        eventId
      );
      setIsMerging(false);

      if (!success) {
        showToaster({ message, placement: 'left', type: 'error' });
        return;
      }
      showToaster({
        message: 'Successfully merged 2 events. Navigatng to updated event.',
        placement: 'left',
      });
      setModal(null);

      // navigate to target event details page
      const eventQuery = formatSearchQuery({
        id: eventId,
        eventDate,
      });
      window.location.replace(
        `/${BASE_MATRIX_ROUTE}/${currentEvent.vessel.id}?${eventQuery}`
      );
    },
    [currentEvent, setModal, getMergeCompliance]
  );

  return {
    isMerging,
    hasConfirmedOnsigner,
    onUpdateEvent: handleUpdateEvent,
    onMergeEvents: handleMergeEvents,
  };
}

export default useMergeCompliance;
