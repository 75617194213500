import partition from 'lodash/partition';
import uniqBy from 'lodash/uniqBy';
import React, { useEffect, useContext, useState, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import {
  CrewEvent,
  CrewChangeEvent,
} from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { textGray } from 'lib/colors';
import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_CREW_CHANGE_FETCH_PREVIOUS } from 'utils/analytics/constants';
import { getVesselCrewChangeEvents } from 'api/flotilla';
import { selectSidePanel } from 'redux/selectors';
import sampleEvents from 'utils/sample-crew-change-events';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import { Button, Loader } from 'components/shared';
import EventDetails from './EventDetails';
import CardContainer from '../common/CardContainer';
import CardHeader from '../common/CardHeader';
import { hasHistoricalEvent } from './helpers';

const EventInfo = styled.span`
  margin-top: 0;
  color: ${textGray};
  text-transform: uppercase;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-bottom: 1rem;
`;

const TimeContainer = styled.div`
  font-size: 0.7rem;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 100%;
`;

const StyledButton = styled(Button)`
  height: 25px;
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

const SecondaryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h5 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const MotionComponent = motion(EventContainer);

const TimeComponent = ({ label, date }: { label: string; date: string }) => {
  const displayTime = moment(date).format(`h:mm a`);
  const displayDate = moment(date).format(`DD MMM YYYY`);
  return (
    <TimeContainer>
      <EventInfo>{label}</EventInfo>
      <p style={{ margin: 0 }}>{`${displayTime} on ${displayDate}`}</p>
    </TimeContainer>
  );
};

function CrewChangeEventsContainer({ vessel }: { vessel: Vessel }) {
  const sidePanel = useSelector(selectSidePanel);

  const { isInWizard } = useContext(SidePanelFieldContext);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<CrewChangeEvent | undefined>();

  const showLoader = loading && !isInWizard;
  const vesselEvents = useMemo(() => {
    if (isInWizard) sampleEvents.slice(0, 1);
    if (!event?.crewChangeEvents?.length) return [];

    const [eventsWithETA, eventsWithoutETA] = partition(
      event.crewChangeEvents,
      ({ eta }) => eta
    );
    // sort crew-change events based on ETA, show events with no ETA last
    return [
      ...eventsWithETA.sort((a, b) => a.eta!.localeCompare(b.eta!)),
      ...eventsWithoutETA,
    ];
  }, [isInWizard, event]);

  useEffect(() => {
    const updatedAt = moment().subtract(1, 'day').toLocaleString();
    const fetchedAt = moment().toLocaleString();
    if (isInWizard) {
      setEvent({
        updatedAt,
        fetchedAt,
        crewChangeEvents: sampleEvents,
      });
      return;
    }
    const existingEvent = {
      crewChangeEvents: vessel.upcomingCrewChangeEvents,
      updatedAt: vessel.updatedAt,
      fetchedAt,
    };
    setEvent(existingEvent);
  }, [vessel, isInWizard]);

  const handleFetchCrewChangeEvents = async (withPastEvents?: boolean) => {
    setLoading(true);
    try {
      const ccEvent = await getVesselCrewChangeEvents(vessel.id, true);
      setEvent(
        (prevEvent) =>
          ({
            ...(prevEvent || {}),
            ...(ccEvent || {}),
            crewChangeEvents: uniqBy(
              [
                ...(prevEvent?.crewChangeEvents || []),
                ...(ccEvent?.crewChangeEvents || []),
              ],
              'id'
            ),
          } as CrewChangeEvent | undefined)
      );

      // track user action for crew change event fetching
      trackUserAction(TRACK_CREW_CHANGE_FETCH_PREVIOUS, 'click', {
        vesselId: vessel.id,
        historical: withPastEvents,
      });

      if (withPastEvents && !hasHistoricalEvent(vessel, ccEvent)) {
        showToaster({
          message: 'No historical event available.',
          type: 'info',
        });
      }
    } catch (error) {
      console.log('Error while fetching crew change events', error);
      showToaster({
        message: withPastEvents
          ? 'Historical events cannot be fetched!'
          : 'Cannot update crew change events!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardContainer>
      <CardHeader>
        <h4>Crew Change Events</h4>
        <StyledButton
          variant="secondary"
          size="small"
          style={{ marginRight: 0 }}
          onClick={() => sidePanel.visible && handleFetchCrewChangeEvents()}
        >
          Refresh
        </StyledButton>
      </CardHeader>

      <TimeWrapper>
        {event?.updatedAt && (
          <TimeComponent label="Updated At" date={event.updatedAt} />
        )}
      </TimeWrapper>

      <SecondaryHeader>
        <h5>{`Showing (${vesselEvents.length}) event${
          vesselEvents.length > 1 ? 's' : ''
        }`}</h5>
        <StyledButton
          variant="secondary"
          size="small"
          style={{ marginRight: 0 }}
          onClick={() => sidePanel.visible && handleFetchCrewChangeEvents(true)}
        >
          Show Previous
        </StyledButton>
      </SecondaryHeader>

      {Boolean(event?.crewChangeEvents?.length) && (
        <div style={{ marginBottom: '10px' }} />
      )}

      <AnimatePresence>
        {showLoader ? (
          <Loader size={120} />
        ) : (
          <MotionComponent>
            {vesselEvents.map((event: CrewEvent, index) => (
              <motion.div
                key={event.id}
                transition={{ delay: 0.1 * index }}
                initial={{ opacity: 0, x: 10 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 1, x: -10 }}
              >
                <EventDetails
                  key={event.id}
                  event={event}
                  vesselId={vessel.id}
                />
              </motion.div>
            ))}
          </MotionComponent>
        )}
      </AnimatePresence>
    </CardContainer>
  );
}

export default memo(CrewChangeEventsContainer);
