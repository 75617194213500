import { memo } from 'react';
import styled from 'styled-components/macro';

import { textGray } from 'lib/colors';
import { LottieAnimation } from 'components/shared';

const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1rem;
`;

const Text = styled.i`
  color: ${textGray};
  margin-right: 0.5rem;
`;

type Props = {
  from: string;
  to: string;
};

function RowFlightLoader({ from, to }: Props) {
  return (
    <AnimationWrapper>
      <Text>{from}</Text>
      <LottieAnimation
        name="64012-loader-plane-voi"
        width="100%"
        style={{ marginRight: '0.25rem' }}
      />
      <Text>{to}</Text>
    </AnimationWrapper>
  );
}

export default memo(RowFlightLoader);
