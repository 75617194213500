import { formatDate } from 'utils/format-date';
import { Crew, Flight, Port } from 'utils/types/crew-change-types';
import { Departures } from 'components/CrewChangePanel/types';
import { UniqueKeyInput } from './types';

// match for current crew home airport
// we can have flights for multiple crew home airport in the flight storage
export const matchCrewHomeAirport = (crew: Crew, flight: Flight) => {
  const { type, homeAirport } = crew;
  const { departure, arrival } = flight;

  return type.toLowerCase() === 'onsigner'
    ? homeAirport?.iataCode === departure?.airportCode
    : homeAirport?.iataCode === arrival?.airportCode;
};

export const filterDepartures = (
  flight: Flight,
  departures?: Departures | null
) => {
  const { crew, departure } = flight;
  // `onsigner` & `offsinger` departures of the selected port
  const { onsigner, offsigner } = departures || {};
  if (departure?.time) {
    // filter for flights on the same day
    return crew.type.toLowerCase() === 'onsigner'
      ? onsigner && formatDate(onsigner) === formatDate(departure.time)
      : offsigner && formatDate(offsigner) === formatDate(departure.time);
  }
};

// A port-card may have flights for more than one port
// Check if flight includes the selected port nearby airport
export const hasPortAirport = (
  flight: Flight,
  locode: string,
  iataCode?: string
) => {
  const { crew, port, departure, arrival } = flight;
  return (
    port.locode === locode &&
    iataCode &&
    iataCode ===
      (crew.type.toLowerCase() === 'offsigner'
        ? departure?.airportCode
        : arrival?.airportCode)
  );
};

export const formatFlights = (
  flights: Flight[] | null,
  crew: Crew,
  port: Port
): Flight[] =>
  (flights || []).map((flight: Flight) => ({
    ...flight,
    id: `${flight.originalId || flight.id}--${crew.id}-${port.locode}`,
    originalId: (flight.originalId || flight.id) as string,
    crew,
    port,
  }));

// calculated from flight numbers & departure time
export const getFlightUniqueKey = ({
  flightNumbers = [],
  departure,
}: UniqueKeyInput) => {
  const flightNumberStr = flightNumbers.join('-');
  return `${flightNumberStr}:${departure.time}`;
};
