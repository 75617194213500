import tzlookup from './geo-tz';

export function convertTZ(date: Date, tzString: string) {
  return new Date(date.toLocaleString('en-US', { timeZone: tzString }));
}

function padToTwoDigits(n: number) {
  if (n >= 10) {
    return n.toString();
  } else {
    return '0' + n.toString();
  }
}

export function tzTime(tzString: string) {
  const now = new Date();
  const nowLocale = convertTZ(now, tzString);
  const nowUTC = convertTZ(now, 'UTC');
  let tzOffset = nowLocale.getHours() - nowUTC.getHours();
  if (tzOffset < -12) {
    tzOffset = 24 + tzOffset;
  } else if (tzOffset > 12) {
    tzOffset = 24 - tzOffset;
  }
  return (
    padToTwoDigits(nowLocale.getHours()) +
    ':' +
    padToTwoDigits(nowLocale.getMinutes()) +
    ' (UTC ' +
    (tzOffset < 0 ? '-' : '+') +
    Math.abs(tzOffset) +
    ')'
  );
}

export function locationTime(point: { lat: number; lon: number }) {
  const tz = tzlookup(point.lat, point.lon);
  return tzTime(tz);
}
