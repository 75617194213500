import type { GeofenceCommon } from '@greywing-maritime/frontend-library/dist/types/geofences';
import { blue, gray50, purple } from 'lib/colors';
import { vesselHighlights } from './color-utils';
import { GeofenceWithColor } from './types';

const GEOFENCE_TYPES = ['EEZ', 'WOA', 'ERZ', 'HRA', 'other'] as const;
type GeofenceTypes = typeof GEOFENCE_TYPES[number];

const RISK_AREA_COLOR_MAPPING: { [key in GeofenceTypes]: string } = {
  EEZ: purple,
  WOA: '#c70000',
  ERZ: '#f5b402',
  HRA: '#b9330B',
  other: gray50,
};

// This divides the layers into 5 group colors
function defineFillLayerColors(
  geofences: GeofenceCommon[]
): GeofenceWithColor[] {
  return geofences.map((geofence, index) => {
    let group: GeofenceTypes = 'other';
    switch (true) {
      case geofence.displayName.includes('EEZ'):
        group = 'EEZ';
        break;
      case geofence.displayName.includes('WOA'):
        group = 'WOA';
        break;
      case geofence.displayName.includes('ERZ'):
        group = 'ERZ';
        break;
      case geofence.displayName.includes('HRA'):
        group = 'HRA';
        break;
      default:
        break;
    }

    const color = RISK_AREA_COLOR_MAPPING[group];

    return { ...geofence, color };
  });
}

function defineGeneralFillLayerColors(
  geofences: GeofenceCommon[]
): GeofenceWithColor[] {
  return geofences.map((geofence, index) => {
    const color =
      vesselHighlights.qualitative[
        index % vesselHighlights.qualitative?.length
      ];

    return { ...geofence, color: color || blue };
  });
}

export { defineFillLayerColors, defineGeneralFillLayerColors };
