import { useContext, useEffect, useState, memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useAppDispatch, useMobile } from 'hooks';
import sleep from 'lib/sleep';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_CREW_CHANGE_START_EMPTY,
  TRACK_PIRACY,
} from 'utils/analytics/constants';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { CREW_CHANGE_CANCEL_MESSAGE } from 'lib/constants';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { selectSidePanelVesselCount } from 'redux/reducers/sidePanel';
import { closeSidePanel, selectCrewChangeEvent } from 'redux/actions';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

// Components
import { Button } from '../shared';
import { Title } from '../shared/Typography';
import ParallaxImage from '../ParallaxImage';

import { white } from 'lib/colors';

const BASE_URL = getAPIUrl();

const Container = styled.div<{
  $isInWizard?: boolean;
  $isTMC?: boolean;
}>`
  position: relative;
  margin: ${({ $isInWizard }) => ($isInWizard ? '-1rem' : '-0.5rem')} -0.5rem ${({
      $isTMC,
    }) => ($isTMC ? '0rem' : '2rem')} -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: absolute;
  width: calc(100% - 1rem);
  align-self: flex-end;
  margin-bottom: -1.25rem;
`;

const ButtonsWrapper = styled.div<{ $isTitleShown: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: ${({ $isTitleShown }) => ($isTitleShown ? '0.25rem' : 0)};
`;

const cachedImages: { [vesselId: number]: boolean } = {};

export default memo(function VesselDetailsActions({
  vessel,
}: {
  vessel: Vessel;
}) {
  const dispatch = useAppDispatch();
  const totalVesselsInSidePanel = useSelector(selectSidePanelVesselCount);
  const { active, event: crewChangeEvent } = useSelector(selectCrewChangePanel);
  const { userInfo } = useSelector(selectSettings);
  const isTMC = userInfo?.type === 'tmc';
  const [imgSrc, setImgSrc] = useState<string>(
    cachedImages[vessel.id] && vessel.image
      ? vessel.image
      : `${getAPIUrl()}/files/placeholder_vessel.webp`
  );

  const isMobile = useMobile(BREAK_POINT_XS);
  const { isInWizard } = useContext(SidePanelFieldContext);

  const ref = useRef<HTMLDivElement | null>(null);
  const [sidePanelRef, setSidePanelRef] = useState<
    HTMLDivElement | null | undefined
  >(null);

  const onButtonClick = (route: string) => {
    window.open(`${BASE_URL}${route}`);
  };

  // start a new/empty crew change
  const handleStartCrewChange = () => {
    dispatch(
      selectCrewChangeEvent({
        vesselId: vessel.id,
        event: {} as CrewEvent, // pass on empty object for empty cerw change
        active: 'plan',
      })
    );

    trackUserAction(TRACK_CREW_CHANGE_START_EMPTY, 'click', {
      vesselId: vessel.id,
    });
  };

  const handleOnClick = async () => {
    if (active === 'plan' && crewChangeEvent) {
      const canDiscard = window.confirm(CREW_CHANGE_CANCEL_MESSAGE);
      if (canDiscard) {
        dispatch(selectCrewChangeEvent());
        await sleep(100);
        handleStartCrewChange();
      }
      return;
    }

    if (isMobile) {
      dispatch(closeSidePanel());
    }

    handleStartCrewChange();
  };

  useEffect(() => {
    if (cachedImages[vessel.id] && vessel.image) {
      setImgSrc(vessel.image);
    } else {
      setImgSrc(`${getAPIUrl()}/files/placeholder_vessel.webp`);
      if (vessel.image) {
        const img = new Image();
        img.src = vessel.image;
        if (!img.complete)
          setImgSrc(`${getAPIUrl()}/files/placeholder_vessel.webp`);
        img.onload = () => {
          cachedImages[vessel.id] = true;
          setImgSrc(vessel.image!);
        };
      }
    }
  }, [vessel.image]); // eslint-disable-line

  useEffect(() => {
    if (ref && ref.current && !isInWizard && totalVesselsInSidePanel === 1) {
      const sidePanel = ref.current.parentElement?.parentElement?.parentElement
        ?.parentElement as HTMLDivElement;
      if (sidePanel) setSidePanelRef(sidePanel);
    }
  }, [isInWizard, totalVesselsInSidePanel]);

  return (
    <Container ref={ref} $isInWizard={isInWizard} $isTMC={isTMC}>
      <ParallaxImage
        parent={sidePanelRef}
        scrollSpeed={2}
        hideAtScrollTop={200}
        src={imgSrc}
      />
      <Wrapper>
        {totalVesselsInSidePanel >= 1 && (
          <Title
            level={3}
            style={{
              marginBottom: isTMC ? '1.25rem' : '0.25rem',
              color: white,
              textTransform: 'uppercase',
            }}
          >
            {vessel.name}
          </Title>
        )}
        {!isTMC && (
          <ButtonsWrapper $isTitleShown={totalVesselsInSidePanel >= 1}>
            <Button
              variant="primary"
              size="small"
              onClick={handleOnClick}
              style={{ marginRight: 0, width: '100%' }}
            >
              {/* This button text is used within e2e */}
              NEW CREW CHANGE
            </Button>
            {false && (
              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  onButtonClick(`/risk/vessel/${vessel.id}`);
                  trackUserAction(TRACK_PIRACY, 'click', {
                    vesselId: vessel.id,
                  });
                }}
                style={{ marginRight: 0 }}
              >
                PIRACY
              </Button>
            )}
          </ButtonsWrapper>
        )}
      </Wrapper>
    </Container>
  );
});
