import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useForm, useWatch } from 'react-hook-form';
import { useCallback, useMemo, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { superstructResolver } from '@hookform/resolvers/superstruct';

import {
  Container,
  FormContainer,
  ImageContainer,
} from 'components/UserAccount/common';
import SignupBackground from 'assets/images/sign-up.png';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { StopTrackingRequest } from 'components/TrackFlight/types';
import { StopTrackingRequestSchema } from 'components/TrackFlight/validation';
import { ButtonV2 } from 'components/shared';
import { TextFieldV2 } from 'components/shared/forms';
import { DATE_FORMAT, formatToLocalTime } from 'utils/format-date';
import { stopFlightTrackingRequest } from 'api/flotilla';
import { showToaster } from 'lib/toaster';
import { red } from 'lib/colors';

const FormWrapper = styled.form`
  align-self: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  overflow-y: auto;
  max-height: calc(100 * var(--vh));

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    max-height: calc(75 * var(--vh));
    align-self: start;
  }
`;

const Wrapper = styled.div`
  align-self: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    max-height: calc(75 * var(--vh));
    align-self: start;
  }
`;

const options: { value: string; greywingSuggestedFlightRebooked: boolean }[] = [
  {
    value: 'This flight is no longer relevant',
    greywingSuggestedFlightRebooked: false,
  },
  {
    value: 'I have booked a cheaper flight suggested by Greywing',
    greywingSuggestedFlightRebooked: true,
  },
  {
    value: 'Other',
    greywingSuggestedFlightRebooked: false,
  },
];

function StopTrackingFlight() {
  const [success, setSuccess] = useState<boolean>(false);
  const { search } = useLocation();
  const { uid } = useParams();

  const flightInfo = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      const { dep, arr, depTime } = params;
      if (!dep || !arr || !depTime) return null;
      return {
        text: `Flight from ${dep} to ${arr} on ${formatToLocalTime(
          depTime,
          DATE_FORMAT.DD_MMM_YY_HH_mm
        )}`,
        depCode: dep,
        arrCode: arr,
        depTime,
      };
    } catch (error) {
      return null;
    }
  }, [search]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm<StopTrackingRequest>({
    defaultValues: {
      greywingSuggestedFlightRebooked:
        options[0].greywingSuggestedFlightRebooked,
      reason: options[0].value,
      selection: options[0].value,
    },
    resolver: superstructResolver(StopTrackingRequestSchema),
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue('selection', value);
    if (value === 'Other') {
      setValue('reason', '');
    } else {
      setValue('reason', value);
    }
  };
  const selectionValue = useWatch({
    control,
    name: 'selection',
  });

  const handleFormSubmit = useCallback(
    async (values: StopTrackingRequest) => {
      if (!uid || !flightInfo) return;
      const { success, message } = await stopFlightTrackingRequest({
        uid,
        greywingSuggestedFlightRebooked: values.greywingSuggestedFlightRebooked,
        reason: values.reason,
        depCode: flightInfo.depCode,
        arrCode: flightInfo.arrCode,
        depTime: flightInfo.depTime,
      });
      if (!success) {
        showToaster({
          message,
          type: 'error',
        });
      } else {
        setSuccess(true);
      }
    },
    [flightInfo, uid]
  );
  return (
    <Container style={{ background: 'white' }}>
      <FormContainer>
        {success ? (
          <Wrapper>
            You have stopped tracking this flight. We will not send you anymore
            email updates.
          </Wrapper>
        ) : (
          <FormWrapper onSubmit={handleSubmit(handleFormSubmit)}>
            <p>Are you sure you want to stop tracking this flight?</p>
            {flightInfo && (
              <p>
                <b>{flightInfo.text}</b>
              </p>
            )}
            {!flightInfo && (
              <p style={{ color: red }}>
                There appears to be something wrong with your request URL,
                please check that you have used the correct link from your
                email.
              </p>
            )}
            <RadioGroup value={selectionValue} onChange={handleChange}>
              {options.map((o) => (
                <FormControlLabel
                  key={o.value}
                  sx={{
                    marginLeft: '0',
                    marginRight: '0',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                  value={o.value}
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#385DEA',
                        },
                      }}
                    />
                  }
                  label={o.value}
                />
              ))}
            </RadioGroup>
            {selectionValue === 'Other' && (
              <TextFieldV2
                // @ts-ignore
                control={control}
                name="reason"
                size="small"
                label="Reason"
                placeholder="Please specify your reason"
              />
            )}
            <ButtonV2
              loading={isSubmitting}
              disabled={!flightInfo}
              type="submit"
            >
              Stop Tracking
            </ButtonV2>
          </FormWrapper>
        )}
      </FormContainer>
      <ImageContainer>
        <img src={SignupBackground} alt="3d vessel" />
      </ImageContainer>
    </Container>
  );
}

export default StopTrackingFlight;
