import { memo } from 'react';
import styled from 'styled-components/macro';

import { red, orange, green } from 'lib/colors';
import {
  BudgetStatus,
  UIBudgetDetails,
} from 'components/CrewChangePanel/types';

const StatusWrapper = styled.div<{ $color?: string }>`
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  ${({ $color }) =>
    $color &&
    `
      background: ${$color}20;
      border: 1px solid ${$color};
      color: ${$color};
  `};
  border-radius: 0.25rem;
  padding: 0.1rem 0.5rem;
`;

function BudgetStatusBadge({
  budgetDetails,
}: {
  budgetDetails: UIBudgetDetails;
}) {
  const { text, status } = budgetDetails;
  const textColor =
    (status === BudgetStatus.ALL_OVER && red) ||
    (status === BudgetStatus.SOME_OVER && orange) ||
    (status === BudgetStatus.ALL_UNDER && green) ||
    undefined;

  return <StatusWrapper $color={textColor}>{text}</StatusWrapper>;
}

export default memo(BudgetStatusBadge);
