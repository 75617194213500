import styled from 'styled-components/macro';

import { gray60 } from 'lib/colors';

const ImageWrapper = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width}px`};
`;

const ImageSVG = ({ color }: { color: string }) => {
  return (
    <svg version="1.1" viewBox="0 0 128 160">
      <path
        d="M21.32813,125.96582a3.04477,3.04477,0,0,1-3.3111-2.00012,6.60442,6.60442,0,0,1-2.17157-.60486l.051.34131a5.03414,5.03414,0,0,0,5.72656,4.24219L48.07416,123.999H34.5144Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M.05566,17.73438,12.001,97.643V84.11273L2.03418,17.43945A3.047,3.047,0,0,1,4.5918,13.97852l7.40918-1.1051V10.85089L4.29688,12A5.04953,5.04953,0,0,0,.05566,17.73438Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M88.125,4.59277,89.2854,12.356l2.32031,1.99164-1.5022-10.0498A5.04747,5.04747,0,0,0,84.377.05566L57.939,3.999H71.49872L84.67188,2.03418A3.04308,3.04308,0,0,1,88.125,4.59277Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M97.06122,95.67743A31.24344,31.24344,0,0,0,104,76.01465,30.14439,30.14439,0,0,0,95.14355,54.5293c-11.96484-11.95312-31.62793-11.75-43.83789.44824A31.35488,31.35488,0,0,0,42,77.28711a30.20059,30.20059,0,0,0,30.3623,30.31934,31.558,31.558,0,0,0,22.332-9.28223q.549-.5484,1.06714-1.11859L126.293,127.707A.99989.99989,0,0,0,127.707,126.293Zm-3.78094,1.23273c-11.43164,11.417-29.82812,11.61914-41.00977.44824A28.1614,28.1614,0,0,1,44,77.28711,29.73348,29.73348,0,0,1,73.6377,47.69434,28.2018,28.2018,0,0,1,102,76.01465,29.37028,29.37028,0,0,1,93.28027,96.91016Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M73.1748,88.40234a13.16476,13.16476,0,0,0-1.876.13477,13.73139,13.73139,0,0,0-.13379,1.92773,13.41431,13.41431,0,0,0,.13379,1.88281,13.47487,13.47487,0,0,0,1.91992.13477,13.16476,13.16476,0,0,0,1.876-.13477,13.58591,13.58591,0,0,0,.13379-1.92773,13.25867,13.25867,0,0,0-.13379-1.88281A13.485,13.485,0,0,0,73.1748,88.40234Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M80.09668,62.44141q-2.6792-2.376-7.9043-2.376a23.40076,23.40076,0,0,0-3.55078.24609,23.906,23.906,0,0,0-3.55078.875q.22412.80713.40234,1.43457a10.388,10.388,0,0,0,.44629,1.25586q1.20557-.4043,2.45605-.71777a14.306,14.306,0,0,1,3.34961-.31348,9.32231,9.32231,0,0,1,5.583,1.54688,5.30177,5.30177,0,0,1,2.14355,4.5957,5.76319,5.76319,0,0,1-.55859,2.60059,6.29561,6.29561,0,0,1-1.47363,1.92773,9.26186,9.26186,0,0,1-2.05469,1.36719,16.46122,16.46122,0,0,1-2.25488.91895l-1.51855.49316v6.9502q.312.04541.71484.06738c.26758.01563.52051.02246.75879.02246s.49121-.00684.75977-.02246q.40137-.022.80371-.06738V78.22461a17.01187,17.01187,0,0,0,2.12109-.85254,12.58963,12.58963,0,0,0,2.70215-1.72559,10.214,10.214,0,0,0,2.32227-2.78027,7.7317,7.7317,0,0,0,.98242-3.96777A8.19494,8.19494,0,0,0,80.09668,62.44141Z"
        fill={color}
        fillRule="nonzero"
      />
      <path
        d="M104.001,117.32227A2.67861,2.67861,0,0,1,101.32715,120H18.67383a2.67841,2.67841,0,0,1-2.67285-2.67773V10.67773A2.67841,2.67841,0,0,1,18.67383,8H77.07324a2.68961,2.68961,0,0,1,2.67188,2.61621l.29883,13.14062a4.6728,4.6728,0,0,0,4.53711,4.56934l16.82324.48633a2.66248,2.66248,0,0,1,2.59668,2.67578V64.73254A32.59964,32.59964,0,0,1,106,76.01465a33.28183,33.28183,0,0,1-1.999,11.39063V99.78363l2,1.998V31.48828a4.64067,4.64067,0,0,0-1.6781-3.57507c-.01324-.0127-.01874-.03-.03284-.04211L80.25586,7.24121a.94509.94509,0,0,0-.14716-.0824A4.629,4.629,0,0,0,77.07324,6H18.67383A4.681,4.681,0,0,0,14.001,10.67773V117.32227A4.681,4.681,0,0,0,18.67383,122h82.65332a4.68122,4.68122,0,0,0,4.67383-4.67773v-7.06042l-2-1.998Zm-4.06641-90.554-15.2959-.44214a2.67166,2.67166,0,0,1-2.59473-2.61426l-.28528-12.545Z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

const NotFoundImage = (props: { width: number; color?: string }) => {
  return (
    <ImageWrapper $width={props.width}>
      <ImageSVG color={props.color || gray60} />
    </ImageWrapper>
  );
};

export default NotFoundImage;
