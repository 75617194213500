import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import sleep from 'lib/sleep';

type Props = {
  value: string;
  options: string[];
  testId?: string;
  capitalize?: boolean;
  disabled?: boolean;
  fontSize?: string;
  formStyles?: { [key: string]: string | number };
  onChange: (e: SelectChangeEvent) => void;
  handler?: (open: boolean) => void;
};

function Dropdown({
  value,
  testId,
  options,
  fontSize,
  capitalize,
  disabled,
  formStyles = {},
  onChange,
  handler, // handler to take custom action in calling component with open/close
}: Props) {
  const [open, setOpen] = useState(false);
  const textStyles = {
    fontSize: fontSize || '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  };

  return (
    <FormControl data-id={testId} variant="standard" sx={formStyles}>
      <Select
        size="small"
        open={open}
        value={value}
        defaultValue={value}
        onChange={onChange}
        disabled={disabled}
        onOpen={() => {
          // add delay to fix keyboard navigation issue
          sleep(100).then(() => setOpen(true));
          handler?.(true);
        }}
        onClose={() => {
          setOpen(false);
          handler?.(false);
        }}
        sx={textStyles}
      >
        {options.map((text, index) => (
          <MenuItem key={`${text}_${index}`} value={text} sx={textStyles}>
            {capitalize ? text.toUpperCase() : text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
