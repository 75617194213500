import type { LineLayer } from 'mapbox-gl';
import turfAlong from '@turf/along';
import { Position } from '@turf/turf';
import turfLength from '@turf/length';
import { Airport } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { runWhenIdle } from './map';

type DirectRoute = {
  start: Position;
  end: Position;
};

export type MapAirport = {
  id: string;
  directRoute?: DirectRoute;
};

export function generateAirportFeature(
  id: string,
  airport: Airport
): GeoJSON.Feature {
  return {
    type: 'Feature',
    id,
    properties: {
      icon: 'flight-image',
      name: airport.text,
      label: airport.text,
    },
    geometry: {
      type: 'Point',
      coordinates: [airport.lon, airport.lat],
    },
  };
}

export function generateAirportRouteFeature(
  id: string,
  directRoute: DirectRoute,
  properties?: any
): GeoJSON.Feature {
  const initialFeature = {
    id,
    type: 'Feature',
    properties: {
      id,
      ...properties,
    },
    geometry: {
      type: 'LineString',
      coordinates: [directRoute.start, directRoute.end],
    },
  };

  const lineDistance = turfLength(initialFeature as GeoJSON.Feature);
  const arc = [];
  const steps = 500;
  const increment = lineDistance / steps;
  for (let i = 0; i < lineDistance; i += increment) {
    const segment = turfAlong(
      initialFeature as GeoJSON.Feature<GeoJSON.LineString>,
      i
    );
    arc.push(segment.geometry.coordinates);
  }

  initialFeature.geometry.coordinates = arc;
  // in cases where the route cuts the antimeridian,
  // removing the first element seems to solve the problem of the horizontal line appearing
  initialFeature.geometry.coordinates.splice(0, 1);
  return initialFeature as GeoJSON.Feature;
}

export function drawAirports(
  sourceId: string,
  layerId: string,
  styles?: Partial<mapboxgl.SymbolLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map!.getSource(sourceId) && !map!.getLayer(layerId)) {
      map!.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'symbol',
          ...(styles || {
            layout: {
              'icon-image': ['get', 'icon'],
              'icon-allow-overlap': true,
              'text-allow-overlap': true,
              'icon-size': 0.5,
            },
          }),
        },
        beforeId
      );
    }
  });
}

export function drawAirportRoutes(
  sourceId: string,
  layerId: string,
  styles?: Partial<LineLayer>,
  beforeId?: string
) {
  runWhenIdle((map) => {
    if (map!.getSource(sourceId) && !map!.getLayer(layerId)) {
      map!.addLayer(
        {
          id: layerId,
          source: sourceId,
          type: 'line',
          ...(styles || {
            paint: {
              'line-width': 2,
              'line-color': '#007cbf',
            },
          }),
        },
        beforeId
      );
    }
  });
}
