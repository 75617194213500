import {
  object,
  string,
  pattern,
  size,
  Struct,
  define,
  is,
  literal,
  array,
  number,
  coerce,
} from 'superstruct';
import { isEmail } from 'utils/validations';

const password = () => pattern(string(), /^(?!.* ).{8,}$/);
const message = <T>(struct: Struct<T, any>, message: string): Struct<T, any> =>
  define('message', (value) => (is(value, struct) ? true : message));
export const passwordResetCodeValidation = size(string(), 36, 36);

export const LoginSchema = object({
  email: message(isEmail(), 'A valid email is required.'),
  password: message(size(string(), 1, 100), 'A password is required.'),
});

export const SignupSchema = object({
  email: message(isEmail(), 'A valid email is required.'),
  password: message(
    password(),
    'Password must contain a minimum of 8 characters and must not contain any empty spaces.'
  ),
  company: message(
    size(string(), 1, 100),
    'Please specify the name of your company'
  ),
  agree: message(literal(true), 'You must agree to continue.'),
});

export const ForgotPasswordSchema = object({
  email: message(isEmail(), 'A valid email is required.'),
});

export const ResetPasswordSchema = object({
  password: message(
    password(),
    'Password must contain a minimum of 8 characters and must not contain any empty spaces.'
  ),
});

export type VerifySignup = {
  code: [
    number | '',
    number | '',
    number | '',
    number | '',
    number | '',
    number | ''
  ];
};

export const VerifySignupSchema = object({
  code: size(
    array(
      size(
        coerce(number(), string(), (o) => (o ? Number(o) : undefined)),
        0,
        9
      )
    ),
    6
  ),
});

export default SignupSchema;
