import { useState, useCallback, useEffect } from 'react';

type ModalType = string | null;
type ModalData = any;

export type SetModal = (type: ModalType, data?: ModalData) => void;
export type Modal = {
  type: ModalType;
  data?: ModalData;
};

const defaultModal = {
  type: null,
  data: null,
};

function useModal(
  initial?: ModalType,
  data?: ModalData
): { modal: Modal; setModal: SetModal } {
  const [modalObj, setModalObj] = useState<Modal | null | undefined>();

  const setModal = useCallback((type: ModalType, data?: ModalData) => {
    const newModalObj = { type, data };
    setModalObj(newModalObj);
  }, []);

  useEffect(() => {
    if (initial) {
      setModal(initial, data);
    }
  }, [data, initial, setModal]);

  return { modal: modalObj || defaultModal, setModal };
}

export default useModal;
