import { useMemo, memo } from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

// Components
import CardContainer from './common/CardContainer';
import CardHeader from './common/CardHeader';

// Utilities and Types
import { lightGray } from 'lib/colors';

interface ICrewNationalityProps {
  vessel: Vessel;
}

const columns: GridColDef[] = [
  {
    field: 'country',
    headerName: 'Country',
    valueGetter: ({ row }) => row.country.text,
    flex: 5,
  },
  {
    field: 'count',
    headerName: 'Count',
    sortable: false,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    minWidth: 100,
  },
];

interface CountryData {
  id: string;
  text: string;
}

interface Nationality {
  count: number;
  country: CountryData;
}

const TableWrapper = styled.div`
  div,
  p {
    font-family: 'HK Grotesk', 'Roboto', sans-serif;
  }

  .MuiDataGrid-row.Mui-selected {
    background-color: ${lightGray} !important;
  }
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
`;

export default memo(function CrewNationality({
  vessel,
}: ICrewNationalityProps) {
  const { crewNationalities } = vessel;

  const nationalityData: Nationality[] = useMemo(() => {
    if (!crewNationalities) return [];
    return Object.keys(crewNationalities)
      .sort((a, b) => {
        return crewNationalities[b].count - crewNationalities[a].count;
      })
      .map((key: string) => crewNationalities[key]);
  }, [crewNationalities]);

  if (!crewNationalities || !Object.keys(crewNationalities).length) return null;
  return (
    <CardContainer>
      <CardHeader>Crew Change Nationalities</CardHeader>
      <TableWrapper>
        <DataGridPro
          getRowId={(e) => e.country.id}
          rows={nationalityData}
          columns={columns}
          disableColumnMenu
          autoHeight
          hideFooter
        />
      </TableWrapper>
    </CardContainer>
  );
});
