import { memo, SyntheticEvent, useState } from 'react';
import { MenuItem, Menu, Fade } from '@mui/material';
import styled from 'styled-components/macro';

import { blue, textBlack, white } from 'lib/colors';
import { Tooltip } from 'components/shared';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  margin-left: 0.5rem;
  font-size: 15px !important;
`;

export const ActionButton = styled.button`
  background-color: #fff;
  color: ${textBlack};
  border: 1px solid #fafafc;
  padding: 0.3em 1em;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 20%);
  display: inline-flex;
  align-items: center;
  border-radius: 0.25rem;
  user-select: none;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
  pointer-events: all;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.2;
  font-family: HK Grotesk, Arial;
`;

const menuItemStyles = {
  fontSize: '0.9rem',
  lineHeight: 1,
  fontWeight: '500',
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 100ms ease',
  '&:hover': {
    background: blue,
    color: white,
  },
};

type ButtonDetails = {
  content: string;
  active: boolean;
  onClick: () => void;
  tooltip?: string;
};

type Props = {
  buttonText: string;
  options: ButtonDetails[];
  hasDelay?: boolean; // add delay to open the options
};

function ButtonGroup({ buttonText, options, hasDelay }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: SyntheticEvent) => {
    const { target } = event;
    if (hasDelay) {
      setTimeout(() => {
        setAnchorEl(target as HTMLElement);
      }, 250);
      return;
    }
    setAnchorEl(target as HTMLElement);
  };

  return (
    <>
      <ActionButton
        id="fade-button"
        aria-haspopup="true"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {buttonText}
        <StyledArrowDownIcon />
      </ActionButton>

      <Menu
        id="fade-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{ marginTop: '1px' }}
      >
        {options
          .filter(({ active }) => active)
          .map(({ content, onClick, tooltip }, index) =>
            tooltip ? (
              <Tooltip
                key={index}
                placement="left"
                enterDelay={500}
                content={tooltip}
              >
                <MenuItem
                  onClick={(e) => {
                    onClick();
                    setAnchorEl(null);
                  }}
                  sx={menuItemStyles}
                >
                  {content}
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem
                key={index}
                onClick={(e) => {
                  onClick();
                  setAnchorEl(null);
                }}
                sx={menuItemStyles}
              >
                {content}
              </MenuItem>
            )
          )}
      </Menu>
    </>
  );
}

export default memo(ButtonGroup);
