import flatten from 'lodash/flatten';
import { AutocompleteRenderGroupParams } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';

import { getRandomId } from 'lib/common';
import { gray40, gray90, textBlack } from 'lib/colors';

const GroupHeader = muiStyled('div')({
  position: 'sticky',
  top: '-8px',
  padding: '6px 10px',
  fontSize: '0.75rem',
  color: textBlack,
  backgroundColor: gray40,
  borderTop: gray90,
  borderBottom: gray90,
  textTransform: 'uppercase',
});

const GroupItems = muiStyled('ul')({
  padding: 0,
});

type Props = AutocompleteRenderGroupParams & {
  showCount?: boolean;
};

function AutocompleteGroup(props: Props) {
  const { group, children, showCount } = props;
  const header = showCount
    ? `${group} (${flatten(Array(children)).length})`
    : group;

  return (
    <li key={getRandomId()}>
      <GroupHeader>{header}</GroupHeader>
      <GroupItems>{children}</GroupItems>
    </li>
  );
}

export default AutocompleteGroup;
