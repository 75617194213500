import { useCallback, useContext } from 'react';
import styled from 'styled-components/macro';

import { showToaster } from 'lib/toaster';
import { blue, blueGray, iconGray, textGray, white } from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { formatReadOnlyDataLink } from 'utils/crew-change-events';

import { Tooltip } from 'components/shared';
import { CCPanelContext } from 'contexts/CCPanelContext';
import BudgetStatusBadge from './BudgetStatusBadge';

import { ReadOnlyPlanningData } from '../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(FlexWrapper)`
  font-size: 0.85rem;
  color: ${textGray};
  white-space: nowrap;
`;

const LinkWrapper = styled(FlexWrapper)`
  margin-left: 0.5rem;
`;

const UserEmail = styled.b`
  margin: 0 0.2rem;
`;

const CopyLinkButton = styled.div`
  border: none;
  border-radius: 4px;
  background: ${blue};
  color: ${white};
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding: 0.15rem 0.5rem;
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    background: ${blueGray};
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 1rem;
  margin: 0 1rem;
  background-color: ${iconGray};
`;

function ReadOnlyMeta({ reportData }: { reportData: ReadOnlyPlanningData }) {
  const {
    createdAt,
    creator,
    crewChangePlan: { vessel },
  } = reportData;

  const {
    tableState: { step, budgetDetails },
  } = useContext(CCPanelContext);

  const handleCopyLink = useCallback(() => {
    if (reportData?.id) {
      const shareableLink = formatReadOnlyDataLink(reportData?.id);
      if (shareableLink && navigator.clipboard) {
        navigator.clipboard.writeText(shareableLink);
        showToaster({
          message: 'Copied the link to clipboard',
          testId: 'e2e_plan-copy-link-toaster',
        });
      } else {
        showToaster({
          message: 'Failed to copy the link',
          type: 'info',
        });
      }
    }
  }, [reportData?.id]);

  return (
    <Wrapper data-id="e2e_read-only-meta">
      Generated at {formatDate(createdAt)} by{' '}
      <UserEmail>{creator?.email || 'Unknown user'}</UserEmail> for{' '}
      {vessel.name} ({vessel.imo})
      <LinkWrapper>
        <Tooltip content="Copy link to clipboard for this read-only data">
          <CopyLinkButton data-id="e2e_meta-copy-link" onClick={handleCopyLink}>
            Copy Link
          </CopyLinkButton>
        </Tooltip>
      </LinkWrapper>
      {step === 'flights' && budgetDetails?.text && (
        <>
          <Separator />
          <BudgetStatusBadge budgetDetails={budgetDetails} />
        </>
      )}
    </Wrapper>
  );
}

export default ReadOnlyMeta;
