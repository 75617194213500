import React, { useContext, useState } from 'react';
import CTeleportLogo from 'assets/images/c-teleport-logo.svg';
import { ButtonV2 } from 'components/shared';
import { useSelector } from 'react-redux';
import { selectCrewChangePanel, selectMapVessels } from 'redux/selectors';
import { getVesselById } from 'utils/vessel';
import { Crew } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import moment from 'moment';
import { getAlpha2CodeFromCountry } from 'lib/countries';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { trackUserAction } from 'lib/amplitude';

const smallButtonStyles: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: '600',
  minHeight: '20px',
};

const largeButtonStyles: React.CSSProperties = {
  minHeight: '100%',
  minWidth: '64px',
  width: 'calc(64px + 0.5rem)',
  marginRight: '0.5rem',
  padding: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  fontSize: '9px',
  fontWeight: '700',
  letterSpacing: '1.38px',
  textTransform: 'uppercase',
};

const smallImageStyles: React.CSSProperties = {
  width: '16px',
  height: '16px',
  marginRight: '8px',
};

const largeImageStyles: React.CSSProperties = {
  width: '22px',
  height: '22px',
  filter: 'brightness(0) invert(1)',
};

type Props = {
  type?: 'small' | 'large';
  portData: {
    eta: Date;
    etd: Date;
    airport: string;
  };
  trackAction: string;
};

type Passenger = {
  arrive?: string;
  depart?: string;
  last_name: string;
  first_name: string;
  dob: string;
  nationality: string;
  gender: string;
  doc_country: string;
  doc_number: string;
  doc_expire: string;
  home_airport: string;
  email?: string;
  id: string;
};

export type cTeleportBookingReqParams = {
  vessel: string;
  flag: string;
  change_airport: string;
  onsigners: Passenger[];
  offsigners: Passenger[];
};

const transformDate = (date?: string | null) =>
  date ? moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';

const transformCrew = (c: Crew) => ({
  last_name: '',
  first_name: c.name || '',
  dob: transformDate(c.birthday),
  nationality: c.country ? getAlpha2CodeFromCountry(c.country) : '',
  gender: c.sex ?? '',
  doc_country: c.country ? getAlpha2CodeFromCountry(c.country) : '',
  doc_number: c.passportNumber ?? '',
  doc_expire: transformDate(c.passportExpiry),
  home_airport: c.homeAirport?.iataCode ?? '',
  id: c.cid ?? '',
});

type FlattenedObject = Record<string, string>;
const flattenObject = (
  obj: Record<string, any>,
  parentKey = ''
): FlattenedObject => {
  return Object.keys(obj).reduce((acc: FlattenedObject, key: string) => {
    const newKey = parentKey ? `${parentKey}[${key}]` : key;

    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return { ...acc, ...flattenObject(obj[key], newKey) };
    } else {
      return { ...acc, [newKey]: obj[key] };
    }
  }, {});
};

const createHiddenField = (key: string, value: string) => {
  const hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', key);
  hiddenField.setAttribute('value', value);
  return hiddenField;
};

const CTeleportButton = ({ type = 'small', portData, trackAction }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { eta, etd, airport } = portData;
  const { vesselId, event } = useSelector(selectCrewChangePanel);
  const {
    planningData: { crew },
  } = useContext(CCPanelContext);
  const { vesselsFull } = useSelector(selectMapVessels);
  const vessel = getVesselById(vesselsFull, vesselId!);

  const handleOnClick = () => {
    setLoading(true);
    if (event && vessel) {
      trackUserAction(trackAction, 'click');
      const onsigners = crew.filter((c) => c.type === 'onsigner');
      const offsigners = crew.filter((c) => c.type === 'offsigner');
      const data: cTeleportBookingReqParams = {
        vessel: vessel.name,
        flag: vessel.flag ? getAlpha2CodeFromCountry(vessel.flag) : '',
        change_airport: airport,
        onsigners: onsigners.map((c: Crew) => ({
          arrive: moment(eta).format('YYYY-MM-DD'),
          ...transformCrew(c),
        })),
        offsigners: offsigners.map((c: Crew) => ({
          depart: moment(etd).format('YYYY-MM-DD'),
          ...transformCrew(c),
        })),
      };
      const fields = flattenObject(data);

      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute(
        'action',
        `${
          process.env.REACT_APP_CTELEPORT_URL ?? 'https://app-dev.cteleport.com'
        }/integrations/search`
      );
      form.setAttribute('target', '_blank');

      Object.entries(fields).forEach(([key, value]) => {
        const hiddenField = createHiddenField(key, String(value));
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
    setLoading(false);
  };

  return (
    <ButtonV2
      variant={type === 'small' ? 'secondary' : 'primary'}
      style={type === 'small' ? smallButtonStyles : largeButtonStyles}
      loading={loading}
      onClick={handleOnClick}
    >
      <img
        src={CTeleportLogo}
        alt="CTeleport Logo"
        style={type === 'small' ? smallImageStyles : largeImageStyles}
      />
      Book with C-Teleport
    </ButtonV2>
  );
};

export default CTeleportButton;
