import styled from 'styled-components/macro';

import { useE2ETests } from 'hooks';
import { blue } from 'lib/colors';
import { CrewChangeCost } from 'components/CrewChangePanel/types';
import { CustomCheckbox, Text } from '../../common';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const ResetAction = styled(Text)`
  margin-left: 0.25rem;
  text-decoration: underline;
  text-transform: uppercase;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    color: ${blue};
    font-weight: bold;
  }
`;

export type UpdateCostParams = (
  type: string,
  newValue: string[] | number | number[]
) => void;

type Props = {
  options: CrewChangeCost[];
  updateCostParams: UpdateCostParams;
};

function CostOptions({ options, updateCostParams }: Props) {
  const { canResetCostOptions } = useE2ETests();

  const handleUpdateOptions = (type: CrewChangeCost) => {
    const newOptions = options.includes(type)
      ? options.filter((option) => option !== type)
      : [...options, type];
    updateCostParams('costOptions', newOptions);
  };

  const handleResetOptions = () => {
    const newOptions = options.filter((option) => option === 'Flight');
    updateCostParams('costOptions', newOptions);
  };

  const renderOption = (type: CrewChangeCost) => {
    const isOptionIncluded = options.includes(type);
    const isChecked = type === 'Flight' || isOptionIncluded;
    const testId = `e2e_${type.toLowerCase()}-cost-option`;
    return (
      <CustomCheckbox
        data-id={testId}
        // isCircle={false}
        label={type}
        disabled={type === 'Flight'}
        checked={isChecked}
        onClick={() => handleUpdateOptions(type)}
      />
    );
  };

  return (
    <>
      <FlexWrapper>
        <Text>Select crew change costs to be included in report.</Text>
        {/* Only available for E2E tests */}
        {canResetCostOptions && (
          <ResetAction
            data-id="e2e_reset-cost-options"
            onClick={handleResetOptions}
          >
            Reset
          </ResetAction>
        )}
      </FlexWrapper>
      <SelectionWrapper>
        {renderOption('Flight')}
        {renderOption('Agency')}
        {renderOption('Wage')}
        {renderOption('Hotel')}
      </SelectionWrapper>
    </>
  );
}

export default CostOptions;
