import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MapPortState, RootState } from '../types';
import { SearchedPort } from 'utils/types';
import uniqBy from 'lodash/uniqBy';

export const initialMapPort: MapPortState = {
  ports: [],
  pinnedPorts: [],
};

const mapPortSlice = createSlice({
  name: 'mapPort',
  initialState: initialMapPort,
  reducers: {
    setMapPorts: (state, action: PayloadAction<SearchedPort[]>) => ({
      ...state,
      ports: action.payload,
    }),
    pinMapPort: (state, action: PayloadAction<SearchedPort>) => ({
      ...state,
      pinnedPorts: uniqBy([...state.pinnedPorts, action.payload], 'id'),
    }),
    unpinMapPort: (state, action: PayloadAction<SearchedPort>) => ({
      ...state,
      pinnedPorts: state.pinnedPorts.filter(
        (port) => port.id !== action.payload.id
      ),
    }),
  },
});

/* ----- selectors -----*/

export const selectMapPorts = ({ mapPort }: RootState) => mapPort;

/* ----- actions -----*/

export const { setMapPorts, pinMapPort, unpinMapPort } = mapPortSlice.actions;

/* ----- reducer -----*/

export default mapPortSlice.reducer;
