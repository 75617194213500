import { dset } from 'lib/dset';
import { StructError, validate, create } from 'superstruct';
import { define, Struct, is, string, pattern } from 'superstruct';

export const validationErrMessage = <T>(
  struct: Struct<T, any>,
  message: string
): Struct<T, any> =>
  define('message', (value) => (is(value, struct) ? true : message));

export const isEmail = () =>
  pattern(string(), /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+$/);

export function validateValues<T>(struct: any) {
  return function (values: T) {
    let errors: any = {};
    const [error] = validate(values, struct);
    if (error instanceof StructError) {
      errors = Object.values(error.failures()).reduce(function (
        errors,
        error: any
      ) {
        /* uses dset to write deep object values (e.g. errors.address.street) */
        dset(errors, error.path, error.message);
        return errors;
      },
      {});
    }
    return errors || {};
  };
}

export function validateTransformedValues<T>(struct: any) {
  return function (values: T) {
    try {
      let errors: any = {};
      const created = create(values, struct);
      const [error] = validate(created, struct);
      if (error instanceof StructError) {
        errors = Object.values(error.failures()).reduce(function (
          errors,
          error: any
        ) {
          /* uses dset to write deep object values (e.g. errors.address.street) */
          dset(errors, error.path, error.message);
          return errors;
        },
        {});
      }

      return errors || {};
    } catch (error) {
      // This catches error from create
      let errors: any = {};
      if (error instanceof StructError) {
        errors = Object.values(error.failures()).reduce(function (
          errors,
          error: any
        ) {
          /* uses dset to write deep object values (e.g. errors.address.street) */
          dset(errors, error.path, error.message);
          return errors;
        },
        {});
      }

      return errors || {};
    }
  };
}
