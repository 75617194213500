import min from 'lodash/min';
import max from 'lodash/max';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { initiateFlightFilters } from 'components/FlotillaSearch/helpers';
import { updateQuickFlyFilters } from 'redux/actions';
import { initQuickFlyFiltersFromSettings } from 'redux/helpers/flotillaSearch';
import { RootState } from 'redux/types';
import { FlightSearchResult } from 'utils/types';
import { SearchContext } from 'contexts/SearchContext';
import {
  MAX_FLIGHT_TIME,
  MAX_LAYOVER_TIME,
  MAX_STOPS_COUNT,
} from 'components/CrewChangePanel/helpers';

import useAppDispatch from './useAppDispatch';

function useInitiateFlightSearchFilters() {
  const dispatch = useAppDispatch();
  const quickFlyTMC = useSelector(
    ({ settings }: RootState) => settings.quickFlyTMC
  );
  const crewChangeFlightParams = useSelector(
    ({ settings }: RootState) => settings.crewChange.flightParams
  );
  const {
    isLoading,
    searchType,
    results: allSearchResults,
  } = useContext(SearchContext);

  const flightSearchParams = useMemo(
    () => ({
      ...crewChangeFlightParams,
      quickFlyTMC,
    }),
    [quickFlyTMC, crewChangeFlightParams]
  );

  const flights = useMemo(() => {
    const { results = [] } = (allSearchResults.find(
      ({ searchType }) => searchType === 'searchFlights'
    ) || {}) as FlightSearchResult;
    return results;
  }, [allSearchResults]);

  useEffect(() => {
    // set flight search filters
    if (searchType.type === 'searchFlights' && !isLoading && flights.length) {
      const { range, customFilters } = initiateFlightFilters(flights);
      const {
        layover,
        stopsCount,
        time: flightTime,
        allowAirportTransfer,
        source,
      } = initQuickFlyFiltersFromSettings(flightSearchParams);

      // determine the min values between dynamic/calculated & default/initial values & set them as initial values
      const layoverTimeRange: [number, number] = [
        max([layover[0], range.min.layoverTime]) as number,
        min([layover[1], range.max?.layoverTime || MAX_LAYOVER_TIME]) as number,
      ];

      const filtersPayload = {
        ...customFilters,
        time: min([
          flightTime,
          range.max?.flightTime || MAX_FLIGHT_TIME,
        ]) as number,
        layover: layoverTimeRange,
        stopsCount: min([
          stopsCount,
          range.max?.stopsCount || MAX_STOPS_COUNT,
        ]) as number,
        range,
        source,
        allowAirportTransfer,
      };
      // intialize flight filters with dynamic values
      dispatch(updateQuickFlyFilters(filtersPayload));
    }
  }, [isLoading, searchType.type, flights, flightSearchParams, dispatch]);
}

export default useInitiateFlightSearchFilters;
