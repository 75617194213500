import { SyntheticEvent } from 'react';
import styled from 'styled-components/macro';

import { Button, Tooltip } from 'components/shared';
import { SvgIconComponent } from '@mui/icons-material';

type VariantType = 'primary' | 'success' | 'delete' | 'secondary';

const StyledButton = styled(Button)<{ variant: VariantType }>`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-right: 0;
  display: flex;
  column-gap: 0.4rem;
  align-items: center;
  // This is used to make sure that the popup from the settings button will align itself properly
  width: ${({ variant }) => (variant === 'delete' ? '100px' : '140px')};
  transition: all 150ms ease;
  &:hover {
    transform: unset;
    box-shadow: unset;
  }
  svg {
    font-size: 18px !important;
  }
`;

type ButtonProps = {
  variant: VariantType;
  iconLeft: SvgIconComponent;
  iconRight?: SvgIconComponent;
  buttonText: string;
  tooltipContent?: string;
  className?: string;
  onClick: (event?: SyntheticEvent) => void;
};

function ActionButton({
  variant,
  iconLeft: IconLeft,
  iconRight: IconRight,
  buttonText,
  className = '',
  tooltipContent,
  onClick,
}: ButtonProps) {
  const actionButton = (
    <StyledButton className={className} variant={variant} onClick={onClick}>
      <IconLeft />
      {buttonText}
      {IconRight && <IconRight />}
    </StyledButton>
  );

  return tooltipContent ? (
    <Tooltip enterDelay={500} content={tooltipContent}>
      {actionButton}
    </Tooltip>
  ) : (
    actionButton
  );
}

export default ActionButton;
