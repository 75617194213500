import mapValues from 'lodash/mapValues';
import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import { gray50, textBlack, textGray } from 'lib/colors';
import { SAMPLE_PORTS } from '../../helpers';

export const TableWrapper = styled.div`
  width: 96%;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 999;

  .MuiDataGrid-cell {
    color: ${textGray};
  }
  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }
  .selected {
    color: ${textBlack};
  }
  .unselected {
    color: ${gray50};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CostWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`;

const Cost = styled(FlexWrapper)`
  font-size: 0.8rem;
  margin-right: 6px;
  text-transform: uppercase;
`;

const BorderRight = styled.div`
  border-right: 2px solid ${textGray};
  height: 0.7rem;
  margin-right: 6px;
`;

const tableStyles = {
  fontFamily: 'HK Grotesk, Roboto',
  fontSize: '0.8rem',
  '& .table-header': {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.7rem',
    letterSpacing: '0.05rem',
    color: `${textGray}`,
  },
};

const gridColumns: GridColDef[] = [
  { field: 'name', maxWidth: 100 },
  { field: 'locode', maxWidth: 90 },
  {
    field: 'costs',
    minWidth: 180,
    renderCell: (params: GridRenderCellParams) => {
      const { costs } = params.row;
      const agencyCosts = mapValues(
        costs,
        ({ indicative, currency }) => `${indicative || 0} ${currency}`
      );
      const agencyList = Object.keys(agencyCosts);

      return (
        <FlexWrapper>
          {agencyList.map((vendor, index) => (
            <CostWrapper key={vendor}>
              <Cost>
                <b>{vendor}</b>: {agencyCosts[vendor]}
              </Cost>
              {agencyList.length - 1 !== index && <BorderRight />}
            </CostWrapper>
          ))}
        </FlexWrapper>
      );
    },
  },
  { field: 'airport', maxWidth: 160 },
  { field: 'priority', maxWidth: 110 },
];

function CrewChangeTable({ isCompact }: { isCompact: boolean }) {
  const columns = gridColumns.map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: item.maxWidth || 250,
  }));

  return (
    <TableWrapper>
      <DataGridPro
        rows={SAMPLE_PORTS}
        columns={columns}
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        hideFooterPagination
        autoHeight
        density={isCompact ? 'compact' : 'standard'}
        sx={tableStyles}
      />
    </TableWrapper>
  );
}

export default CrewChangeTable;
