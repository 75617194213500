import { memo, useContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import type { PortInfo } from '@greywing-maritime/frontend-library/dist/types/portCalls';

import { useAppDispatch, useModal } from 'hooks';
import { darkBlue, gray70 } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { selectPortNotes } from 'redux/selectors';
import { fetchPortNotesAsync } from 'redux/thunks';
import {
  TRACK_VIEW_CCPANEL_PORT_NOTES,
  TRACK_VIEW_SIDEPANEL_PORT_NOTES,
} from 'utils/analytics/constants';
import { Port } from 'utils/types/crew-change-types';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';
import { ReadOnlyPort } from 'components/CrewChangePanel/types';

import { Button, Card } from 'components/shared';
import CreateModal from './CreateModal';
import ResultsModal from './ResultsModal';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCard = styled(Card)<{ $width?: number }>`
  width: ${({ $width }) => ($width ? `${$width}px` : 'fit-content')};
  margin-right: 0.75rem;
  padding: 0.5rem 0.75rem;
  padding-bottom: 0.6rem;
  flex-shrink: 0;
`;

const CardBody = styled(FlexWrapper)`
  margin-top: 0.5rem;
`;

const Title = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
`;

const ActionButton = styled(Button)<{
  $addNew?: boolean;
  $panelView?: boolean;
  $background?: string;
}>`
  width: fit-content;
  min-height: unset;
  min-width: unset;
  font-size: 0.725rem;
  font-weight: 500;
  letter-spacing: 0.033rem;
  background-color: ${({ $background }) => `${$background}`};
  ${({ $addNew }) =>
    $addNew &&
    `
    color: ${gray70};
  `};
  ${({ $panelView }) =>
    $panelView
      ? `
    height: 22px;
  `
      : `
    padding: 0.1rem 0.33rem;
  `};
`;

type LoadingState = {
  initial?: boolean;
  loadMore?: boolean;
};

type Props = {
  port: Port | ReadOnlyPort | PortInfo;
  view: 'ccpanel' | 'sidepanel';
};

const initialLoadingState = {
  initial: false,
  loadMore: false,
};

function ViewNotesButton({ port, view }: Props) {
  const dispatch = useAppDispatch();
  const storedPortNotes = useSelector(selectPortNotes);
  const { isInWizard } = useContext(SidePanelFieldContext);

  const { locode } = port;
  const notesResponse = (locode && storedPortNotes?.[locode]) || null;
  const { notes: portNotes = [], totalCount = 0 } = notesResponse || {};

  const { modal, setModal } = useModal(null);
  const [loadingState, setLoadingState] =
    useState<LoadingState>(initialLoadingState);

  const handleFetchPortNotes = useCallback(
    (type: 'initial' | 'loadMore') => {
      if (isInWizard) {
        return;
      }

      setLoadingState({ [type]: true });
      dispatch(fetchPortNotesAsync({ locode })).then(() => {
        setLoadingState({ [type]: false });
      });
    },
    [isInWizard, locode, dispatch]
  );

  const handleViewNotes = useCallback(() => {
    setModal('notesResults');
    trackUserAction(
      view === 'ccpanel'
        ? TRACK_VIEW_CCPANEL_PORT_NOTES
        : TRACK_VIEW_SIDEPANEL_PORT_NOTES,
      'click',
      { locode }
    );
  }, [locode, view, setModal]);

  useEffect(() => {
    // fetch port notes if port doesn't have notes fetched already
    if (!notesResponse) {
      handleFetchPortNotes('initial');
    }

    // prevent setting state of unmounted component
    return () => {
      setLoadingState(initialLoadingState);
    };
  }, [notesResponse, handleFetchPortNotes]);

  const renderModal = () => {
    if (!modal?.type || !locode) {
      return null;
    }

    const closeModal = () => setModal(null);

    if (modal.type === 'portNotes') {
      return (
        <CreateModal view={view} portLocode={locode} closeModal={closeModal} />
      );
    }

    if (modal.type === 'notesResults' && notesResponse) {
      return (
        <ResultsModal
          port={port}
          notesResponse={notesResponse}
          loadingState={loadingState}
          setModal={setModal}
          closeModal={closeModal}
          loadMore={() => handleFetchPortNotes('loadMore')}
        />
      );
    }
  };

  // render add button if port has no notes
  if (!portNotes.length || !totalCount) {
    // hide the add notes button in port-panel (ccpanel)
    if (view === 'ccpanel') return null;

    return (
      <>
        <ActionButton
          $addNew
          variant="secondary"
          onClick={() => setModal('portNotes')}
        >
          Add notes
        </ActionButton>

        {renderModal()}
      </>
    );
  }

  const commonProps = {
    className: 'e2e_port-notes-button',
    onClick: handleViewNotes,
    $background: darkBlue,
  };

  return (
    <>
      {view === 'ccpanel' ? (
        <StyledCard>
          <Title>Port Notes:</Title>
          <CardBody>
            <ActionButton $panelView {...commonProps}>
              See notes
            </ActionButton>
          </CardBody>
        </StyledCard>
      ) : (
        <ActionButton {...commonProps}>See notes</ActionButton>
      )}

      {renderModal()}
    </>
  );
}

export default memo(ViewNotesButton);
