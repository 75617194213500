import styled from 'styled-components/macro';

import { gray50, textBlack, textGray } from 'lib/colors';

export const AlertsTableWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;

  .MuiDataGrid-columnHeaderTitleContainerContent {
    width: 100%;
  }
  .selected {
    color: ${textBlack};
  }
  .unselected {
    color: ${gray50};
  }
`;

export const alertsTableStyles = {
  fontFamily: 'HK Grotesk, Roboto',
  fontSize: '0.8rem',
  '& .table-header': {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.7rem',
    letterSpacing: '0.05rem',
    color: `${textGray}`,
  },
};
