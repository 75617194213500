import isBoolean from 'lodash/isBoolean';
import { memo, SyntheticEvent, useCallback, useState } from 'react';
import { Fade, IconButton, Menu, MenuItem } from '@mui/material';
import {
  RestrictionSourceType,
  RestrictionType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { RestrictionsData } from '@greywing-maritime/frontend-library/dist/types/restrictionsDataQuery';
import styled from 'styled-components/macro';

import {
  blue,
  borderGray,
  gray80,
  green,
  lightPurple,
  white,
} from 'lib/colors';
import { Button, Tooltip } from '../../shared';
import { UpdateFeedback, UserFeedback } from './type';
import { showToaster } from 'lib/toaster';
import DoneIcon from '@mui/icons-material/Done';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled(FlexWrapper)`
  justify-content: space-between;
  width: 100%;
  background: #fff;
  position: sticky;
  bottom: -1px;
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
  box-shadow: 0px -4px 3px rgba(0, 0, 0, 0.08);
`;

const IconWrapper = styled(FlexWrapper)`
  margin-left: 0.75rem;
`;

const StyledIconButon = styled(IconButton)`
  height: 2.5rem !important;
  width: 2.5rem !important;
`;

const FeedbackIcon = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${blue};
  svg {
    font-size: 20px !important;
  }
`;

const CheckIcon = styled(DoneIcon)`
  margin-right: 0.25rem;
  font-size: 15px !important;
  color: ${green};
`;

const ShareIcon = styled(FileCopyIcon)`
  margin-right: 0.5rem;
  color: ${blue};
  font-size: 15px !important;
`;

const OptionTitle = styled.div`
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  font-weight: 600;
  letter-spacing: 0.075rem;
  text-transform: uppercase;
`;

const ShareButton = styled(Button)`
  background: ${lightPurple};
  padding: 0.5rem 0.75rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: ${blue};
  background: ${lightPurple};
  transition: 'all 150ms ease';
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${borderGray};
`;

const getMenuItemStyles = (selected: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.9rem',
  lineHeight: 1.25,
  fontWeight: '500',
  color: gray80,
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 150ms ease',
  ...(selected ? { fontStyle: 'italic' } : {}),
  '&:hover': {
    background: blue,
    color: white,
  },
});

const options = [
  "Not what I'm looking for",
  'Information is incorrect',
  'Information is outdated',
  'Hard to read',
  'Something else',
];

type Props = {
  disableFeedback: boolean;
  feedback: UserFeedback | null;
  updateFeedback: UpdateFeedback;
  restrictionsData: {
    type: RestrictionType;
    name: string;
    details: RestrictionsData | null;
    locode: string;
    source: RestrictionSourceType;
  };
};

function RestrictionsFooter({
  feedback,
  updateFeedback,
  restrictionsData,
  disableFeedback,
}: Props) {
  // const dispatch = useAppDispatch();
  // const userInfo = useSelector((state: RootState) => state.settings.userInfo);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { like: isLiked, reason } = feedback || {};

  const open = Boolean(anchorEl);

  const handleClickDislike = (event: SyntheticEvent) => {
    setAnchorEl(event.target as HTMLElement);
  };

  const handleSelectOption = (event: SyntheticEvent) => {
    const { textContent } = event.target as HTMLElement;
    updateFeedback({ like: false, reason: textContent });
    setAnchorEl(null);
  };

  const handleCreateLink = useCallback(() => {
    const { type, name, details } = restrictionsData;
    if (!details) {
      return;
    }

    const { selector, source, lastUpdated } = details;
    const latestUpdatedAt =
      typeof lastUpdated === 'string' ? lastUpdated : lastUpdated.toISOString();
    // remove trailing slash
    const baseUrl = window.location.href.replace(/\/$/, '');
    const queryParams = new URLSearchParams({
      type,
      name,
      latestUpdatedAt,
      portOrCountryCode: selector,
      ...(source ? { source } : {}),
    });
    const shareableLink = `${baseUrl}/ports/restrictions?${queryParams}`;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareableLink);
      showToaster({
        message: `Successfully copied link for ${type} restrictions ${`by ${source.toUpperCase()}`}`,
        testId: 'e2e_restrictions-copied-toaster',
      });
    }
  }, [restrictionsData]);

  const hasFeedback = isBoolean(isLiked);

  const getTooltipContent = () => {
    const { name, type } = restrictionsData;
    return (
      <span>
        Copy link for {type} restrictions of <b>{name}</b>
      </span>
    );
  };

  const tooltipContent = 'Click to select an option';
  return (
    <Wrapper>
      <FlexWrapper style={{ columnGap: '1rem' }}>
        {/* TODO: temporarily disable */}
        {/* {!!restrictionsData.details && userInfo?.access['SeaGPT'] && (
          <ButtonV2 variant="dark" onClick={handleAsk}>
            Ask me
            <Person sx={{ marginLeft: '0.5rem' }}/>
          </ButtonV2>
        )} */}
        {!disableFeedback && (
          <>
            <span data-id="e2e_restrictions-feedback">
              Is this result helpful?
            </span>
            <IconWrapper>
              <StyledIconButon onClick={() => updateFeedback({ like: true })}>
                <Tooltip content={tooltipContent}>
                  <FeedbackIcon>
                    {isLiked ? <ThumbUpRoundedIcon /> : <ThumbUpOutlinedIcon />}
                  </FeedbackIcon>
                </Tooltip>
              </StyledIconButon>

              <StyledIconButon onClick={handleClickDislike}>
                <Tooltip content={tooltipContent}>
                  <FeedbackIcon>
                    {hasFeedback && !isLiked ? (
                      <ThumbDownRoundedIcon />
                    ) : (
                      <ThumbDownOutlinedIcon />
                    )}
                  </FeedbackIcon>
                </Tooltip>
              </StyledIconButon>
            </IconWrapper>
            <Menu
              id="dislike-menu"
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              TransitionComponent={Fade}
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <OptionTitle>Let us know why</OptionTitle>
              <Divider />
              {options.map((option) => {
                const alreadySelected = Boolean(reason && reason === option);
                return (
                  <MenuItem
                    key={option}
                    disabled={alreadySelected}
                    onClick={handleSelectOption}
                    sx={getMenuItemStyles(alreadySelected)}
                  >
                    {alreadySelected && <CheckIcon />}
                    {option}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </FlexWrapper>

      <FlexWrapper>
        <Tooltip content={getTooltipContent()}>
          <ShareButton variant="primary" onClick={handleCreateLink}>
            <ShareIcon />
            Copy Link to Share
          </ShareButton>
        </Tooltip>
      </FlexWrapper>
    </Wrapper>
  );
}

export default memo(RestrictionsFooter);
