import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
  memo,
} from 'react';
import {
  Autocomplete,
  AutocompleteChangeReason,
  TextField,
} from '@mui/material';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { useSelector } from 'react-redux';

import CardContainer from './common/CardContainer';
import CardHeader from './common/CardHeader';
import {
  addVesselTag,
  removeVesselTag,
  filterOptions,
  getNewHashColour,
  transformRawTags,
} from './VesselTags/helpers';
import { renderOption, renderTags } from './VesselTags/common';

import { useAppDispatch } from 'hooks';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import { selectMapVessels } from 'redux/selectors';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_ADD_VESSEL_TAG_SINGLE,
  TRACK_REMOVE_VESSEL_TAG_SINGLE,
} from 'utils/analytics/constants';

import { VesselTagCreateUpdate } from './VesselTags/types';

type VesselTagProps = {
  vessel: Vessel;
};

function VesselTags({ vessel }: VesselTagProps) {
  const dispatch = useAppDispatch();
  const { globalTags } = useSelector(selectMapVessels);
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<VesselTagCreateUpdate[]>(
    // @ts-ignore
    transformRawTags(vessel.vesselTags || [])
  );
  const [searchTagResult, setSearchTagResult] = useState<
    VesselTagCreateUpdate[]
  >([]);
  const { searchGlobal } = useContext(SidePanelFieldContext);

  // Update with relevant tag when sidepanel switches vessel
  useEffect(() => {
    // @ts-ignore
    setTags(transformRawTags(vessel.vesselTags));
  }, [vessel]);

  // Update initial search results when global tags change
  useEffect(() => {
    setSearchTagResult(transformRawTags(globalTags));
  }, [globalTags]);

  const handleInputChange = (
    e: SyntheticEvent<Element, Event>,
    value: string
  ) => {
    const searched = searchGlobal(value || '');
    setSearchTagResult(transformRawTags(searched));
  };

  const handleChange = useCallback(
    async (
      e: React.SyntheticEvent,
      v: (VesselTagCreateUpdate | string)[],
      reason: AutocompleteChangeReason
    ) => {
      setLoading(true);
      if (reason === 'createOption') {
        if (typeof v[v.length - 1] === 'string') {
          const newDisplayName = String(v[v.length - 1]).trim();
          if (
            !newDisplayName ||
            tags.filter((o) => o.displayName === newDisplayName).length
          ) {
            setLoading(false);
            return Promise.resolve();
          }

          const newColour = getNewHashColour(newDisplayName);
          const addedTag = await addVesselTag({
            dispatch,
            requestBody: {
              flotVesIds: [vessel.id],
              displayName: newDisplayName,
              colour: newColour,
            },
          });
          if (addedTag) {
            trackUserAction(TRACK_ADD_VESSEL_TAG_SINGLE, 'click', {
              tag: newDisplayName,
              vessel: vessel.name,
            });
            showToaster({
              message: `Sucessfully tagged vessel.`,
              type: 'success',
            });
            setTags([
              ...tags,
              {
                label: newDisplayName,
                id: addedTag.id,
                value: addedTag.id,
                isNew: false,
                displayName: newDisplayName,
                colour: newColour,
                count: 1,
              },
            ]);
          }
        }
      }
      if (reason === 'selectOption') {
        if (typeof v[v.length - 1] !== 'string') {
          const selectedTag = v[v.length - 1] as VesselTagCreateUpdate;

          const addedTag = await addVesselTag({
            dispatch,
            requestBody: {
              flotVesIds: [vessel.id],
              displayName: selectedTag.displayName,
              colour: selectedTag.colour,
            },
          });

          if (addedTag) {
            trackUserAction(TRACK_ADD_VESSEL_TAG_SINGLE, 'click', {
              tag: selectedTag.displayName,
              vessel: vessel.name,
            });
            showToaster({
              message: 'Sucessfully tagged vessel.',
              type: 'success',
            });
            setTags([
              ...tags,
              {
                ...selectedTag,
                label: selectedTag.displayName,
                id: addedTag.id,
                value: addedTag.id,
                count: 1,
              },
            ]);
          }
        }
      }
      if (reason === 'removeOption') {
        // Identify missing tag
        const ids = (v as VesselTagCreateUpdate[]).map((o) => o.id);
        const missing = tags.filter((o) => !ids.includes(o.id));

        // Remove
        const removedTag = missing[0];
        if (!removedTag) return;
        const isRemoved = await removeVesselTag({
          dispatch,
          requestBody: {
            flotVesIds: [vessel.id],
            tag: {
              id: removedTag.id,
              colour: removedTag.colour,
              displayName: removedTag.displayName,
            },
          },
        });
        if (isRemoved) {
          trackUserAction(TRACK_REMOVE_VESSEL_TAG_SINGLE, 'click', {
            tag: removedTag.displayName,
            vessel: vessel.name,
          });
          setTags(v as VesselTagCreateUpdate[]);
        }
      }
      setLoading(false);
      return Promise.resolve();
    },
    [tags, vessel] // eslint-disable-line
  );

  return (
    <CardContainer>
      <CardHeader>
        <h4>Vessel Tags</h4>
      </CardHeader>
      <div>
        <Autocomplete
          multiple
          noOptionsText="search or add new tags"
          renderTags={renderTags}
          value={tags}
          getOptionLabel={(option) => (option as VesselTagCreateUpdate).label!}
          freeSolo
          options={searchTagResult}
          renderOption={renderOption}
          onInputChange={handleInputChange}
          disableClearable
          openOnFocus
          onChange={handleChange}
          loading={loading}
          disabled={loading}
          isOptionEqualToValue={(option, selected) =>
            option.value === selected.value
          }
          // Override default
          filterOptions={filterOptions}
          filterSelectedOptions
          size="small"
          renderInput={(params) => (
            <TextField
              label="Add or remove tags"
              autoComplete="off"
              {...params}
            />
          )}
        />
      </div>
    </CardContainer>
  );
}

export default memo(VesselTags);
