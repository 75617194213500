import flatten from 'lodash/flatten';
import values from 'lodash/values';
import { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import { useMobile } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import {
  selectCrewChangePanel,
  selectPreferredPorts,
  selectSettings,
} from 'redux/selectors';
import { RootState } from 'redux/types';

import PanelDetail, { PANEL_HEIGHT } from './PortPanel';
import { getColumnVisibility, getGridColumns } from './Header';
import PortPopups from '../../Map/Popups';
import {
  headerStyles,
  ReadOnlyLabel,
  ReadOnlyRow,
  ReadOnlyText,
  TableWrapper,
} from '../../common';
import { NoRowsOverlay } from '../../common/TableItems';
import ReadOnlyControls from '../../common/Controls/ReadOnly';
import { getReadOnlyPortRows } from '../../helpers';
import { ReadOnlyPort, SetFocusedPort } from '../../types';

const StyledTableWrapper = styled(TableWrapper)`
  .Mui-selected div button.MuiDataGrid-detailPanelToggleCell {
    color: #fff;
  }
`;

const ActionWrapper = styled.div`
  margin-left: 1rem;
`;

type Props = {
  focused: ReadOnlyPort | null;
  setFocused: SetFocusedPort<ReadOnlyPort>;
};

function ReadOnlyPortsTable({ focused, setFocused }: Props): JSX.Element {
  const { readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const { compact: isCompact } = useSelector(
    ({ settings }: RootState) => settings.crewChange
  );
  const preferredPorts = useSelector(selectPreferredPorts);
  const { userInfo } = useSelector(selectSettings);
  const isCTeleportEnabled = userInfo?.access['C-Teleport Booking'] ?? false;

  const isMobile = useMobile(BREAK_POINT_XS);
  const {
    route = [],
    ports = [],
    flights = [],
    portFilters: filters,
  } = reportData?.crewChangePlan || {};
  const portRows = useMemo(
    () => getReadOnlyPortRows(ports, preferredPorts),
    [ports, preferredPorts]
  );
  const columns = useMemo(() => getGridColumns(route), [route]);
  const nextUnavilable = useMemo(
    () => !flatten(values(flights)).length,
    [flights]
  );
  const rowIds = portRows.map(({ id }) => id);

  const getDetailPanelHeight = useCallback(() => PANEL_HEIGHT, []);
  const getDetailPanelContent = useCallback(({ row }: GridRowParams) => {
    return (
      <PanelDetail<ReadOnlyPort> isReportView port={row} onFind={setFocused} />
    );
  }, []); // eslint-disable-line

  return (
    <>
      <ReadOnlyControls nextUnavilable={nextUnavilable}>
        <ActionWrapper>
          <ReadOnlyRow>
            <ReadOnlyLabel>Port Range:</ReadOnlyLabel>
            <ReadOnlyText>{filters?.range || '-'} NM</ReadOnlyText>
          </ReadOnlyRow>
          <ReadOnlyRow>
            <ReadOnlyLabel>Agency:</ReadOnlyLabel>
            <ReadOnlyText>
              {(filters?.agency || 'All').toUpperCase()}
            </ReadOnlyText>
          </ReadOnlyRow>
          <ReadOnlyRow>
            <ReadOnlyLabel>ETA Limit:</ReadOnlyLabel>
            <ReadOnlyText>
              {filters?.etaLimit ? `${filters?.etaLimit} Week(s)` : '---'}
            </ReadOnlyText>
          </ReadOnlyRow>
          <ReadOnlyRow>
            <ReadOnlyLabel>Priority:</ReadOnlyLabel>
            <ReadOnlyText>
              {(filters?.priorities || []).join(', ').toUpperCase()}
            </ReadOnlyText>
          </ReadOnlyRow>
        </ActionWrapper>
      </ReadOnlyControls>

      <StyledTableWrapper>
        <DataGridPro
          rows={portRows}
          columns={columns}
          columnVisibilityModel={getColumnVisibility(
            isMobile,
            isCTeleportEnabled
          )}
          checkboxSelection={false}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          hideFooter
          sx={headerStyles}
          density={isCompact ? 'compact' : 'standard'}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          detailPanelExpandedRowIds={rowIds}
          components={{ NoRowsOverlay }}
          componentsProps={{
            noRowsOverlay: { type: 'ports' },
          }}
        />
      </StyledTableWrapper>

      {/* render popups with focus */}
      <PortPopups focusedPort={focused} />
    </>
  );
}

export default memo(ReadOnlyPortsTable);
