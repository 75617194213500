import { memo } from 'react';
import styled from 'styled-components/macro';

import { Tooltip } from 'components/shared';
import { TextContent, TextWrapper, TooltipWrapper } from './common';
import { CommonProps } from './types';

const ContentWrapper = styled(TextContent)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Cost = styled.b`
  margin-left: 1rem;
`;

function TotalTravelCost({ details, filters }: CommonProps) {
  if (!details) {
    return null;
  }

  const { hotelCostDelay } = filters; // in hours
  const { difference, total, currency } = details;
  const showHotelCost = hotelCostDelay <= difference * 24;
  const totalTravelCost =
    total.wage + (showHotelCost ? total.hotel : 0) + total.flight;

  if (!totalTravelCost) {
    return <TextWrapper>-</TextWrapper>;
  }

  const getTooltipContent = () => (
    <TooltipWrapper>
      {total.wage > 0 && (
        <ContentWrapper>
          Wage cost{' '}
          <Cost>
            {total.wage} {currency}
          </Cost>
        </ContentWrapper>
      )}
      {showHotelCost && total.hotel > 0 && (
        <ContentWrapper>
          Hotel cost{' '}
          <Cost>
            {total.hotel} {currency}
          </Cost>
        </ContentWrapper>
      )}
      <ContentWrapper>
        Flight cost{' '}
        <Cost>
          {total.flight} {currency}
        </Cost>
      </ContentWrapper>
    </TooltipWrapper>
  );

  return (
    <Tooltip content={getTooltipContent()}>
      <TextWrapper>
        {String(totalTravelCost)} {currency}
      </TextWrapper>
    </Tooltip>
  );
}

export default memo(TotalTravelCost);
