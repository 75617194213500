import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { completedRemindersAsync, upcomingRemindersAsync } from 'redux/thunks';
import { selectReminders, selectDesktopNotifications } from 'redux/selectors';
import useAppDispatch from './useAppDispatch';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

const createWorker = createWorkerFactory(
  () => import('../workers/reminder-notifications.worker')
);

function useReminder() {
  const worker = useWorker(createWorker);
  const dispatch = useAppDispatch();
  const { upcomingReminders } = useSelector(selectReminders);
  const desktopNotifications = useSelector(selectDesktopNotifications);
  const notifications = useRef<NodeJS.Timeout[]>([]);

  useEffect(() => {
    if (!window.Notification) return;
    const notificationArray = notifications.current;
    if (
      Notification.permission === 'granted' &&
      upcomingReminders &&
      desktopNotifications === true &&
      localStorage.getItem('allow-desktop-notifications') !== '0'
    ) {
      worker.setReminders(
        upcomingReminders.reminders || [],
        notificationArray,
        dispatch
      );
    }
    return () => {
      // clear timeouts
      notificationArray.forEach((notificationTimeout) =>
        clearTimeout(notificationTimeout)
      );
      notifications.current = [];
    };
  }, [upcomingReminders, desktopNotifications, dispatch]); //eslint-disable-line

  useEffect(() => {
    dispatch(completedRemindersAsync({}));
    dispatch(upcomingRemindersAsync({}));
  }, []); // eslint-disable-line

  return null;
}

export default useReminder;
