const GreywingLogo = (props: any) => {
  return (
    <svg viewBox="0 0 121 121" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group">
          <rect id="Rectangle" x="0" y="0" width="121" height="121"></rect>
          <path
            d="M117.886326,10 L119,10 L119,10.8543165 C116.315746,10.968226 113.774312,11.5947185 111.375618,12.7338129 C108.976923,13.8729074 106.949488,15.2255617 105.293245,16.7918165 C103.637003,18.3580714 102.095011,20.2517875 100.667216,22.4730216 C98.3827458,26.0042143 96.4409749,30.0479388 94.8418451,34.6043165 L78.3937397,82.1897482 C77.2515034,85.7778955 76.6232856,88.9388354 76.509061,91.6726619 L75.738056,91.6726619 L58.261944,42.8911871 L44.4695222,85.948741 C42.413499,92.3846244 39.1010652,97.3965649 34.5321252,100.984712 C31.2196431,103.661584 27.5074342,105 23.3953871,105 C21.9675931,105 20.454154,104.829138 18.8550247,104.48741 L19.0263591,103.633094 C19.8830356,103.803958 20.8681987,103.889388 21.9818781,103.889388 C23.0955574,103.889388 24.5804409,103.547665 26.4365733,102.864209 C28.2927055,102.180752 30.1345322,100.956244 31.9621087,99.1906475 C35.6743733,95.6025001 38.5299194,91.1031446 40.5288303,85.692446 L23.2240527,36.9109712 C22.5387115,35.2023296 21.4821598,33.9066291 20.0543657,33.0238309 C18.6265717,32.1410328 17.1702436,31.6996403 15.6853377,31.6996403 L15,31.6996403 L15,30.8453237 L34.0181219,30.8453237 L34.0181219,31.6996403 L33.247117,31.6996403 C32.0477699,31.6996403 31.1482732,32.0840789 30.5485997,32.8529676 C29.9489261,33.6218563 29.6490939,34.3480181 29.6490939,35.0314748 C29.6490939,35.7149316 29.7347604,36.341424 29.9060956,36.9109712 L42.9275124,78.5161871 L58.8616145,29.4784173 L59.6326194,29.5638489 L76.5947282,76.8929856 L89.2734761,34.6043165 C90.7583812,29.6492558 93.0428185,25.1783772 96.1268534,21.1915468 C101.83803,13.7304783 109.091115,10 117.886326,10 Z"
            id="greyWing"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default GreywingLogo;
