import { memo, useState } from 'react';
import styled from 'styled-components/macro';

import { lightPurple, red, textGray } from 'lib/colors';
import { titleize } from 'lib/string';
import { BREAK_POINT_SM } from 'lib/breakpoints';

import { Modal } from 'components/shared';
import PortInputs from './Inputs';
import { StyledButton } from '../../../common';
import { getInvalidMessage } from '../../../helpers';
import { PortDateType, RouteModalType, RoutePort } from '../../../types';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_ADD_PORT_OF_CALL } from 'utils/analytics/constants';

const Wrapper = styled.div`
  .MuiInputLabel-root,
  .MuiInputBase-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.85rem;
    line-height: 1.2rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputBase-input {
    padding: '0.5rem 0.9rem';
    padding-right: 0;
  }

  .MuiSvgIcon-root {
    height: 1.3rem;
    width: 1.3rem;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const InputWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 80px 3fr;
  grid-gap: 10px;
  margin-bottom: 0.75rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: 1.33fr 3fr;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
`;

const Description = styled.div`
  font-size: 0.9rem;
  color: ${textGray};
  margin-bottom: 1.25rem;
`;

const Asterisk = styled.span`
  color: ${red};
  margin-left: 2px;
`;

type Props = {
  type: RouteModalType;
  port: RoutePort;
  previousPort: RoutePort;
  closeModal: () => void;
  onClick: (newPort: RoutePort) => void; // add/update a port
};

function RoutePortModal({
  type,
  port,
  previousPort,
  closeModal,
  onClick,
}: Props) {
  const title =
    type === 'add' ? 'Add New Port' : `Update ${port.displayName || 'Port'}`;

  const [portDetails, setPortDetails] = useState<RoutePort>(port);

  // update `portDetails` with selection of port or date
  const handleSelect = (newDetails: RoutePort) => {
    setPortDetails(newDetails);
  };

  const handleSubmit = () => {
    trackUserAction(TRACK_ADD_PORT_OF_CALL);
    onClick(portDetails);
    closeModal();
  };

  const commonProps = {
    port: portDetails,
    previousPort,
    onSelect: handleSelect,
  };

  const actions = (
    <ButtonsWrapper>
      <ContinueButton variant="secondary" onClick={closeModal}>
        Cancel
      </ContinueButton>
      <StyledButton
        className="e2e_submit-new-route-port"
        variant="primary"
        disabled={Boolean(getInvalidMessage(portDetails))}
        onClick={handleSubmit}
      >
        {titleize(type)}
      </StyledButton>
    </ButtonsWrapper>
  );

  return (
    <Modal width={500} title={title} actions={actions} closeModal={closeModal}>
      <Wrapper>
        <Description>
          Port <b>Name</b> and one of <b>ETA</b> or <b>ETD</b> are required.
          Dates cannot be from past.
        </Description>
        <InputWrapper>
          <Title>
            <span>
              Name<Asterisk>*</Asterisk>
            </span>
            <span>:</span>
          </Title>
          <PortInputs.Name port={portDetails} onSelect={handleSelect} />
        </InputWrapper>

        {['eta', 'etd'].map((type, index) => (
          <InputWrapper key={`${type}_${index}`}>
            <Title>
              {type.toUpperCase()}
              <span>:</span>
            </Title>
            <PortInputs.Date type={type as PortDateType} {...commonProps} />
          </InputWrapper>
        ))}
      </Wrapper>
    </Modal>
  );
}

export default memo(RoutePortModal);
