import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import styled from 'styled-components/macro';

import { textBlack } from 'lib/colors';
import Button from './Button';
import CloseButton from './CloseButton';
import { BREAK_POINT_SM, BREAK_POINT_XS } from 'lib/breakpoints';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  padding: 0 20px;
`;

const Title = styled.h2`
  color: ${textBlack};
  margin-bottom: 20px;
  font-size: 1.25rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    font-size: 1.1rem;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  height: 25px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin: 0;
`;

const getModalStyle = (width?: number | string, height?: number | string) => ({
  sx: {
    border: 'none',
    borderRadius: '8px',
    minWidth: `${
      width ? (typeof width === 'string' && width) || `${width}px` : '500px'
    }`,
    maxHeight: `${
      height ? (typeof height === 'string' && height) || `${height}px` : '75vh'
    }`,
    [`@media screen and (max-width: ${
      width && Number(width) > 500 ? width?.toString() + 'px' : BREAK_POINT_XS
    })`]: {
      minWidth: 'calc(100vw - 2rem)',
      maxWidth: 'calc(100vw - 2rem)',
      maxHeight: 'calc(100vh - 2rem)',
      margin: '0 1rem',
    },
  },
});

type Props = {
  width?: number | string;
  height?: number | string;
  center?: boolean; // centers the content inside modal
  title?: string | React.ReactNode;
  styles?: { [key: string]: string | number };
  headerComponent?: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
  disableExternalClose?: boolean;
  closeModal?: () => void;
};

function Modal({
  width,
  height,
  center,
  title,
  children,
  headerComponent,
  actions,
  styles,
  disableExternalClose = false,
  closeModal,
}: Props): JSX.Element {
  const contentStyles = {
    ...(center
      ? {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {}),
    ...styles,
  };

  const renderHeader = () => {
    if (!title && !closeModal) {
      return null;
    }

    return (
      <HeaderWrapper>
        {Boolean(title) && <Title data-id="e2e_modal-title">{title}</Title>}
        <FlexWrapper>
          {headerComponent || null}
          {title && closeModal && (
            <CloseButton size={24} onClick={closeModal} />
          )}
        </FlexWrapper>
      </HeaderWrapper>
    );
  };

  const renderFooter = () => {
    if (actions === false) return null;
    if (actions) {
      return <DialogActions sx={{ py: 2, px: 3 }}>{actions}</DialogActions>;
    }

    return (
      title &&
      closeModal && (
        <DialogActions sx={{ py: 2, px: 3 }}>
          <Footer>
            <StyledButton
              className="e2e_modal-close"
              variant="primary"
              onClick={closeModal}
            >
              Close
            </StyledButton>
          </Footer>
        </DialogActions>
      )
    );
  };

  return (
    <Dialog
      open
      scroll="paper"
      onClose={(_, reason) => {
        if (disableExternalClose && reason) return;
        closeModal?.();
      }}
      PaperProps={getModalStyle(width, height)}
    >
      {renderHeader()}

      <DialogContent dividers sx={{ py: 3, ...(contentStyles || {}) }}>
        {children}
      </DialogContent>

      {renderFooter()}
    </Dialog>
  );
}

export default Modal;
