import { useState } from 'react';

import ButtonV2 from '../../shared/ButtonV2';
import { ErrorText } from '../../shared/Typography';
import { authorizeMsalSSO } from 'api/flotilla';

function MsalButton() {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const handleMsalSSO = () => {
    setLoading(true);
    setError(undefined);
    return authorizeMsalSSO()
      .then(({ success, data }) => {
        if (success && data) {
          sessionStorage.setItem('msal-verifier', data.verifier);
          window.location.href = data.redirectUrl;
        } else {
          setLoading(false);
          setError('Microsoft SSO has not been enabled');
        }
      })
      .catch(() => {
        setLoading(false);
        setError('Microsoft SSO has not been enabled');
      });
  };

  return (
    // https://learn.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps#pictogram-and-sign-in-with-microsoft
    <>
      <ButtonV2
        variant="outline-black"
        onClick={handleMsalSSO}
        style={{
          display: 'flex',
          columnGap: '12px',
          marginTop: '0.5rem',
        }}
        loading={loading}
      >
        <img src="/ms-logo.svg" alt="Microsoft Logo" width={28} height={28} />
        Sign in with Microsoft
      </ButtonV2>
      {error && <ErrorText>{error}</ErrorText>}
    </>
  );
}

export default MsalButton;
