import { DebouncedFunc } from 'lodash';
import mapboxgl from 'mapbox-gl';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import * as Sentry from '@sentry/react';

import generateFeatureCollection from './generate-feature-collection';
import getRiskColor from './get-risk-color';
import { computeBBox } from './geo';
import { getVesselSvg, VesselFieldHighlight } from './highlight-vessels';
import {
  compoundPopupFields,
  getFieldHighlight,
  getLabellableFields,
  labelFields,
  stringifyFieldValue,
  vesselFields,
} from './vesselFields';

import { Route, Waypoint, VesselFieldId, HoverAirport } from './types';

import moment from 'moment';

import turfLength from '@turf/length';
import turfAlong from '@turf/along';

import { GenerateFlotillaMapConfig } from './flotilla-config';
import { getAPIUrl } from '@greywing-maritime/frontend-library/dist/utils/platform';

const layerConstants = {
  type: 'line',
  paint: {
    'line-color': '#385dea',
    'line-width': 8,
    'line-pattern': 'line-texture',
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
};

//*********************************************************************** Types */

interface VesselGeoJSONColorScheme {
  geoJSONs: GeoJSON.FeatureCollection<GeoJSON.Point>;
  colors: Set<string>;
}

//*********************************************************************** Mutable State */

const vesselsOnMap: Map<number, Vessel> = new Map();

let currentlyHighlightedField: {
  field: string;
  highlight: VesselFieldHighlight<any>;
} | null = null;

let eraseRouteDebounce: DebouncedFunc<(vesselId?: number) => void> | undefined;
let closeVesselPopupFunc: () => void;
let closeWaypointPopupsFunc: () => void;

//*********************************************************************** Generic Utility Functions */

function formatDate(d?: Date) {
  if (!d) return 'unknown';

  return moment(d).format('DD MMM YYYY');
}

function loadImage(
  map: mapboxgl.Map,
  image: string,
  name: string,
  callback?: () => void
) {
  map.loadImage(image, (err, img) => {
    // this if-condition is inside loadImage()'s callback to prevent race condition
    if (map.hasImage(name)) {
      console.log('image already added', name);
      return;
    }

    if (err || !img) {
      console.log(err);
      return;
    }
    map.addImage(name, img);

    callback?.();
  });
}

//*********************************************************************** GIS Functions */

function generateVesselGeoJSON(
  vessels: Map<number, Vessel>,
  fieldToHighlight: VesselFieldId | null,
  labelFieldId: string | null,
  highlightVessels: number[]
): VesselGeoJSONColorScheme | null {
  if (!vessels) return null;

  let vesselHighlight: VesselFieldHighlight<any> | null = null;
  try {
    vesselHighlight = fieldToHighlight
      ? getFieldHighlight(fieldToHighlight, Array.from(vessels.values()))
      : null;
    currentlyHighlightedField =
      (fieldToHighlight &&
        vesselHighlight && {
          field: fieldToHighlight,
          highlight: vesselHighlight,
        }) ||
      null;
  } catch (err) {
    Sentry.captureException(err);
  }

  if (fieldToHighlight && !vesselHighlight) {
    Sentry.captureException(
      new Error(
        `No VesselFieldHighlight found for '${fieldToHighlight}'. Falling back to default color.'`
      )
    );
  }

  let vesselColors: string[] = [];
  if (vesselHighlight) {
    try {
      // If this is not present, compiler complains about vesselHighlight possibly being null when calling getHighlightColor().
      const highlight = vesselHighlight;
      vesselColors = Array.from(vessels.values()).map((vessel) => {
        let color = highlight.getHighlightColor(vessel);
        if (color) {
          return color.replace('#', '');
        } else {
          throw new Error(
            `color is empty after attempting to highlight by field ${fieldToHighlight} for vessel ${vessel.id}.`
          );
        }
      });
    } catch (err) {
      Sentry.captureException(err);
      console.log(
        `Error highlighting by field ${fieldToHighlight}. Falling back to default color.`
      );
    }
  }

  const colors: Set<string> = new Set<string>(vesselColors);

  const geoJSONs: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: 'FeatureCollection',
    features: Array.from(vessels.values()).map((vessel, index) => {
      let icon: string;
      const greyed: boolean = !(
        !highlightVessels.length || highlightVessels.includes(vessel.id)
      );
      const highlighted: boolean = !!(
        highlightVessels.length && highlightVessels.includes(vessel.id)
      );

      icon = vesselColors.length
        ? `vessel-${vesselColors[index]}`
        : 'vessel-large';

      const vesselLabel =
        labelFieldId && getLabellableFields().includes(labelFieldId)
          ? stringifyFieldValue(vessel, labelFields[labelFieldId])
          : null;
      return {
        type: 'Feature',
        id: vessel.id,
        properties: {
          icon: icon,
          greyed,
          highlighted,
          name: vessel.name,
          label: vesselLabel || null,
          course: vessel.course,
          riskStatusColor: getRiskColor(vessel.riskStatusColor),
          vesselId: vessel.id,
          selected: vessel.selected === true ? 1 : 0,
        },
        geometry: {
          type: 'Point',
          coordinates: [vessel.lng, vessel.lat],
        },
      };
    }),
  };

  return {
    geoJSONs,
    colors,
  };
}

export function generateWaypointsGeoJSON(
  waypoints?: Waypoint[]
): GeoJSON.FeatureCollection<GeoJSON.Point> | null {
  if (!waypoints) return null;

  const now = new Date();

  return {
    type: 'FeatureCollection',
    features: waypoints.map((waypoint) => ({
      type: 'Feature',
      id: waypoint.id,
      properties: {
        icon:
          waypoint.eta && new Date(waypoint.eta).getTime() > now.getTime()
            ? 'arrival-wp-marker'
            : 'depart-wp-marker',
        eta: waypoint.eta,
        etd: waypoint.etd,
        hoursInPort: waypoint.hoursInPort,
        id: waypoint.id,
        text: waypoint.text,
        lat: waypoint.lat,
        lon: waypoint.lon,
      },
      geometry: {
        type: 'Point',
        coordinates: [waypoint.lon, waypoint.lat],
      },
    })),
  };
}

//*********************************************************************** Markup Functions */

export function renderPopupMarkup(vessel: Vessel, popupFields: string[]) {
  let fields = popupFields
    .filter(
      (fieldId) =>
        (fieldId in vesselFields && vesselFields[fieldId].popupEnabled) ||
        fieldId in compoundPopupFields
    )
    .filter(
      (fieldId) =>
        fieldId in compoundPopupFields ||
        !vesselFields[fieldId].emptyValues.includes(vessel[fieldId])
    )
    .sort(
      (a, b) =>
        (a in vesselFields
          ? vesselFields[a].displayPriority
          : compoundPopupFields[a].displayPriority) -
        (b in vesselFields
          ? vesselFields[b].displayPriority
          : compoundPopupFields[b].displayPriority)
    )
    .slice(0, GenerateFlotillaMapConfig.maximumPopupFields);

  if (
    currentlyHighlightedField &&
    fields.indexOf(currentlyHighlightedField.field) === -1 &&
    vesselFields[currentlyHighlightedField.field].popupEnabled &&
    !vesselFields[currentlyHighlightedField.field].emptyValues.includes(
      vessel[currentlyHighlightedField.field]
    )
  ) {
    fields.push(currentlyHighlightedField.field);
  }

  const fieldMarkups = fields.map((fieldId) => {
    let value = fieldId in vesselFields ? vessel[fieldId] : vessel;
    let field =
      fieldId in vesselFields
        ? vesselFields[fieldId]
        : compoundPopupFields[fieldId];
    let valueStr = stringifyFieldValue(value, field);

    let markupColor =
      currentlyHighlightedField && fieldId === currentlyHighlightedField.field
        ? currentlyHighlightedField.highlight.getHighlightColor(vessel)
        : null;

    return valueStr
      ? `<li class=popup-item-${fieldId} ${
          markupColor ? `style="color:${markupColor}"` : ''
        }>
                              <span class="field-name">${
                                field.shortDesc
                              }</span>: ${valueStr}
                            </li>`
      : '';
  });

  return `<div class="vessel-map-popup">
    <div class="vessel-popup-title">${vessel.name}</div>
    <ul>
      ${fieldMarkups.join('')}
    </ul>
  </div>
  <button class="cta-button" onclick="window.open('${getAPIUrl()}/intel/vessel/${
    vessel.id
  }')">Plan Crew Change</button>`;
}

export function renderWaypointPopup(waypoint: Waypoint, index: number): string {
  return `<div class="waypoint-map-popup">
    <div class="waypoint-popup-title">Waypoint ${index}</div>
    <ul>
      <li class="location-item">${waypoint.text}</li>
      ${
        waypoint.eta
          ? `
        <li class="eta-etd">ETA</li>
        <li class="time-item">${formatDate(waypoint.eta)}</li>
      `
          : ''
      }
      ${
        waypoint.etd
          ? `
        <li class="eta-etd">ETD</li>
        <li class="time-item">${formatDate(waypoint.etd)}</li>
      `
          : ''
      }
    </ul>
  </div>`;
}

//*********************************************************************** Drawing Functions */

export function drawVesselPopup(
  map: mapboxgl.Map,
  vessel: Vessel | undefined,
  popupFields: string[] | undefined
) {
  if (!vessel || !popupFields) {
    map.fire('closeVesselPopup');
    map.off('closeVesselPopup', closeVesselPopupFunc);
  } else {
    const className = 'vessel-mapbox-popup mapboxgl-popup-focus';
    const vesselPopup = new mapboxgl.Popup({
      offset: [0, -20],
      closeButton: false,
      closeOnClick: false,
      className: className,
    });
    vesselPopup
      .setLngLat({
        lat: vessel.lat,
        lng: vessel.lng,
      })
      .setHTML(renderPopupMarkup(vessel, popupFields))
      .addTo(map);

    addPopupFocusEventListener(className, vessel.id);

    closeVesselPopupFunc = () => vesselPopup.remove();

    map.on('closeVesselPopup', closeVesselPopupFunc);
  }
}

export function drawVesselRoute(
  map: mapboxgl.Map,
  route: Route | undefined | null,
  vesselId: number | undefined
) {
  const routeGeoJSON:
    | GeoJSON.FeatureCollection<
        GeoJSON.MultiLineString,
        GeoJSON.GeoJsonProperties
      >
    | undefined = route?.path?.pathGeoJSON
    ? generateFeatureCollection({
        geometry: route.path.pathGeoJSON,
      })
    : undefined;

  if (!routeGeoJSON) {
    if (map.getLayer('vessel-route')) map.removeLayer('vessel-route');
    if (map.getSource('vessel-route')) map.removeSource('vessel-route');

    if (map.getLayer('waypoints')) map.removeLayer('waypoints');
    if (map.getSource('waypoints')) map.removeSource('waypoints');

    map.fire('closeWaypointPopups');
    map.off('closeWaypointPopups', closeWaypointPopupsFunc);
  } else {
    try {
      if (map.getSource('vessel-route')) {
        (map.getSource('vessel-route') as mapboxgl.GeoJSONSource).setData(
          routeGeoJSON
        );
      } else {
        map.addSource('vessel-route', {
          type: 'geojson',
          data: routeGeoJSON,
        } as mapboxgl.GeoJSONSourceRaw);
      }

      if (!map.getLayer('vessel-route')) {
        map.addLayer(
          {
            id: 'vessel-route',
            source: 'vessel-route',
            ...layerConstants,
          } as mapboxgl.LineLayer,
          'vessels'
        );
      }

      const waypointsGeoJSONData = generateWaypointsGeoJSON(route?.waypoints);

      if (!waypointsGeoJSONData) return;

      if (!map.getSource('waypoints')) {
        map.addSource('waypoints', {
          type: 'geojson',
          data: waypointsGeoJSONData,
        });

        map.addLayer(waypointLayer({}, {}, 'waypoints'));
      } else {
        (map.getSource('waypoints') as mapboxgl.GeoJSONSource).setData(
          waypointsGeoJSONData
        );
      }

      const popups = route?.waypoints
        .filter((waypoint) => !waypoint.currentPosition)
        .slice(1)
        .map((waypoint, index) => {
          const popup = new mapboxgl.Popup({
            anchor: index % 2 === 0 ? 'right' : 'left',
            closeButton: false,
            closeOnClick: false,
            className: 'waypoint-mapbox-popup',
          });

          popup
            .setLngLat({
              lat: waypoint.lat,
              lng: waypoint.lon,
            })
            .setHTML(renderWaypointPopup(waypoint, index + 1))
            .addTo(map);

          return popup;
        });

      addPopupFocusEventListener('waypoint-mapbox-popup', vesselId);

      closeWaypointPopupsFunc = () => {
        popups?.forEach((p) => p.remove());
      };

      map.on('closeWaypointPopups', closeWaypointPopupsFunc);
    } catch (err) {
      Sentry.captureException(err);
      console.error('Error drawing route - ', err);
      drawVesselRoute(map, undefined, undefined); // Erase any drawn route
    }
  }
}

function canDrawAirportRoute(
  vesselCoords: { lat?: number; lng?: number },
  airportCoords: { lat?: number; lon?: number }
) {
  const validVesselCoords = Boolean(vesselCoords?.lat && vesselCoords?.lng);
  const validAirportCoords = Boolean(airportCoords?.lat && airportCoords?.lon);

  return validVesselCoords && validAirportCoords;
}

export function drawAirport(
  map: mapboxgl.Map,
  hoverAirport: HoverAirport | undefined | null
) {
  const airportsLayerAndSourceId = 'airports';
  const routeLayerAndSourceId = 'airport-routes';

  if (!hoverAirport) {
    if (map.getLayer(airportsLayerAndSourceId))
      map.removeLayer(airportsLayerAndSourceId);

    if (map.getLayer(routeLayerAndSourceId)) {
      map.removeLayer(routeLayerAndSourceId);
    }
  } else {
    const { airport, vesselId, vesselLatLng } = hoverAirport;

    if (canDrawAirportRoute(vesselLatLng, airport)) {
      drawVesselAirportRoute(
        map,
        routeLayerAndSourceId,
        vesselLatLng,
        airport,
        vesselId,
        airport?.id
      );
    }

    const geoJSON = airportGeoJSON(hoverAirport);
    if (!map.getSource(airportsLayerAndSourceId)) {
      map.addSource(airportsLayerAndSourceId, {
        type: 'geojson',
        data: geoJSON,
      });
    } else {
      (
        map.getSource(airportsLayerAndSourceId) as mapboxgl.GeoJSONSource
      ).setData(geoJSON);
    }

    if (!map.getLayer(airportsLayerAndSourceId)) {
      map.addLayer({
        id: airportsLayerAndSourceId,
        type: 'symbol',
        source: airportsLayerAndSourceId,
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-size': 0.5,
        },
      });
    }
  }
}

function drawVesselAirportRoute(
  map: mapboxgl.Map,
  routeLayerAndSourceId: string,
  vesselCoords: { lat: number; lng: number },
  airportCoords: { lat: number; lon: number },
  vesselId: number,
  airportId: string
) {
  const route: number[][] = [
    [vesselCoords.lng, vesselCoords.lat],
    [airportCoords.lon, airportCoords.lat],
  ];

  const routeGeoJSON = {
    type: 'Feature',
    properties: {
      id: `vessel-${vesselId}-airport-${airportId}`,
    },
    geometry: {
      type: 'LineString',
      coordinates: route,
    },
  };

  const lineDistance = turfLength(routeGeoJSON as GeoJSON.Feature);
  const arc = [];
  const steps = 500;
  const increment = lineDistance / steps;
  for (let i = 0; i < lineDistance; i += increment) {
    const segment = turfAlong(
      routeGeoJSON as GeoJSON.Feature<GeoJSON.LineString>,
      i
    );
    arc.push(segment.geometry.coordinates);
  }

  routeGeoJSON.geometry.coordinates = arc;
  // in cases where the route cuts the antimeridian,
  // removing the first element seems to solve the problem of the horizontal line appearing
  routeGeoJSON.geometry.coordinates.splice(0, 1);

  if (!map.getSource(routeLayerAndSourceId)) {
    map.addSource(routeLayerAndSourceId, {
      type: 'geojson',
      data: routeGeoJSON,
    } as mapboxgl.GeoJSONSourceRaw);
  } else {
    (map.getSource(routeLayerAndSourceId) as mapboxgl.GeoJSONSource).setData(
      routeGeoJSON as GeoJSON.Feature<GeoJSON.Geometry>
    );
  }

  if (!map.getLayer(routeLayerAndSourceId)) {
    map.addLayer({
      id: routeLayerAndSourceId,
      type: 'line',
      source: routeLayerAndSourceId,
      paint: {
        'line-width': 2,
        'line-color': '#007cbf',
      },
    });
  }
}

//*********************************************************************** Map Functions */
export function getMapViewport() {
  let resLeft = 0;
  let resRight = document.body.clientWidth;
  let resTop = 0;
  let resBottom = document.body.clientHeight;

  const midVertical = resRight / 2;
  const midHorizontal = resBottom / 2;

  document.querySelectorAll<HTMLElement>('*').forEach(function (element) {
    const zIndex = parseInt(element.style.zIndex);
    if (zIndex < 10) return;

    const rect = element.getBoundingClientRect();
    if (rect.width < 40 || rect.height < 40) return;

    if (rect.right < midVertical && rect.right > resLeft) resLeft = rect.right;
    if (rect.left > midVertical && rect.right < resRight) resRight = rect.left;
    if (rect.top < midHorizontal && rect.top > resBottom) resBottom = rect.top;
    if (rect.bottom > midHorizontal && rect.bottom < resTop)
      resTop = rect.bottom;
  });

  return {
    left: resLeft,
    right: resRight,
    top: resTop,
    bottom: resBottom,
  };
}

function zoomToVessels(
  map: mapboxgl.Map,
  vessels: Vessel[],
  sidebarOn: boolean = false
) {
  if (!map || !vessels || vessels.length < 2) return;

  const targetBBox = computeBBox(vessels);

  if (sidebarOn) {
    const viewport = getMapViewport();
    const baseCoord = map.unproject(new mapboxgl.Point(0, 0));
    const sidebarCoord = map.unproject(
      new mapboxgl.Point(0, GenerateFlotillaMapConfig.sidebarWidth)
    );
    const endCoord = map.unproject(
      new mapboxgl.Point(viewport.bottom, viewport.right)
    );
    const totalWidth = Math.abs(endCoord.lat - baseCoord.lat);
    const sidebarWidth = Math.abs(sidebarCoord.lat - baseCoord.lat);
    const targetWidth = Math.abs(targetBBox.maxLng - targetBBox.minLng);
    const targetFrac = targetWidth / totalWidth;

    if (targetFrac < GenerateFlotillaMapConfig.zoomLimitFrac)
      map.fitBounds(
        new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(targetBBox.minLng - 1, targetBBox.minLat - 1),
          new mapboxgl.LngLat(
            targetBBox.maxLng + sidebarWidth * targetFrac,
            targetBBox.maxLat + 1
          )
        )
      );
  } else {
    map.fitBounds(
      new mapboxgl.LngLatBounds(
        new mapboxgl.LngLat(targetBBox.minLng + 1, targetBBox.minLat + 1),
        new mapboxgl.LngLat(targetBBox.maxLng + 1, targetBBox.maxLat + 1)
      )
    );
  }
}

function airportGeoJSON(
  hoverAirport: HoverAirport
): GeoJSON.FeatureCollection<GeoJSON.Point> {
  const { airport } = hoverAirport;
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: airport.id,
        properties: {
          icon: 'flight-image',
          name: airport.text,
          label: airport.text,
        },
        geometry: {
          type: 'Point',
          coordinates: [airport.lon, airport.lat],
        },
      },
    ],
  };
}

export function addPopupFocusEventListener(
  popupClassName: string,
  vesselId: number | undefined
) {
  const focusClass = 'mapboxgl-popup-focus';

  const popupElements = document.getElementsByClassName(popupClassName);
  Array.from(popupElements).forEach((element: Element, i: number) => {
    const target = popupElements[i];
    target.addEventListener('mouseleave', () => {
      if (vesselId) {
        eraseRouteDebounce?.call(vesselId);
      }
    });
    target.addEventListener('mouseenter', () => {
      eraseRouteDebounce?.cancel();
      const elements = document.getElementsByClassName(focusClass);
      let i = 0;
      while (i < elements.length) {
        const current = elements[i];
        current.className = current.className.replace(focusClass, '').trim();
        i++;
      }

      target.className += ' ' + focusClass;
    });
  });
}

function vesselLayer(
  paintProps: Partial<mapboxgl.SymbolPaint>,
  layoutProps: Partial<mapboxgl.SymbolLayout>,
  id: string,
  iconImage: string | mapboxgl.StyleFunction | mapboxgl.Expression
): mapboxgl.AnyLayer {
  return {
    id: id,
    type: 'symbol',
    source: 'vessels',
    paint: {
      ...paintProps,
    },
    layout: {
      'icon-image': iconImage,
      'icon-rotate': ['get', 'course'],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      ...layoutProps,
    },
  };
}

function waypointLayer(
  paintProps: Partial<mapboxgl.SymbolPaint>,
  layoutProps: Partial<mapboxgl.SymbolLayout>,
  id: string
): mapboxgl.AnyLayer {
  return {
    id: id,
    type: 'symbol',
    source: 'waypoints',
    paint: {
      ...paintProps,
    },
    layout: {
      'icon-image': ['get', 'icon'],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      ...layoutProps,
    },
  };
}

export function addVesselsToFlotillaMap(args: {
  map: mapboxgl.Map;
  vessels: Map<number, Vessel>;
  mapLoaded: boolean;
  fieldToHighlight: VesselFieldId | null;
  fieldToLabel: VesselFieldId | null;
  highlightVessels: number[];
}) {
  const {
    map,
    vessels,
    mapLoaded,
    fieldToHighlight,
    fieldToLabel,
    highlightVessels,
  } = args;
  console.log('adding vessels', vessels);
  if (vessels.size > 0) {
    const geoJsonColorScheme = generateVesselGeoJSON(
      vessels,
      fieldToHighlight,
      fieldToLabel,
      highlightVessels
    );

    vessels.forEach((v, k) => vesselsOnMap.set(k, v));

    const addedImages = geoJsonColorScheme
      ? Promise.all(
          Array.from(geoJsonColorScheme.colors).map((color) => {
            return new Promise((resolve, reject) => {
              const imageId = `vessel-${color}`;
              if (map.hasImage(imageId)) {
                resolve(imageId);
                return;
              }
              console.log('adding', imageId);

              const img = new Image();
              img.onload = () => {
                map.addImage(imageId, img);
                resolve(imageId);
              };
              img.src = URL.createObjectURL(
                new Blob([getVesselSvg(color)], { type: 'image/svg+xml' })
              );
            });
          })
        )
      : [];

    const drawVessels = async () => {
      let useFallbackIcon = false;
      if (geoJsonColorScheme) {
        console.log(await addedImages);
      }

      const iconImage: string | mapboxgl.StyleFunction | mapboxgl.Expression =
        useFallbackIcon ? 'vessel-large' : ['get', 'icon'];

      if (!map?.getSource?.('vessels') && geoJsonColorScheme) {
        map.addSource('vessels', {
          type: 'geojson',
          data: geoJsonColorScheme.geoJSONs,
        });

        // size is a layout property, and layout properties can't change based on feature-state
        // so to change the size on hover we have to create two icons and show/hide them by setting the opacity

        map.addLayer(
          vesselLayer(
            {
              // 'icon-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0., 1.]
              'icon-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0,
                ['boolean', ['get', 'greyed'], true],
                0.25,
                1,
              ],
            },
            {
              'icon-allow-overlap': true,
              'icon-size': 0.4,
            },
            'vessels-small',
            iconImage
          )
        );
        map.addLayer(
          vesselLayer(
            {
              'icon-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                ['boolean', ['get', 'highlighted'], true],
                1,
                0,
              ],
              'text-color': '#fff',
              // 'text-halo-color': "hsla(224, 54%, 39%, 0.8)",
              'text-halo-color': [
                'case',
                ['==', ['get', 'selected'], 1],
                'hsla(224, 54%, 20%, 0.8)',
                'hsla(224, 54%, 45%, 0.8)',
              ],
              'text-halo-width': 2.25,
            },
            {
              'icon-size': 0.6,
              'icon-allow-overlap': true,
              'text-optional': true,
              'text-field': ['get', 'label'],
              'text-radial-offset': 1,
              'text-ignore-placement': true,
              'text-allow-overlap': false,
              'text-variable-anchor': [
                'top',
                'top-left',
                'top-right',
                'bottom',
                'bottom-left',
                'bottom-right',
              ],
              'text-font': ['literal', ['HK Grotesk Medium', 'Roboto Medium']],
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                3,
                ['case', ['==', ['get', 'selected'], 1], 12, 11],
                6,
                ['case', ['==', ['get', 'selected'], 1], 16, 15],
              ],
            },
            'vessels',
            iconImage
          )
        );
      } else if (geoJsonColorScheme) {
        (map.getSource('vessels') as mapboxgl.GeoJSONSource).setData(
          geoJsonColorScheme.geoJSONs
        );
      }
    };

    if (mapLoaded && vessels && map.loaded()) {
      drawVessels();
    } else {
      map.on('load', async () => {
        await loadImages(map);
        drawVessels();
        zoomToVessels(map, Array.from(vessels.values()));
      });
    }
  }
}

function loadImages(map: mapboxgl.Map): Promise<Array<null>> {
  function imagePromise(url: string, name: string): Promise<null> {
    return new Promise(function (resolve, _reject) {
      loadImage(map, url, name, () => resolve(null));
    });
  }

  return Promise.all([
    imagePromise('departure.png', 'depart-wp-marker'),
    imagePromise('arrival.png', 'arrival-wp-marker'),
    imagePromise('linestyle.png', 'line-texture'),
    imagePromise('flight.png', 'flight-image'),
  ]);
}
