import moment from 'moment';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { trackUserAction } from 'lib/amplitude';
import { CREW_CHANGE_CANCEL_MESSAGE } from 'lib/constants';
import { TRACK_DISCARD } from 'utils/analytics/constants';
import { ReadOnlyCrewEvent } from 'components/CrewChangePanel/types';

export const shouldFetchCrewChangeEvents = (fetchedAt?: string): boolean => {
  if (!fetchedAt) {
    return true;
  }

  // find if crew change events were fetched more than an hour ago
  return !fetchedAt || moment().diff(moment(fetchedAt), 'minutes') > 60;
};

export const isHistoricalEvent = (
  event: CrewEvent | ReadOnlyCrewEvent
): boolean => {
  const { eta, etd } = event;
  const presentTime = moment();

  return moment(eta) <= presentTime && moment(etd) <= presentTime;
};

export const discardEventPlanning = (
  eventId: number | undefined,
  clearCCEvent: () => void,
  isReportView?: boolean
): Promise<boolean> =>
  new Promise((resolve) => {
    if (!isReportView) {
      const canDiscard = window.confirm(CREW_CHANGE_CANCEL_MESSAGE);
      if (!canDiscard) {
        resolve(false);
        return;
      }
    }

    if (eventId) {
      trackUserAction(TRACK_DISCARD, 'click', { eventId });
    }
    clearCCEvent();
    resolve(true);
  });

export const formatReadOnlyDataLink = (id: string) => {
  // remove trailing slash
  const baseUrl = window.location.href.replace(/\/$/, '');
  return `${baseUrl}/crew-change-plan?id=${id}`;
};
