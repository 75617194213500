import { DebouncedFunc } from 'lodash';
import { Position } from '@turf/turf';
import { AuthResponse } from '@greywing-maritime/frontend-library/dist/utils/auth';
import type {
  PortShort,
  PortWithMetaResp,
} from '@greywing-maritime/frontend-library/dist/types/searchPorts';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type {
  Airport,
  BunkeringEstimates,
  HomeAirports,
  Nationalities,
  Vessel,
  VesselCargoStatus,
} from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type { HotelCommon } from '@greywing-maritime/frontend-library/dist/types/hotels';
import type { UserConfigResp } from '@greywing-maritime/frontend-library/dist/types/userConfig';
import type {
  BatchGetPortCallsV2Resp,
  PortCallV2Common,
} from '@greywing-maritime/frontend-library/dist/types/portCalls';
import {
  RestrictionSourceType,
  RestrictionType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import {
  ActiveFlight,
  RoutePort,
  Summary,
} from 'components/CrewChangePanel/types';
import type { GeofenceCommon } from '@greywing-maritime/frontend-library/dist/types/geofences';

import { Modal, SetModal } from 'hooks/useModal';
import { Crew, Flight, Port } from './types/crew-change-types';
import { VesselFieldHighlight } from './highlight-vessels';
import { ToolTipElement, ToolTipState } from './tooltips';
import { RestrictionResults } from './types/search';
import { StepsKey } from 'components/MapQuickMenu/components/variables';

export const FLOTILLA_SETTINGS_KEY = 'flotillaSettings';

export type ModalProps = {
  modal: Modal;
  setModal: SetModal;
};

export type CustomPortSearchCommand =
  | 'portRestrictions'
  | 'hotelSearch'
  | 'futureport'
  | 'deviation';

export type FlotillaSearchType =
  | CustomPortSearchCommand
  | 'global'
  | 'vesselCommand'
  | 'searchFlights'
  | 'portMap';

export type ETASearchType = 'eta' | 'eventeta';

export interface Point {
  lat: number;
  lng: number;
}

export interface BBox {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
}

export interface PixelBBox {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

// type for ports inside response from `/ports/search` endpoint
export type SearchedPort = PortWithMetaResp & {
  order?: number;
};

// type for searched hotels
export type Hotel = HotelCommon & { id: string };

// type for searched hotels response
export type HotelsResponse = {
  hotels: Hotel[];
  meta: {
    cacheTime?: Date;
  };
};

// type for response from `/ports` endpoint
export type SearchedPortResponse = {
  results: SearchedPort[];
  pagination: {
    totalCount: number;
    more: boolean;
  };
};

export type RestrictionsReqParams = {
  portOrCountryCode: string;
  type: RestrictionType;
  source?: RestrictionSourceType;
  isFromSeaGptEmail?: boolean;
};

export type SeaGPTRestrictionsReqParams = {
  locode: string;
  agent: string;
};

export type FlotillaSearchState = 'input' | 'slider' | 'text' | 'port-input';

export type ParsedSearchType = {
  type: FlotillaSearchType;
  vessel?: {
    field: string;
  };
  meta: {
    possibleCommands: string[];
    command: string;
    search: string;
    additionalQuery?: string; // additional helper query for multi-step searches, e.g - hotels search
  };
};

export interface PortRestrictionSearchResult {
  searchType: 'portRestrictions';
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  filterLabel: string;
  results: RestrictionResults[];
}

export interface PortMapSearchResult {
  searchType: 'portMap';
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  filterLabel: string;
  results: SearchedPort[];
}

export interface HotelsSearchResult {
  searchType: 'hotelSearch';
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  filterLabel: string;
  // hotel search is done in 2 phase - first port search & then hotels for that port
  // the result here can be of either type. Use the util `isHotelResponse`
  results: RestrictionResults[] | HotelsResponse;
}

export type VoyagePlanPortResult = {
  vessel: Vessel;
  portCalls: (PortCallV2CommonUTC & { order?: number })[];
};

export interface VoyagePlanSearchResult {
  searchType: 'futureport';
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  filterLabel: string;
  results: VoyagePlanPortResult[];
}

export interface FlightSearchResult {
  searchType: 'searchFlights';
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  filterLabel: string;
  results: Flight[] | undefined;
}

export type ToolTipSetter = DebouncedFunc<
  (element?: ToolTipElement, state?: ToolTipState) => void
>;

export interface HoverAirport {
  airport: Airport;
  vesselId: number;
  vesselLatLng: {
    lat: number;
    lng: number;
  };
}

export interface Route extends AuthResponse {
  updated: Date;
  path: {
    pathGeoJSON: any;
  } | null;
  waypoints: Array<Waypoint>;
  properties?: {
    isClient: boolean;
  };
}

export type BatchPortCallsResponse = {
  success: boolean;
  message: string;
  portCalls?: BatchGetPortCallsV2Resp;
};

export type PortCallResponse = {
  success: boolean;
  message: string;
  portCalls: PortCallV2CommonUTC[];
  meta?: any;
};

export type PortCallResponseMap = {
  [vesselId: number]: PortCallResponse;
};

export interface Waypoint {
  currentPosition?: boolean;
  eta?: Date;
  etd?: Date;
  hoursInPort?: number;
  id: string;
  lat: number;
  lon: number;
  text: string;
}

export interface PortCall {
  text: string;
  id: string;
  lat: number;
  lng: number;
  source: string;
  locode?: string;
  type: string;
  eta?: string;
  etd?: string;
  name?: string;
}

export type PortCallV2CommonUTC = PortCallV2Common & {
  utcETA: string | null;
  utcETD: string | null;
  portDict: PortShort | null;
  vesselId: number;
  dateToSort: string | null;
};

export type PortCallMeta = {
  [key: string]: any;
  purpose?: string;
  voyageStatus?: string;
  function?: string;
  timezoneOffset?: number;
};

export interface DataloyPortCall extends PortCall {
  type: 'Dataloy';
  locode: string;
  source: 'commercial';
  key: number;
  isCanal: boolean;
  arrivalFixed: boolean;
  departureFixed: boolean;
  timezoneOffset: number;
}
export interface IMOSPortCall extends PortCall {
  type: 'Voyage Plan';
  locode: string;
  source: 'IMOS';
  meta?: PortCallMeta;
}

export interface PredictivePortCall extends PortCall {
  type: 'Predictive';
  locode?: string;
  source: 'Commercial';
}

export interface AISPortCall extends PortCall {
  type: 'AIS';
  locode: string;
  source: 'AIS';
}

export interface VesselFeature extends GeoJSON.Feature {
  properties: {
    id: string | number;
    vesselId: number;
    distance?: number;
    waypoints?: Position[];
    type?: string;
  };
}
export interface RouteState {
  routes: Map<number, Route>;
  portCalls: Map<number, Promise<PortCallResponse | null>>;
}

export interface RouteMethods {
  storeVesselRoute: (vesselId: number, route: Route) => void;
  storeVesselPortCalls: (
    vesselId: number,
    portCalls: Promise<PortCallResponse | null>
  ) => void;
}

export type RouteStore = RouteState & RouteMethods;

export interface VesselSearchResult {
  field: {
    [key: VesselFieldId]: VesselFieldValue | null;
    idField: number;
  };
  match?: string;
}

export type FlightResultWithCache = {
  flights: Flight[];
  fromCache: boolean;
};

export interface SearchResult {
  searchFieldName: string;
  searchFieldDescription: string;
  priority: number;
  searchResultDescFn: (count: number, term: string) => string;
  filterLabel: string;
  results: { timeTaken: number; results: VesselSearchResult[] };
}

export interface Update {
  id: number;
  created_at: Date;
  updated_at: Date;
  prevValue: string;
  newValue: string;
  updateType: string;
  field: string;
  source: string;
  initiator: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  content: {
    short: string;
    long: string;
  };
  entity: {
    type: string;
    id: string;
    name: string;
  };
  meta?: {
    vesselId: number;
    portName?: string;
  };
}

export interface NotificationUpdate {
  id: number;
  created_at: Date;
  updated_at: Date;
  update_id: number;
  recipient_id: number;
  web_notification_read_at: Date | null;
  remind_at: Date | null;
  update: Update;
}

export type CrewChangeStep = 'crew' | 'route' | 'ports' | 'flights';

export type CrewChangeDataType = (Crew | Port | ActiveFlight | Summary)[];

export type PlanningData = {
  crew: Crew[];
  route: RoutePort[];
  ports: Port[];
  flights: ActiveFlight[];
};

export interface ReminderTag {
  text?: string;
  type?: ReminderTagType;
  meta?: {};
}

export type ReminderStatusType = 'PAST' | 'UPCOMING';

export type ReminderTagType = 'vessel' | 'port' | 'generic';

export interface Reminder {
  remindOn: string;
  text: string;
  subtext: string | '';
  shouldNotify: boolean;
  tags: ReminderTag[];
}

// TODO: replace Reminder when merged
export interface Reminderv2 {
  remindOn: string;
  text: string;
  subtext: string | '';
  shouldNotify: boolean;
  tags: ReminderTagObject[];
}

export type VesselAutocomplete = Pick<Vessel, 'imo' | 'name' | 'type'>;

export type VesselNameResponse = {
  results: VesselAutocomplete[];
  pagination: {
    more: boolean;
  };
};

export type UserSelectedPortsReq = {
  pois: {
    locode: string;
    name: string;
  }[];
};

export interface ReminderTagObject extends ReminderTag {
  id: string;
  createdAt: Date;
  reminderId: string;
}
export interface ReminderObject extends Reminderv2 {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  creatorId: string;
  notified: boolean;
}

export interface PaginatedReminders extends Pagination {
  reminders: ReminderObject[];
}
export interface ReminderResponse {
  success: boolean;
  message: string;
  reminders?: PaginatedReminders;
}

export type PortRestrictionSearchResponse = {
  success: boolean;
  message: string;
  pagination?: {
    more: boolean;
    totalCount: number;
  };
  results?: RestrictionResults[];
};

export interface Pagination {
  pagination: {
    nextPageToken: string;
    totalCount: number;
  };
}
export interface ProximityPort {
  distanceMetres: number;
  id: number;
  locode: string;
  description: string;
  name: string;
  added_by: string | null;
  lat: number;
  lng: number;
  created_at: Date;
  country: {
    country: {
      name: string;
      'alpha-2': string;
      'country-code': number;
    };
    portsOpen: string;
    visas: {};
  };
  costs: {
    s5: {
      indicative: number;
    };
  };
  pcrTestInfo: {
    locode: string;
    crew_change_possible: boolean;
    advance_notice_days_min: number;
    advance_notice_days_max: number;
    onsigner_pcr_required: string;
    onsigner_quarantine_upon_arrival: boolean;
    pcr_results_hrs_min: number;
    pcr_results_hrs_max: number;
    pcr_test_availability: boolean;
  };
}

export interface FlotillaSearchArg {
  searchId: string;
  searchQuery: string;
  updateInput: boolean;
  vessels?: Map<number, Vessel>;
}

export type VesselUpdateResponse = {
  success: boolean;
  vesselIds?: number[];
};

export interface SequentialParameters<T extends VesselFieldValue> {
  min: T;
  max: T;
}

export interface QualitativeParameters<T extends VesselFieldValue> {
  buckets: Array<T>;
}

export interface HighlightParameters<T extends VesselFieldValue> {
  field: string;
  emptyCheck: (value: any) => boolean;
  emptyExample: T | null;
  sequential: SequentialParameters<T> | null;
  qualitative: QualitativeParameters<T> | null;
}

export interface VesselFieldHighlightConfig {
  highlightType: 'enum' | 'number' | 'date';
  // Type of highlight.
  colorPalette: 'sequential' | 'qualitative' | 'qualitative_contract';
  // Type of color palette to use.
}

export type PrintableVesselField<T> = {
  displayPriority: number;
  // Lower is better, order in which fields are displayed.
  getDisplayString(value: T): string;
  // Get an (unstyled for native fields) string for this value
  shortDesc: string;
  // Short description for the field. e.g. "Name"
  longDesc: string;
  // Long description for the field. e.g. "Vessel Name"
  tooltip: string;
  // Additional supporting text to describe the field to the user
  emptyValues: any[];
  // Values considered invalid/empty for this field.
  emptyValueReplacementStr: string;
  // Values to replace empty values with for purposes like display.
};

export interface LabelFieldMetadata extends PrintableVesselField<Vessel> {}

export interface CompoundPopupFieldMetadata
  extends PrintableVesselField<Vessel> {
  fieldInputs: VesselFieldId[];
  compoundField: true;
}

export type CompoundSidePanelField = {
  displayPriority: number;
  persistent: boolean;
  shortDesc: string;
  longDesc: string;
  tooltip: string;
  getDisplayElement(vessel: Vessel): JSX.Element | null;
};

export type CompoundSidePanelFields = {
  [key: string]: CompoundSidePanelField;
};

export interface VesselFieldMetadata<T extends VesselFieldValue>
  extends PrintableVesselField<T> {
  display: boolean;
  // Can this field be displayed (anywhere) at all?
  popupEnabled: boolean;
  // Can this field be shown on the popup?
  fieldType: 'enum' | 'number' | 'string' | 'date' | 'object';
  // Type of field used for sorting/categorization.
  includedInCSV: boolean;
  // Whether this field is included in the CSV.
  updateFrequencyMs: number | null;
  // Expected update frequency of this field, for fractional refreshes. Null means will not change.
  highlightConfig?: VesselFieldHighlightConfig;
  // configuration for marker highlighting based on this field.  Empty means cannot be highlighted.
  processFieldFromString?(inp: string): T;
  // Function to process a string (received from the server to the correct type.)
  emptyValues: any[];
  // Values considered invalid/empty for this field.
  emptyValueReplacementStr: string;
  // Values to replace empty values with for purposes like display.
}

export type MapRef = React.MutableRefObject<MapObject | null | undefined>;

export interface MapObject {
  map: mapboxgl.Map;
  focusOnVessel: (vessel: Vessel) => void;
  focusOnPort: (coordinates: { lat: number; lon: number }) => void;
  remove: () => void;
  zoomToVessels: (v: Vessel[]) => void;
  makeVesselBig: (v: Vessel | null) => void;
  setVesselMap: (v: Map<number, Vessel>) => void;
}

export type VesselFieldId = keyof VesselFieldsMetadata & string;

export type VesselFieldValue =
  | Vessel
  | number
  | string
  | Date
  | HomeAirports
  | Nationalities
  | boolean
  | CrewEvent[]
  | Crew[]
  | VesselCargoStatus
  | VesselTag[]
  | BunkeringEstimates
  | null;

export type VesselFieldsMetadata = {
  [key: string]: VesselFieldMetadata<VesselFieldValue>;
  id: VesselFieldMetadata<number>;
  name: VesselFieldMetadata<string>;
  imo: VesselFieldMetadata<string>;
  mmsi: VesselFieldMetadata<string>;
  nextPortCode: VesselFieldMetadata<string>;
  lastPortCode: VesselFieldMetadata<string>;
  nextPortName: VesselFieldMetadata<string>;
  lastPortName: VesselFieldMetadata<string>;
  lng: VesselFieldMetadata<number>;
  lat: VesselFieldMetadata<number>;
  owner: VesselFieldMetadata<string>;
  manager: VesselFieldMetadata<string>;
  updatedAt: VesselFieldMetadata<Date>;
  portCallsLastChanged: VesselFieldMetadata<Date>;
  arrival: VesselFieldMetadata<Date>;
  departure: VesselFieldMetadata<Date>;
  cargoStatus: VesselFieldMetadata<VesselCargoStatus>;
  riskStatus: VesselFieldMetadata<string>;
  riskStatusColor: VesselFieldMetadata<string>;
  CRY4GeneratedUUID: VesselFieldMetadata<string>;
  image: VesselFieldMetadata<string>;
  crewHomeAirports: VesselFieldMetadata<HomeAirports>;
  speed: VesselFieldMetadata<number>;
  dwt: VesselFieldMetadata<number>;
  course: VesselFieldMetadata<number>;
  crewNationalities: VesselFieldMetadata<Nationalities>;
  addedBy: VesselFieldMetadata<string>;
  status: VesselFieldMetadata<string>;
  source: VesselFieldMetadata<string>;
  flag: VesselFieldMetadata<string>;
  type: VesselFieldMetadata<string>;
  picLabel: VesselFieldMetadata<string>;
  routeExists: VesselFieldMetadata<boolean>;
  secondsToNextPort: VesselFieldMetadata<number>;
  tags: VesselFieldMetadata<VesselTag[]>;
  crewEarliestDueDate: VesselFieldMetadata<Date>;
};

export type UserInfo = UserConfigResp;

export type UserPICInfo = Pick<
  UserInfo,
  'id' | 'firstname' | 'lastname' | 'loginEmail'
>;

export type HandlerThunk = () => Promise<any>;

export type HighlightedField = {
  field: string;
  highlight: VesselFieldHighlight<any>;
};

export type OnboardCompletion = {
  type: StepsKey;
  completed_at: Date;
};

export type UserLoginValues = {
  email: string;
  password: string;
};

export type UserSignUpValues = {
  email: string;
  password: string;
  company: string;
  agree: boolean;
};

export type UserVerifySignUp = {
  email: string;
  token: string;
};

export type UserForgotPasswordValues = {
  email: string;
};

export type MagicLinkResetPasswordValues = {
  magicLinkToken?: string;
  oldPassword?: string;
  newPassword: string;
  retypePassword: string;
};

export type UserResetPasswordValues = {
  password: string;
};

export type UserResetPasswordRequest = {
  email: string;
  token: string;
  password: string;
};

export type AuthPacket = {
  [key: string]: string;
  token: string;
  expires: string;
  api: string;
  icHash: string;
  icEmail: string;
};

export type GeofenceWithColor = GeofenceCommon & {
  color: string;
};

export type HoveredFeature = {
  feature: mapboxgl.MapboxGeoJSONFeature;
  coordinates: mapboxgl.LngLat;
};

export const ALL_SETUP_WIZARD_STEPS = [
  'role',
  'popup',
  'sidepanel',
  'highlight',
  'add-vessel',
  'assign-pic',
  'notifications',
  'contact',
] as const;

export type WizardSteps = typeof ALL_SETUP_WIZARD_STEPS[number];

export type PortMarkers = {
  eta?: string;
  etd?: string;
  id: string;
  index: number;
  lat: number;
  lng: number;
  segment: 'past' | 'future';
  source: string;
  displayName: string;
  locode: string;
  type: string;
  waypointType: 'port' | 'vessel';
  backgroundColor: string;
  port: {
    name: string;
    locode: string;
  };
};

export type VesselTag = {
  id: number;
  colour: string;
  displayName: string;
};

export type VesselTagWithCount = VesselTag & {
  count: number;
};

export type UpdateAddRemoveVesselTag = {
  flotVesIds: number[];
  tag: VesselTag;
  action: 'add' | 'remove';
};

export type CopilotCommandObject = {
  id: string;
  extractableFields: any;
  desc: string;
};
