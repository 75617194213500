import { NavigateFunction } from 'react-router-dom';
import {
  AMPLITUDE_TOKEN,
  HANDOVER_TOKEN,
  SENTRY_DSN_TOKEN,
  SENTRY_TAG_TOKEN,
  USERID_TOKEN,
} from '@greywing-maritime/frontend-library/dist/config/tokens';

import { setSessionKeys } from 'utils/handle-login';

// TODO: remove this utility once we decommission V1 and don't have to support handover
export const handoverPlatform = (
  hashString: string,
  navigate: NavigateFunction
) => {
  console.log('Checking for handover...');

  const tokens: { [key: string]: string } = {};
  hashString
    .substring(1)
    .split('&')
    .map((token) => token.split('='))
    .forEach((token) => {
      tokens[token[0]] = token[1];
    });

  const handoverToken = tokens[HANDOVER_TOKEN];

  if (handoverToken) {
    const defaultExpiryDate = new Date();
    // Default expiry set to seven days, this shouldn't happen.
    defaultExpiryDate.setTime(
      defaultExpiryDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    const expiresToken = tokens['expires'];
    const expires =
      (expiresToken && decodeURIComponent(expiresToken)) ||
      defaultExpiryDate.toUTCString();

    setSessionKeys({
      analytics: tokens[AMPLITUDE_TOKEN],
      api: tokens['api'],
      dsn: tokens[SENTRY_DSN_TOKEN],
      expires,
      tag: tokens[SENTRY_TAG_TOKEN],
      token: handoverToken,
      userId: tokens[USERID_TOKEN],
    });

    // navigate to root route for successful handover
    navigate('/', { replace: true });
  }
};
