import React, { SyntheticEvent } from 'react';
import {
  AmadeusAirport,
  Airport as GreywingAirport,
  LngLat2Tuple,
  CostObjectType,
  CountryWithCrewChangeCount,
  RestrictionSourceType,
  ProximityPort,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import {
  CrewEvent,
  CrewType,
} from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type { RestrictionsData } from '@greywing-maritime/frontend-library/dist/types/restrictionsDataQuery';
import type {
  CrewChangePlanSummary,
  CrewChangePlanUserNotes,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import { CabinClass } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import { Route } from '@greywing-maritime/gw-ngraph';

import {
  CrewChangeStep,
  FlightResultWithCache,
  PlanningData,
} from 'utils/types';
import { GetVesselRoute } from 'utils/types/route-calculator';
import {
  Crew,
  Flight,
  FlightRequest,
  OrgCrew,
  Port,
} from 'utils/types/crew-change-types';
import {
  JourneyPort,
  JourneyVessel,
} from 'components/SidePanel/VesselCourse/types';
import {
  READ_ONLY_CREW_EVENT_FIELDS,
  READ_ONLY_FLIGHT_CREW_FIELDS,
  READ_ONLY_FLIGHT_FIELDS,
  READ_ONLY_FLIGHT_FILTERS,
  READ_ONLY_FLIGHT_PORT_FIELDS,
  READ_ONLY_PORT_FIELDS,
  READ_ONLY_VESSEL_FIELDS,
} from '../helpers';
import { SvgIconComponent } from '@mui/icons-material';

// TODO: remove this import to avoid circular dependency
import { CrewLinkFlightSummary } from './crew-link';

export type LocodeDetails = {
  locode: string;
  portETA?: string;
  flightSource?: string;
};

export type FlightReqDetails = {
  requestId: string;
  crew: Crew;
  port: Port;
};

export type FetchCrewFlightsApi = (
  reqParams: FlightRequest,
  options?: {
    cabinClass?: CabinClass;
    refresh?: boolean;
    allowTimeout?: boolean; // close the event stream after 5 minutes, if true
  }
) => Promise<FlightResultWithCache>;

export type CrewInput = Crew & {
  selected?: boolean;
  order?: number;
  existing?: boolean; // indicates if this crew input comes from existing events
  visaCountry?: string;
  visaExpiry?: string;
};

// type of user added or replacement for missing crew
export type CustomCrew = Partial<Crew> & {
  id: number;
  type: CrewType;
  wage?: number | null; // derived from `monthlySalary` field
};

// crew in the selection list - containing event & org crew list
export type ListItemCrew = (Crew | OrgCrew) & {
  index?: number;
  label?: 'onsigner' | 'offsigner' | 'unspecified';
};

export type AddCrew = (newCrew: CrewInput, isEventCrew?: boolean) => void;

export type TableType = CrewChangeStep;

export type StepData = PlanningData[TableType];

export type TableState = {
  collapse: boolean;
  // selected: boolean;
  step: CrewChangeStep;
  view: FlightTableView;
  summaryCollapse: boolean;
  // selected locode in flights table for read-only view
  readOnlyDetails: ReadOnlyPortCardDetails;
  // contains budget details for ongoing crew-change plan
  budgetDetails: UIBudgetDetails | null;
};

// crew-chage panel report info state
export type ReportInfoState = {
  // complete report shareable link generated when saved - reset after any change made to `planningData`
  savedLink?: string;
  // ID of incomplete crew-change plan during planning
  incompletePlanId?: string;
};

export type CrewAddStatus = {
  addingId: number | boolean;
  isSelecting: boolean;
};

export enum FareType {
  all = 'all',
  marine = 'marine',
  general = 'non-marine',
}

export type PortNotesLoadingState = {
  initial?: boolean;
  loadMore?: boolean;
};

export type PortParams = {
  unit: 'NM' | 'KM';
  range: number;
  agency: string;
  priorities: string[];
  showUrgentPorts: boolean; // indicates whether currently showing ports nearby the vessel
  etaLimit: number; // ETAs allowed withing number of weeks
};

// use `estimated` field to idicate if either of ETA or ETD is missing
export type PortMeta = MergedPort & {
  estimated: string; // one of `eta`, `etd` or empty string
};
// port merged with route data to find a duplicate ETA, if available
export type MergedPort = Port & {
  uniqETA?: string;
};
export type MergedReadOnlyPort = ReadOnlyPort & {
  uniqETA?: string;
};

// insert filtersn inside confirmed list to carry forward in report data
export type ConfirmedFlight = ActiveFlight & {
  filters: ReadOnlyFlightFilters;
};

export type PortDates = {
  eta: string | undefined;
  etd: string | undefined;
};

export type FlightFilters = {
  active: boolean;
  time: number;
  layover: [number, number];
  stopsCount: number;
  type: FlightFilterType;
  source: string;
  fareType: FareType;
  locode: string;
  portAirport: string;
  confirmed: ConfirmedFlight[];
  departures: Departures | null;
  airlines: Airline[];
  selectedStops: LayoverAirport[]; // array of layover airport iata codes
  range: FlightParamsRange;
  arrivalTime: [number, number];
  departureTime: [number, number];
  emailSent: boolean;
  preferred?: boolean; // preferred port for crew-change flights
  duplicate?: boolean; // available for duplicate port(s)
  uniqETA?: string;
  portDates?: PortDates;
  allowAirportTransfer?: boolean;
  settingsLoaded?: boolean; // indicates if the filter params are already loaded from settings
};

export type CustomFilterValues = {
  selectedStops: LayoverAirport[];
  airlines: Airline[];
};

export type ToggleConfirm = (flight: FlightRow, confirmed: boolean) => void;

export type UpdateFilter = (item: Partial<FlightFilters>) => void;

export type FetchStatusReducer = (
  state: FetchStatus,
  action: { type: string; payload?: any }
) => FetchStatus;

export type CommonReducer<StateType> = (
  state: StateType,
  action: { type: string; payload?: any }
) => StateType;

export type FlightFiltersReducer = (
  state: { [locodeKey: string]: FlightFilters },
  action: { type: string; payload?: any }
) => { [locodeKey: string]: FlightFilters };

export type SelectInModal = (
  flight: ActiveFlight,
  crewId: number,
  filters: FlightFilters | ReadOnlyFlightFilters
) => void;

export type SetFocusedPort<T> = React.Dispatch<React.SetStateAction<T | null>>;

export type FlightTableView = 'filter' | 'compare';

export type FlightFilterType = 'Cheapest' | 'Fastest' | 'Lowest CO2';

export type Departures = {
  onsigner?: string;
  offsigner?: string;
};

export type DepartureInput = {
  type: string;
  locodeKey: string;
  departures: Departures;
};

export type CrewActionProps = {
  crew: CrewInput | null;
  addingMissingCrew?: boolean;
  addCrew: (crew: CrewInput) => void;
  selectAirport: SelectCrewItem;
};

export type FormatPortAirportInput = {
  iataCode: string;
  name: string;
  cityName: string | null;
};

export type FormatPortAirportName = ({
  iataCode,
  name,
  cityName,
}: FormatPortAirportInput) => string;

export type RemoveCrewFunc = ({
  all,
  crew,
}: {
  all: boolean;
  crew?: Crew;
}) => CrewInput[] | void;

export type Layover = { airports: string[]; time: number };

export type LayoverAirport = {
  iataCode: string;
  airport: string;
  selected: boolean;
};

export type Airline = {
  airline: string;
  selected: boolean;
};

// add extra fields for flight row in table
export interface ActiveFlight extends Flight {
  id: string;
  confirmed?: boolean;
  flight: string | null; // make this one `connection`
  cost: string;
  layoverTime: number;
  time: string;
  flightLayoverTime: string;
  layovers: string[];
  departureTime: string;
  arrivalTime: string;
  hotelCost?: number;
  // TODO: Update when the type is available in common
  crewLinkFlightSummary?: CrewLinkFlightSummary;
}

export type FlightRow = ActiveFlight | Partial<ActiveFlight>;
export type ReadOnlyFlightRow = (ReadOnlyFlight | Partial<ReadOnlyFlight>) & {
  flight: string | null; // make this one `connection`
};
export type EmptyFlight = {
  id: string;
  crew: Crew;
  path: string;
  flight: null;
};

// props available inside flight results modal of CC panel
// to allow users to select a flight for a crew from all the available ones
export type FlightSelectProps = {
  // currently selected flight for a specific crew
  selected: Flight | undefined;
  // method that updates the selected flight to a new one
  selectFlight: (flight: Flight) => void;
};

export type DepartureCrewUpdate = {
  [type: string]: boolean; // crew type for which departure date is updated
};

export type FetchStatus = {
  progress: { [locodeKey: string]: number } | null; // highest is 1, meaning completed
  initialized: boolean; // indicates first flights' fetching status
  crewType?: DepartureCrewUpdate;
};

export type PortProgressHandler = (port: Port, reset?: boolean) => void;
export type FetchStatusHandler = (
  source?: string,
  uniqETA?: string
) => PortProgressHandler;

export interface PortRow extends Port {
  name: string;
  locode: string;
  costDetails: CostObjectType;
  // mui throws warning in console for array
  closestAirports: string;
  calculatedDistance: number;
  deviationTimeDifference: number;
  selectedAirport?: AmadeusAirport | GreywingAirport;
  distanceNM: number;
  eta: string;
  coordinates: { lat: number; lon: number };
  airportsCount: number | string;
  selected?: boolean;
  status?: string[]; // port status based on priorities
}

export interface CustomPort extends Port {
  selected: boolean;
}
export interface PortCardType extends Port {
  flightSource?: string; // available for duplicated port
  flightsCount: number;
  sources?: string[]; // available to split flights
  uniqETA?: string;
}

export type FlightPrice = {
  amount?: number;
  currency?: string;
};

// updated crew (in summary table) before sending report
export type Summary = ActiveFlight & {
  cid?: string | null;
  crew: Crew;
  country: string | undefined;
  portName: string;
  locode: string;
  totalCost: string;
  ready?: string;
  change?: string;
  flightCost: FlightPrice;
};

export type SelectCrewItem = (
  crew: CrewInput | CustomCrew,
  item: Partial<CrewInput | CustomCrew>
) => void;

export type SelectPortNearbyAirport = (
  locode: string
) => (airport: AmadeusAirport | GreywingAirport) => void;

export type ReadOnlyPortCardDetails = {
  locode: string;
  flightSource?: string;
  uniqETA?: string;
  saveable?: boolean; // read-only report can be saved once flight(s) updated
};

export type ArrangePortPopupsInput = {
  ports: (Port | ReadOnlyPort)[];
  allFilters: { [locodeKey: string]: FlightFilters };
  readOnlyDetails?: ReadOnlyPortCardDetails;
};

export type FlightParamsType = {
  stopsCount: number;
  layoverTime: number;
  flightTime: number;
};

export type FlightParamsRange = {
  min: FlightParamsType;
  max: FlightParamsType | null;
};

export type FilterRanges = {
  [locodeKey: string]: CustomFilterValues & {
    flightParamsRange: FlightParamsRange;
  };
};

export type EmailDetailInputs = {
  crew: Crew[];
  vessel: Vessel;
  port: Port | ReadOnlyPort;
  flights: (ConfirmedFlight | ReadOnlyFlight)[];
  additionalCrew?: Crew[];
};

export type VesselPath = { type: string; coordinates: LngLat2Tuple[][] };

export type VesselRoute = {
  path: VesselPath;
  posRoute: Route[];
  waypoints: RoutePort[];
  distances: number[]; // in NM
  unit: 'NM' | 'KM';
};

export type TravelRequirements = {
  visa: string;
  covid19?: string;
  documents?: string;
  quarantine?: string;
};

// port LOCODE & filters locode key
export type LocodesProps = {
  activeLocode: string;
  locodeKey: string;
};

export type ActionStatus = {
  allConfirmed: boolean | undefined;
  allCompareable: boolean;
};

export type FlightActionProps = {
  view: FlightTableView;
  actionStatus: ActionStatus;
  toggleConfirmAll: (value: boolean) => void;
  loadFlights: (departures: DepartureInput) => void;
};

export type AddPortDatesAndDeviation = (ports: Port[]) => Promise<Port[]>;

export type PortETAProps = {
  port: ProximityPort;
  coords: [number, number];
  getVesselRoute: GetVesselRoute;
};

export type PortCardProps = {
  port: PortCardType;
  onChange: (port: PortCardType) => void;
};

export type PortRequestTuple = [
  number, // range
  Vessel,
  VesselRoute,
  string | null | undefined,
  Crew[]
];

export type StepDetailsInput = {
  canSelectAllCrew?: boolean;
  canSelectTop3?: boolean;
  view?: FlightTableView;
};

export type StepDetails = {
  [key in CrewChangeStep]: {
    header: string;
    instructions: string;
    prevButton?: string;
    nextButton: string;
    icon?: SvgIconComponent;
  };
};

export type RoutePortMeta = {
  added: boolean;
  order: number;
  distance?: number;
  time?: number; // time in seconds to reach to current port from the previous
  skipped?: boolean; // if true, this entry in `routePorts` is skipped in ports table (ETA slider)
};

export type RoutePort = JourneyPort & RoutePortMeta;
export type RoutePortOrVessel = RoutePort | JourneyVessel;

export type RouteModalType = 'add' | 'update';

export type PortDateType = 'eta' | 'etd';

export type PortPopupDetails = {
  portParams: PortParams;
  crew: Crew[];
  flights: (ActiveFlight | ReadOnlyFlight)[];
};

export type PopupFlight = (ActiveFlight | ReadOnlyFlight) & {
  connection: string;
};

export type PopupPort =
  | ((PortRow | Port | ReadOnlyPort) & {
      flightSource?: string;
      uniqETA?: string;
    })
  | PortCardType;

/*----- Read Only Data Types -----*/

export type OpenReadOnlyFilter = (
  flight: ReadOnlyFlight
) => (event: SyntheticEvent) => void;

export type ReadOnlyPortRestrictions =
  | {
      [source in RestrictionSourceType]?: RestrictionsData | undefined;
    }
  | null;

export type ReadOnlyPort = Pick<Port, typeof READ_ONLY_PORT_FIELDS[number]> & {
  selected: boolean;
  selectedAirport: FormatPortAirportInput;
  restrictions: ReadOnlyPortRestrictions;
  // custom/trimmed `pastCrewChanges` field
  pastCrewChanges: {
    count: number;
    countries: CountryWithCrewChangeCount[];
  };
};

export type DuplicateReadOnlyPort = ReadOnlyPort & {
  flightSource?: string;
  preferred?: boolean;
  uniqETA?: string;
};

export type ReadOnlyFlightFilters = Pick<
  FlightFilters,
  typeof READ_ONLY_FLIGHT_FILTERS[number]
>;

export type ReadOnlyFlightCrew = Pick<
  Crew,
  typeof READ_ONLY_FLIGHT_CREW_FIELDS[number]
>;
export type ReadOnlyFlightPort = Pick<
  Port,
  typeof READ_ONLY_FLIGHT_PORT_FIELDS[number]
>;

export type ReadOnlyFlight = Pick<
  Flight,
  typeof READ_ONLY_FLIGHT_FIELDS[number]
> & {
  hotelCost: number;
  crew: ReadOnlyFlightCrew;
  port: ReadOnlyFlightPort;
  filters: ReadOnlyFlightFilters;
  // indicates if flights saved against this locode key is the preferred one
  preferredLocodeKey: boolean;
  confirmed: boolean;
  crewLinkFlightSummary?: CrewLinkFlightSummary;
  type?: Flight['type']; // instead of using directly from READ_ONLY_FLIGHT_FIELDS, to take care of old objects where this doesn't exist
};

export type ReadOnlyVessel = Pick<
  Vessel,
  typeof READ_ONLY_VESSEL_FIELDS[number]
>;

export type ReadOnlyCrewEvent = Pick<
  CrewEvent,
  typeof READ_ONLY_CREW_EVENT_FIELDS[number]
>;

export interface SavedCrewChangePlan {
  crew: Crew[];
  route: RoutePort[];
  ports: ReadOnlyPort[];
  flights: { [locodeKey: string]: ReadOnlyFlight[] };
  vessel: ReadOnlyVessel;
  portFilters: PortParams;
  event?: ReadOnlyCrewEvent;
}

// type of data saved in backend for viewing read-only panel
export interface SaveableCCReportData {
  crewChangePlan: SavedCrewChangePlan;
  userNotes: CrewChangePlanUserNotes;
  summary: CrewChangePlanSummary;
}

// type of final read-only data - prepared from saved cc panel data & returned response
export interface ReadOnlyPlanningData extends SaveableCCReportData {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator?:
    | {
        id: number;
        email: string;
        name: string;
      }
    | undefined;
  flotillaVesselId: number;
}

/* ----- Typss for budgets ----- */

export enum CCBudgetType {
  FLIGHTS = 'FLIGHTS',
}

export enum BudgetStatus {
  ALL_OVER = 'ALL_OVER',
  SOME_OVER = 'SOME_OVER',
  ALL_UNDER = 'ALL_UNDER',
  NONE = 'NONE',
}

export type UIBudgetDetails = {
  text: string;
  status: BudgetStatus;
};

export type VesselCCBudget = {
  budgetType: CCBudgetType;
  amount: number;
  currency: string;
};

export type VesselCCBudgetResponse = {
  vesselId: number;
  budgets: VesselCCBudget[];
};
