import groupBy from 'lodash/groupBy';
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
  memo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { blue, borderGray, gray, red, textGray, white } from 'lib/colors';
import { setSidePanelRouteConfig, setCalculatedRoute } from 'redux/actions';
import { fetchVesselPortCallsAsync } from 'redux/thunks';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import { Card, Loader, Button } from 'components/shared';
import JourneyPortCard from './JourneyPortCard';
import JourneyVesselCard from './JourneyVesselCard';
import CardHeader from '../common/CardHeader';

import { RouteCalculatorContext } from 'contexts/RouteCalculatorContext';

import { JourneyPortVisualCard, JourneyVesselVisualCard } from './types';
import { ICON_COLOR, TEXT_COLOR } from '../common/variables';
import {
  prepareSingleTypeRoute,
  SegmentTypes,
} from '../../VesselRoutes/helpers';

import { RootState } from 'redux/types';
import { selectSinglePortCall } from 'redux/reducers/portCalls';
import { selectSidePanelVesselRouteConfig } from 'redux/reducers/sidePanel';
import { selectActiveVesselRouteById } from 'redux/reducers/calculatedRoute';
import { formatPortCallType } from 'utils/routes';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

const CardContainer = styled(Card)`
  padding: 0;
  border-radius: 0;
`;

const RestrictedCardHeightContainer = styled.div<{ $loading: boolean }>`
  padding: 0 0.5rem;
  max-height: 340px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ${({ $loading }) =>
    $loading &&
    `
    height: 200px;
    background-color: ${gray};
  `};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ErrorText = styled.p`
  color: ${red};
  margin: 0;
  padding: 0 0.5rem;
`;

const IconButton = styled(Button)`
  min-width: unset;
  width: 2rem !important;
  svg {
    font-size: 15px !important;
  }
`;

const Display = styled.div<{ $color: string; $textColor: string }>`
  background-color: ${({ $color }) => $color};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  border: 1px solid
    ${({ $color }) => ($color === white ? borderGray : 'transparent')};
  p {
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.6rem;
    margin: 0;
    color: ${({ $textColor }) => $textColor};
  }
`;

const ActionHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 15px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0.25rem 0;
  column-gap: 0.25rem;
  row-gap: 0.25rem;
  button {
    margin-right: 0;
    &:hover {
      transform: unset !important;
      box-shadow: unset !important;
    }
  }
`;

const SegmentContainer = styled.div`
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  color: ${textGray};
  p {
    font-size: 0.8rem;
    margin: 0 0 0 auto;
  }
  svg {
    font-size: 14px !important;
  }
`;

const SegmentHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  p {
    font-size: 0.8rem;
    margin: 0;
    color: ${textGray};
  }
`;

const RadioFormControl = styled.fieldset`
  position: relative;
  flex: 1;
  width: 100%;
`;

interface IRadioGroup {
  value: string;
}

const computeTranslate = (value: string) => {
  if (value === 'past') return 'translateX(0%)';
  if (value === 'future') return 'translateX(100%)';
  if (value === 'both') return 'translateX(200%)';
};

const RadioList = styled(RadioGroup)<IRadioGroup>`
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border-radius: 20px;
  margin: 0.5rem 0;
  padding: 0.4rem;
  display: flex;
  flex-direction: row !important;
  list-style: none;
  .MuiRadio-root {
    display: none;
  }
  label {
    margin: 0;
    flex: 1;
    padding: 0.2rem 0.5rem;
    z-index: 2;
    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .Mui-checked ~ span {
    color: ${white};
    transition: 0.25s ease-out;
  }

  .glider {
    position: absolute;
    display: flex;
    height: 1.8rem;
    width: calc(100% / 3 - 0.3rem);
    background-color: ${blue};
    z-index: 1;
    border-radius: 14px;
    transition: 0.25s ease-out;
    transform: ${(p) => computeTranslate(p.value)};
  }
`;

const visibleDestinationOptions: { value: SegmentTypes; label: string }[] = [
  { value: 'past', label: 'Past' },
  { value: 'future', label: 'Future' },
  { value: 'both', label: 'All' },
];

const VesselCourse = ({ vessel }: { vessel: Vessel }) => {
  const dispatch = useAppDispatch();
  const { getVesselJourney } = useContext(SidePanelFieldContext);
  const { getVesselRoute } = useContext(RouteCalculatorContext);
  const selectedPortCall = useSelector((state: RootState) =>
    selectSinglePortCall(state, vessel.id)
  );
  const selectedVesselRouteConfig = useSelector((state: RootState) =>
    selectSidePanelVesselRouteConfig(state, vessel.id)
  );

  const activeVesselRoute = useSelector((state: RootState) =>
    selectActiveVesselRouteById(state, vessel.id)
  );

  const { isInWizard } = useContext(SidePanelFieldContext);
  const [hasError, setHasError] = useState<string | undefined>();

  const activeDisplayType = useMemo(
    () => selectedVesselRouteConfig.displayType || 'all_sources',
    [selectedVesselRouteConfig]
  );

  const activeRouteSegment = useMemo(
    () => selectedVesselRouteConfig.segment || 'both',
    [selectedVesselRouteConfig]
  );

  const routeOptions: string[] = useMemo(() => {
    const { response } = selectedPortCall;
    if (response) {
      const grouped = groupBy(response.portCalls, (o) => o.type.toLowerCase());
      const options = Object.keys(grouped).map((each) => each.toLowerCase());
      options.push('all_sources');
      return options;
    }
    return [];
  }, [selectedPortCall]);

  const isLoading = useMemo(() => {
    if (isInWizard) return false;
    const { request } = selectedPortCall;
    return !request || request === 'pending';
  }, [selectedPortCall, isInWizard]);

  const journey = useMemo(
    () => getVesselJourney(vessel, selectedPortCall, selectedVesselRouteConfig),
    [vessel, selectedPortCall, selectedVesselRouteConfig, getVesselJourney]
  );

  useEffect(() => {
    if (isInWizard) return;
    const { request } = selectedPortCall;
    if (!request) {
      dispatch(fetchVesselPortCallsAsync({ vesselId: vessel.id }));
    }
    if (request === 'rejected') {
      setHasError('Vessel journey failed to load.');
    }
  }, [selectedPortCall, vessel, dispatch, isInWizard]);

  const handleNext = useCallback(async () => {
    if (isLoading) return;
    const currentIndex = routeOptions.findIndex((o) => o === activeDisplayType);
    const nextDisplayType =
      currentIndex === routeOptions.length - 1
        ? routeOptions[0]
        : routeOptions[currentIndex + 1];

    dispatch(
      setSidePanelRouteConfig({
        vesselId: vessel.id,
        config: {
          displayType: nextDisplayType,
          segment: activeRouteSegment,
        },
      })
    );
  }, [
    isLoading,
    activeDisplayType,
    activeRouteSegment,
    dispatch,
    routeOptions,
    vessel,
  ]);

  const handlePrevious = useCallback(async () => {
    if (isLoading) return;
    const currentIndex = routeOptions.findIndex((o) => o === activeDisplayType);
    const nextDisplayType =
      currentIndex === 0
        ? routeOptions[routeOptions.length - 1]
        : routeOptions[currentIndex - 1];

    dispatch(
      setSidePanelRouteConfig({
        vesselId: vessel.id,
        config: {
          displayType: nextDisplayType,
          segment: activeRouteSegment,
        },
      })
    );
  }, [
    vessel,
    activeDisplayType,
    activeRouteSegment,
    dispatch,
    isLoading,
    routeOptions,
  ]);

  const prepareRoute = useCallback(
    async ({
      activeDisplayType,
      activeRouteSegment,
      typeSegment,
      vessel,
    }: {
      activeDisplayType: string;
      activeRouteSegment: SegmentTypes;
      typeSegment: string;
      vessel: Vessel;
    }) => {
      const organisedRoute = await prepareSingleTypeRoute({
        displayType: activeDisplayType,
        segmentType: activeRouteSegment,
        getVesselRoute,
        portCalls: selectedPortCall.response?.portCalls || [],
        vessel,
      });
      dispatch(
        setCalculatedRoute({
          vesselId: vessel.id,
          type: typeSegment,
          route: organisedRoute,
        })
      );
    },
    [getVesselRoute, selectedPortCall, dispatch]
  );

  useEffect(() => {
    if (isInWizard) return;
    const typeSegment = `${activeDisplayType}-${activeRouteSegment}`;
    if (!activeVesselRoute?.[typeSegment]) {
      prepareRoute({
        typeSegment,
        activeDisplayType,
        activeRouteSegment,
        vessel,
      });
    }
  }, [
    activeDisplayType,
    activeRouteSegment,
    vessel,
    activeVesselRoute,
    prepareRoute,
    isInWizard,
  ]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      dispatch(
        setSidePanelRouteConfig({
          vesselId: vessel.id,
          config: {
            displayType: activeDisplayType,
            segment: value as SegmentTypes,
          },
        })
      );
    },
    [activeDisplayType, dispatch, vessel]
  );

  return (
    <CardContainer>
      <ActionHeader>
        <CardHeader>
          <h4>Journey</h4>
          {!isLoading && !hasError && (
            <ButtonGroup>
              <IconButton variant="secondary" onClick={handlePrevious}>
                <ArrowBackIosNewRoundedIcon />
              </IconButton>
              <Display
                $color={ICON_COLOR[activeDisplayType] || ICON_COLOR.DEFAULT}
                $textColor={TEXT_COLOR[activeDisplayType] || TEXT_COLOR.DEFAULT}
              >
                <p>
                  {formatPortCallType(activeDisplayType as PortCallTypesCommon)}
                </p>
              </Display>
              <IconButton variant="secondary" onClick={handleNext}>
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            </ButtonGroup>
          )}
        </CardHeader>

        <SegmentHeaderContainer>
          {!isLoading && !hasError && (
            <>
              <p>Visible Destinations</p>
              <FormControl component={RadioFormControl}>
                <RadioList
                  name="route-display-option"
                  value={activeRouteSegment}
                  onChange={handleChange}
                >
                  {visibleDestinationOptions.map((option, i) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={
                        <Radio
                          size="small"
                          sx={{
                            '&.Mui-checked': {
                              color: blue,
                            },
                          }}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                  <span className="glider" />
                </RadioList>
              </FormControl>
            </>
          )}
        </SegmentHeaderContainer>
      </ActionHeader>
      <RestrictedCardHeightContainer $loading={isLoading}>
        {isLoading ? (
          <Loader size={120} />
        ) : (
          <>
            {hasError && <ErrorText>{hasError}</ErrorText>}
            {!!journey.length &&
              journey.map((eachJourney, index) =>
                eachJourney.componentType === 'port' ? (
                  <JourneyPortCard
                    key={index}
                    journey={eachJourney as JourneyPortVisualCard}
                  />
                ) : (
                  <JourneyVesselCard
                    key="journey-vessel-card"
                    journey={eachJourney as JourneyVesselVisualCard}
                  />
                )
              )}
          </>
        )}
      </RestrictedCardHeightContainer>
      <SegmentContainer>
        <p>oldest</p>
        <FileDownloadRoundedIcon />
      </SegmentContainer>
    </CardContainer>
  );
};

export default memo(VesselCourse);
