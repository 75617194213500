import every from 'lodash/every';
import once from 'lodash/once';
import {
  memo,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import {
  useAppDispatch,
  useDebounce,
  useMobile,
  useModal,
  usePrevious,
  useReadOnlyPlanningData,
} from 'hooks';
import { secondsToDHM } from 'lib/common';
import { fadedGreen, orange, red, white } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { findFlightTrackAccess } from 'lib/common';
import { fetchPortHotels, storeUserSelectedPorts } from 'api/flotilla';
import { selectSettings } from 'redux/selectors';
import { setCCPanelHotels, updateColumnVisibility } from 'redux/actions';
import { HotelsResultPayload, RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { Port } from 'utils/types/crew-change-types';

import { CommonConfirmModal, Loader } from 'components/shared';
import { getColumnVisibility, getGridColumns } from './Header';
import Actions from '../Actions/Flights';
import Controls from '../../common/Controls';
import { NoRowsOverlay } from '../../common/TableItems';
import { headerStyles, TableWrapper } from '../../common';
import {
  fetchCrewFlights,
  getCurrentFilters,
  getBestFlights,
  getCompareableFlights,
  getFlightRowClassName,
  getFlightRows,
  getFlightsInitialColumnVisibility,
  replaceFlight,
  getLocodeKeyDetails,
  getFlightDelay,
  hasFareInfo,
} from '../../helpers';
import {
  ActiveFlight,
  DepartureInput,
  EmptyFlight,
  FetchStatusHandler,
  SelectInModal,
  ToggleConfirm,
} from '../../types';

const StyledTableWrapper = styled(TableWrapper)<{ $empty: boolean }>`
  .MuiDataGrid-virtualScrollerContent {
    ${({ $empty }) =>
      $empty &&
      `
      opacity: 0;
      height: 100%;
    `};
  }
  /* custom styling for additional cost cell */
  .delay {
    background: ${fadedGreen}40;
  }
  .medium-delay {
    background: ${orange};
    color: ${white};
  }
  .long-delay {
    background: ${red};
    color: ${white};
  }
`;

const INDIVIDUAL_CONFIRM_FLIGHT = 'confirmFlight';
const CONFIRM_ALL = 'confirmAll';

function FlightsTable(): JSX.Element {
  const dispatch = useAppDispatch();
  const hotelResults = useSelector(
    ({ crewChangeResources }: RootState) => crewChangeResources.hotelResults
  );
  const columnVisibility = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.columnVisibility
  );
  const {
    userInfo,
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);

  const apiRef = useGridApiRef();
  const {
    data,
    fetchStatus,
    filters: allFilters,
    planningData,
    tableState: { view },
    reportInfo: { incompletePlanId },
    updateReportInfo,
    updateFilters,
    updateFetchStatus,
    updatePlanningData,
  } = useContext(CCPanelContext);

  const { saveIncompletePlan } = useReadOnlyPlanningData();
  const isMobile = useMobile(BREAK_POINT_XS);
  const { modal, setModal } = useModal(null);
  const { initialized, progress } = fetchStatus;
  const { crew, ports } = planningData;
  const canTrackFlight = findFlightTrackAccess(userInfo);

  const [flights, setFlights] = useState<(ActiveFlight | EmptyFlight)[]>([]);

  const { flights: flightColumns } = columnVisibility || {};
  // check if all port flights are fetched
  const allFetched = useMemo(
    () => progress && every(progress, (value) => value === 1),
    [progress]
  );
  const { filters, locodeKey = ports[0].locode } = useMemo(
    () => getCurrentFilters(allFilters),
    [allFilters]
  );
  const portProgress = progress?.[locodeKey] || 0;
  const prevFilters = usePrevious(filters);
  const finishedFetching = !usePrevious(allFetched) && allFetched;

  // valid flights in the table
  const activeFlights = useMemo(
    () => flights.filter(({ flight }) => flight) as ActiveFlight[],
    [flights]
  );
  // calculate flights as the fetching progress updates
  const formattedFlights = useMemo(
    () => (filters ? getBestFlights(crew, filters) : []),
    [crew, filters, initialized, portProgress] // eslint-disable-line
  ) as (ActiveFlight | EmptyFlight)[];
  const toggledSelect = useMemo(() => {
    const { confirmed: prevConfirmedFlights } = prevFilters || {};
    return (
      prevConfirmedFlights &&
      prevConfirmedFlights.length !== filters?.confirmed?.length
    );
  }, [prevFilters, filters]);

  // handler updates fetch status with flights table update
  const statusHandler: FetchStatusHandler = useCallback(
    (source, uniqETA) => (port, reset) => {
      updateFetchStatus({
        type: 'UPDATE_FETCH_PROGRESS',
        payload: { source, uniqETA, port, reset },
      });
    },
    [updateFetchStatus]
  );

  const handleFetchFlightsOnLoad = useCallback(() => {
    const getFlights = fetchCrewFlights(statusHandler());
    getFlights(planningData).then(() => {
      updateFetchStatus({ type: 'INITIAL_FETCH_COMPLETED' });
    });
  }, []); // eslint-disable-line

  // fetch all port hotels at the start
  const handleFetchPortHotels = useCallback(async () => {
    const portLocodes = Object.keys(hotelResults || {});
    // filter out ports for which hotels already fetched
    const portsToFetch = ports.filter(
      ({ locode }) => !portLocodes.includes(locode)
    );
    if (portsToFetch.length) {
      const hotelPromises = portsToFetch.map(async ({ locode }) => {
        const { hotelSearchResult } = await fetchPortHotels(locode);
        return { [locode]: hotelSearchResult } as HotelsResultPayload;
      });
      const results = await Promise.all(hotelPromises);
      const payload = results.reduce((acc, item) => ({ ...acc, ...item }), {});
      dispatch(setCCPanelHotels(payload));
    }
  }, []); // eslint-disable-line

  const handleStoreUserSelectedPorts = async (ports: Port[]) => {
    const request = {
      pois: ports.map((port) => ({
        locode: port.locode,
        name: port.description,
      })),
    };
    const { success, message } = await storeUserSelectedPorts(request);
    if (!success) {
      console.log(message);
    }
  };

  const confirmInModal: SelectInModal = useCallback(
    (flight) => {
      // update the flights to reflect change in specific row
      const updatedFlights = getFlightRows(
        replaceFlight(flight, flights as ActiveFlight[])
      ) as ActiveFlight[];
      // find the formatted flight row
      const selectedFlightRow = updatedFlights.find(
        ({ id: customFlightId }) => customFlightId === flight.id
      );
      // update the confirmed flights in filters
      updateFilters({
        type: 'CONFIRM_FLIGHTS_IN_MODAL',
        payload: { locodeKey, flight: selectedFlightRow },
      });
      setFlights(updatedFlights);
    },
    [flights, locodeKey] // eslint-disable-line
  );

  const toggleConfirmAll = useCallback(
    (value: boolean) => {
      // check for long-delay for all flights
      const hasLongDelayFlight = flights.some(
        (flight) => getFlightDelay(flight) >= 3
      );
      // open confirm-all modal if there's a flight with long delay
      if (hasLongDelayFlight && value && modal?.type !== CONFIRM_ALL) {
        setModal(CONFIRM_ALL);
        return;
      }
      updateFilters({
        type: 'TOGGLE_CONFIRM_ALL',
        payload: { locodeKey, value, tableFlights: flights },
      });
    },
    [locodeKey, flights, modal, setModal, updateFilters]
  );

  useEffect(() => {
    handleFetchFlightsOnLoad();
    handleFetchPortHotels();
    handleStoreUserSelectedPorts(ports);

    if (!columnVisibility?.flights) {
      // Don't include `Track` column in visiblity options list
      const columnList = columns.filter(({ field }) => field !== 'tracked');
      // set the flight columns visibility
      dispatch(
        updateColumnVisibility({
          flights: getFlightsInitialColumnVisibility(columnList),
        })
      );
    }

    return () => {
      updateFetchStatus({ type: 'RESET_FETCH_STATUS' });
    };
  }, []); // eslint-disable-line

  // save incomplete plan when all flights are fetched
  useEffect(() => {
    if (finishedFetching || view === 'compare') {
      once(saveIncompletePlan)(incompletePlanId).then((planId) => {
        updateReportInfo({ type: 'incompletePlanId', payload: planId });
      });
    }
  }, [finishedFetching, view]); // eslint-disable-line

  useEffect(() => {
    // async update for flights in MUI rows, when toggling flight state, i.e - confirm <-> change
    if (toggledSelect) setTimeout(() => setFlights(formattedFlights));
    else setFlights(formattedFlights);
  }, [formattedFlights, toggledSelect]);

  useEffect(() => {
    const selectedFlights = flights.filter(({ id }) =>
      (filters?.confirmed || []).some((flight) => flight.id === id)
    );

    updatePlanningData((prevData) => ({
      ...prevData,
      flights: selectedFlights as ActiveFlight[],
    }));
  }, [flights, filters?.confirmed]); // eslint-disable-line

  // fetch flights when updating departure input
  const handleFetchForNewDeparture = useDebounce(
    (departureInput: DepartureInput) => {
      const { type: crewType, locodeKey } = departureInput;
      const { flightSource, portETA } = getLocodeKeyDetails(locodeKey);
      const getFlights = fetchCrewFlights(statusHandler(flightSource, portETA));
      const payload = { locodeKey, crewType };
      updateFetchStatus({ type: 'RESET_ON_DEPARTURE_UPDATE', payload });
      getFlights(planningData, departureInput).then(() => {
        updateFetchStatus({ type: 'DEPARTURE_UPDATE_COMPLETED', payload });
      });
    }
  );

  const toggleConfirm: ToggleConfirm = useCallback(
    (flight, confirmed) => {
      const delay = getFlightDelay(flight);
      // open confirm modal if flight is not confirmed & delay is longer than 3 days
      if (
        delay >= 3 &&
        !confirmed &&
        modal?.type !== INDIVIDUAL_CONFIRM_FLIGHT
      ) {
        setModal(INDIVIDUAL_CONFIRM_FLIGHT, { flight, delay });
        return;
      }
      // update filter when confirmed flights updated
      updateFilters({
        type: 'TOGGLE_CONFIRMED',
        payload: { locodeKey, flight, change: confirmed },
      });
    },
    [locodeKey, modal, setModal, updateFilters]
  );

  const trackFlight = useCallback(
    (flightId: string) => {
      updateFilters({ type: 'TRACK_FLIGHT', payload: { locodeKey, flightId } });
  }, [locodeKey]); // eslint-disable-line

  const columns = useMemo(() => {
    const funcProps = {
      onToggle: toggleConfirm,
      onTrack: trackFlight,
      selectInModal: confirmInModal,
    };
    return getGridColumns({
      commonProps: { hasFareInfo: hasFareInfo(activeFlights) },
      planningProps: { view },
      funcProps,
    });
  }, [view, activeFlights, toggleConfirm, trackFlight, confirmInModal]);
  const compareableFlights = useMemo(
    () => getCompareableFlights(flights),
    [flights]
  );
  const flightRows = view === 'filter' ? flights : compareableFlights;
  const actionStatus = useMemo(() => {
    const allCompareable = flights.length === compareableFlights.length;
    const allConfirmed = activeFlights.length
      ? data.length === activeFlights.length
      : undefined;
    return { allConfirmed, allCompareable };
  }, [flights, activeFlights, data.length, compareableFlights.length]);
  const actionProps = { actionStatus, toggleConfirmAll };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    if (modal.type === INDIVIDUAL_CONFIRM_FLIGHT) {
      const { flight, delay } = modal.data;
      const formattedDelay = secondsToDHM(delay * 24 * 3600, true);
      const description = `This flight has a longer delay than usual (${formattedDelay}). Are you sure you want to confirm it?`;

      return (
        <CommonConfirmModal
          title="Flight with Long Delay!"
          description={description}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            toggleConfirm(flight, false);
            setModal(null);
          }}
        />
      );
    }

    if (modal.type === CONFIRM_ALL) {
      const description =
        'One or more flights have delays longer than 3 days. Do you still want to confirm all flights?';

      return (
        <CommonConfirmModal
          title="Flight(s) with Long Delay!"
          description={description}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            toggleConfirmAll(true);
            setModal(null);
          }}
        />
      );
    }
  };

  return (
    <>
      <Controls disablePrev={!allFetched} disableNext={!allFetched}>
        <Actions
          view={view}
          loadFlights={handleFetchForNewDeparture}
          {...actionProps}
        />
      </Controls>

      <StyledTableWrapper $empty={!flights.length}>
        <DataGridPro
          apiRef={apiRef}
          rows={flightRows}
          columns={columns}
          columnVisibilityModel={{
            // show `tracked` column in compare view for users with flight-track access
            tracked: view === 'compare' && canTrackFlight,
            ...getColumnVisibility(isMobile, flightColumns),
          }}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnReorder
          hideFooter
          sx={headerStyles}
          density={isCompact ? 'compact' : 'standard'}
          getRowClassName={getFlightRowClassName({
            ...fetchStatus,
            portProgress,
          })}
          components={{
            NoRowsOverlay,
            LoadingOverlay: () => <Loader size={150} />,
          }}
          componentsProps={{
            noRowsOverlay: { type: 'flights' },
          }}
        />
      </StyledTableWrapper>

      {renderModal()}
    </>
  );
}

export default memo(FlightsTable);
