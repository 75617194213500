import { Formik, Form } from 'formik';

import { TextField } from 'components/shared/forms';
import styled from 'styled-components/macro';

import { gray90, gray70, gray20, white, yellowOrange } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';

import { Section, Text, Title as Header } from '../common';
import { ActionButton } from '../common';
import { UserInfo } from 'utils/types';

import { showToaster } from 'lib/toaster';
import { validateValues } from 'utils/validations';
import { magicLinkResetPassword } from 'api/flotilla';
import { MagicLinkResetPasswordValues } from 'utils/types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { magicLinkResetPasswordSchema } from 'utils/validation/magin-link';

const Wrapper = styled.div`
  padding: auto 0.5rem;

  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input {
    font-size: 0.9rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: ${gray70} !important;
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: bold;
  height: 100%;
  position: relative;
  align-items: flex-start;
  top: 11px;
`;

const InputWrapper = styled.div`
  width: 70% !important;
  align-items: center;
  display: grid;
  grid-template-columns: 1.6fr 4fr;
  grid-gap: 10px;
  margin-bottom: 0.75rem;
  width: 100%;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90% !important;
    grid-template-columns: 1.33fr 3fr;
  }
`;

const StyledTextField = styled(TextField)<{ disabled: boolean }>`
  width: 100%;
  background-color: ${({ disabled }) => (disabled ? gray20 : '')};
  outline: none;
`;

interface IProps {
  initialValues: UserInfo | undefined;
  magicLink?: string;
}

function Security({ magicLink, initialValues }: IProps) {
  const [code, SetCode] = useState(magicLink);
  const loginEmail = initialValues?.loginEmail || '';
  const [passwordRequestTimer, setPasswordRequestTimer] = useState<number>(0);
  const initialFormValues = magicLink
    ? {
        newPassword: '',
        retypePassword: '',
      }
    : {
        oldPassword: '',
        newPassword: '',
        retypePassword: '',
      };

  const handleSubmit = async (
    { oldPassword, newPassword }: MagicLinkResetPasswordValues,
    { resetForm }: any
  ) => {
    const body = code
      ? { magicLinkToken: code, newPassword }
      : {
          oldPassword,
          newPassword,
        };
    const { success, message } = await magicLinkResetPassword(body);
    if (success) {
      localStorage.setItem(
        'reset-password-request-expire',
        moment().add(180, 'second').toISOString()
      );
      setPasswordRequestTimer(180);
      showToaster({
        message: 'Password updated successfully.',
        type: 'success',
      });
      resetForm();
    } else {
      if (message === 'Link has expired.') SetCode('');
      showToaster({
        message,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    const requestExpiry = localStorage.getItem('reset-password-request-expire');
    if (!requestExpiry) return;
    if (moment(requestExpiry).isValid()) {
      const remaining = moment().diff(moment(requestExpiry), 'seconds');
      if (remaining >= -180 && remaining < 0) {
        setPasswordRequestTimer(-remaining);
      } else {
        localStorage.removeItem('forget-password-request-expire');
      }
    }
  }, []);

  useEffect(() => {
    const handleTimer = setTimeout(() => {
      if (passwordRequestTimer > 0) {
        setPasswordRequestTimer(passwordRequestTimer - 1);
      }
    }, 1000);
    return () => {
      clearTimeout(handleTimer);
    };
  }, [passwordRequestTimer]);

  return (
    <Wrapper>
      <Section>
        <Formik
          onSubmit={handleSubmit}
          validate={validateValues<MagicLinkResetPasswordValues>(
            magicLinkResetPasswordSchema
          )}
          initialValues={initialFormValues}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form>
              <HeaderWrapper>
                <Header data-id="settings_security-title">
                  Change Password
                </Header>
                {code && (
                  <Text style={{ color: yellowOrange }}>
                    Incase you have forgotten your password, please consider
                    resetting.
                  </Text>
                )}
              </HeaderWrapper>

              <InputWrapper>
                <Title>
                  Current<span>:</span>
                </Title>
                <StyledTextField
                  size="small"
                  placeholder="Enter Current Password"
                  type="password"
                  disabled={
                    loginEmail && !code && !passwordRequestTimer ? false : true
                  }
                  id="oldPassword"
                />
              </InputWrapper>

              <InputWrapper>
                <Title>
                  New<span>:</span>
                </Title>
                <StyledTextField
                  required
                  size="small"
                  placeholder="Enter New Password"
                  type="password"
                  disabled={loginEmail && !passwordRequestTimer ? false : true}
                  id="newPassword"
                />
              </InputWrapper>

              <InputWrapper>
                <Title>
                  Retype New<span>:</span>
                </Title>
                <StyledTextField
                  required
                  size="small"
                  placeholder="Retype New Password"
                  type="password"
                  disabled={loginEmail && !passwordRequestTimer ? false : true}
                  id="retypePassword"
                />
              </InputWrapper>

              {passwordRequestTimer <= 0 ? (
                <ActionButton
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  type="submit"
                  style={{ marginTop: '1rem' }}
                >
                  Save Changes
                </ActionButton>
              ) : (
                <ActionButton
                  disabled
                  style={{ backgroundColor: gray90, color: white }}
                >
                  Wait for ({passwordRequestTimer})s before next Reset
                </ActionButton>
              )}
            </Form>
          )}
        </Formik>
      </Section>
    </Wrapper>
  );
}

export default Security;
