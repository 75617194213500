import findKey from 'lodash/findKey';
import uniqBy from 'lodash/uniqBy';
import { RECENT_SEARCHES_KEY } from 'lib/constants';

import {
  FlotillaSearchOption,
  Nullable,
  RecentSearchContent,
  RecentSearches,
  RecentSearchType,
  SearchItem,
} from 'redux/types';

const getFlotillaSearchItems = (newItem: string) =>
  newItem.split(':').map((str) => str.trim());

const getFlotillaRecentSearches = (
  existingItems: FlotillaSearchOption[] | undefined,
  newItem: FlotillaSearchOption
) => {
  const [searchCommand] = getFlotillaSearchItems(newItem.inputStr);
  // replace an existing filter/category type with the new one
  // otherwise, show the latest 3
  const filteredItems = (existingItems || []).filter(({ inputStr }) => {
    const command = getFlotillaSearchItems(inputStr)[0];
    return command !== searchCommand;
  });
  return [newItem, ...filteredItems].slice(0, 3);
};

export const formatRecentSearches = (
  state: RecentSearches | null,
  payload: RecentSearchContent
) => {
  const type = findKey(payload) as RecentSearchType;
  const newItem = payload[type];
  let newSearchItems: SearchItem[] = [];

  switch (type) {
    case 'flotillaSearch':
      newSearchItems = getFlotillaRecentSearches(
        state?.[type] as FlotillaSearchOption[],
        newItem as FlotillaSearchOption
      );
      break;

    case 'airport':
    case 'crewNation':
      newSearchItems = newItem
        ? uniqBy(
            [newItem, ...(state?.[type] || [])],
            type === 'airport' ? 'text' : 'name'
          ).slice(0, 3)
        : state?.[type] || [];
      break;

    default:
      break;
  }

  return {
    ...(state || {}),
    [type]: newSearchItems,
  };
};

// sanitize initial state to avoid data type changes in localStorage
export const sanitizeInitialState = (): RecentSearches | null => {
  const savedState = localStorage.getItem(RECENT_SEARCHES_KEY);
  const recentSearches: Nullable<RecentSearches> = savedState
    ? JSON.parse(savedState)
    : null;

  if (!recentSearches) {
    return null;
  }

  const { flotillaSearch: recentFlotillaSearches = [] } = recentSearches;
  return {
    ...recentSearches,
    ...(recentFlotillaSearches.length
      ? {
          // filters out non-string flotilla searches stored
          flotillaSearch: recentFlotillaSearches.filter(
            (item) =>
              typeof item !== 'string' &&
              // filters out previous flight search command
              !(item as FlotillaSearchOption).inputStr.includes('flights:')
          ),
        }
      : {}),
  };
};
