import { MouseEvent, useState, lazy, Suspense, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Formik, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import TextField from '../..//shared/forms/TextField';
import { Button, ButtonV2, GreywingLogo } from '../../shared';
import { Text } from '../..//shared/Typography';
import MsalButton from './MsalButton';

import { useAppDispatch } from 'hooks';
import { setUserAuth } from 'redux/actions';

import { TRACK_USER_LOGIN } from 'utils/analytics/constants';
import { handleLogin, setSessionKeys } from 'utils/handle-login';
import { validateValues } from 'utils/validations';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { blue, white } from 'lib/colors';
import { trackUserAction } from 'lib/amplitude';
import { UserLoginValues } from 'utils/types';
import { LoginSchema } from '../validation';

const InputAdornment = lazy(() => import('@mui/material/InputAdornment'));
const IconButton = lazy(() => import('@mui/material/IconButton'));
const VisibilityIcon = lazy(() => import('@mui/icons-material/Visibility'));
const VisibilityOffIcon = lazy(
  () => import('@mui/icons-material/VisibilityOff')
);

const StyledContainer = styled(Paper)`
  width: 100%;
  width: 100%;
  max-width: 350px;
  max-height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 1rem;
    a {
      color: ${blue};
      font-weight: 400;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    max-width: unset;
    max-height: unset;
    height: 95%;
  }
`;

const SubmitButton = styled(Button)`
  margin-right: 0;
  margin-bottom: 1rem;
  height: 40px;
  font-size: 1rem;
`;

const SignUpButton = styled(Link)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  color: ${white};
  width: 12rem;
  height: 40px;
  font-size: 1rem;
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 200% auto;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(
    to right,
    ${blue} 0%,
    #bd91ff 50%,
    ${blue} 100%
  );
  transition: 0.5s;
  &:hover {
    background-position: right center;
  }
`;

const StyledGWLogo = styled(GreywingLogo)`
  margin: 30px auto;
`;

const msalSSOisEnabled = process.env.REACT_APP_DISABLE_MSAL_SSO !== 'true';

const StyledIconButton = styled(IconButton)`
  svg {
    font-size: 20px !important;
    cursor: pointer;
  }
`;

// eslint-disable-next-line
const LoginForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isLocalBackend, setIsLocalBackend] = useState<boolean>(false);

  useEffect(() => {
    try {
      if (localStorage.getItem('API_URL')?.includes('http://localhost:')) {
        setIsLocalBackend(true);
      }
    } catch (error) {}
  }, []);

  const handleReset = () => {
    localStorage.removeItem('API_URL');
    window.location.reload();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const handleSubmit = async (values: UserLoginValues) => {
    setError('');
    const { email, password } = values;
    const loggedInTime = new Date().toISOString();
    try {
      const response = await handleLogin({
        email,
        password,
      });
      if (!response) {
        setError('No valid response');
        return;
      }
      const { success, message, authPacket } = response;

      if (success && authPacket) {
        setSessionKeys(authPacket);
        // push to root route after store update
        dispatch(setUserAuth(true));
        // track user login with login time
        trackUserAction(TRACK_USER_LOGIN, 'click', {
          email,
          time: loggedInTime,
        });
      } else if (
        message ===
        'Your account is pending our review. We will notify you when it is approved. Thank you for your patience!'
      ) {
        navigate('/register/complete');
      } else if (message === 'Please verify your email first.') {
        navigate(`/register/verify?email=${encodeURIComponent(email)}`);
      } else {
        setError(message);
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  return (
    <StyledContainer tabIndex={-1}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleSubmit}
        validate={validateValues<UserLoginValues>(LoginSchema)}
      >
        {({ isSubmitting }) => (
          <StyledForm>
            <StyledGWLogo />
            {error && (
              <Text variant="error" align="center">
                {error}
              </Text>
            )}
            <TextField
              required
              sx={{ marginBottom: 2 }}
              id="email"
              name="email"
              label="Email address"
              size="small"
            />

            <TextField
              required
              sx={{ marginBottom: 2 }}
              id="password"
              name="password"
              label="Password"
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <Suspense fallback={<></>}>
                    <InputAdornment position="end">
                      <StyledIconButton
                        type="button"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </StyledIconButton>
                    </InputAdornment>
                  </Suspense>
                ),
              }}
            />

            <SubmitButton
              className="e2e_login"
              variant="primary"
              onClick={() => {}} // let formik handle the submission
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              Sign In
            </SubmitButton>
            <Text align="center">
              <Link to="/auth/forgot-password">Forgotten Password?</Link>
            </Text>
            <Divider />
            {msalSSOisEnabled && <MsalButton />}
            <SignUpButton to="/register">Create New Account</SignUpButton>
            {isLocalBackend && (
              <ButtonV2
                variant="dark-blue"
                onClick={handleReset}
                style={{
                  marginTop: '1rem',
                }}
              >
                Reset API connection
              </ButtonV2>
            )}
          </StyledForm>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default LoginForm;
