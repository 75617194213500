import {
  object,
  string,
  pattern,
  Struct,
  define,
  is,
  optional,
  dynamic,
  literal,
} from 'superstruct';

const password = () => pattern(string(), /^(?!.* ).{8,}$/);
const message = <T>(struct: Struct<T, any>, message: string): Struct<T, any> =>
  define('message', (value) => (is(value, struct) ? true : message));

export const magicLinkResetPasswordSchema = object({
  oldPassword: optional(message(password(), 'Invalid Password')),
  newPassword: message(
    password(),
    'Password must contain a minimum of 8 characters and must not contain any empty spaces.'
  ),
  retypePassword: dynamic((value, { branch }) =>
    message(literal(branch[0]['newPassword']), "Passsword doesn't match")
  ),
});
