import debounce from 'lodash/debounce';
import { useLayoutEffect, useState } from 'react';

type SizeTuple = [number, number]; // [width, height]

// gives resized window dimensions
function useWindowSize(): SizeTuple {
  const [size, setSize] = useState<SizeTuple>([
    window.innerWidth,
    window.innerHeight,
  ]);

  useLayoutEffect(() => {
    const resize = debounce(
      () => setSize([window.innerWidth, window.innerHeight]),
      1000
    );

    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return size;
}

export default useWindowSize;
