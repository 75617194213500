import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';

import {
  CopilotState,
  CopilotCategories,
  RootState,
  CopilotRestrictions,
  CopilotView,
} from '../types';
import {
  AssistedConversationWithExtra,
  AssistedCrewChangeAgentConvoResp,
  ListAssistedCrewChangeConvosScope,
  NewSeaGptChatBlock,
  SeaGPTChatBlock,
  SeaGPTChatMessage,
  SeaGPTThread,
} from 'components/SeaGPT/type';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_SEAGPT_DESELECT_COMPARE,
  TRACK_SEAGPT_SELECT_COMPARE,
} from 'utils/analytics/constants';
import {
  fetchAssistedCrewChangeConvoEmailAsync,
  fetchAssistedCrewChangeConvosAsync,
  processAssistedCrewChangeUpdate,
} from 'redux/thunks/sea-gpt';

const getFetchCostSettings = () => {
  try {
    const copilotFetchCosts = localStorage.getItem('copilotFetchCosts');
    if (
      copilotFetchCosts &&
      typeof JSON.parse(copilotFetchCosts) === 'boolean'
    ) {
      return JSON.parse(copilotFetchCosts);
    }
    return true;
  } catch (error) {
    return true;
  }
};

export const initialCopilot: CopilotState = {
  view: 'mini',
  active: false,
  category: 'general',
  context: null,
  threads: {},
  emails: {
    shouldRefetchAllPages: false,
    view: 'inbox',
    activeEmailConvoId: null,
    isLoading: false,
    inboxLastScrollHeight: null,
    convoKeys: {}, // simplified result
    convoEmailKeys: {}, // detailed result
    queryConvoKeys: {},
  },
  isFlightLoading: false,
  activeThread: null,
  blocks: [],
  showAudioFile: false,
  fetchCosts: getFetchCostSettings(),
  inputValue: '',
  showThreadHistory: false,
  emailControls: {
    search: '',
    comparedId: [],
    isCompare: false,
    companyFilter: 'COMPANY',
  },
  autoSubmit: false,
};

const copilotSlice = createSlice({
  name: 'copilot',
  initialState: initialCopilot,
  reducers: {
    updateSeaGPTFlightLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isFlightLoading: action.payload,
    }),
    clearSeaGptEmailPages: (state, action: PayloadAction<boolean>) => ({
      ...state,
      emails: {
        ...state.emails,
        shouldRefetchAllPages: action.payload,
        convoKeys: {},
        queryConvoKeys: {},
      },
    }),
    setSeaGptConvoHasUpdate: (
      state,
      action: PayloadAction<{ id: number; data: AssistedConversationWithExtra }>
    ) => ({
      ...state,
      emails: {
        ...state.emails,
        convoKeys: {
          ...state.emails.convoKeys,
          [action.payload.id]: action.payload.data,
        },
      },
    }),
    updateSeaGPTConvoEmail: (
      state,
      action: PayloadAction<AssistedCrewChangeAgentConvoResp>
    ) => ({
      ...state,
      emails: {
        ...state.emails,
        convoEmailKeys: {
          ...state.emails.convoEmailKeys,
          [action.payload.conversation.id]: action.payload,
        },
      },
    }),
    updateSeaGPTEmailView: (
      state,
      action: PayloadAction<{
        view: 'inbox' | 'emailConvo';
        activeEmailConvoId?: number | null;
        hasUpdate?: boolean;
        lastScrollHeight?: number | null;
      }>
    ) => ({
      ...state,
      emails: {
        ...state.emails,
        view: action.payload.view,
        inboxLastScrollHeight:
          action.payload.lastScrollHeight || state.emails.inboxLastScrollHeight,
        activeEmailConvoId: action.payload.activeEmailConvoId || null,
        ...(action.payload.hasUpdate && action.payload.activeEmailConvoId
          ? {
              convoKeys: {
                ...state.emails.convoKeys,
                [action.payload.activeEmailConvoId]: {
                  ...state.emails.convoKeys[action.payload.activeEmailConvoId],
                  hasUpdate: false,
                },
              },
            }
          : {}),
      },
    }),
    updateSeaGPTThreadMeta: (
      state,
      action: PayloadAction<Partial<SeaGPTThread> & { id: string }>
    ) => ({
      ...state,
      threads: {
        ...state.threads,
        [action.payload.id]: {
          ...state.threads[action.payload.id],
          ...action.payload,
        },
      },
    }),
    selectCopilotConversation: (state, action: PayloadAction<string>) => ({
      ...state,
      activeThread: action.payload,
    }),
    startNewSeaGPTThread: (state, action: PayloadAction<SeaGPTThread>) => ({
      ...state,
      activeThread: action.payload.id,
      threads: {
        ...state.threads,
        [action.payload.id]: action.payload,
      },
    }),
    toggleThreadHistory: (state) => ({
      ...state,
      showThreadHistory: !state.showThreadHistory,
    }),
    initStoreThreadHistory: (
      state,
      action: PayloadAction<{ [key: string]: SeaGPTThread }>
    ) => {
      const sorted = sortBy(Object.values(action.payload), 'createdAt');
      return {
        ...state,
        threads: action.payload,
        activeThread: sorted[sorted.length - 1]?.id || null,
      };
    },
    toggleCopilotAudio: (state) => ({
      ...state,
      showAudioFile: !state.showAudioFile,
    }),
    toggleCopilotFetchCosts: (state) => ({
      ...state,
      fetchCosts: !state.fetchCosts,
    }),
    updateCopilotContext: (
      state,
      action: PayloadAction<null | CopilotRestrictions>
    ) => ({
      ...state,
      context: action.payload,
    }),
    updateCopilotCategory: (
      state,
      action: PayloadAction<CopilotCategories>
    ) => ({
      ...state,
      category: action.payload,
    }),
    startCopilot: (
      state,
      action: PayloadAction<{
        view: CopilotView;
        category?: CopilotCategories;
        context?: null | CopilotRestrictions;
        autoSubmit?: boolean;
      }>
    ) => ({
      ...state,
      view: action.payload.view,
      active: true,
      category: action.payload.category || state.category,
      autoSubmit: action.payload.autoSubmit || false,
      context:
        action.payload.context !== undefined
          ? action.payload.context
          : state.context,
    }),
    toggleCopilot: (state, action: PayloadAction<CopilotView | undefined>) => ({
      ...state,
      view: action.payload || state.view,
      category: !state.active ? 'general' : state.category,
      active: !state.active,
      context: null,
    }),
    saveGptBlock: (
      state,
      action: PayloadAction<SeaGPTChatBlock | NewSeaGptChatBlock>
    ) => ({
      ...state,
      threads: {
        ...state.threads,
        [action.payload?.threadId || state.activeThread!]: {
          ...(state.threads[state.activeThread!] || {}),
          blocks: [
            ...state.threads[state.activeThread!].blocks.filter(
              (o) => o.id !== action.payload.id
            ),
            action.payload,
          ],
        },
      },
    }),
    // Update an existing message
    saveGptMessage: (
      state,
      action: PayloadAction<{ blockId: string; message: SeaGPTChatMessage }>
    ) => ({
      ...state,
      threads: {
        ...state.threads,
        [state.activeThread!]: {
          ...state.threads[state.activeThread!],
          blocks: state.threads[state.activeThread!]?.blocks.map((block) => {
            if (block.id !== action.payload.blockId) return block;
            return {
              ...block,
              messages: block.messages.map((oldMessage) => {
                if (oldMessage.id !== action.payload.message.id)
                  return oldMessage;
                return action.payload.message;
              }),
            };
          }),
        },
      },
    }),
    toggleCopilotView: (
      state,
      action: PayloadAction<CopilotView | undefined>
    ) => ({
      ...state,
      view: action.payload || state.view,
    }),
    saveCopilotInputValue: (state, action: PayloadAction<string>) => ({
      ...state,
      inputValue: action.payload,
    }),
    updateCopilotEmailFilterValue: (state, action: PayloadAction<string>) => ({
      ...state,
      emailControls: {
        ...state.emailControls,
        search: action.payload,
      },
    }),
    updateCopilotEmailCompareState: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      emailControls: {
        ...state.emailControls,
        isCompare: action.payload,
      },
    }),
    updateCopilotEmailCompanyFilter: (
      state,
      action: PayloadAction<ListAssistedCrewChangeConvosScope>
    ) => ({
      ...state,
      emailControls: {
        ...state.emailControls,
        companyFilter: action.payload,
      },
    }),
    updateCopilotEmailCompareId: (state, action: PayloadAction<number>) => {
      const current = state.emailControls.comparedId;
      const updated = current.includes(action.payload)
        ? current.filter((o) => o !== action.payload)
        : [...current, action.payload];
      if (current.includes(action.payload)) {
        trackUserAction(
          TRACK_SEAGPT_DESELECT_COMPARE(
            state.view === 'mini' ? 'sea-gpt-modal-small' : 'sea-gpt-modal'
          ),
          'click',
          {
            id: action.payload,
          }
        );
      } else {
        trackUserAction(
          TRACK_SEAGPT_SELECT_COMPARE(
            state.view === 'mini' ? 'sea-gpt-modal-small' : 'sea-gpt-modal'
          ),
          'click',
          {
            id: action.payload,
          }
        );
      }
      return {
        ...state,
        emailControls: {
          ...state.emailControls,
          comparedId: updated,
        },
      };
    },
    updateCopilotEmailCompareIds: (state, action: PayloadAction<number[]>) => ({
      ...state,
      emailControls: {
        ...state.emailControls,
        comparedId: action.payload,
      },
    }),
    updateCopilotAutoSubmit: (state, action: PayloadAction<boolean>) => ({
      ...state,
      autoSubmit: action.payload,
    }),
    clearCopilotHistory: () => initialCopilot,
  },
  extraReducers(builder) {
    builder.addCase(fetchAssistedCrewChangeConvosAsync.pending, (state) => ({
      ...state,
      emails: {
        ...state.emails,
        isLoading: true,
      },
    }));
    builder.addCase(
      fetchAssistedCrewChangeConvosAsync.fulfilled,
      (
        state,
        action: PayloadAction<{
          convoKeys: { [key: string]: AssistedConversationWithExtra };
          queryPageKey: string;
          rowIds: number[];
          nextPageToken: string;
          totalCount: number;
        } | null>
      ) => ({
        ...state,
        emails: {
          ...state.emails,
          isLoading: false,
          ...(action.payload
            ? {
                convoKeys: {
                  ...state.emails.convoKeys,
                  ...action.payload.convoKeys,
                },
                queryConvoKeys: {
                  ...state.emails.queryConvoKeys,
                  [action.payload.queryPageKey]: {
                    rowIds: uniq([
                      ...(state.emails.queryConvoKeys?.[
                        action.payload.queryPageKey
                      ]?.rowIds || []),
                      ...action.payload.rowIds,
                    ]),
                    nextPageToken: action.payload.nextPageToken,
                    totalCount: action.payload.totalCount,
                  },
                },
              }
            : {}),
        },
      })
    );
    builder.addCase(
      fetchAssistedCrewChangeConvoEmailAsync.pending,
      (state) => ({
        ...state,
        emails: {
          ...state.emails,
          isLoading: true,
        },
      })
    );
    builder.addCase(
      fetchAssistedCrewChangeConvoEmailAsync.fulfilled,
      (
        state,
        action: PayloadAction<AssistedCrewChangeAgentConvoResp | null>
      ) => ({
        ...state,
        emails: {
          ...state.emails,
          isLoading: false,
          ...(action.payload
            ? {
                convoEmailKeys: {
                  ...state.emails.convoEmailKeys,
                  [action.payload.conversation.id]: action.payload,
                },
              }
            : {}),
        },
      })
    );
    builder.addCase(
      processAssistedCrewChangeUpdate.fulfilled,
      (state, action: PayloadAction<boolean>) => ({
        ...state,
        emails: {
          ...state.emails,
          shouldRefetchAllPages: action.payload,
        },
      })
    );
  },
});

/* ----- selectors -----*/

export const selectCopilot = ({ copilot }: RootState) => copilot;

export const selectSeaGPTActiveThread = createSelector(
  (state: RootState) => state.copilot.activeThread,
  (state: RootState) => state.copilot.threads,
  (active, threads) => (active ? threads[active] : null)
);

export const selectSeaGPTThreads = createSelector(
  (state: RootState) => state.copilot.threads,
  ({ emailUpdates, ...threads }) => {
    const [pinned, notPinned] = partition(Object.values(threads), 'pinnedOn');
    return [
      ...sortBy(pinned, 'pinnedOn').reverse(),
      ...sortBy(notPinned, 'createdAt').reverse(),
    ];
  }
);

export const selectActiveEmailConvo = createSelector(
  [
    ({ copilot }: RootState) => copilot.emails.isLoading,
    ({ copilot }: RootState) => copilot.emails.activeEmailConvoId,
    ({ copilot }: RootState) => copilot.emails.convoEmailKeys,
  ],
  (isLoading, emailConvoId, convoEmailKeys) => ({
    isLoading,
    activeEmailConvo: emailConvoId ? convoEmailKeys[emailConvoId] : null,
  })
);

export const selectCurrentEmailQuerykey = createSelector(
  [
    ({ copilot }: RootState) => copilot.emailControls.companyFilter,
    ({ copilot }: RootState) => copilot.emailControls.search,
  ],
  (filter, search) => `${filter}${search ? `-${search}` : ''}`
);

export const selectCurrentInboxConvoPaginationData = createSelector(
  [
    selectCurrentEmailQuerykey,
    ({ copilot }: RootState) => copilot.emails.queryConvoKeys,
  ],
  (currentQueryKey, queryConvoKeys) => ({
    totalCount: queryConvoKeys[currentQueryKey]?.totalCount || 0,
    nextPageToken: queryConvoKeys[currentQueryKey]?.nextPageToken || '',
  })
);

export const selectCurrentInboxConvos = createSelector(
  [
    selectCurrentEmailQuerykey,
    ({ copilot }: RootState) => copilot.emails.queryConvoKeys,
    ({ copilot }: RootState) => copilot.emails.convoKeys,
  ],
  (currentQueryKey, queryConvoKeys, convoKeys) => {
    if (!queryConvoKeys[currentQueryKey]) {
      // meaning that there hasn't been any search for this yet
      return null;
    }
    return queryConvoKeys[currentQueryKey].rowIds
      .map((o) => convoKeys[o])
      .filter(Boolean);
  }
);

/* ----- actions -----*/

export const {
  toggleCopilot,
  startCopilot,
  updateCopilotCategory,
  initStoreThreadHistory,
  updateCopilotContext,
  toggleCopilotFetchCosts,
  saveGptBlock,
  saveGptMessage,
  toggleCopilotView,
  toggleCopilotAudio,
  saveCopilotInputValue,
  clearCopilotHistory,
  toggleThreadHistory,
  startNewSeaGPTThread,
  selectCopilotConversation,
  updateSeaGPTThreadMeta,
  updateCopilotEmailFilterValue,
  updateCopilotEmailCompareId,
  updateCopilotEmailCompareIds,
  updateCopilotEmailCompareState,
  updateCopilotEmailCompanyFilter,
  updateCopilotAutoSubmit,
  updateSeaGPTEmailView,
  updateSeaGPTConvoEmail,
  setSeaGptConvoHasUpdate,
  clearSeaGptEmailPages,
  updateSeaGPTFlightLoading,
} = copilotSlice.actions;

/* ----- reducer -----*/

export default copilotSlice.reducer;
