import styled from 'styled-components/macro';

import LottieAnimation from './LottieAnimation';

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;
  line-spacing: 0.05rem;
  margin: 2rem auto;
  text-align: center;
`;

const AnimationContainer = styled.div`
  align-self: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 20em;
`;

type Props = { name: string; title?: string; loop?: boolean };

function SuccessAnimation({ name, title, loop }: Props) {
  return (
    <AnimationContainer id="completion-animation">
      <LottieAnimation name={name} loop={loop} width="100%" />
      {title && <Title data-id="e2e_success-modal-title">{title}</Title>}
    </AnimationContainer>
  );
}

export default SuccessAnimation;
