import moment from 'moment-timezone';

export function getEarliestDate(dates: (string | undefined | null)[]) {
  const filteredDates = dates.filter(Boolean);
  return (filteredDates as string[]).sort(
    (a, b) => new Date(a).getTime() - new Date(b).getTime()
  )[0];
}

export function convertDateIntoUTC(
  date: string | Date,
  timezone: string
): string {
  if (String(date).length !== 24) return String(date);
  const stringiedDate = String(date);
  const reformatTime = `${stringiedDate.substring(
    0,
    10
  )} ${stringiedDate.substring(11, 19)}`;
  const converted = moment.tz(reformatTime, timezone).toISOString();
  return converted;
}
