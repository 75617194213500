import { useState } from 'react';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { darkBlue, white } from 'lib/colors';
import { SETUP_WIZARD_STEP } from 'lib/constants';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { toggleWizardVisiblity } from 'redux/actions';

import {
  Title,
  Text,
  Divider,
  Section,
  ActionButton,
  CustomCheckbox,
} from '../common';
import { DevicesSettingsProps } from '../types';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const IncompleteWrapper = styled.div`
  padding: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: ${darkBlue};
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-right: 1rem;
  }
`;

const IconWrapper = styled.div`
  padding-right: 0.75rem;
`;

const StyledWarningIcon = styled(WarningRoundedIcon)`
  color: ${white};
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${white};
`;

const Content = styled(Text)`
  color: ${white};
`;

const StepText = styled(Text)`
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: ${white};
`;

function Devices({
  initialValue,
  updateSettings,
  closeModal,
}: DevicesSettingsProps) {
  const dispatch = useAppDispatch();
  const [canShare, setCanShare] = useState(initialValue);
  const setupWizardStep = window.localStorage.getItem(SETUP_WIZARD_STEP);

  const handleCanShare = () => {
    const updatedState = !canShare;

    setCanShare(updatedState);
    updateSettings((prevSettings) => ({
      ...prevSettings,
      crossDevices: updatedState,
    }));
  };

  const handleTriggerSetup = () => {
    dispatch(toggleWizardVisiblity(true));
    closeModal();
  };

  return (
    <>
      <Section>
        <Title>Cross-devices</Title>
        <Text>
          Would like to share your flotilla configuration across all of your
          devices?
        </Text>
        <CheckboxWrapper>
          <CustomCheckbox
            label="Yes"
            checked={canShare}
            onClick={handleCanShare}
          />
          <CustomCheckbox
            label="No"
            checked={!canShare}
            onClick={handleCanShare}
          />
        </CheckboxWrapper>
      </Section>

      <Divider />

      <Section>
        <Title>Flotilla</Title>

        {setupWizardStep ? (
          <IncompleteWrapper>
            <IconWrapper>
              <StyledWarningIcon />
            </IconWrapper>
            <div>
              <Content>
                It looks like you haven't completed your flotilla setup
              </Content>
              <ButtonWrapper>
                <ActionButton
                  onClick={handleTriggerSetup}
                  style={{ marginTop: '1rem' }}
                >
                  Continue Setup
                </ActionButton>
                <StepText>Step {parseInt(setupWizardStep)} of 6</StepText>
              </ButtonWrapper>
            </div>
          </IncompleteWrapper>
        ) : (
          <>
            <Text>You have completed setting up Flotilla</Text>
            <ActionButton
              onClick={handleTriggerSetup}
              style={{ marginTop: '1rem' }}
            >
              Reconfigure Flotilla
            </ActionButton>
          </>
        )}
      </Section>
    </>
  );
}

export default Devices;
