import { memo } from 'react';
import styled from 'styled-components/macro';

import { darkBlue } from 'lib/colors';
import MapboxPopup from 'components/shared/MapboxPopup';
import Details from './PortDetails';
import { PopupPort } from '../types';

const Wrapper = styled.div<{
  $selected?: boolean;
  $hovered?: boolean;
  $focused?: boolean;
}>`
  padding: 0.5rem 0.75rem;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.2rem;
  border-radius: 8px;
  transition: all 150ms ease;
  &:hover {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  ${({ $selected }) =>
    $selected &&
    `
    background-color: ${darkBlue};
    color: #fff;
    .mapboxgl-popup-tip {
      background-color: ${darkBlue} !important;
    }
  `};
  ${({ $focused }) =>
    $focused &&
    `
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
  `};

  &:after {
    content: '';
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: ${({ $selected }) =>
      $selected ? `10px solid ${darkBlue}` : '10px solid #fff'};
    position: absolute;
    left: calc(50% - 10px);
    bottom: -9.5px;
  }
`;

type Props = {
  port: PopupPort;
  hovered?: boolean;
  focused?: boolean;
  className?: string;
};

function PortPopup({ port, hovered, focused, className }: Props) {
  const { lat, lng, locode, selected } = port;

  return (
    <MapboxPopup
      lng={lng}
      lat={lat}
      closeButton={false}
      closeOnClick={false}
      offset={[0, -10]}
      className={className}
    >
      <Wrapper
        id={`port-popup--${locode}`}
        $selected={selected}
        $focused={focused}
        $hovered={hovered}
      >
        <Details port={port} />
      </Wrapper>
    </MapboxPopup>
  );
}

export default memo(PortPopup);
