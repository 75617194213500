// port priority options
export const MIN_DEVIATION = 'Deviation';
export const NO_DEVIATION = 'Voyage Plan';
export const PREFERRED = 'Slow Down';

export const INVALID_DATE = 'Invalid Date';

export const DEFAULT_PRIORITIES = [NO_DEVIATION, MIN_DEVIATION];
export const DEFAULT_FLIGHT_TIME = 24; /// Hour(s)
export const DEFAULT_LAYOVER_RANGE: [number, number] = [2, 8]; /// Hour(s)
export const DEFAULT_STOPS_COUNT = 1;
export const DEFAULT_HOTEL_COST_DELAY = 12; /// Hour(s)
export const DEFAULT_ETA_LIMIT = 4; /// Week(s)
export const MAX_FLIGHT_TIME = 48; /// Hour(s)
export const MAX_LAYOVER_TIME = 36; /// Hour(s)
export const MAX_STOPS_COUNT = 5;
export const MAX_HOTEL_COST_DELAY = 6 * 24; /// 6 days, in hours
