import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import type { PortCallTypesCommon } from '@greywing-maritime/frontend-library/dist/types/portCalls';
import moment from 'moment';
import { PortCallResponse } from './types';
import { convertDateIntoUTC } from './dates';

const twoMonthsFromNow = moment().add(2, 'months');
const commonAirportFields = {
  lat: null,
  lon: null,
  name: '',
  text: '',
  municipality: null,
};

const vessel: Vessel = {
  id: 3894,
  name: 'GOLDEN BARNET',
  imo: '9721487',
  lastPortCode: 'CNLYG',
  nextPortCode: 'AUPHE',
  lng: 120.526,
  lat: -15.4291,
  updatedAt: '2021-11-23T01:50:20.200Z',
  riskStatus: 'Unknown (please generate report)',
  riskStatusColor: 'soft-secondary',
  upcomingCrewChangeEvents: [
    {
      id: 8609,
      name: 'Corpus Christi',
      locode: 'USDCCS',
      onsigners: 2,
      offsigners: 3,
      eta: twoMonthsFromNow.toLocaleString(),
      etd: twoMonthsFromNow.toLocaleString(),
      crew: [],
      externalIdentifier: null,
      airport: null,
    },
    {
      id: 8915,
      name: 'Singapore',
      locode: 'SGSIN',
      onsigners: 5,
      offsigners: 5,
      eta: twoMonthsFromNow.toLocaleString(),
      etd: twoMonthsFromNow.toLocaleString(),
      crew: [],
      externalIdentifier: null,
      airport: null,
    },
  ],
  crewHomeAirports: {
    MNL: {
      count: 12,
      airport: {
        id: 'MNL',
        lat: 14.5086,
        lon: 121.02,
        text: 'Ninoy Aquino International Airport (MNL)',
      },
      onsigners: 5,
      offsigners: 7,
    },
  },
  speed: 11.6,
  course: 198,
  crewNationalities: {
    PH: {
      count: 12,
      country: {
        id: 'PH',
        text: 'Philippines',
      },
    },
  },
  addedBy: 'manager@grey-wing.com',
  mmsi: '477684700',
  departure: new Date('2021-11-07T05:50:00.000Z'),
  arrival: new Date('2021-11-24T06:30:00.000Z'),
  type: 'Bulk Carrier',
  picLabel: 'Crew Manager (manager@grey-wing.com)',
  picId: 0,
  image: 'https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=2502338',
  owner: 'Greywing',
  manager: 'captain',
  status: 'Underway using Engine',
  operatingStatus: 'Active',
  dwt: '180355 Tons',
  lastPortName: 'LIANYUNGANG',
  nextPortName: 'PORT HEDLAND',
  tags: [],
  // @ts-ignore
  vesselTags: [{ id: 1, displayName: 'Priority', colour: '#000000' }],
  flag: 'Hong Kong',
  source: 'AIS',
  routeUpdatedAt: new Date('2021-11-13T04:11:30.211Z'),
  routeExists: true,
  secondsToNextPort: 101534,
  portCallsLastChanged: '2021-09-15T01:45:22.830Z',
  selected: true,
  crewWithoutEvents: [],
  cargoStatus: 'B',
  crewEarliestDueDate: null,
};

export const SAMPLE_VESSELS: Vessel[] = [
  vessel,
  {
    id: 4948,
    name: 'CMA CGM THAMES',
    imo: '9674567',
    lastPortCode: 'CNSZX',
    nextPortCode: 'CNSZX',
    lng: 113.836,
    lat: 22.5007,
    updatedAt: '2022-08-15T05:00:02.642Z',
    riskStatus: 'Unknown (please generate report)',
    riskStatusColor: 'soft-secondary',
    crewHomeAirports: {},
    crewNationalities: {},
    addedBy: 'manager@grey-wing.com',
    mmsi: '256213000',
    departure: new Date('2022-08-14T02:29:00.000Z'),
    arrival: new Date('2022-08-14T02:43:00.000Z'),
    type: 'Container Ship',
    picId: 58,
    picLabel: 'Crew Manager (manager@grey-wing.com)',
    image:
      'https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=2682021',
    owner: '',
    manager: '',
    status: 'Moored',
    operatingStatus: 'Active',
    dwt: '113800 Tons',
    lastPortName: 'SHENZHEN',
    nextPortName: 'SHENZHEN',
    tags: [],
    // @ts-ignore
    vesselTags: [{ id: 1, displayName: 'Priority', colour: '#000000' }],
    flag: 'Malta',
    source: 'AIS',
    routeUpdatedAt: new Date('2022-08-14T13:00:19.761Z'),
    routeExists: true,
    secondsToNextPort: 123456,
    portCallsLastChanged: '2021-09-15T01:45:22.830Z',
    upcomingCrewChangeEvents: [],
    crewWithoutEvents: [],
    speed: 11.6,
    course: 198,
    selected: true,
    cargoStatus: 'L',
    crewEarliestDueDate: null,
  },
  {
    id: 4912,
    name: 'PETROLIMEX 21',
    imo: '9459254',
    lastPortCode: 'SGSIN',
    nextPortCode: 'MYTPP',
    lng: 103.541,
    lat: 1.35149,
    updatedAt: '2022-08-15T05:00:15.416Z',
    riskStatus: 'Unknown (please generate report)',
    riskStatusColor: 'soft-secondary',
    crewHomeAirports: {
      ATH: {
        count: 1,
        airport: {
          id: 'ATH',
          lat: 37.9364,
          lon: 23.9445,
          text: 'Eleftherios Venizelos International Airport',
        },
        onsigners: 1,
        offsigners: 0,
      },
      MNL: {
        count: 2,
        airport: {
          id: 'MNL',
          lat: 14.5086,
          lon: 121.02,
          text: 'Ninoy Aquino International Airport',
        },
        onsigners: 1,
        offsigners: 1,
      },
      RIX: {
        count: 2,
        airport: {
          id: 'RIX',
          lat: 46.4268,
          lon: 30.6765,
          text: 'Riga International Airport',
        },
        onsigners: 1,
        offsigners: 1,
      },
    },
    speed: 11.4,
    course: 267,
    crewNationalities: {
      GR: {
        count: 1,
        country: {
          id: 'GR',
          text: 'Greece',
        },
      },
      LV: {
        count: 2,
        country: {
          id: 'LV',
          text: 'Latvia',
        },
      },
      PH: {
        count: 2,
        country: {
          id: 'PH',
          text: 'Philippines',
        },
      },
    },
    addedBy: 'manager@grey-wing.com',
    mmsi: '574004960',
    departure: new Date('2022-08-12T05:06:00.000Z'),
    arrival: new Date('2022-08-12T07:26:00.000Z'),
    type: 'Oil Products Tanker',
    picId: 58,
    picLabel: 'Crew Manager (manager@grey-wing.com)',
    image:
      'https://photos.marinetraffic.com/ais/showphoto.aspx?photoid=2761263',
    owner: '',
    manager: '',
    status: 'Moored',
    operatingStatus: 'Active',
    dwt: '45942 Tons',
    lastPortName: 'SINGAPORE',
    nextPortName: 'TANJUNG PELEPAS',
    tags: [],
    // @ts-ignore
    vesselTags: [{ id: 1, displayName: 'Priority', colour: '#000000' }],
    flag: 'Viet Nam',
    source: 'AIS',
    routeUpdatedAt: new Date('2022-08-13T00:00:29.344Z'),
    routeExists: true,
    secondsToNextPort: 23456,
    portCallsLastChanged: '2022-04-14T06:30:31.068Z',
    selected: true,
    upcomingCrewChangeEvents: [
      {
        id: 6,
        name: null,
        locode: null,
        onsigners: 3,
        offsigners: 2,
        eta: '2022-08-17T00:00:00.000Z',
        etd: '2022-08-18T00:00:00.000Z',
        externalIdentifier: null,
        airport: null,
        crew: [
          {
            id: 78,
            cid: '6798',
            type: CrewType.offsigner,
            rank: '4ENG',
            name: 'ANASTASIA BELLITONI',
            country: 'Latvia',
            countryCode: 'LVA',
            homeAirport: { iataCode: 'RIX', ...commonAirportFields },
            age: 29,
            dueDate: '2022-01-02T00:00:00.000Z',
            variance: 43,
            replacementCrewId: 77,
            surname: null,
            middleName: null,
            lastName: null,
            ready: null,
            manningOffice: null,
            change: null,
            lastVessel: null,
            embarkationDate: null,
            disembarkationDate: null,
            flotillaVesselId: 123,
          },
          {
            id: 76,
            cid: '7634',
            type: CrewType.offsigner,
            rank: 'CHENG',
            name: 'IVORY KOBE',
            country: 'Philippines',
            countryCode: 'PHL',
            homeAirport: { iataCode: 'MNL', ...commonAirportFields },
            age: 39,
            dueDate: '2022-01-14T00:00:00.000Z',
            variance: 31,
            replacementCrewId: 75,
            surname: null,
            middleName: null,
            lastName: null,
            ready: null,
            manningOffice: null,
            change: null,
            lastVessel: null,
            embarkationDate: null,
            disembarkationDate: null,
            flotillaVesselId: 123,
          },
          {
            id: 75,
            cid: '9453',
            type: CrewType.onsigner,
            rank: 'CHENG',
            name: 'Antonios DAVIS',
            country: 'Greece',
            countryCode: 'GRC',
            homeAirport: { iataCode: 'ATH', ...commonAirportFields },
            age: 57,
            lastVessel: {
              name: 'DOMINICA',
            },
            ready: '2021-08-15T00:00:00.000Z',
            change: '2022-02-27T00:00:00.000Z',
            manningOffice: 'ERT',
            replacementCrewId: 76,
            dueDate: null,
            variance: null,
            surname: null,
            middleName: null,
            lastName: null,
            embarkationDate: null,
            disembarkationDate: null,
            flotillaVesselId: 123,
          },
          {
            id: 77,
            cid: null,
            type: CrewType.onsigner,
            rank: '4ENG',
            name: 'Jeric TSAN',
            country: 'Latvia',
            countryCode: 'LVA',
            homeAirport: { iataCode: 'RIX', ...commonAirportFields },
            replacementCrewId: 78,
            age: null,
            dueDate: null,
            variance: null,
            surname: null,
            middleName: null,
            lastName: null,
            ready: null,
            manningOffice: null,
            change: null,
            lastVessel: null,
            embarkationDate: null,
            disembarkationDate: null,
            flotillaVesselId: 123,
          },
          {
            id: 83,
            cid: '4568',
            type: CrewType.onsigner,
            rank: 'D/CDT',
            name: 'Rafael NADAL',
            country: 'Philippines',
            countryCode: 'PHL',
            homeAirport: { iataCode: 'MNL', ...commonAirportFields },
            age: 23,
            ready: '2021-11-18T00:00:00.000Z',
            change: '2022-02-20T00:00:00.000Z',
            manningOffice: 'UUU',
            replacementCrewId: null,
            dueDate: null,
            variance: null,
            surname: null,
            middleName: null,
            lastName: null,
            lastVessel: null,
            embarkationDate: null,
            disembarkationDate: null,
            flotillaVesselId: 123,
          },
        ],
      },
    ],
    crewWithoutEvents: [],
    cargoStatus: null,
    crewEarliestDueDate: null,
  },
];

export const samplePortCall: PortCallResponse = {
  success: true,
  portCalls: [
    {
      vesselId: 1,
      displayName: 'HOUSTON',
      portLocode: 'USHOU',
      eta: null,
      etd: new Date('2022-04-10T08:23:00.000Z'),
      lat: 29.7491,
      lng: -95.2854,
      type: 'AIS' as PortCallTypesCommon,
      source: 'AIS',
      vesselStatus: {
        cargoStatus: null,
      },
      timezone: 'Asia/Jakarta',
      utcETA: null,
      utcETD: convertDateIntoUTC(
        new Date('2022-04-10T08:23:00.000Z'),
        'Asia/Jakarta'
      ),
      portDict: {
        displayName: 'HOUSTON',
        locode: 'USHOU',
        lat: 29.7491,
        lng: -95.2854,
        timezone: 'Asia/Jakarta',
      },
      dateToSort: convertDateIntoUTC(
        new Date('2022-04-10T08:23:00.000Z'),
        'Asia/Jakarta'
      ),
    },
  ],
  meta: {},
  message: 'success',
};

export default vessel;
