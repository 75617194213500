import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { selectMapVessels } from 'redux/selectors';
import { getSearchResults } from 'utils/search-engine';
import { ParsedSearchType, SearchResult } from 'utils/types';
import { trackUserAction } from 'lib/amplitude';

import { TRACK_SEARCH_VESSEL_INPUT } from 'utils/analytics/constants';

type VesselSearchType = {
  params: ParsedSearchType | null;
  inputStr: string;
};

function useVesselSearch({ params, inputStr }: VesselSearchType) {
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (inputStr: string) => {
      const newResults = await getSearchResults(
        inputStr.toLowerCase(),
        vessels
      );
      trackUserAction(TRACK_SEARCH_VESSEL_INPUT, 'typed', { query: inputStr });
      setResults(newResults);
      setIsLoading(false);
    }, 250),
    [vessels]
  );

  useEffect(() => {
    if (!params) return;
    if (!inputStr) {
      setResults([]);
    } else {
      setIsLoading(true);
      debouncedSearch(inputStr);
    }
  }, [vessels, inputStr, params, debouncedSearch]);

  return { results, isLoading };
}

export default useVesselSearch;
