import { useEffect, useRef } from 'react';

type Callback = () => any;

// Declarative `setInterval` usage
function useInterval(
  callback: Callback,
  delay: number // milliseconds
) {
  const savedCallback = useRef<Callback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }

    if (delay) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

export default useInterval;
