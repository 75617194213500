import uniqBy from 'lodash/uniqBy';
import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'redux/types';
import { CCPanelContext } from 'contexts/CCPanelContext';

import PortPopup from './PortPopup';
import { arrangePortPopups, mergePortsWithRoute } from '../helpers';
import { PortRow, ReadOnlyPort } from '../types';

type Props = {
  hoveredPort?: PortRow | ReadOnlyPort | null;
  focusedPort?: PortRow | ReadOnlyPort | null;
};

function PortPopups({ hoveredPort, focusedPort }: Props) {
  const {
    filters,
    tableState: { step, readOnlyDetails },
    planningData: { ports, route },
  } = useContext(CCPanelContext);
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const reportData = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.readOnlyPlanningData
  );

  // list of ports to show - both for planning & readonly view
  const popupPorts = useMemo(() => {
    const isReportView = activity === 'readOnly';
    const {
      ports: readOnlyPorts = [],
      route: readOnlyRoute = [],
      portFilters: { etaLimit = 0 } = {},
    } = reportData?.crewChangePlan || {};
    const portList = uniqBy(
      mergePortsWithRoute(
        isReportView ? readOnlyPorts : ports,
        isReportView ? readOnlyRoute : route,
        etaLimit
      ),
      'locode'
    );

    return arrangePortPopups({
      ports: [...portList], // avoid readonly value
      allFilters: filters,
      ...(isReportView ? { readOnlyDetails } : {}),
    });
  }, [activity, ports, route, reportData, readOnlyDetails, filters]);

  return (
    <>
      {/* render selected port popups */}
      {popupPorts.map((port, index) => {
        const priority = popupPorts.length - index;
        const className = `mapboxgl-popup-zindex-${
          step !== 'ports' ? priority : index
        }`;
        return (
          <PortPopup
            key={`${port.id}-${index}`}
            port={port}
            className={className}
            focused={focusedPort?.id === port.id}
          />
        );
      })}
      {/*
       * render popup for hovered row
       * don't show popupup if already selected
       */}
      {step === 'ports' &&
        hoveredPort &&
        !ports.some((port) => port.id === hoveredPort.id) && (
          <PortPopup
            key={`${hoveredPort.locode}`}
            port={hoveredPort}
            // high priority for hovered port to render above others
            className={`mapboxgl-popup-zindex-25`}
            focused={hoveredPort.id === focusedPort?.id}
            hovered
          />
        )}

      {/*
       * render animated popup for focused row
       */}
      {step === 'ports' && focusedPort && focusedPort.id !== hoveredPort?.id && (
        <PortPopup
          key={`${focusedPort.locode}`}
          port={focusedPort}
          // max priority for focused port to render above everyone else
          className={`mapboxgl-popup-zindex-30`}
          focused
        />
      )}
    </>
  );
}

export default memo(PortPopups);
