import { CCPanelContext } from 'contexts/CCPanelContext';
import { ReactNode, useContext } from 'react';

import { useAppDispatch, useE2ETests, useReadOnlyPlanningData } from 'hooks';
import { titleize } from 'lib/string';
import { closeSidePanel } from 'redux/actions';
import StepButton from '../StepButton';
import { findStepIndex, steps } from '../../helpers';
import { ControlsWrapper, ControlsChildWrapper } from '../../common';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

type ControlProps = {
  nextUnavilable?: boolean;
  children: ReactNode;
};

function ReadOnlyControls({ children, nextUnavilable }: ControlProps) {
  const dispatch = useAppDispatch();
  const { startNewCrewChange } = useReadOnlyPlanningData();
  const { isE2ETesting } = useE2ETests();

  const {
    tableState: { step },
    updateTableState,
  } = useContext(CCPanelContext);
  const stepIndex = findStepIndex(step);

  const handleSelectStep = (type: 'next' | 'previous') => {
    const newIndex = type === 'next' ? stepIndex + 1 : stepIndex - 1;
    updateTableState({ type: 'step', payload: steps[newIndex] });
    if (step === 'ports') {
      dispatch(closeSidePanel());
    }
  };

  return (
    <ControlsWrapper>
      <StepButton
        variant="secondary"
        direction="left"
        hide={step === 'crew'}
        onClick={() => handleSelectStep('previous')}
      >
        Back
      </StepButton>

      <ControlsChildWrapper $fullWidth={step === 'flights'}>
        {children}
      </ControlsChildWrapper>

      {/* Show different text & icon for incomplete plans final step
        Also, trigger starting a new plan for last step */}
      {/* No button for E2E end */}
      {isE2ETesting && nextUnavilable ? null : (
        <StepButton
          onClick={() =>
            nextUnavilable ? startNewCrewChange() : handleSelectStep('next')
          }
          direction="right"
          hide={step === 'flights'}
          {...(nextUnavilable ? { icon: PlayCircleIcon } : {})}
        >
          {(nextUnavilable && 'Continue In A Plan') ||
            (step !== 'flights'
              ? `Review ${titleize(steps[stepIndex + 1])}`
              : '')}
        </StepButton>
      )}
    </ControlsWrapper>
  );
}

export default ReadOnlyControls;
