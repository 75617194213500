import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { uuid } from 'uuidv4';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

import { selectDesktopNotifications, selectUserAuth } from 'redux/selectors';
import {
  addNotificationUpdates,
  setUserAuth,
  updateNoUpdateId,
} from 'redux/actions';
import { onLogout, removeOnLogout } from 'api/helpers';
import { ping } from 'utils/websocket';
import { NotificationUpdate } from 'utils/types';
import useAppDispatch from './useAppDispatch';

const createWorker = createWorkerFactory(
  () => import('../workers/notifications.worker')
);

const maxRetries = 20;
let numRetries = 0;

let webSocketRef: WebSocket | null = null;

export { webSocketRef as generalWebsocket };

function useWebsocket() {
  const worker = useWorker(createWorker);
  const dispatch = useAppDispatch();
  const isAuthed = useSelector(selectUserAuth);
  const allowNotifications = useSelector(selectDesktopNotifications);

  const handleUpdateAuth = useCallback((isAuthed: boolean) => {
    dispatch(setUserAuth(isAuthed));
  }, []); // eslint-disable-line

  const onNotifications = useCallback(
    (notifications: NotificationUpdate[]) => {
      if (notifications.length) {
        dispatch(addNotificationUpdates(notifications));
      } else {
        dispatch(updateNoUpdateId(uuid().substring(0, 8)));
      }
    },
    [dispatch]
  );

  const connectWebSocketAuthed = useCallback(
    async (allowNotifications: boolean) => {
      if (!isAuthed || webSocketRef) {
        return;
      }
      console.log('%cConnecting to Websocket', 'color: red;');

      const ws = await worker.connectWS({
        onNotificationsCb: onNotifications,
        dispatch,
        allowNotifications,
      });

      if (!ws) {
        webSocketRef = null;
        return;
      }

      ws.onopen = function open() {
        ping(ws);
        numRetries = 0;
      };

      ws.onclose = function close(ev: CloseEvent) {
        if (ev.code === 1000) {
          // it was closed normally, don't attempt to reconnect
          return;
        }

        if (numRetries < maxRetries) {
          const waitSecs = 1.5 ** (numRetries + 1);
          console.log(`WebSocket connection failed. Retrying in ${waitSecs}s.`);
          setTimeout(() => {
            connectWebSocketAuthed(allowNotifications);
          }, waitSecs * 1000);
        } else if (numRetries >= maxRetries) {
          console.log('Max retries reached. Not reconnecting.');
        }
      };

      ws.onerror = function error() {
        numRetries++;
        if (ws) {
          ws.close();
          webSocketRef = null;
        }
      };

      webSocketRef = ws;
    },
    [isAuthed, dispatch, worker, onNotifications]
  );

  useEffect(() => {
    if (!webSocketRef) return;
    console.log('%cReconnecting Websocket', 'color: red;');
    webSocketRef.close(1000);
    webSocketRef = null;
    connectWebSocketAuthed(allowNotifications);
  }, [allowNotifications, connectWebSocketAuthed]);

  useEffect(() => {
    const id = onLogout(() => {
      handleUpdateAuth(false);
    });
    return () => {
      removeOnLogout(id);
      if (webSocketRef) {
        webSocketRef.close(1000);
        webSocketRef = null;
      }
    };
  }, [handleUpdateAuth]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      worker.clearPing();
    };
  }, []); // eslint-disable-line

  return {
    websocket: webSocketRef,
    connectWebSocketAuthed,
  };
}

export default useWebsocket;
