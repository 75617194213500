import {
  RestrictionSourceType,
  RestrictionType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import {
  CREW_CHANGE_REDIRECT,
  RESTRICTIONS_REDIRECT,
  SIDEPANEL_UPDATES_REDIRECT,
  MAGIC_LINK_REDIRECT,
  FLIGHTS_TRACK_REDIRECT,
} from 'lib/constants';
import { useSelector } from 'react-redux';

import { selectRedirect } from 'redux/selectors';

// handle top level app redirects
const useAppRedirect = () => {
  const appRedirect = useSelector(selectRedirect);

  if (!appRedirect) {
    return null;
  }

  const { pathname, params } = appRedirect;

  // cover the cases for different redirects
  switch (true) {
    case pathname.includes(FLIGHTS_TRACK_REDIRECT): {
      return {
        [FLIGHTS_TRACK_REDIRECT]: params,
      };
    }

    case pathname.includes(RESTRICTIONS_REDIRECT): {
      const { type, name, latestUpdatedAt, portOrCountryCode, source } = params;
      const restrictionDetails = {
        name,
        locode: portOrCountryCode,
        restrictionsAvailable: [
          { source: source as RestrictionSourceType, latestUpdatedAt },
        ],
      };
      return {
        [RESTRICTIONS_REDIRECT]: {
          type: type as RestrictionType,
          restrictionDetails,
        },
      };
    }

    case pathname.includes(CREW_CHANGE_REDIRECT): {
      const { id } = params;
      return {
        [CREW_CHANGE_REDIRECT]: { id },
      };
    }

    case pathname.includes(SIDEPANEL_UPDATES_REDIRECT): {
      return {
        [SIDEPANEL_UPDATES_REDIRECT]: true,
      };
    }

    case pathname.includes(MAGIC_LINK_REDIRECT): {
      const { code, email, admin } = params;
      const magicLinkDetails = {
        option: 'Security',
        magicLink: {
          code,
          email,
          admin,
        },
      };
      return {
        [MAGIC_LINK_REDIRECT]: magicLinkDetails,
      };
    }

    default:
      return null;
  }
};

export default useAppRedirect;
