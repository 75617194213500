import { memo, useEffect, useMemo, useState } from 'react';
import Lottie, { LottieProps } from 'react-lottie';

import { setAnimationOptions } from 'lib/common';

type Props = {
  name: string; // name of the animation json file
  loop?: boolean;
} & Omit<LottieProps, 'options'>;

function LottieAnimation({
  name,
  width,
  height,
  speed = 1,
  loop = true,
  style = {},
}: Props) {
  const [animationData, setAnimationData] = useState();

  const animationOptions = useMemo(
    () => animationData && setAnimationOptions(animationData, loop),
    [animationData, loop]
  );

  useEffect(() => {
    import(`assets/lotties/${name}.json`).then(setAnimationData);
  }, [name]);

  if (!animationOptions) {
    return null;
  }

  return (
    <Lottie
      options={animationOptions}
      width={width}
      height={height}
      speed={speed}
      style={style}
    />
  );
}

export default memo(LottieAnimation);
