import uniqBy from 'lodash/uniqBy';
import alasql from 'alasql';
import { AirportShort } from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';

import { logInDevelopment } from 'lib/log';
import { isDevelopment } from 'lib/environments';
import { showToaster } from 'lib/toaster';
import { Crew, Flight, Port } from 'utils/types/crew-change-types';
import {
  FlightFilters,
  FlightReqDetails,
} from 'components/CrewChangePanel/types';
import {
  filterDepartures,
  formatFlights,
  getFlightUniqueKey,
  hasPortAirport,
  matchCrewHomeAirport,
} from './helpers';
import { FlightRequest, PopulateRecordTable } from './types';

const FLIGHT_TABLE = 'flight_records';
const REQUEST_TABLE = 'flight_requests';

const FLIGHT_RECORD_DDL = `
  CREATE TABLE IF NOT EXISTS ${FLIGHT_TABLE} (
    id character varying PRIMARY KEY,
    requestId character varying NOT NULL,
    departure jsonb NOT NULL,
    arrival jsonb NOT NULL,
    flightNumbers jsonb NOT NULL,
    stops jsonb NOT NULL,
    source character varying NOT NULL,
    price jsonb NOT NULL,
    segments jsonb NOT NULL,
    type jsonb NOT NULL,
    crew jsonb NOT NULL,
    port jsonb NOT NULL,
    totalFlightTime double precision NOT NULL,
    originalId character varying,
    fetchedAt character varying,
    uid character varying,
    fareInformation jsonb,
    co2 jsonb,
    totalCO2 jsonb,
    airports jsonb,
    rawFlight jsonb,
    priceInUSD double precision,
    cabinClass character varying,
    tracked boolean,
    numSeatsAvailable integer,
    flightId character varying,
    crewLinkFlightSummary jsonb
  );
`;

const FLIGHT_REQUEST_DDL = `
  CREATE TABLE IF NOT EXISTS ${REQUEST_TABLE} (
    requestId character varying PRIMARY KEY,
    portLocode character varying NOT NULL,
    portAirportCode character varying NOT NULL,
    completed boolean NOT NULL,
    flightKeys jsonb
  );
`;

/* ----- Start: User defined functions in alasql ----- */

alasql.fn.getCommonFlights = (
  flights: Flight[],
  filters: FlightFilters,
  crew?: Crew
) => {
  const { locode, duplicate, departures, source, portAirport } = filters;
  return flights.filter(
    (flight) =>
      // filter for port & port nearby airport
      hasPortAirport(flight, locode, portAirport) &&
      // filter for crew home airport, if available, otherwise filter for all crew
      (crew ? matchCrewHomeAirport(crew, flight) : true) &&
      // filter for departure time
      filterDepartures(flight, departures) &&
      // filter only for specific source, if duplicate port
      (duplicate ? flight.source === source : true)
  );
};

alasql.fn.addedRecord = (
  flightNumbers: string[],
  departure: AirportShort,
  requestId: string
) => {
  const fetchedDetails = alasql(
    `SELECT * FROM ${REQUEST_TABLE} WHERE requestId = ?`,
    [requestId]
  );
  const { flightKeys = [] } = fetchedDetails?.[0] || {};
  // this is an extra layer of check to prevent inserting same flight for same crew twice
  return flightKeys.includes(getFlightUniqueKey({ flightNumbers, departure }));
};

alasql.fn.addedRequest = (requestId: string) => {
  const requestDetails = alasql(
    `SELECT * FROM ${REQUEST_TABLE} WHERE requestId = ?`,
    [requestId]
  );
  return Boolean(requestDetails?.length);
};

/* ----- End: User defined functions in alasql ----- */

export const initFlightTables = () => {
  alasql(FLIGHT_RECORD_DDL);
  alasql(FLIGHT_REQUEST_DDL);
  const tables = alasql(`SHOW TABLES`);
  logInDevelopment('Created tables - ', tables);
};

export const dropFlightsTables = () => {
  alasql(`DROP TABLE ${FLIGHT_TABLE}`);
  alasql(`DROP TABLE ${REQUEST_TABLE}`);
  const tables = alasql(`SHOW TABLES`);
  logInDevelopment('Dropped tables - ', tables);
};

export const showTableData = () => {
  const flightsCount = alasql(
    `SELECT VALUE COUNT(DISTINCT originalId) FROM ${FLIGHT_TABLE}`
  );
  const fetchedRequests = alasql(`SELECT * FROM ${REQUEST_TABLE}`);
  // log the updates
  console.log('Total flights fetched - ', flightsCount);
  logInDevelopment('Flight requests - ', fetchedRequests);
};

export const populateRequestTable = (requests: FlightReqDetails[]) => {
  try {
    const requestList = uniqBy(requests, 'requestId').map(
      ({ requestId, port }) => ({
        requestId,
        completed: false,
        portLocode: port.locode,
        portAirportCode: port.selectedAirport?.iataCode || null,
      })
    );
    // initiate requests table
    alasql(
      `
        INSERT INTO ${REQUEST_TABLE}
        SELECT * FROM ?
        WHERE NOT addedRequest(requestId)
      `,
      [requestList]
    );
  } catch (error) {
    console.log('Error insering flight request in REQUESTS table', error);
    const errorMessaage = (error as Error).message;
    // show error message in toaster for `Development` env only
    if (isDevelopment && errorMessaage) {
      showToaster({ message: errorMessaage, type: 'warning' });
    }
  }
};

// resolves with progress for the specific port
export const populateRecordTable: PopulateRecordTable =
  (statusHandler) => (request, flights) => {
    try {
      const { requestId, crew, port } = request;
      // filter out flights that are already stored
      const formattedFlights = formatFlights(flights, crew, port);
      // populate record table with new flights after filter
      alasql(
        `
          INSERT INTO ${FLIGHT_TABLE}
          SELECT * FROM ?
          WHERE NOT addedRecord(flightNumbers, departure, ?)
        `,
        [formattedFlights, requestId]
      );
      // update request table with unique flight keys
      const flightKeys = flights.map(getFlightUniqueKey);
      alasql(
        `
          UPDATE ${REQUEST_TABLE}
          SET completed = true, flightKeys = ?
          WHERE requestId = ?
        `,
        [flightKeys, requestId]
      );
      // update progress status for components
      statusHandler(port);
    } catch (error) {
      console.log('Error insering flight record in RECORDS table', error);
      const errorMessaage = (error as Error).message;
      // show error message in toaster for `Development` env only
      if (isDevelopment && errorMessaage) {
        showToaster({ message: errorMessaage, type: 'warning' });
      }
    }
  };

// find the already fetched flight for another crew with same requestId
// and insert them with new crew and port data
export const duplicateReqFlights = (requestData: FlightReqDetails) => {
  try {
    const { requestId, crew, port } = requestData;
    const existingDuplicates = alasql(
      `SELECT * FROM ${FLIGHT_TABLE} WHERE requestId = ? AND crew->id = ?`,
      [requestId, crew.id]
    );
    // no action needed, if already has duplicates
    if (existingDuplicates.length) {
      return;
    }
    const newFlights = alasql(
      `
        SELECT ${FLIGHT_TABLE}.* FROM ${FLIGHT_TABLE}
        WHERE requestId = ? AND crew->id <> ?
        GROUP BY ${FLIGHT_TABLE}.originalId
      `,
      [requestId, crew.id]
    );
    if (newFlights.length) {
      const formattedFlights = formatFlights(newFlights, crew, port);
      alasql(`INSERT INTO ${FLIGHT_TABLE} SELECT * FROM ?`, [formattedFlights]);
    }
  } catch (error) {
    console.log('Error insering flight duplicate in RECORDS table', error);
    const errorMessaage = (error as Error).message;
    // show error message in toaster for `Development` env only
    if (isDevelopment && errorMessaage) {
      showToaster({ message: errorMessaage, type: 'warning' });
    }
  }
};

// check if certain requestId is already completed
export const isCompletedRequest = (requestId: string) => {
  const fetchedDetails = alasql(
    `SELECT * FROM ${REQUEST_TABLE} WHERE requestId = ?`,
    [requestId]
  );
  // check if flights are fetched for this `requestId`
  const { completed } = fetchedDetails?.[0] || {};
  return completed;
};

// update saved flight with tracked status
export const trackConfirmedFlight = (flightId: string) => {
  alasql(`UPDATE ${FLIGHT_TABLE} SET tracked = true WHERE requestId = ?`, [
    flightId,
  ]);
};

// determine port's fetching progress - both initial & departure update
export const getPortRequestProgress = (port: Port) => {
  const { locode, selectedAirport: { iataCode } = {} } = port;
  const portRequests: FlightRequest[] = alasql(
    `
      SELECT * FROM ${REQUEST_TABLE}
      WHERE portLocode = ? AND portAirportCode = ?
    `,
    [locode, iataCode]
  );
  // find current finished step
  const finishedRequests = portRequests.filter(({ completed }) => completed);
  return portRequests.length
    ? (finishedRequests.length || 0) / portRequests.length
    : 0;
};

// get individual flight with `originalId`
export const getFlightData = (flightId: string) => {
  const flights: Flight[] = alasql(
    `SELECT * FROM ${FLIGHT_TABLE} WHERE originalId = ?`,
    [flightId]
  );
  return flights.length ? flights[0] : null;
};

// get the fetced flights count for a port
export const getPortFlightsCount = (locode: string, airportCode: string) => {
  const flightsCount: number = alasql(
    `
    SELECT VALUE COUNT(DISTINCT originalId)
    FROM ${FLIGHT_TABLE}
    WHERE port->locode = ? AND port->selectedAirport->iataCode = ?
  `,
    [locode, airportCode]
  );
  return { [locode]: flightsCount };
};

// query for common flights
// filtering for port, departure, crew home-airport (if needed), & source (if needed)
export const getCommonFlights = (
  filters: FlightFilters,
  crew?: Crew
): Flight[] => {
  // get common flights for all, if no crew is provided
  const totalFlights: Flight[] = crew
    ? alasql(
        `SELECT * FROM ${FLIGHT_TABLE} WHERE ${FLIGHT_TABLE}.crew.id = ?`,
        [crew.id]
      )
    : alasql(`SELECT * FROM ${FLIGHT_TABLE}`);
  const crewFlights = alasql(`SELECT getCommonFlights(?, ?, ?) [result]`, [
    totalFlights,
    filters,
    crew,
  ]);
  return crewFlights?.[0]?.result || [];
};
