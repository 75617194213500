import { memo, useContext } from 'react';
import Stack from '@mui/material/Stack';
import styled from 'styled-components/macro';

import { useFiltersChange } from 'hooks';
import { formatHour } from 'lib/common';
import { CCPanelContext } from 'contexts/CCPanelContext';

import {
  AirlineSelection,
  AwesomeSlider,
  LayoverSelection,
} from 'components/shared';
import { Thumb } from 'components/shared/AwesomeSlider';
import { ItemWrapper, StyledSliderWrapper } from '../common';
import { Label } from '../../../../common';
import { Airline, LayoverAirport } from '../../../../types';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ThumbHour = (props: any) => {
  const { value } = props.ownerState;
  const hourValue = props['data-index'] ? value[1] : value[0];
  const formattedHour =
    (hourValue === 0 && '12AM') ||
    (hourValue === 24 && '11:59PM') ||
    formatHour(hourValue);
  return <Thumb {...props} modifiedValue={formattedHour} />;
};

function CustomFilters({ locodeKey }: { locodeKey: string }) {
  const { filters: allFilters, updateFilters } = useContext(CCPanelContext);
  const filters = allFilters[locodeKey];
  const { localFilters, changeFilters } = useFiltersChange({
    locodeKey,
    componentType: 'custom',
  });

  if (!localFilters) {
    return null;
  }

  const handleUpdateAirlines = (airlines: Airline[]) => {
    updateFilters({
      type: 'UPDATE_SELECTED_AIRLINES',
      payload: { locodeKey, airlines },
    });
  };

  const handleUpdateLayoers = (layovers: LayoverAirport[]) => {
    updateFilters({
      type: 'UPDATE_SELECTED_STOPS',
      payload: { locodeKey, layovers },
    });
  };

  const {
    arrivalTime,
    departureTime,
    selectedStops,
    airlines: selectedAirlines,
  } = localFilters;
  const hideButtons =
    !(filters.stopsCount && selectedStops.length) && !selectedAirlines.length;

  return (
    <Wrapper>
      {!hideButtons && (
        <ItemWrapper>
          <Label>Flight Details</Label>
          <FlexWrapper>
            {Boolean(filters.stopsCount && selectedStops.length) && (
              <LayoverSelection
                layovers={selectedStops}
                updateFilters={handleUpdateLayoers}
              />
            )}
            {Boolean(selectedAirlines.length) && (
              <AirlineSelection
                airlines={selectedAirlines}
                updateFilters={handleUpdateAirlines}
              />
            )}
          </FlexWrapper>
        </ItemWrapper>
      )}

      {arrivalTime && (
        <ItemWrapper>
          <Label>Arrival Hours</Label>
          <StyledSliderWrapper>
            <Stack spacing={2} direction="row" alignItems="center">
              <AwesomeSlider
                size="small"
                min={0}
                max={24}
                step={1}
                value={arrivalTime}
                onChange={(_, newValue) =>
                  changeFilters({
                    arrivalTime: newValue as [number, number],
                  })
                }
                components={{ Thumb: ThumbHour }}
              />
            </Stack>
          </StyledSliderWrapper>
        </ItemWrapper>
      )}

      {departureTime && (
        <ItemWrapper>
          <Label>Departure Hours</Label>
          <StyledSliderWrapper>
            <Stack spacing={2} direction="row" alignItems="center">
              <AwesomeSlider
                size="small"
                min={0}
                max={24}
                step={1}
                value={departureTime}
                onChange={(_, newValue) =>
                  changeFilters({
                    departureTime: newValue as [number, number],
                  })
                }
                components={{ Thumb: ThumbHour }}
              />
            </Stack>
          </StyledSliderWrapper>
        </ItemWrapper>
      )}
    </Wrapper>
  );
}

export default memo(CustomFilters);
