import { useCallback, useState } from 'react';
import { CreateAlertRequest } from '@greywing-maritime/frontend-library/dist/types/alerts';

// Context
import { createNewAlert } from 'api/flotilla';
import { showToaster } from 'lib/toaster';

const useSearchAlert = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setAlert = useCallback(async (props: CreateAlertRequest) => {
    setIsLoading(true);
    try {
      const { success, message } = await createNewAlert(props);
      setIsLoading(false);
      if (!success) {
        showToaster({
          message,
          type: 'error',
        });
        return false;
      }
      showToaster({
        message: 'Sucessfully created a new alert.',
        type: 'success',
      });
      return true;
    } catch (error) {
      showToaster({
        message: 'Failed to create a new alert',
        type: 'error',
      });
      setIsLoading(false);
      return false;
    }
  }, []);

  return { setAlert, isLoading };
};

export default useSearchAlert;
