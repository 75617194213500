import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { showToaster } from 'lib/toaster';
import { trackUserAction } from 'lib/amplitude';
import { selectMapVessels, selectSettings } from 'redux/selectors';
import { formatReadOnlyDataLink } from 'utils/crew-change-events';
import {
  TRACK_DOWNLOAD_PORT_AGENT_CC_PLAN,
  TRACK_DOWNLOAD_REGULAR_CC_PLAN,
} from 'utils/analytics/constants';
import {
  formatEmailDetails,
  getLocodeKeyDetails,
} from 'components/CrewChangePanel/helpers';
import { ReadOnlyPlanningData } from 'components/CrewChangePanel/types';

import {
  convertReportToWSForCCP,
  convertReportToWSForPortAgent,
  createWorkbook,
  downloadWorkbook,
  getReportDetails,
} from './helpers';
import {
  ConvertToWSForCCP,
  ConvertToWSForPortAgent,
  TrackActionData,
} from './types';

function useSavedPlanExport() {
  const {
    userInfo,
    crewChange: { costParams },
  } = useSelector(selectSettings);
  const { filteredVessels: vessels } = useSelector(selectMapVessels);

  // method to save read-only data to excel file
  const saveReadOnlyExcelReport = useCallback(
    async (readOnlyData: ReadOnlyPlanningData, isPortAgent?: boolean) => {
      const {
        id: planId,
        userNotes,
        summary,
        crewChangePlan: {
          vessel,
          crew,
          ports,
          flights,
          portFilters: portParams,
        },
      } = readOnlyData;
      const vesselData = vessels.get(vessel.id);

      if (!vesselData) {
        return;
      }

      try {
        const filename = `Crew change for ${vessel.name}`;
        const shareableLink = formatReadOnlyDataLink(planId);
        const workbook = await createWorkbook(userInfo);

        const generateWorksheet = isPortAgent
          ? convertReportToWSForPortAgent(workbook)
          : convertReportToWSForCCP(workbook);

        Object.keys(flights).forEach((locodeKey: string) => {
          const activeFlights = flights[locodeKey];
          const {
            locode: originalLocode,
            flightSource,
            portETA: uniqETA,
          } = getLocodeKeyDetails(locodeKey);
          const port = {
            ...ports.find(({ locode }) => locode === originalLocode)!,
            flightSource,
            uniqETA,
          };

          // generate worksheet according to port-agent template
          if (isPortAgent) {
            (generateWorksheet as ConvertToWSForPortAgent)(
              crew,
              port,
              vesselData
            );
            return;
          }

          const additionalDetails = getReportDetails(
            port,
            crew,
            activeFlights,
            portParams,
            costParams
          );
          const emailDetails = formatEmailDetails({
            crew,
            port,
            vessel: vesselData,
            flights: activeFlights,
          });

          (generateWorksheet as ConvertToWSForCCP)(
            port,
            emailDetails,
            additionalDetails,
            shareableLink
          );
        });

        await downloadWorkbook(workbook, filename);
        trackUserAction(
          isPortAgent
            ? TRACK_DOWNLOAD_PORT_AGENT_CC_PLAN
            : TRACK_DOWNLOAD_REGULAR_CC_PLAN,
          'click',
          {
            reportSummary: {
              userNotes,
              crew: summary.crew,
              ports: summary.ports,
            },
          } as TrackActionData
        );
      } catch (error) {
        console.log('Error downloading saved crew-change plan', error);
        showToaster({
          message: 'Failed to download excel report for the saved plan.',
          type: 'error',
        });
      }
    },
    [userInfo, vessels, costParams]
  );

  return { saveReadOnlyExcelReport };
}

export default useSavedPlanExport;
