import type {
  Vessel,
  VesselCargoStatus,
  VesselCargoStatusTypes,
} from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { secondsToDHM } from 'lib/common';
import { compoundSidePanelFields } from 'components/SidePanel/CompoundSidePanelFields';
import {
  CompoundPopupFieldMetadata,
  LabelFieldMetadata,
  PrintableVesselField,
  VesselFieldHighlightConfig,
  VesselFieldId,
  VesselFieldMetadata,
  VesselFieldsMetadata,
  VesselFieldValue,
  VesselTag,
} from './types';
import { VesselFieldHighlight } from './highlight-vessels';
import { VesselFieldsConfig } from './flotilla-config';
import { locationTime } from './timezone';
import { formatDate } from './format-date';

const FAST_UPDATE_FREQUENCY_MS = 1000 * 300;
const SLOW_UPDATE_FREQUENCY_MS = 1000 * 60 * 60;
const GLACIAL_UPDATE_FREQUENCY_MS = 1000 * 60 * 60 * 4;

const VESSEL_CARGO_MAPPING: {
  [key in VesselCargoStatus]: VesselCargoStatusTypes;
} = {
  L: 'LADEN',
  B: 'BALLAST',
};

export function stringifyFieldValue<T extends VesselFieldValue>(
  input: T,
  field: PrintableVesselField<T>
) {
  if (field.emptyValues && field.emptyValues.includes(input))
    return field.emptyValueReplacementStr;
  try {
    return field.getDisplayString(input);
  } catch (err) {
    console.error('Error coersing ', input, ' to string');
    // TODO: Log to Sentry?
    return field.emptyValueReplacementStr;
  }
}

export function getFieldHighlight<T extends VesselFieldValue>(
  fieldId: VesselFieldId | null,
  vessels: Vessel[]
): VesselFieldHighlight<T> | null {
  if (!fieldId) return null;
  if (vesselFields[fieldId].highlightConfig)
    return new VesselFieldHighlight<T>(
      fieldId,
      vessels.map((v) => v[fieldId]) as T[],
      vesselFields[fieldId].emptyValues,
      vesselFields[fieldId].highlightConfig as VesselFieldHighlightConfig
    );
  else return null;
}

// TODO: Move the helper functions elsewhere
const toTitleCase = (phrase: string): string => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export function getSidePanelDisplayableFields(): VesselFieldId[] {
  const defaultFields = Object.keys(vesselFields).reduce<
    {
      [key: string]: VesselFieldMetadata<VesselFieldValue>;
    }[]
  >((fields, field) => {
    if (vesselFields[field].display) {
      fields.push({
        [field]: vesselFields[field],
      });
    }
    return fields;
  }, []);

  const sidePanelFields = Object.keys(compoundSidePanelFields).map(
    (fieldId) => ({ [fieldId]: compoundSidePanelFields[fieldId] })
  );
  const displayFields = [...sidePanelFields, ...defaultFields].sort((a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];

    if (a[aKey].displayPriority > b[bKey].displayPriority) {
      return 1;
    } else if (a[aKey].displayPriority < b[bKey].displayPriority) {
      return -1;
    } else {
      return 0;
    }
  });

  const displayFieldIds = displayFields.map((field) => Object.keys(field)[0]);

  return displayFieldIds;
}

export function getHighlightableFields(): VesselFieldId[] {
  return (Object.keys(vesselFields) as VesselFieldId[]).filter(
    (fieldId) => !!vesselFields[fieldId].highlightConfig
  );
}

export function getLabellableFields(): VesselFieldId[] {
  return Object.keys(labelFields) as VesselFieldId[];
}

export function getPopuppableFields(): VesselFieldId[] {
  return (Object.keys(vesselFields) as VesselFieldId[])
    .filter((fieldId) => vesselFields[fieldId].popupEnabled)
    .concat(Object.keys(compoundPopupFields));
}

export const labelFields: { [key: string]: LabelFieldMetadata } = {
  label_name: {
    displayPriority: 0,
    getDisplayString: (vessel: Vessel) => {
      return vessel.name ? vessel.name : '';
    },
    shortDesc: 'Name',
    longDesc: 'Vessel Name',
    tooltip: 'Vessel Name',
    emptyValues: [],
    emptyValueReplacementStr: 'Unknown',
  },
  label_name_and_speed: {
    displayPriority: 0,
    getDisplayString: (vessel: Vessel) => {
      return `${vessel.name ? vessel.name : ''}${
        vessel.speed ? `: ${vessel.speed} kts` : ''
      }`;
    },
    shortDesc: 'Name and Speed',
    longDesc: 'Vessel Name and Speed',
    tooltip: 'Vessel Name and Speed',
    emptyValues: [],
    emptyValueReplacementStr: 'Unknown',
  },
};

export const ALL_LABELABLE_FIELDS = Object.keys(labelFields);

export const compoundPopupFields: {
  [key: string]: CompoundPopupFieldMetadata;
} = {
  compound_crewSigners: {
    compoundField: true,
    displayPriority: 21,
    shortDesc: 'Crew',
    longDesc: 'Crew to Change',
    tooltip: 'The number of crew that requires changing',
    fieldInputs: ['crewHomeAirports'],
    emptyValueReplacementStr: 'None',
    emptyValues: [],
    getDisplayString: function (vessel: Vessel) {
      function offsignerCount(vessel: Vessel) {
        let res = 0;
        for (const k in vessel.crewHomeAirports) {
          res += vessel.crewHomeAirports[k].offsigners;
        }
        return res;
      }
      function onsignerCount(vessel: Vessel) {
        let res = 0;
        for (const k in vessel.crewHomeAirports) {
          res += vessel.crewHomeAirports[k].onsigners;
        }
        return res;
      }

      if (!vessel.crewHomeAirports) return this.emptyValueReplacementStr;

      return `${onsignerCount(vessel)} on, ${offsignerCount(vessel)} off`;
    },
  },
  compound_localTime: {
    compoundField: true,
    displayPriority: 31,
    shortDesc: 'Local Time',
    longDesc: 'Local Time Onboard Vessel',
    tooltip: 'Local Time Onboard Vessel',
    fieldInputs: ['lat', 'lng'],
    emptyValueReplacementStr: '',
    emptyValues: [],
    getDisplayString: function (vessel: Vessel) {
      if (!vessel.lat || !vessel.lng) return '';

      return locationTime({ lat: vessel.lat, lon: vessel.lng });
    },
  },
};

export const vesselFields: VesselFieldsMetadata = {
  // Sidepanel display fields
  imo: {
    displayPriority: 10, // Using tens so it's easier to insert compound fields
    display: true,
    popupEnabled: true,
    shortDesc: 'IMO',
    longDesc: 'IMO',
    tooltip: 'Vessel IMO number',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown IMO)',
    emptyValues: [null, ''],
  },
  secondsToNextPort: {
    displayPriority: 20,
    display: true,
    popupEnabled: true,
    shortDesc: 'Time to Next Port',
    longDesc: 'Time to Next Port',
    tooltip: 'The estimated time for the vessel to arrive at the next port',
    fieldType: 'number',
    highlightConfig: {
      highlightType: 'number',
      colorPalette: 'sequential',
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : secondsToDHM(value);
    },
  },
  arrival: {
    displayPriority: 50,
    popupEnabled: true,
    display: false,
    shortDesc: 'Arrival',
    longDesc: 'Arrival at Next Port',
    tooltip: 'The estimated arrival time at the next port of call',
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    highlightConfig: {
      highlightType: 'date',
      colorPalette: 'sequential',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : formatDate(value, 'DD MMM YY HH:mm');
    },
  },
  crewEarliestDueDate: {
    displayPriority: 50,
    popupEnabled: true,
    display: true,
    shortDesc: 'Contract End Date',
    longDesc: 'Crew Contract End Date',
    tooltip: 'The closest contract expiration date for all crew members.',
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    highlightConfig: {
      highlightType: 'date',
      colorPalette: 'qualitative_contract',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : formatDate(value, 'DD MMM YY HH:mm');
    },
  },

  cargoStatus: {
    displayPriority: 240,
    display: true,
    popupEnabled: true,
    shortDesc: 'Cargo Status',
    longDesc: 'Cargo Status',
    tooltip: `The current state of the vessel's cargo`,
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : VESSEL_CARGO_MAPPING[value as VesselCargoStatus] || value;
    },
  },

  speed: {
    displayPriority: 30,
    display: true,
    popupEnabled: true,
    shortDesc: 'Speed',
    longDesc: 'Vessel Speed',
    tooltip: 'The speed of the vessel',
    fieldType: 'number',
    highlightConfig: {
      highlightType: 'number',
      colorPalette: 'sequential',
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined, NaN],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value) + ' knots';
    },
  },
  nextPortName: {
    displayPriority: 40,
    popupEnabled: true,
    display: false,
    shortDesc: 'Next Port',
    longDesc: 'Next Port Name (AIS)',
    tooltip: 'The port name for the next port of call',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(String(value));
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  source: {
    displayPriority: 60,
    display: true,
    popupEnabled: true,
    shortDesc: 'Source',
    longDesc: 'Data Retrieved from',
    tooltip: 'The data source from which the vessel data is retrieved from',
    fieldType: 'enum',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  lastPortName: {
    displayPriority: 70,
    popupEnabled: true,
    display: false,
    shortDesc: 'Last Port',
    longDesc: 'Last Port Name (AIS)',
    tooltip: 'The port name for the previous port of call',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(String(value));
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  departure: {
    displayPriority: 80,
    popupEnabled: true,
    display: false,
    shortDesc: 'Departed',
    longDesc: 'Departure from Last Port',
    tooltip: 'The departure time from the previous port of call',
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    highlightConfig: {
      highlightType: 'date',
      colorPalette: 'sequential',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : formatDate(value, 'DD MMM YY hh:mm');
    },
  },
  flag: {
    displayPriority: 90,
    display: true,
    popupEnabled: true,
    shortDesc: 'Flag',
    longDesc: 'Flag State',
    tooltip: 'The flag state of the vessel',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(value);
    },
  },
  picLabel: {
    displayPriority: 100,
    display: true,
    popupEnabled: true,
    shortDesc: 'In-Charge',
    longDesc: 'Person In Charge',
    tooltip: 'Person in charge of the vessel',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Not Assigned Yet',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  course: {
    displayPriority: 110,
    popupEnabled: true,
    display: false,
    shortDesc: 'Course',
    longDesc: 'Vessel Course',
    tooltip: 'The current direction of the vessel heading',
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value) + '°';
    },
  },
  owner: {
    displayPriority: 120,
    popupEnabled: true,
    display: true,
    shortDesc: 'Owner',
    longDesc: 'Vessel Owner',
    tooltip: 'Vessel owner',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(value);
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  manager: {
    displayPriority: 130,
    popupEnabled: true,
    display: true,
    shortDesc: 'Manager',
    longDesc: 'Vessel Manager',
    tooltip: 'Vessel Manager',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : toTitleCase(value);
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  updatedAt: {
    displayPriority: 140,
    display: true,
    popupEnabled: true,
    shortDesc: 'Updated',
    longDesc: 'Vessel Updated At',
    tooltip: 'The time at which the data of the vessel was last updated on',
    fieldType: 'date',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : new Date(value).toLocaleDateString(
            'en-US',
            VesselFieldsConfig.dateDisplayOptions
          );
    },
  },
  portCallsLastChanged: {
    displayPriority: 150,
    display: true,
    popupEnabled: false,
    shortDesc: 'Portcalls Changed',
    longDesc: 'Portcalls Last Changed AT',
    tooltip:
      'The time at which the data on vessel port calls was last updated on',
    fieldType: 'date',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, new Date(0)],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : new Date(value).toLocaleDateString(
            'en-US',
            VesselFieldsConfig.dateDisplayOptions
          );
    },
  },
  status: {
    displayPriority: 160,
    display: true,
    popupEnabled: true,
    shortDesc: 'Status',
    longDesc: 'Current AIS Status',
    tooltip: 'Current AIS Status',
    fieldType: 'enum',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  type: {
    displayPriority: 180,
    display: true,
    shortDesc: 'Type',
    popupEnabled: true,
    longDesc: 'Type of Vessel',
    tooltip: 'Type of Vessel',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  nextPortCode: {
    displayPriority: 190,
    display: true,
    popupEnabled: true,
    shortDesc: 'Next Port',
    longDesc: 'Next Port LOCODE',
    tooltip: 'The LOCODE of the next port of call',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value).toUpperCase();
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  lastPortCode: {
    displayPriority: 200,
    display: true,
    popupEnabled: true,
    shortDesc: 'Last Port',
    longDesc: 'Last Port LOCODE',
    tooltip: 'The LOCODE of the previous port of call',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value).toUpperCase();
    },
    emptyValues: [null, ''],
    emptyValueReplacementStr: '(Unknown)',
  },
  dwt: {
    displayPriority: 210,
    display: true,
    popupEnabled: false,
    shortDesc: 'DWT',
    longDesc: 'Deadweight Tonnage',
    tooltip: 'Deadweight Tonnage',
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined, NaN],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  mmsi: {
    displayPriority: 220,
    display: true,
    popupEnabled: false,
    shortDesc: 'MMSI',
    longDesc: 'MMSI',
    tooltip: 'Vessel MMSI number',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown MMSI)',
    emptyValues: [null, ''],
  },
  addedBy: {
    displayPriority: 230,
    display: true,
    popupEnabled: false,
    shortDesc: 'Creator',
    longDesc: 'Added By',
    tooltip: 'The user that added this vessel',
    fieldType: 'string',
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    includedInCSV: true,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Greywing',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },

  // Non-display fields

  id: {
    displayPriority: 1000,
    display: false,
    popupEnabled: false,
    shortDesc: 'ID',
    longDesc: 'Vessel ID',
    tooltip: 'A unique identifier for this vessel',
    fieldType: 'number',
    includedInCSV: false,
    updateFrequencyMs: null,
    highlightConfig: undefined,
    emptyValues: [null],
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown ID)',
  },
  name: {
    displayPriority: 1010,
    display: false,
    popupEnabled: false,
    shortDesc: 'Name',
    longDesc: 'Vessel Name',
    tooltip: 'Vessel Name',
    fieldType: 'string',
    includedInCSV: true,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    highlightConfig: {
      highlightType: 'enum',
      colorPalette: 'qualitative',
    },
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '(Unknown Name)',
    emptyValues: [null, ''],
  },
  lat: {
    displayPriority: 1020,
    display: false,
    popupEnabled: false,
    shortDesc: 'Lat',
    longDesc: 'Latitude',
    tooltip: 'Current latitude of the vessel',
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
    emptyValueReplacementStr: '-',
  },
  lng: {
    displayPriority: 1030,
    display: false,
    popupEnabled: false,
    shortDesc: 'Lon',
    longDesc: 'Longitude',
    tooltip: 'Current longitude of the vessel',
    fieldType: 'number',
    includedInCSV: true,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [null, '', NaN],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  riskStatus: {
    displayPriority: 1040,
    display: false,
    popupEnabled: false,
    shortDesc: 'Risk',
    longDesc: 'Risk Status',
    tooltip: 'Risk status of the vessel',
    fieldType: 'enum',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: 'None',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  riskStatusColor: {
    displayPriority: 1050,
    display: false,
    popupEnabled: false,
    shortDesc: 'Risk Color',
    longDesc: 'Risk Status Color',
    tooltip: 'Risk status color of the vessel',
    fieldType: 'enum',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: 'None',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  CRY4GeneratedUUID: {
    displayPriority: 1060,
    display: false,
    popupEnabled: false,
    shortDesc: 'CRY4 ID',
    longDesc: 'CRY4 ID',
    tooltip: 'Unique identifier for CRY4',
    fieldType: 'string',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: 'None',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  image: {
    displayPriority: 1070,
    display: false,
    popupEnabled: false,
    shortDesc: 'Image',
    longDesc: 'Image URL',
    tooltip: 'An image of the vessel',
    fieldType: 'string',
    includedInCSV: false,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null],
    emptyValueReplacementStr: '-',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },
  crewHomeAirports: {
    displayPriority: 1080,
    display: false,
    popupEnabled: false,
    shortDesc: 'Crew Airports',
    longDesc: 'Crew Home Airports',
    tooltip: 'A list of home airports for the current crew',
    fieldType: 'object',
    includedInCSV: false,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined],
    emptyValueReplacementStr: 'None',
    getDisplayString: function (value) {
      if (this.emptyValues.includes(value))
        return this.emptyValueReplacementStr;

      let airportStrs: string[] = [];

      for (const key in value) {
        airportStrs.push(
          `${value[key].airport.text}: ${value[key].onsigners} on, ${value[key].offsigners} off`
        );
      }
      return airportStrs.join(', ');
    },
  },
  crewNationalities: {
    displayPriority: 1090,
    display: false,
    popupEnabled: false,
    shortDesc: 'Crew Country',
    longDesc: 'Crew Nationalities',
    tooltip: 'A list of crew nationalities for the current crew',
    fieldType: 'object',
    includedInCSV: true,
    updateFrequencyMs: SLOW_UPDATE_FREQUENCY_MS,
    emptyValues: [null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (value) {
      if (this.emptyValues.includes(value))
        return this.emptyValueReplacementStr;

      let nationalityStrs: string[] = [];

      for (const key in value) {
        nationalityStrs.push(`${value[key].country.text}: ${value[key].count}`);
      }
      return nationalityStrs.join(', ');
    },
  },
  routeExists: {
    displayPriority: 1100,
    display: false,
    popupEnabled: false,
    shortDesc: 'Route Exists',
    longDesc: 'Route Exists',
    tooltip: 'Indication if a route exists',
    fieldType: 'string',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [],
    emptyValueReplacementStr: 'No',
    getDisplayString: function (value) {
      return value ? 'Yes' : 'No';
    },
  },
  upcomingCrewChangeEvents: {
    displayPriority: 20,
    display: true,
    popupEnabled: false,
    shortDesc: 'Upcoming Crew Change Events',
    longDesc: 'Upcoming Crew Change Events',
    tooltip: 'A list of upcoming crew change events',
    fieldType: 'object',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: ['', null, undefined],
    emptyValueReplacementStr: 'Unknown',
    getDisplayString: function (events: CrewEvent[]) {
      const noun = events.length === 1 ? 'event' : 'events';
      return `${events.length} ${noun}`;
    },
  },
  tags: {
    displayPriority: 1200,
    display: false,
    popupEnabled: false,
    shortDesc: 'Vessel tags',
    longDesc: 'Vessel tags',
    fieldType: 'object',
    includedInCSV: false,
    updateFrequencyMs: FAST_UPDATE_FREQUENCY_MS,
    emptyValues: [],
    emptyValueReplacementStr: 'Unknown',
    tooltip: 'Vessel tags',
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : String(value);
    },
  },

  // Newer implementation
  vesselTags: {
    displayPriority: 1300,
    display: false,
    popupEnabled: false,
    shortDesc: 'Tags',
    longDesc: 'Vessel Tags',
    tooltip: 'Vessel Tags',
    fieldType: 'object',
    includedInCSV: false,
    updateFrequencyMs: GLACIAL_UPDATE_FREQUENCY_MS,
    getDisplayString: function (value) {
      return this.emptyValues.includes(value)
        ? this.emptyValueReplacementStr
        : (value as VesselTag[])?.map((o) => o.displayName).join(', ');
    },
    emptyValueReplacementStr: '',
    emptyValues: [null, ''],
  },
  // d: {
  //   displayPriority:          ,
  //   display:                  ,
  //   shortDesc:                ,
  //   longDesc:                 ,
  //   fieldType:                ,
  //   highlightConfig:
  //   includedInCSV:            ,
  //   updateFrequencyMs:        ,
  //   emptyValues:              ,
  //   emptyValueReplacementStr: ,
  // getDisplayString: function(value) {
  //   return this.emptyValues.includes(value) ? this.emptyValueReplacementStr : String(value)
  // },
  // },

  // ^^ Template for adding new fields
};

// Fields to be considered later
// 'picId' |
// 'tags';

export const HIGHLIGHTABLE_FIELDS: string[] = Object.keys(vesselFields).reduce<
  string[]
>((fields, field) => {
  if (vesselFields[field].highlightConfig) {
    fields.push(field);
  }
  return fields;
}, []);

export const ALL_POPUPABLE_FIELDS: string[] = Object.keys(vesselFields).reduce<
  string[]
>((fields, field) => {
  if (vesselFields[field].popupEnabled) {
    fields.push(field);
  }
  return fields;
}, Object.keys(compoundPopupFields));

export const ALL_SIDEPANEL_FIELDS: string[] = Object.keys(vesselFields).reduce<
  string[]
>((fields, field) => {
  if (vesselFields[field].display) {
    fields.push(field);
  }
  return fields;
}, Object.keys(compoundSidePanelFields));

export const hideForTMC = [
  'compound_riskAreaTracking',
  'compound_crewChangeEvents',
  'compound_savedPlans',
  'compound_incompletePlans',
  'compound_crewChangeNoEvents',
  'compound_crewHomeAirports',
  'compound_crewNationalities',
];
