import { toast } from 'react-toastify';

import sleep from './sleep';

type ToastProps = {
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error';
  placement?: 'left' | 'right'; // custom placement prop for the toaster
  delay?: number; // milliseconds delay to trigger the toaster
  testId?: string; // prop passed for e2e test
};

export const showToaster = ({
  testId,
  message,
  type = 'success',
  delay,
  placement,
}: ToastProps): void => {
  const isError = type === 'error';
  const triggerToaster = () =>
    toast(message, {
      type,
      toastId: testId,
      theme: 'colored',
      position: placement === 'left' ? 'bottom-left' : 'bottom-right',
      closeButton: isError,
      hideProgressBar: isError,
      autoClose: !isError ? 5000 : false, // persist the toaster for error
      closeOnClick: true,
      draggable: true,
      ...(!isError
        ? {
            pauseOnHover: true,
            progress: undefined,
          }
        : {}),
      style: {
        fontFamily: 'HK Grotesk, Roboto',
        fontWeight: 500,
        fontSize: '0.9rem',
      },
    });

  if (delay) {
    sleep(delay).then(triggerToaster);
  } else {
    triggerToaster();
  }
};
