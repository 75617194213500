import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FlotillaMapState, MapReady, RootState } from '../types';

type CounterState = FlotillaMapState & { isReady: MapReady };

export const initialMapState: CounterState = {
  loaded: false,
  vesselsLoaded: false,
  isReady: {
    vessel: false, // vessel layers are ready
    vesselIcons: false, // mapbox vessel icons
    route: false, // general route layers are ready
    map: false, // mapbox instance is ready
    unmount: false,
    forceStart: false, // this is used to allow the user to land on the UI without waiting for everything to load
  },
};

const mapStateSlice = createSlice({
  name: 'mapState',
  initialState: initialMapState,
  reducers: {
    setMapState: (state, action: PayloadAction<FlotillaMapState>) => ({
      ...state,
      ...action.payload,
    }),
    setMapReady: (
      state,
      action: PayloadAction<{ type: string; value: boolean }>
    ) => {
      const { type, value } = action.payload;
      return {
        ...state,
        isReady: { ...(state.isReady || {}), [type]: value },
      };
    },
  },
});

/* ----- selectors -----*/

export const selectMapState = ({ mapState }: RootState) => mapState;

/* ----- actions -----*/

export const { setMapState, setMapReady } = mapStateSlice.actions;

/* ----- reducer -----*/

export default mapStateSlice.reducer;
