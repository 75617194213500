import styled from 'styled-components/macro';

import {
  borderGray,
  gray50,
  gray60,
  iconGray,
  textGray,
  white,
} from 'lib/colors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const Time = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.1rem;
  text-align: center;
  color: ${textGray};
`;

const TimeWrapper = styled.div`
  margin-top: 50px;
  flex-shrink: 0;
`;

const Duration = styled(Time)`
  transform: rotate(270deg);
  font-size: 0.7rem;
  font-weight: 400;
  border: 1px solid ${gray50};
  border-radius: 4px;
  width: 90px;
  background: ${white};
  font-style: italic;
`;

const ConnectionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: ${borderGray};
  height: 100%;
  width: 2px;
  flex-shrink: 0;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid ${iconGray};
  background: ${white};
`;

type Props = {
  duration: string | undefined;
};

const ForwardIcon = styled(ChevronRightIcon)`
  font-size: 1rem !important;
  color: ${gray60};
`;

function Connection({ duration }: Props) {
  return (
    <ConnectionWrapper>
      <IconWrapper>
        <ForwardIcon />
      </IconWrapper>
      {duration && (
        <TimeWrapper>{duration && <Duration>{duration}</Duration>}</TimeWrapper>
      )}
    </ConnectionWrapper>
  );
}

export default Connection;
