import { ReactElement, memo } from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { useField } from 'formik';

type FormikCheckboxProps = CheckboxProps & {
  id: string;
  label: string | ReactElement;
};

function Checkbox(props: FormikCheckboxProps) {
  const { label, ...rest } = props;
  const [field, meta] = useField(props.id);
  return (
    <>
      <FormControlLabel
        label={props.label}
        control={<MuiCheckbox {...rest} {...field} checked={field.value} />}
      />

      {meta.touched && meta.error && (
        <FormHelperText error>{meta.error}</FormHelperText>
      )}
    </>
  );
}

export default memo(Checkbox);
