import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import max from 'lodash/max';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import values from 'lodash/values';
import moment from 'moment';

import { getStartOfDate } from 'lib/common';
import { fetchVesselCrewSchedules } from 'api/crew-matrix';
import { ScheduleMatrixEvents } from 'redux/types';

import { MONTHS_OF_YEAR } from '../constants';
import {
  CrewChangeSchedule,
  TimelineMonth,
  VesselScheduleDetails,
  VesselYearlyEvents,
} from '../types';

// format search query of crew details page
export const formatScheduleReqDates = (year?: number) => {
  if (year) {
    return {
      fromDate: `${year}-01-01T00:00:00.000Z`,
      toDate: moment([year]).endOf('year').toISOString(),
    };
  }
  const currentYear = new Date().getFullYear();
  const currentMonthStartDate = `${currentYear}-01-01T00:00:00.000Z`;
  // Instead of getting the 6 months before and after the current month, we fetch all 12 months of the current year
  const fromDate = moment(currentMonthStartDate).toISOString();
  const toDate = moment(currentMonthStartDate).endOf('year').toISOString();
  return { fromDate, toDate };
};

const getMonthOrder = (month: string) =>
  String(MONTHS_OF_YEAR.findIndex((item) => item === month) + 1);

export const isTodayInCalendar = (
  date: number,
  crewChangeSchedule: CrewChangeSchedule
) => {
  const CURRENT_DATE = new Date();
  return (
    CURRENT_DATE.getDate() === date &&
    crewChangeSchedule.month === MONTHS_OF_YEAR[CURRENT_DATE.getMonth()] &&
    crewChangeSchedule.year === CURRENT_DATE.getFullYear()
  );
};

export const getDaysOfMonth = (
  year?: number
): {
  [month: string]: {
    order: number;
    days: number;
  };
} => ({
  January: { order: 1, days: 31 },
  February: { order: 2, days: year && year % 4 ? 28 : 29 },
  March: { order: 3, days: 31 },
  April: { order: 4, days: 30 },
  May: { order: 5, days: 31 },
  June: { order: 6, days: 30 },
  July: { order: 7, days: 31 },
  August: { order: 8, days: 31 },
  September: { order: 9, days: 30 },
  October: { order: 10, days: 31 },
  November: { order: 11, days: 30 },
  December: { order: 12, days: 31 },
});

// get total of 24 months based on current month
export const getTimelineMonths = (): TimelineMonth[] => {
  const currentMonthNumber = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  // get number of months of previous year to be included in timeline
  const prevYearMonthCount = ((currentMonthNumber <= 4 && 12) ||
    (currentMonthNumber >= 5 && currentMonthNumber <= 8 && 6) ||
    (currentMonthNumber >= 9 && currentMonthNumber <= 12 && 0)) as number;
  // get number of months of next year to be included in timeline
  const nextYearMonthCount = (
    currentMonthNumber <= 4 && 0
      ? 0
      : (currentMonthNumber >= 5 && currentMonthNumber <= 8 && 6) ||
        (currentMonthNumber >= 9 && currentMonthNumber <= 12 && 12)
  ) as number;
  const timelineMonths = [
    // get later months of previous year based on prevYearMonthCount
    ...MONTHS_OF_YEAR.slice(-prevYearMonthCount).map((month) => ({
      label: `${month.slice(0, 3)} ${String(currentYear - 1).slice(2)}`,
      date: new Date(
        `${currentYear - 1}/${getMonthOrder(month)}/01`
      ).toISOString(),
    })),
    // get all the month of current year
    ...MONTHS_OF_YEAR.map((month) => ({
      label: `${month.slice(0, 3)} ${String(currentYear).slice(2)}`,
      date: new Date(`${currentYear}/${getMonthOrder(month)}/01`).toISOString(),
    })),
    // get earlier months of next year based on nextYearMonthCount
    ...(nextYearMonthCount
      ? MONTHS_OF_YEAR.slice(0, nextYearMonthCount).map((month) => ({
          label: `${month.slice(0, 3)} ${String(currentYear + 1).slice(2)}`,
          date: new Date(
            `${currentYear + 1}/${getMonthOrder(month)}/01`
          ).toISOString(),
        }))
      : []),
  ];

  return sortBy(timelineMonths, 'date');
};

export const formatCalendarDate =
  (crewChangeSchedule: CrewChangeSchedule) => (dayNum: number) => {
    const { month, year } = crewChangeSchedule;
    const monthNum = MONTHS_OF_YEAR.findIndex((text) => text === month) + 1;
    return moment(`${year}-${monthNum}-${dayNum}`, 'YYYY-MM-DD').format(
      'DD-ddd'
    );
  };

// util to reset crew change schedule
export const getInitialCrewChangeSchedule = () => {
  const CURRENT_DATE = new Date();
  return {
    year: CURRENT_DATE.getFullYear(),
    month: MONTHS_OF_YEAR[CURRENT_DATE.getMonth()],
    selectedDate: getStartOfDate(CURRENT_DATE.toISOString()),
  };
};

// util to update crew-change schedule page view with toggle update button
export const getCrewChangeSchedule = (year: number) => {
  const currentYear = new Date().getFullYear();
  // reset to initial crew change schedule for current year
  return year === currentYear
    ? getInitialCrewChangeSchedule()
    : {
        year,
        month: MONTHS_OF_YEAR[0],
        selectedDate: getStartOfDate(
          moment(String(year), 'YYYY').toISOString()
        ),
      };
};

export const scheduleUtils = (crewChangeSchedule: CrewChangeSchedule) => ({
  // update picker month based on arrow click
  getProgressiveUpdate: (type: 'add' | 'subtract'): CrewChangeSchedule => {
    const { year, month } = crewChangeSchedule;
    const monthIndex = MONTHS_OF_YEAR.findIndex((name) => name === month);
    const newMonthIndex =
      type === 'add'
        ? (monthIndex < MONTHS_OF_YEAR.length - 1 && monthIndex + 1) || 0
        : (monthIndex <= 0 && MONTHS_OF_YEAR.length - 1) || monthIndex - 1;
    const newMonth = MONTHS_OF_YEAR[newMonthIndex];
    const newYear =
      (newMonth === 'January' && type === 'add' && year + 1) ||
      (newMonth === 'December' && type === 'subtract' && year - 1) ||
      year;
    const newMonthOrder = ('0' + (newMonthIndex + 1)).slice(-2);

    return {
      year: newYear,
      month: newMonth,
      selectedDate: `${newYear}-${newMonthOrder}-01T00:00:00.000Z`,
    };
  },
  // update picker month with direct month selection
  getPickerUpdate: (newDate: Date): CrewChangeSchedule => {
    const newYear = newDate.getFullYear();
    const newMonthIndex = newDate.getMonth();
    const newMonth = MONTHS_OF_YEAR[newMonthIndex];
    const newMonthOrder = ('0' + (newMonthIndex + 1)).slice(-2);
    return {
      year: newYear,
      month: newMonth,
      selectedDate: `${newYear}-${newMonthOrder}-01T00:00:00.000Z`,
    };
  },
});

export const isBeforeToday = (
  date: number,
  crewChangeSchedule: CrewChangeSchedule
) => {
  const { year, month } = crewChangeSchedule;
  const monthOrder = MONTHS_OF_YEAR.findIndex((name) => name === month) + 1;
  const dateStr = `${year}-${monthOrder}-${date}`;
  const dateObj = moment(dateStr, 'YYYY-MM-DD');
  return !dateObj.isSame(moment(), 'day') && dateObj.isBefore(moment());
};

// format schedule events based on months
// for each month get events for each vessel
export const formatEventsForYearlyView = (
  storedEvents: ScheduleMatrixEvents
): { [vesselId: string]: VesselYearlyEvents } => {
  // const allEvents = flatten(values(storedEvents));
  const groupedEventsByVessel = groupBy(
    flatten(values(storedEvents)),
    'vessel.id'
  );
  // map values to get events for each vessel
  return mapValues(groupedEventsByVessel, (events) => {
    const monthlyEvents = groupBy(events, (event) =>
      moment(event.eventDate).format('MMMM-YYYY')
    );
    return {
      ...events[0].vessel,
      events: monthlyEvents,
      // highest number of events in a month
      rowCount: Math.round(
        (max(map(values(monthlyEvents), 'length')) || 1) / 2
      ),
    };
  });
};

// util used to fetch vessel crew schedules for multiple vessels
// primiarily for ranks comparison feature
export const getVesselsCrewSchedules = async (
  vesselIds: number[],
  signal?: AbortSignal
) => {
  const promises = vesselIds.map(async (vesselId) =>
    fetchVesselCrewSchedules(vesselId, signal)
  );
  const responses = await Promise.all(promises);
  return responses.reduce<VesselScheduleDetails>(
    (acc, { result }) => (result ? { ...acc, ...result } : acc),
    []
  );
};

// all ranks of vessels in the response
export const getRankOptions = (response: VesselScheduleDetails | null) =>
  uniq(
    flatten(
      values(mapValues(response, ({ crewSchedules }) => keys(crewSchedules)))
    )
  );
