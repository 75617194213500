import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import Bowser from 'bowser';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

import { toggleCopilot } from 'redux/actions';

import { useAppDispatch } from 'hooks';
import { RootState } from 'redux/types';
import { trackUserAction } from 'lib/amplitude';
import { TRACK_SEAGPT_START } from 'utils/analytics/constants';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { fetchAssistedCrewChangeConvosAsync } from 'redux/thunks';
import { isProduction } from 'lib/environments';

const createWorker = createWorkerFactory(
  () => import('../../workers/seagpt.worker')
);

function useSeaGPTSocketIO(isEnabled: boolean) {
  const dispatch = useAppDispatch();
  const worker = useWorker(createWorker);
  useEffect(() => {
    const token = getAuthToken().token;
    if (!token) return;
    isEnabled && worker.initEmailSocket(token, dispatch);
    return () => {
      isEnabled && worker.clearEmailSocket();
    };
  }, [dispatch, worker, isEnabled]);
  return null;
}

function useAssistedCrewChanges(isEnabled: boolean) {
  const dispatch = useAppDispatch();
  const scopeFilter = useSelector(
    ({ copilot }: RootState) => copilot.emailControls.companyFilter
  );
  useSeaGPTSocketIO(!isProduction);
  useEffect(() => {
    if (!isEnabled) return;
    dispatch(fetchAssistedCrewChangeConvosAsync({ scope: scopeFilter }));
  }, [isEnabled]); // eslint-disable-line

  return null;
}

function useHotKeyInit() {
  const dispatch = useAppDispatch();
  const userInfo = useSelector((state: RootState) => state.settings.userInfo);
  const [hotKey, setHotKey] = useState<string>('ctrl+k');
  const copilotIsActive = useSelector(
    (state: RootState) => state.copilot.active
  );

  const disableChromeSearchHotkey = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.keyCode === 75) e.preventDefault();
  };

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isMac = browser.getOS().name === 'macOS';
    if (isMac) {
      setHotKey('meta+k');
    }

    document.addEventListener('keydown', disableChromeSearchHotkey);

    return () => {
      document.removeEventListener('keydown', disableChromeSearchHotkey);
    };
  }, []);

  useHotkeys(
    hotKey,
    () => {
      if (!userInfo?.access['SeaGPT']) return;
      trackUserAction(TRACK_SEAGPT_START('keyboard-shortcut'));
      !copilotIsActive && dispatch(toggleCopilot('full'));
    },
    [copilotIsActive, userInfo, dispatch]
  );
}

export { useHotKeyInit, useAssistedCrewChanges };
