import { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { SelectChangeEvent } from '@mui/material';
import { FlightBookingTxStateCommon } from '@greywing-maritime/frontend-library/dist/types/flightBooking';

import Select from '../components/Select';

import { useAppDispatch } from 'hooks';

import { handleManageBookingModal, updateBookingFilters } from 'redux/actions';
import { selectBookingFilters } from 'redux/reducers/booking';
import { BookingFilterLabels } from 'redux/types';

import { gray50 } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { CloseButton } from '../common';
import CloseIcon from '@mui/icons-material/Close';

const Wrapper = styled.nav`
  column-gap: 0.5rem;
  // min-height: 48px;
  border-bottom: ${gray50};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 0.5rem;

  h2 {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
`;

const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const MobileWrapper = styled.div`
  display: none;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    display: flex;
    width: 100%;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  @media screen and (max-width: ${BREAK_POINT_XS}) {
    #inputs {
      display: none;
    }
  }
`;

export const PAST_BOOKING_STATES = [
  FlightBookingTxStateCommon.FLIGHT_COMPLETED,
  FlightBookingTxStateCommon.CANCELLED,
  FlightBookingTxStateCommon.EXPIRED,
];

export const UPCOMING_BOOKING_STATES = [
  FlightBookingTxStateCommon.FLIGHT_SELECTED,
  FlightBookingTxStateCommon.FLIGHT_HELD,
  FlightBookingTxStateCommon.TRAVELER_INFO_FILLED,
  FlightBookingTxStateCommon.FLIGHT_TICKETED,
];

const FILTER_OPTIONS: {
  value: string;
  states?: FlightBookingTxStateCommon[];
  label: BookingFilterLabels;
}[] = [
  {
    label: 'Upcoming Bookings',
    states: UPCOMING_BOOKING_STATES,
    value: UPCOMING_BOOKING_STATES.join(','),
  },
  {
    label: 'Past Bookings',
    states: PAST_BOOKING_STATES,
    value: PAST_BOOKING_STATES.join(','),
  },
  { label: 'All Bookings', states: undefined, value: 'all' },
];

function Header() {
  const dispatch = useAppDispatch();
  const filters = useSelector(selectBookingFilters);
  const handleClose = () => {
    dispatch(handleManageBookingModal(false));
  };

  const handleChange = useCallback(
    (event: SelectChangeEvent<any>) => {
      dispatch(
        updateBookingFilters({
          field: 'states',
          value: FILTER_OPTIONS.find((o) => o.value === event.target.value)
            ?.states,
        })
      );
    },
    [dispatch]
  );

  return (
    <Wrapper>
      <MainHeader>
        <h2>Manage Travel Bookings</h2>
        <ActionsWrapper>
          <div id="inputs">
            <Select
              id="date"
              size="small"
              variant="standard"
              label="filter by"
              value={filters.states?.join(',') || 'all'}
              options={FILTER_OPTIONS}
              onChange={handleChange}
              sx={{ width: 180 }}
            />
          </div>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </ActionsWrapper>
      </MainHeader>
      <MobileWrapper>
        <Select
          id="date"
          size="small"
          variant="standard"
          label="filter by"
          value={filters.states?.join(',') || 'all'}
          options={FILTER_OPTIONS}
          onChange={handleChange}
          sx={{ width: 180 }}
        />
      </MobileWrapper>
    </Wrapper>
  );
}

export default memo(Header);
