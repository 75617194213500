import { ModalProps } from 'utils/types';
import CopyLinkModal from './CopyLinkModal';
import UserNotesModal from './UserNotesModal';

function ShareLinkModal({ modal, setModal }: ModalProps) {
  if (!modal?.type) {
    return null;
  }

  if (modal.type === 'copyLink') {
    return <CopyLinkModal {...modal.data} closeModal={() => setModal(null)} />;
  }

  if (modal.type === 'addUserNotes') {
    return <UserNotesModal setModal={setModal} />;
  }

  return null;
}

export default ShareLinkModal;
