import { Tooltip } from 'components/shared';
import { TextContent, TextWrapper, TooltipWrapper } from './common';
import { CommonProps } from './types';

function DelayComponent({ details, crewType }: CommonProps) {
  if (!details) {
    return null;
  }

  const { difference, formattedDelay: delay } = details;

  const getTooltipContent = () => {
    // negative `difference` indicates:
    // onsigner arriving after vessel ETD or offsigner departing before vessel ETA
    if (difference < 0) {
      return crewType === 'onsigner' ? (
        <span>Onsigner is arriving too late to board vessel</span>
      ) : (
        <span>Offsigner departure time is before vessel ETA</span>
      );
    }

    return (
      <TooltipWrapper>
        <TextContent>
          Delay between event & flight: <b>{delay}</b>
        </TextContent>
      </TooltipWrapper>
    );
  };

  return delay !== '-' ? (
    <Tooltip content={getTooltipContent()}>
      <TextWrapper>{delay}</TextWrapper>
    </Tooltip>
  ) : (
    <TextWrapper>{delay}</TextWrapper>
  );
}

export default DelayComponent;
