import { memo } from 'react';
import Stack from '@mui/material/Stack';
import styled from 'styled-components/macro';

import AwesomeSlider, { Thumb } from 'components/shared/AwesomeSlider';
import { StyledValueLabel } from '../../../common/PortSlider';

const SliderWrapper = styled.div`
  width: 100px;
  margin-left: 10px;
  margin-right: 20px;
  flex-shrink: 0;
`;

type Props = {
  value: number;
  updateValue: (value: number) => void;
};

const ThumbComponent = (props: any) => {
  const { value } = props.ownerState;
  const modifiedValue = `${value}W`;
  return <Thumb {...props} modifiedValue={modifiedValue} />;
};

const ValueLabelComponent = (props: any) => {
  return <StyledValueLabel {...props} value="ETA Limit" />;
};

function ETASlider({ value, updateValue }: Props) {
  return (
    <SliderWrapper>
      <Stack spacing={2} direction="row" alignItems="center">
        <AwesomeSlider
          step={1}
          min={2}
          max={12}
          value={value}
          size="small"
          data-id="e2e_port-eta-limit"
          onChange={(_, newValue) => updateValue(newValue as number)}
          valueLabelDisplay="on"
          components={{
            Thumb: ThumbComponent,
            ValueLabel: ValueLabelComponent,
          }}
        />
      </Stack>
    </SliderWrapper>
  );
}

export default memo(ETASlider);
