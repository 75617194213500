// for staging instance at https://v2-develop.grey-wing.com/
export const isStagingInstance = process.env.REACT_APP_STAGING_ENV === 'YES';
// for demo instance at https://v2-edge.grey-wing.com/
export const isDemoInstance = process.env.REACT_APP_DEMO_ENV === 'YES';
// for production instance at https://v2.grey-wing.com/
// since staging & demo instances have `REACT_APP_NETLIFY_CONTEXT` as `production`
// we need to confirm that the environment is not either staging or demo
// hide experimental features for this instance/environment
export const isProduction =
  process.env.REACT_APP_NETLIFY_CONTEXT === 'production' &&
  !(isStagingInstance || isDemoInstance);

export const isDeployPreview =
  process.env.REACT_APP_NETLIFY_CONTEXT === 'deploy-preview';

export const isDevelopment = process.env.NODE_ENV === 'development';

export const showLogger = process.env.REACT_APP_REDUX_LOGGER === 'yes';

export const getEnvironment = () => {
  switch (true) {
    case isProduction:
      return 'production';

    case isDeployPreview:
      return 'deploy-preview';

    case isStagingInstance:
      return 'staging';

    case isDemoInstance:
      return 'demo';

    case isDevelopment:
      return 'development';

    default:
      return 'no-env-set';
  }
};
