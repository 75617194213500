import { useEffect, useState } from 'react';

import { FlightSearchResult } from 'utils/types';
import { useSelector } from 'react-redux';
import { selectFlotillaSearch } from 'redux/selectors';

const useFlightSearch = (isFlightSearch: boolean) => {
  const {
    flightSearch: { loading, searchQuery, flights },
  } = useSelector(selectFlotillaSearch);
  const [results, setResults] = useState<FlightSearchResult | null>(null);

  useEffect(() => {
    if (!isFlightSearch) {
      setResults(null);
      return;
    }

    setResults({
      searchType: 'searchFlights',
      searchFieldName: 'quickfly',
      searchFieldDescription: '',
      priority: 10,
      filterLabel: 'search flights for',
      results: (searchQuery && flights) || undefined,
    });
  }, [isFlightSearch, searchQuery, flights]);

  return { results, isLoading: loading };
};

export default useFlightSearch;
