import { ChangeEvent, Dispatch, memo, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components/macro';

import { useAppDispatch, useMobile } from 'hooks';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { updateColumnVisibility } from 'redux/actions';
import { RootState } from 'redux/types';

import { MOBILE_HIDDEN_COLUMNS } from '../../Flights/Header';
import {
  MenuDescription,
  MenuFiltersWrapper,
  MenuSeparator as Divider,
  MenuTitle,
} from '../../../common';

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;

  .MuiFormControlLabel-label {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-left: 0.5rem;
    font-weight: 500;
  }
`;

type Props = {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
};

function ColumnsMenu({ anchorEl, setAnchorEl }: Props) {
  const dispatch = useAppDispatch();
  const columnVisibility = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.columnVisibility
  );
  const isMobile = useMobile(BREAK_POINT_XS);

  const { crew: crewColumns } = columnVisibility || {};
  const columns = useMemo(
    () =>
      isMobile
        ? (crewColumns || []).filter(
            ({ field }) => !MOBILE_HIDDEN_COLUMNS.includes(field)
          )
        : crewColumns || [],
    [isMobile, crewColumns]
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    selected: boolean
  ) => {
    const updatedColumns = (crewColumns || []).map((columnItem) =>
      columnItem.name === event.target.name
        ? { ...columnItem, selected }
        : columnItem
    );
    dispatch(updateColumnVisibility({ crew: updatedColumns }));
  };

  return (
    <Menu
      id="columns-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      TransitionComponent={Fade}
      MenuListProps={{
        'aria-labelledby': 'fade-button',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MenuTitle>Column Visibility</MenuTitle>

      <Divider />

      <MenuFiltersWrapper>
        <MenuDescription>
          Select the columns to be visible in the flights table.
        </MenuDescription>

        <FormWrapper>
          {columns.map(({ name, selected, fixed }, index) => {
            return (
              <FormControlLabel
                key={`${name}_${index}`}
                disabled={fixed}
                control={
                  <Checkbox
                    checked={selected}
                    onChange={handleChange}
                    name={name}
                  />
                }
                label={name.toUpperCase()}
              />
            );
          })}
        </FormWrapper>
      </MenuFiltersWrapper>
    </Menu>
  );
}

export default memo(ColumnsMenu);
