import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import { getVesselPortCalls } from 'api/flotilla';
import { updateVesselPortCalls } from 'redux/actions';
import { AppDispatch, FutureRoute } from 'redux/types';
import { getVesselWithPortCallPositions } from 'utils/routes';
import { PortCallV2CommonUTC, SearchedPort } from 'utils/types';
import {
  CalculatedVesselRoute,
  GetVesselRoute,
} from 'utils/types/route-calculator';
import { Port, PortRequest } from 'utils/types/crew-change-types';
import { getEarliestDate } from 'utils/dates';
import {
  JourneyPort,
  JourneyVessel,
} from 'components/SidePanel/VesselCourse/types';
import { formatPortDates, getAlternateDate, includePortETA } from './ports';
import {
  MergedPort,
  MergedReadOnlyPort,
  PortDateType,
  ReadOnlyPort,
  RoutePort,
  RoutePortOrVessel,
  VesselPath,
  VesselRoute,
} from '../types';

const getRouteReturnValue = (
  routeDetails: CalculatedVesselRoute,
  waypoints: RoutePort[]
): VesselRoute => {
  const {
    route,
    calculation: { posRoute },
  } = routeDetails;
  return {
    waypoints,
    posRoute,
    path: route[0].geometry as VesselPath,
    distances: posRoute.map(({ distance }) => distance),
    unit: posRoute?.[0]?.properties?.unit || 'NM',
  };
};

const refetchPortCalls = async ({
  vesselId,
  dispatch,
}: {
  vesselId: number;
  dispatch: AppDispatch;
}) => {
  const response = await getVesselPortCalls(vesselId);
  if (response.success && response.portCalls) {
    dispatch(updateVesselPortCalls({ vesselId, ...response }));
    return response.portCalls;
  }
  return null;
};

// gets route details for planning vessel only during initialization
export const getVesselRoute = async (
  vessel: Vessel,
  portCall: PortCallV2CommonUTC[] | undefined,
  fetchRoute: GetVesselRoute,
  dispatch: AppDispatch
): Promise<VesselRoute | null> =>
  new Promise(async (resolve) => {
    let portCallToUse: PortCallV2CommonUTC[] | null = portCall || null;
    if (!portCall) {
      portCallToUse = await refetchPortCalls({ vesselId: vessel.id, dispatch });
      // If it still failed to fetch
      if (!portCallToUse) {
        resolve(null);
        return;
      }
    }
    const initialPortRoutes = createInitialRoutePort(vessel, portCallToUse!);
    const waypoints = getVesselWithPortCallPositions(portCallToUse!, vessel);
    const routeDetails = await fetchRoute({
      waypoints,
      connectAllWaypoints: true,
    });
    resolve(getRouteReturnValue(routeDetails, initialPortRoutes));
  });

export const updateVesselRoute = async (
  journey: FutureRoute,
  vessel: Vessel,
  fetchRoute: GetVesselRoute
): Promise<VesselRoute> => {
  const routePorts = journey.filter(
    ({ componentType }) => componentType === 'port'
  ) as RoutePort[];

  const waypoints = journey.reduce<WaypointWithProperties[]>(
    (processed, item) => {
      const { componentType, ...restOfVessel } = item;
      if (componentType === 'vessel') {
        processed.push({
          coor: [vessel.lng, vessel.lat],
          properties: {
            componentType,
            segment: 'future',
            ...restOfVessel,
          },
        });
        return processed;
      }
      const { lng, lat, ...restOfPort } = item as JourneyPort;
      if (!lng || !lat) return processed;
      processed.push({
        coor: [lng, lat],
        properties: {
          ...restOfPort,
          segment: '',
        },
      });
      return processed;
    },
    []
  );

  const routeDetails = await fetchRoute({
    waypoints,
    connectAllWaypoints: true,
  });
  return getRouteReturnValue(routeDetails, routePorts);
};

const createInitialRoutePort = (
  vessel: Vessel,
  portCalls: PortCallV2CommonUTC[]
) => {
  return portCalls
    .reduce<(RoutePortOrVessel & { dateToSort: string })[]>(
      (acc, portCall: PortCallV2CommonUTC) => {
        const dateToSort = getEarliestDate([portCall.utcETA, portCall.utcETD]);
        // this will only process routes in the future
        if (!dateToSort) return acc;
        if (dateToSort < vessel.updatedAt) return acc;
        acc.push({
          index: null,
          componentType: 'port',
          displayName: portCall.displayName,
          eta: portCall.eta ? String(portCall.eta) : undefined,
          etd: portCall.etd ? String(portCall.etd) : undefined,
          port: {
            locode: portCall.portDict?.locode || portCall.portLocode,
            name: portCall.portDict?.displayName || portCall.displayName,
          },
          dateToSort,
          type: portCall.type,
          lng: portCall.lng || undefined,
          lat: portCall.lat || undefined,
          order: 0,
          added: false,
        });
        return acc;
      },
      []
    )
    .sort((a, b) => {
      if (a.dateToSort && b.dateToSort) {
        return moment(b.dateToSort).diff(moment(a.dateToSort));
      }
      return 0;
    })
    .map(({ dateToSort, ...others }, index) => ({
      ...others,
      index: index + 1,
      order: index + 1,
    })) as RoutePort[];
};

const getCCVesselJourney = (
  vessel: Vessel,
  portCalls: RoutePort[]
): RoutePortOrVessel[] => {
  const { id: vesselId, status, course, updatedAt } = vessel;
  if (!portCalls.length) {
    return [
      {
        componentType: 'vessel',
        type: 'vessel',
        vesselId,
        status,
        course,
      } as JourneyVessel,
    ];
  }

  const vesselObject: JourneyVessel & { dateToSort: string } = {
    index: null,
    componentType: 'vessel',
    type: 'vessel',
    status,
    course,
    vesselId,
    dateToSort: updatedAt,
  };

  const final = portCalls
    .reduce<(RoutePortOrVessel & { dateToSort: string })[]>(
      (acc, portCall: RoutePort) => {
        if (portCall.type === 'vessel') return acc;
        const journeyPort = portCall as JourneyPort;
        // not all situations will have timezone data
        const sortEta = journeyPort.utcETA || journeyPort.eta;
        const sortEtd = journeyPort.utcETD || journeyPort.etd;
        const dateToSort = getEarliestDate([sortEta, sortEtd]);
        // this will only process routes in the future
        if (!dateToSort) return acc;
        if (dateToSort < updatedAt) return acc;
        acc.push({
          index: null,
          componentType: 'port',
          displayName: journeyPort.displayName,
          eta: journeyPort.eta ? String(journeyPort.eta) : undefined,
          etd: journeyPort.etd ? String(journeyPort.etd) : undefined,
          utcETA: journeyPort.utcETA,
          utcETD: journeyPort.utcETD,
          port: journeyPort.port,
          dateToSort,
          type: journeyPort.type,
          lng: journeyPort.lng || undefined,
          lat: journeyPort.lat || undefined,
          order: 0,
          added: false,
        });
        return acc;
      },
      [vesselObject]
    )
    .sort((a, b) => {
      if (a.dateToSort && b.dateToSort) {
        return moment(b.dateToSort).diff(moment(a.dateToSort));
      }
      return 0;
    })
    .map(({ dateToSort, ...others }) => others);

  // Set the index numbers to match the display on the map
  let portIndex = final.length - 1;
  return final.map((portCalls) => {
    if (portCalls.componentType === 'port') {
      portIndex -= 1;
      return {
        ...portCalls,
        index: portIndex + 1,
      };
    }
    return portCalls;
  });
};

export const calculateJourney = (
  vessel: Vessel,
  route: VesselRoute | null
): RoutePortOrVessel[] => {
  if (!route) {
    return [];
  }
  const { waypoints: portCalls } = route;
  const journey = getCCVesselJourney(vessel, portCalls);
  return [...journey].reverse();
};

// attaches distance & time
// distance -> distance in NM of a port from previous one
// time -> time in seconds to reach current port from previous
export const addDistanceAndTime = (
  ports: RoutePort[],
  distances: number[] = []
): RoutePort[] =>
  ports.map((port, index) => {
    const distance = distances[index];
    const prevPort = index > 0 && ports[index - 1];
    const currPortETA = port.eta || getAlternateDate('eta', port.etd!);

    if (!prevPort) {
      const timeDiff = moment(currPortETA).diff(moment(), 'hours');
      return {
        ...port,
        distance,
        time: timeDiff > 0 ? timeDiff * 3600 : 0,
      };
    }

    const prevPortETD = prevPort.etd || getAlternateDate('etd', prevPort.eta!);
    const timeDiff = moment(currPortETA).diff(moment(prevPortETD), 'hours');
    return { ...port, distance, time: timeDiff * 3600 };
  });

export const getEmptyPort = (order: number = 0): RoutePort => ({
  index: null,
  displayName: '',
  port: { name: null, locode: null },
  componentType: 'port',
  eta: undefined,
  etd: undefined,
  type: 'User Input',
  added: true,
  order,
});

export const formatRoutePorts = (ports: RoutePort[]): RoutePort[] => {
  const filteredPorts = ports.filter(
    ({ type }) => type.toUpperCase() === 'MAERSK'
  );
  return (filteredPorts.length ? filteredPorts : ports).map((port, index) => ({
    ...port,
    added: false,
    order: index + 1,
  }));
};

export const sortRoutePorts = (ports: RoutePort[]) =>
  [...ports]
    // sort based on port ETA
    .sort((a, b) =>
      (a.eta || getAlternateDate('eta', a.etd!)).localeCompare(
        b.eta || getAlternateDate('eta', b.etd!)
      )
    )
    .map((port, index) => ({ ...port, index: index + 1, order: index + 1 }));

export const getInvalidMessage = (
  port?: RoutePort,
  ports?: RoutePort[]
): string => {
  if (!port) {
    return 'No available port.';
  }

  const currentTime = moment();
  const etaFromPast = moment(port.eta).diff(currentTime, 'days') < 0;
  const etdFromPast = moment(port.etd).diff(currentTime, 'days') < 0;
  // check if port name or date is invalid
  const invalidNameOrDate =
    !port.displayName || !(port.eta || port.etd) || etaFromPast || etdFromPast;
  // check if ETA is after ETD, if both are available
  const isETAAfterETD =
    port.eta && (port.etd ? moment(port.eta).isAfter(port.etd) : !port.eta);
  // check if the added port has overlapping ETA/ETD
  // and find for which port it's overlapping
  const { isOverlapping, port: overlappedPort } = (ports || [])?.reduce<{
    isOverlapping?: boolean;
    port?: RoutePort;
  }>((acc, currPort) => {
    const { eta, etd } = port;
    const portETADateObj = moment(eta);
    const portETDDateObj = moment(etd);
    const currPortETADateObj = moment(currPort.eta);
    const currPortETDDateObj = moment(currPort.etd);
    const isOverlappingETA =
      // check if port ETA is the same or after current port ETA
      portETADateObj.isAfter(currPortETADateObj) &&
      // check if port ETD is before current port ETD
      portETADateObj.isBefore(currPortETDDateObj);
    const isOverlappingETD =
      // check if port ETD is before current port ETD
      portETDDateObj.isAfter(currPortETADateObj) &&
      // check if port ETD before current port ETD
      portETDDateObj.isBefore(currPortETDDateObj);
    const isOverlapping =
      port.port?.locode !== currPort.port?.locode &&
      (isOverlappingETA || isOverlappingETD);

    return isOverlapping ? { isOverlapping, port: currPort } : acc;
  }, {});

  return (
    (invalidNameOrDate && 'Invalid port name or date.') ||
    (isETAAfterETD && 'Invalid ETA - port ETA is after ETD') ||
    (isOverlapping &&
      `This port's ETA/ETD overlaps with ${
        overlappedPort!.displayName
      }. Please update.`) ||
    ''
  );
};

export const addPortToRow = (newPort: RoutePort, routePorts: RoutePort[]) => {
  const ports = uniqBy([newPort, ...routePorts], 'order');
  const invalidMessage = getInvalidMessage(newPort, routePorts);
  return { ports, invalidMessage };
};

export const getPreviousPort = (order: number, ports: RoutePort[]) =>
  ports.find((port) => {
    const diff = order - port.order;
    return diff > 0 && diff <= 1;
  });

// Minimum selectable date in picker.
// For ETA, it's previous vessel's ETD or today (if first items in table)
// For ETD, it's current ETA in other input or previous vessel's ETD or today (based on availability)
export const getMinDate = (
  type: PortDateType,
  currPort: RoutePort,
  prevPort: RoutePort | undefined
) => {
  const today = new Date();
  const prevDate = prevPort?.etd || prevPort?.eta;
  const minETADate = (prevDate && new Date(prevDate)) || today;
  const minETDDate = currPort.eta ? new Date(currPort.eta) : minETADate;
  return type === 'eta' ? minETADate : minETDDate;
};

// Minimum selectable date in picker.for port ETA
export const getMaxDate = (type: PortDateType, port: RoutePort) => {
  if (type === 'etd' || !port.etd) {
    return;
  }
  return new Date(port.etd);
};

export const isRouteUpdated = (
  newRoute: VesselRoute | undefined,
  portRequest: PortRequest | null
) => {
  const { waypointPortLocodes: existingLocodes = [] } = portRequest || {};
  const newLocodes = (newRoute?.waypoints || [])
    .map(({ port }) => port?.locode)
    .filter(Boolean);
  const routeUnchanged =
    existingLocodes.length === newLocodes.length &&
    newLocodes.every((locode) => existingLocodes.includes(locode!));

  return !routeUnchanged;
};

export const portConversion = {
  // the response from port calls has a different format than searched ports
  // convert before using
  routeToSearched: (port: RoutePort) =>
    ({
      id: port.order,
      text: port.displayName || '',
      locode: port.port.locode || '',
      name: port.port.name || port.displayName!,
      lat: port.lat!,
      lng: port.lng!,
      order: port.order,
    } as SearchedPort),
  // Convert the searched port back to the same format stored in RoutePorts
  searchedToRoute: (port: SearchedPort, oriPort: RoutePort) => ({
    ...oriPort,
    port: { name: port.name, locode: port.locode },
    displayName: port.text,
    lat: port.lat,
    lng: port.lng,
  }),
};

// merge route-ports with selected ports (in ports table)
// to prepare ports with multiple ETAs, if available
export const mergePortsWithRoute = (
  ports: (Port | ReadOnlyPort)[],
  route: RoutePort[],
  // number of weeks to filter out ports
  // available for report-view/saved-plans only
  etaLimit?: number
) =>
  ports.reduce<(MergedPort | MergedReadOnlyPort)[]>((acc, port) => {
    const matchedPorts = uniqBy(
      route.filter(
        ({ skipped, port: { locode } }) => !skipped && locode === port.locode
      ),
      'eta' // prevent possible duplicate ETAs
    );
    const portsWithMultipleETA = matchedPorts.length
      ? matchedPorts
          .filter(({ eta }) =>
            etaLimit ? includePortETA(eta, etaLimit) : true
          )
          .map(({ eta, etd }) => ({
            ...port,
            ...formatPortDates({ eta, etd }), // use given ETA/ETD, otherwise fallback to default
            uniqETA: matchedPorts.length > 1 ? eta : undefined,
          }))
      : [port];

    return [...acc, ...portsWithMultipleETA];
  }, []);
// .sort((a, b) => (a.eta || '').localeCompare(b.eta || ''));
