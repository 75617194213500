import styled from 'styled-components/macro';

export const TooltipWrapper = styled.div`
  padding: 0.25rem;
`;

export const TextWrapper = styled.span`
  margin-left: -4px;
`;

export const TextContent = styled.div`
  margin-bottom: 1px;
  &:last-child {
    margin-bottom: 0;
  }
`;
