import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components/macro';
import type { CrewChangePlanSummaryResp } from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';

import { useAppDispatch } from 'hooks';
import { blue, gray50, gray80, green, purple, red } from 'lib/colors';
import { BREAK_POINT_M, BREAK_POINT_SM, BREAK_POINT_XL } from 'lib/breakpoints';
import { CREW_CHANGE_CANCEL_MESSAGE } from 'lib/constants';
import { selectCrewChangeEvent } from 'redux/actions';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { openReadOnlyPanel } from 'utils/read-only-cc-plans';
import { getDefaultReportTitle } from 'components/CrewChangePanel/helpers';

import { OffsignerIcon, OnsignerIcon } from 'components/icons';
import { Tooltip } from 'components/shared';
import ActionMenu from './ActionMenu';
import AnchorIcon from '@mui/icons-material/Anchor';
import ListAltIcon from '@mui/icons-material/ListAlt';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div<{
  $isOpen: boolean;
  $completed: boolean;
  $recentlyAdded?: boolean;
}>`
  cursor: pointer;
  transition: background 100ms linear;
  &:hover {
    ${({ $isOpen, $completed }) =>
      !$isOpen &&
      `
      background: ${$completed ? `${green}20` : `${red}20`};
    `};
  }
  ${({ $isOpen }) =>
    $isOpen &&
    `
    pointer-events: none;
  `};
  ${({ $recentlyAdded, $completed }) =>
    $recentlyAdded &&
    `
      @keyframes blink {
        0% { background: ${$completed ? `${green}20` : `${red}20`}; }
        100% { background: ${$completed ? `${green}40` : `${red}40`}; }
      }
      transition: all 0.75s ease-in-out;
      animation: blink normal 0.75s infinite ease-in-out;
    `};
`;

const StyledAnchorIcon = styled(AnchorIcon)`
  height: 1rem !important;
  width: 1rem !important;
  color: ${blue};
`;

const Count = styled.span<{ $color?: string }>`
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.33rem;
  color: ${({ $color }) => $color || green};
`;

const Text = styled.div`
  font-size: 0.8rem;
`;

const IconWrapper = styled(FlexWrapper)<{ $anchor?: boolean }>`
  justify-content: center;
  border-radius: 50%;
  background: ${blue}30;
  height: 18px;
  width: 18px;
  border: none;
  margin-right: 0.25rem;
  ${({ $anchor }) =>
    $anchor &&
    `
    background: transparent;
  `};
`;

const MetaDivider = styled.div`
  border-right: 2px solid ${gray50};
  height: 16px;
  margin: 0 0.33rem;
`;

const Main = styled.div<{ $wizardView: boolean }>`
  width: 100% !important;
  display: grid;
  align-items: center;
  justify-content: space-between;
  ${({ $wizardView }) =>
    $wizardView
      ? `
    grid-template-columns: 2fr 1fr;
    grid-gap: 0.25rem;
  `
      : `
    grid-template-columns: 2.5fr 1fr;
    grid-gap: 1rem;
    padding-right: 0.5rem;
  `};

  @media screen and (max-width: ${BREAK_POINT_XL}) {
    grid-template-columns: 2fr 1fr;
    grid-gap: 0.5rem;
    ${IconWrapper} {
      height: 16px;
      width: 16px;
    }
    ${Count} {
      font-size: 0.85rem;
    }
    ${Text} {
      font-size: 0.8rem;
    }
    ${StyledAnchorIcon} {
      height: 0.6rem;
      width: 0.6rem;
    }
    ${MetaDivider} {
      margin: 0 0.25rem;
    }
  }

  @media screen and (max-width: ${BREAK_POINT_M}) {
    grid-template-columns: 1.75fr 1fr;
    grid-gap: 0.5rem;
    ${IconWrapper} {
      height: 16px;
      width: 16px;
    }
    ${Count} {
      font-size: 0.75rem;
    }
    ${Text} {
      font-size: 0.7rem;
    }
    ${StyledAnchorIcon} {
      height: 0.5rem;
      width: 0.5rem;
    }
  }

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 0.25rem;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const TitleIcon = styled(ListAltIcon)`
  font-size: 12px !important;
  color: ${blue};
`;

const AnchorWrapper = styled(FlexWrapper)`
  align-items: baseline;
  color: ${gray80};
`;

type Props = {
  summary: CrewChangePlanSummaryResp;
  wizardView: boolean;
  isCompleted: boolean;
  recentlyAdded?: boolean;
};

function PlanDetails({
  summary: crewChangeSummary,
  wizardView,
  recentlyAdded,
  isCompleted,
}: Props) {
  const dispatch = useAppDispatch();
  const { readOnlyPlanningData: reportData, active: activity } = useSelector(
    selectCrewChangePanel
  );
  const { userInfo } = useSelector(selectSettings);

  const [fetching, setFetching] = useState(false);

  const { firstname = '', lastname = '' } = userInfo || {};
  const { uuid, updatedAt, createdAt, creator, userNotes, summary } =
    crewChangeSummary;
  const {
    ports,
    crew: { onsignerCount, offsignerCount },
  } = summary;
  const isOpen = reportData?.id === uuid;
  const userFullName = `${firstname || ''} ${lastname || ''}`.trim();
  const dateText = moment(updatedAt).fromNow();
  const planTitle =
    userNotes.title || getDefaultReportTitle({ summary, createdAt });

  const handleOpenPlan = useCallback(() => {
    if (wizardView || isOpen) return;
    if (!activity) {
      openReadOnlyPanel(uuid, dispatch);
      return;
    }
    const discardMessage =
      activity === 'plan'
        ? CREW_CHANGE_CANCEL_MESSAGE
        : 'Current panel will be closed. Are you sure?';
    if (window.confirm(discardMessage)) {
      setFetching(true);
      // close existing cc panel before for another plan
      dispatch(selectCrewChangeEvent());
      openReadOnlyPanel(uuid, dispatch).then(() => {
        setFetching(false);
      });
    }
  }, [isOpen, activity, uuid, wizardView, dispatch]);

  const testProps = isCompleted ? { 'data-id': 'e2e_saved-plan-title' } : {};

  return (
    <Container
      $isOpen={isOpen}
      $completed={isCompleted}
      $recentlyAdded={recentlyAdded}
      onClick={handleOpenPlan}
    >
      <FlexWrapper style={{ marginBottom: '0.2rem' }}>
        <IconWrapper>
          <TitleIcon />
        </IconWrapper>
        <Text>
          <b {...testProps}>{planTitle}</b>
        </Text>
      </FlexWrapper>

      <Main $wizardView={wizardView}>
        <ContentWrapper>
          <FlexWrapper>
            {Boolean(ports.length) && (
              <Tooltip content={`${ports.length} port(s) included`}>
                <AnchorWrapper>
                  <IconWrapper $anchor>
                    <StyledAnchorIcon />
                  </IconWrapper>
                  <Count $color={purple}>{ports.length}</Count>
                  <Text>port{ports.length > 1 ? 's' : ''}</Text>
                </AnchorWrapper>
              </Tooltip>
            )}

            {Boolean(ports.length) &&
              Boolean(onsignerCount || offsignerCount) && <MetaDivider />}

            {Boolean(offsignerCount) && (
              <Tooltip content={`${offsignerCount} offsigner(s)`}>
                <FlexWrapper style={{ marginRight: '0.75rem' }}>
                  <Count $color={red}>{offsignerCount}</Count>
                  <OffsignerIcon color={red} size={16} />
                </FlexWrapper>
              </Tooltip>
            )}
            {Boolean(onsignerCount) && (
              <Tooltip content={`${onsignerCount} onsigner(s)`}>
                <FlexWrapper>
                  <Count style={{ marginRight: '8px' }}>{onsignerCount}</Count>
                  <OnsignerIcon color={green} size={16} />
                </FlexWrapper>
              </Tooltip>
            )}
          </FlexWrapper>

          <Text>
            Last updated <i>{dateText}</i>{' '}
            {creator?.name && (
              <span>
                by{' '}
                <b>
                  <i>
                    {creator.name.trim() === userFullName
                      ? 'You'
                      : creator.name}
                  </i>
                </b>
              </span>
            )}
          </Text>
        </ContentWrapper>

        <ActionMenu
          isOpen={isOpen}
          isCompleted={isCompleted}
          fetching={fetching}
          userId={userInfo?.id}
          wizardView={wizardView}
          summary={crewChangeSummary}
          onOpen={handleOpenPlan}
        />
      </Main>
    </Container>
  );
}

export default PlanDetails;
