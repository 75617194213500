import { Middleware, PayloadAction } from '@reduxjs/toolkit';

import { StoreAPI } from '../types';
import { saveSetupWizardToLocalStorage } from 'redux/helpers/setupWizard';

const setupWizard: Middleware<{}, any> =
  ({ getState }: StoreAPI) =>
  (next) =>
  async (action: PayloadAction<undefined>) => {
    if (action.type === 'setupWizard/nextWizardStep') {
      const {
        setupWizard: { availableSteps, currentStep, version, isCompleted },
      } = getState();

      const currentIndex = availableSteps.findIndex((o) => o === currentStep);
      const nextStep = availableSteps[currentIndex + 1];

      saveSetupWizardToLocalStorage({
        currentStep: nextStep,
        version,
        isCompleted,
      });
    } else if (action.type === 'setupWizard/completeWizard') {
      const {
        setupWizard: { availableSteps, version },
      } = getState();

      saveSetupWizardToLocalStorage({
        currentStep: availableSteps[availableSteps.length - 1],
        version,
        isCompleted: true,
      });
    }

    next(action);
  };

export default setupWizard;
