import { memo } from 'react';

import { NOT_REQUIRED_TEXT } from 'lib/constants';
import { FlightNumberDetails } from 'redux/types';
import { Flight } from 'utils/types/crew-change-types';

type Props = {
  matched?: boolean;
  from: string;
  to: string;
  matchedFlights: Flight[];
  unmatchedFlights: Flight[];
  searchOptions: FlightNumberDetails;
};

function SearchResultText({
  matched,
  from,
  to,
  matchedFlights,
  unmatchedFlights,
  searchOptions,
}: Props) {
  const { included, flightNumber } = searchOptions;
  const totalFlightsCount = matchedFlights.length + unmatchedFlights.length;
  const combinedText = (
    <span>
      {' '}
      {(included || !flightNumber) && <span>filters</span>}{' '}
      {included && flightNumber && <span>and</span>}{' '}
      {flightNumber && <span>flight number</span>}
    </span>
  );

  if (!totalFlightsCount) {
    return from === to ? (
      <i>
        <span data-id="e2e_quickfly-no-flight">{NOT_REQUIRED_TEXT}</span>
      </i>
    ) : (
      <span data-id="e2e_quickfly-no-flight">
        No flight found from{' '}
        <i>
          <b>{from}</b> to <b>{to}</b>
        </i>
      </span>
    );
  }

  const foundFlightsText = (
    <div data-id="e2e_quickfly-total-flights">
      {totalFlightsCount} total flight{totalFlightsCount > 1 ? 's' : ''} found
      from{' '}
      <i>
        <b>{from}</b> to <b>{to}</b>
      </i>
    </div>
  );

  return (
    <div>
      {matched ? foundFlightsText : null}

      {matched &&
        (Boolean(matchedFlights.length) ? (
          <div data-id="e2e_quickfly-matched-flights">
            <span data-id="e2e_quickfly-matched-flights-count">
              {matchedFlights.length} flight
              {matchedFlights.length > 1 ? 's' : ''}
            </span>{' '}
            matching {combinedText}
          </div>
        ) : (
          <div>No result matching the {combinedText}</div>
        ))}

      {!matched && Boolean(unmatchedFlights.length) && (
        <div>
          {unmatchedFlights.length} flight
          {unmatchedFlights.length > 1 ? 's' : ''} NOT matching {combinedText}
        </div>
      )}
    </div>
  );
}

export default memo(SearchResultText);
