import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { portsInRangeAsync } from '../thunks';
import { PortsInRange, RootState } from '../types';

export const initialPortsInRange: PortsInRange = {
  showPortsInRange: false,
  ports: [],
  rangeNauticalMiles: 100,
};

const portsInRangeSlice = createSlice({
  name: 'portsInRange',
  initialState: initialPortsInRange,
  reducers: {
    togglePortsInRange: (state, action: PayloadAction<boolean>) => ({
      ...state,
      // assign payload state or toggle the existing one
      showPortsInRange: action.payload,
      // set to initial state if ports in range panel is closed
      ...(!action.payload ? initialPortsInRange : {}),
    }),
    setRangeNauticalMiles: (state, action: PayloadAction<number>) => ({
      ...state,
      rangeNauticalMiles: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(portsInRangeAsync.fulfilled, (state, action) => ({
      ...state,
      ports: action.payload ?? state.ports,
    }));
  },
});

/* ----- selectors -----*/

export const selectPortsInRange = (state: RootState) => state.portsInRange;

/* ----- actions -----*/

export const { togglePortsInRange, setRangeNauticalMiles } =
  portsInRangeSlice.actions;

/* ----- reducer -----*/

export default portsInRangeSlice.reducer;
