import {
  blue,
  iconGray,
  white,
  textBlack,
  textGray,
  gray60,
  gray,
  gray30,
  gray20,
  gray50,
  gray90,
  black,
} from 'lib/colors';
import styled, { css } from 'styled-components/macro';

import { Button } from 'components/shared';
import { BREAK_POINT_XS } from 'lib/breakpoints';

export const SearchContainer = styled.div`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
  background: white;
`;

export const SEARCHBOX_EXPAND_ANIMATION = 200;

export const SearchFluidContainer = styled.div<{ $expanded?: boolean }>`
  position: relative;
  pointer-events: all;
  width: 25vw;
  min-width: 300px;
  display: inline-block;
  font-size: 0.9rem;
  transition: min-width ${SEARCHBOX_EXPAND_ANIMATION}ms,
    font-size ${SEARCHBOX_EXPAND_ANIMATION}ms;
  z-index: 10;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  @media (max-width: ${BREAK_POINT_XS}) {
    width: 100%;
    // margin-right: 1rem;
    min-width: unset;
    font-size: unset;
  }

  ${({ $expanded }) =>
    $expanded &&
    `
    font-size: 1.1rem;
    min-width: 500px;
    max-width: 700px;

    @media (max-width: ${BREAK_POINT_XS}) {
      min-width: unset;
      font-size: unset;
    }
  `};
`;

export const BackgroundWrapper = styled.div<{
  $isExpanded?: boolean;
  $isAdmin: boolean;
}>`
  border-radius: 0.5rem 0.5rem 0 0;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: ${({ $isAdmin }) => ($isAdmin ? black : blue)};
  column-gap: 0.5rem;
  z-index: 9999 !important;

  ${({ $isExpanded }) =>
    $isExpanded &&
    `
    background-color: ${white};
  `};

  &:focus {
    outline: none;
  }
`;

export const AutoCompleteWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const searchQueryStyles = css`
  padding: 0;
  width: 100%;
  flex: 1;
  font-size: 1.4em;
  font-family: 'HK Grotesk', 'Arial';
  font-weight: 500;
  background-color: transparent;
  color: ${white};
  margin: 0;
`;

export const searchQueryExpandedStyles = css`
  font-size: 1.6rem;
  border-bottom: 1px solid #ececec;
  border-top: none;
  border-right: none;
  border-left: none;
  color: ${textBlack};
`;

export const InputField = styled.input<{ $expanded?: boolean }>`
  ${searchQueryStyles}
  ${({ $expanded }) =>
    $expanded ? searchQueryExpandedStyles : 'border: none;'};
  color: ${({ $expanded }) =>
    $expanded ? textBlack : 'rgba(255, 255, 255, 0.5)'};
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${({ $expanded }) =>
      $expanded ? iconGray : 'rgba(255, 255, 255, 0.5)'};
  }
`;

export const PortInput = styled.input<{ $expanded?: boolean }>`
  flex: 1;
  width: 100%;
  margin-left: 5px;
  border: none;
  background-color: transparent;
  font-family: 'HK Grotesk', 'Arial';
  ${({ $expanded }) =>
    $expanded
      ? `
    font-weight: 500;
    color: ${textBlack};
    font-size: 1.6rem;
  `
      : `
    color: ${white};
    font-size: 1.4rem;
  `};

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: ${({ $expanded }) => ($expanded ? '1.4rem' : '1.2rem')};
    color: ${iconGray};
  }
`;

export const ResultContainer = styled.div`
  background-color: ${gray};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  max-height: 35em;
  overflow: auto;
`;

export const AutoCompleteText = styled.div`
  position: absolute;
  bottom: 1px;
  margin-left: 0;
  display: block;
  color: ${textGray};
  font-size: 1.6rem;
  pointer-events: none;
`;

export const ResultCard = styled.div`
  background-color: ${white};
  border-radius: 0.25rem;
  border: 1px solid #${gray60};
  display: inline-block;
  text-align: left;
  padding-bottom: 0.5rem;
  margin: 0.5rem;
`;

export const StyledCard = styled(ResultCard)`
  padding-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  width: 96%;
  margin-right: 5px;
  transition: all 150ms ease;
`;

export const ResultTile = styled.div`
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  padding: 0 1rem;
  h6 {
    margin: 0;
    font-size: 1rem;
  }
  p {
    margin: 0;
    font-size: 0.9rem;
    color: ${textGray};
  }
`;

export const ResultList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const ExpandButton = styled.div<{ $isExpanded: boolean }>`
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 0.5em 0;
  margin-bottom: -0.6em;
  margin-left: 0;
  font-size: 0.8em;
  border-radius: 0 0 4px 4px;
  margin-right: 0;

  &:hover {
    background-color: #375dea;
    color: white;
  }

  ${({ $isExpanded }) =>
    !$isExpanded &&
    `
  &:before {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    content: '';
    height: 300%;
    pointer-events: none;
    background: linear-gradient(
      hsla(0, 0%, 100%, 0) 1%,
      hsla(0, 0%, 100%, 0.8)
    );
  }
`};
`;

export const FiltersContainer = styled(ResultCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const CommandCompletion = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.4em 1em;
  cursor: pointer;
  font-size: 0.8em;
  &:hover {
    background-color: ${gray30};
  }
`;

export const Command = styled.div`
  background-color: ${gray30};
  border-radius: 0.25em;
  border: 1px solid #ccc;
  color: ${textBlack};
  text-decoration: none;
  margin-right: 1em;
  padding: 0.25em;
  width: fit-content;
`;

export const SearchInfo = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: center;
  color: ${gray60};
`;

export const SearchActionButton = styled(Button)`
  min-width: unset;
  width: fit-content;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.7rem;
  background: ${gray};
  margin-right: 0;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    align-self: start;
  }
`;

export const SearchLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ListItem = styled.li<{
  $isFocused: boolean;
  $isClickable?: boolean;
}>`
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  column-gap: 0.5rem;
  cursor: ${(props) =>
    typeof props.$isClickable != 'undefined' && !props.$isClickable
      ? 'default'
      : 'pointer'};

  &:hover {
    background-color: ${gray30} !important;
  }

  ${({ $isFocused }) =>
    $isFocused &&
    `
    background-color: ${gray30} !important;
  `};
`;

export const LinkText = styled.div`
  font-size: 0.8rem;
  color: ${gray50};
  span {
    margin-right: 0.5rem;

    #diff-bold {
      font-weight: 700;
      margin-right: 0;
    }
  }
  b {
    color: ${gray90};
  }
`;

export const FlightResultColumn = styled.div<{ $shadow?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  ${({ $shadow }) =>
    $shadow &&
    `
    height: 100%;
    background: ${gray20};
    box-shadow: -5px 0px 10px -5px rgba(0, 0, 0, 0.2)
  `};
`;

// wrapper used for port-restrictions & search-flights
export const CustomWrapper = styled.div<{ $expanded: boolean }>`
  display: flex;
  align-items: center;
  transition: all 200ms ease;
  ${searchQueryStyles};
  ${({ $expanded }) =>
    $expanded ? searchQueryExpandedStyles : 'border: none;'};
  * {
    color: ${({ $expanded }) =>
      $expanded ? textBlack : 'rgba(255, 255, 255, 0.5)'};
  }
`;
