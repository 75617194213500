import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import partition from 'lodash/partition';

import { titleize } from 'lib/string';

import { OPERATOR_IMS_RANKS, VISIBLE_PERSONAL_INFO } from '../constants';
import {
  CrewExperience,
  CrewPersonalInfo,
  VisiblePersonalInfo,
  ComputedExperience,
} from '../types';

export const formatCrewExperiences = (experiences: CrewExperience[] = []) => {
  const formattedExperiences = experiences.map((experience) => {
    const {
      experienceType,
      experienceTypeVal: rank,
      yearsExperience,
    } = experience;
    const formattedExperienceType = experienceType
      .toLowerCase()
      .split('_')
      .map(titleize)
      .join(' ');
    return {
      name: `${formattedExperienceType} (${rank})`,
      years: yearsExperience ? `${yearsExperience.toFixed(1)} year(s)` : 'N/A',
    };
  });

  return flatten(
    // partition to hoist the operator IMS rank experiences at the top (if available)
    partition(formattedExperiences, ({ name }) =>
      OPERATOR_IMS_RANKS.includes(name.toLowerCase())
    )
  );
};

// check if crw has enough personal info to display
export const showPersonalInfo = (crew: CrewPersonalInfo) =>
  VISIBLE_PERSONAL_INFO.some((key) => crew[key as keyof VisiblePersonalInfo]);

// group all relevent experiences under the given types
export const groupExperiencesByType = (experiences: ComputedExperience[]) =>
  experiences
    .filter(({ exType }) =>
      ['OPERATOR', 'RANK', 'CREW_MATRIX_TANKER_TYPE'].includes(exType)
    )
    .reduce<{ [key: string]: string }[]>((acc, experience) => {
      const { exType, exTypeVal, ranksInvolved } = experience;
      const existingGroup = acc.find((item) => item.type === exType);
      const newGroupExperience = {
        [ranksInvolved.sort().join(', ')]: `${exTypeVal.toFixed(1)} yrs`,
      };

      return existingGroup
        ? uniqBy([{ ...existingGroup, ...newGroupExperience }, ...acc], 'type')
        : [...acc, { type: exType, ...newGroupExperience }];
    }, [])
    .sort((a, b) => b.type.localeCompare(a.type));
