import {
  define,
  is,
  object,
  size,
  string,
  Struct,
  boolean,
  Describe,
  any,
  enums,
  optional,
  array,
  mask,
  StructError,
  defaulted,
} from 'superstruct';
import { SeaGPTThread, SeaGPTChatBlock, SeaGPTChatMessage } from './type';
import { SEAGPT_CHAT_CATEGORIES, SEAGPT_CHAT_TYPE } from './common';

const message = <T>(struct: Struct<T, any>, message: string): Struct<T, any> =>
  define('message', (value) => (is(value, struct) ? true : message));

export const copilotQuerySchema = object({
  query: message(size(string(), 3, Infinity), 'You must include some text.'),
  audio: boolean(),
  isSuggestion: boolean(),
});

const SeaGPTChatMessageSchema: Describe<SeaGPTChatMessage> = object({
  id: string(),
  type: enums(SEAGPT_CHAT_TYPE),
  isLast: optional(boolean()),
  likeOrDislike: optional(enums(['like', 'dislike'])),
  isSuggestion: optional(boolean()),
  message: string(),
  createdAt: string(),
});

const commonSeaGPTChatBlock = {
  id: string(),
  category: enums(SEAGPT_CHAT_CATEGORIES),
  createdAt: string(),
  isRestartBlock: optional(boolean()),
  caseId: optional(string()),
  isLast: optional(boolean()),
  isAudio: optional(boolean()),
  isError: optional(boolean()),
  plugin: optional(any()),
  meta: optional(any()),
  messages: array(SeaGPTChatMessageSchema),
};

const SeaGPTChatBlockSchema: Describe<SeaGPTChatBlock> = object(
  commonSeaGPTChatBlock
);

export const SeaGPTConversationSchema: Describe<SeaGPTThread> = object({
  id: string(),
  createdAt: string(),
  title: string(),
  blocks: array(SeaGPTChatBlockSchema),
  version: string(),
  isNew: defaulted(boolean(), false),
});

export function validateSeaGPTConversation(
  conversation: any
): SeaGPTThread | null {
  try {
    const validated = mask(conversation, SeaGPTConversationSchema);
    return validated;
  } catch (error) {
    if (error instanceof StructError) {
      const failedPaths = error.failures().map(({ path }) => path);
      console.log(
        'Failed paths for proteus conversation data validation',
        conversation?.id,
        failedPaths.join(',')
      );
    }
    return null;
  }
}
