import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  hideNotification,
  markNotificationAsRead,
  markSelectedAsRead,
} from 'api/flotilla';

/* ----- Async Thunk ----- */

export const removeNotificationAsync = createAsyncThunk(
  'sidePanel/removeNotificationAsync',
  async (id: number) => {
    const { notification } = await hideNotification(id);
    return notification;
  }
);

export const markNotificationReadAsync = createAsyncThunk(
  'sidePanel/markNotificationReadAsync',
  async (id: number) => {
    const { notification } = await markNotificationAsRead(id);
    return notification;
  }
);

export const markSelectedNotificationReadAsync = createAsyncThunk(
  'sidePanel/markSelectedNotificationReadAsync',
  async (ids: number[]) => {
    const { notifications } = await markSelectedAsRead(ids);
    return notifications;
  }
);
