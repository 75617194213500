import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { featureCollection as getFeatureCollection } from '@turf/helpers';

import { selectCrewChangePanel, selectMapVessels } from 'redux/selectors';
import { Crew } from 'utils/types/crew-change-types';

import { removeLayer, removeSource, setSource } from 'map/map';
import { CREW_CHANGE_AIRPORT, CREW_CHANGE_AIRPORT_ROUTE } from 'map/variables';
import {
  drawAirportRoutes,
  drawAirports,
  generateAirportFeature,
  generateAirportRouteFeature,
} from 'map/airports';

type HomeAirportsProps = {
  crew: Crew[];
};

type HomeAirport = {
  id: string;
  count: number;
  text: string;
  offsigners: number;
  onsigners: number;
  lat: number;
  lon: number;
};

function HomeAirports({ crew }: HomeAirportsProps) {
  const { vesselsFull: vessels } = useSelector(selectMapVessels);
  const { vesselId } = useSelector(selectCrewChangePanel);

  const [isSourceReady, setIsSourceReady] = useState<boolean>(false);

  const activeVesselCoordinate = useMemo(() => {
    if (!vessels.size || !vesselId) return null;
    const selectedVessel = vessels.get(vesselId);
    if (!selectedVessel) return null;
    return {
      lat: selectedVessel.lat,
      lng: selectedVessel.lng,
    };
  }, [vessels, vesselId]);

  // Startup Logic
  useEffect(() => {
    Promise.all([
      setSource(CREW_CHANGE_AIRPORT).then(() => {
        drawAirports(CREW_CHANGE_AIRPORT, CREW_CHANGE_AIRPORT);
      }),
      setSource(CREW_CHANGE_AIRPORT_ROUTE).then(() => {
        drawAirports(
          CREW_CHANGE_AIRPORT_ROUTE,
          `${CREW_CHANGE_AIRPORT}-label`,
          {
            layout: {
              'symbol-placement': 'line-center',
              'text-field': ['get', 'label'],
              'text-anchor': 'top',
              'text-font': ['literal', ['HK Grotesk Medium', 'Roboto Medium']],
              'text-size': 12,
            },
          }
        );
        drawAirportRoutes(
          CREW_CHANGE_AIRPORT_ROUTE,
          CREW_CHANGE_AIRPORT_ROUTE,
          undefined,
          `${CREW_CHANGE_AIRPORT}-label`
        );
      }),
    ]).then(() => {
      setIsSourceReady(true);
    });

    return () => {
      removeLayer(CREW_CHANGE_AIRPORT).then((stillExist) => {
        !stillExist && removeSource(CREW_CHANGE_AIRPORT);
      });
      Promise.all([
        removeLayer(CREW_CHANGE_AIRPORT_ROUTE),
        removeLayer(`${CREW_CHANGE_AIRPORT}-label`),
      ]).then(() => {
        removeSource(CREW_CHANGE_AIRPORT_ROUTE);
      });
    };
  }, []); // eslint-disable-line

  const airportData: HomeAirport[] = useMemo(() => {
    if (!crew) return [];
    const airports = crew.reduce<{ [key: string]: HomeAirport }>(
      (allAirports, crew) => {
        if (!crew.homeAirport) return allAirports;
        const airportExist = allAirports[crew.homeAirport.iataCode];
        const crewType = String(crew.type).toLowerCase();
        if (airportExist) {
          airportExist.onsigners += crewType === 'onsigner' ? 1 : 0;
          airportExist.offsigners += crewType === 'offsigner' ? 1 : 0;
          airportExist.count += 1;
        } else {
          if (!crew.homeAirport.lat || !crew.homeAirport.lon)
            return allAirports;
          allAirports[crew.homeAirport.iataCode] = {
            id: crew.homeAirport.iataCode,
            onsigners: crewType === 'onsigner' ? 1 : 0,
            offsigners: crewType === 'offsigner' ? 1 : 0,
            count: 1,
            text: crew.homeAirport.name,
            lat: crew.homeAirport.lat,
            lon: crew.homeAirport.lon,
          };
        }
        return allAirports;
      },
      {}
    );
    return Object.values(airports);
  }, [crew]);

  const airportFC: {
    airports: GeoJSON.FeatureCollection;
    airportRoutes: GeoJSON.FeatureCollection;
  } | null = useMemo(() => {
    if (!activeVesselCoordinate) return null;
    return {
      airports: getFeatureCollection(
        airportData.map((homeAirport) =>
          generateAirportFeature(
            `${CREW_CHANGE_AIRPORT}-airport-${homeAirport.id}`,
            homeAirport
          )
        )
      ),
      airportRoutes: getFeatureCollection(
        airportData.map(({ text, onsigners, offsigners, lat, lon, id }) =>
          generateAirportRouteFeature(
            `${CREW_CHANGE_AIRPORT}-route-${id}`,
            {
              start: [activeVesselCoordinate.lng, activeVesselCoordinate.lat],
              end: [lon, lat],
            },
            {
              label: `${text} (${onsigners} on, ${offsigners} off)`,
            }
          )
        )
      ),
    };
  }, [airportData, activeVesselCoordinate]);

  useEffect(() => {
    if (!isSourceReady) return;
    setSource(
      CREW_CHANGE_AIRPORT,
      airportFC ? airportFC.airports : getFeatureCollection([])
    );
    setSource(
      CREW_CHANGE_AIRPORT_ROUTE,
      airportFC ? airportFC.airportRoutes : getFeatureCollection([])
    );
  }, [isSourceReady, airportFC]);
  return null;
}

export default HomeAirports;
