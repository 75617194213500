import { memo } from 'react';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import styled from 'styled-components/macro';

import { gray70, midBlue, textGray } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { formatDate } from 'utils/format-date';

import { FlightResultColumn } from 'components/FlotillaSearch/common';
import {
  FlightStepDetails,
  LayoverDetails,
} from 'components/FlotillaSearch/types';
import Tooltip from '../../Tooltip';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
`;

const CollapsedInfo = styled.div<{ $collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  min-width: 45px;
  padding: 0.75rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    cursor: pointer;
    transition: all 150ms ease;
    &:hover {
      background: ${midBlue};
    }
  `};
`;

const AirportCode = styled.div<{ $minimized?: boolean }>`
  font-size: 1.1rem;
  font-weight: bold;
  ${({ $minimized }) =>
    $minimized &&
    `
    font-size: 0.85rem;
    font-weight: bold;
  `};
  text-transform: uppercase;
`;

const Text = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  margin-top: 0.1rem;
  text-align: center;
`;

const FlightNumber = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
  // letter-spacing: 0.5px;
  margin-top: 0.33rem;
`;

const CollapsedAirportCode = styled(AirportCode)`
  margin-top: 0.75rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
`;

const CollapsedFlightNumber = styled(FlightNumber)`
  margin-top: 0.5rem;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
`;

const InfoWrapper = styled(FlightResultColumn)`
  justify-content: flex-start;
  max-width: 150px;
  height: 100%;
  flex-shrink: 0;
  padding: 0.6rem;
  padding-bottom: 0.5rem;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-right: 0;
  }
`;

const StyledIcon = styled.div`
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
  border-radius: 50%;
`;

const FlightType = styled(Text)`
  font-size: 0.75rem;
  font-style: italic;
  color: ${textGray};
  margin-bottom: 2px;
`;

const Time = styled(Text)`
  color: ${textGray};
`;

const BaggageText = styled(Time)`
  font-size: 0.725rem;
`;

const CustomTime = styled(Time)`
  font-style: italic;
  text-decoration: underline;
  cursor: pointer;
`;

const LayoverTooltipWrapper = styled.div`
  padding: 0.25rem;
  padding-right: 0;
`;

const LayoverTooltipText = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 1px;
  font-weight: 500;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ForwardIcon = styled(ChevronRightIcon)<{ $isModalView: boolean }>`
  font-size: 1rem !important;
  color: ${gray70};
  margin: 0 3px;
  margin-top: 0.25rem;
  ${({ $isModalView }) =>
    $isModalView &&
    `
    margin: 0 6px;
    margin-top: 0.25rem;
  `}
`;

const customIconStyle = {
  height: '1.25rem',
  width: '1.25rem',
  marginBottom: '0.33rem',
};

type TooltipProps = {
  type: string;
  time?: string;
  layoverDetails?: LayoverDetails;
};

type Props = FlightStepDetails & {
  isModalView: boolean;
  canExpand?: boolean;
  onExpand?: () => void;
};

const TextWithTooltip = ({ type, time, layoverDetails }: TooltipProps) => {
  const isLayover = layoverDetails && type.toLowerCase().includes('layover');
  if (!isLayover) {
    const [hour, date] = formatDate(time, 'HH:mm, DD MMM').split(', ');
    const tooltipContent = (
      <span>
        Flight {type.toLowerCase()}s at{' '}
        <b>
          {hour}, {date}
        </b>
      </span>
    );
    return (
      <Tooltip content={tooltipContent}>
        <CustomTime>{hour}</CustomTime>
      </Tooltip>
    );
  }

  const { arrival, departure, layoverTime } = layoverDetails;
  const tooltipContent = (
    <LayoverTooltipWrapper>
      <LayoverTooltipText>
        <span>Arrival at {arrival.iataCode}: </span>
        <b data-id="e2e_flight-layover-arrival">
          {formatDate(arrival.time, 'HH:mm, DD MMM')}
        </b>
      </LayoverTooltipText>
      <LayoverTooltipText>
        <span>Departure from {departure.iataCode}: </span>
        <b data-id="e2e_flight-layover-departure">
          {formatDate(departure.time, 'HH:mm, DD MMM')}
        </b>
      </LayoverTooltipText>
    </LayoverTooltipWrapper>
  );
  return (
    <Tooltip content={tooltipContent}>
      <CustomTime data-id="e2e_flight-layover">{layoverTime}</CustomTime>
    </Tooltip>
  );
};

function FlightInfo({
  canExpand,
  onExpand,
  isModalView,
  ...flightDetails
}: Props) {
  const {
    type,
    city,
    time,
    layoverDetails,
    departureFlightNumber,
    arrivalFlightNumber,
    iataCode,
    iconDetails,
    baggage,
  } = flightDetails;
  const { name: Icon, color: iconColor } = iconDetails || {};

  if (canExpand) {
    return (
      <CollapsedInfo $collapsed={Boolean(onExpand)} onClick={onExpand}>
        <>
          <AirlineStopsIcon
            fontSize="small"
            color="disabled"
            sx={customIconStyle}
          />
          <CollapsedFlightNumber>{departureFlightNumber}</CollapsedFlightNumber>
        </>
        <CollapsedAirportCode>{iataCode}</CollapsedAirportCode>
      </CollapsedInfo>
    );
  }

  const renderFlightNumber = () => {
    const isDeparture = Boolean(departureFlightNumber);
    const isArrival = Boolean(arrivalFlightNumber);
    const isLayover = Boolean(layoverDetails);

    switch (true) {
      case isLayover:
        return (
          <FlexWrapper>
            <FlightNumber>{arrivalFlightNumber}</FlightNumber>
            <ForwardIcon $isModalView={isModalView} />
            <FlightNumber>{departureFlightNumber}</FlightNumber>
          </FlexWrapper>
        );

      case isDeparture:
        return <FlightNumber>{departureFlightNumber}</FlightNumber>;

      case isArrival:
        return <FlightNumber>{arrivalFlightNumber}</FlightNumber>;

      default:
        return null;
    }
  };

  return (
    <InfoWrapper>
      {iconDetails ? (
        // show arrival & departure icons
        <StyledIcon>{Icon && <Icon sx={{ color: iconColor! }} />}</StyledIcon>
      ) : (
        // show layover icon
        <AirlineStopsIcon
          fontSize="small"
          color="disabled"
          sx={customIconStyle}
        />
      )}
      <FlightType>{type}</FlightType>
      <>
        <AirportCode>{iataCode}</AirportCode>
        <Text>{city}</Text>
      </>

      {renderFlightNumber()}
      <Time>{formatDate(time, 'DD MMM YY')}</Time>
      <TextWithTooltip
        type={type}
        time={time}
        layoverDetails={layoverDetails}
      />
      {baggage && (
        <BaggageText data-id="e2e_baggage-info">Baggage: {baggage}</BaggageText>
      )}
    </InfoWrapper>
  );
}

export default memo(FlightInfo);
