import { Pagination } from '@mui/material';
import styled, { css } from 'styled-components/macro';

import {
  blue,
  borderGray,
  darkBlue,
  fadedGreen,
  gray70,
  lightPurple,
  red,
  textBlack,
  textGray,
} from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';

import { Button } from 'components/shared';
import DataGridWrapper, { getHeaderStyle } from 'components/shared/DataGrid';

// cmmon styles
export const headerStyles = getHeaderStyle('.table-header');

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyWrapper = styled(FlexWrapper)`
  border-top: 1px solid ${borderGray};
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
`;

export const EmptyText = styled(FlexWrapper)`
  font-size: 1.1rem;
  color: ${textGray};
`;

export const TableWrapper = styled(DataGridWrapper)<{
  $crew?: boolean;
  $isReportView?: boolean;
}>`
  height: 100% !important;
  ${({ $crew, $isReportView }) =>
    $crew &&
    !$isReportView &&
    `
    // show border for pair of crews in non-report view
    .added-replacement.odd,
    .adding-crew.odd,
    .existing-crew.odd {
      border-top: 1px solid ${blue};
      &:first-child {
        border-top: none;
      }
    }
    .added-replacement.even,
    .adding-crew.even,
    .existing-crew.even {
      border-bottom: 1px solid ${blue};
      &:last-child {
        border-bottom: none;
      }
    }
    // style for crew-type update
    .crew-update-dropdown > div > .MuiSelect-select {
      font-size: 0.75rem !important;
      font-family: HK Grotesk, Roboto !important;
    }
    // style for date input in crew table
    .crew-date-cell > div > .MuiInputBase-input {
      font-size: 0.85rem !important;
      font-family: HK Grotesk, Roboto !important;
    }
  `};
  ${({ $crew }) =>
    !$crew
      ? `
    .MuiDataGrid-row.Mui-selected,
    .MuiDataGrid-row.changing {
      background: ${darkBlue} !important;
      color: #fff;
      .MuiCheckbox-root, .MuiSelect-select, .MuiSvgIcon-root {
        color: #fff;
      }
      .MuiInput-root {
        border-bottom: 1px solid #fff !important;
        &:after {
          border-bottom: 2px solid #fff !important;
        }
      }
    }
    .MuiDataGrid-row.added-port {
      background: ${lightPurple} !important;
      &:hover {
        background: ${lightPurple} !important;
      }
    }
    .MuiDataGrid-row.missing {
      background: ${red}20 !important;
    }
    .scheduled-port {
      background-color: ${lightPurple} !important;
    }
  `
      : `
    .MuiDataGrid-row.added-crew,
    .MuiDataGrid-row.added-replacement {
      background-color: ${fadedGreen}30 !important;
      &:hover {
        background-color: ${fadedGreen}40 !important;
      }
    }
    .MuiDataGrid-row.added-crew.Mui-selected,
    .MuiDataGrid-row.added-replacement.Mui-selected {
      background-color: ${fadedGreen}40 !important;
      &:hover {
        background-color: ${fadedGreen}40 !important;
      }
    }
    // show lighter background for crew table to make the border prominent
    .MuiDataGrid-row.Mui-selected {
      background: ${darkBlue}16 !important;
    }
  `};
  .MuiDataGrid-columnHeaderTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledButton = styled(Button)`
  height: 25px;
  width: fit-content;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  margin-right: 10px;
  padding: 8px 12px;
  &:first-child {
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const buttonStyles = css`
  min-height: unset;
  min-width: unset;
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  margin-left: 0.5rem;
  text-transform: uppercase;
  flex-shrink: 0;
`;

export const ActionButton = styled(Button)`
  ${buttonStyles}
`;

export const HeaderTitle = styled.div`
  align-self: start;
  font-size: 1.1rem;
  font-weight: 700;
  min-width: 200px;
  margin-bottom: 5px;
  margin-right: 15px;
`;

export const StyledPagination = styled(Pagination)`
  li .MuiPaginationItem-root {
    font-family: HK Grotesk, Roboto;
    font-weight: normal;
  }
`;

export const CrewBadge = styled.div<{ $on?: boolean }>`
  font-size: 0.6rem;
  font-weight: 600;
  line-height: 1.33;
  padding: 3px 6px;
  border-radius: 20px;
  color: #fff;
  width: fit-content;
  text-transform: uppercase;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  background-color: ${({ $on }) => ($on ? `${fadedGreen}` : `${red}`)};
`;

export const Label = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.7rem;
  margin-left: 5px;
  font-weight: bold;
  color: ${textGray};
  letter-spacing: 0.05rem;
  flex-shrink: 0;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    font-size: 0.65rem;
    font-weight: 500;
    letter-spacing: 0.03rem;
  }
`;

export const filterButtonStyles = css`
  font-size: 0.7rem;
  letter-spacing: 0.05rem;
  min-height: unset;
  min-width: unset;
  margin-right: 8px !important;
  padding: 0.75rem;
  flex-shrink: 0;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    font-size: 0.65rem;
    padding: 0.5rem 0.75rem;
  }
`;

export const SliderWrapper = styled.div`
  margin-left: 10px;
  margin-right: 20px;
  flex-shrink: 0;
  .MuiSlider-valueLabel {
    padding: 0.08rem 0.3rem !important;
    letter-spacing: 0.05rem;
    top: -6px;
  }
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    width: 90px;
    margin-left: 5px;
  }
`;

/*----- Styles for Controls -----*/

export const ControlsWrapper = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  z-index: 1000; // the slider tooltips are overlapped by the table beneath, so this zindex places the whole component above the table
`;

export const ControlsChildWrapper = styled.div<{
  $custom?: boolean;
  $fullWidth?: boolean;
  $isCTeleportVisible?: boolean;
}>`
  padding: 0 0.5rem 0 0.5rem;
  align-self: stretch;
  // This is calculated using the width of the step buttons
  // overflow for x-axis should be handled within the child components
  width: calc(
    100% -
      ${({ $custom, $isCTeleportVisible }) => {
        if (!$custom && $isCTeleportVisible) return '188px';
        else return $custom ? '64px' : '128px';
      }}
  );
  ${({ $fullWidth }) => $fullWidth && 'width: 100%'};

  &:nth-child(1) {
    padding-left: 1rem;
  }
`;

/*----- Read only table styling -----*/

export const ReadOnlyRow = styled(FlexWrapper)`
  margin-bottom: 5px;
  width: fit-content;
`;

export const ReadOnlyLabel = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  margin-right: 6px;
  color: ${gray70};
`;

export const ReadOnlyText = styled.div`
  font-size: 0.8rem;
  line-height: 1.33;
  font-weight: 500;
  color: ${gray70};
`;

export const MetaDivider = styled.div<{ $color?: string }>`
  border-right: ${({ $color }) => `1.5px solid ${$color || borderGray}`};
  height: 20px;
  margin: 0 0.75rem;
`;

/* ------ Styles for column menu ----- */

export const MenuTitle = styled.h3`
  color: ${textBlack};
  padding: 0 1.5rem;
  margin-top: 5px;
  margin-bottom: 8px;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    padding-left: 0.5rem;
  }
`;

export const MenuSeparator = styled.div`
  border-top: 1px solid ${borderGray};
`;

export const MenuDescription = styled.div`
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.33;
  margin-bottom: 1.5rem;
`;

export const MenuFiltersWrapper = styled.div`
  height: fit-content;
  padding: 1rem 1.5rem;

  @media screen and (max-width: ${BREAK_POINT_SM}) {
    padding-left: 0.5rem;
  }
`;
