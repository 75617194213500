import { lazy, memo, Suspense, useEffect, useState } from 'react';
import {
  RestrictionSourceType,
  RestrictionType,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { RestrictionsData } from '@greywing-maritime/frontend-library/dist/types/restrictionsDataQuery';
import moment from 'moment';

import { useMobile } from 'hooks';
import { showToaster } from 'lib/toaster';
import { BREAK_POINT_CUSTOM_XS } from 'lib/breakpoints';
import {
  getRestrictions,
  getSeaGptRestrictions,
  updateRestrictionsFeedback,
} from 'api/flotilla';
import { Port } from 'utils/types/crew-change-types';

import { Loader, Modal } from 'components/shared';
import RestrictionsFooter from './Footer';
import {
  Container,
  Sidebar,
  SidebarContent,
  SidebarTitle,
  ItemWrapper,
  SourceName,
  DateText,
  Header,
  EmptyWrapper,
  Main,
  Sources,
} from './common';
import { UpdateFeedback, UserFeedback } from './type';
import SearchComponent from './SearchComponent';

const ProcessedHTML = lazy(() => import('./ProcessedHTML'));

const noPaddingContentStyles = {
  padding: 0,
  margin: 0,
  overflowX: 'hidden',
};

type RestrictionsDataAndFeedback = {
  data: RestrictionsData;
  feedback: UserFeedback | null;
  disableFeedback: boolean;
};
type FetchedRestrictions = Map<string, RestrictionsDataAndFeedback>;

type Props = {
  type: RestrictionType;
  restrictionDetails: Pick<Port, 'name' | 'locode' | 'restrictionsAvailable'>;
  closeModal: () => void;
};

const fetchedRestrictions: FetchedRestrictions = new Map();

function RestrictionsModal({ type, restrictionDetails, closeModal }: Props) {
  const { name, locode, restrictionsAvailable } = restrictionDetails;
  const title = `Port Restrictions ${name ? `- ${name}` : ''}`;
  const sources = (restrictionsAvailable || []).map(({ source }) => source);

  const isMobile = useMobile();
  const isCustomSize = useMobile(BREAK_POINT_CUSTOM_XS);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [matchCount, setMatchCount] = useState(0);
  const [details, setDetails] = useState<RestrictionsDataAndFeedback | null>(
    null
  );
  const [selected, setSelected] = useState<RestrictionSourceType | undefined>(
    sources[0]
  );
  const [feedback, setFeedback] = useState<UserFeedback | null>(null);

  const handleFetchRestrictions = async (
    selectedSource: RestrictionSourceType
  ) => {
    const alreadyFetched = fetchedRestrictions.get(selectedSource);
    if (alreadyFetched) {
      setDetails(alreadyFetched);
      return;
    }

    setLoading(true);
    const isFromSeaGptEmail = restrictionsAvailable?.find(
      (o) => o.source === selectedSource
    )?.isFromSeaGptEmail;

    if (isFromSeaGptEmail) {
      const { message, data } = await getSeaGptRestrictions({
        locode,
        agent: selectedSource,
      });
      setFeedback(null);
      if (data) {
        fetchedRestrictions.set(selectedSource, {
          data,
          feedback: null,
          disableFeedback: true,
        });
        setDetails({ data, feedback, disableFeedback: true });
      } else {
        showToaster({ message, type: 'error' });
      }
    } else {
      const { message, data } = await getRestrictions({
        type,
        source: selectedSource,
        portOrCountryCode: locode,
      });
      if (data) {
        const { restrictionsData, userFeedback } = data;
        fetchedRestrictions.set(selectedSource, {
          data: restrictionsData,
          feedback: userFeedback,
          disableFeedback: false,
        });
        setDetails({
          data: restrictionsData,
          feedback: userFeedback,
          disableFeedback: false,
        });
        setFeedback(userFeedback);
      } else {
        showToaster({ message, type: 'error' });
      }
    }

    setLoading(false);
  };

  const handleUpdateFeedback: UpdateFeedback = async ({ like, reason }) => {
    const updateDisabled = feedback?.like && like === feedback.like;

    if (!details || updateDisabled) return;

    const { source, selector, type, lastUpdated: date } = details.data;
    const feedbackRequest = {
      like,
      type,
      source,
      selector,
      reason,
      updateTs: typeof date !== 'string' ? date.toISOString() : date,
    };
    const { success, message } = await updateRestrictionsFeedback(
      feedbackRequest
    );
    // update UI with successful feedback update to backend
    if (success) {
      fetchedRestrictions.set(source, {
        data: fetchedRestrictions.get(source)?.data!,
        feedback: { like, reason },
        disableFeedback: false,
      });
      setFeedback({ like, reason });
    }
    showToaster({ message, type: success ? 'success' : 'error' });
  };

  useEffect(() => {
    return () => {
      // clear saved info from memory when modal unmounts
      fetchedRestrictions.clear();
    };
  }, []);

  useEffect(() => {
    if (!selected) return;
    handleFetchRestrictions(selected);
    setFeedback(fetchedRestrictions.get(selected)?.feedback || null);
  }, [selected]); // eslint-disable-line

  if (!restrictionsAvailable) {
    return null;
  }

  const handleUpdateSource = (source: RestrictionSourceType | undefined) => {
    setSelected(source);
    setSearchQuery('');
  };

  const renderHeaderComponent = () => {
    if (loading) {
      return null;
    }
    return (
      <SearchComponent
        count={matchCount}
        query={searchQuery}
        setQuery={setSearchQuery}
      />
    );
  };

  const renderBody = () => {
    if (loading) {
      return (
        <EmptyWrapper>
          <Loader size={150} />
        </EmptyWrapper>
      );
    }

    if (!details?.data.htmlContent) {
      return (
        <EmptyWrapper>
          <Header>No port restriction details available.</Header>
        </EmptyWrapper>
      );
    }

    const resData = {
      type,
      name,
      details: details.data,
      locode,
      source: selected!,
    };

    return (
      <Main>
        <Suspense fallback={<></>}>
          <ProcessedHTML
            htmlContent={details.data.htmlContent}
            searchQuery={searchQuery}
            setCount={setMatchCount}
          />
        </Suspense>
        {Boolean(details) && (
          <RestrictionsFooter
            disableFeedback={details.disableFeedback}
            feedback={feedback}
            updateFeedback={handleUpdateFeedback}
            restrictionsData={resData}
          />
        )}
      </Main>
    );
  };

  return (
    <Modal
      width={900}
      title={title}
      closeModal={closeModal}
      styles={noPaddingContentStyles}
      // Render search component in header for larger dimensions
      headerComponent={!isCustomSize && renderHeaderComponent()}
    >
      <Container $isMobile={isMobile} $noSource={!sources.length}>
        {!isMobile && Boolean(sources.length) && (
          <Sidebar>
            <SidebarContent>
              <SidebarTitle>Sources</SidebarTitle>
              {restrictionsAvailable.map(
                ({ source, latestUpdatedAt }, index) => {
                  const testId = `e2e_restrictions-source-${index + 1}`;
                  const active = selected === source;
                  const updateText = latestUpdatedAt
                    ? `Last updated ${moment(latestUpdatedAt).fromNow()}`
                    : 'Last update unavailable';

                  return (
                    <ItemWrapper
                      key={source}
                      $active={active}
                      onClick={() => handleUpdateSource(source)}
                    >
                      <SourceName data-id={testId} $active={active}>
                        {source.toUpperCase()}
                      </SourceName>
                      <DateText $active={active}>{updateText}</DateText>
                    </ItemWrapper>
                  );
                }
              )}
            </SidebarContent>
          </Sidebar>
        )}

        <>
          <Sources
            isMobile={isMobile}
            sources={sources}
            selected={selected}
            setSelected={handleUpdateSource}
            // Render search component inside modal body for smaller dimensions
            searchComponent={isCustomSize && renderHeaderComponent()}
          />
          {renderBody()}
        </>
      </Container>
    </Modal>
  );
}

export default memo(RestrictionsModal);
