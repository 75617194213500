import { memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import { darkBlue } from 'lib/colors';
import { startFlightBooking } from 'redux/actions';
import { RootState } from 'redux/types';
import { Flight } from 'utils/types/crew-change-types';
import { showToaster } from 'lib/toaster';

import { Button } from 'components/shared';
import { selectFlightToBook } from 'api/flotilla';

const StyledButton = styled(Button)`
  min-height: unset;
  min-width: unset;
  column-gap: 0.2rem;
  padding: 0.2rem 1rem;
  transform: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  transition: background 200ms ease-in-out;
  &:hover {
    background: ${darkBlue};
  }
`;

type QuickFlyBookProps = {
  flight: Flight;
};

function QuickFlyBook(props: QuickFlyBookProps) {
  const dispatch = useAppDispatch();
  const { flight } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const bookingUserType = useSelector(
    (state: RootState) => state.settings.userInfo?.type
  );

  // TODO: There should be something in here to indicate if it is an automated api flow, or a manual flow
  // Different endpoints are used depending on the type
  const startBooking = useCallback(async () => {
    setIsLoading(true);
    if (flight.bookingFlowType === 'API') {
      try {
        const { success, message } = await selectFlightToBook(flight.flightId);
        if (!success) throw Error(message);
        dispatch(startFlightBooking(flight));
      } catch (error) {
        console.error(error);
        showToaster({
          message: 'Flight Booking has Failed, try Refreshing Flights!',
          type: 'error',
        });
      }
    } else {
      dispatch(startFlightBooking(flight));
    }
    setIsLoading(false);
  }, [flight, dispatch]);

  if (
    !bookingUserType ||
    bookingUserType !== 'client' ||
    flight.bookingFlowType !== 'API'
  )
    return null;
  return (
    <StyledButton loading={isLoading} onClick={startBooking}>
      Book
    </StyledButton>
  );
}

export default memo(QuickFlyBook);
