import styled from 'styled-components/macro';

import { gray60 } from 'lib/colors';
import { secondsToDHM } from 'lib/common';
import { PlanningData } from 'utils/types';

const Text = styled.div`
  font-size: 0.7rem;
  font-weight: 500;
  color: ${gray60};
  letter-spacing: 0.75px;
  text-transform: uppercase;
`;

function RouteMeta({ planningData }: { planningData: PlanningData }) {
  const { route: routePorts } = planningData;
  // calculate total time & distance for all route ports
  const { distance, time } = routePorts.reduce(
    (acc, currPort) => {
      const { distance, time } = currPort;
      return {
        distance: acc.distance + (distance || 0),
        time: acc.time + (time || 0),
      };
    },
    { distance: 0, time: 0 }
  );

  if (!distance) {
    return null;
  }

  return (
    <div data-id="route-info">
      <Text>
        <b>Total Distance:</b> {Math.round(distance)} NM
      </Text>
      <Text>
        <b>Total Time:</b> {secondsToDHM(time)}
      </Text>
    </div>
  );
}

export default RouteMeta;
