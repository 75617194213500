import isEqual from 'lodash/isEqual';

import { isValidEmail } from 'lib/common';
import { isDevelopment, isProduction } from 'lib/environments';

import {
  MIN_DEVIATION,
  NO_DEVIATION,
  PREFERRED,
} from 'components/CrewChangePanel/helpers/constants';
import {
  CustomAlertResponse,
  SettingsItem,
  TypeCounts,
  UserProps,
} from './types';
import DevicesIcon from '@mui/icons-material/Devices';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import FlightIcon from '@mui/icons-material/Flight';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MemoryIcon from '@mui/icons-material/Memory';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import SecurityIcon from '@mui/icons-material/Security';

// TODO: enable for all env when features are ready
export const settingsOptions: SettingsItem[] = [
  ...(isDevelopment
    ? ([{ name: 'Devices', icon: DevicesIcon }] as SettingsItem[])
    : []),
  { name: 'Alerts', icon: NotificationsIcon },
  { name: 'Vessels', icon: DirectionsBoatIcon },
  { name: 'Flight Search', icon: FlightIcon },
  { name: 'Crew Changes', icon: Diversity1Icon },
  { name: 'Account', icon: AccountCircleIcon },
  { name: 'Security', icon: SecurityIcon },
  { name: 'Proteus', icon: MemoryIcon },
  { name: 'Demo Control', icon: SportsEsportsIcon, hide: isProduction }, // DO NOT ever enable this for production
];

export const SAMPLE_PORTS = [
  {
    id: 1,
    locode: 'SGSIN',
    name: 'Singapore',
    costs: {
      s5: { currency: 'USD', indicative: 253 },
      wss: { currency: 'USD', indicative: 230 },
    },
    airport: 'SIN, Changi',
    priority: NO_DEVIATION,
  },
  {
    id: 2,
    locode: 'MYTBI',
    name: 'Tanjong Bin',
    costs: {
      wss: { currency: 'USD', indicative: 230 },
    },
    airport: 'KUL, Kuala Lumpur Intl',
    priority: MIN_DEVIATION,
  },
  {
    id: 3,
    locode: 'MYPGG',
    name: 'Pengerang',
    costs: {
      wss: { currency: 'USD', indicative: 230 },
    },
    airport: 'KUL, Kuala Lumpur Intl',
    priority: MIN_DEVIATION,
  },
  {
    id: 4,
    locode: 'SGCHG',
    name: 'Changi',
    costs: {
      wss: { currency: 'USD', indicative: 230 },
    },
    airport: 'SIN, Changi',
    priority: MIN_DEVIATION,
  },
  {
    id: 5,
    locode: 'HKHKG',
    name: 'Hong Kong',
    costs: {
      s5: { currency: 'USD', indicative: 193 },
      wss: { currency: 'USD', indicative: 230 },
    },
    airport: 'HKG, International',
    priority: PREFERRED,
  },
];

export const initialCustomAlertResponse: CustomAlertResponse = {
  initial: true,
  loadMore: false,
  results: [],
  typeCounts: {
    SEARCH_FILTER: 0,
    UPDATE_FILTER: 0,
  },
};

export const updateTypeCounts = (
  action: 'add' | 'delete',
  alertId: number,
  response: CustomAlertResponse
): TypeCounts => {
  const { results: alerts, typeCounts } = response;
  const { type } = alerts.find(({ id }) => id === alertId) || {};

  if (action === 'add') {
    return {
      ...typeCounts,
      UPDATE_FILTER: typeCounts['UPDATE_FILTER'] + 1,
    };
  }

  if (action === 'delete') {
    return {
      ...typeCounts,
      [type!]: typeCounts[type!] - 1,
    };
  }

  return typeCounts;
};

export const getEmailErrorMessage = (
  email: string | null | undefined
): string | undefined => {
  if (!email || !email.trim().length) {
    return 'This field is required.';
  }
  return !isValidEmail(email) ? 'The email is invalid.' : undefined;
};

export function getObjectDiff(
  obj1: any,
  obj2: any,
  compareRef: boolean = false
): string[] {
  return Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);

      if (compareRef && obj1[key] !== obj2[key]) {
        result[resultKeyIndex] = `${key} (ref)`;
      } else {
        result.splice(resultKeyIndex, 1);
      }
    }
    return result;
  }, Object.keys(obj2));
}

export const getUserName = (
  user: Pick<UserProps, 'firstName' | 'lastName'>
) => {
  if (!user.firstName) return '-';
  if (!user.lastName) return user.firstName;
  return `${user.firstName} ${user.lastName}`;
};
