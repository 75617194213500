import { memo } from 'react';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Popper,
  Select,
  TextField,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';

import { BREAK_POINT_XS } from 'lib/breakpoints';
import { Tooltip } from 'components/shared';
import { PORT_PRIORITIES } from '../../../helpers';
import { Label } from '../../../common';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    flex-wrap: wrap;
    row-gap: 1.25rem;
  }
`;

const DropdownWrapper = styled(FlexWrapper)`
  margin-left: 1.5rem;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    margin-left: 0;
  }
`;

const StyledLabel = styled(Label)`
  margin-right: 0.5rem;
`;

const PriorityWrapper = styled.div`
  flex-shrink: 0;
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input,
  .MuiAutocomplete-loading {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiInputLabel-root {
    width: fit-content;
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }

  .MuiChip-root {
    height: 20px;
    font-size: 0.7rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const StyledTextField = muiStyled(TextField)(() => ({
  '.MuiInputBase-root': {
    height: 35,
  },
}));

const StyledPopper = muiStyled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-loading': {
    fontSize: '1rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const dropdownStyles = {
  minWidth: '60px',
  marginLeft: '8px',
  marginRight: '10px',
};

const textStyles = {
  fontSize: '0.75rem',
  fontFamily: 'HK Grotesk, Roboto',
};

const dropDownStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type AgencyProps = {
  agency: string;
  options: string[];
  onSelect: (value: string) => void;
};

type PriorityProps = {
  priorities: string[];
  onSelect: (options: string[]) => void;
};

const PortDropdowns = {
  Agency: memo(({ agency, options: baseOptions, onSelect }: AgencyProps) => {
    const agencyOptions = [...baseOptions, 'ALL'];
    const currentAgency = agencyOptions.find((item) =>
      item.toLowerCase().includes(agency.toLowerCase())
    );

    return Boolean(baseOptions.length) ? (
      <DropdownWrapper>
        <Tooltip content="Compare costs between port agencies" enterDelay={500}>
          <StyledLabel>Agency</StyledLabel>
        </Tooltip>
        <FormControl variant="standard" sx={dropdownStyles}>
          <Select
            size="small"
            value={currentAgency || ''}
            onChange={({ target }) => onSelect(target.value)}
            sx={textStyles}
          >
            {agencyOptions.map((text) => (
              <MenuItem key={text} value={text} sx={textStyles}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DropdownWrapper>
    ) : null;
  }),

  Priority: memo(({ priorities, onSelect }: PriorityProps) => (
    <DropdownWrapper>
      <PriorityWrapper>
        <Autocomplete
          multiple
          freeSolo
          size="small"
          inputValue=""
          options={PORT_PRIORITIES}
          value={priorities}
          onChange={(_, newValue) => onSelect(newValue)}
          filterSelectedOptions
          renderInput={(params) => (
            <StyledTextField {...params} label="Priority" size="small" />
          )}
          ListboxProps={{ style: dropDownStyles }}
          PopperComponent={StyledPopper}
          sx={{ minWidth: 140 }}
        />
      </PriorityWrapper>
    </DropdownWrapper>
  )),
};

export default PortDropdowns;
