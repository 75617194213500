import { SyntheticEvent, useState } from 'react';
import { MenuItem, Menu, Fade } from '@mui/material';
import styled from 'styled-components/macro';

import sleep from 'lib/sleep';
import { blue, white } from 'lib/colors';
import { Tooltip } from 'components/shared';
import { CrewAddStatus } from 'components/CrewChangePanel/types';

import { AddButtonProps } from './types';
import { ActionButton } from '../../common';
import PersonIcon from '@mui/icons-material/Person';

const StyledPersonIcon = styled(PersonIcon)`
  margin-right: 0.5rem;
  font-size: 18px !important;
`;

const StyledButton = styled(ActionButton)`
  margin: auto 2px;
`;

const AddButton = styled(StyledButton)`
  margin-left: 0.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  z-index: 999;
`;

const menuItemStyles = {
  fontSize: '0.9rem',
  lineHeight: 1,
  fontWeight: '500',
  fontFamily: 'HK Grotesk, Arial',
  transition: 'all 100ms ease',
  '&:hover': {
    background: blue,
    color: white,
  },
};

const options = [
  {
    content: 'Add New Crew',
    active: true,
  },
  {
    content: 'Select Existing Crew',
    active: true,
    tooltip: 'Select crew from existing evetn(s)',
  },
];

function AddCrewButton({ updateStatus }: AddButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!updateStatus) {
    return null;
  }

  const handleClickMenuItem = (type: keyof CrewAddStatus) => {
    // set state to lose focus from input fields
    updateStatus({ [type]: false });
    // set focus again
    sleep(0).then(() => {
      updateStatus({ [type]: true });
    });
    setAnchorEl(null);
  };

  return (
    <>
      <AddButton
        className="e2e_add-crew-button"
        variant="primary"
        onClick={(event: SyntheticEvent) =>
          setAnchorEl(event.target as HTMLElement)
        }
      >
        <StyledPersonIcon />
        Add Crew
      </AddButton>

      <Menu
        id="fade-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        sx={{ marginTop: '1px' }}
      >
        {options
          .filter(({ active }) => active)
          .map(({ content, tooltip }, index) =>
            tooltip ? (
              <Tooltip
                key={index}
                placement="left"
                enterDelay={500}
                content={tooltip}
              >
                <MenuItem
                  data-id="e2e_select_existing_crew"
                  onClick={() => handleClickMenuItem('isSelecting')}
                  sx={menuItemStyles}
                >
                  {content}
                </MenuItem>
              </Tooltip>
            ) : (
              <MenuItem
                key={index}
                data-id="e2e_add-new-crew"
                onClick={() => handleClickMenuItem('addingId')}
                sx={menuItemStyles}
              >
                {content}
              </MenuItem>
            )
          )}
      </Menu>
    </>
  );
}

export default AddCrewButton;
