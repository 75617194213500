import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { searchFlightsAsync } from 'redux/thunks';
import { ResultFilters } from 'components/FlotillaSearch/types';
import { initialResultFilters } from '../helpers/flotillaSearch';
import {
  FlightSearch,
  FlotillaSearch,
  QuickFlySearch,
  RootState,
} from '../types';

const initialFlightSearch: QuickFlySearch = {
  listOpen: false,
  loading: false,
  searchQuery: '',
  date: '',
  departure: null,
  arrival: null,
  flights: [],
  fromCache: false,
  filters: initialResultFilters,
  modalOpen: false,
  cabinClass: undefined,
  flightNumberDetails: {
    flightNumber: '',
    included: false,
  },
};

export const initialFlotillaSearch: FlotillaSearch = {
  searchReady: false,
  searchIsExpanded: false,
  flightSearch: initialFlightSearch,
  copilotSearch: null,
};

const flotillaSearchSlice = createSlice({
  name: 'flotillaSearch',
  initialState: initialFlotillaSearch,
  reducers: {
    setCopilotSearch: (state, action: PayloadAction<string>) => ({
      ...state,
      copilotSearch: action.payload,
    }),
    setSearchReady: (state) => ({ ...state, searchReady: true }),
    setSearchExpanded: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      const { searchIsExpanded } = state;
      return {
        ...state,
        searchIsExpanded:
          payload !== searchIsExpanded ? payload : searchIsExpanded,
      };
    },
    toggleModalView: (state, action: PayloadAction<boolean>) => ({
      ...state,
      searchIsExpanded: !action.payload,
      flightSearch: {
        ...state.flightSearch,
        modalOpen: action.payload,
      },
    }),
    updateFlightSearch: (
      state,
      action: PayloadAction<Partial<FlightSearch>>
    ) => ({
      ...state,
      flightSearch: {
        ...state.flightSearch,
        ...action.payload,
      },
    }),
    updateQuickFlyFilters: (
      state,
      action: PayloadAction<Partial<ResultFilters>>
    ) => ({
      ...state,
      flightSearch: {
        ...state.flightSearch,
        filters: {
          ...state.flightSearch.filters,
          ...action.payload,
        },
      },
    }),
    resetQuickFlyFilters: (state, action: PayloadAction<ResultFilters>) => ({
      ...state,
      flightSearch: {
        ...state.flightSearch,
        filters: action.payload, // reset QuickFly filters to initial values set in settings
      },
    }),
    resetFlightSearch: (state) => ({
      ...state,
      flightSearch: initialFlightSearch,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchFlightsAsync.pending, (state) => ({
        ...state,
        flightSearch: { ...state.flightSearch, loading: true },
      }))
      .addCase(searchFlightsAsync.fulfilled, (state, action) => ({
        ...state,
        flightSearch: {
          ...state.flightSearch,
          ...action.payload,
          loading: false,
        },
      }));
  },
});

/* ----- selectors -----*/

export const selectFlotillaSearch = ({ flotillaSearch }: RootState) =>
  flotillaSearch;

/* ----- actions -----*/

export const {
  setSearchReady,
  setSearchExpanded,
  updateFlightSearch,
  updateQuickFlyFilters,
  resetFlightSearch,
  resetQuickFlyFilters,
  toggleModalView,
  setCopilotSearch,
} = flotillaSearchSlice.actions;

/* ----- reducer -----*/

export default flotillaSearchSlice.reducer;
