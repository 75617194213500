import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { memo, useEffect, useState } from 'react';

import { toggleDesktopNotifications } from 'redux/actions';
import { selectDesktopNotifications } from 'redux/selectors';
import { useAppDispatch } from 'hooks';
import { trackUserAction } from 'lib/amplitude';
import { textBlack, darkBlue, white } from 'lib/colors';
import { BREAK_POINT_SM } from 'lib/breakpoints';
import { TRACK_TEST_NOTIFICATION } from 'utils/analytics/constants';
import { ButtonV2, SwitchV2 } from '../../../shared';
import { Title, Text, Section } from '../../common';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const BoldText = styled.b`
  color: ${textBlack};
  font-weight: bold;
`;

const ToggleWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  column-gap: 1rem;
`;

const IncompleteWrapper = styled.div`
  max-width: 96%;
  padding: 1rem;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: ${darkBlue};
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    margin-right: 1rem;
  }
`;

const Content = styled(Text)`
  color: ${white};
`;

const IconWrapper = styled.div`
  padding-right: 0.75rem;
`;

const WarningIcon = styled(WarningRoundedIcon)`
  font-size: 20px !important;
  color: ${white};
`;

const DesktopNotifications = () => {
  const dispatch = useAppDispatch();
  const desktopNotifications = useSelector(selectDesktopNotifications);
  const [errorText, setErrorText] = useState('');

  const handleErrorText = () => {
    if (!window.Notification) {
      setErrorText(
        "Your current browser doesn't support desktop notifications."
      );
    } else if (Notification.permission === 'default') {
      setErrorText('');
    } else if (Notification.permission === 'denied') {
      setErrorText(
        'It looks like you have disabled permissions to receive browser notifications from this application. Please enable in your browser settings.'
      );
    }
  };

  useEffect(() => {
    // If notification API doesn't exist, disable
    if (!window.Notification) {
      localStorage.setItem('allow-desktop-notifications', '0');
      dispatch(toggleDesktopNotifications(false));
      return;
    }
    if (
      localStorage.getItem('allow-desktop-notifications') !== '0' &&
      Notification.permission === 'granted'
    ) {
      dispatch(toggleDesktopNotifications(true));
    } else {
      dispatch(toggleDesktopNotifications(false));
      localStorage.setItem('allow-desktop-notifications', '0');
    }
    handleErrorText();
  }, []); // eslint-disable-line

  const handleDesktopNotifications = () => {
    if (!window.Notification) return;
    if (desktopNotifications === true) {
      dispatch(toggleDesktopNotifications(false));
      localStorage.setItem('allow-desktop-notifications', '0');
    } else if ('Notification' in window)
      Notification.requestPermission().then((result) => {
        if (result === 'denied' || result === 'default') {
          handleErrorText();
          localStorage.setItem('allow-desktop-notifications', '0');
          return;
        } else {
          dispatch(toggleDesktopNotifications(true));
          localStorage.setItem('allow-desktop-notifications', '1');
        }
      });
  };

  const handleTestNotification = () => {
    if (!window.Notification) return;
    trackUserAction(TRACK_TEST_NOTIFICATION('request'));
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        const notification = new Notification('Greywing', {
          body: `This is a test notification from Greywing.`,
          icon: 'favicons/favicon.ico',
          badge: 'favicons/favicon.ico',
          tag: `test-notification-${new Date().getTime()}}`,
        });

        notification.onclick = () => {
          trackUserAction(TRACK_TEST_NOTIFICATION('click'));
          window.parent.parent.focus();
        };
        notification.onshow = () => {
          trackUserAction(TRACK_TEST_NOTIFICATION('show'), 'happened');
        };
        notification.onerror = (error) => {
          trackUserAction(TRACK_TEST_NOTIFICATION('error'), 'happened', {
            error,
          });
          console.log('Show error', error);
        };
        notification.onclose = () => {
          trackUserAction(TRACK_TEST_NOTIFICATION('close'));
        };
      }
    });
  };

  return (
    <div>
      <Section data-id="desktop-notifications">
        <Title>Desktop Notifications</Title>
        <Text>Receive Notifications directly in your browser.</Text>
        <ToggleWrapper>
          <SwitchV2
            checked={desktopNotifications}
            onChange={() => handleDesktopNotifications()}
          />
          <Text>
            <BoldText>Enable Desktop Notifications</BoldText>
          </Text>
        </ToggleWrapper>
        {desktopNotifications && (
          <ButtonV2 variant="secondary" onClick={handleTestNotification}>
            Send a test notification
          </ButtonV2>
        )}
        {errorText && (
          <IncompleteWrapper>
            <IconWrapper>
              <WarningIcon />
            </IconWrapper>
            <div>
              <Content>{errorText}</Content>
            </div>
          </IncompleteWrapper>
        )}
      </Section>
    </div>
  );
};

export default memo(DesktopNotifications);
