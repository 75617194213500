import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchPortsInRange } from 'api/flotilla';

/* ----- Async Thunk ----- */

export const portsInRangeAsync = createAsyncThunk(
  'portsInRange/portsInRangeAsync',
  async ({ lat, lng }: { lat: number; lng: number }) => {
    const response = await fetchPortsInRange(lng, lat);
    return response.ports;
  }
);
