export type StepsKey =
  | 'setup-flotilla'
  | 'add-vessel'
  | 'start-cc'
  | 'start-cc-compare'
  | 'add-search-filter';

export type OnboardSteps = {
  title: string;
  description: string;
  youtubeId: string;
  url: string;
  step: number;
  stepKey: StepsKey;
  disabled: boolean;
  reason?: string;
};

export type OnboardContent = Pick<
  OnboardSteps,
  'title' | 'description' | 'youtubeId' | 'url' | 'stepKey'
>;

export const ONBOARDING_STEPS: OnboardSteps[] = [
  {
    title: 'Setup Flotilla',
    description: `Configure your dashboard with the information you'd like to see`,
    youtubeId: 'wRYCZMxSzlU',
    url: 'https://grey-wing.com/blog/what-i-think-about-when-i-think-about-crew-changes-1-2022',
    step: 1,
    stepKey: 'setup-flotilla',
    disabled: false,
  },
  {
    title: 'Add your first vessel',
    description: 'Add a vessel that you would like to track.',
    youtubeId: 'wRYCZMxSzlU',
    url: '',
    step: 2,
    stepKey: 'add-vessel',
    disabled: false,
  },
  {
    title: 'Add a search filter',
    description:
      'Filter the vessels on the map according to a specific criteria.',
    youtubeId: 'wRYCZMxSzlU',
    url: '',
    step: 3,
    stepKey: 'add-search-filter',
    disabled: true,
    reason: 'You have to add your first vessel.',
  },
  {
    title: 'Start a crew change',
    description: 'Start planning your first crew change',
    youtubeId: 'wRYCZMxSzlU',
    url: 'https://grey-wing.com/blog/what-i-think-about-when-i-think-about-crew-changes-1-2022',
    step: 4,
    stepKey: 'start-cc',
    disabled: true,
    reason: 'You have to add your first vessel.',
  },
  {
    title: 'Compare crew change estimates',
    description: 'Observe the breakdown of costs between ports',
    youtubeId: 'wRYCZMxSzlU',
    url: '',
    step: 5,
    stepKey: 'start-cc-compare',
    disabled: false,
  },
];
