import { memo } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, TextFieldProps } from '@mui/material';
import {
  useController,
  Control,
  FieldValues,
  FieldPathByValue,
} from 'react-hook-form';

function DatePickerV2<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  textFieldProps,
  ...props
}: {
  name: TPath;
  control: Control<TFieldValues>;
  textFieldProps: TextFieldProps;
  [x: string]: any;
}) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
  });
  return (
    <>
      <DatePicker
        {...props}
        {...field}
        value={field.value}
        renderInput={(params: any) => (
          <TextField
            size="small"
            autoComplete="off"
            inputRef={field.ref}
            onBlur={field.onBlur}
            {...params}
            {...textFieldProps}
            error={invalid}
            helperText={error?.message}
          />
        )}
      />
    </>
  );
}

export default memo(DatePickerV2);
