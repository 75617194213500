import { useMemo, useCallback, useState, useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import { featureCollection as getFeatureCollection } from '@turf/turf';
import type {
  Airport,
  Vessel,
} from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

// Components
import CardContainer from './common/CardContainer';
import CardHeader from './common/CardHeader';
import { Button, Tooltip } from 'components/shared';

// Utilities and Types
import {
  GridColDef,
  DataGridPro,
  GridEventListener,
  GridEvents,
} from '@mui/x-data-grid-pro';

// Styles
import { lightGray, textGray } from 'lib/colors';
import {
  drawAirportRoutes,
  drawAirports,
  generateAirportFeature,
  generateAirportRouteFeature,
} from 'map/airports';
import { removeLayer, removeSource, setSource } from 'map/map';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import InfoIcon from '@mui/icons-material/Info';

interface ICrewHomeAirportProps {
  vessel: Vessel;
}
interface AirportData {
  id: string;
  airport: Airport | null;
  count: number;
  offsigners: number;
  onsigners: number;
}

const Text = styled.span`
  margin-left: 0.5rem;
`;

const AirportName = styled.span`
  color: ${textGray};
  font-size: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  margin-left: 0.5rem;
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    display: none;
  }
`;

const TableWrapper = styled.div`
  div,
  p {
    font-family: 'HK Grotesk', 'Roboto', sans-serif;
  }

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }
  .MuiDataGrid-row.Mui-selected {
    background-color: ${lightGray} !important;
  }
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
`;

const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: start;
  column-gap: 0.25rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    font-size: 20px !important;
  }
`;

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Airport',
    flex: 10,
    renderCell: ({ row }) => {
      return (
        <p
          style={{
            margin: 0,
            overflow: 'hidden',
          }}
        >
          {row.id}

          {row.airport && (
            <>
              <br />
              <AirportName>{row.airport.text}</AirportName>
            </>
          )}
        </p>
      );
    },
  },
  {
    field: 'onsigners',
    flex: 1,
    headerName: 'On',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    minWidth: 60,
  },
  {
    field: 'offsigners',
    flex: 1,
    headerName: 'Off',
    sortable: false,
    align: 'center',
    headerAlign: 'center',
    minWidth: 60,
  },
];

type HomeAirport = {
  id: string;
  count: number;
  offsigners: number;
  onsigners: number;
  airport: Airport | null;
};

export default memo(function CrewHomeAirport({
  vessel,
}: ICrewHomeAirportProps) {
  const { crewHomeAirports } = vessel;
  const [activeAirports, setActiveAirports] = useState<HomeAirport[]>([]);
  const [showAll, setShowAll] = useState(false);

  // Clean up only when this component unmounts
  useEffect(() => {
    setSource('crew-home-airports').then(() => {
      drawAirports('crew-home-airports', 'crew-home-airports');
    });
    setSource('crew-home-airport-routes').then(() => {
      drawAirports('crew-home-airport-routes', 'crew-home-airport-label', {
        layout: {
          // 'icon-size': 0.5,
          // 'icon-image': 'flight-image',
          'symbol-placement': 'line-center',
          'text-field': ['get', 'label'],
          'text-anchor': 'top',
          'text-font': ['literal', ['HK Grotesk Medium', 'Roboto Medium']],
          'text-size': 12,
        },
      });
      drawAirportRoutes(
        'crew-home-airport-routes',
        'crew-home-airport-routes',
        undefined,
        'crew-home-airport-label'
      );
    });

    return () => {
      removeLayer('crew-home-airports').then((stillExist) => {
        !stillExist && removeSource('crew-home-airports');
      });
      Promise.all([
        removeLayer('crew-home-airport-routes'),
        removeLayer('crew-home-airport-label'),
      ]).then(() => {
        removeSource('crew-home-airport-routes');
      });
    };
  }, [vessel]); // eslint-disable-line

  const airportData: AirportData[] = useMemo(() => {
    if (!crewHomeAirports) return [];
    return Object.keys(crewHomeAirports)
      .sort((a, b) => {
        return crewHomeAirports[b].count - crewHomeAirports[a].count;
      })
      .map((key: string) => ({
        id: crewHomeAirports[key].airport?.id || key,
        ...crewHomeAirports[key],
      }));
  }, [crewHomeAirports]);

  const handleRowClick: GridEventListener<GridEvents.rowClick> = useCallback(
    ({ row }) => {
      if (activeAirports.map((each) => each.id).includes(row.id)) {
        setActiveAirports([]);
      } else {
        setActiveAirports([row as HomeAirport]);
      }
    },
    [activeAirports]
  );

  const featureCollection = useMemo(() => {
    return {
      airports: getFeatureCollection(
        activeAirports.map((homeAirport) =>
          generateAirportFeature(
            `crew-home-airport-${vessel.id}-${homeAirport.airport!.id}`,
            homeAirport.airport as Airport
          )
        )
      ),
      airportRoutes: getFeatureCollection(
        activeAirports.map(({ airport, onsigners, offsigners }) =>
          generateAirportRouteFeature(
            `crew-home-airport-${vessel.id}-route-${airport!.id}`,
            {
              start: [vessel.lng, vessel.lat],
              end: [airport!.lon, airport!.lat],
            },
            {
              label: `${airport!.text} (${onsigners} on, ${offsigners} off)`,
            }
          )
        )
      ),
    };
  }, [activeAirports, vessel]);

  const showAllAirport = useCallback(() => {
    if (showAll || activeAirports.length) {
      setActiveAirports([]);
      setShowAll(false);
    } else {
      setActiveAirports(
        airportData.reduce<HomeAirport[]>((acc, each) => {
          if (each.airport) {
            acc.push(each);
          }
          return acc;
        }, [])
      );
      setShowAll(true);
    }
  }, [airportData, activeAirports, showAll, setShowAll]);

  useEffect(() => {
    setSource('crew-home-airports', featureCollection.airports);
    setSource('crew-home-airport-routes', featureCollection.airportRoutes);
  }, [featureCollection]);

  if (!crewHomeAirports || !Object.keys(crewHomeAirports).length) return null;

  return (
    <CardContainer>
      <CardHeader>
        <CardHeaderWrapper>
          <FlexWrapper>
            <Tooltip content="Click on a row to view Airport">
              <InfoIcon />
            </Tooltip>
            <Text>Crew Change Airports</Text>
          </FlexWrapper>
          <ButtonContainer>
            <Button
              size="small"
              variant="secondary"
              onClick={showAllAirport}
              style={{ marginRight: '0' }}
            >
              {showAll || activeAirports.length ? 'Hide' : 'See on Map'}
            </Button>
          </ButtonContainer>
        </CardHeaderWrapper>
      </CardHeader>
      <TableWrapper>
        <DataGridPro
          onRowClick={handleRowClick}
          columns={columns}
          rows={airportData}
          disableColumnMenu
          hideFooter
          autoHeight
        />
      </TableWrapper>
    </CardContainer>
  );
});
