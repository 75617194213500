import * as Sentry from '@sentry/react';
import styled from 'styled-components/macro';

import sleep from 'lib/sleep';
import { isDevelopment } from 'lib/environments';
import { trackUserAction } from 'lib/amplitude';
import { setErrorReloadCount } from 'lib/common';
import { ERROR_RELOAD_COUNT } from 'lib/constants';

import { Emoji } from './shared';
import { TRACK_CATCH_ERROR } from 'utils/analytics/constants';

const Wrapper = styled.div`
  margin-top: 10vh;
  padding: 0;
  text-align: center;
  font-size: 20px;
  line-height: 2;
`;

const Link = styled.span`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;

type Props = {
  children?: React.ReactNode;
};

// using error boundary from Sentry
// https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

function ErrorBoundary({ children }: Props): JSX.Element {
  const reloadCount = JSON.parse(
    localStorage.getItem(ERROR_RELOAD_COUNT) || '0'
  );
  // for non-development environments
  const canReload = !isDevelopment && reloadCount < 2;

  const handleGoBack = () => {
    window.location.reload();
  };

  const handleAppReload = () => {
    handleGoBack();
    setErrorReloadCount({ count: reloadCount + 1 });
    trackUserAction(TRACK_CATCH_ERROR, 'reload');
  };

  const handleError = (error: Error) => {
    if (!canReload) return;
    // immediate app reload without showing bug page for 1st occurrence
    if (reloadCount === 0) {
      handleAppReload();
      return;
    }
    // go back to home page automatically after 5 seconds delay, for further occurrences
    sleep(5000).then(() => {
      handleAppReload();
    });
  };

  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <Wrapper>
          <Emoji emoji="😦" size={48} />
          {reloadCount === 0 ? (
            <div>Reloading and making things better..</div>
          ) : (
            <div>
              You've encountered a small hiccup in our system, but no worries!
              Our team is already on top of it and working to resolve the issue.
            </div>
          )}
          {canReload && (
            <>
              <br />
              <div>You'll be automatically returned to the home page.</div>
              <div>OR</div>
            </>
          )}
          <div>
            Click <Link onClick={handleGoBack}>here</Link> to go back.
          </div>
        </Wrapper>
      )}
      onError={handleError}
      onMount={() => {
        if (!isDevelopment) {
          setErrorReloadCount();
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
