import React, { forwardRef, useContext } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { green, red } from 'lib/colors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { selectCrewChangePanel } from 'redux/selectors';

import Tooltip from 'components/shared/Tooltip';
import { CREW_ROWS, isValidCrew } from '../../helpers';
import { CrewInput } from '../../types/common';
import { OnOffSigner } from 'components/icons';

const IconWrapper = styled.span`
  height: 1rem;
  width: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  align-items: center;
`;

const CrewCounter = forwardRef((_, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { planningData } = useContext(CCPanelContext);
  const { active: activity, readOnlyPlanningData: reportData } = useSelector(
    selectCrewChangePanel
  );
  const { crew: readOnlyCrew = [] } = reportData?.crewChangePlan || {};
  const isReportView = activity === 'readOnly' && reportData;
  const crewData = isReportView ? readOnlyCrew : planningData.crew;
  const crewList = (isReportView ? readOnlyCrew : CREW_ROWS).filter(
    isValidCrew
  );

  const renderCrewIcon = (crew: CrewInput) => {
    const { id, type } = crew;
    const selected = crewData.some((crew) => crew.id === id);
    const offsigner = type.toLowerCase() === 'offsigner';
    return (
      <IconWrapper key={id}>
        <OnOffSigner
          color={offsigner ? red : green}
          selected={selected}
          isOffsigner={offsigner}
        />
      </IconWrapper>
    );
  };

  return (
    <Wrapper ref={ref}>
      {crewList.map((crew) =>
        crew.name ? (
          <Tooltip key={crew.id} content={crew.name}>
            {renderCrewIcon(crew)}
          </Tooltip>
        ) : (
          renderCrewIcon(crew)
        )
      )}
    </Wrapper>
  );
});

export default CrewCounter;
