import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Popper,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import type {
  AmadeusAirport,
  Airport as GreywingAirport,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';
import styled from 'styled-components/macro';

import { useAppDispatch, useDebounce } from 'hooks';
import { searchAirports } from 'api/flotilla';
import { setRecentSearches, updateFlightSearch } from 'redux/actions';
import {
  selectCrewChangePanel,
  selectRecentSearches,
  selectSettings,
} from 'redux/selectors';
import { AirportOption } from 'redux/types';
import { AutocompleteGroup, Dropdown } from 'components/shared';
import {
  formatCrewHomeAirport,
  formatPortNearbyAirport,
  getAirportOptions,
} from '../helpers';
import { FormatPortAirportInput } from '../types';

const Wrapper = styled.div`
  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input,
  .MuiAutocomplete-loading {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const StyledPopper = muiStyled(Popper)(() => ({
  '& .MuiAutocomplete-loading': {
    fontSize: '0.9rem',
    fontFamily: 'HK Grotesk, Roboto',
  },
}));

const dropdownStyles = {
  minWidth: '120px',
};

const autoCompleteStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type SelectedAirport = AirportOption | null;

type CrewProps = {
  type: 'ccPlanning' | 'ccReadOnly' | 'ccAction' | 'searchFlights';
  label?: string;
  placeholder?: string;
  reset?: boolean;
  selected?: SelectedAirport;
  disabled?: boolean;
  autoFocus?: boolean;
  crewId?: number;
  styles?: { [key: string]: number | string };
  onSelect: (airport: AirportCommon | null) => void;
};

type PortProps = {
  selected: FormatPortAirportInput;
  nearbyAirports: (AmadeusAirport | GreywingAirport)[];
  onSelect: (airport: AmadeusAirport | GreywingAirport) => void;
};

const AirportDropdown = {
  Crew: ({
    type,
    label,
    placeholder, // custom placeholder
    reset, // boolean flag to externally reset values
    autoFocus,
    disabled,
    crewId, // defaults to zero for add-crew dropdown
    selected: selectedAirport, // currently selected airport name
    styles, // custom styling for dropdown
    onSelect,
  }: CrewProps) => {
    const dispatch = useAppDispatch();
    const recentSearches = useSelector(selectRecentSearches);
    const { active } = useSelector(selectCrewChangePanel);
    const {
      crewChange: { compact: isCompact },
    } = useSelector(selectSettings);
    const isPlanningStep = active === 'plan';
    const inputLabel = crewId ? (!isCompact && label) || '' : label;
    const { airport: recentAirports = [] } = recentSearches || {};

    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState<AirportOption | null | undefined>(
      selectedAirport
    );
    const [airportList, setAirportList] = useState<AirportCommon[]>([]);

    const handleFetchAirports = useDebounce(async (text: string) => {
      setLoading(true);

      const { iataCode, name, municipality } = selected || {};
      const fetchedAirports = await searchAirports(text);
      setAirportList(fetchedAirports || []);
      // set selected airport from fetched list, if only `iataCode` is available
      // mainly for saved org crew from external sources
      if (iataCode && (!name || !municipality)) {
        const airport = (fetchedAirports || []).find(
          (a) => a.iataCode === iataCode
        );
        if (airport) setSelected(airport);
      }

      setLoading(false);
    });

    // method to update redux state that keeps search bar open for airport autocomplete menu
    const handleSearchbarFocus = (value: boolean = true) => {
      if (type === 'searchFlights') {
        // state update when popup is open to prevent closing search results
        dispatch(updateFlightSearch({ listOpen: value }));
      }
    };

    const handleClear = () => {
      setQuery('');
      setSelected(null);
    };

    // call aiports api with query update in dropdown input field
    useEffect(() => {
      if (query) {
        handleFetchAirports(query);
      }
      handleSearchbarFocus();
    }, [query]); // eslint-disable-line

    // clear out values when reset externally, e.g - with a reset button
    useEffect(() => {
      if (reset) {
        handleClear();
      }
    }, [reset]);

    const options = useMemo(
      () =>
        getAirportOptions(
          recentAirports as AirportOption[],
          airportList,
          Boolean(query)
        ),
      [recentAirports, airportList, query]
    );

    // render formatted text for read-only view
    if (!isPlanningStep && type === 'ccReadOnly') {
      return <span>{formatCrewHomeAirport(selected) || '-'}</span>;
    }

    const handleInputChange = (event: SyntheticEvent, value: string) => {
      setQuery(value);
      if (!value) {
        setAirportList([]);
        // update home airport for cleared input
        onSelect(null);
      }
    };

    const handleSelectAirport = (
      event: SyntheticEvent,
      value: SelectedAirport | string
    ) => {
      setSelected(value as SelectedAirport);
      const homeAirport = options.find(
        (airport) => airport.iataCode === (value as SelectedAirport)?.iataCode
      );

      if (homeAirport) {
        onSelect(homeAirport);
        dispatch(setRecentSearches({ airport: value as AirportOption }));
      }
    };

    const customGroupProps =
      recentAirports.length && !query
        ? { groupBy: (option: AirportOption) => option.label || '' }
        : {};

    return (
      <Wrapper
        onClick={(e) => e.stopPropagation()} // prevent selecting row when editing airport
      >
        <Autocomplete
          freeSolo
          size="small"
          loading={loading}
          disabled={disabled}
          value={selected || null}
          inputValue={query}
          options={options}
          getOptionLabel={(option) =>
            formatCrewHomeAirport(option as AirportCommon)
          }
          onChange={handleSelectAirport}
          onInputChange={handleInputChange}
          onFocus={() => handleSearchbarFocus(true)}
          onBlur={() => handleSearchbarFocus(false)}
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={inputLabel}
              autoFocus={autoFocus}
              variant={inputLabel ? 'outlined' : 'standard'}
              placeholder={placeholder || 'Type airport name'}
              // disable MUI Data Grid default navigation for certain keys inside text input.
              // https://mui.com/x/react-data-grid/accessibility/#keyboard-navigation
              onKeyDown={(event) => {
                // prevent keyboard navigation when inside crew-change panel table
                if (type === 'ccPlanning') event.stopPropagation();
              }}
            />
          )}
          renderGroup={AutocompleteGroup}
          ListboxProps={{
            id: 'airport-dropdown',
            style: autoCompleteStyles,
          }}
          PopperComponent={StyledPopper}
          sx={{ width: 150, ...styles }}
          {...customGroupProps}
        />
      </Wrapper>
    );
  },

  Port: ({ selected, nearbyAirports, onSelect }: PortProps) => {
    const airports = nearbyAirports.map(({ iataCode, name, address }) => {
      const nameInput = { iataCode, name, cityName: address.cityName || '' };
      return formatPortNearbyAirport(nameInput);
    });
    const [value, setValue] = useState(
      selected ? formatPortNearbyAirport(selected) : airports[0]
    );

    const handleSelect = (event: SelectChangeEvent) => {
      const { value: airportName } = event.target;
      // split airport name which is in the format of `${iataCode}-${fulAirportlName}, ${city}`
      const airportCode = airportName.split('-')[0];
      const selectedAirport = nearbyAirports.find(
        ({ iataCode }) => iataCode === airportCode
      );

      setValue(airportName);
      if (selectedAirport) {
        onSelect(selectedAirport);
      }
    };

    return (
      <Dropdown
        value={value}
        options={airports}
        formStyles={dropdownStyles}
        fontSize="0.8rem"
        onChange={handleSelect}
      />
    );
  },
};

export default AirportDropdown;
