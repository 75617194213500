import {
  memo,
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import styled from 'styled-components/macro';

import { useCrewChangeCosts, useModal, usePrevious } from 'hooks';
import sleep from 'lib/sleep';
import {
  blue,
  purple,
  red50 as red,
  yellow50 as yellow,
  green,
  textGray,
  white,
} from 'lib/colors';
import { formatDate } from 'utils/format-date';
import { selectCrewChangePanel, selectSettings } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';

import { CommonConfirmModal, Tooltip } from 'components/shared';
import { Card, EmailIcon, Header } from './common';
import DuplicatePortModal from './DuplicatePortModal';
import SummaryModal from '../../../SendModal';
import {
  getFilterRangesForPort,
  getLocodeKeyFromPortData,
  getUnneededFlightsCount,
  getVendorsList,
} from '../../../helpers';
import { BudgetStatus, PortCardProps } from '../../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.6rem;
  color: ${textGray};
`;

const CostWrapper = styled(Description)`
  margin-top: 0.25rem;
  font-weight: bold;
  letter-spacing: 0.075rem;
  color: ${blue};
`;

const ActionButton = styled.div<{ $duplicate: boolean }>`
  display: none;
  width: 100% !important;
  text-align: center;
  margin-top: 0.3rem;
  padding: 0.1rem 1rem;
  font-size: 0.6rem;
  line-height: 1rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.2rem;
  color: ${white};
  background: ${({ $duplicate }) => ($duplicate ? `${red}90` : `${purple}90`)};
  transition: all 150ms ease;
  &:hover {
    background: ${({ $duplicate }) => ($duplicate ? red : purple)};
    color: ${white};
  }
`;

const Wrapper = styled(Card)<{
  $active?: boolean;
  $disabled?: boolean;
  $cannotSplit: boolean;
  $budgetStatus?: BudgetStatus;
}>`
  min-width: 100px;
  background: ${({ $budgetStatus }) =>
    ($budgetStatus === BudgetStatus.ALL_OVER && `${red}20`) ||
    ($budgetStatus === BudgetStatus.SOME_OVER && `${yellow}20`) ||
    ($budgetStatus === BudgetStatus.ALL_UNDER && `${green}20`)};

  &:hover {
    ${({ $cannotSplit }) =>
      !$cannotSplit &&
      `
      ${CostWrapper} {
        display: none;
      }
    `};
    ${ActionButton} {
      display: inline;
    }
    ${({ $disabled }) =>
      !$disabled &&
      `
      cursor: pointer;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    `};
    ${({ $active, $disabled }) =>
      !$active &&
      !$disabled &&
      `
      transform: translate(-1px, -1px);
    `};
  }
  ${({ $active }) => $active && `border: 2px solid ${blue}`};
`;

const ContentWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ProgressTextWrapper = styled(ContentWrapper)`
  font-size: 0.6rem;
  font-weight: 500;
  position: absolute;
  top: 0;
  margin-top: 4px;
  color: ${white};
  letter-spacing: 1px;
`;

const StyledCircularProgress = styled(CircularProgress)`
  width: 0.75rem;
  margin-left: 0.5rem;
`;

const progressStyles = {
  width: '100%',
  minWidth: '120px',
  borderRadius: '4px',
  height: '1.25rem',
};

type Props = PortCardProps & {
  disabled?: boolean;
};

type StatusText = 'scanning' | 'fetching' | 'calculating' | 'finished';

function PortCard({ disabled, port, onChange }: Props) {
  const { event } = useSelector(selectCrewChangePanel);
  const { userInfo } = useSelector(selectSettings);
  const {
    filters: allFilters,
    planningData: { crew },
    tableState: { budgetDetails },
    fetchStatus,
    updateFilters,
    updateFetchStatus,
  } = useContext(CCPanelContext);
  const { modal, setModal } = useModal(null);
  const { totalCost } = useCrewChangeCosts(port);

  const locodeKey = useMemo(() => getLocodeKeyFromPortData(port), [port]);

  const { initialized, progress } = fetchStatus;
  const portProgress = progress?.[locodeKey] || 0;
  const loading = portProgress < 1;
  const filters = allFilters[locodeKey];
  const { active, confirmed, duplicate, emailSent } = filters;
  const { name, flightSource, flightsCount, uniqETA } = port;
  const prevLoading = usePrevious(loading);
  const allLoaded = prevLoading && !loading;
  // state indiciating readiness of the card, for smoother expeience
  const [statusText, setStatusText] = useState<StatusText>(
    !initialized && loading ? 'scanning' : 'finished'
  );
  const ready = statusText === 'finished';

  const crewChangeCostDetailsText = useMemo(() => {
    // no. of crew included in the plan
    const includedCrewCount =
      confirmed.length + getUnneededFlightsCount(crew, port);
    return includedCrewCount && totalCost
      ? `${includedCrewCount}/${crew.length} crew - ${totalCost}`
      : null;
  }, [confirmed.length, crew, port, totalCost]);
  const { allDuplicated, availableSources } = useMemo(() => {
    const { access, allowedTravelVendors = [] } = userInfo || {};
    const allowedVendors = access?.['Vendors Comparison']
      ? allowedTravelVendors
      : [];
    return getVendorsList(locodeKey, port, allFilters, allowedVendors);
  }, [locodeKey, port, allFilters, userInfo]);

  useEffect(() => {
    switch (true) {
      case portProgress > 0 && portProgress < 1: {
        setStatusText('fetching');
        break;
      }
      case portProgress === 1: {
        if (['fetching', 'scanning'].includes(statusText)) {
          setStatusText('calculating');
          sleep(100).then(() => {
            setStatusText('finished');
          });
        }
        break;
      }
    }
  }, [initialized, portProgress, statusText]);

  useEffect(() => {
    // dispatch action to context level reducer
    // to confirm flights & set dynamic filters when flights finished loading
    if (allLoaded) {
      const allFilterRanges = getFilterRangesForPort(filters);
      // confirm flights in next thread after calculation
      sleep(200).then(() => {
        updateFilters({
          type: 'UPDATE_DYNAMIC_FILTERS',
          payload: {
            crew,
            locodeKey,
            allFilterRanges,
            confirm: true,
          },
        });
      });
    }
  }, [locodeKey, crew, allLoaded, filters, updateFilters]);

  const handleVendorAction = (event: SyntheticEvent) => {
    event.stopPropagation();
    setModal(duplicate ? 'confirmDelete' : 'duplicatePort');
  };

  const handleDuplicatePort = (source: string) => {
    const duplicateDetails = { source, crew, port };
    updateFilters({
      type: 'DUPLICATE_PORT',
      payload: { locode: port.locode, duplicateDetails, updateFetchStatus },
    });
    // add a delay between duplicating & changing port to confirm flights
    sleep().then(() => {
      updateFilters({
        type: 'CHANGE_PORT',
        payload: { port, source },
      });
    });
  };

  const renderAction = () => {
    if (!ready || (!duplicate && allDuplicated)) return null;
    if (duplicate) {
      return (
        <ActionButton
          data-id="e2e_remove-copy-button"
          $duplicate
          onClick={handleVendorAction}
        >
          Remove Copy
        </ActionButton>
      );
    }
    return Boolean(flightsCount) ? (
      <ActionButton
        data-id="e2e_split-flights-button"
        $duplicate={false}
        onClick={handleVendorAction}
      >
        Split Flights
      </ActionButton>
    ) : null;
  };

  const renderFlightCosts = () =>
    !ready ? (
      <ProgressWrapper>
        <LinearProgress
          variant="determinate"
          value={portProgress * 100}
          sx={progressStyles}
        />
        <ProgressTextWrapper>
          <span style={{ textTransform: 'uppercase' }}>{statusText}...</span>
          {portProgress === 0 && (
            <StyledCircularProgress size={12} sx={{ color: white }} />
          )}
          {statusText === 'fetching' && (
            <>
              <div style={{ width: '0.75rem' }} />
              <span style={{ fontWeight: 'bold' }}>
                {Math.round(portProgress * 100)}%
              </span>
            </>
          )}
        </ProgressTextWrapper>
      </ProgressWrapper>
    ) : (
      <CostWrapper>{crewChangeCostDetailsText}</CostWrapper>
    );

  const renderContent = () => {
    const basePortName = uniqETA
      ? `${name} (${formatDate(uniqETA, 'DD MMM, YY')})`
      : name;
    const portCardTitle = flightSource
      ? `${basePortName} - ${flightSource}`
      : basePortName;
    // Don't show tooltip when flights are being fetched for first time
    const tooltipContent =
      !disabled && !active && 'Click to see flights, and book agency.';
    const titleTestId = `e2e_port-card-title${
      flightSource ? `-${flightSource}` : ''
    }`;
    const descriptionTestId = `e2e_port-card-description${
      flightSource ? `-${flightSource}` : ''
    }`;

    const content = (
      <>
        <ContentWrapper>
          <FlexWrapper>
            <Header data-id={titleTestId}>{portCardTitle}</Header>
          </FlexWrapper>
          <FlexWrapper style={{ marginLeft: '0.5rem' }}>
            {emailSent && (
              <Tooltip content="Email sent to port agent">
                <EmailIcon />
              </Tooltip>
            )}
          </FlexWrapper>
        </ContentWrapper>
        {ready && (
          <Description data-id={descriptionTestId}>
            {flightsCount
              ? `${flightsCount} Flight${flightsCount > 0 ? 's' : ''}`
              : 'No Flights'}
          </Description>
        )}
      </>
    );

    return tooltipContent ? (
      <Tooltip enterDelay={500} enterNextDelay={500} content={tooltipContent}>
        {content}
      </Tooltip>
    ) : (
      content
    );
  };

  const renderModal = () => {
    if (!modal?.type) {
      return null;
    }

    const closeModal = () => setModal(null);

    if (modal.type === 'send' && event) {
      return (
        <SummaryModal
          port={port}
          event={event as CrewEvent}
          closeModal={closeModal}
        />
      );
    }

    if (modal.type === 'duplicatePort') {
      return (
        <DuplicatePortModal
          sources={availableSources}
          duplicatePort={handleDuplicatePort}
          closeModal={closeModal}
        />
      );
    }

    if (modal.type === 'confirmDelete') {
      return (
        <CommonConfirmModal
          type="remove"
          description="This card will be removed with the flights & settings."
          onCancel={closeModal}
          onConfirm={() => {
            updateFilters({ type: 'REMOVE_DUPLICATE', payload: { locodeKey } });
            closeModal();
          }}
        />
      );
    }
  };

  return (
    <>
      <Wrapper
        $active={!disabled && active}
        $disabled={disabled}
        // state original card when it's duplicated all vendors
        $cannotSplit={allDuplicated && !duplicate}
        $budgetStatus={budgetDetails?.status}
        onClick={() => !active && onChange(port)}
        data-testid="e2e_port-card"
      >
        {renderContent()}
        {renderAction()}
        {renderFlightCosts()}
      </Wrapper>

      {renderModal()}
    </>
  );
}

export default memo(PortCard);
