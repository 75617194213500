import moment from 'moment';
import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import {
  setCompletedOnboardSteps,
  toggleCopilotAudio,
  updateStoreSettings,
} from 'redux/actions';
import { selectOnboard, selectSettings } from 'redux/selectors';

import {
  OnboardSteps,
  StepsKey,
} from '../../MapQuickMenu/components/variables';
import { Divider, Section, Text, Title } from '../common';
import { ErrorText } from '../../shared/Typography';

import { green, white } from 'lib/colors';
import { ActionButton } from '../common';
import { saveSteps } from 'utils/onboard';
import { validateAppSettings } from 'utils/validation/settings';
import { DEFAULT_SETTINGS } from 'redux/helpers/settings';
import { saveAppSettings } from 'api/flotilla';
import { RootState } from 'redux/types';
import { ButtonV2 } from 'components/shared';
import { useForm } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { validationErrMessage } from 'utils/validations';
import { object, string } from 'superstruct';
import { TextFieldV2 } from 'components/shared/forms';

const Container = styled.div`
  margin-top: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
`;

const List = styled.div`
  margin-bottom: 1rem;
`;

const Badge = styled.span`
  background-color: ${green};
  border-radius: 4px;
  color: ${white};
  padding: 0.3rem 0.5rem;
  margin-right: 0.5rem;
`;

function DemoControl() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStepResetLoading, setIsStepResetLoading] = useState<boolean>(false);
  const [isSettingsReset, setIsSettingsReset] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { stepsCompleted, stepsContent } = useSelector(selectOnboard);
  const { userInfo, trackSettings } = useSelector(selectSettings);
  const showAudioFile = useSelector(
    (state: RootState) => state.copilot.showAudioFile
  );

  const stepsMap = useMemo(
    () =>
      stepsContent.reduce((steps, step) => {
        steps[step.stepKey] = step;
        return steps;
      }, {} as { [key in StepsKey]: OnboardSteps }),
    [stepsContent]
  );
  const handleReset = async () => {
    setError(null);
    setIsStepResetLoading(true);
    const { success, message } = await saveSteps([]);
    if (success) {
      dispatch(setCompletedOnboardSteps([]));
    } else {
      setError(message);
    }
    setIsStepResetLoading(false);
  };

  const handleSettingsReset = async () => {
    setError(null);
    setIsLoading(true);
    const defaultAppSettings = validateAppSettings({
      ...DEFAULT_SETTINGS,
      ...(userInfo
        ? { userInfo: { ...userInfo, access: { ...userInfo.access } } }
        : {}),
      trackSettings,
    });

    if (defaultAppSettings) {
      const { success, settings: savedSettings } = await saveAppSettings(
        defaultAppSettings
      );

      if (success && savedSettings) {
        setIsSettingsReset(true);
        dispatch(updateStoreSettings(savedSettings));
      }
    } else {
      setError('Oops.. something broke. please let a Dev know!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isSettingsReset) {
      setTimeout(() => setIsSettingsReset(false), 2000);
    }
  }, [isSettingsReset]);

  const handleLocal = ({ port }: { port: string }) => {
    localStorage.setItem('API_URL', `http://localhost:${port}`);
    window?.location?.reload();
  };

  const { handleSubmit, control } = useForm<{ port: string }>({
    defaultValues: {
      port: '8100',
    },
    resolver: superstructResolver(
      object({
        port: validationErrMessage(
          string(),
          'A valid port number must be provided'
        ),
      })
    ),
  });

  return (
    <>
      <Section>
        <Title>Onboarding</Title>
        {!!stepsCompleted.length ? (
          <>
            <Text>Would you like to reset onboard completion steps?</Text>
            <Container>
              {stepsCompleted.map((each) => (
                <List key={each.type}>
                  <Badge>{stepsMap[each.type].title}</Badge>
                  {`Completed on ${moment(each.completed_at).format(
                    'DD-MMM-yy H:mm a'
                  )}`}
                </List>
              ))}
              {error && <ErrorText>{error}</ErrorText>}
              <div style={{ marginTop: '1rem' }}>
                <ActionButton
                  onClick={handleReset}
                  loading={isStepResetLoading}
                >
                  Reset all steps
                </ActionButton>
              </div>
            </Container>
          </>
        ) : (
          <Container>
            <Text>None of the onboard steps have been completed.</Text>
          </Container>
        )}
      </Section>
      <Divider />
      <Section>
        <Title>Setup Wizard and Settings</Title>
        <Text>Would you like to reset your settings?</Text>
        <Text>
          Note: This DOESN'T include removal of flight tracking settings and
          user info
        </Text>
        {error && <ErrorText>{error}</ErrorText>}
        <div style={{ marginTop: '1rem' }}>
          <ButtonV2 onClick={handleSettingsReset} loading={isLoading}>
            Reset settings
          </ButtonV2>
        </div>
        {isSettingsReset && <p>Successfully Reset</p>}
      </Section>
      <Divider />
      <Section>
        {userInfo?.access['SeaGPT'] && (
          <>
            <Title>Proteus</Title>
            <div style={{ marginTop: '1rem' }}>
              <p>
                {showAudioFile
                  ? 'Hide Audio Preview'
                  : 'Show Push-to-talk Audio Preview'}
              </p>
              <ButtonV2 onClick={() => dispatch(toggleCopilotAudio())}>
                Toggle
              </ButtonV2>
            </div>
          </>
        )}
      </Section>
      <Divider />
      <Section>
        <Title>Local Backend Access</Title>
        <Text>
          To access the local backend, please click on the following and this
          page will refresh with a connection to the specified port number
        </Text>
        <form onSubmit={handleSubmit(handleLocal)}>
          <TextFieldV2
            // @ts-ignore
            control={control}
            size="small"
            label="Port Number"
            name="port"
            sx={{ marginTop: '1rem' }}
          />
          <ButtonV2 type="submit" style={{ marginTop: '1rem' }}>
            Use Local Backend
          </ButtonV2>
        </form>
      </Section>
    </>
  );
}

export default DemoControl;
