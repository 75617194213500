import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WaypointWithProperties } from '@greywing-maritime/gw-ngraph';
import { VesselRouteCalculationResult } from 'utils/types/route-calculator';

import {
  MapRulerState,
  MapRulerType,
  MapRulerUnits,
  MapRulerViewType,
  RootState,
} from '../types';

export const initialMapRuler: MapRulerState = {
  active: false,
  view: 'calculator',
  config: {
    type: 'route',
    units: 'nauticalmiles',
    speed: 13,
  },
  subview: 'distance',
  waypoints: [],
  compareWaypoints: [],
  calculation: {
    time: 0,
    posRoute: [],
    totalDistance: 0,
  },
  compareCalculation: {
    time: 0,
    posRoute: [],
    totalDistance: 0,
  },
};

const mapRulerSlice = createSlice({
  name: 'mapRuler',
  initialState: initialMapRuler,
  reducers: {
    setMapRulerViewType: (state, action: PayloadAction<MapRulerViewType>) => ({
      ...state,
      view: action.payload,
    }),
    setMapRulerSubview: (
      state,
      action: PayloadAction<MapRulerState['subview']>
    ) => ({
      ...state,
      subview: action.payload,
    }),
    setMapRulerConfig: (
      state,
      action: PayloadAction<Partial<MapRulerState['config']>>
    ) => ({
      ...state,
      config: {
        ...state.config,
        ...action.payload,
      },
    }),
    setRulerWaypoints: (
      state,
      action: PayloadAction<WaypointWithProperties[]>
    ) => ({
      ...state,
      waypoints: action.payload,
    }),
    setRulerCompareWaypoints: (
      state,
      action: PayloadAction<WaypointWithProperties[]>
    ) => ({
      ...state,
      compareWaypoints: action.payload,
    }),
    addRulerWaypoints: (
      state,
      action: PayloadAction<WaypointWithProperties>
    ) => ({
      ...state,
      waypoints: [...state.waypoints, action.payload],
    }),
    removeRulerWaypoint: (state, action: PayloadAction<number>) => {
      const updatedArray = [...state.waypoints];
      updatedArray.splice(action.payload, 1);
      return {
        ...state,
        waypoints: updatedArray,
      };
    },
    setRulerCalculation: (
      state,
      action: PayloadAction<VesselRouteCalculationResult>
    ) => ({
      ...state,
      calculation: action.payload,
    }),
    setRulerCompareCalculation: (
      state,
      action: PayloadAction<VesselRouteCalculationResult>
    ) => ({
      ...state,
      compareCalculation: action.payload,
    }),
    startMapRuler: (
      state,
      action: PayloadAction<{
        type?: MapRulerType;
        units?: MapRulerUnits;
      }>
    ) => {
      const { type, units } = action.payload;
      return {
        ...initialMapRuler,
        config: {
          ...initialMapRuler.config,
          type: type || state.config.type,
          units: units || state.config.units,
        },
        active: true,
      };
    },
    closeMapRuler: (state) => ({
      ...state,
      active: false,
    }),
  },
});

/* ----- selectors -----*/

export const selectMapRuler = ({ mapRuler }: RootState) => mapRuler;

/* ----- actions -----*/

export const {
  startMapRuler,
  closeMapRuler,
  setMapRulerSubview,
  setRulerCalculation,
  setRulerCompareCalculation,
  setRulerWaypoints,
  setRulerCompareWaypoints,
  addRulerWaypoints,
  removeRulerWaypoint,
  setMapRulerConfig,
  setMapRulerViewType,
} = mapRulerSlice.actions;

/* ----- reducer -----*/

export default mapRulerSlice.reducer;
