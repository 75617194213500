import { memo } from 'react';
import { SliderRail, SliderThumb, SliderProps, Slider } from '@mui/material';
import { styled as MuiStyled } from '@mui/system';
import styled from 'styled-components/macro';

import { textBlack, white, gray60 } from 'lib/colors';

const StyledSlider = styled(Slider)({
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    height: 4,
  },
});

const commonStyles = {
  color: white,
  width: 'fit-content !important',
  fontWeight: 500,
  backgroundColor: `${textBlack} !important`,
  '&.Mui-disabled': {
    backgroundColor: `${gray60} !important`,
  },
};

const StyledThumb = MuiStyled(SliderThumb)(() => ({
  ...commonStyles,
  minWidth: 16,
  borderRadius: 6,
  padding: '0.25rem 0.4rem',
  fontSize: '0.65rem',
  letterSpacing: '1px',
  height: '24px',
}));

const SmallStyledThumb = MuiStyled(SliderThumb)(() => ({
  ...commonStyles,
  minWidth: 10,
  borderRadius: 4,
  padding: '0 0.2rem',
  fontSize: '0.65rem',
  letterSpacing: '0.25px',
  height: '16px',
}));

const StyledRail = MuiStyled(SliderRail)(() => ({
  backgroundColor: gray60,
  height: 4,
}));

const NoWrapText = styled.span`
  white-space: nowrap;
`;

// MUI Slider Thumb doesn't have a proper type that can be used
// I think this gets solved here https://github.com/mui/material-ui/pull/32739
export const Thumb = (props: any) => {
  const { children, modifiedValue, size, ...rest } = props;
  const ThumbComponent = size === 'small' ? SmallStyledThumb : StyledThumb;

  if (modifiedValue) {
    return (
      <ThumbComponent {...rest}>
        {children}
        <NoWrapText>{modifiedValue}</NoWrapText>
      </ThumbComponent>
    );
  }
  return (
    <ThumbComponent {...rest}>
      {children}
      {rest.ownerState.value}
    </ThumbComponent>
  );
};

// Same as above - no suitable types to use yet
export const Rail = (props: any) => <StyledRail {...props} />;

const Wrapper = styled.div<{ $noMargin?: boolean }>`
  width: 100%;
  ${({ $noMargin }) => !$noMargin && 'margin-top: 3px'};
`;

type ExtraProps = { noMargin?: boolean };

function AwesomeSlider({ noMargin, ...props }: SliderProps & ExtraProps) {
  return (
    <Wrapper $noMargin={noMargin}>
      <StyledSlider
        {...props}
        components={{
          Rail,
          ...props.components,
        }}
      />
    </Wrapper>
  );
}

export default memo(AwesomeSlider);
