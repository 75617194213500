import sum from 'lodash/sum';
import keys from 'lodash/keys';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components/macro';

import { gray70 } from 'lib/colors';
import { selectCrewChangePanel } from 'redux/selectors';
import { CCPanelContext } from 'contexts/CCPanelContext';
import { PlanningData } from 'utils/types';
import { ACTION_TIMES } from '../helpers';

import { LottieAnimation } from 'components/shared';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ActionList = styled.div`
  justify-content: flex-start;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const ActionText = styled.span<{ $finished: boolean }>`
  ${({ $finished }) =>
    !$finished &&
    `
    letter-spacing: 0.5px;
    color: ${gray70};
    font-style: italic;
  `};
`;

const TimeDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.95rem;
`;

const Time = styled.b`
  margin-left: 5px;
`;

const animationStyles = {
  margin: 0,
  marginTop: '3px',
  marginBottom: '-3px',
  marginRight: '8px',
};

type ListItemProps = {
  children: React.ReactNode;
  order: number;
};

const formatActionTime = (milliSeconds: number): string => {
  const seconds = milliSeconds / 1000;

  if (seconds < 1) {
    return 'less than a second';
  }

  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds - (h * 3600 + m * 60));

  const hrs = h > 0 ? h + (h === 1 ? ' hour' : ' hours') : '';
  const mins = m > 0 ? m + (m === 1 ? ' minute' : ' minutes') : '';
  const secs = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';

  switch (true) {
    case Boolean(hrs && mins && secs):
      return `${hrs}, ${mins}, ${secs}`;
    case Boolean(mins && secs):
      return `${mins}, ${secs}`;
    default:
      return `${secs}`;
  }
};

// render each list item with a delay
const ListItem = ({ children, order }: ListItemProps) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const delay = order * 1500;
    const timer = setTimeout(() => {
      setLoaded(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [order]);

  return <TimeDetails>{loaded ? children : null}</TimeDetails>;
};

function ActionTimes({ agentsCount }: { agentsCount?: number }) {
  const { isOneClickPlan } = useSelector(selectCrewChangePanel);
  const { planningData, filters } = useContext(CCPanelContext);

  const getConfirmedFlightsCount = useCallback(
    () =>
      // count all confirmed flights from all port-cards
      sum(
        values(mapValues(filters, ({ confirmed }) => confirmed?.length || 0))
      ),
    [filters]
  );

  const getCount = useCallback(
    (action: string) =>
      (action !== 'flights' &&
        planningData[action as keyof PlanningData]?.length) ||
      0,
    [planningData]
  );

  const getTitle = (action: string) => {
    const count = getCount(action);
    switch (action) {
      case 'crew':
        return count ? `${count} crew selected in` : 'Selecting crew...';
      case 'route':
        return count
          ? `${count} port${count > 1 ? 's' : ''} selected for route in`
          : 'Selecting ports for route...';
      case 'ports':
        return count
          ? `${count} port${count > 1 ? 's' : ''} selected for flights in`
          : 'Selecting ports...';
      case 'flights':
        return Boolean(ACTION_TIMES.flights?.show)
          ? `${getConfirmedFlightsCount()} flight(s) confirmed for ${
              keys(filters).length
            } port-card(s) in`
          : 'Selecting flights...';
      case 'emails':
        return `${agentsCount} Email${agentsCount! > 1 ? 's' : ''} delivered`;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <ActionList>
        {Object.keys(ACTION_TIMES).map((action, index) => {
          const showLoader =
            isOneClickPlan &&
            action !== 'emails' &&
            !ACTION_TIMES[action]?.show;
          return (
            <ListItem key={action} order={index}>
              {showLoader ? (
                <CircularProgress size={16} sx={{ marginRight: '12px' }} />
              ) : (
                <LottieAnimation
                  name="82361-tick"
                  width="20px"
                  loop={false}
                  style={animationStyles}
                />
              )}
              <ActionText $finished={!showLoader}>
                {`${getTitle(action)}`}{' '}
              </ActionText>
              {ACTION_TIMES[action]?.show && (
                <Time>{formatActionTime(ACTION_TIMES[action].time)}</Time>
              )}
            </ListItem>
          );
        })}
      </ActionList>
    </Wrapper>
  );
}

export default memo(ActionTimes);
