import { memo } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';

type FormikDatePickerProps = {
  id: string;
  textFieldProps?: TextFieldProps;
  [x: string]: any;
};

function FormikDatePicker(props: FormikDatePickerProps) {
  const { textFieldProps, ...rest } = props;
  const [field, meta, helpers] = useField(props.id);

  const handleChange = (date: any) => {
    helpers.setValue(date);
  };

  return (
    <>
      <DatePicker
        {...rest}
        {...field}
        value={field.value}
        onChange={handleChange}
        renderInput={(params: any) => (
          <TextField
            size="small"
            autoComplete="off"
            {...params}
            {...textFieldProps}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      />
    </>
  );
}

export default memo(FormikDatePicker);
