import keys from 'lodash/keys';
import isNumber from 'lodash/isNumber';
import { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import type { CostObjectType } from '@greywing-maritime/frontend-library/dist/types/proxPorts';

import { textBlack, white } from 'lib/colors';
import { RootState } from 'redux/types';

import { Tooltip } from 'components/shared';
import { CCPanelContext } from 'contexts/CCPanelContext';
import AgencyCostModule from '../modules/agency-cost';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BorderRight = styled.div`
  border-right: 2px solid ${textBlack};
  height: 0.7rem;
  margin-right: 6px;
`;

const CostWrapper = styled.div`
  display: flex;
  align-itesm: center;
  justify-content: space-between;
`;

const Category = styled.b`
  margin-right: 0.5rem;
`;

const Country = styled.span`
  font-size: 0.7rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 1rem;
`;

const Cost = styled(FlexWrapper)`
  font-size: 0.8rem;
  margin-right: 6px;
  text-transform: uppercase;
`;

const Header = styled.div`
  font-style: italic;
  font-weight: bold;
  letter-spacing: 1px;
`;

const Divider = styled.hr`
  color: ${white};
  margin: 0.25rem 0;
`;

type Props = {
  agencyDetails: CostObjectType;
};

const renderBreakdownItem = (
  category: string,
  details: number | { [country: string]: number },
  currency: string
) => {
  if (isNumber(details)) {
    return (
      <CostWrapper>
        <Category>{category || 'Unknown'}:</Category> {Math.round(details)}{' '}
        {currency}
      </CostWrapper>
    );
  }

  return (
    <>
      <Category>{category || 'Unknown'}:</Category>
      {keys(details).map((country, index) => (
        <CostWrapper key={index} style={{ fontStyle: 'italic' }}>
          <Country>{country}</Country>
          <span>
            {details[country]} {currency}
          </span>
        </CostWrapper>
      ))}
    </>
  );
};

// component rendering tooltip for agency cost with breakdown
function AgencyCost({ agencyDetails }: Props) {
  const activity = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.active
  );
  const reportData = useSelector(
    ({ crewChangePanel }: RootState) => crewChangePanel.readOnlyPlanningData
  );
  const {
    planningData: { crew: planningCrew },
  } = useContext(CCPanelContext);

  // set the right crew list based on the activity
  const crewList = useMemo(() => {
    const { crew: readOnlyCrew = [] } = reportData?.crewChangePlan || {};
    const isReportView = activity === 'readOnly' && Boolean(reportData);
    return isReportView ? readOnlyCrew : planningCrew;
  }, [activity, planningCrew, reportData]);

  const costDetails = useMemo(() => {
    const costModule = new AgencyCostModule(crewList, agencyDetails);
    return costModule.getAllAgencyCostDetails();
  }, [crewList, agencyDetails]);

  const costsList = keys(costDetails || {});
  if (!costsList?.length) {
    return <span>---</span>;
  }

  const renderBreakdown = (vendor: string) => {
    const { breakdown, currency = '', historical } = costDetails[vendor] || {};
    return (
      <>
        <Header>
          Total Cost ({historical ? 'Historical' : `${crewList.length} pax`})
        </Header>
        <Divider />
        {breakdown ? (
          keys(breakdown).map((category, index) => (
            <div key={index}>
              {renderBreakdownItem(category, breakdown[category], currency)}
            </div>
          ))
        ) : (
          <Category>
            <i>Breakdown Unavailable</i>
          </Category>
        )}
      </>
    );
  };

  const renderCost = (vendor: string) => {
    const { agencyCost, currency } = costDetails[vendor] || {};
    if (!costDetails[vendor] || !agencyCost) {
      return (
        <>
          <b>{vendor}</b>: ---
        </>
      );
    }

    return (
      <>
        <b>{vendor}</b>: {agencyCost} {currency}
      </>
    );
  };

  const renderAgencyCost = (name: string, index: number) => (
    <FlexWrapper key={name}>
      <Cost>{renderCost(name)}</Cost>
      {costsList.length - 1 !== index && <BorderRight />}
    </FlexWrapper>
  );

  return (
    <FlexWrapper>
      {costsList.map((name: string, index: number) =>
        !costDetails[name] ? (
          renderAgencyCost(name, index)
        ) : (
          <Tooltip key={name} content={renderBreakdown(name)}>
            {renderAgencyCost(name, index)}
          </Tooltip>
        )
      )}
    </FlexWrapper>
  );
}

export default memo(AgencyCost);
