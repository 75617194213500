export const ETA_SEARCH_KEYS = [
  'secondsToNextPort',
  'upcomingCrewChangeEvents',
];
export const CREW_CHANGE_CANCEL_MESSAGE =
  "You'll lose the crew change planning progress. Are you sure?";
export const CC_PANEL_HEIGHT = 'ccPanelHeight';
export const ADDED_CREWS = 'addedCrews';
export const SIDE_PANEL_DEFAULT_EXCLUSION = [
  'compound_crewNationalities',
  'compound_crewHomeAirports',
];
export const NM_TO_KM = 1.852;

// CC panel heights
export const CC_PANEL_INITIAL_HEIGHT = '45vh';
export const CC_PANEL_COLLAPSED_HEIGHT = '68px';
export const CC_PANEL_INITIAL_MOBILE_HEIGHT = '70vh';
export const CC_PANEL_RESIZE_BAR_HEIGHT = 16;

// Setup Wizard
export const SETUP_WIZARD_STORAGE = 'setupWizard';

// TODO: This should be phased out at some stage
export const SETUP_WIZARD_STEP = 'SETUP_WIZARD_STEP';
export const SETUP_WIZARD_COMPLETED = 'SETUP_WIZARD_COMPLETED';

export const ERROR_RELOAD_COUNT = 'errorReloadCount';
export const REPORT_PANEL_DATA = 'readOnlyPlanningData';

// list of redirect types - determined from redirect pathname
export const RESTRICTIONS_REDIRECT = 'restrictions';
export const CREW_CHANGE_REDIRECT = 'crew-change-plan';
export const SIDEPANEL_UPDATES_REDIRECT = 'sidepanel-updates';
export const MAGIC_LINK_REDIRECT = 'magic-link';
export const FLIGHTS_TRACK_REDIRECT = 'stop-tracking';

export const PROXPORTS_PAGE_SIZE = 15;
export const RECENT_SEARCHES_KEY = 'recentSearches';

export const NOT_REQUIRED_TEXT = 'No flight required';
export const REQUEST_BOOKING_TEXT = 'Request for booking';
export const MISSING_FLIGHT_TEXT = `Unavailable - ${REQUEST_BOOKING_TEXT}`;

// throlle flights filters update in ms
export const FILTERS_UPDATE_DELAY = 250;

export const VERSION_CHECK_INTERVAL = 1000 * 60 * 10;

export const MOBILE_MENU_HEIGHT = '64px';
