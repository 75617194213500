export const vesselHighlights = {
  baseStyle: {
    strokeColor: '#424343',
    strokeWeight: 1,
    fillOpacity: 0.9,
  },
  highlightFields: ['fillColor'],
  empty: '#385DEA',
  sequential: [
    '#881e19',
    '#b9330B',
    '#db6f02',
    '#f5b402',
    '#aad11d',
    '#69a129',
    '#387f35',
    '#106033',
  ],
  qualitative: [
    '#083D77',
    '#2629e3',
    '#b71518',
    '#EF2D56',
    '#DA4167',
    '#225560',
    '#1B998B',
    '#003e1f',
    '#103900',
    '#28464B',
    '#4b3f72',
    '#525B76',
    '#2E4057',
    '#30362F',
    '#201E50',
    '#322214',
    '#001B2E',
    '#B47EB3',
    '#7161EF',
    '#7D5C65',
    '#D7263D',
    '#AF1B3F',
    '#DD1C1A',
    '#931621',
    '#AB3428',
    '#2D728F',
    '#5A716A',
    '#294C60',
    '#625834',
    '#473144',
    '#986C6A',
    '#6A2E35',
    '#472836',
    '#590925',
  ],
  qualitative_contract: ['#001B2E', '#69a129', '#f5b402', '#db6f02', '#f24726'],
};

export function interpolate(
  startColorHex: string,
  endColorHex: string,
  fraction: number
): string | null {
  fraction = Math.max(fraction, 0);
  fraction = Math.min(fraction, 1);

  const start = hexToRgb(startColorHex);
  const end = hexToRgb(endColorHex);

  if (!start || !end) {
    console.error('unable to interpolate colors, ', arguments);
    return null;
  }

  const newColor = {
    r: Math.round((end.r - start.r) * fraction + start.r),
    g: Math.round((end.g - start.g) * fraction + start.g),
    b: Math.round((end.b - start.b) * fraction + start.b),
  };

  return rgbToHex(newColor.r, newColor.g, newColor.b);
}

export interface RGB {
  r: number;
  g: number;
  b: number;
}

export function hexToRgb(hex: string): RGB | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function rgbToHex(r: number, g: number, b: number): string {
  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  }
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function luminance(r: number, g: number, b: number): number {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// contrast checker using 1/3 as the minimum value
// https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
function contrastRatio(
  foregroundHex: string,
  backgroundHex: string
): number | null {
  const foregroundRGB = hexToRgb(foregroundHex);
  const backgroundRGB = hexToRgb(backgroundHex);
  if (!foregroundRGB || !backgroundRGB) return null;
  const foregroundLuminance = luminance(
    foregroundRGB.r,
    foregroundRGB.g,
    foregroundRGB.r
  );
  const backgroundLuminance = luminance(
    backgroundRGB.r,
    backgroundRGB.g,
    backgroundRGB.r
  );
  return foregroundLuminance > backgroundLuminance
    ? (backgroundLuminance + 0.05) / (foregroundLuminance + 0.05)
    : (foregroundLuminance + 0.05) / (backgroundLuminance + 0.05);
}

// This is not a function that is actively used within the application
// It is only used during development when trying to decide if a specific
// color has sufficient contrast to be used on the UI
export function colorIsTooBright(
  foregroundHex: string,
  backgroundHex: string,
  maxRatio: number = 1 / 3
): boolean {
  const ratio = contrastRatio(foregroundHex, backgroundHex);
  if (ratio === null) return false;
  return ratio >= maxRatio;
}
