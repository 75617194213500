import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';

import { useMobile, useModal } from 'hooks';
import { BREAK_POINT_CUSTOM_XS } from 'lib/breakpoints';
import {
  blue,
  borderGray,
  gray60,
  gray20,
  textGray,
  green,
  black,
  white,
  lightBlue,
  fadedGreen,
} from 'lib/colors';
import {
  findFlightTrackAccess,
  findFlightBookAccess,
  secondsToDHM,
} from 'lib/common';
import { isProduction } from 'lib/environments';
import { selectSettings } from 'redux/selectors';
import { Flight } from 'utils/types/crew-change-types';
import { FlightStepDetails } from 'components/FlotillaSearch/types';
import { Tooltip } from 'components/shared';
import { formatDate } from 'utils/format-date';

import { CommonConfirmModal } from 'components/shared';
import FlightInfoV2 from './FlightInfoV2';
import QuickFlyBook from '../../FlightBook';
import { SelectButton, QuickFlyFlightTrack } from '../../FlightTrack';

import { getFlightDelay } from 'components/CrewChangePanel/helpers';
import { getFlightSteps } from 'components/FlotillaSearch/helpers';
import { LayoverDetails } from 'components/FlotillaSearch/types';
import { ModalView } from '../types';
import FlightIcon from '@mui/icons-material/Flight';
import DescriptionIcon from '@mui/icons-material/Description';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoIcon from '@mui/icons-material/Info';

const Wrapper = styled.div<{
  $last: boolean;
  $unmatched?: boolean;
  $isConfirmed?: boolean;
  $highlight?: boolean;
}>`
  display: flex;
  padding: 0.2rem;
  column-gap: 0.2rem;
  border-bottom: 1px solid ${borderGray};
  transition: all 200ms ease-in-out;
  background-color: ${gray20};
  ${({ $last }) => $last && 'border-bottom: none'};
  ${({ $unmatched }) => $unmatched && 'opacity: 0.4'};
  ${({ $isConfirmed }) =>
    $isConfirmed &&
    `
    background: ${fadedGreen}40;
  `};
  ${({ $highlight }) =>
    $highlight &&
    `
    cursor: default;
  `};
`;

const LoaderWrapper = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-style: italic;
  color: ${gray60};
  width: 100%;
`;

const FlightsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 180px 1fr 180px;
  gap: 10px;
  padding: 0.2rem;
  background-color: ${white};
  border-radius: 4px;
`;

const FlightTrackContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  margin: auto;
  max-width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 6.5px;
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  column-gap: 0.5rem;
  min-width: fit-content;
`;

const Track = styled.div`
  margin: 15px 0;
  position: relative;

  height: 2px; /* Adjust this value to change the height of the dashed border */
  width: 60px;
  background-image: linear-gradient(90deg, ${lightBlue} 50%, transparent 50%);
  background-size: 15px; /* Adjust this value to change the number of the dashes */
  background-position: 0 100%;
  animation: border-anim 10s infinite reverse; /* Adjust this value to change the speed of the dashes */
  @keyframes border-anim {
    0% {
      background-position: 0 100%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
`;

const FlightNumber = styled(Text)`
  font-style: italic;
`;

const Time = styled(Text)`
  color: ${textGray};
  font-style: italic;
`;

const AirportCode = styled.div`
  margin: 6px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
`;

const FlightInfoColumn = styled.div`
  display: flex;
  column-gap: 0.2rem;
`;

const InfoWrapper = styled.div`
  min-width: 8rem;
  display: grid;
  grid-template-rows: 1fr 1fr;
  border-radius: 4px;
  padding: 10px;
  align-items: center;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonWrapper = styled(ColumnWrapper)`
  margin-right: -0.2rem;
  button,
  span {
    margin: 0;
    margin-right: 0.2rem;
    height: 100%;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
  }
`;

const Title = styled(Text)`
  font-size: 0.65rem;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TotalFlightTime = styled(Text)`
  font-size: 1rem;
  font-weight: bold;
`;

const Price = styled(Text)`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const LayoverTooltipWrapper = styled.div`
  padding: 0.25rem;
  padding-right: 0;
`;

const LayoverTooltipText = styled.div`
  display: grid;
  grid-template-columns: 1.1fr 1fr;
  grid-gap: 0.5rem;
  margin-bottom: 1px;
  font-weight: 500;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: 15px !important;
  color: ${white};
  margin-left: 0.33rem;
  cursor: pointer;
`;

const StyledDescriptionIcon = styled(DescriptionIcon)`
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1rem !important;
`;

const StyledFlightIcon = styled(FlightIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  color: ${blue};
`;

type ResultCardProps = {
  modalView?: ModalView;
  flight: Flight;
  last: boolean;
  isSelected?: boolean; // indicates if a flight is selected, only for cc panel modal view
  unmatched?: boolean;
  // available for cc panel modal view, only for matched flights
  selectFlight?: ((flight: Flight) => void) | undefined;
  // open raw data view
  openRawData?: (id: string) => void;
};

type TooltipProps = {
  iataCode: string;
  layoverDetails: LayoverDetails;
};

const TextWithTooltip = ({ iataCode, layoverDetails }: TooltipProps) => {
  const { arrival, departure, layoverTime } = layoverDetails;
  const tooltipContent = (
    <LayoverTooltipWrapper>
      <LayoverTooltipText>
        <span>Layover Duration: </span>
        <b>{layoverTime}</b>
      </LayoverTooltipText>
      <LayoverTooltipText>
        <span>Arrival at {arrival.iataCode}: </span>
        <b>{formatDate(arrival.time, 'HH:mm, DD MMM')}</b>
      </LayoverTooltipText>
      <LayoverTooltipText>
        <span>Departure from {departure.iataCode}: </span>
        <b>{formatDate(departure.time, 'HH:mm, DD MMM')}</b>
      </LayoverTooltipText>
    </LayoverTooltipWrapper>
  );
  return (
    <Tooltip content={tooltipContent}>
      <AirportCode>{iataCode}</AirportCode>
    </Tooltip>
  );
};

function FlightResultCardV2({
  flight,
  last,
  modalView,
  isSelected,
  unmatched,
  selectFlight,
  openRawData,
}: ResultCardProps) {
  const {
    price,
    totalFlightTime,
    stops,
    type,
    source,
    originalId,
    cabinClass,
    numSeatsAvailable,
  } = flight;
  const isQuickFlyModal = modalView === 'quickfly';
  const { userInfo } = useSelector(selectSettings);

  const isMobile = useMobile(BREAK_POINT_CUSTOM_XS);
  const { modal, setModal } = useModal();

  const isModal = Boolean(modalView);
  const canTrackFlight = findFlightTrackAccess(userInfo);
  const canBookFlight = findFlightBookAccess(userInfo);
  const flightTime = useMemo(
    () => secondsToDHM(totalFlightTime * 3600),
    [totalFlightTime]
  );
  const isMarineFlight = useMemo(
    () => type.type.toLowerCase() === 'marine',
    [type]
  );

  const [segments, setSegments] = useState<FlightStepDetails[]>([]);

  useEffect(() => {
    setSegments(getFlightSteps(flight, isMobile, isModal));
  }, [flight, isMobile, isModal]);

  const renderBookButton = useCallback(() => {
    if (
      unmatched ||
      modalView === 'ccpanel' ||
      !canBookFlight ||
      isProduction
    ) {
      return null;
    }
    return <QuickFlyBook flight={flight} />;
  }, [flight, unmatched, modalView, canBookFlight]);

  const renderSelectButton = () => {
    if (!selectFlight) {
      return null;
    }

    return (
      <Tooltip content="Click to select this flight">
        <SelectButton
          style={{
            width: '65px',
            backgroundColor: isSelected ? fadedGreen : blue,
          }}
          className="e2e_select-flight"
          variant="primary"
          disabled={isSelected}
          onClick={() => {
            const timeDifference = getFlightDelay(flight);
            if (timeDifference < 0)
              setModal('selectFlight', { timeDifference });
            else selectFlight(flight);
          }}
        >
          {isSelected ? <VerifiedIcon sx={{ color: white }} /> : 'Select'}
        </SelectButton>
      </Tooltip>
    );
  };

  const renderTrackButton = () => {
    // hide track button for unmatched flights & users without access
    // also hide in cc panel view
    if (modalView === 'ccpanel' || !canTrackFlight) {
      return null;
    }
    return <QuickFlyFlightTrack flight={flight} modalView={modalView} />;
  };

  const renderModal = () => {
    if (!modal?.type) return null;

    const closeModal = () => setModal(null);

    if (modal.type === 'selectFlight') {
      const { timeDifference } = modal.data;
      const { crew, port } = flight;
      const isOffsigner = crew.type === CrewType.offsigner;
      const formattedDelay = secondsToDHM(Math.abs(timeDifference) * 24 * 3600);
      const description = `Selected ${crew.type} flight's ${
        isOffsigner ? 'departure' : 'arrival'
      } is ${formattedDelay} ${
        isOffsigner ? 'before' : 'after'
      } this vessel's ${isOffsigner ? 'ETA' : 'ETD'} for ${
        port.name
      }. Do you still want to select this?`;

      return (
        <CommonConfirmModal
          description={description}
          onConfirm={() => {
            selectFlight?.(flight);
            closeModal();
          }}
          onCancel={closeModal}
        />
      );
    }
  };

  const renderFlightSegments = () => {
    // no layovers - only departure & arrival
    if (segments.length <= 2) {
      return (
        <FlightsContainer>
          {/* Departure */}
          <FlightInfoV2
            {...segments[0]}
            cabinClass={cabinClass}
            numSeatsAvailable={numSeatsAvailable}
          />
          <FlightTrackContainer>
            <FlexWrapper>
              <ColumnWrapper>
                <Tooltip content={'Travel Time: ' + segments[1].duration}>
                  <Track style={{ width: '150px' }}>
                    <StyledFlightIcon />
                  </Track>
                </Tooltip>
                <FlightNumber>{segments[1].arrivalFlightNumber}</FlightNumber>
              </ColumnWrapper>
            </FlexWrapper>
          </FlightTrackContainer>
          {/* Arrival */}
          <FlightInfoV2 {...segments[1]} />
        </FlightsContainer>
      );
    }

    const layovers = segments.slice(1, segments.length - 1);
    return (
      <FlightsContainer>
        {/* Departure */}
        <FlightInfoV2
          {...segments[0]}
          cabinClass={cabinClass}
          numSeatsAvailable={numSeatsAvailable}
        />
        {/* Layovers */}
        <FlightTrackContainer>
          {layovers.map((segment, index) => (
            <FlexWrapper key={index}>
              <ColumnWrapper>
                <Tooltip content={'Travel Time: ' + segment.duration}>
                  <Track>
                    <StyledFlightIcon />
                  </Track>
                </Tooltip>
                <FlightNumber>{segment.arrivalFlightNumber}</FlightNumber>
              </ColumnWrapper>
              <ColumnWrapper>
                <TextWithTooltip
                  iataCode={segment.iataCode}
                  layoverDetails={segment.layoverDetails!}
                />
                <Time>{segment.layoverDetails!.layoverTime}</Time>
              </ColumnWrapper>
              {index === layovers.length - 1 ? (
                <ColumnWrapper>
                  <Tooltip
                    content={'Travel Time: ' + segments[index + 2]?.duration}
                  >
                    <Track>
                      <StyledFlightIcon />
                    </Track>
                  </Tooltip>
                  <FlightNumber>{segment.departureFlightNumber}</FlightNumber>
                </ColumnWrapper>
              ) : null}
            </FlexWrapper>
          ))}
        </FlightTrackContainer>
        {/* Arrival */}
        <FlightInfoV2 {...segments[segments.length - 1]} />
      </FlightsContainer>
    );
  };

  const renderRawFlightViewText = () => {
    const canViewRawData = modalView && userInfo?.access?.['Show Raw Flights'];
    if (!canViewRawData) {
      return null;
    }
    const flightId = modalView === 'ccpanel' ? originalId : flight.id;
    return (
      <StyledDescriptionIcon
        onClick={() => flightId && openRawData?.(flightId)}
      />
    );
  };

  return (
    <Wrapper
      data-id="e2e_flight-result"
      $last={last}
      $unmatched={unmatched}
      $isConfirmed={isSelected}
      $highlight={isQuickFlyModal}
    >
      {Boolean(segments.length) ? (
        renderFlightSegments()
      ) : (
        <LoaderWrapper>Loading...</LoaderWrapper>
      )}

      <FlightInfoColumn>
        <InfoWrapper>
          <Title style={{ color: textGray }}>
            {`Time (${
              Boolean(stops.length)
                ? `${stops.length} stop${stops.length > 1 ? 's' : ''}`
                : 'No stop'
            })`}
            {renderRawFlightViewText()}
          </Title>
          <TotalFlightTime data-id="e2e_results-fight-time">
            {flightTime}
          </TotalFlightTime>
        </InfoWrapper>

        <InfoWrapper
          style={{
            color: white,
            backgroundColor: isMarineFlight ? green : black,
          }}
        >
          <Title>
            {isMarineFlight ? 'Marine' : 'General'}
            {source && (
              <Tooltip
                content={
                  <span>
                    Flight source: <b>{source}</b>
                  </span>
                }
              >
                <StyledInfoIcon />
              </Tooltip>
            )}
          </Title>
          <Price data-id="e2e_results-fight-cost">
            {price.amount.toFixed(2)} {price.currency}
          </Price>
        </InfoWrapper>

        <ButtonWrapper>
          {renderBookButton()}
          {renderTrackButton()}
          {renderSelectButton()}
        </ButtonWrapper>
      </FlightInfoColumn>

      {renderModal()}
    </Wrapper>
  );
}

export default memo(FlightResultCardV2);
