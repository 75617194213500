import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';

import styled from 'styled-components/macro';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled(FlexWrapper)`
  margin-right: 10px;
  height: 26px;
  width: 26px;
  padding: 5px;
  border-radius: 6px;
  justify-content: center;
  background-color: #f3f3f4;
  transition: all 150ms ease;
  cursor: pointer;
  &:hover {
    background-color: #e7e7e9;
  }
  svg {
    font-size: 40px !important;
  }
`;

type Props = {
  collapse: boolean;
  onClick: (arg: any) => void;
};

function CollapseButton({ collapse, onClick }: Props) {
  return (
    <IconWrapper onClick={onClick}>
      {!collapse ? <ArrowDropDownRoundedIcon /> : <ArrowDropUpRoundedIcon />}
    </IconWrapper>
  );
}

export default CollapseButton;
