import moment from 'moment';

// shows formatted date in UTC time
export const formatDate = (
  date: string | Date | null | undefined,
  format: string = DATE_FORMAT.DD_MMM_YY
) => moment(date).utc().format(format);

// shows formatted date in local time (converts from utc)
export const formatToLocalTime = (
  date: string | Date | null | undefined,
  format: string = DATE_FORMAT.DD_MMM_YY
) => moment(date).local().format(format);

export const DATE_FORMAT = {
  DD_MMM_YY: 'DD MMM YY',
  DD_MMM_YYYY: 'DD MMM YYYY',
  DD_MMM_YY_HH_mm: 'DD MMM YY, HH:mm',
};
