import { useContext, useMemo, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import styled from 'styled-components/macro';

import { selectMapVessels, selectSettings } from 'redux/selectors';
import { VesselFieldId } from 'utils/types';
import {
  getSidePanelDisplayableFields,
  vesselFields,
  stringifyFieldValue,
  getFieldHighlight,
} from 'utils/vesselFields';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import CardContainer from './common/CardContainer';
import LabelTitle from './common/LabelTitle';
import { FIELDS_IN_COURSE_HEADER } from './common/variables';

const AdditionalInfoWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  row-gap: 1rem;
  li {
    margin: 0 !important;
    flex: 1 1 50%;
    list-style-type: none;
  }
`;

const FieldInfo = styled.li`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
`;

const VesselInfo = ({ vessel }: { vessel: Vessel }) => {
  const { filteredVessels: vessels } = useSelector(selectMapVessels);
  const { colorHighlightField, sidePanelFields } = useSelector(selectSettings);

  const defaultSidePanelDisplayableFields = useMemo(
    () =>
      getSidePanelDisplayableFields().filter((o) =>
        FIELDS_IN_COURSE_HEADER.includes(o)
      ),
    []
  );

  const { activeRefs, highlightedField, isInWizard, wizardSidePanelFields } =
    useContext(SidePanelFieldContext);

  const highlightStyleColor = useMemo(() => {
    const vesselHighlights = getFieldHighlight(
      colorHighlightField as string,
      Array.from(vessels.values())
    );
    return vesselHighlights?.getHighlightColor(vessel) || '';
  }, [vessels, vessel, colorHighlightField]);

  const highlightedFieldData = useMemo(() => {
    return vesselFields[colorHighlightField as VesselFieldId];
  }, [colorHighlightField]);

  useEffect(() => {
    if (
      highlightedField &&
      highlightedField in activeRefs.current &&
      activeRefs.current[highlightedField]
    ) {
      activeRefs.current[highlightedField].scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [highlightedField, activeRefs]);

  const displayFields = useMemo(() => {
    const fieldsToUse =
      isInWizard && wizardSidePanelFields
        ? wizardSidePanelFields
        : sidePanelFields;
    return defaultSidePanelDisplayableFields
      .reduce((acc: Array<any>, fieldId) => {
        // do not include colored highlight field
        if (fieldId === colorHighlightField || !fieldsToUse.includes(fieldId)) {
          return acc;
        }
        if (fieldId === 'secondsToNextPort') {
          // Manually force it to go to the far bottom
          acc.push({
            fieldId,
            data: { ...vesselFields[fieldId], displayPriority: 999 },
          });
        } else {
          acc.push({ fieldId, data: vesselFields[fieldId] });
        }
        return acc;
      }, [])
      .sort((a, b) => a.data.displayPriority - b.data.displayPriority);
  }, [
    sidePanelFields,
    colorHighlightField,
    isInWizard,
    wizardSidePanelFields,
    defaultSidePanelDisplayableFields,
  ]);

  if (!displayFields.length && !highlightedFieldData) return null;
  return (
    <CardContainer>
      <AdditionalInfoWrapper>
        {displayFields.map(({ fieldId, data }) => (
          <FieldInfo
            key={fieldId}
            ref={(element) => ((activeRefs.current as any)[fieldId] = element)}
          >
            <LabelTitle>{data.longDesc}</LabelTitle>
            <p style={{ margin: 0 }}>
              {stringifyFieldValue(vessel[fieldId], data)}
            </p>
          </FieldInfo>
        ))}
        {highlightedFieldData && (
          <FieldInfo>
            <LabelTitle>{highlightedFieldData.longDesc}</LabelTitle>
            <p style={{ margin: 0, color: highlightStyleColor }}>
              {stringifyFieldValue(
                vessel[colorHighlightField as VesselFieldId],
                highlightedFieldData
              )}
            </p>
          </FieldInfo>
        )}
      </AdditionalInfoWrapper>
    </CardContainer>
  );
};

export default memo(VesselInfo);
