import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import uniq from 'lodash/uniq';
import type { UserConfigResp } from '@greywing-maritime/frontend-library/dist/types/userConfig';

import {
  fetchTrackSettings,
  fetchAppSettings,
  getUserInfo,
  saveAppSettings,
} from 'api/flotilla';
import { updateStoreSettings } from 'redux/actions';
import {
  DEFAULT_SETTINGS,
  loadInitialAppSettings,
} from 'redux/helpers/settings';
import { AppDispatch, AppSettings } from 'redux/types';
import { MAX_LAYOVER_TIME } from 'components/CrewChangePanel/helpers';

import { validateAppSettings } from './validation/settings';
import { FLOTILLA_SETTINGS_KEY } from './types';
import { initStoreThreadHistory } from 'redux/reducers/copilot';
import { initThreadHistory } from 'components/SeaGPT/helpers';

// checks if settings response contains empty settings object
const isValidSettingsResponse = (settings: AppSettings | null) =>
  settings && Boolean(Object.keys(settings).length);

const sanitizeUserInfo = (userInfo: UserConfigResp | undefined) =>
  userInfo
    ? {
        userInfo: {
          ...userInfo,
          // fix read-only data validation for `access` object
          access: { ...userInfo.access },
          // fallback values for newly added fields to prevent validation & other potential errors
          allowedTravelVendors: userInfo.allowedTravelVendors || [],
          vendorsDisabledForFRE: userInfo.vendorsDisabledForFRE || [],
        },
      }
    : {};

// sanitize settings value for any structural changes
export const sanitizeAppSettings = (
  settings: AppSettings,
  initial: boolean = true
): AppSettings => {
  const {
    userInfo,
    crewChange,
    sidePanelFields,
    crewChange: {
      flightParams: { layover },
      portParams: { priorities = [] },
    },
  } = settings;

  // Convert `layover` to an array, if already not.
  const layovertimeRange = isArray(layover)
    ? layover
    : (isNumber(layover) && [0, layover]) || [0, MAX_LAYOVER_TIME];

  // remove `operatingStatus` from sidepanelFields since it's no longer available
  const updatedSidePaneFields = filter(
    sidePanelFields,
    (field) => field !== 'operatingStatus'
  );

  // sanitizes any read-only for validation
  const commonSanitizedSettings = {
    ...settings,
    crewChange: {
      ...crewChange,
      flightParams: {
        ...crewChange.flightParams,
        layover: [...layovertimeRange] as [number, number],
      },
      portParams: {
        ...crewChange.portParams,
        // Following changes to the name changes to priority
        priorities: priorities.map((priority) => {
          if (priority === 'Min Deviation') {
            return 'Deviation';
          }
          if (priority === 'No Deviation') {
            return 'Voyage Plan';
          }
          if (priority === 'Preferred') {
            return 'Slow Down';
          }
          return priority;
        }),
      },
    },
    ...sanitizeUserInfo(userInfo),
  };

  if (!initial) {
    return {
      ...commonSanitizedSettings,
      sidePanelFields: updatedSidePaneFields,
    };
  }

  // include additional changes for settings initialization, e.g - override a value
  return {
    ...commonSanitizedSettings,
    // make Saved Plans available in Sidepanel by default when initializing
    ...(initial
      ? {
          sidePanelFields: uniq([
            ...updatedSidePaneFields,
            'compound_savedPlans',
            'compound_incompletePlans',
          ]),
        }
      : {}),
  };
};

// checks if user has previously saved settings in the backend
// if so, save it to redux store
export const initializeAppSettings = async (dispatch: AppDispatch) => {
  // fetch user data when initializing app
  const { success: getUserInfoSuccess, userConfig: userInfo } =
    await getUserInfo();

  if (!getUserInfoSuccess) {
    return false;
  }

  // set `session_started_at` in `localStorage` - for latest reload
  localStorage.setItem('session_started_at', new Date().toISOString());

  // fetch chat history
  if (userInfo) {
    const threads = initThreadHistory(userInfo.id);
    threads && dispatch(initStoreThreadHistory(threads));
  }
  // fetch flight tracking settings
  const { success: trackSettingsSuccess, trackSettings } =
    await fetchTrackSettings();

  // app previously saved app-settings, if any
  const { success: fetchSuccess, settings: fetchedSettings } =
    await fetchAppSettings();

  // hydrate store with sanitized settings object
  const settings: AppSettings = sanitizeAppSettings({
    ...DEFAULT_SETTINGS,
    ...(fetchedSettings || {}),
    ...(getUserInfoSuccess ? { userInfo } : {}),
    // add original track settings to avoid any conflict with saved settings
    // https://github.com/Greywing-Maritime/greywing-server/pull/360
    trackSettings: (trackSettingsSuccess && trackSettings) || null,
  });

  if (fetchSuccess && validateAppSettings(settings)) {
    console.log('Successfully initialized saved app settings!');
    dispatch(updateStoreSettings(settings));
    return true;
  }

  // otherwise, save app initial/default settings in the backend
  // & hydrate store with returned setting in response
  const initialSettings = { ...loadInitialAppSettings(), userInfo };
  // validate initial/default settings
  const validSettings = validateAppSettings(initialSettings);

  if (validSettings) {
    const { success: saveSuccess, settings: savedSettings } =
      await saveAppSettings(validSettings);

    if (saveSuccess && isValidSettingsResponse(savedSettings)) {
      dispatch(updateStoreSettings(savedSettings!));
      localStorage.removeItem(FLOTILLA_SETTINGS_KEY);
    }
  }
  return true;
};
