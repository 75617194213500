import { number, object } from 'superstruct';
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { CrewEvent } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { Chip } from '@mui/material';
import { superstructResolver } from '@hookform/resolvers/superstruct';

import {
  saveCopilotInputValue,
  startCopilot,
  startNewSeaGPTThread,
} from 'redux/actions';
import { RootState } from 'redux/types';
import { selectVesselById } from 'redux/reducers/mapVessels';

import { ButtonV2, Modal } from '../../../components/shared';
import { generateNewConversation } from '../../../components/SeaGPT/helpers';
import { SelectV2 } from '../../../components/shared/forms';
import { DATE_FORMAT, formatDate } from 'utils/format-date';
import { PortCallResponse } from 'utils/types';
import { Crew } from 'utils/types/crew-change-types';
import { validationErrMessage } from 'utils/validations';
import { trackUserAction } from 'lib/amplitude';
import {
  TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL,
  TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL_SELECT_PORT,
} from 'utils/analytics/constants';
import { ICON_COLOR, TEXT_COLOR } from '../common/variables';

type SendSeaGptEmailModalProps = {
  onClose: () => void;
  portsResponse: PortCallResponse | null;
  crewEvent: CrewEvent;
  vesselId: number;
};

type CrewMap = {
  onsigner: CrewNationalityCount;
  offsigner: CrewNationalityCount;
};
type CrewNationalityCount = {
  [key: string]: {
    country: string;
    countryCode: string;
    count: number;
  };
};

type FilteredPort = {
  etaStr: string;
  eta: Date;
  displayName: string;
  portLocode: string;
  value: number;
  type: string;
};

type FormValues = {
  selectedPort: number;
};

const PortSelectionSchema = object({
  selectedPort: validationErrMessage(number(), 'A port must be selected.'),
});

function crewNationalityMap(crew: Crew[]) {
  return crew.reduce(
    (map, eachCrew) => {
      if (!eachCrew.country || !eachCrew.countryCode) return map;
      if (map[eachCrew.type][eachCrew.countryCode]) {
        map[eachCrew.type][eachCrew.countryCode].count += 1;
      } else {
        map[eachCrew.type][eachCrew.countryCode] = {
          country: eachCrew.country,
          countryCode: eachCrew.countryCode,
          count: 1,
        };
      }
      return map;
    },
    { onsigner: {}, offsigner: {} } as CrewMap
  );
}

function generateCrewText(crew: Crew[]): string {
  const crewMap = crewNationalityMap(crew);
  const onsignerText = Object.values(crewMap.onsigner)
    .map((each) => `${each.count} ${each.country}`)
    .join(', ');
  const offsignerText = Object.values(crewMap.offsigner)
    .map((each) => `${each.count} ${each.country}`)
    .join(', ');
  return [
    onsignerText ? `${onsignerText} onsigners` : undefined,
    offsignerText ? `${offsignerText} offsigners` : undefined,
  ].join(' and ');
}

function generateSeaGptInputValue(
  vesselName: string,
  portName: string,
  eta: Date | string,
  crewMapText: string
) {
  return `Plan a crew change for vessel ${vesselName} at port ${portName} on ${moment(
    eta
  ).format('DD MMM YYYY')} with ${crewMapText}.`;
}

const OptionLabel = styled.div`
  display: flex;
  column-gap: 0.5rem;
  flex-direction: row;
  label {
  }
`;

function SendSeaGptEmailModal({
  crewEvent,
  onClose,
  vesselId,
  portsResponse,
}: SendSeaGptEmailModalProps) {
  const dispatch = useDispatch();
  const vessel = useSelector((state: RootState) =>
    selectVesselById(state, vesselId)
  );
  const { name, eta, locode, crew } = crewEvent;
  useEffect(() => {
    if (name && eta && locode && crew.length) {
      handleDirectSubmit(name, eta, locode, crew);
      trackUserAction(TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL, 'click', {
        vesselId,
        eventId: crewEvent.id,
        selectPort: false,
      });
    } else {
      trackUserAction(TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL, 'click', {
        vesselId,
        eventId: crewEvent.id,
        selectPort: true,
      });
    }
  }, []); // eslint-disable-line

  const handleDirectSubmit = (
    name: string,
    eta: string,
    locode: string,
    crew: Crew[]
  ) => {
    const crewMapText = generateCrewText(crew);
    const portName = [name, locode ? `(${locode})` : undefined]
      .filter(Boolean)
      .join(' ');
    const crewText = generateSeaGptInputValue(
      vessel.name,
      portName,
      eta,
      crewMapText
    );
    const newThread = generateNewConversation('New Thread');
    dispatch(startNewSeaGPTThread(newThread));
    dispatch(saveCopilotInputValue(crewText));
    // Close modal
    onClose();
    // Open proteus
    dispatch(
      startCopilot({
        view: 'full',
        category: 'general',
        autoSubmit: true,
      })
    );
  };

  const filteredPorts = useMemo(() => {
    return (
      portsResponse?.portCalls.reduce((acc, portCall) => {
        if (portCall.displayName && portCall.portLocode && portCall.eta) {
          if (moment(portCall.eta).isBefore(moment())) return acc;
          const etaStr = `${formatDate(
            portCall.eta,
            DATE_FORMAT.DD_MMM_YY_HH_mm
          )}${
            portCall.timezone
              ? ` ${moment().tz(portCall.timezone).format('ZZ')}`
              : ''
          }`;
          acc.push({
            portLocode: portCall.portLocode,
            displayName: portCall.displayName,
            etaStr: etaStr,
            eta: portCall.eta,
            value: acc.length,
            type: portCall.type,
          });
          return acc;
        }
        return acc;
      }, [] as FilteredPort[]) || []
    );
  }, [portsResponse]);

  const portOptions = useMemo(() => {
    return filteredPorts.map((port) => {
      return {
        value: port.value,
        label: (
          <OptionLabel>
            <Chip
              size="small"
              label={port.type.replace('_', ' ')}
              sx={{
                color:
                  TEXT_COLOR[port.type.toLowerCase()] || TEXT_COLOR.DEFAULT,
                background:
                  ICON_COLOR[port.type.toLowerCase()] || ICON_COLOR.DEFAULT,
              }}
            />
            <label>{`${port.displayName} (${port.portLocode}) ${port.etaStr}`}</label>
          </OptionLabel>
        ),
      };
    });
  }, [filteredPorts]);

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: { selectedPort: 0 },
    resolver: superstructResolver(PortSelectionSchema),
  });
  const onSubmit = useCallback(
    (values: FormValues) => {
      const selectedPortOfCall = filteredPorts.find(
        (o) => o.value === values.selectedPort
      );
      if (!selectedPortOfCall) return;
      const crewMapText = generateCrewText(crew);
      const portName = [
        selectedPortOfCall.displayName,
        `(${selectedPortOfCall.portLocode})`,
      ].join(' ');
      const crewText = generateSeaGptInputValue(
        vessel.name,
        portName,
        selectedPortOfCall.eta,
        crewMapText
      );
      const newThread = generateNewConversation('New Thread');
      dispatch(startNewSeaGPTThread(newThread));
      dispatch(saveCopilotInputValue(crewText));
      trackUserAction(
        TRACK_SEAGPT_START_CONCIERGE_DRAFT_EMAIL_SELECT_PORT,
        'click',
        {
          vesselId: vessel.id,
          eventId: crewEvent.id,
          portSelected: portName,
        }
      );
      // Close modal
      onClose();
      // Open proteus
      dispatch(
        startCopilot({
          view: 'full',
          category: 'general',
          autoSubmit: true,
        })
      );
    },
    [crew, vessel, filteredPorts, dispatch, onClose, crewEvent.id]
  );

  return (
    <Modal
      closeModal={onClose}
      title="Draft a crew change email for Port Agents"
      actions={
        <div>
          <ButtonV2 onClick={handleSubmit(onSubmit)}>Submit</ButtonV2>
        </div>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p style={{ margin: '0 0 1.5rem 0' }}>
          We're almost there, please select a port to continue
        </p>
        <SelectV2
          sx={{ width: '100%' }}
          // @ts-ignore
          control={control}
          name="selectedPort"
          options={portOptions}
          label="Select a port of call"
        />
      </form>
    </Modal>
  );
}

export default SendSeaGptEmailModal;
