import { Middleware } from '@reduxjs/toolkit';

import { RECENT_SEARCHES_KEY } from 'lib/constants';

const resetStore: Middleware<{}, any> = () => (next) => async (action) => {
  // make changes when store is reset
  if (action.type === 'store/reset') {
    localStorage.removeItem(RECENT_SEARCHES_KEY);
  }

  next(action);
};

export default resetStore;
