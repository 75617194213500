export const MAIN_VESSELS_SOURCE_ID = 'all-vessel';
export const MAIN_VESSELS_LAYER_ID_SMALL = `${MAIN_VESSELS_SOURCE_ID}-small`;
export const MAIN_VESSELS_LAYER_ID_LARGE = `${MAIN_VESSELS_SOURCE_ID}-large`;
// export const MAIN_VESSELS_FEATURE_ID = null; // a number making use of the vessel id.

export const MAIN_ROUTES_SOURCE_ID = 'all-vessel-routes';
export const MAIN_ROUTES_FEATURE_ID = 'vessel-route-'; // this is combined with the vessel id.
export const MAIN_ROUTES_ARROW_LAYER_ID = 'all-vessel-arrows';

export const ACTIVE_VESSELS = 'active-vessels';
export const ACTIVE_ROUTES = 'active-routes';
export const ACTIVE_WAYPOINTS = 'active-waypoints';
export const ACTIVE_ROUTES_ARROWS = 'active-routes-arrows';

export const ACTIVE_VESSEL_FEATURE = 'active-vessel-';
export const ACTIVE_VESSEL_ROUTE_FEATURE = 'active-vessel-route-';

export const ACTIVE_PORT_WAYPOINTS = 'active-port-waypoints';
export const SELECTED_PORT_WAYPOINTS = 'selected-port';

// Port Map Stuffs
export const PORTS_SOURCE_ID = 'all-ports';
export const PORTS_PINNED_SOURCE_ID = 'pinned-ports';
export const PORTS_AIRPORT_SOURCE_ID = 'map-ports-airport';
export const PORTS_AIRPORT_ROUTES_SOURCE_ID = 'map-ports-airport-route';

// Crew Change Map Stuffs
export const CREW_CHANGE_VESSEL = 'cc-vessel';
export const CREW_CHANGE_ROUTE = 'cc-route';
export const CREW_CHANGE_ROUTE_ARROWS = 'cc-arrows';
export const CREW_CHANGE_PROXIMITY_PORT = 'cc-proximity-port';
export const CREW_CHANGE_AIRPORT = 'cc-home-airports';
export const CREW_CHANGE_AIRPORT_ROUTE = 'cc-home-airports-routes';

// Risk Area Tracking Stuffs
export const ALL_RISK_AREA = 'all-risk-area';
export const ACTIVE_RISK_AREA = 'active-risk-area';

// Time Travel
export const TIME_TRAVEL_VESSELS = 'time-travelling-vessel';
export const TIME_TRAVEL_ROUTE = 'time-travelling-vessel-route';
export const TIME_TRAVEL_ROUTE_ARROW_LAYER_ID = 'time-travelling-vessel-arrows';
