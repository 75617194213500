import { useState, ChangeEvent, useEffect, ReactElement } from 'react';
import styled from 'styled-components/macro';
import { CSSProperties } from 'styled-components';
import { blue, borderGray, white } from 'lib/colors';

interface ICheckboxProps {
  id: string;
  label: string | ReactElement;
  checked: boolean;
  onChange?: (e: boolean, id: string, cb: () => void) => void;
  onMouseEnter?: React.MouseEventHandler<HTMLLabelElement>;
  disabled?: boolean;
  checkboxContainerStyle?: CSSProperties;
}

const CheckboxContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
`;

const CheckboxInput = styled.input`
  position: absolute;
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
`;

const CheckboxStyle = styled.div<{ $isChecked: boolean; $id: string }>`
  display: inline-block;
  height: 16px;
  width: 16px;
  background-color: ${white};
  border: 1px solid ${borderGray};
  border-radius: 3px;
  transition: all 150ms;

  #${({ $id }) => $id}:focus + & {
    box-shadow: 0 0 3px 3px rgba(56, 93, 234, 0.5);
  }

  ${({ $isChecked }) =>
    $isChecked
      ? `
      background-color: ${blue};
      svg {
        visibility: visible;
      }`
      : ''}
`;

const Checkmark = styled.svg`
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

const Label = styled.label<{ $disabled?: boolean }>`
  display: flex;
  align-items: start;
  ${({ $disabled }) =>
    $disabled &&
    `
      opacity: 0.33;
      pointer-events: none;
    `};
`;

export default function Checkbox({
  id,
  label,
  checked,
  onChange,
  onMouseEnter,
  disabled,
  checkboxContainerStyle,
}: ICheckboxProps) {
  // "isChecked" (state) does not update even when "checked" (props) changes.
  // The solution in class components is to use getDerivedStateFromProps().
  // In functional components, it is through useEffect (see below).
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.checked;

    onChange && onChange(newVal, id, () => setIsChecked(newVal));
  };

  useEffect(() => {
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checked]);

  return (
    <Label onMouseEnter={onMouseEnter || undefined} $disabled={disabled}>
      <CheckboxContainer style={checkboxContainerStyle}>
        <CheckboxInput
          type="checkbox"
          id={id}
          onChange={(e) => handleChange(e)}
          checked={isChecked}
          disabled={disabled}
        />
        <CheckboxStyle id="checkbox-style" $isChecked={isChecked} $id={id}>
          <Checkmark viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Checkmark>
        </CheckboxStyle>
      </CheckboxContainer>
      {label}
    </Label>
  );
}
