import styled from 'styled-components/macro';

import { BREAK_POINT_SM } from 'lib/breakpoints';
import { borderGray, gray60, lightPurple } from 'lib/colors';

import { ResultTile as Title } from 'components/FlotillaSearch/common';
import InfoIcon from '@mui/icons-material/Info';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FormWrapper = styled(Wrapper)`
  .MuiFormControlLabel-root {
    height: 24px;
    margin-right: 0.33rem;
  }
  .MuiFormControlLabel-label {
    font-size: 0.95rem;
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  font-size: 16px !important;
  color: ${gray60};
`;

export const CardHeader = styled.div<{ $expanded?: boolean }>`
  border-radius: 4px;
  ${({ $expanded }) =>
    $expanded &&
    `
      border-bottom: 1px solid ${borderGray};
    `};
`;

export const TitleWrapper = styled(Wrapper)<{ $filters?: boolean }>`
  justify-content: space-between;
  cursor: pointer;
  transition: all 150ms ease;
  ${({ $filters }) =>
    $filters &&
    `
      padding: 0.3rem 0 0.15rem;
    `};
  &:hover {
    background: ${lightPurple};
  }
`;

export const CardTitle = styled.div`
  margin-left: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const CardInfo = styled(Title)`
  font-size: 0.95rem;
  line-height: 1.5;
  @media screen and (max-width: ${BREAK_POINT_SM}) {
    font-size: 0.9rem;
  }
`;
