import {
  DataGridPro,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import styled from 'styled-components/macro';

import {
  textBlack,
  textGray,
  iconGray,
  darkBlue,
  midBlue,
  blue,
  white,
  lightGray,
} from 'lib/colors';
import { TableSkeleton } from 'components/shared';
import { Text } from '../../common';
import { useState } from 'react';
import AssignPICModal from './AssignPICModal';
import { PICDataProps, PICDataSetStateProps, UserProps } from '../../types';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';

export const TableWrapper = styled.div`
  width: 96%;
  margin-top: 1rem;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 999;
  user-select: none;

  .MuiDataGrid-row:hover {
    cursor: pointer;
  }
  .MuiDataGrid-row.Mui-selected {
    background-color: ${lightGray} !important;
  }
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-iconSeparator {
    display: none;
  }
  .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
  }
  .MuiDataGrid-pinnedColumnHeaders {
    box-shadow: none;
    background-color: transparent;
  }
  .MuiDataGrid-pinnedColumns {
    box-shadow: none;
    & .MuiDataGrid-cell {
      padding: 0;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const tableStyles = {
  fontFamily: 'HK Grotesk, Roboto',
  fontSize: '0.8rem',
  '& .table-header': {
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '0.7rem',
    letterSpacing: '0.05rem',
    color: `${textGray}`,
  },
};

const PICButton = styled.div`
  display: flex;
  width: 100px;
  align-items: center;
  justify-content: center;
  font-size: 0.6rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${textBlack};
  background: ${midBlue};
  border: 1.25px solid ${darkBlue};
  width: fit-content;
  padding: 3px 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 150ms ease;
  &:hover {
    color: ${white};
    background: ${blue};
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 1rem;
`;

type ButtonProps = {
  vessel: number;
  users: UserProps[];
  updatePICData: PICDataSetStateProps;
};

const RenderActionButton = ({ vessel, users, updatePICData }: ButtonProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <FlexWrapper>
      {users.length ? (
        <PICButton onClick={() => setShowModal(true)}>Assign New PIC</PICButton>
      ) : null}
      {showModal ? (
        <AssignPICModal
          users={users}
          vessel={vessel}
          updatePICData={updatePICData}
          closeModal={handleCloseModal}
        />
      ) : null}
    </FlexWrapper>
  );
};

type AssignPICProps = {
  loading: boolean;
  isCompact: boolean;
  picData: PICDataProps[];
  users: UserProps[];
  setPICData: PICDataSetStateProps;
};

function AssignPIC({
  loading,
  isCompact,
  picData,
  users,
  setPICData,
}: AssignPICProps) {
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);

  if (loading) {
    return (
      <>
        <Text>Loading PIC Data.</Text>
        <LoaderWrapper>
          <TableSkeleton rows={2} />
        </LoaderWrapper>
      </>
    );
  }

  const gridColumns: GridColDef[] = [
    {
      field: 'vesselName',
      maxWidth: 300,
      renderCell: (params: GridRenderCellParams) => params.value.toUpperCase(),
      renderHeader: () => (
        <FlexWrapper>
          <DirectionsBoatIcon
            sx={{
              fontSize: '12px',
              color: iconGray,
              marginRight: '6px',
            }}
          />
          {'Vessel'}
        </FlexWrapper>
      ),
    },
    {
      field: 'pic',
      maxWidth: 200,
      renderHeader: () => (
        <FlexWrapper>
          <PersonIcon
            sx={{
              fontSize: '12px',
              color: iconGray,
              marginRight: '6px',
            }}
          />
          {'PIC'}
        </FlexWrapper>
      ),
    },
    {
      field: 'email',
      maxWidth: 200,
      renderHeader: () => (
        <FlexWrapper>
          <MailIcon
            sx={{
              fontSize: '12px',
              color: iconGray,
              marginRight: '6px',
            }}
          />
          {'PIC Email'}
        </FlexWrapper>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        if (hoveredRow === params.id)
          return (
            <RenderActionButton
              vessel={params.id as number}
              users={users}
              updatePICData={setPICData}
            />
          );
        else return null;
      },
    },
  ];

  const columns = gridColumns.map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: item.maxWidth || 250,
  }));

  const onMouseEnterRow = (event: MouseEvent) => {
    const id = Number((event.currentTarget as Element).getAttribute('data-id'));
    setHoveredRow(id);
  };

  const onMouseLeaveRow = (event: MouseEvent) => {
    setHoveredRow(null);
  };

  return (
    <TableWrapper>
      <DataGridPro
        rows={picData}
        columns={columns}
        hideFooter
        disableSelectionOnClick
        disableColumnMenu
        disableColumnReorder
        hideFooterPagination
        autoHeight
        density={isCompact ? 'compact' : 'standard'}
        sx={tableStyles}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        componentsProps={{
          row: {
            onMouseEnter: onMouseEnterRow,
            onMouseLeave: onMouseLeaveRow,
          },
        }}
      />
    </TableWrapper>
  );
}

export default AssignPIC;
