import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { trackUserAction } from 'lib/amplitude';
import { vesselFields } from 'utils/vesselFields';
import { TRACK_DOWNLOAD_CSV } from 'utils/analytics/constants';
import { ActionButtonState } from 'components/SidePanel/SidePanelVesselHeader';
import { selectSidePanelVessels } from 'redux/reducers/sidePanel';
import { locationTime } from 'utils/timezone';

const useDownloadCSV = () => {
  const sidePanelVessels = useSelector(selectSidePanelVessels);

  const [downloadCSVState, setDownloadCSVState] = useState<ActionButtonState>(
    ActionButtonState.normal
  );

  const downloadCSV = useCallback(() => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    };

    const headers: string[] = [];
    const columns: string[] = [];
    for (const field in vesselFields) {
      const vesselMeta = vesselFields[field];
      if (vesselMeta.includedInCSV) {
        headers.push(vesselMeta.shortDesc || vesselMeta.longDesc || field);
        columns.push(field);
      }
    }
    headers.push('Local Time');
    columns.push('localTime');

    const vessels: Vessel[] = sidePanelVessels.map((v) => {
      const vessel = {
        ...v!,
      };
      for (const field in vessel) {
        const vesselMeta = vesselFields[field];
        if (vesselMeta && vesselMeta.includedInCSV) {
          vessel[field] = vesselMeta.getDisplayString(vessel[field]);
        }
      }
      if (vessel.lat && vessel.lng) {
        vessel['localTime'] = locationTime({
          lat: vessel.lat,
          lon: vessel.lng,
        });
      } else {
        vessel['localTime'] = '';
      }
      return vessel;
    });

    const csv =
      Papa.unparse({ fields: headers, data: [] }) +
      Papa.unparse(vessels, { header: false, columns });
    const csvURL = window.URL.createObjectURL(
      new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    );
    const link = document.createElement('a');
    link.href = csvURL;
    link.setAttribute(
      'download',
      `Vessel List ${new Date().toLocaleString('en-GB', dateOptions)}.csv`
    );
    link.click();
  }, [sidePanelVessels]);

  const onDownload = () => {
    if (downloadCSVState === ActionButtonState.normal) {
      trackUserAction(TRACK_DOWNLOAD_CSV);
      setDownloadCSVState(ActionButtonState.in_progress);
      setTimeout(() => {
        try {
          downloadCSV();
        } catch (err) {
          console.error('Error downloading CSV', err);
        }
        setDownloadCSVState(ActionButtonState.normal);
      }, 300);
    }
  };

  return { onDownload, downloadCSVState };
};

export default useDownloadCSV;
