import { memo } from 'react';
import styled from 'styled-components/macro';

import { formatDate } from 'utils/format-date';
import { Tooltip } from 'components/shared';
import { getLayoverDetails } from 'components/FlotillaSearch/helpers';
import { LayoverDetails } from 'components/FlotillaSearch/types';
import { FlightRow } from '../../types';

const TooltipWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 0.33rem;
  padding: 0.25rem;
  padding-right: 0;
`;

const TooltipText = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.25fr;
  grid-gap: 0.5rem;
  margin-bottom: 1px;
  font-weight: 500;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TextLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 4px;
`;

type TooltipProps = {
  flightLayovers: LayoverDetails[];
};

type Props = {
  flightRow: FlightRow;
};

function LayoverTooltip({ flightLayovers }: TooltipProps) {
  const hasMultipleLayovers = flightLayovers.length > 1;
  return (
    <>
      {flightLayovers.map((layoverDetails, index) => {
        const { arrival, departure, layoverTime } = layoverDetails;
        return (
          <TooltipWrapper key={arrival.iataCode}>
            {hasMultipleLayovers ? <span>{index + 1}.</span> : null}
            <div>
              {hasMultipleLayovers && (
                <TooltipText>
                  <TextLeft>
                    Total Layover Time <span>:</span>{' '}
                  </TextLeft>
                  <b>{layoverTime}</b>
                </TooltipText>
              )}
              <TooltipText>
                <TextLeft>
                  Arrival at {arrival.iataCode} <span>:</span>{' '}
                </TextLeft>
                <b>{formatDate(arrival.time, 'hh:mm a, DD MMM')}</b>
              </TooltipText>
              <TooltipText>
                <TextLeft>
                  Departure from {departure.iataCode} <span>:</span>{' '}
                </TextLeft>
                <b>{formatDate(departure.time, 'hh:mm a, DD MMM')}</b>
              </TooltipText>
            </div>
          </TooltipWrapper>
        );
      })}
    </>
  );
}

function FlightLayoverDetails({ flightRow }: Props) {
  const { flightLayoverTime, segments, stops } = flightRow;

  if (!flightLayoverTime || flightLayoverTime === '-') {
    return <span>-</span>;
  }

  if (!segments || !stops) {
    return <span>{flightLayoverTime}</span>;
  }

  const flightLayovers = stops.map((stop) => getLayoverDetails(stop, segments));

  return (
    <Tooltip content={<LayoverTooltip flightLayovers={flightLayovers} />}>
      <span>{flightLayoverTime}</span>
    </Tooltip>
  );
}

export default memo(FlightLayoverDetails);
