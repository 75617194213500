// Moves the item to the new position in the array.
// Useful for huge arrays where performance is important.
export const arrayMoveMutable = (
  array: any[],
  fromIndex: number,
  toIndex: number
) => {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
};

// Clones the given array, moves the item to a new position in the new array,
// and then returns the new array. The given array is not mutated.
export const arrayMoveImmutable = (
  array: any[],
  fromIndex: number,
  toIndex: number
) => {
  array = [...array];
  arrayMoveMutable(array, fromIndex, toIndex);
  return array;
};

// Inserts a new item into an array
// by default inserts as the first item
export const insertIntoArray = (array: any[], item: any, index: number = 0) => {
  return [...array.slice(0, index), item, ...array.slice(index)];
};
