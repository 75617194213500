import { memo } from 'react';
import { TextFieldProps, TextField as MuiTextField } from '@mui/material';
import {
  useController,
  Control,
  FieldValues,
  FieldPathByValue,
} from 'react-hook-form';

function TextFieldV2<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, string>
>({
  control,
  name,
  ...props
}: TextFieldProps & { name: TPath; control: Control<TFieldValues> }) {
  const {
    field,
    fieldState: { invalid, error },
  } = useController<TFieldValues>({
    name,
    control,
  });
  return (
    <MuiTextField
      {...props}
      {...field}
      inputRef={field.ref}
      value={field.value}
      error={invalid}
      helperText={error?.message}
    />
  );
}

export default memo(TextFieldV2);
