import {
  date,
  object,
  boolean,
  string,
  array,
  union,
  enums,
  Describe,
  number,
  nullable,
  optional,
  assign,
  record,
  mask,
  StructError,
  defaulted,
  type,
  size,
  tuple,
} from 'superstruct';
import {
  RestrictionInfo as RestrictionInfoList,
  Country,
} from '@greywing-maritime/frontend-library/dist/types/proxPorts';
import {
  AirportLong,
  BaggageInfo,
  FareInformation,
  FlightSegment,
} from '@greywing-maritime/frontend-library/dist/types/flightResultTypes';
import {
  CrewType,
  CrewWageInfo,
  CrewWageInfoType,
} from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import type {
  CrewChangePlanSummary,
  CrewChangePlanUserNotes,
} from '@greywing-maritime/frontend-library/dist/types/saveCrewChangePlanTypes';
import type { AirportCommon } from '@greywing-maritime/frontend-library/dist/types/airports';

import { trackUserAction } from 'lib/amplitude';
import { isDevelopment } from 'lib/environments';
import { Crew, Flight } from 'utils/types/crew-change-types';
import {
  FareType,
  FlightParamsType,
  ReadOnlyCrewEvent,
  ReadOnlyFlight,
  ReadOnlyFlightFilters,
  ReadOnlyPort,
  ReadOnlyPortRestrictions,
  ReadOnlyVessel,
  RoutePort,
  SaveableCCReportData,
  SavedCrewChangePlan,
} from 'components/CrewChangePanel/types';
import {
  MIN_DEVIATION,
  SKIPPED_PORT_FIELDS,
} from 'components/CrewChangePanel/helpers';
import { TRACK_CREW_CHANGE_READ_ONLY_FAIL } from 'utils/analytics/constants';

type ReducedReadOnlyPort = Omit<
  ReadOnlyPort,
  typeof SKIPPED_PORT_FIELDS[number]
>;

// excluded read-only fields inside por data
type ReducedReadOnlyPlanningData = Omit<SavedCrewChangePlan, 'ports'> & {
  ports: ReducedReadOnlyPort[];
};

// validated data contains all fileds except read-only data coming from back-end
// read-only fields cannot be validated by superstruct
type ValidatedPlanningData = {
  crewChangePlan: ReducedReadOnlyPlanningData;
  userNotes: CrewChangePlanUserNotes;
  summary: CrewChangePlanSummary;
};

const validAirportCode = size(string(), 3);
const validLocode = size(string(), 5);

const CrewWageInfoSchema: Describe<CrewWageInfo> = object({
  wage: number(),
  currency: string(),
  type: enums([
    CrewWageInfoType.INDIVIDUAL,
    CrewWageInfoType.ORG_RANK_ESTIMATE,
    CrewWageInfoType.GW_RANK_ESTIMATE,
  ]),
});

const HomeAirportSchema: Describe<AirportCommon> = object({
  iataCode: string(),
  lat: nullable(number()),
  lon: nullable(number()),
  name: string(),
  text: string(),
  municipality: nullable(string()),
});

const CrewSchema: Describe<Crew> = object({
  id: number(),
  type: enums([CrewType.onsigner, CrewType.offsigner]),
  name: nullable(string()),
  country: nullable(string()),
  homeAirport: nullable(HomeAirportSchema),
  // optinal fields
  replacementCrewId: optional(nullable(number())),
  countryCode: optional(nullable(string())),
  cid: optional(nullable(string())),
  rank: optional(nullable(string())),
  monthlySalary: optional(CrewWageInfoSchema),
  dueDate: optional(nullable(string())),
  variance: optional(nullable(number())),
  lastVessel: optional(
    nullable(
      object({
        name: string(),
      })
    )
  ),
  ready: optional(nullable(string())),
  change: optional(nullable(string())),
  manningOffice: optional(nullable(string())),
  age: optional(nullable(number())),
  surname: optional(nullable(string())),
  middleName: optional(nullable(string())),
  lastName: optional(nullable(string())),
  embarkationDate: optional(nullable(string())),
  disembarkationDate: optional(nullable(string())),
  flotillaVesselId: optional(number()),
  added: optional(boolean()),
  wage: optional(number()),
  sex: optional(string()),
  birthday: optional(string()),
  passportNumber: optional(string()),
  passportExpiry: optional(string()),
  passportIssued: optional(string()),
  seamenBookNumber: optional(string()),
  seamenBookIssued: optional(string()),
  seamenBookExpiry: optional(string()),
  visaCountry: optional(string()),
  visaExpiry: optional(string()),
});

const RoutePortSchema = object({
  name: nullable(string()),
  locode: nullable(string()), // allow empty string
});

const RouteSchema: Describe<RoutePort> = object({
  componentType: enums(['port', 'vessel']),
  type: string(),
  displayName: optional(string()),
  index: nullable(number()),
  port: RoutePortSchema,
  eta: optional(string()),
  etd: optional(string()),
  lng: optional(number()),
  lat: optional(number()),
  // additional fields for route port
  added: boolean(),
  distance: optional(number()),
  time: optional(number()),
  order: number(),
});

const CountrySchema: Describe<Country> = object({
  name: string(),
  countryCode: string(),
  countryISO2: string(),
});

// TODO: Remove at some stage. No longer applicable
// const RestrictionSourceSchema = enums(['greywing', 'wilhelmsen']);

const RestrictionInfoSchema = object({
  source: string(),
  latestUpdatedAt: union([string(), date()]),
});

const RestrictionsAvailableSchema: Describe<RestrictionInfoList | null> =
  nullable(array(RestrictionInfoSchema));

const RestrictionsSchema: Describe<ReadOnlyPortRestrictions> = nullable(
  record(
    string(),
    optional(
      object({
        id: number(),
        generalContent: string(),
        htmlContent: string(),
        lastUpdated: union([string(), date()]),
        lastChecked: union([string(), date()]),
        source: string(),
        selector: string(),
        type: string(),
      })
    )
  )
);

const PortSchema: Describe<ReducedReadOnlyPort> = type({
  id: number(),
  locode: validLocode,
  name: string(),
  lat: number(),
  lng: number(),
  eta: optional(string()),
  etd: optional(string()),
  calculatedDistance: optional(number()),
  deviationTimeDifference: optional(number()),
  selected: boolean(),
  selectedAirport: object({
    iataCode: string(),
    name: string(),
    cityName: nullable(string()),
  }),
  distanceKM: number(),
  isPartOfRoute: boolean(),
  daysFromVessel: optional(nullable(number())),
  pastCrewChanges: object({
    count: defaulted(number(), 0),
    countries: defaulted(
      array(assign(object({ count: number() }), CountrySchema)),
      []
    ),
  }),
  restrictionsAvailable: RestrictionsAvailableSchema,
  restrictions: RestrictionsSchema,
});

const FlightParamsRangeSchema: Describe<FlightParamsType> = object({
  stopsCount: number(),
  layoverTime: number(),
  flightTime: number(),
});

const FlightFiltersSchema: Describe<ReadOnlyFlightFilters> = object({
  time: number(),
  locode: string(),
  duplicate: optional(boolean()),
  layover: tuple([number(), number()]),
  stopsCount: number(),
  type: enums(['Cheapest', 'Fastest', 'Lowest CO2']),
  source: string(),
  fareType: enums([FareType.marine, FareType.general, FareType.all]),
  arrivalTime: tuple([number(), number()]),
  departureTime: tuple([number(), number()]),
  departures: nullable(
    object({
      onsigner: optional(string()),
      offsigner: optional(string()),
    })
  ),
  airlines: array(
    object({
      airline: string(),
      selected: boolean(),
    })
  ),
  selectedStops: array(
    object({
      iataCode: string(),
      airport: string(),
      selected: boolean(),
    })
  ),
  range: object({
    min: FlightParamsRangeSchema,
    max: nullable(FlightParamsRangeSchema),
  }),
  allowAirportTransfer: optional(boolean()),
});

const AirportsSchema: Describe<AirportLong> = object({
  name: string(),
  municipality: string(),
  iataCode: string(),
  identifier: string(),
  lat: number(),
  lng: number(),
  countryIso: string(),
  timezone: string(),
});

const BaggageInfoSchema: Describe<BaggageInfo> = object({
  weight: nullable(number()),
  unit: nullable(string()),
  numPieces: nullable(number()),
});

const FareInformationSchema: Describe<FareInformation> = object({
  description: string(),
  monetaryValue: optional(
    array(
      object({
        amount: optional(number()),
        currency: optional(string()),
      })
    )
  ),
});

const FlightSegmentSchema: Describe<FlightSegment> = object({
  flightNumber: string(),
  carrier: object({
    code: string(),
    name: string(),
  }),
  origin: object({
    time: string(),
    iata: string(),
    municipality: string(),
  }),
  dest: object({
    time: string(),
    iata: string(),
    municipality: string(),
  }),
  flightTime: number(),
  operatingCarrierName: defaulted(string(), ''),
  baggage: optional(BaggageInfoSchema),
});

const FlightType: Describe<Flight['type']> = object({
  code: optional(string()),
  enabled: optional(boolean()),
  office: optional(string()),
  type: string(),
});

const FlightSchema: Describe<ReadOnlyFlight> = object({
  id: string(),
  originalId: optional(string()),
  requestId: string(),
  fetchedAt: optional(string()),
  airports: record(validAirportCode, AirportsSchema),
  totalFlightTime: number(),
  source: string(),
  flightNumbers: array(string()),
  totalCO2: number(),
  confirmed: boolean(),
  stops: array(
    object({
      airportCode: validAirportCode,
      layoverMs: number(),
    })
  ),
  segments: array(FlightSegmentSchema),
  departure: object({
    airportCode: validAirportCode,
    time: string(),
  }),
  arrival: object({
    airportCode: validAirportCode,
    time: string(),
  }),
  price: object({
    amount: number(),
    currency: string(),
  }),
  crew: object({
    id: number(),
    type: enums([CrewType.onsigner, CrewType.offsigner]),
    name: nullable(string()),
    country: nullable(string()),
    rank: optional(nullable(string())),
    homeAirport: nullable(HomeAirportSchema),
  }),
  port: object({
    id: number(),
    locode: validLocode,
    eta: optional(string()),
    etd: optional(string()),
  }),
  filters: FlightFiltersSchema,
  hotelCost: number(),
  preferredLocodeKey: boolean(),
  fareBasis: optional(array(string())),
  fareInformation: optional(array(FareInformationSchema)),
  type: optional(FlightType),
});

const VesselSchema: Describe<ReadOnlyVessel> = object({
  id: number(),
  imo: string(),
  name: string(),
  lat: number(),
  lng: number(),
});

const CrewEventSchema: Describe<ReadOnlyCrewEvent> = object({
  id: number(),
  name: nullable(string()),
  locode: nullable(string()), // allow empty string
  onsigners: nullable(number()),
  offsigners: nullable(number()),
  eta: nullable(string()),
  etd: nullable(string()),
  crew: array(CrewSchema),
});

const ReadOnlyPlanningDataSchema: Describe<ReducedReadOnlyPlanningData> =
  object({
    crew: array(CrewSchema),
    route: array(RouteSchema),
    ports: array(PortSchema),
    flights: record(string(), array(FlightSchema)),
    portFilters: object({
      unit: defaulted(enums(['KM', 'NM']), 'NM'),
      range: defaulted(number(), 5),
      agency: defaulted(string(), 'ALL'),
      priorities: defaulted(array(string()), [MIN_DEVIATION]),
      showUrgentPorts: defaulted(boolean(), false),
      etaLimit: defaulted(number(), 4), // default ETA limit to 4 weeks
    }),
    vessel: VesselSchema,
    event: optional(CrewEventSchema),
  });

const CCPlanSummarySchema: Describe<CrewChangePlanSummary> = object({
  crew: object({
    onsignerCount: number(),
    offsignerCount: number(),
    homeAirports: array(
      object({
        iataCode: string(),
        name: string(),
      })
    ),
  }),
  ports: array(
    object({
      locode: string(),
      name: string(),
      crewCompletion: number(),
      totalCost: number(),
      travelSources: array(string()),
    })
  ),
});

const CCPlanUserNotesSchema: Describe<CrewChangePlanUserNotes> = object({
  title: string(),
  notes: string(),
});

const SaveableReportgData: Describe<ValidatedPlanningData> = object({
  crewChangePlan: ReadOnlyPlanningDataSchema,
  userNotes: CCPlanUserNotesSchema,
  summary: CCPlanSummarySchema,
});

export const validateCrewChangeData = (reportDetails: SaveableCCReportData) => {
  try {
    const sanitizedReportData = JSON.parse(JSON.stringify(reportDetails));
    const validated = mask(sanitizedReportData, SaveableReportgData);
    return validated;
  } catch (error) {
    console.log('Error while validating crew change data', error);
    if (error instanceof StructError) {
      const failedPaths = error.failures().map(({ path }) => path[0]);
      if (!isDevelopment) {
        trackUserAction(TRACK_CREW_CHANGE_READ_ONLY_FAIL, 'happened', {
          fields: failedPaths,
        });
        return;
      }
      console.log('Failed paths for crew change data validation', failedPaths);
    }
  }
};
