import { SyntheticEvent } from 'react';
import moment from 'moment';
import {
  GridAlignment,
  GridColDef,
  GridActionsCellItem,
  GridValueSetterParams,
  GridValueFormatterParams,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridColumnGroupingModel,
  GridColumnGroupHeaderParams,
} from '@mui/x-data-grid-pro';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EditIcon from '@mui/icons-material/Edit';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PassportIcon from '@mui/icons-material/Language';
import MuiDeleteIcon from '@mui/icons-material/DeleteOutlined';
import { CrewType } from '@greywing-maritime/frontend-library/dist/types/crewChangeEventTypes';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components/macro';

import { blueGray, gray50, gray60, textGray, white } from 'lib/colors';
import { ColumnVisibility } from 'redux/types';

import { CrewVisaInfo, Tooltip } from 'components/shared';
import SelectCountry from './SelectCountry';
import GridEditDateCell, { formatCellDate } from './GridEditDateCell';
import CrewWage from '../../common/CrewWage';
import AirportDropdown from '../../common/AirportDropdown';
import { ActionButton, CrewBadge } from '../../common';
import {
  CREW_ROWS,
  formatCrewHomeAirport,
  INVALID_DATE,
  isValidCrew,
} from '../../helpers';
import { AddCrew, RemoveCrewFunc, SelectCrewItem } from '../../types';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const UserInput = styled.span`
  font-style: italic;
  color: ${textGray};
`;

const MissingCrew = styled.i`
  color: ${textGray};
  margin-right: 0.5rem;
`;

const AddButton = styled(ActionButton)`
  font-size: 0.6rem;
  font-weight: 500;
  padding: 0.25rem 0.5rem;
  background: ${blueGray};
`;

const CustomTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  padding: 0.25rem 0.5rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-style: italic;
  background: ${gray50};
  border-radius: 4px;
  color: ${white};
  flex-shrink: 0;
`;

const ColumnGroupHeader = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  fontFamily: 'HK Grotesk, Roboto',
  textTransform: 'uppercase',
  fontWeight: 500,
  letterSpacing: '0.05rem',
  fontSize: '0.75rem',
  color: `${textGray}`,
  '& span': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(0.5),
  },
}));

const PlaceholderDate = styled.div`
  font-size: 0.8rem;
  color: ${gray60};
`;

type Visibility = { [name: string]: boolean };

type FuncProps = {
  removeCrew: RemoveCrewFunc;
  selectItem: SelectCrewItem;
  addCrew: AddCrew;
};

export const MOBILE_HIDDEN_COLUMNS = ['Rank', 'Manning Office'];

const getCellDate = (value: Date | string | null, disablePast?: boolean) => {
  const newDate = formatCellDate(value);
  const isValidDate =
    (disablePast
      ? moment(newDate).isAfter(moment())
      : moment(newDate).isBefore(moment())) &&
    moment(newDate, 'DD/MM/YYYY', true).isValid();
  return isValidDate
    ? moment(newDate).utcOffset(0, true).format('DD/MM/YYYY')
    : INVALID_DATE;
};

const renderHeader = ({
  field,
  colDef,
}: {
  field: string;
  colDef: GridColDef;
}) => {
  const { headerName } = colDef;
  const showIcon = [
    'type',
    'name',
    'sex',
    'birthday',
    'rank',
    'wage',
    'passportNumber',
    'passportExpiry',
    'passportIssued',
    'passportIssuedCountry',
    'manningOffice',
    'seamenBookNumber',
    'seamenBookExpiry',
    'seamenBookIssued',
    'seamenBookIssuedCountry',
    'visaExpiry',
  ].includes(field);
  const columnHeader = (
    <FlexWrapper>
      {showIcon ? <EditIcon fontSize="small" /> : null}
      {headerName}
    </FlexWrapper>
  );

  return showIcon ? (
    <Tooltip content={`Double click to update ${headerName}`}>
      {columnHeader}
    </Tooltip>
  ) : (
    columnHeader
  );
};

export const getGridColumns = (
  isPlanning?: boolean,
  addingId?: number | boolean,
  funcProps?: FuncProps
): GridColDef[] =>
  [
    {
      field: 'cid',
      headerName: 'CID',
      minWidth: 60,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        const { id, cid, name, type, added } = crew;
        if (!isPlanning) return <span>{cid || '-'}</span>;
        if (isValidCrew({ name })) {
          return (
            (cid && <span>{cid}</span>) ||
            (added ? <UserInput>User Input</UserInput> : <span>-</span>)
          );
        }
        return addingId !== id ? (
          <Tooltip content={`Add a new ${type}`}>
            <AddButton
              variant="primary"
              onClick={(e: SyntheticEvent) => {
                e.stopPropagation();
                funcProps?.addCrew(crew);
              }}
            >
              Add
            </AddButton>
          </Tooltip>
        ) : (
          <Tooltip content={`Currently adding an ${type}`}>
            <CustomTextWrapper>Adding</CustomTextWrapper>
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      cellClassName: 'crew-update-dropdown',
      valueOptions: [CrewType.onsigner, CrewType.offsigner],
      minWidth: 80,
      align: 'left' as GridAlignment,
      editable: true,
      valueFormatter: ({ value }: GridValueFormatterParams) =>
        value.toUpperCase(),
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.type) return '';
        const type = params.row.type || '';
        return <CrewBadge $on={type === 'onsigner'}>{type}</CrewBadge>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 140,
      editable: true,
      renderCell: ({ row: { name } }: GridRenderCellParams) =>
        isValidCrew({ name }) ? (
          <span>{name || '-'}</span>
        ) : (
          <MissingCrew>{name}</MissingCrew>
        ),
    },
    {
      field: 'manningOffice',
      headerName: 'Manning Office',
      minWidth: 100,
      editable: true,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return <span>{crew.manningOffice || '-'}</span>;
      },
    },
    { field: 'rank', headerName: 'Rank', minWidth: 60, editable: true },
    {
      field: 'wage',
      headerName: 'Wage',
      type: 'number',
      align: 'left' as GridAlignment,
      minWidth: 80,
      editable: true,
      // prevent adding negative wage
      valueParser: (value: number) => (Number(value) < 0 ? 0 : value),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return <CrewWage crew={crew} />;
      },
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 130,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        if (!isPlanning) return <span>{crew.country}</span>;
        return (
          <SelectCountry
            country={crew.country}
            onSelect={(country) => funcProps?.selectItem(crew, { country })}
          />
        );
      },
    },
    {
      field: 'homeAirport',
      headerName: 'Home Airport',
      minWidth: 160,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        const { id, name, homeAirport } = crew;
        if (!isValidCrew({ name })) return '';
        return (
          <AirportDropdown.Crew
            crewId={id}
            label={'Airport'}
            type={isPlanning ? 'ccPlanning' : 'ccReadOnly'}
            selected={homeAirport}
            onSelect={(airport) =>
              funcProps?.selectItem(crew, { homeAirport: airport })
            }
          />
        );
      },
    },
    {
      field: 'birthday',
      headerName: 'Birthdate',
      cellClassName: 'crew-date-cell',
      minWidth: 120,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        birthday: getCellDate(value),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.birthday || <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>}
          </span>
        );
      },
    },
    {
      field: 'sex',
      headerName: 'Sex',
      minWidth: 60,
      editable: true,
      type: 'singleSelect',
      cellClassName: 'crew-update-dropdown',
      valueOptions: ['Female', 'Male', 'Other'],
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return <span>{crew.sex || '-'}</span>;
      },
    },
    {
      field: 'seamenBookNumber',
      headerName: 'SB No.',
      minWidth: 80,
      editable: true,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return <span>{crew.seamenBookNumber || '-'}</span>;
      },
    },
    {
      field: 'seamenBookIssued',
      headerName: 'SB Issue',
      cellClassName: 'crew-date-cell',
      minWidth: 100,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        seamenBookIssued: getCellDate(value),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.seamenBookIssued || (
              <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>
            )}
          </span>
        );
      },
    },
    {
      field: 'seamenBookExpiry',
      headerName: 'SB Expiry',
      cellClassName: 'crew-date-cell',
      minWidth: 100,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        seamenBookExpiry: getCellDate(value, true),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} disablePast />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.seamenBookExpiry || (
              <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>
            )}
          </span>
        );
      },
    },
    {
      field: 'passportNumber',
      headerName: 'PP No.',
      minWidth: 120,
      editable: true,
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return <span>{crew.passportNumber || '-'}</span>;
      },
    },
    {
      field: 'passportIssued',
      headerName: 'PP Issue',
      cellClassName: 'crew-date-cell',
      minWidth: 100,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        passportIssued: getCellDate(value),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.passportIssued || (
              <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>
            )}
          </span>
        );
      },
    },
    {
      field: 'passportExpiry',
      headerName: 'PP Expiry',
      cellClassName: 'crew-date-cell',
      minWidth: 100,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        passportExpiry: getCellDate(value, true),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} disablePast />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.passportExpiry || (
              <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>
            )}
          </span>
        );
      },
    },
    {
      field: 'visaCountry',
      headerName: 'Visa Country',
      align: 'center' as GridAlignment,
      minWidth: 120,
      renderCell: ({ row: crew }: GridRenderCellParams) =>
        isValidCrew({ name: crew.name }) ? (
          <CrewVisaInfo countryCode={crew.visaCountry} />
        ) : (
          ''
        ),
    },
    {
      field: 'visaExpiry',
      headerName: 'Visa Expiry',
      cellClassName: 'crew-date-cell',
      minWidth: 100,
      editable: true,
      valueSetter: ({ row: crew, value }: GridValueSetterParams) => ({
        ...crew,
        visaExpiry: getCellDate(value, true),
      }),
      renderEditCell: (params: GridRenderEditCellParams) => (
        <GridEditDateCell {...params} disablePast />
      ),
      renderCell: ({ row: crew }: GridRenderCellParams) => {
        if (!isValidCrew({ name: crew.name })) return '';
        return (
          <span>
            {crew.visaExpiry || <PlaceholderDate>dd/mm/yyyy</PlaceholderDate>}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      maxWidth: 60,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      type: 'string',
      renderCell: ({ row: crew }: GridRenderCellParams) =>
        crew.added && (
          <Tooltip
            enterDelay={500}
            content={`Remove ${crew.name || 'this crew'} from the list`}
          >
            <GridActionsCellItem
              icon={<MuiDeleteIcon />}
              label="Delete"
              onClick={() => funcProps?.removeCrew({ all: false, crew })}
              color="error"
              size="small"
            />
          </Tooltip>
        ),
    },
  ].map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: 300,
    ...(isPlanning ? { renderHeader } : {}),
  }));

export const getReadOnlyColumns = (): GridColDef[] =>
  [
    {
      field: 'cid',
      headerName: 'CID',
      minWidth: 60,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row }: GridRenderCellParams) =>
        (row.cid && <span>{row.cid}</span>) ||
        (row.added ? <UserInput>User Input</UserInput> : <span>-</span>),
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 90,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.type) return '';
        const type = params.row.type || '';
        return <CrewBadge $on={type === 'onsigner'}>{type}</CrewBadge>;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 150,
      renderCell: ({ row: { name } }: GridRenderCellParams) => (
        <span>{name || '-'}</span>
      ),
    },
    {
      field: 'manningOffice',
      headerName: 'Manning Office',
      minWidth: 100,
      renderCell: ({ row: { manningOffice } }: GridRenderCellParams) => (
        <span>{manningOffice || '-'}</span>
      ),
    },
    { field: 'rank', headerName: 'Rank', minWidth: 100 },
    {
      field: 'wage',
      headerName: 'Wage (Standby)',
      minWidth: 100,
      renderCell: ({ row: crew }: GridRenderCellParams) => (
        <CrewWage crew={crew} />
      ),
    },
    { field: 'country', headerName: 'Country', minWidth: 100 },
    {
      field: 'homeAirport',
      headerName: 'Home Airport',
      minWidth: 160,
      renderCell: ({ row }: GridRenderCellParams) => (
        <span>{formatCrewHomeAirport(row.homeAirport) || '-'}</span>
      ),
    },
    {
      field: 'birthday',
      headerName: 'Birthdate',
      minWidth: 100,
      renderCell: ({ row: { birthday } }: GridRenderCellParams) => (
        <span>{birthday || '-'}</span>
      ),
    },
    {
      field: 'sex',
      headerName: 'Sex',
      minWidth: 80,
      renderCell: ({ row: { sex } }: GridRenderCellParams) => (
        <span>{sex || '-'}</span>
      ),
    },
    {
      field: 'seamenBookNumber',
      headerName: 'SB No.',
      minWidth: 100,
      renderCell: ({ row: { seamenBookNumber } }: GridRenderCellParams) => (
        <span>{seamenBookNumber || '-'}</span>
      ),
    },
    {
      field: 'seamenBookIssued',
      headerName: 'SB Issue',
      minWidth: 100,
      renderCell: ({ row: { seamenBookIssued } }: GridRenderCellParams) => (
        <span>{seamenBookIssued || '-'}</span>
      ),
    },
    {
      field: 'seamenBookExpiry',
      headerName: 'SB Expiry',
      minWidth: 100,
      renderCell: ({ row: { seamenBookExpiry } }: GridRenderCellParams) => (
        <span>{seamenBookExpiry || '-'}</span>
      ),
    },
    {
      field: 'passportNumber',
      headerName: 'PP No.',
      minWidth: 100,
      renderCell: ({ row: { passportNumber } }: GridRenderCellParams) => (
        <span>{passportNumber || '-'}</span>
      ),
    },
    {
      field: 'passportIssued',
      headerName: 'PP Issue',
      minWidth: 100,
      renderCell: ({ row: { passportIssued } }: GridRenderCellParams) => (
        <span>{passportIssued || '-'}</span>
      ),
    },
    {
      field: 'passportExpiry',
      headerName: 'PP Expiry',
      minWidth: 100,
      renderCell: ({ row: { passportExpiry } }: GridRenderCellParams) => (
        <span>{passportExpiry || '-'}</span>
      ),
    },
    {
      field: 'visaCountry',
      headerName: 'Visa Country',
      minWidth: 100,
      renderCell: ({ row: { visaCountry } }: GridRenderCellParams) => (
        <CrewVisaInfo countryCode={visaCountry} />
      ),
    },
    {
      field: 'visaExpiry',
      headerName: 'Visa Expiry',
      minWidth: 100,
      renderCell: ({ row: { visaExpiry } }: GridRenderCellParams) => (
        <span>{visaExpiry || '-'}</span>
      ),
    },
  ].map((item) => ({
    ...item,
    flex: 1,
    sortable: false,
    headerClassName: 'table-header',
    maxWidth: 300,
  }));

export const columnGroupingModel: GridColumnGroupingModel = [
  {
    groupId: 'Seaman Book (SB)',
    headerClassName: 'column-grouping-header',
    renderHeaderGroup: (params: GridColumnGroupHeaderParams) => (
      <Tooltip content="Seaman book info details of a crew">
        <ColumnGroupHeader>
          <span>{params.headerName ?? params.groupId}</span>
          <MenuBookIcon fontSize="small" sx={{ marginLeft: '0.25rem' }} />
        </ColumnGroupHeader>
      </Tooltip>
    ),
    children: [
      { field: 'seamenBookNumber' },
      { field: 'seamenBookExpiry' },
      { field: 'seamenBookIssued' },
    ],
  },
  {
    groupId: 'Passport (PP)',
    headerClassName: 'column-grouping-header',
    renderHeaderGroup: (params: GridColumnGroupHeaderParams) => (
      <Tooltip content="Crew passport details">
        <ColumnGroupHeader>
          <span>{params.headerName ?? params.groupId}</span>
          <PassportIcon fontSize="small" sx={{ marginLeft: '0.25rem' }} />
        </ColumnGroupHeader>
      </Tooltip>
    ),
    children: [
      { field: 'passportNumber' },
      { field: 'passportExpiry' },
      { field: 'passportIssued' },
    ],
  },
  {
    groupId: 'Visa',
    headerClassName: 'column-grouping-header',
    renderHeaderGroup: (params: GridColumnGroupHeaderParams) => (
      <Tooltip content="Crew visa details">
        <ColumnGroupHeader>
          <span>{params.headerName ?? params.groupId}</span>
          <AccountBoxIcon fontSize="small" sx={{ marginLeft: '0.25rem' }} />
        </ColumnGroupHeader>
      </Tooltip>
    ),
    children: [{ field: 'visaCountry' }, { field: 'visaExpiry' }],
  },
];

export const getColumnVisibility = (
  isMobile: boolean,
  crewColumns?: ColumnVisibility[] | null
) => {
  if (!crewColumns) {
    return Object.keys(MOBILE_HIDDEN_COLUMNS).reduce(
      (acc, field) => ({ ...acc, [field]: !isMobile }),
      {} as Visibility
    );
  }

  const hasUserInput = CREW_ROWS.some(({ added }) => added);
  return crewColumns.reduce(
    (acc, item) => ({
      ...acc,
      [item.field]: (item.field === 'rank' && hasUserInput) || item.selected,
    }),
    {} as Visibility
  );
};
