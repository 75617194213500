import { useSelector } from 'react-redux';

import { RootState } from 'redux/types';

const FLOTILLA_E2E_TEST_EMAIL = 'e2e-tests@grey-wing.com';

// hook to handle E2E test related abstractions
// e.g - utils specific for E2E tests
function useE2ETests() {
  const userInfo = useSelector(({ settings }: RootState) => settings.userInfo);
  const crewChange = useSelector(
    ({ settings }: RootState) => settings.crewChange
  );

  const { costOptions = [] } = crewChange.costParams;

  const isE2ETesting = userInfo?.loginEmail === FLOTILLA_E2E_TEST_EMAIL;
  const canResetCostOptions =
    isE2ETesting && costOptions.includes('Flight') && costOptions.length > 1;

  return { isE2ETesting, canResetCostOptions };
}

export default useE2ETests;
