import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { loadVesselImages, vesselColorBuilder } from 'map/vessels';
import { setMapReady } from 'redux/actions';
import { selectMapVessels, selectSettings } from 'redux/selectors';
import useAppDispatch from './useAppDispatch';
import { simpleHash } from 'utils/hash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'redux/types';

const selectVesselIsReady = createSelector(
  [
    ({ mapState }: RootState) => mapState.isReady.vessel,
    ({ mapState }: RootState) => mapState.isReady.vesselIcons,
  ],
  (vesselIsReady, vesselIconIsReady) => ({ vesselIsReady, vesselIconIsReady })
);

const selectVesselStates = createSelector(
  [
    ({ mapVessels }: RootState) => mapVessels.vesselsIsLoading,
    ({ mapVessels }: RootState) => mapVessels.hasZeroVessels,
  ],
  (vesselsIsLoading, hasZeroVessels) => ({
    vesselsIsLoading,
    hasZeroVessels,
  })
);

function useVesselColors() {
  const cache = useRef<number | null>(null);
  const dispatch = useAppDispatch();
  const { vesselIsReady, vesselIconIsReady } = useSelector(selectVesselIsReady);
  const { vesselsIsLoading, hasZeroVessels } = useSelector(selectVesselStates);
  const { vesselsFull: vessels } = useSelector(selectMapVessels);
  const { colorHighlightField } = useSelector(selectSettings);

  // Prepare Colors
  useEffect(() => {
    if (!vesselIsReady || vesselsIsLoading) return;
    if (hasZeroVessels) {
      dispatch(setMapReady({ type: 'vesselIcons', value: true }));
      return;
    }
    const hash = simpleHash(Array.from(vessels.keys()).join('-'));
    if (hash === cache.current) return;
    console.log('%cStart Loading Vessel SVG icons', 'color:green');
    if (vesselIconIsReady) {
      dispatch(setMapReady({ type: 'vesselIcons', value: false }));
    }
    cache.current = hash;
    const vesselColors = vesselColorBuilder(vessels, colorHighlightField);
    Promise.all([
      loadVesselImages(['385dea']),
      loadVesselImages(['385dea'], '385dea', 'ffffff'), // default vessel color
      loadVesselImages(['000000'], '000000', 'ffffff'), // default black color
      loadVesselImages(
        Array.from(new Set<string>(vesselColors.map((color) => color.color)))
      ),
    ]).then(() => {
      console.log('%cLoaded Vessel SVG icons', 'color: green');
      dispatch(setMapReady({ type: 'vesselIcons', value: true }));
    });
  }, [
    vessels,
    vesselsIsLoading,
    colorHighlightField,
    dispatch,
    hasZeroVessels,
    vesselIconIsReady,
    vesselIsReady,
  ]);

  return null;
}

export default useVesselColors;
