import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginatedReminders } from 'utils/types';

import { completedRemindersAsync, upcomingRemindersAsync } from '../thunks';
import { RemindersState, RootState } from '../types';

export const initialReminders: RemindersState = {
  upcomingIsLoading: false,
  completedIsLoading: false,
  completedReminders: null,
  upcomingReminders: null,
};

const remindersSlice = createSlice({
  name: 'reminders',
  initialState: initialReminders,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(completedRemindersAsync.pending, (state) => {
        return { ...state, completedIsLoading: true };
      })
      .addCase(
        completedRemindersAsync.fulfilled,
        (state, action: PayloadAction<PaginatedReminders | null>) => {
          if (!action.payload) {
            return {
              ...state,
              completedIsLoading: false,
            };
          }
          return {
            ...state,
            completedReminders: action.payload,
            completedIsLoading: false,
          };
        }
      )
      .addCase(upcomingRemindersAsync.pending, (state) => {
        return { ...state, upcomingIsLoading: true };
      })
      .addCase(
        upcomingRemindersAsync.fulfilled,
        (state, action: PayloadAction<PaginatedReminders | null>) => {
          if (!action.payload) {
            return {
              ...state,
              upcomingIsLoading: false,
            };
          }
          return {
            ...state,
            upcomingReminders: action.payload,
            upcomingIsLoading: false,
          };
        }
      );
  },
});

/* ----- selectors -----*/

export const selectReminders = ({ reminders }: RootState) => reminders;

export const selectReminderCalculatedState = createSelector(
  (reminders: RemindersState) => reminders,
  (reminders) => ({
    isLoading: reminders.completedIsLoading || reminders.upcomingIsLoading,
    total:
      (reminders.completedReminders?.pagination?.totalCount || 0) +
      (reminders.upcomingReminders?.pagination?.totalCount || 0),
  })
);

/* ----- actions -----*/

// None at the moment. Primarily thunks

/* ----- reducer -----*/

export default remindersSlice.reducer;
