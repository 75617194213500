import { useCallback } from 'react';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';

import useAppDispatch from './useAppDispatch';

const createWorker = createWorkerFactory(
  () => import('../workers/preferred-ports.worker')
);

// hook to fetch organization preferred ports in the background when app mounts
function usePreferredPorts() {
  const dispatch = useAppDispatch();
  const worker = useWorker(createWorker);

  const getOrgPreferredPorts = useCallback(
    async () => worker.getOrganizationPreferredPorts(dispatch),
    [dispatch, worker]
  );

  return getOrgPreferredPorts;
}

export default usePreferredPorts;
