import mapboxgl from 'mapbox-gl';
import { Point, BBox, PixelBBox } from './types';

export function computeBBox(inp: Array<Point>): BBox {
  let minLat = null;
  let minLng = null;
  let maxLat = null;
  let maxLng = null;

  for (let i = 0; i < inp.length; i++) {
    const p = inp[i];
    if (minLat === null || p.lat < minLat) minLat = p.lat;
    if (minLng === null || p.lng < minLng) minLng = p.lng;
    if (maxLat === null || p.lat > maxLat) maxLat = p.lat;
    if (maxLng === null || p.lng > maxLng) maxLng = p.lng;
  }

  return {
    // TODO: Needs to be fixed, ideally we default to world bounds if we can't resolve
    minLat: minLat || 0,
    maxLat: maxLat || 0,
    minLng: minLng || 100,
    maxLng: maxLng || 100,
  };
}

export function bboxToMapbox(inp: BBox) {
  return new mapboxgl.LngLatBounds(
    new mapboxgl.LngLat(inp.minLng, inp.minLat),
    new mapboxgl.LngLat(inp.maxLng, inp.maxLat)
  );
}

export function zoomMap(
  map: mapboxgl.Map,
  box: BBox,
  options?: mapboxgl.FitBoundsOptions
) {
  map.fitBounds(bboxToMapbox(box), options);
}

export function zoomPixelBBox(map: mapboxgl.Map, box: PixelBBox) {
  const topLeft = map.unproject(new mapboxgl.Point(box.minX, box.minY));
  const bottomRight = map.unproject(new mapboxgl.Point(box.maxX, box.maxY));
  map.fitBounds(new mapboxgl.LngLatBounds(topLeft, bottomRight));
}
