import styled from 'styled-components/macro';

import { Modal } from 'components/shared';

import { StyledButton } from '../common';
import { gray50, lightPurple, red, textBlack } from 'lib/colors';
import { SetModal } from 'hooks/useModal';
import { useCCPanelExport } from 'hooks';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonsWrapper = styled(FlexWrapper)`
  width: 100%;
  justify-content: space-between;
`;

const ContinueButton = styled(StyledButton)`
  background: ${lightPurple};
  border: none;
`;

const CancelButton = styled(StyledButton)`
  background: ${red}40;
  color: ${red};
  border: none;
`;

const ContentWrapper = styled.div`
  font-size: 0.9rem;
  fonr-weight: 500;
  line-height: 1.5rem;
  color: ${textBlack} !important;
  i {
    color: ${gray50};
  }
`;

type Props = {
  closeModal: () => void;
  setModal: SetModal;
  selectedLocode?: string;
  portAgent?: boolean;
};

function ConfirmDownloadModal({ setModal, closeModal, ...rest }: Props) {
  const { saveExcelReport } = useCCPanelExport();

  const actions = (
    <ButtonsWrapper>
      <CancelButton variant="secondary" onClick={closeModal}>
        Cancel
      </CancelButton>
      <FlexWrapper>
        <ContinueButton
          variant="secondary"
          onClick={() => {
            saveExcelReport({ ...rest, skipSaving: true });
            closeModal();
          }}
        >
          Download without Saving
        </ContinueButton>
        <StyledButton
          variant="primary"
          onClick={() => setModal('exportReport', { ...rest })}
        >
          Next to Save
        </StyledButton>
      </FlexWrapper>
    </ButtonsWrapper>
  );

  return (
    <Modal width={400} title="Save before Download?" actions={actions}>
      <ContentWrapper>
        An excel report of this plan will be downloaded. <br />
        Do you want to save current plan before downloading the report?
      </ContentWrapper>
    </Modal>
  );
}

export default ConfirmDownloadModal;
