import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DetailedRouteObject } from 'components/VesselRoutes/helpers';
import { VesselFeature } from 'utils/types';
import { RootState } from '../types';

type CalculatedRouteState = {
  activeVesselRoutes: {
    [vesselId: number]: { [typeSegment: string]: DetailedRouteObject };
  };
  hoveredVesselRoutes: { [vesselId: number]: VesselFeature | null };
};

export const initialCalculatedRoute: CalculatedRouteState = {
  activeVesselRoutes: {},
  hoveredVesselRoutes: {},
};

const calculatedRouteSlice = createSlice({
  name: 'calculatedRoute',
  initialState: initialCalculatedRoute,
  reducers: {
    setCalculatedHoveredRoute: (
      state,
      action: PayloadAction<{ vesselId: number; route: VesselFeature | null }>
    ) => ({
      ...state,
      hoveredVesselRoutes: {
        ...state.hoveredVesselRoutes,
        [action.payload.vesselId]: action.payload.route,
      },
    }),
    setCalculatedRoute: (
      state,
      action: PayloadAction<{
        vesselId: number;
        type: string;
        route: DetailedRouteObject;
      }>
    ) => ({
      ...state,
      activeVesselRoutes: {
        ...state.activeVesselRoutes,
        [action.payload.vesselId]: {
          ...state.activeVesselRoutes[action.payload.vesselId],
          [action.payload.type]: action.payload.route,
        },
      },
    }),
  },
});

/* ----- selectors -----*/

export const selectCalculatedRoute = ({ calculatedRoute }: RootState) =>
  calculatedRoute;

export const selectInitialMapReady = createSelector(
  [
    (state: RootState) => state.mapState.isReady.vesselIcons,
    (state: RootState) => state.mapState.isReady.map,
    (state: RootState) => state.mapState.isReady.vessel,
    (state: RootState) => state.mapState.isReady.route,
  ],
  (vesselIcons, map, mapVessel, mapRoute) =>
    vesselIcons && map && mapVessel && mapRoute
);

export const selectInitialRouteReady = createSelector(
  [
    selectInitialMapReady,
    (state: RootState) => state.portCalls.portCallRequests,
    (state: RootState) => state.mapVessels.filteredVessels,
    (state: RootState) => state.calculatedRoute.hoveredVesselRoutes,
    (state: RootState) => state.mapState.isReady.forceStart,
  ],
  (
    mapReady,
    portCallRequests,
    filteredVessels,
    hoveredVesselRoutes,
    forceStart
  ) => {
    if (!mapReady) return false;
    if (forceStart) return true;
    // Override this to true if developing locally..
    if (process.env.REACT_APP_LOCAL_DEV === 'true') return true;
    const allPortCallReady = Array.from(filteredVessels.keys()).reduce(
      (isPortCallReady, vesselId) => {
        if (!portCallRequests[vesselId]) return false;
        return isPortCallReady;
      },
      true
    );
    if (!allPortCallReady) return false;
    const allRoutesReady = Array.from(filteredVessels.keys()).reduce(
      (isRouteReady, vesselId) => {
        if (hoveredVesselRoutes[vesselId] === undefined) return false;
        return isRouteReady;
      },
      true
    );
    return allRoutesReady;
  }
);

export const selectHoveredVesselRoutes = createSelector(
  [
    (state: RootState) => state.calculatedRoute.hoveredVesselRoutes,
    (state: RootState) => state.mapVessels.filteredVessels,
  ],
  (hoveredVesselRoutes, filteredVessels) => {
    const completed = Array.from(filteredVessels.keys()).reduce(
      (isComplete, vesselId) => {
        if (hoveredVesselRoutes[vesselId] === undefined) return false;
        return isComplete;
      },
      true
    );
    if (completed) {
      return hoveredVesselRoutes;
    }
    return null;
  }
);

export const selectActiveVesselRoutes = createSelector(
  [selectCalculatedRoute],
  (calculatedRoute) => calculatedRoute.activeVesselRoutes
);

export const selectActiveVesselRouteById = createSelector(
  [
    (state: RootState) => state.calculatedRoute.activeVesselRoutes,
    (state: RootState, vesselId: number) => vesselId,
  ],
  (activeVesselRoutes, vesselId) => activeVesselRoutes[vesselId] || null
);

/* ----- actions -----*/

export const { setCalculatedRoute, setCalculatedHoveredRoute } =
  calculatedRouteSlice.actions;

/* ----- reducer -----*/

export default calculatedRouteSlice.reducer;
