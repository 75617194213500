import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type {
  InfoboxPopupState,
  PortInfoPopup,
  RootState,
  VesselInfoPopup,
} from '../types';

export const initialInfoboxPopup: InfoboxPopupState = {
  popup: null,
};

const infoboxPopupSlice = createSlice({
  name: 'infoboxPopup',
  initialState: initialInfoboxPopup,
  reducers: {
    setInfoboxPopup: (
      state,
      action: PayloadAction<VesselInfoPopup | PortInfoPopup>
    ) => ({
      ...state,
      popup: action.payload,
    }),
    clearInfoboxPopup: () => initialInfoboxPopup,
  },
});

/* ----- selectors -----*/

export const selectInfoboxPopup = ({ infoboxPopup }: RootState) => infoboxPopup;

/* ----- actions -----*/

export const { setInfoboxPopup, clearInfoboxPopup } = infoboxPopupSlice.actions;

/* ----- reducer -----*/

export default infoboxPopupSlice.reducer;
