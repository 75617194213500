import { useContext, useMemo, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';

import { textGray } from 'lib/colors';
import { BREAK_POINT_XS } from 'lib/breakpoints';
import { selectSettings } from 'redux/selectors';
import { SidePanelFieldContext } from 'contexts/SidepanelContext';

import CardContainer from './common/CardContainer';
import CardHeader from './common/CardHeader';
import { FIELDS_IN_COURSE_HEADER } from './common/variables';
import {
  getSidePanelDisplayableFields,
  vesselFields,
  stringifyFieldValue,
} from 'utils/vesselFields';
import { compoundSidePanelFields } from './CompoundSidePanelFields';

interface MiscDataProps {
  vessel: Vessel;
}

const Subtitle = styled.span`
  display: inline-block;
  color: ${textGray};
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1;
`;

const AdditionalInfoWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0.5rem 0;
  padding: 0;
  row-gap: 1rem;
  li {
    margin: 0 !important;
    padding-right: 0.2rem;
    flex: 1 1 50%;
    list-style-type: none;
  }
  @media screen and (max-width: ${BREAK_POINT_XS}) {
    li {
      flex-basis: 100%;
    }
  }
`;

const FieldInfo = styled.li`
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: space-between;
`;

const MiscData = ({ vessel }: MiscDataProps) => {
  const { sidePanelFields } = useSelector(selectSettings);
  const { activeRefs, highlightedField, isInWizard, wizardSidePanelFields } =
    useContext(SidePanelFieldContext);

  const defaultSidePanelDisplayableFields = useMemo(
    () =>
      getSidePanelDisplayableFields().filter(
        (o) =>
          !FIELDS_IN_COURSE_HEADER.includes(o) &&
          !(o in compoundSidePanelFields)
      ),
    []
  );

  useEffect(() => {
    if (
      highlightedField &&
      highlightedField in activeRefs.current &&
      activeRefs.current[highlightedField]
    ) {
      activeRefs.current[highlightedField].scrollIntoView(true, {
        behavior: 'smooth',
      });
    }
  }, [highlightedField, activeRefs]);

  const displayFields = useMemo(() => {
    const fieldsToUse =
      isInWizard && wizardSidePanelFields
        ? wizardSidePanelFields
        : sidePanelFields;
    return defaultSidePanelDisplayableFields
      .reduce((acc: Array<any>, fieldId) => {
        if (!fieldsToUse.includes(fieldId) || !vesselFields[fieldId].display) {
          return acc;
        }
        acc.push({ fieldId, data: vesselFields[fieldId] });
        return acc;
      }, [])
      .sort((a, b) => a.data.displayPriority - b.data.displayPriority);
  }, [
    sidePanelFields,
    defaultSidePanelDisplayableFields,
    isInWizard,
    wizardSidePanelFields,
  ]);

  if (!displayFields.length) return null;
  return (
    <CardContainer>
      <CardHeader>
        <h4>Miscellaneous Data</h4>
      </CardHeader>
      <AdditionalInfoWrapper>
        {displayFields.map(({ fieldId, data }) => (
          <FieldInfo
            key={fieldId}
            ref={(element) => ((activeRefs.current as any)[fieldId] = element)}
          >
            <Subtitle>{data.longDesc}</Subtitle>
            <p style={{ margin: 0 }}>
              {stringifyFieldValue(vessel[fieldId], data)}
            </p>
          </FieldInfo>
        ))}
      </AdditionalInfoWrapper>
    </CardContainer>
  );
};

export default memo(MiscData);
