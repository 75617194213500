import { createPortal } from 'react-dom';
import React, { useEffect, useMemo, useRef, ReactElement } from 'react';
import mapboxgl, { Anchor } from 'mapbox-gl';
import { mapState } from 'map/map';

interface PopupProps {
  children: React.ReactNode | ReactElement;
  offset?: [number, number];
  closeButton?: boolean;
  closeOnClick?: boolean;
  className?: string;
  lng: number;
  lat: number;
  // generic `Object` type used in `EventedListener`
  // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/fca9dca5950b744c1286a86a1622d6c566d4ed84/types/mapbox-gl/index.d.ts#L1826
  onOpen?: (e?: Object) => void;
  onClose?: (e?: Object) => void;
  maxWidth?: number;
  anchor?: Anchor;
}

/* eslint-disable complexity,max-statements */
function MapboxPopup(props: PopupProps) {
  const map = mapState.map;
  const container = useMemo(() => {
    return document.createElement('div');
  }, []);
  const thisRef = useRef({ props });
  thisRef.current.props = props;

  const popup = useMemo(() => {
    const pp = new mapboxgl.Popup({
      ...props,
      closeButton: props.closeButton ?? false, // In view of using our own close button instead of mapbox's implementation this is forced to be false unless otherwise required
      maxWidth: props.maxWidth ? `${props.maxWidth}px` : undefined,
    }).setLngLat([props.lng, props.lat]);

    pp.on('open', (e) => {
      thisRef.current.props.onOpen?.(e);
    });

    pp.on('close', (e) => {
      thisRef.current.props.onClose?.(e);
    });

    return pp;
  }, [props]); // eslint-disable-line

  useEffect(() => {
    if (!map) return;
    popup.setDOMContent(container).addTo(map);
    return () => {
      if (popup.isOpen()) {
        popup.remove();
      }
    };
  }, [popup]); // eslint-disable-line

  if (popup.isOpen()) {
    if (
      popup.getLngLat().lng !== props.lng ||
      popup.getLngLat().lat !== props.lat
    ) {
      popup.setLngLat([props.lng, props.lat]);
    }
  }

  const child = React.cloneElement(props.children as ReactElement, {
    closePopup: popup.remove,
  });

  return createPortal(child, container);
}

export default React.memo(MapboxPopup);
