import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable, RedirectDetails, RootState } from '../types';

type CounterState = Nullable<RedirectDetails>;

export const initialRedirect = null as CounterState;

const redirectSlice = createSlice({
  name: 'redirect',
  initialState: initialRedirect,
  reducers: {
    setRedirectDetails: (_, action: PayloadAction<RedirectDetails | null>) =>
      action.payload,
  },
});

/* ----- selectors -----*/

export const selectRedirect = ({ redirect }: RootState) => redirect;

/* ----- actions -----*/

export const { setRedirectDetails } = redirectSlice.actions;

/* ----- reducer -----*/

export default redirectSlice.reducer;
