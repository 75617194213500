import { useContext } from 'react';
import styled from 'styled-components/macro';

import { gray60, iconGray } from 'lib/colors';
import { formatDate } from 'utils/format-date';

import { Tooltip } from 'components/shared';
import { CCPanelContext } from 'contexts/CCPanelContext';
import BudgetStatusBadge from './BudgetStatusBadge';

import { FlightFilters, PortMeta } from '../../types';
import InfoIcon from '@mui/icons-material/Info';

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled(FlexWrapper)`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${gray60};
`;

const StyledIcon = styled(FlexWrapper)`
  color: ${iconGray};
  margin-left: 0.33rem;
  svg {
    font-size: 1rem !important;
  }
`;

const Separator = styled.div`
  width: 2px;
  height: 1rem;
  margin: 0 0.5rem;
  background-color: ${iconGray};
`;

type Props = {
  ports: PortMeta[];
  filters: { [locodeKey: string]: FlightFilters };
};

function FlightsMeta({ filters, ports }: Props) {
  const {
    tableState: { budgetDetails },
  } = useContext(CCPanelContext);

  // find currently selected port
  const { locode: currentPortLocode, uniqETA } =
    Object.values(filters).find(({ active }) => active) || {};
  const activePort = ports.find(
    (port) =>
      port.locode === currentPortLocode &&
      (uniqETA ? uniqETA === port.uniqETA : true)
  );

  if (!activePort) {
    return null;
  }

  const { name, eta, etd, estimated } = activePort;

  return (
    <div data-id="port-info">
      <Text>
        Showing flights for <b style={{ marginLeft: '0.25rem' }}>{name}</b>
        <Separator />
        <b>ETA: {eta ? formatDate(eta, 'HH:mm, DD MMM YY') : 'Unknown'}</b>
        {estimated === 'eta' && (
          <Tooltip content="Port ETA missing in route. Showing estimated ETA.">
            <StyledIcon>
              <InfoIcon />
            </StyledIcon>
          </Tooltip>
        )}
        <Separator />
        <b>ETD: {etd ? formatDate(etd, 'HH:mm, DD MMM YY') : 'Unknown'}</b>
        {estimated === 'etd' && (
          <Tooltip content="Port ETD missing in route. Showing estimated ETD.">
            <StyledIcon>
              <InfoIcon />
            </StyledIcon>
          </Tooltip>
        )}
        {budgetDetails?.text && (
          <>
            <Separator />
            <BudgetStatusBadge budgetDetails={budgetDetails} />
          </>
        )}
      </Text>
    </div>
  );
}

export default FlightsMeta;
