import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import { HTMLAttributes } from 'react';

import { colorIsTooBright } from 'utils/color-utils';
import { black, white } from 'lib/colors';
import { VesselTagCreateUpdate } from './types';

export function renderTags(
  tagValue: VesselTagCreateUpdate[],
  getTagProps: AutocompleteRenderGetTagProps
) {
  return tagValue.map((option, index) => (
    <Chip
      size="small"
      sx={{
        backgroundColor: option.colour,
        color: colorIsTooBright(white, option.colour) ? black : white,
        '.MuiSvgIcon-root': {
          color: colorIsTooBright(option.colour, white) ? black : white,
        },
      }}
      {...getTagProps({ index })}
      label={option.label}
    />
  ));
}

export function renderOption(
  props: HTMLAttributes<HTMLLIElement>,
  option: VesselTagCreateUpdate
) {
  return (
    <li style={{ fontStyle: option?.isNew ? 'italic' : undefined }} {...props}>
      {option?.isNew ? (
        option.label
      ) : (
        <div>
          <Chip
            size="small"
            sx={{
              backgroundColor: option.colour,
              color: colorIsTooBright(white, option.colour) ? black : white,
              '.MuiSvgIcon-root': {
                color: colorIsTooBright(option.colour, white) ? black : white,
              },
              marginRight: '0.25rem',
            }}
            label={option.label}
          />
          <span>{`${option.count} vessel${option.count > 1 ? 's' : ''}`}</span>
        </div>
      )}
    </li>
  );
}
