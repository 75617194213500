import { FlotillaMapConfig } from 'utils/flotilla-config';

export const formatNumberWithSeparator = (
  value: number,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 0 }
) => {
  return Number(value).toLocaleString('en', options);
};

type RouteDisplayUnits = { [key: string]: string };

export const formatRouteDisplayUnits: RouteDisplayUnits = {
  nauticalmiles: 'NM',
  kilometers: 'KM',
  miles: 'mi',
};

export const formatNumberWithUnits = (
  number: number,
  units = FlotillaMapConfig.routeUnits
) => {
  return `${formatNumberWithSeparator(number)} ${
    formatRouteDisplayUnits[units]
  }`;
};
