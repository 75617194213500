import * as Sentry from '@sentry/react';
import type { Vessel } from '@greywing-maritime/frontend-library/dist/types/flotillaVesselTypes';
import { getAuthToken } from '@greywing-maritime/frontend-library/dist/utils/auth';

let logoutCallbackIdCtr = 0;
let onLogoutCallbacks: Map<number, () => void> = new Map();

export const onLogout = (cb: () => void): number => {
  const id = logoutCallbackIdCtr++;
  onLogoutCallbacks.set(id, cb);
  return id;
};

export const removeOnLogout = (id: number) => {
  onLogoutCallbacks.delete(id);
};

export const fireLogout = () => {
  onLogoutCallbacks.forEach((v) => {
    try {
      v();
    } catch (e) {
      console.error(e);
    }
  });
};

export const headers = (token: string) => {
  return {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
};

export const handleAbort = (
  error: Error,
  nonAbortResponse: { success: boolean; message: string }
) => {
  if (error.name === 'AbortError') {
    return { success: false, message: 'Aborted Request.' };
  }
  return nonAbortResponse;
};

// common util to use for handling invalid response code
// should throw an exception, which will be caught by `catch` block
export const handleAPIError = (
  responseStatus: number,
  result?: any,
  skipStatus?: number[]
) => {
  if (skipStatus?.includes(responseStatus)) return;
  if (responseStatus < 200 || responseStatus >= 300) {
    throw new Error(result?.message || 'Invalid response code.');
  }
};

export const handleErrorToSentry = (error: any, apiPath: string) => {
  Sentry.withScope(function (scope) {
    scope.setLevel('warning');
    scope.setTag('type', 'apiPath');
    scope.setTag('apiPath', apiPath);
    Sentry.captureException(error, {
      extra: { apiPath },
    });
  });
};

export const getLatestAppVersion = async (): Promise<{
  success: boolean;
  message: string;
  response?: { version: string };
}> => {
  const authToken = getAuthTokenValue();
  if (!authToken) {
    return { success: false, message: 'Unauthenticated user. Please login.' };
  }

  try {
    const baseUrl = window.location.href.replace(/\/$/, ''); // remove trailing slash
    const response = await fetch(`${baseUrl}/version.json`, {
      headers: headers(authToken),
    });
    const parsedResponse = await response.json();
    handleAPIError(response.status, parsedResponse);

    return {
      success: true,
      message: 'Latest app version fetched successfully!',
      response: parsedResponse,
    };
  } catch (error) {
    console.log('Error getting app version', error);
    return {
      success: false,
      message: 'Failed to fetch app version!',
    };
  }
};

export const getAuthTokenValue = (): string | null => {
  const authToken = getAuthToken();

  if (authToken.success) {
    if (authToken.token) {
      return authToken.token;
    }

    fireLogout();
    Sentry.captureException(
      new Error('getAuthToken() returns success but no auth token present.')
    );
    return null;
  }

  fireLogout();
  return null;
};

export const formatVesselsResponse = (result: Vessel[] | undefined) => {
  const vesselsObj: Map<number, Vessel> = new Map();
  (result || []).forEach((vessel) => {
    // remove operatingStatus from vessels since it's no longer available
    delete vessel.operatingStatus;
    vesselsObj.set(vessel.id, vessel);
  });
  console.log('Loaded vessels - ', (result || []).length);
  return vesselsObj;
};
