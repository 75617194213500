import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { ButtonBase, SxProps } from '@mui/material';
import { blue, borderGray, white } from 'lib/colors';

const IconBase = styled(ButtonBase)`
  height: 42px;
  width: 42px;
  border-radius: 4px;
  font-size: 24px;
  svg {
    font-size: 28px !important;
  }
`;

const PrimaryIcon = styled(IconBase)`
  background-color: ${blue} !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  color: ${white} !important;
`;

const SecondaryIcon = styled(IconBase)`
  background-color: ${white} !important;
  border: 1px solid ${borderGray} !important;
  border-radius: 4px !important;
  color: ${blue} !important;
`;

type IconButtonProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  onClick: () => void;
  sx?: SxProps;
};

const IconButton = ({ variant = 'primary', ...rest }: IconButtonProps) => {
  switch (variant) {
    case 'primary':
      return <PrimaryIcon {...rest} />;
    case 'secondary':
      return <SecondaryIcon {...rest} />;
    default:
      return <PrimaryIcon {...rest} />;
  }
};
export default memo(IconButton);
