import { memo, SyntheticEvent, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styled from 'styled-components/macro';

import { useAppDispatch } from 'hooks';
import sleep from 'lib/sleep';
import { setRecentSearches } from 'redux/actions';
import { selectRecentSearches, selectSettings } from 'redux/selectors';
import { CountryOption as Country } from 'redux/types';

import { AutocompleteGroup } from 'components/shared';
import { formatCountries } from '../../helpers';

const Wrapper = styled.div`
  .MuiInputLabel-root,
  .MuiAutocomplete-inputRoot,
  .MuiOutlinedInput-input,
  .MuiAutocomplete-loading {
    font-size: 0.8rem;
    font-family: HK Grotesk, Roboto;
  }
`;

const autoCompleteStyles = {
  maxHeight: '250px',
  fontSize: '0.8rem',
  lineHeight: '1rem',
  fontFamily: 'HK Grotesk, Roboto',
};

type Props = {
  country: string;
  onSelect: (text: string | null) => void;
};

function SelectCountry({ country, onSelect }: Props) {
  const dispatch = useAppDispatch();
  const recentSearches = useSelector(selectRecentSearches);
  const {
    crewChange: { compact: isCompact },
  } = useSelector(selectSettings);
  const { crewNation = [] } = recentSearches || {};

  const inputRef = useRef<HTMLInputElement>(null);
  const [countryQuery, setCountryQuery] = useState('');
  const countryList = useMemo(
    () => formatCountries(crewNation as Country[], Boolean(countryQuery)),
    [crewNation, countryQuery]
  );
  const selectedCountry = useMemo(
    () => countryList.find(({ name }) => name === country),
    [country] // eslint-disable-line
  );

  const handleChangeCountry = (
    event: SyntheticEvent,
    value?: Country | string | null
  ) => {
    const newValue = (value as Country | null)?.name || '';
    // setNewCrew((prevValue) => ({ ...prevValue, country: newValue }));
    if (value) {
      onSelect(newValue);
      dispatch(setRecentSearches({ crewNation: value as Country }));
    }
  };

  const handleCountryInputChange = (event: SyntheticEvent, value: string) => {
    setCountryQuery(value);
    if (!value) {
      setCountryQuery('');
      onSelect(null);
    }
  };

  const handleKeyDown = async (event: SyntheticEvent) => {
    // async update of country with keyboard to have value available in event
    await sleep(0);
    const { value: country } = event.target as HTMLInputElement;
    onSelect(country);
  };

  const customGroupProps =
    crewNation.length && !countryQuery
      ? { groupBy: (option: Country) => option.label || '' }
      : {};

  return (
    <Wrapper>
      <Autocomplete
        disablePortal
        freeSolo
        size="small"
        options={countryList}
        value={selectedCountry || null} // fallback to `null` for no option
        inputValue={countryQuery}
        getOptionLabel={(option) => (option as Country).name}
        onChange={handleChangeCountry}
        onInputChange={handleCountryInputChange}
        onKeyDown={(e) => e.key === 'Enter' && handleKeyDown(e)}
        renderInput={(params) => (
          <TextField
            {...params}
            {...(!isCompact ? { label: 'Nationality' } : {})}
            inputRef={inputRef}
            variant={!isCompact ? 'outlined' : 'standard'}
            placeholder="Edit crew country"
          />
        )}
        renderGroup={AutocompleteGroup}
        ListboxProps={{ style: autoCompleteStyles }}
        sx={{ width: 130 }}
        {...customGroupProps}
      />
    </Wrapper>
  );
}

export default memo(SelectCountry);
