import find from 'lodash/find';
import filter from 'lodash/filter';
import type { HotelCommon } from '@greywing-maritime/frontend-library/dist/types/hotels';

import { secondsToDHM } from 'lib/common';
import { getCrewWage } from './crew';
import { getFlightDelay } from './flights';
import {
  ActiveFlight,
  BudgetStatus,
  CCBudgetType,
  ConfirmedFlight,
  CrewChangeCost,
  ReadOnlyFlight,
  TravelCostFunc,
  UIBudgetDetails,
  VesselCCBudget,
} from '../types';

// get total additional cost - wage & hotel currently
// also calculates delay in integer & formatted text
export const getTravelCost: TravelCostFunc =
  (costOptions) => (flight, crew) => {
    if (!crew) {
      return null;
    }

    const showCost = (type: CrewChangeCost) => costOptions.includes(type);

    const difference = getFlightDelay(flight);
    const absDifference = Math.abs(difference);
    const { amount: dailyWage } = getCrewWage(crew);
    const wageCost =
      showCost('Wage') && difference > 0
        ? Math.round(absDifference * dailyWage)
        : 0;
    const hotelCost =
      showCost('Hotel') && flight.hotelCost
        ? Math.round(Math.ceil(absDifference) * flight.hotelCost)
        : 0;
    const timeInSeconds = absDifference * 24 * 3600;
    const flightCost = showCost('Flight')
      ? Math.round(flight.price?.amount || 0)
      : 0;

    return {
      difference,
      formattedDelay: secondsToDHM(timeInSeconds),
      total: { wage: wageCost, hotel: hotelCost, flight: flightCost },
      daily: {
        wage: showCost('Wage') && crew.wage ? dailyWage : 0,
        hotel: showCost('Hotel') ? flight.hotelCost || 0 : 0,
        flight: showCost('Flight') ? flightCost : 0,
      },
      currency: flight.price?.currency || '',
    };
  };

export const findLowestHotelCost = (hotels: HotelCommon[]) => {
  const { price } =
    hotels
      .slice() // make a copy to prevent error of reading from readonly array
      .sort((a, b) => Number(a.price.total) - Number(b.price.total))[0] || {};
  return Math.round(Number(price?.total)) || 0;
};

// insert selected hotel cost inside crew details
export const includeHotelCost = (
  flight: ActiveFlight,
  hotels: HotelCommon[]
) => {
  if (!hotels.length || !flight.flight) {
    return { ...flight, hotelCost: 0 };
  }
  return { ...flight, hotelCost: findLowestHotelCost(hotels) };
};

// set budget status for a specific port
export const getBudgetDetailsForPort = (
  confirmedFlights: (ConfirmedFlight | ReadOnlyFlight)[] = [],
  vesselBudgets: VesselCCBudget[] | null
): UIBudgetDetails | null => {
  const flightBudget = find(vesselBudgets, [
    'budgetType',
    CCBudgetType.FLIGHTS,
  ]);

  if (!flightBudget) {
    return null;
  }

  let statusText = '';
  let budgetStatus = BudgetStatus.NONE;
  const overBudgetCount = filter(
    confirmedFlights,
    ({ price }) => price.amount > flightBudget.amount
  ).length;

  switch (true) {
    // all confirmed flights are over budget
    case overBudgetCount === confirmedFlights.length:
      budgetStatus = BudgetStatus.ALL_OVER;
      statusText = `${overBudgetCount}/${confirmedFlights.length} flight(s) over budget`;
      break;

    // some confirmed flights are over budget
    case overBudgetCount > 0 && overBudgetCount < confirmedFlights.length:
      budgetStatus = BudgetStatus.SOME_OVER;
      statusText = `${overBudgetCount}/${confirmedFlights.length} flight(s) over budget`;
      break;

    // no confirmed flights are over budget
    default:
      budgetStatus = BudgetStatus.ALL_UNDER;
      statusText = 'All flight(s) under budget';
      break;
  }

  return { text: statusText, status: budgetStatus };
};
