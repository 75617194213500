import { useAppDispatch } from 'hooks';
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useRef,
  useState,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import uniqBy from 'lodash/uniqBy';

import { updateGlobalTags } from 'redux/actions';
import { selectMapVessels } from 'redux/selectors';
import { VesselTag, VesselTagWithCount } from 'utils/types';
import { selectSidePanelVessels } from 'redux/reducers/sidePanel';
import { GetVesselJourney } from 'components/SidePanel/VesselCourse/types';
import { getVesselJourneyForSidePanel } from 'components/SidePanel/VesselCourse/helpers';

type SidePanelFieldContextValue = {
  activeRefs: any;
  highlightedField?: string;
  setHighlightedField: React.Dispatch<React.SetStateAction<any>>;
  isInWizard: boolean;
  wizardSidePanelFields?: string[];
  setWizardSidePanelFields: Dispatch<SetStateAction<string[]>>;
  searchGlobal: (v: string) => VesselTagWithCount[];
  filteredCommonTags: VesselTag[];
  getVesselJourney: GetVesselJourney;
};

export const SidePanelFieldContext = createContext(
  {} as SidePanelFieldContextValue
);

export const SidePanelFieldProvider = ({
  children,
  isInWizard = false,
}: {
  children: ReactNode;
  isInWizard?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const sidePanelVessels = useSelector(selectSidePanelVessels);
  const {
    globalTags,
    filteredVessels,
    vesselsFull: vessels,
  } = useSelector(selectMapVessels);

  const activeRefs = useRef<any>({});
  const [highlightedField, setHighlightedField] = useState<
    string | undefined
  >();
  const [wizardSidePanelFields, setWizardSidePanelFields] = useState<string[]>(
    []
  );
  const [tagIndex, setTagIndex] =
    useState<Fuse.FuseIndex<VesselTagWithCount> | null>(null);

  // Create index for searching tags
  useEffect(() => {
    const indexed = Fuse.createIndex(['displayName'], globalTags);
    setTagIndex(indexed);
  }, [globalTags]);

  // search for tags globally - mainly used to populate dropdown
  const searchGlobal = useCallback(
    (value: string) => {
      if (!tagIndex) return [];
      if (!value) return globalTags;
      const fuse = new Fuse(
        globalTags,
        {
          includeScore: true,
          keys: ['displayName'],
          distance: 10,
          threshold: 0.2,
        },
        tagIndex
      );
      const result = fuse.search(value);
      const finalResult = result.map((o) => ({
        id: o.item.id,
        displayName: o.item.displayName,
        colour: o.item.colour,
        count: o.item.count,
      }));
      return finalResult as VesselTagWithCount[];
    },
    [tagIndex, globalTags]
  );

  // Identify common tags amongst multiple selected vessels
  const filteredCommonTags: VesselTag[] = useMemo(() => {
    const tagTotal = Array.from(filteredVessels.values()).reduce(
      (tags, vessel) => {
        if (!sidePanelVessels.map((o) => o.id).includes(vessel.id)) return tags;
        // @ts-ignore
        vessel.vesselTags.forEach((tag) => {
          if (tags[tag.id]) {
            tags[tag.id] += 1;
          } else {
            tags[tag.id] = 1;
          }
        });
        return tags;
      },
      {} as { [tagId: number]: number }
    );

    return Object.keys(tagTotal).reduce<VesselTag[]>(
      (commonTags, tagId: string) => {
        if (tagTotal[Number(tagId)] === sidePanelVessels.length) {
          const locateTag: VesselTagWithCount | undefined = globalTags.find(
            (o) => o.id === Number(tagId)
          );
          if (locateTag)
            commonTags.push({
              id: locateTag.id,
              displayName: locateTag.displayName,
              colour: locateTag.colour,
            });
          return commonTags;
        }
        return commonTags;
      },
      []
    );
    // eslint-disable-next-line
  }, [sidePanelVessels, filteredVessels, globalTags]);

  // curried function to calculate vessel journey in sidepanel
  const getVesselJourney = useMemo(
    () => getVesselJourneyForSidePanel(isInWizard),
    [isInWizard]
  );

  // update global tags whenever vessels change
  useEffect(() => {
    const tagTotal = Array.from(vessels.values()).reduce((tags, vessel) => {
      // @ts-ignore
      vessel.vesselTags.forEach((tag) => {
        if (tags[tag.id]) {
          tags[tag.id] += 1;
        } else {
          tags[tag.id] = 1;
        }
      });
      return tags;
    }, {} as { [tagId: number]: number });
    const uniqueTags = Array.from(vessels.values())
      .reduce((tags, vessel) => {
        // @ts-ignore
        return uniqBy([...tags, ...vessel.vesselTags], 'id');
      }, [] as VesselTag[])
      .map<VesselTagWithCount>((o) => ({ ...o, count: tagTotal[o.id] }));
    dispatch(updateGlobalTags(uniqueTags));
  }, [vessels]); // eslint-disable-line

  return (
    <SidePanelFieldContext.Provider
      value={{
        activeRefs,
        highlightedField,
        setHighlightedField,
        isInWizard,
        wizardSidePanelFields,
        setWizardSidePanelFields,
        searchGlobal,
        filteredCommonTags,
        getVesselJourney,
      }}
    >
      {children}
    </SidePanelFieldContext.Provider>
  );
};
