import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Tooltip } from 'components/shared';

type Props = {
  expanded: boolean;
};

const ExpandButton = ({ expanded }: Props) => (
  <Tooltip
    content={`Click to ${expanded ? 'minimize' : 'expand'}`}
    enterDelay={1000}
    enterNextDelay={500}
  >
    <IconButton size="medium" tabIndex={-1}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${expanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  </Tooltip>
);

export default memo(ExpandButton);
